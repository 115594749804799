<template>
  <div class="page-wrapper">
    <base-panel
      title="Статистика по аварийности"
      :is-add-button-visible="false"/>
    <accident-report-form :table-rows="tableRows" />
    <accident-report :accident-rate="getAccidentRate" />
    <base-content-loaders v-if="loading" />
    <div v-if="!reportData.length && !loading"
         class="no-result">
      <img src="@/assets/img/no-result-2.png"
           alt="не сформирован отчёт">
      <div class="no-result__text">
        Сформируйте запрос, чтобы получить данные
      </div>
    </div>
  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel.vue";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import {mapState} from "vuex";
import AccidentReportForm from "@/components/ServiceDesk/ReportsForms/AccidentReportForm.vue";
import AccidentReport from "@/components/ServiceDesk/ReportsPreviews/AccidentReport.vue";

export default {
  name: "AccidentReportPage",
  components: {
    AccidentReport,
    AccidentReportForm,
    BaseContentLoaders,
    BasePanel
},
  computed: {
    ...mapState({
      reportData: state => state.reportsData.reportData,
      loading: state => state.reportsData.loading
    }),
    tableRows() {
      const rows = [];
      if (this.reportData.length){
        rows.push(['Наименование', 'Ед. изм.']);
        for (let item of this.reportData) {
          rows[rows.length - 1].push(this.$moment(item.date).format('MMMM'))
        }
        rows.push(['Водопровод - сети'])
        rows.push(['Количество повреждений, в т.ч. аварий на водопроводной сети', 'шт.'])
        for (let item of this.reportData) {
          rows[rows.length - 1].push(item.accident_water.all_count)
        }
        rows.push(['Справочно: из грунта (с разрытием)', 'шт.'])
        for (let item of this.reportData) {
          rows[rows.length - 1].push(item.accident_water.with_earth_breaking)
        }
        rows.push(['Справочно: из ВК', 'шт.'])
        for (let item of this.reportData) {
          rows[rows.length - 1].push(item.accident_water.from_well)
        }
        rows.push(['Справочно: из поверхностной трубы', 'шт.'])
        for (let item of this.reportData) {
          rows[rows.length - 1].push(item.accident_water.on_surface)
        }
        rows.push(['Авария (с отключением)', 'шт.'])
        for (let item of this.reportData) {
          rows[rows.length - 1].push(item.accident_water.with_shutdowns)
        }
        rows.push(['Повреждения (без отключения)', 'шт.'])
        for (let item of this.reportData) {
          rows[rows.length - 1].push(item.accident_water.without_shutdowns)
        }

        rows.push(['Канализация - сети'])
        rows.push(['Количество повреждений на канализационной сети', 'шт.'])
        for (let item of this.reportData) {
          rows[rows.length - 1].push(item.accident_drainage.all_count)
        }
        rows.push(['Засоры', 'шт.'])
        for (let item of this.reportData) {
          rows[rows.length - 1].push(item.accident_drainage.clogging)
        }
        rows.push(['Порывы на напорных сетях', 'шт.'])
        for (let item of this.reportData) {
          rows[rows.length - 1].push(item.accident_water.gusts)
        }

        rows.push(['Протяженность сети'])
        rows.push(['Протяженность водопроводной сети', 'км.'])
        for (let item of this.reportData) {
          rows[rows.length - 1].push(item.accident_water.network_len)
        }
        rows.push(['Протяженность канализационной сети', 'км.'])
        for (let item of this.reportData) {
          rows[rows.length - 1].push(item.accident_drainage.network_len)
        }

        rows.push(['Аварийность'])
        rows.push(['По водопроводным сетям', 'кол-во авар./км*год'])
        for (let item of this.reportData) {
          rows[rows.length - 1].push(this.getAccidentRate(item.accident_water.all_count,
              item.accident_water.network_len))
        }
        rows.push(['По канализационным сетям', 'кол-во авар./км*год'])
        for (let item of this.reportData) {
          rows[rows.length - 1].push(this.getAccidentRate(item.accident_drainage.all_count,
              item.accident_drainage.network_len))
        }

        rows.push(['Очистка канализационной сети'])
        rows.push(['Протяженность промытой канализационной сети', 'м.'])
        for (let item of this.reportData) {
          rows[rows.length - 1].push(item.accident_drainage.washed_len)
        }
      }

      return rows;
    }
  },
  created() {
    this.$store.commit('reportsData/SET_REPORT_DATA', []);
  },
  methods: {
    getAccidentRate(accidentsCount, networkLength) {
      if (networkLength) {
        const accidentRate = accidentsCount / networkLength;
        return accidentRate.toFixed(2);
      }
      else return 0
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
