<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      Добавить расходный материал
      <span @click="$modal.hide('consumable-form')"
            class="base-popup__close">
        <icon-close/>
      </span>
    </div>
    <div class="popup-form-group">
      <div class="form-item">
        <div
          v-if="!file"
          class="file-input__dropzone-container"
          :class="{'dragover': isDragging}"
          @dragover="dragover"
          @dragleave="dragleave"
          @drop="drop"
        >
          <input
            @change="handleFileUpload"
            id="fileInput"
            ref="file"
            type="file"
            accept=".csv"
            class="file-input__hidden-input"
          >

          <label
            for="fileInput"
            class="file-input__label">
            <div class="file-input__text">
              Переместите файл в данную область <br> или выберите файл
            </div>
            <div class="button button--mode-outline">Выбрать файл</div>
          </label>
        </div>
        <div
          v-else
          class="file-preview">
          <div class="file-preview__body">
            <div class="file-preview__icon">
              <icon-document-text-colored/>
            </div>
            <div class="file-preview__info">
              <div class="file-preview__name">{{ fileCaption.name }}</div>
              <div class="file-preview__ext">{{fileCaption.extension}}, {{ fileSizeInKb }} kb</div>
            </div>
          </div>
          <div
            @click="file = null"
            class="file-preview__delete">
            <icon-trash/>
          </div>
        </div>
      </div>
      <a
        href="https://static.masis05.ru/general/materials_templ.csv"
        class="download-template-text">Скачать шаблон CSV-файла</a>
    </div>
    <div class="base-popup__footer">
      <div @click="submitForm()"
           class="button">
        Добавить
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from "@/components/Icons/IconClose.vue";
import {showAlert, showToast} from "@/utils/notification";
import IconTrash from "@/components/Icons/IconTrash.vue";
import IconDocumentTextColored from "@/components/Icons/IconDocumentTextColored.vue";

export default {
  name: "ConsumablesForm",
  components: {IconDocumentTextColored, IconTrash, IconClose},
  props: {
    consumable: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isDragging: false,
      file: null,
      formData: null
    }
  },
  computed: {
    fileSizeInKb() {
      const fileSizeInKb = this.file.size / 1024;
      return fileSizeInKb.toFixed(2);
    },
    fileCaption() {
      const [name, extension] = this.file.name.split('.');
      return {name, extension};
    }
  },
  methods: {
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.isDragging = false;
    },
    handleFileUpload() {
      const [file] = this.$refs.file.files;
      this.file = file;
      this.formData = new FormData();
      this.formData.append('csv_file', file);
    },
    submitForm() {
      this.$store.dispatch('consumables/uploadCsvFile', this.formData)
        .then(() => {
          showToast('CSV-файл загружен', 'success');
          this.$modal.hide('consumable-form');
          this.$store.dispatch('consumables/getConsumablesList');
        })
        .catch(error => {
          showAlert('Ошибка', error);
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.download-template-text {
  color: var(--text-link);
  cursor: pointer;
}
</style>
