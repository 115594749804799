<template>
  <multiselect
    class="base-select"
    :class="{'base-select--single': !multiple}"
    :value="processedValue"
    :options="options"
    :track-by="trackBy"
    :custom-label="getOptionLabel"
    :placeholder="placeholder"
    :disabled="disabled"
    :multiple="multiple"
    selectedLabel="Выбран"
    selectLabel="Выбрать"
    deselectLabel="Удалить"
    :taggable="false"
    :close-on-select="closeOnSelect"
    @input="updateValue"
  >
    <template v-slot:noResult>
      <span>{{noResults}}</span>
    </template>

    <template v-slot:selection="{values, isOpen}">
      <span
        class="multiselect__single"
        v-if="values.length"
        v-show="!isOpen">
        {{ values.length }} выбрано
      </span>
    </template>

  </multiselect>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  name: "BaseSelect",
  components: {
    Multiselect
  },
  props: {
    value: {
      default: null
    },
    options: {
      type: Array,
      required: true
    },
    reduce: {
      type: Function,
      required: true
    },
    getOptionLabel: {
      type: Function,
      default: option => option.name
    },
    placeholder: {
      type: String,
      default: 'Выберите из списка'
    },
    trackBy: {
      type: String,
      default: 'name'
    },
    noResults: {
      type: String,
      default: 'Не найдено результатов'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    processedValue() {
      let option;

      if (this.multiple) option = this.value.map(item => this.options.find(option => this.reduce(option) === item))
      else option = this.options.find(option => this.reduce(option) === this.value);

      return option
    }
  },
  methods: {
    updateValue(value) {
      let processedValue;

      if (this.multiple) processedValue = this.reduce ? value.map(item => this.reduce(item)) : value;
      else processedValue = this.reduce ? this.reduce(value) : value;

      this.$emit('input', processedValue);
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>

</style>
