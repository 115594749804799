<script>
export default {
  name: "IconDocumentTextColored"
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none">
    <path
      opacity="0.4"
      d="M37.584 18.6816H32.2857C27.9407 18.6816 24.4023 15.1433 24.4023 10.7983V5.49996C24.4023 4.49163 23.5773
      3.66663 22.569 3.66663H14.7957C9.14898 3.66663 4.58398 7.33329 4.58398 13.8783V30.1216C4.58398 36.6666 9.14898
       40.3333 14.7957 40.3333H29.2057C34.8523 40.3333 39.4173 36.6666 39.4173 30.1216V20.515C39.4173 19.5066 38.5923
        18.6816 37.584 18.6816Z"
      fill="#B9BAD5"/>
    <path
      d="M28.9674 4.05169C28.2157 3.30003 26.9141 3.81336 26.9141 4.85836V11.2567C26.9141 13.9334 29.1874 16.1517
      31.9557 16.1517C33.6974 16.17 36.1174 16.17 38.1891 16.17C39.2341 16.17 39.7841 14.9417 39.0507 14.2084C36.4107
       11.55 31.6807 6.76503 28.9674 4.05169Z"
      fill="#9192AD"/>
    <path
      d="M24.75 25.2084H13.75C12.9983 25.2084 12.375 24.585 12.375 23.8334C12.375 23.0817 12.9983 22.4584 13.75
       22.4584H24.75C25.5017 22.4584 26.125 23.0817 26.125 23.8334C26.125 24.585 25.5017 25.2084 24.75 25.2084Z"
      fill="#9192AD"/>
    <path
      d="M21.0833 32.5416H13.75C12.9983 32.5416 12.375 31.9183 12.375 31.1666C12.375 30.415 12.9983 29.7916 13.75
      29.7916H21.0833C21.835 29.7916 22.4583 30.415 22.4583 31.1666C22.4583 31.9183 21.835 32.5416 21.0833 32.5416Z"
      fill="#9192AD"/>
  </svg>
</template>

<style scoped>

</style>
