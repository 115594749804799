import BalanceService from "@/services/BalanceService";

export const namespaced = true;

export const state = {
    balance: [],
    loading: false,
    error: null,
    grouping: null
}

export const mutations = {
    SET_BALANCE(state, balance) {
        state.balance = balance;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_GROUPING(state, grouping) {
        state.grouping = grouping;
    },
    CLEAR_STATE(state) {
        state.balance = [];
        state.grouping = null;
    }
}

export const actions = {
    async getSupplyBalance({commit}, payload) {
        commit("SET_LOADING", true);
        let balance = await BalanceService.getSupplyBalance(payload)
        commit("SET_BALANCE", balance);
        commit("SET_LOADING", false);
    },
    async getDrainBalance({commit}, payload) {
        commit("SET_LOADING", true);
        let balance = await BalanceService.getDrainBalance(payload)
        commit("SET_BALANCE", balance);
        commit("SET_LOADING", false);
    }
}

export const getters = {
    balance: state => {
        return state.balance;
    },
    grouping: state => {
        return state.grouping;
    },
    balance_by_id: state => id => {
        return state.balance.find(item => item.id === id)
    },
    loading: state => {
        return state.loading;
    }
}