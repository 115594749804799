<template>
  <v-select
    class="vue-select-input-borderless"
    :value="appeal_id"
    :options="appealsList"
    :reduce="appeal => appeal.id"
    :get-option-label="(option) => option.id"
    :filter="filterByAddress"
    placeholder="Выберите из списка"
    @input="linkAppealToIssue"
  >
    <template #option="option">
      <div class="issue-select-option">
        <div class="issue-select-option__id">
          {{ option.id }}
        </div>
        <div class="issue-select-option__address">
          {{ option.address.street }}, {{ option.address.house }}
        </div>
        <div class="issue-select-option__time">
          {{ $moment(option.created_at).fromNow() }}
        </div>
        <div class="issue-select-option__checkbox">
          <label class="base-checkbox base-checkbox--squared">
            <input
              @click="linkAppealToIssue(option.id)"
              type="radio">
            <span/>
          </label>
        </div>
      </div>
    </template>
  </v-select>
</template>

<script>
import {mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";

export default {
  name: "RelatedAppealsForm",
  props: {
    issueId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      appeal_id: null,
      hideOptions: false
    }
  },
  computed: {
    ...mapState({
      appealsList: state => state.appeals.unallocatedAppeals
    })
  },
  mounted() {
    this.$store.dispatch('appeals/getAppealsByStatus', 1);
  },
  methods: {
    filterByAddress(appeals, queryText) {
      const searchText = queryText.toLowerCase();
      return appeals.filter(appeal => {
        const appealAddress = appeal.getFullAddress().toLowerCase();
        return appealAddress.includes(searchText);
      });
    },
    linkAppealToIssue(appealId) {
      this.appeal_id = appealId;
      this.hideOptions = true;
      const payload = {
        appeal_id: appealId,
        params: {
          issue_id: this.issueId
        }
      }
      this.$store.dispatch('appeals/linkAppealToIssue', payload)
        .then(() => {
          this.$store.dispatch('issueModal/getIssue', this.issueId);
          this.$store.dispatch('appeals/getAppealsByStatus', 1);
          showToast('Обращение прикреплено к заявке', 'success');
          this.appeal_id = null;
        })
        .catch(error => {
          showAlert('Ошибка', error, 'error');
        })
        .finally(() => {
          this.hideOptions = false;
          this.appeal_id = null;
        })
    }
  }
}
</script>

<style scoped>
.issue-select-option {
  padding: 8px 24px;
}
</style>
