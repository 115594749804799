<template>
  <div class="base-table base-table--issues">
    <div class="base-table__container">
      <div class="base-table__header base-table__header--tech-service-works">
        <div class="base-table__header-cell">#</div>
        <div class="base-table__header-cell">Наименование</div>
        <div class="base-table__header-cell"/>
      </div>

      <div v-for="technicalServiceWork in technicalServiceWorksList"
           :key="technicalServiceWork.id"
           class="base-table__row base-table__row--tech-service-works">
        <div class="base-table__column ">
          <div class="base-table__text">{{ technicalServiceWork.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ technicalServiceWork.name }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__icons">
            <div @click="updateTechnicalServiceWork(technicalServiceWork)">
              <icon-pencil class="icon-pencil"/>
            </div>
            <div @click="deleteTechnicalServiceWork(technicalServiceWork.id)">
              <icon-trash/>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import IconTrash from "@/components/Icons/IconTrash";
import IconPencil from "@/components/Icons/IconPencil";
import TechnicalServiceWorksModal from "@/components/Telemetry/admin/TechnicalService/TechnicalServiceWorksModal";
import {modalAdaptiveWidth} from "@/utils/helpers";
import {showConfirmationModal, showToast, showAlert} from "@/utils/notification";

export default {
  name: "TechnicalServiceWorksList",
  components: {IconPencil, IconTrash},
  props: {
    technicalServiceWorksList: {
      type: Array,
      required: true
    }
  },
  methods: {
    updateTechnicalServiceWork(technicalServiceWork) {
      this.$modal.show(
        TechnicalServiceWorksModal,
        {
          'technical-service-work': technicalServiceWork
        },
        {
          name: 'technical-service-work-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(600),
          scrollable: true
        }
      )
    },
    deleteTechnicalServiceWork(id) {
      showConfirmationModal('Вы действительно хотите удалить вид работ технического обслуживания?')
          .then(result => {
            if (result.isConfirmed) {
              this.$store.dispatch('technicalServiceWorks/deleteTechnicalServiceWork', id)
                  .then(() => {
                    this.$store.dispatch('technicalServiceWorks/getTechnicalServiceWorks');
                    showToast('Вид работ технического обслуживания удалён', 'success');
                  })
                  .catch(error => showAlert('Ошибка', error))
            }
          })
    }
  }
}
</script>

<style scoped>
.base-table__header--tech-service-works {
  grid-template-columns: 4.07% 1fr 8.13%;
}

.base-table__row--tech-service-works {
  grid-template-columns: 4.07% 1fr 8.13%;
}
</style>
