<template>
  <div class="problem-category-selector">
    <div class="tags-select">
      <div v-for="group in categoryGroups"
           :key="group.id"
           @click="group_id = group.id"
           class="tags-select__item"
           :class="{
             'selected': group_id === group.id,
             'tags-select__item--error': validation
           }">
        {{ group.name }}
      </div>
    </div>

    <v-select
      v-model="categoryId"
      class="vue-select-input"
      :class="{'vue-select-input--error': validation}"
      :options="categoriesByGroupId(group_id)"
      :reduce="category => category.id"
      :get-option-label="(option) => option.name"
      placeholder="Выберите проблему"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ProblemCategorySelector",
  props: {
    category_id: {
      required: true
    },
    validation: {
      required: false,
      default: false
    },
    group: {
      required: false,
      default: null,
      type: Number
    }
  },
  data() {
    return {
      group_id: null
    }
  },
  computed: {
    ...mapGetters({
      categoryGroups: 'serviceDeskCategories/getGroupsContainingCategories',
      categoriesByGroupId: 'serviceDeskCategories/getActiveCategoriesByGroupId'
    }),
    categoryId: {
      get() {
        return this.category_id
      },
      set(value) {
        this.$emit('update:category_id', value);
      }
    }
  },
  watch: {
    group_id: function (groupId) {
      this.$emit('update:group_id', groupId);
    },
    group: function (val) {
      this.group_id = val;
    }
  },
  mounted() {
    this.$store.dispatch('serviceDeskCategories/getAllCategories');
    this.$store.dispatch('serviceDeskCategories/getCategoryGroups');
  }
}
</script>

<style lang="scss" scoped>

.problem-category-selector {
  display: flex;
  flex-direction: column;
  gap: 16px;

}
</style>
