<template>
  <div class="task-violations-list">
    <div
      v-for="violation in violationsList"
      :key="violation.id"
      class="task-violation">
      <div class="base-modal-info__item">
        <div class="base-modal-info__name">
          <span>Тип дефекта/нарушения</span>
        </div>
        <div class="base-modal-info__value">
          <p class="base-modal-info__value-txt">
            {{ violation.violation.name }}
          </p>
        </div>
      </div>

      <div class="base-modal-info__item">
        <div class="base-modal-info__name">
          <span>Статус</span>
        </div>
        <div class="base-modal-info__value">
          <div
            v-if="!violation.finishedDate && !violation.canceledDate"
            class="task-violation__state task-violation__state--not-eliminated">
            Не устранено
          </div>

          <div
            v-if="violation.finishedDate"
            class="task-violation__state task-violation__state--eliminated">
            Устранено
          </div>

          <div
            v-if="violation.canceledDate"
            class="task-violation__state task-violation__state--canceled">
            Отменено
          </div>
        </div>
      </div>

      <div
        v-for="field in violation.fields"
        :key="field.id"
        class="base-modal-info__item">
        <div class="base-modal-info__name">
          <span>{{field.field.name}}</span>
        </div>
        <div class="base-modal-info__value">
          <p class="base-modal-info__value-txt">
            {{ field.value }}
          </p>
        </div>
      </div>

      <div class="base-modal-info__item">
        <div class="base-modal-info__name">
          <span>Описание</span>
        </div>
        <div class="base-modal-info__value">
          <p class="base-modal-info__value-message">
            {{ violation.description }}
          </p>
        </div>
      </div>

      <div
        v-if="violation.attachments.length"
        class="base-modal-info__item">
        <div class="base-modal-info__name">
          <span>Фото и видео</span>
        </div>
        <div class="base-modal-info__value">
          <files-list
            :files-list="violation.attachments"
            :max-length="6"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FilesList from "@/components/ServiceDesk/CommonComponents/FilesList/FilesList.vue";

export default {
  name: "ViolationsList",
  components: {FilesList},
  props: {
    violationsList: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.task-violations-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.task-violation {
  border-radius: 16px;
  border: 1px solid var(--border-main);
  background: var(--bg-main);
  padding: 24px 16px;

  &__state {
    display: inline-block;
    padding: 4px 12px;
    border-radius: 8px;
    font-size: 16px;

    &--not-eliminated {
      background-color: var(--bg-light-orange);
    }

    &--eliminated {
      background-color: var(--bg-light-green);
    }

    &--canceled {
      background-color: var(--bg-light-red);
    }
  }
}
</style>