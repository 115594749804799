<template>
  <div class="base-modal-info__item base-modal-info__item--border">
    <div class="base-modal-info__name">
      <icon-clip-circle/>
      <span>Фото и видео</span>
    </div>
    <div class="base-modal-info__value">
      <div class="task-attachments">
        <div
          v-if="isActionsVisible"
          class="task-attachments__add-button">
          <input
            @change="handleFileUpload()"
            id="taskFile"
            ref="taskFile"
            type="file"
            accept=".pdf,.png,.jpg,.mpeg,.mp4,.ogg,.webm,.avi"
            class="file-input__hidden-input"
          >
          <label
            for="taskFile">
            Прикрепить фото или видео-файл
          </label>
        </div>
        <div class="task-attachments__list">
          <files-list
            :files-list="filesList"
            :is-delete-icon-shown="true"
            @delete-file="deleteFile"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconClipCircle from "@/components/Icons/IconClipCircle";
import {showAlert, showToast, showConfirmationModal} from "@/utils/notification";
import FilesList from "@/components/ServiceDesk/CommonComponents/FilesList/FilesList.vue";

export default {
  name: "TaskFilesList",
  components: {FilesList, IconClipCircle},
  props: {
    taskId: {
      type: Number,
      required: true
    },
    filesList: {
      type: Array,
      required: true
    },
    isActionsVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      payload: {
        task_id: null,
        formData: null
      },
      file: null
    }
  },
  methods: {
    handleFileUpload() {
      const [file] = this.$refs.taskFile.files;
      this.file = file;

      this.payload.task_id = this.taskId;
      this.payload.formData = new FormData();
      this.payload.formData.append('file', this.file);
      this.payload.formData.append('name', this.file.name);

      this.$store.dispatch('task/addFile', this.payload)
        .then(() => {
          this.file = null;
          this.payload = {
            task_id: null,
            formData: null
          }

          showToast('Файл добавлен', 'success');
          this.$store.dispatch('taskModal/getAttachmentsList', this.taskId);
        })
        .catch(error => {
          showAlert('Ошибка', error);
        })
    },
    deleteFile(fileId) {
      showConfirmationModal('Вы уверены, что хотите удалить файл?')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('task/deleteFile', {
              taskId: this.taskId,
              fileId
            })
              .then(() => {
                this.$store.commit('taskModal/DELETE_ATTACHMENT', fileId);
                showToast('Файл удалён', 'success');
              })
              .catch(error => showAlert('Ошибка', error, 'error'))
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.task-attachments {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__add-button {
    & input {
      opacity: 0;
      overflow: hidden;
      position: absolute;
      width: 1px;
      height: 1px;
    }

    & label {
      cursor: pointer;
      margin-bottom: 0;

      @include font-style(400, 16px, 24px, var(--text-link));
    }
  }

  &__list {
    width: 100%;
  }
}
</style>
