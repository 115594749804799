import * as API from './API';
import BalanceSheet from "@/models/telemetry/BalanceSheet";
import {getError} from "@/utils/helpers";

export default {
    fetchBalanceSheetsList() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/balance-sheet-affiliations`)
                .then(response => resolve(response.data.data.map(item => new BalanceSheet({
                    id: item.id,
                    ...item.attributes
                }))))
                .catch(error => reject(getError(error)))
        })
    },
    fetchBalanceSheet(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/balance-sheet-affiliations/${id}`)
                .then(response => resolve(new BalanceSheet({
                    id: response.data.data.id,
                    ...response.data.data.attributes
                })))
                .catch(error => reject(getError(error)))
        })
    },
    createBalanceSheet(payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`/balance-sheet-affiliations`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateBalanceSheet(id, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/balance-sheet-affiliations/${id}`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteBalanceSheet(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`/balance-sheet-affiliations/${id}`)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}