<template>
  <div class="page-wrapper">
    <base-panel
      title="Оповещения об отключениях"
      add-button-name="Создать оповещение"
      @add-button-clicked="showNotificationFormModal()"
    />

    <div class="tabs">
      <div class="tabs__header tabs__header--block">
        <div
          @click="notificationsState = NotificationClass.STATE_SENT"
          :class="{'active': notificationsState === NotificationClass.STATE_SENT}"
          class="tabs__header-item">
          Отправленные
        </div>
        <div
          @click="notificationsState = NotificationClass.STATE_DRAFT"
          :class="{'active': notificationsState === NotificationClass.STATE_DRAFT}"
          class="tabs__header-item">
          Черновики
        </div>
      </div>

      <div class="tabs__item active">
        <shutdown-notifications-list
          v-if="notificationsByState(notificationsState).length"
          :notifications-list="notificationsByState(notificationsState)"
          @show-notification-modal="showNotificationModal"
          @edit-notification="showNotificationFormModal"
          @delete-notification="deleteNotification"
        />

        <base-no-data v-else/>
      </div>
    </div>
  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel.vue";
import {mapGetters} from "vuex";
import ShutdownNotificationsList from "@/components/ServiceDesk/SutdownNotifications/ShutdownNotificationsList.vue";
import ShutdownNotification from "@/models/serviceDesk/ShutdownNotification";
import {showConfirmationModal, showAlert, showToast} from "@/utils/notification";
import BaseNoData from "@/components/BaseNoData.vue";

export default {
  name: "ShutdownNotificationsPage",
  components: {BaseNoData, ShutdownNotificationsList, BasePanel},
  data() {
    return {
      notificationsState: ShutdownNotification.STATE_SENT
    }
  },
  computed: {
    ...mapGetters({
      notificationsByState: "shutdownNotifications/getShutdownNotificationsByState"
    }),
    NotificationClass: () => ShutdownNotification
  },
  mounted() {
    this.$store.dispatch('shutdownNotifications/getShutdownNotificationsList');
    this.$store.dispatch('baseMap/getLayers');
  },
  methods: {
    showNotificationModal(notification) {
      this.$store.dispatch('baseSideModal/openModal', {
        modalContentType: 'shutdownNotification',
        propsData: notification
      });
    },
    showNotificationFormModal(notification = {}) {
      this.$store.dispatch('baseSideModal/openModal', {
        modalContentType: 'shutdownNotificationForm',
        propsData: notification
      });
    },
    deleteNotification(id) {
      showConfirmationModal('Вы уверены, что хотите удалить оповещение? Это действие невозвратно.')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('shutdownNotifications/deleteShutdownNotification', id)
              .then(() => {
                this.$store.commit('shutdownNotifications/DELETE_SHUTDOWN_NOTIFICATION', id);
                showToast('Оповещение удалено', 'success');
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style scoped>

</style>