<template>
  <div class="page-wrapper">
    <base-panel
      title="Типы объектов сети"
      :is-add-button-visible="true"
      add-button-name="Добавить тип объекта сети"
      @add-button-clicked="showPopup()"
    />

    <base-functional-line
      @search-change="event => $store.commit('networkObjectTypes/SET_SEARCH_QUERY', event.target.value)">
      <network-object-types-list-filter />
    </base-functional-line>

    <network-object-types-list
      :network-object-types-list="networkObjectTypes"
      @edit-network-object-type="showPopup"
      @delete-network-object-type="deleteNetworkObject"
    />

    <base-content-loaders v-if="loading && !networkObjectTypes.length" />

    <base-no-data v-if="!loading && !networkObjectTypes.length" />
  </div>
</template>

<script>
import NetworkObjectTypesList from "@/components/Telemetry/admin/NetworkObjectTypesList";
import {mapGetters, mapState} from "vuex";
import BasePanel from "@/components/BasePanel.vue";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import {showAlert, showToast, showConfirmationModal} from "@/utils/notification";
import NetworkObjectTypesForm from "@/components/Telemetry/admin/NetworkObjectTypesForm.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import BaseFunctionalLine from "@/components/BaseFunctionalLine.vue";
import NetworkObjectTypesListFilter from "@/views/Telemetry/admin/NetworkObjectTypesListFilter.vue";
export default {
  name: "NetworkObjectTypesListPage",
  components: {
    NetworkObjectTypesListFilter,
    BaseFunctionalLine, BaseNoData, BaseContentLoaders, BasePanel, NetworkObjectTypesList
  },
  computed: {
    ...mapState({
      loading: state => state.networkObjectTypes.loading,
      networkObjectTypeGroupsList: state => state.networkObjectTypes.networkObjectTypeGroups
    }),
    ...mapGetters({
      networkObjectTypes: 'networkObjectTypes/getFilteredNetworkObjectTypesList'
    })
  },
  mounted() {
    this.$store.dispatch('networkObjectTypes/getNetworkObjectTypeGroups');
    this.$store.dispatch('networkObjectTypes/getNetworkObjectTypesList');
  },
  methods: {
    showPopup(networkObjectType = {}) {
      this.$modal.show(
        NetworkObjectTypesForm,
        {
          'networkObjectType': networkObjectType,
          'networkObjectTypeGroups': this.networkObjectTypeGroupsList,
          'createNetworkObjectType': this.createNetworkObject,
          'updateNetworkObjectType': this.updateNetworkObject
        },
        {
          name: 'network-object-type-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        })
    },
    createNetworkObject(payload) {
      this.$store.dispatch('networkObjectTypes/createNetworkObjectType', payload)
        .then(() => {
          this.$store.dispatch('networkObjectTypes/getNetworkObjectTypesList');
          this.$modal.hide('network-object-type-form');
          showToast('Тип объекта сети добавлен', 'success');
        })
        .catch(error => showAlert('Ошибка', error));
    },
    updateNetworkObject(networkObjectTypeId, payload) {
      this.$store.dispatch('networkObjectTypes/updateNetworkObjectType', {
        typeId: networkObjectTypeId,
        payload
      })
        .then(() => {
          this.$store.dispatch('networkObjectTypes/getNetworkObjectTypesList');
          this.$modal.hide('network-object-type-form');
          showToast('Тип объекта сети обновлён', 'success');
        })
        .catch(error => showAlert('Ошибка', error));
    },
    deleteNetworkObject(networkObjectId) {
      showConfirmationModal('Вы уверены, что хотите удалить тип объекта сети? Это действие невозвратно.')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('networkObjectTypes/deleteNetworkObjectType', networkObjectId)
              .then(() => {
                showToast('Тип объекта сети удалён', 'success');
                this.$store.commit("networkObjectTypes/DELETE_NETWORK_OBJECT_TYPE", networkObjectId);
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style scoped>

</style>