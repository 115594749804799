<template>
  <div class="personal-list-object-popup">
    <div class="personal-list-object-popup__title">{{networkObject.name}}</div>
    <div
      v-for="equipment in networkObject.equipments"
      :key="equipment.id"
      class="personal-list-object-popup__equipment">
      <div class="personal-list-object-popup__equipment-name">{{equipment.name}}</div>
      <div class="personal-list-object-popup__parameters-wrapper">
        <personal-list-parameter
          v-for="parameter in equipment.parameters"
          :key="parameter.id"
          :parameter="parameter"
          :show-reading-at-date="true"
          :is-dark-theme="true"
          :parameter-value="parameterValue(parameter.id)"
          :parameter-profile="parameterProfile(parameter.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PersonalListParameter from "@/components/Telemetry/PersonalLists/PersonalListParameter.vue";
import store from "@/store";

export default {
  name: "PersonalListNetworkObjectMapPopup",
  components: {PersonalListParameter},
  props: {
    networkObject: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    parameterValue(parameterId) {
      return store.getters['telemetry/getParameterValue'](parameterId)
    },
    parameterProfile(parameterId) {
      return store.getters['telemetry/getParameterProfile'](parameterId)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.personal-list-object-popup {
  border-radius: 4px;
  border: 1px solid var(--border-dark-grey);
  background: var(--bg-sidebar);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.25);
  padding: 12px;

  width: max-content;
  max-width: 320px;

  &__title {
    padding-bottom: 12px;
    border-bottom: 1px solid var(--border-dark-grey);
    @include font-style(600, 14px, 16px, var(--text-tertiary));
  }

  &__equipment {
    padding-top: 12px;

    &:not(:last-child) {
      border-bottom: 1px solid var(--border-dark-grey);
      padding-bottom: 12px;
    }

    &-name {
      margin-bottom: 8px;
      @include font-style(600, 12px, 14px, var(--text-tertiary));
    }
  }

  &__parameters-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
}
</style>