<template>
  <div class="telemetry-list">
    <base-content-loaders v-if="loading" />

    <base-no-data v-if="!selectListMethod.length && !loading"/>

    <draggable
      v-model="selectListMethod"
      :disabled="$isMobile()">
      <div
        v-for="networkObject in selectListMethod"
        :key="networkObject.id"
        v-show="networkObject.showInList && networkObjectEquipments(networkObject.id).length"
        class="grab">
        <div class="network_object__item mb-2">
          <div class="network_object__header">
            <div
              class="network_object__name"
              @click="$store.commit('baseMap/SET_CENTER_ZOOM', {
                center: networkObject.coordinates,
                zoom: 18
              })">
              {{ networkObject.name }}
            </div>
            <div class="control-wrapper">
              <div @click="handleInputChange('show_in_list', networkObject.id)"
                   class="vehicle-control-card">
                <img v-if="networkObject.showInList"
                     alt="Показан"
                     src="@/assets/img/transport/listActive.svg">
                <img v-else
                     alt="Скрыт"
                     src="@/assets/img/transport/list.svg">
              </div>
              <div @click="handleInputChange('show_on_map', networkObject.id)"
                   class="vehicle-control-card">
                <img v-if="networkObject.showOnMap"
                     alt="Показан"
                     src="@/assets/img/transport/hideMapActive.svg">
                <img v-else
                     alt="Скрыт"
                     src="@/assets/img/transport/hideMap.svg">
              </div>
              <div @click="handleInputChange('fix_on_map', networkObject.id)"
                   class="vehicle-control-card">
                <img v-if="networkObject.fixOnMap"
                     alt="Показан"
                     src="@/assets/img/transport/fixMapActive.svg">
                <img v-else
                     alt="Скрыт"
                     src="@/assets/img/transport/fixMap.svg">
              </div>
            </div>
          </div>
          <span
            @click="showNetworkObjectInfoPopup(networkObject)"
            class="device-detail-button">
            Подробнее
            <img
              src="@/assets/img/transport/more-detailed.svg"
              alt="Подробнее"
            >
          </span>
        </div>

        <network-equipment-item
          v-for="equipment in networkObjectEquipments(networkObject.id)"
          :key="equipment.id"
          class="grab"
          :network-object="networkObject"
          :equipment="equipment"
          :coords="getNetworkObject(equipment.networkObjectId).coordinates"
        />
      </div>
    </draggable>

  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {telemetryLS} from "@/services/localStorageService";
import draggable from 'vuedraggable';
import NetworkEquipmentItem from "@/components/Telemetry/NetworkEquipmentItem";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import NetworkObjectInfoPopupContent from "@/components/Telemetry/NetworkObjectInfoPopupContent.vue";

export default {
  name: "NetworkObjectsList",
  components: {BaseNoData, BaseContentLoaders, draggable, NetworkEquipmentItem},
  props: {
    activeTab: {
      required: true
    },
    query: {
      required: false
    }
  },
  data() {
    return {
      sortList: []
    }
  },
  computed: {
    ...mapState({
      loading: state => state.telemetry.loading
    }),
    ...mapGetters({
      networkObjectsByType: 'telemetry/getNetworkObjectsByType',
      networkObjectEquipments: 'telemetry/getNetworkEquipmentByNetworkObjectId',
      getNetworkObject: 'telemetry/getNetworkObject'
    }),
    selectListMethod: {
      get: function () {
        const sortList = this.networkObjectsByType(this.activeTab)
          .concat()
          .sort((a, b) => this.sortList.indexOf(a.id) - this.sortList.indexOf(b.id));

        return this.filterByName(this.filterByTypeId(sortList, this.query.typeId), this.query.name);
      },
      set: function (value) {
        let arr = value.map(v => v.id);
        telemetryLS.setArray(this.activeTab, arr);
        this.sortList = arr;
      }
    }
  },
  watch: {
    activeTab: function (type) {
      this.sortList = telemetryLS.getArray(type);
    }
  },
  mounted() {
    this.sortList = telemetryLS.getArray(1);
  },
  methods: {
    showNetworkObjectInfoPopup(networkObject) {
      this.$modal.show(
        NetworkObjectInfoPopupContent,
        {
          'networkObject': networkObject,
          'popupName': 'network-object-info-modal'
        },
        {
          name: 'network-object-info-modal',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        })
    },
    filterByName(array, name) {
      return (name && name.length) ? array.filter(networkObject => networkObject.name.toLowerCase()
        .includes(name.toLowerCase())) : array
    },
    filterByTypeId(array, typeId) {
      return typeId ? array.filter(networkObject => networkObject._type.id === typeId) : array;
    },
    handleInputChange(type, id) {
      switch (type) {
        case 'show_in_list':
          this.$store.commit('telemetry/TOGGLE_NETWORK_OBJECT_IN_LIST', id)
          break;
        case 'show_on_map':
          this.$store.commit('telemetry/TOGGLE_NETWORK_OBJECT_ON_MAP', id)
          break;
        case 'fix_on_map':
          this.$store.commit('telemetry/TOGGLE_NETWORK_OBJECT_FIX_MAP', id)
          break;
      }
    }
  }
}
</script>

<style scoped>
.telemetry-list {
  overflow-y: auto;
  box-shadow: none;
  max-height: calc(100vh - 125px);
  padding: 0 !important;
  margin-top: 20px;
}

.telemetry-list::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: var(--panel-bg);
  border-radius: 0.2em;
}

.telemetry-list::-webkit-scrollbar-thumb {
  background-color: rgba(151, 172, 181, 1);
  border-radius: 0.2em;
}

.grab {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.grab:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.grab:hover .grab:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.device_name {
  padding-left: 30px;
}

.min-w {
  min-width: 370px;
}

.activeConstructionType {
  border: 2px solid #1F9BCF;
}

.pr-24 {
  padding-right: 24px;
}

.settings-block {
  height: auto !important;
}

.overflow-unset {
  overflow: unset !important;
}

.tab-pane {
  min-height: 30px;
}

.network_object__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.network_object__name {
  color: var(--text-primary);
  font-weight: 600;
  cursor: alias;
}

.control-wrapper {
  display: flex;
  gap: 4px;
  padding-right: 20px;
}

.vehicle-control-card {
  display: flex;
  cursor: pointer;
}

.vehicle-control-card:nth-last-child(1) {
  margin-right: 0;
}

.device-detail-button {
  font-size: 10px;
  font-style: italic;
  color: var(--text-link);
  display: block;
  cursor: pointer;
  display: flex;
  gap: 4px;
}

.device-table-rows .row {
  border: 1px solid #C7D3D9;
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 10px 15px;
}

.device-table-name {
  font-size: 14px;
  color: var(--text-link);
  font-weight: 600;
  border-right: 1px solid #C7D3D9;
}

.device-table-value {
  font-size: 14px;
  color: var(--text-link);
  font-weight: 700;
}

@media (max-width: 1100px) {
  .nav-link {
    padding: 0.75rem 0.38rem !important;
  }

  .tab_img {
    height: 24px;
  }
}

@media (min-width: 1500px) and (max-width: 1700px) {
  .nav-link {
    padding: 0.75rem 0.6rem !important;
  }
}

@media (min-width: 1100px) and (max-width: 1490px) {
  .nav-link {
    padding: 0.75rem 0.28rem !important;
  }

  .tab_img {
    height: 24px;
  }
}

@media (max-width: 768px) {
  .const_name {
    font-size: 14px;
  }

  .device_name {
    padding-left: 10px;
  }

  .table td {
    padding: 0.5rem;
  }

  .table th {
    padding: 0.55rem;
  }

  .tab-content {
    margin-bottom: 20px;
  }
}

@media (max-width: 450px) {
  .telemetry-tab-content {
    max-height: 500px;
  }
}
</style>
