<template>
  <main class="login-template">
    <slot />
  </main>
</template>

<script>
export default {
  name: "LoginTemplate"
}
</script>

<style scoped>
.login-template {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>