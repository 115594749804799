<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      Новое СММ
      <span @click="$modal.hide('mechanical-equipment-form')"
            class="base-popup__close">
        <icon-close />
      </span>
    </div>
    <div class="popup-form-group">
      <div class="form-item">
        <div class="form-item__name">СММ</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.equipment_id"
            :options="mechanicalEquipments"
            :reduce="equipment => equipment.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите из списка"
            class="vue-select-input"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Время работы, часы</div>
        <div class="form-item__value">
          <input
            v-model="payload.duration"
            class="base-input base-input--bordered"
            type="text"
            name="material"
            placeholder="Укажите время работы">
        </div>
      </div>
    </div>
    <div class="base-popup__footer">
      <div @click="submitForm()"
           class="button">
        Добавить
      </div>
    </div>
  </div>
</template>

<script>
import {showAlert, showToast} from "@/utils/notification";
import IconClose from "@/components/Icons/IconClose";

export default {
  name: "MechanicalEquipmentForm",
  components: {IconClose},
  props: {
    mechanicalEquipments: {
      type: Array,
      required: true
    },
    taskId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      payload: {
        equipment_id: null,
        duration: null
      }
    }
  },
  methods: {
    submitForm() {
      this.$store.dispatch('taskMechanicalEquipments/addMechanicalEquipmentToTask', {
        taskId: this.taskId,
        payload: this.payload
      })
        .then(() => {
          this.$store.dispatch('taskModal/getMechanicalEquipmentsList', this.taskId);
          showToast('Средство малой механизации добавлено в задание', 'success');
          this.$modal.hide('mechanical-equipment-form');
        })
        .catch(error => showAlert('Ошибка', error, 'error'));
    }
  }
}
</script>

<style scoped>

</style>
