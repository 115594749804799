<template>
  <div class="create-appeal-map">
    <base-map
      :center="center"
      :zoom="zoom"
      :base-layers="baseLayers"
      :markers="marker"
      id-key="-create-appeal"
    />
  </div>
</template>

<script>
import BaseMap from "@/components/BaseMap";
import Marker from "@/models/baseMap/Marker";
import Icon from "@/models/baseMap/Icon";
import {mapState} from "vuex";
import {showToast} from "@/utils/notification";

export default {
  name: "CreateAppealMap",
  components: {BaseMap},
  props: {
    markerCoords: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      marker: []
    }
  },
  computed: {
    ...mapState({
      center: state => state.baseMap.center,
      zoom: state => state.baseMap.zoom,
      baseLayers: state => state.baseLayers.baseLayers
    })
  },
  watch: {
    markerCoords: function (coords) {
      this.createMarker(coords);
    }
  },
  mounted() {
    this.$root.$on('fit-bounds-map', dadataCallback => {
      let qc_geo = parseInt(dadataCallback.qc_geo)
      if (qc_geo <= 1) {
        this.$store.commit('baseMap/SET_CENTER_ZOOM', {
          center: dadataCallback.coords,
          zoom: 18
        })
        this.createMarker(dadataCallback.coords);

      } else if (qc_geo > 1 && qc_geo < 4) {
        this.$store.commit('baseMap/SET_CENTER_ZOOM', {
          center: dadataCallback.coords,
          zoom: 16
        })
        this.createMarker(dadataCallback.coords);

      } else {
        showToast('Координаты по указанному адресу не найдены, поставьте отметку вручную', 'warning')
      }
    })

    this.$root.$on('BaseMap::map-click', ({coords}) => {
      this.$emit('update:coords', coords);
      this.createMarker(coords);
    })
  },
  methods: {
    createMarker(coords) {
      this.marker = [
        new Marker({
          coords: coords,
          icon: new Icon({
            type: 'icon',
            url: require('@/assets/img/icons/pin-low.svg'),
            size: [36, 36],
            anchor: [18, 36]
          })
        })
      ]
    }
  }
}
</script>

<style scoped>
.create-appeal-map {
  height: 300px;
  position: relative;
  z-index: 1;
  margin-bottom: 12px;
}
</style>
