<template>
  <div class="transport-map-icon__label">
    <div class="transport-map-icon__label-name">
      <span @click="openTransportModal(vehicle.id)">{{ vehicle.attributes.label }}</span>
      <span>{{ vehicle.attributes.gov_number }}</span>
    </div>
    <div class="transport-map-icon__label-speed">
      <transport-speed
        :transport-speed="vehicleSpeed"
        :id="vehicle.id"
        :white-speed="true"/>
    </div>
  </div>
</template>

<script>
import TransportSpeed from "@/components/Transport/TransportSpeed.vue";
import Vehicle from "@/models/transport/Vehicle";
import store from "@/store";

export default {
  name: "TransportMapPopup",
  components: {TransportSpeed},
  props: {
    vehicle: {
      type: Vehicle,
      default: () => {
      }
    }
  },
  computed: {
    vehicleSpeed() {
      return store.getters["transportData/getSpeed"](this.vehicle.id);
    }
  },
  methods: {
    openTransportModal(vehicleId) {
      store.dispatch('baseSideModal/openModal', {
        modalContentType: 'transport',
        entityId: vehicleId
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.transport-map-icon {
  &__label {
    display: flex;
    flex-direction: column;
    gap: 4px;

    padding: 4px;
    border-radius: 4px;
    border: 1px solid var(--border-dark-grey);
    background: var(--bg-sidebar);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.20);
  }

  &__label-name {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 20px;

    & span:first-child {
      cursor: pointer;
      text-wrap: nowrap;
      @include font-style(400, 10px, 12px, var(--text-panel));
    }

    & span:last-child {
      text-wrap: nowrap;
      @include font-style(600, 10px, 12px, var(--text-tertiary));
    }
  }
}
</style>
