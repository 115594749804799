export default class SemanticProvider {
    constructor(params) {
        this.id = params.id;
        this.name = params.name;
        this.typeId = params.provider_type.id;
        this.typeName = params.provider_type.name;
        this.serverUrl = params.server_settings?.url;
        this.serverLogin = params.server_settings?.login;
        this.serverPassword = params.server_settings?.password;
    }
}