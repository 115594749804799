export const namespaced = true;
export const state = {
    isActiveSidebar: false
}
export const getters = {
    getIsActiveSidebar: state => {
        return state.isActiveSidebar
    }
}
export const mutations = {
    TOGGLE_SIDEBAR(state) {
        state.isActiveSidebar = !state.isActiveSidebar
    },
    CHANGE_SIDEBAR_STATE(state, value) {
        state.isActiveSidebar = value
    }
}
export const actions = {
    togledSidebar({ commit }) {
        commit("TOGGLE_SIDEBAR")
    }
}

