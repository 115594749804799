<template>
  <div class="base-table base-table--issues">
    <div class="base-table__container">
      <div class="base-table__header base-table--archive-issues">
        <div @click="isAscSort = !isAscSort; sortIssues()"
             class="base-table__header-cell base-table__header-cell-sort"
             :class="{'toggled': !isAscSort}">
          ID
          <icon-sort />
        </div>
        <div class="base-table__header-cell">Дата открытия</div>
        <div class="base-table__header-cell">Дата закрытия</div>
        <div class="base-table__header-cell">Проблема</div>
        <div class="base-table__header-cell">Адрес</div>
        <div class="base-table__header-cell base-table__header-cell--center">Обращений</div>
        <div class="base-table__header-cell">Тип</div>
        <div class="base-table__header-cell">Статус</div>
        <div class="base-table__header-cell"/>
      </div>

      <div v-for="issue in issuesList"
           :key="issue.id"
           class="base-table__row base-table--archive-issues"
           @click="openIssueModal(issue.id)">
        <div class="base-table__column base-table__column--number">
          <div class="base-table__num">{{ issue.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ formatDateTime(issue.created_at) }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ formatDateTime(issue.closed_at) }}</div>
        </div>
        <div class="base-table__column base-table__column--number">
          <div class="base-table__text base-table__tooltip-wrapper base-table__indicator">
            <span class="base-table__indicator-circle"
                  :style="{'background-color': issue.category.group_color}"/>
            {{ issue.category.name }}

            <div class="base-table__tooltip">
              {{issue.category.group_name}}
            </div>
          </div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{issue.getFullAddress()}}</div>
        </div>
        <div class="base-table__column base-table__column--appeals">
          <div class="base-table__text base-table__text--center">{{ issue.appeals_count }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text ">{{ issue.type_name }}</div>
        </div>
        <div class="base-table__column base-table__column--state">
          <div class="base-table__tag"
               :class="[
                 {'base-table__tag--new': issue.state.id === IssueState.NEW},
                 {'base-table__tag--work': issue.state.id === IssueState.IN_WORK},
                 {'base-table__tag--done': issue.state.id === IssueState.CLOSED},
                 {'base-table__tag--canceled': issue.state.id === IssueState.CANCELED}
               ]">
            {{ issue.state.label }}
          </div>
        </div>
        <div class="base-table__column base-table__column--info base-table__column--content-right">
          <span v-if="issue.description"
                class="base-table__short-info">
            <icon-comment class="base-table__short-info-icon"/>
            <span class="base-table__short-info-counter">1</span>

            <div class="base-table__short-info-tooltip">
              <div class="base-table__short-info-tooltip-inner">
                {{issue.description}}
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import IconSort from "@/components/Icons/IconSort.vue";
import IconComment from "@/components/Icons/IconComment.vue";
import IssueState from "@/models/serviceDesk/IssueState";
import sideModalControls from "@/mixins/sideModalControls";
import {formatDateTime} from "@/utils/filters";

export default {
  name: "ArchiveIssuesList",
  components: {IconComment, IconSort},
  mixins: [sideModalControls],
  props: {
    issuesList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isAscSort: true
    }
  },
  computed: {
    IssueState: () => IssueState
  },
  methods: {
    formatDateTime,
    sortIssues() {
      let sortDir;
      if (this.isAscSort) sortDir = 'asc'
      else sortDir = 'desc'

      this.$store.commit('issues/SET_FILTER_PARAM', {
        name: 'sort_dir',
        value: sortDir
      })
      this.$store.dispatch('issues/getIssues');
    }
  }
}
</script>

<style scoped>
.base-table--archive-issues {
  grid-template-columns: 4.07% 6.78% 6.78% 14.91% 16.26% 5.42% 13.55% 8.81% 1fr;
}
</style>
