<template>
  <div class="additional-option-wrapper"
       :class="{'always-open': isCollapseVisible}">
    <div class="additional-option">
      <div class="additional-option__icon">
        <icon-vehicle/>
      </div>
      <div class="additional-option__name">
        <span>Транспорт</span>
        <span class="additional-option__quantity">{{ transportOrders.length }}</span>
      </div>
      <div class="additional-option__buttons">
        <button
          v-show="isActionsVisible"
          class="additional-option__add"
          type="button"
          @click="showModal()">
          <icon-plus/>
        </button>
        <button
          @click="isCollapseVisible = !isCollapseVisible"
          class="additional-option__toggle"
          :class="{'rotate': isCollapseVisible}">
          <icon-arrow-double-up/>
        </button>
      </div>
    </div>
    <div class="additional-options-list">
      <div v-for="transportOrder in transportOrders"
           :key="transportOrder.id"
           class="additional-options-list__inner">
        <div class="added-item added-item--flex">
          <div class="added-item__info">
            <div v-if="transportOrder.transport"
                 class="added-item__name">
              {{ transportOrder.transport.name }} {{ transportOrder.transport.gov_number }}
            </div>
            <div v-else
                 class="added-item__name">
              {{ transportOrder.transportType.name }}
            </div>
            <div class="added-item__additional">
              Дата подачи: {{ transportOrder.plannedDatesCaption }}
            </div>
          </div>
          <div class="added-item__right">
            <span>{{ transportOrder.state.name }}</span>
            <span
              v-if="transportOrder.comment"
              class="base-table__short-info base-table__short-info--mr-0">
              <icon-comment class="base-table__short-info-icon"/>
              <span class="base-table__short-info-counter">1</span>

              <div class="base-table__short-info-tooltip">
                <div class="base-table__short-info-tooltip-inner">
                  {{ transportOrder.comment }}
                </div>
              </div>
            </span>
            <div
              v-if="transportOrder.state.id === 1 && isActionsVisible"
              @click="cancelTransportOrder(transportOrder.id)">
              <icon-trash class="svg added-item__tool"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconTrash from "@/components/Icons/IconTrash";
import {showAlert, showToast, showConfirmationModal} from "@/utils/notification";
import IconArrowDoubleUp from "@/components/Icons/IconArrowDoubleUp";
import IconPlus from "@/components/Icons/IconPlus";
import TransportOrdersForm from "@/components/ServiceDesk/Tasks/TaskComponents/TransportOrders/TransportOrdersForm";
import IconVehicle from "@/components/Icons/IconVehicle";
import IconComment from "@/components/Icons/IconComment.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";

export default {
  name: "TransportOrdersList",
  components: {IconComment, IconVehicle, IconPlus, IconArrowDoubleUp, IconTrash},
  props: {
    taskId: {
      type: Number,
      required: true
    },
    transportOrders: {
      type: Array,
      required: true
    },
    isActionsVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isCollapseVisible: false
    }
  },
  methods: {
    cancelTransportOrder(transportOrderId) {
      showConfirmationModal('Вы уверены, что хотите отменить заказ транспорта?', 'Да', 'Нет')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('transportOrders/cancelTransportOrder', transportOrderId)
              .then(() => {
                showToast('Заказ транспорта отменён', 'success');
                this.$store.commit('transportOrders/SET_FILTER_PARAMS', {
                  task_id: this.taskId
                });
                this.$store.dispatch('taskModal/getTransportOrdersList', this.taskId);
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    },
    showModal() {
      this.$modal.show(
        TransportOrdersForm,
        {
          'task-id': this.taskId
        },
        {
          name: 'transport-order-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(600)
        }
      )
    }
  }
}
</script>

<style scoped>

</style>
