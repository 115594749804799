<script>
export default {
  name: "IconSpeedDanger"
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none">
    <path
      d="M8.00004 14.6666C11.6819 14.6666 14.6667 11.6818 14.6667 7.99992C14.6667 4.31802 11.6819 1.33325 8.00004
      1.33325C4.31814 1.33325 1.33337 4.31802 1.33337 7.99992C1.33337 11.6818 4.31814 14.6666 8.00004 14.6666Z"
      fill="#FFF2F2"/>
    <path
      d="M8.00004 13.1666C5.15337 13.1666 2.83337 10.8466 2.83337 7.99992C2.83337 7.09325 3.07337 6.20658 3.52004
       5.42658C3.66004 5.18658 3.9667 5.10659 4.20003 5.23992L8.2467 7.56659C8.4867 7.70659 8.5667 8.00658 8.43337
       8.24658C8.29337 8.48658 7.99338 8.56658 7.75338 8.43325L4.16671 6.37325C3.95337 6.88658 3.84005 7.43992 3.84005
        7.99992C3.84005 10.2999 5.70671 12.1666 8.00671 12.1666C10.3067 12.1666 12.1734 10.2999 12.1734 7.99992C12.1734
         5.69992 10.3067 3.83325 8.00671 3.83325C7.10005 3.83325 6.23338 4.11992 5.50671 4.66659C5.28671 4.83325 4.97337
          4.78659 4.8067 4.56659C4.64004 4.34659 4.6867 4.03325 4.9067 3.86659C5.8067 3.19325 6.88005 2.83325 8.00671
          2.83325C10.8534 2.83325 13.1734 5.15325 13.1734 7.99992C13.1734 10.8466 10.8467 13.1666 8.00004 13.1666Z"
      fill="#FF3A81"/>
  </svg>
</template>

<style scoped>

</style>
