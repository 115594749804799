export default {
    computed: {
        getFormatDate: function () {
            return date => {
                return this.$moment(date).format('DD.MM.YY')
            }
        },
        getFormatHour: function() {
            return hour => {
                return this.$moment(hour).format('HH:mm')
            }
        }
    }
}