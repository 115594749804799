<script>
export default {
  name: "IconUserOctagon"
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none">
    <path
      d="M17.1366 5.18923L12.2191 2.34966C11.4161 1.88605 10.4226 1.88605 9.61132 2.34966L4.70209 5.18923C3.89906
      5.65283 3.40234 6.51381 3.40234 7.4493V13.1119C3.40234 14.0391 3.89906 14.9001 4.70209 15.372L9.6196
      18.2115C10.4226 18.6751 11.4161 18.6751 12.2274 18.2115L17.1449 15.372C17.9479 14.9083 18.4446 14.0474 18.4446
       13.1119V7.4493C18.4363 6.51381 17.9396 5.66111 17.1366 5.18923ZM10.9193 6.42275C11.9873 6.42275 12.8483 7.28372
        12.8483 8.35167C12.8483 9.41961 11.9873 10.2806 10.9193 10.2806C9.8514 10.2806 8.99042 9.41961 8.99042
        8.35167C8.99042 7.292 9.8514 6.42275 10.9193 6.42275ZM13.138 14.1384H8.70067C8.0301 14.1384 7.64101 13.3934
         8.01354 12.8387C8.57649 12.0025 9.66927 11.4396 10.9193 11.4396C12.1694 11.4396 13.2622 12.0025 13.8251
          12.8387C14.1977 13.3851 13.8003 14.1384 13.138 14.1384Z"
      fill="white"/>
  </svg>
</template>

<style scoped>

</style>