<template>
  <div class="page-wrapper">
    <base-panel
      title="Статистика по аварийным элементам"
      :is-add-button-visible="false"/>
    <emergency-elements-report-form :table-rows="tableRows" />
    <emergency-elements-report v-if="reportData.length" />
    <base-content-loaders v-if="loading" />
    <div v-if="!reportData.length && !loading"
         class="no-result">
      <img src="@/assets/img/no-result-2.png"
           alt="не сформирован отчёт">
      <div class="no-result__text">
        Сформируйте запрос, чтобы получить данные
      </div>
    </div>
  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel.vue";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import {mapState} from "vuex";
import EmergencyElementsReportForm from "@/components/ServiceDesk/ReportsForms/EmergencyElementsReportForm.vue";
import EmergencyElementsReport
  from "@/components/ServiceDesk/ReportsPreviews/EmergencyElementsReport/EmergencyElementsReport.vue";

export default {
  name: "EmergencyElementsPage",
  components: {EmergencyElementsReport, EmergencyElementsReportForm, BaseContentLoaders, BasePanel},
  data() {
    return {
      reportParams: {}
    }
  },
  computed: {
    ...mapState({
      reportData: state => state.reportsData.reportData,
      loading: state => state.reportsData.loading
    }),
    tableRows() {
      const rows = [];
      if (this.reportData.length) {
        rows.push(['ID','Сетевой элемент', 'Завершенные инциденты']);
        for (let element of this.reportData) {
          rows.push([element.id, element.networkElementId, element.countIncidents])
        }
      }
      return rows;
    }
  },
  created() {
    this.$store.commit('reportsData/SET_REPORT_DATA', []);
  },
  methods: {
    getData() {
      this.$store.dispatch('reportsData/getEmergencyElementsReportData', this.reportParams);
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
