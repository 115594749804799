<template>
  <div class="operational-scheme__map reset-leaflet-popup-styles">
    <base-map
      :center="center"
      :zoom="zoom"
      :base-layers="baseLayers"
      :layers="layers"
      :markers="setMarkers"
      :circles="setCircles"
      :polylines="setPolylines"
      :polygons="setPolygons"
      :bus="bus"
      @baseMap::change-layer="layerId => $store.commit('baseMap/SET_SELECTED_LAYER_ID', layerId)"
    />

    <div class="operation-data-switcher">
      <div class="operation-data-switcher__icon">
        <icon-flash/>
      </div>

      <div class="operation-data-switcher__content">
        <label
          v-for="item in operationDataList"
          :key="item.id"
          class="base-checkbox base-checkbox--squared">
          <input
            @click="item.shown = !item.shown"
            :checked="item.shown"
            type="radio">
          <span>{{ item.name }}</span>
        </label>
      </div>
    </div>

    <div v-show="!loading"
         class="operational-scheme__popup"
         :class="{'visible': networkElements.length}">
      <div class="operational-scheme__popup-inner">
        <div @click="$store.commit('networkElements/SET_ELEMENTS', [])"
             class="operational-scheme__close">
          <icon-close-modal/>
        </div>
        <div class="operational-scheme__popup-wrapper">
          <div v-for="element in networkElements"
               :key="element.id"
               class="operational-scheme__element">
            <div @click="openModal(element)"
                 class="operational-scheme__element-name">
              {{ element.type.name }} #{{ element.id }}
            </div>
            <div class="operational-scheme__element-action">
              <div
                class="i-select is-selected"
                :class="{'is-active': currentNetworkElementId === element.id}"
                @click.stop="currentNetworkElementId === element.id ?
                  currentNetworkElementId = null :
                  currentNetworkElementId = element.id"
              >
                <input class="i-select__input"
                       hidden="hidden"
                       type="text"
                       name="networkElementMode">
                <div class="i-select__main"
                     tabindex="0"
                     role="combobox"
                     aria-expanded="false">
                  <span class="i-select__selected"
                        data-placeholder="Выберите из списка">
                    {{ element.currentMode.name }}
                  </span>
                </div>
                <div class="i-select__arrow">
                  <icon-arrow/>
                </div>
                <div class="i-select__drop is-default-hidden">
                  <div class="i-select__drop-inner">
                    <ul class="i-select__list"
                        role="listbox">
                      <li
                        v-for="mode in element.availableModes"
                        :key="mode.id"
                        class="i-select__item"
                        :class="{'is-active': mode.id === element.currentMode.id}"
                        :data-value="mode.name"
                        role="option"
                        @click="changeElementMode(element, mode.id, mode.name)"
                      >
                        <div class='list-item operational-scheme-item'>
                          <span class='list-item__txt'>{{ mode.name }}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <network-element-modal
      :is-modal-active="isModalActive"
      :network-element="selectedNetworkElement"
      @close-modal="closeModalCard"/>
  </div>
</template>

<script>
import BaseMap from "@/components/BaseMap";
import {mapState, mapGetters} from "vuex";
import IconArrow from "@/components/Icons/IconArrow";
import {showAlert, showToast} from "@/utils/notification";
import Circle from "@/models/baseMap/Circle";
import Polyline from "@/models/baseMap/Polyline";
import Polygon from "@/models/baseMap/Polygon";
import IconCloseModal from "@/components/Icons/IconCloseModal";
import IconFlash from '@/components/Icons/IconFlash'
import LeakIcon from "@/components/ServiceDesk/OperationalScheme/LeakIcon";
import LeakPopupContent from "@/components/ServiceDesk/OperationalScheme/LeakPopupContent";
import Vue from "vue";
import Marker from "@/models/baseMap/Marker";
import Icon from "@/models/baseMap/Icon";
import NetworkElementModal from "@/components/ServiceDesk/OperationalScheme/NetworkElementModal.vue";
import IssueMapIcon from "@/components/ServiceDesk/Issues/IssueMapIcon.vue";
import IssueMapPopupContent from "@/components/ServiceDesk/Issues/IssueMapPopupContent.vue";
import TransportMapIcon from "@/components/Transport/TransportMapIcon.vue";
import TransportMapPopup from "@/components/Transport/TransportMapPopup.vue";
import ResourceIcon from "@/components/ServiceDesk/OperationalScheme/ResourceIcon.vue";
import ResourcePopup from "@/components/ServiceDesk/OperationalScheme/ResourcePopup.vue";

export default {
  name: "OperationalSchemeMap",
  components: {
    NetworkElementModal,
    IconFlash,
    IconCloseModal,
    IconArrow,
    BaseMap
  },
  props: {
    bus: {
      required: true
    }
  },
  data() {
    return {
      currentNetworkElementId: null,
      selectedNetworkElement: {},
      isModalActive: false,
      activeTab: 1,
      isOperationDataSwitcherVisible: false,
      operationDataList: [
        {id: 1, name: 'Утечки', shown: false},
        {id: 2, name: 'Заявки', shown: false},
        {id: 3, name: 'Транспорт', shown: false},
        {id: 4, name: 'Ресурсы', shown: false}
      ]
    }
  },
  computed: {
    ...mapState({
      center: state => state.baseMap.center,
      zoom: state => state.baseMap.zoom,
      baseLayers: state => state.baseLayers.baseLayers,
      networkElements: state => state.networkElements.elements,
      loading: state => state.networkElements.loading,
      layers: state => state.baseMap.layers,
      selectedLayerId: state => state.baseMap.selectedLayerId,
      leaksList: state => state.operationalSchemeData.leaksList,
      issuesList: state => state.issues.allIssues,
      transportsList: state => state.autos.autos,
      resourcesList: state => state.resources.resourcesList
    }),
    ...mapGetters({
      transportLat: 'transportData/getLat',
      transportLon: 'transportData/getLon'
    }),
    setMarkers: function () {
      const [leaksData, issuesData, transportData, resourcesData] = this.operationDataList;
      let leaks = [];
      let issues = [];
      let transport = [];
      let resources = [];

      if (leaksData.shown) {
        leaks = this.leaksList.map(leak => {
          const leakIconClass = Vue.extend(LeakIcon);
          const iconInstance = new leakIconClass();
          const icon = iconInstance.$mount();
          const iconHtml = icon.$el.outerHTML;

          const leakPopupClass = Vue.extend(LeakPopupContent);
          const popupContentInstance = new leakPopupClass();
          popupContentInstance.leak = leak;

          const popup = popupContentInstance.$mount();
          const popupContentHtml = popup.$el;

          const marker = new Marker({
            coords: leak.issueCoordinates,
            icon: new Icon({
              type: 'div_icon',
              size: [36, 42],
              anchor: [18, 21],
              html: iconHtml
            }),
            popup_content: popupContentHtml,
            accumulation: true
          })

          icon.$destroy();
          popup.$destroy();

          return marker;
        })
      }

      if (issuesData.shown) {
        const IconClass = Vue.extend(IssueMapIcon);
        const PopupClass = Vue.extend(IssueMapPopupContent);
        issues = this.issuesList.map(issue => {
          const iconInstance = new IconClass();
          iconInstance.issue = issue;
          const icon = iconInstance.$mount();

          const popupInstance = new PopupClass();
          popupInstance.issue = issue;
          const popup = popupInstance.$mount();

          const marker = new Marker({
            coords: issue.coordinates,
            icon: new Icon({
              type: 'div_icon',
              size: [36, 42],
              anchor: [18, 21],
              html: icon.$el.outerHTML
            }),
            popup_content: popup.$el,
            accumulation: true
          })

          icon.$destroy();
          popup.$destroy();

          return marker;
        })
      }

      if (transportData.shown) {
        const IconClass = Vue.extend(TransportMapIcon);
        const PopupClass = Vue.extend(TransportMapPopup);
        transport = this.transportsList.map(vehicle => {
          const iconInstance = new IconClass();
          iconInstance.vehicle = vehicle;
          const icon = iconInstance.$mount();

          const popupInstance = new PopupClass();
          popupInstance.vehicle = vehicle;
          const popup = popupInstance.$mount();

          const marker = new Marker({
            coords: [
              this.transportLat(vehicle.id),
              this.transportLon(vehicle.id)
            ],
            icon: new Icon({
              type: 'div_icon',
              size: [36, 42],
              anchor: [18, 21],
              html: icon.$el.outerHTML
            }),
            popup_content: popup.$el,
            accumulation: true
          })

          icon.$destroy();
          popup.$destroy();

          return marker;
        })
      }

      if (resourcesData.shown) {
        const IconClass = Vue.extend(ResourceIcon)
        const PopupClass = Vue.extend(ResourcePopup)

        const currentDate = this.$moment();
        resources = this.resourcesList
          .filter(resource => {
            const locationDate = this.$moment(resource.lastLocationDate)
            return currentDate.format('YYYY-MM-DD') === locationDate.format('YYYY-MM-DD') &&
              resource.lastLocation?.coordinates;
          })
          .map(resource => {
            let actualLocationStatus;
            const locationDate = this.$moment(resource.lastLocationDate);
            const diff = currentDate.diff(locationDate, 'minutes');
            if (diff < 6) actualLocationStatus = 'done';
            else if (diff >= 6 && diff < 30) actualLocationStatus = 'suspended';
            else actualLocationStatus = 'danger';

            const iconInstance = new IconClass();
            iconInstance.actualLocationStatus = actualLocationStatus;
            const icon = iconInstance.$mount();

            const popupInstance = new PopupClass();
            popupInstance.resource = resource;
            const popup = popupInstance.$mount();

            const marker = new Marker({
              coords: resource.lastLocation.coordinates,
              icon: new Icon({
                type: 'div_icon',
                size: [36, 42],
                anchor: [18, 21],
                html: icon.$el.outerHTML
              }),
              popup_content: popup.$el,
              accumulation: true
            })

            icon.$destroy();
            popup.$destroy();

            return marker;
          })
      }

      return [...leaks, ...issues, ...transport, ...resources];
    },
    setCircles: function () {
      return this.networkElements
        .filter(item => item.geometryType === 'Point')
        .map(item => {
          return new Circle({
            coords: item.coordinates,
            radius: 1,
            color: '#000'
          })
        })
    },
    setPolylines: function () {
      return this.networkElements
        .filter(item => item.geometryType === 'LineString' || item.geometryType === 'MultiLineString')
        .map(item => {
          return new Polyline({
            coords: item.coordinates,
            color: '#1AB9EB'
          })
        })
    },
    setPolygons: function () {
      return this.networkElements
        .filter(item => item.geometryType === 'Polygon' || item.geometryType === 'MultiPolygon')
        .map(item => {
          return new Polygon({
            coords: item.coordinates,
            color: '#1AB9EB',
            fill: '#1AB9EB'
          })
        })
    }
  },
  mounted() {
    this.$root.$on('BaseMap::map-click', ({coords, containerPoint}) => {
      if (!this.loading) {
        this.$store.commit('networkElements/SET_FILTER_PARAMS', {
          lat: coords.lat,
          lon: coords.lng,
          layer_id: this.selectedLayerId
        });

        this.$store.dispatch('networkElements/getNetworkElementsByCoords')
          .then(() => {
            const {x, y} = containerPoint;
            const popup = document.querySelector('.operational-scheme__popup');
            popup?.setAttribute('style', `transform: translate(${x + 20}px, ${y - 30}px)`);

            this.addSelectEventListener('.i-select');
          })
      }
    })
  },
  beforeDestroy() {
    this.$store.commit('networkElements/SET_ELEMENTS', []);
  },
  methods: {
    addSelectEventListener(selectors) {
      const select = document.querySelector(selectors);
      document.addEventListener('click', e => {
        if (!e.target.closest('.i-select__drop')) {
          this.currentNetworkElementId = null;
          this.isChangeModeSelectVisible = false;
          select.classList.remove('is-active');
        }
      })
    },
    openModal(element) {
      this.isModalActive = true;
      this.$store.dispatch('networkElements/getNetworkElementById', {
        elementId: element.id, sourceId: element.semanticSource.id
      })
        .then(element => {
          this.selectedNetworkElement = element;
          this.addSelectEventListener('.i-select');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    closeModalCard() {
      this.selectedNetworkElement = {};
      this.isModalActive = false;
    },
    changeElementMode(element, modeId, modeName) {
      if (element.currentMode.id !== modeId) {
        this.$store.dispatch('networkElements/changeNetworkElementMode', {
          element_id: element.id,
          source_id: element.semanticSource.id,
          mode_id: modeId
        })
          .then(() => {
            this.$store.commit("networkElements/CHANGE_ELEMENT_MODE", {
              elementId: element.id,
              modeId: modeId,
              modeName: modeName
            })
            showToast('Режим элемента изменён', 'success');
            this.currentNetworkElementId = null;
          })
          .catch(error => showAlert('Ошибка', error))
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/helpers/mixins";

.operational-scheme {
  &__map {
    position: relative;
    height: calc(100vh - 150px);
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    right: -24px;
    top: -24px;

    width: 24px;
    height: 24px;

    border-radius: 50%;
    background-color: var(--panel-dark);

    cursor: pointer;

    & svg {
      width: 20px;
      height: 20px;

      & path {
        stroke-width: 2px;
      }
    }
  }

  &__popup {
    position: absolute;
    z-index: 400;
    top: 0;

    padding: 12px;

    border-radius: 16px;
    border: 1px solid var(--border-main);

    background-color: var(--panel);
    box-shadow: 0px 8px 16px 0px var(--shadow);

    visibility: hidden;
    opacity: 0;

    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }

  &__popup-inner {
    position: relative;
  }

  &__popup-wrapper {
    position: relative;

    overflow: visible;
    max-height: 194px;

    padding: 4px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__element {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;

    padding: 4px 0;

    @include font-style(400, 12px, 14px, var(--text-primary));

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--border-main);
    }

    &-name {
      padding: 12px 0;
      cursor: pointer;

      &:hover {
        color: var(--text-link);
      }
    }

    &-action {
      padding: 12px 24px;

      &:hover {
        border-radius: 16px;
        border: 1px solid var(--border-main);
        margin: -1px;
      }
    }

    & .i-select__arrow {
      top: -6px;
    }
  }
}

.operation-data-switcher {
  z-index: 401;
  position: absolute;
  top: 80px;
  right: 20px;

  &:hover {
    & .operation-data-switcher__content {
      opacity: 1;
      visibility: visible;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 48px;
    height: 48px;

    border-radius: 12px;
    border: 1px solid var(--border-main);

    background-color: var(--panel);

    box-shadow: 0px 8px 16px 0px var(--shadow);
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px;
    margin-top: 10px;

    position: absolute;
    right: 0;

    opacity: 0;
    visibility: hidden;
    transition: .2s;

    background-color: var(--panel);
    border-radius: 16px;
    box-shadow: -24px 0px 96px 0px rgba(105, 132, 230, 0.16);

    & span {
      gap: 12px;
    }
  }
}
</style>
