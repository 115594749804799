import TelemetryPersonalListsService from "@/services/TelemetryPersonalListsService";

export const namespaced = true;

export const state = {
    personalLists: [],
    error: null,
    loading: false
}

export const mutations = {
    SET_PERSONAL_LISTS(state, lists) {
        state.personalLists = lists;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    }
}

export const actions = {
    getPersonalLists({commit}) {
        commit("SET_LOADING", true)
        TelemetryPersonalListsService.fetchPersonalLists()
            .then(personalLists => commit("SET_PERSONAL_LISTS", personalLists))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false))
    },
    createPersonalList(context, payload) {
        return new Promise((resolve, reject) => {
            TelemetryPersonalListsService.createPersonalList(payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    updatePersonalList(context, {id, payload}) {
        return new Promise((resolve, reject) => {
            TelemetryPersonalListsService.updatePersonalList(id, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    deletePersonalList(context, id) {
        return new Promise((resolve, reject) => {
            TelemetryPersonalListsService.deletePersonalList(id)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    }
}