<template>
  <base-popup
    :title="popupTitle"
    :name="popupName"
  >
    <template v-slot:body>
      <div class="form-item">
        <div class="form-item__name">Наименование параметра</div>
        <div class="form-item__value">
          <input
            v-model="$v.payload.name.$model"
            class="base-input base-input--bordered"
            :class="{'base-input--error': $v.payload.name.$error}"
            type="text"
            placeholder="Введите наименование">
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Краткое наименование параметра</div>
        <div class="form-item__value">
          <input
            v-model="$v.payload.short_name.$model"
            class="base-input base-input--bordered"
            :class="{'base-input--error': $v.payload.short_name.$error}"
            type="text"
            placeholder="Введите краткое наименование">
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Единицы измерения</div>
        <div class="form-item__value">
          <input
            v-model="$v.payload.measure.$model"
            class="base-input base-input--bordered"
            :class="{'base-input--error': $v.payload.measure.$error}"
            type="text"
            placeholder="Введите единицы измерения">
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Точность</div>
        <div class="form-item__value">
          <input
            v-model.number="$v.payload.precision.$model"
            class="base-input base-input--bordered"
            :class="{'base-input--error': $v.payload.precision.$error}"
            type="text"
            placeholder="Введите точность">
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Метод расчета</div>
        <div class="form-item__value">
          <v-select
            class="vue-select-input"
            v-model="payload.calculate_method"
            :options="calculatedMethodsList"
            placeholder="Выберите из списка"
            :reduce="method => method.id"
            :get-option-label="(option) => option.label"
          />
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Маска ввода</div>
        <div class="form-item__value form-item__value--column">
          <input
            v-model="$v.payload.input_mask.$model"
            class="base-input base-input--bordered"
            :class="{'base-input--error': $v.payload.input_mask.$error}"
            type="text"
            placeholder="Введите маску">

          <div class="base-input__label">
            <div>Цифра 9 – соответствует цифре от 0 до 9.</div>
            <div>{1,2} - обозначает количество доступных для ввода символов.</div>
            <div>Пример: 9,9 - допускает ввод одного знака до запятой и одного после.</div>
            <div>Пример: 9{1,2},9{1,2} - допускает ввод от одного до двух знаков до и после запятой.</div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div
        @click="checkFormType()"
        class="button">
        Сохранить
      </div>
    </template>
  </base-popup>
</template>

<script>
import {showAlert, showToast} from "@/utils/notification";
import BasePopup from "@/components/BasePopup.vue";
import {validationMixin} from "vuelidate";
import {required} from 'vuelidate/lib/validators'
import {mapState} from "vuex";
import NetworkEquipmentParameter from "@/models/telemetry/NetworkEquipmentParameter";

export default {
  name: "EquipmentParametersForm",
  components: {BasePopup},
  mixins: [validationMixin],
  props: {
    popupName: {
      type: String,
      required: true
    },
    equipmentParameter: {
      type: Object,
      required: true
    },
    equipmentId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      payload: {
        name: null,
        short_name: null,
        measure: null,
        input_mask: null,
        precision: null,
        calculate_method: null
      }
    }
  },
  validations: {
    payload: {
      name: {required},
      short_name: {required},
      measure: {required},
      input_mask: {required},
      precision: {required}
    }
  },
  computed: {
    ...mapState({
      networkObject: state => state.networkObjectPage.networkObject
    }),
    popupTitle() {
      return !Object.keys(this.equipmentParameter).length ? 'Создать параметр' : 'Редактирование параметра'
    },
    calculatedMethodsList: () => NetworkEquipmentParameter.calculatedMethodsList
  },
  mounted() {
    if (Object.keys(this.equipmentParameter).length) {
      const {name, shortName, measure, inputMask, precision, calculatedMethod} = this.equipmentParameter;
      this.payload.name = name;
      this.payload.short_name = shortName;
      this.payload.measure = measure;
      this.payload.input_mask = inputMask;
      this.payload.precision = precision;
      this.payload.calculate_method = calculatedMethod.id;
    }
  },
  methods: {
    checkFormType() {
      this.$v.$touch()
      if (this.$v.$invalid) return false

      if (!Object.keys(this.equipmentParameter).length) this.createParameter()
      else this.updateParameter()
    },
    createParameter() {
      this.$store.dispatch('networkEquipmentParameters/createNetworkEquipmentParameter', {
        equipmentId: this.equipmentId,
        payload: this.payload
      })
          .then(() => {
            this.$store.dispatch('networkObjectPage/getNetworkObjectEquipments', this.networkObject.id)
              .then(() => this.$store.dispatch('networkObjectPage/getEquipment', this.equipmentId))
            this.$modal.hide(this.popupName);
            showToast('Параметр добавлен', 'success');
          })
          .catch(error => showAlert('Ошибка', error))
    },
    updateParameter() {
      this.$store.dispatch('networkEquipmentParameters/updateNetworkEquipmentParameter', {
        equipmentId: this.equipmentId,
        parameterId: this.equipmentParameter.id,
        payload: this.payload
      })
          .then(() => {
            this.$store.dispatch('networkObjectPage/getNetworkObjectEquipments', this.networkObject.id)
              .then(() => this.$store.dispatch('networkObjectPage/getEquipment', this.equipmentId))
            this.$modal.hide(this.popupName);
            showToast('Параметр обновлён', 'success');
          })
          .catch(error => showAlert('Ошибка', error))
    }
  }
}
</script>

<style scoped>

</style>