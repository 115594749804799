export const transportLS = {
    getArray(type){
        if(localStorage.getItem('transport_sort_'+type) != null){
            return JSON.parse(localStorage.getItem('transport_sort_'+type));
        }else{
            return [];
        }
    },
    setArray(type, array){
        localStorage.setItem('transport_sort_'+type, JSON.stringify(array));
    }
}

export const telemetryLS = {
    getArray(type){
        if(localStorage.getItem('telemetry_sort_'+type) != null){
            return JSON.parse(localStorage.getItem('telemetry_sort_'+type));
        }else{
            return [];
        }
    },
    setArray(type, array){
        localStorage.setItem('telemetry_sort_'+type, JSON.stringify(array));
    }
}