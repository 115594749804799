<template>
  <svg width="24"
       height="24"
       viewBox="0 0 24 24"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M17.3429 3.47509L13.409 7.40902L13.9394 10.0607L16.591 10.591L20.5249 6.65707L20.5253
    6.65691C21.0122 7.80631 21.1287 9.07893 20.8585 10.2976C20.5882 11.5163 19.9448 12.6204 19.0177
    13.4563C18.0905 14.2921 16.9259 14.8182 15.6858 14.9611C14.4457 15.1041 13.1919 14.8568 12.099
    14.2538L12.0991 14.2536L6.841 20.341C6.41896 20.7626 5.84679 20.9993 5.25028 20.9991C4.65376
    20.9989 4.08173 20.7619 3.65993 20.3401C3.23813 19.9183 3.00109 19.3463 3.00092 18.7497C3.00075
    18.1532 3.23746 17.5811 3.65902 17.159L9.74634 11.901L9.74621 11.9011C9.1432 10.8081 8.89597
    9.5543 9.03891 8.31422C9.18185 7.07414 9.70787 5.90948 10.5437 4.98236C11.3796 4.05524 12.4837
    3.41178 13.7024 3.14155C14.9211 2.87133 16.1937 2.98778 17.3431 3.47471L17.3429 3.47509Z"
          stroke="#E19421"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  name: "IconBroken"
}
</script>
