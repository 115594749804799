<template>
  <div class="custom-padding">
    <div class="device-card"
         :class="getBackgroundIconClass(networkObject.type)">
      <div class="device-card-body">

        <div class="device-card__inner">
          <div class="device-card__name">
            <div class="device-icon"
                 :class="getIconClass(networkObject.type)"/>
            <span class="device-title">{{ equipment.name }}</span>
          </div>

          <div class="device-card__sensors">
            <div
              v-if="!equipment.parameters.length"
              class="no-result-title"
            >
              Датчики отсутствуют.
            </div>

            <parameter-item
              v-for="parameter in equipment.parameters"
              :id="`sensor_${parameter.id}`"
              :key="`sensor_${parameter.id}`"
              :parameter="parameter"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ParameterItem from "@/components/Telemetry/ParameterItem";
import formatDate from "@/mixins/formatDate";
import sensorsTime from "@/mixins/sensorsTime";

export default {
  name: "NetworkEquipmentItem",
  components: {ParameterItem},
  mixins: [formatDate, sensorsTime],
  props: [
    "equipment",
    "coords",
    "networkObject"
  ],
  computed: {
    getIconClass: function () {
      return type => {
        switch (type) {
          case 1:
            return 'pump-svg'
          case 2:
            return 'tank-svg'
          case 3:
            return 'mkd-svg'
          case 4:
            return 'counter-svg'
          case 5:
            return 'boiler-svg'
        }
      }
    },
    getBackgroundIconClass: function () {
      return type => {
        switch (type) {
          case 1:
            return 'pump-bg'
          case 2:
            return 'tank-bg'
          case 3:
            return 'mkd-bg'
          case 4:
            return 'counter-bg'
          case 5:
            return 'boiler-bg'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.device-card {
  background-color: var(--panel-bg);
  border-radius: 10px;
  padding: 13px 15px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;

  &__inner {

  }

  &__name {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  &__sensors {
    display: flex;
    column-gap: 24px;
    flex-wrap: wrap;
  }
}

.device-title {
  font-size: 14px;
  font-weight: 700;
  color: var(--text-link);
}

.custom-padding {
  padding: 0 20px 0 0;
}

.no-result-title {
  font-size: 14px;
  padding: 0 12px;
}
</style>
