import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router/index'
import store from './store'

import 'sweetalert2/dist/sweetalert2.min.css';
import vSelect from 'vue-select';
import VModal from 'vue-js-modal'
import VueMobileDetection from "vue-mobile-detection";
import 'vue-select/dist/vue-select.css';
import 'moment/locale/ru'
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';

import "@/assets/css/style.css";
import "@/assets/css/custom.css";

import moment from 'moment';
import 'moment/locale/ru';

// import 'moment-timezone';
moment.locale('ru')
Vue.prototype.$moment = moment;

Vue.component('VSelect', vSelect);

Vue.use(VueRouter);
Vue.use(VueMobileDetection);
Vue.use(VModal);

Vue.config.productionTip = false
Vue.prototype.$eventBus = new Vue();

window.Pusher = require('pusher-js');

new Vue({
    render: h => h(App),
    router: router,
    store
}).$mount('#app')
