export default class Department {
    constructor(id, attributes) {
        this.id = id;
        this.name = attributes.name;
        this.head = attributes.head;
        this.activity = attributes.activity;
    }

    getActivityLabel() {
        switch (this.activity) {
            case 0:
                return 'Не активно'
            case 1:
                return 'Активно'
        }
    }
}