<template>
  <div class="transports-list">
    <div
      v-for="vehicle in autos"
      :key="vehicle.id"
      @click="openTransportModal(vehicle.id)"
      class="transports-list__item">
      <div class="transports-list__icon">
        <div class="transports-list__type-icon">
          <icon-car v-if="vehicle.isPassengerVehicle()"/>
          <icon-special-equipment v-else-if="vehicle.isSpecialVehicle()" />
          <icon-truck v-else-if="vehicle.isTruck()"/>
          <icon-water-carrier v-else-if="vehicle.isWaterCarrier()"/>
        </div>

        <div
          @click.stop="showVehicleOnMap(vehicle.id)"
          class="transports-list__gps-icon">
          <icon-gps />
        </div>
      </div>

      <div class="transports-list__gov-number">
        <div class="transports-list__gov-number-card">
          {{vehicle.attributes.gov_number}}
        </div>
      </div>

      <div class="transports-list__name">
        {{vehicle.attributes.label}}
      </div>

      <div
        v-if="engineStatus(vehicle.id) || speed(vehicle.id) > 0"
        class="transports-list__speed">
        <transport-speed
          :id="vehicle.id"
          :transport-speed="speed(vehicle.id)"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import IconCar from "@/components/Icons/IconCar.vue";
import IconSpecialEquipment from "@/components/Icons/IconSpecialEquipment.vue";
import IconTruck from "@/components/Icons/IconTruck2.vue";
import IconWaterCarrier from "@/components/Icons/IconWaterCarrier.vue";
import TransportSpeed from "@/components/Transport/TransportSpeed.vue";
import IconGps from "@/components/Icons/IconGps.vue";
import sideModalControls from "@/mixins/sideModalControls";

export default {
  name: "TransportsList",
  components: {
    IconGps,
    TransportSpeed,
    IconWaterCarrier,
    IconTruck,
    IconSpecialEquipment,
    IconCar
  },
  mixins: [sideModalControls],
  computed: {
    ...mapGetters({
      autos: 'autos/getFilteredAndSortedAutos',
      speed: 'transportData/getSpeed',
      engineStatus: 'transportData/getEngineStatus',
      lat: 'transportData/getLat',
      lon: 'transportData/getLon'
    })
  },
  methods: {
    showVehicleOnMap(id) {
      const lat = this.lat(id);
      const lon = this.lon(id);

      this.$store.commit('baseMap/SET_CENTER_ZOOM', {
        center: [lat, lon],
        zoom: 17
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.transports-list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 380px;
  height: calc(100vh - 200px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  &__item {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    gap: 8px;

    padding: 12px;
    border-radius: 16px;
    border: 1px solid var(--border-main);
    background: var(--panel);
    cursor: pointer;

    @include transition();

    &:hover {
      box-shadow: 0px 6px 20px 0px rgba(6, 5, 50, 0.10);
      border-color: var(--border-active);

      & .transports-list__type-icon {
        display: none;
      }

      & .transports-list__gps-icon {
        display: flex;
      }
    }
  }

  &__type-icon {
    display: flex;
    align-items: center;
    height: 20px;
    width: 20px;
  }

  &__gps-icon {
    display: none;
    align-items: center;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  &__icon {
    width: 20px;

    & svg {
      width: 20px;
    }
  }

  &__gov-number {
    width: 78px;

    &-card {
      padding: 2px 8px;
      border-radius: 8px;
      background-color: var(--panel-bg);

      @include font-style(600, 10px, 12px, var(--text-primary));
    }
  }

  &__name {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include font-style(400, 12px, 14px, var(--text-primary));
  }

  &__speed {
    width: 66px;
  }
}
</style>
