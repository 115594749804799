<template>
  <div class="base-no-data">
    <icon-no-result />
    <div class="base-no-data__title">
      {{title}}
    </div>
  </div>
</template>

<script>
import IconNoResult from "@/components/Icons/IconNoResult.vue";

export default {
  name: "BaseNoData",
  components: {IconNoResult},
  props: {
    title: {
      type: String,
      default: 'Данные не найдены'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.base-no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  margin-top: 24px;

  &__title {
    @include font-style(400, 16px, 24px, var(--text-primary))
  }
}
</style>