<template>
  <svg width="120"
       height="120"
       viewBox="0 0 120 120"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M53.077 9.23073C28.8615 9.23073 9.23101 28.8612
    9.23101 53.0767C9.23101 77.2921 28.8615 96.9226 53.077 96.9226C77.2924 96.9226 96.9229 77.2921
    96.9229 53.0767C96.9229 28.8612 77.2924 9.23073 53.077 9.23073ZM0.000282288 53.0767C0.000282288
    23.7632 23.7635 0 53.077 0C82.3904 0 106.154 23.7632 106.154 53.0767C106.154 66.0727 101.483
    77.9778 93.7283 87.2048C91.7453 89.5644 89.5606 91.7488 87.2008 93.7316C77.9744 101.484 66.071
    106.153 53.077 106.153C23.7635 106.153 0.000282288 82.3901 0.000282288 53.0767Z"
          fill="url(#paint0_linear_3121_36833)"/>
    <path d="M93.7283 87.2048C91.7453 89.5644 89.5606 91.7488 87.2008 93.7316L112.117
    118.648C113.92 120.451 116.842 120.451 118.645 118.648C120.447 116.846 120.447
    113.923 118.645 112.121L93.7283 87.2048Z"
          fill="url(#paint1_linear_3121_36833)"/>
    <defs>
      <linearGradient id="paint0_linear_3121_36833"
                      x1="-1.99966"
                      y1="-4"
                      x2="120.501"
                      y2="125.496"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#468FC5"
              stop-opacity="0.8"/>
        <stop offset="0.669792"
              stop-color="#468FC5"
              stop-opacity="0.2"/>
        <stop offset="1"
              stop-color="#468FC5"
              stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint1_linear_3121_36833"
                      x1="-1.99966"
                      y1="-4"
                      x2="120.501"
                      y2="125.496"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#468FC5"
              stop-opacity="0.8"/>
        <stop offset="0.669792"
              stop-color="#468FC5"
              stop-opacity="0.2"/>
        <stop offset="1"
              stop-color="#468FC5"
              stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "IconNoResult"
}
</script>
