<template>
  <div class="additional-option-wrapper"
       :class="{'always-open': isCollapseVisible}">
    <div class="additional-option additional-option--no-icon">
      <div class="additional-option__name"><span>Счетчики</span><span
        class="additional-option__quantity">{{ electricMetersList.length }}</span>
      </div>
      <div class="additional-option__buttons">
        <button
          class="additional-option__add"
          type="button"
          @click="showElectricMeterPopup()">
          <icon-plus/>
        </button>
        <button
          @click="isCollapseVisible = !isCollapseVisible"
          class="additional-option__toggle"
          :class="{'rotate': isCollapseVisible}">
          <icon-arrow-double-up/>
        </button>
      </div>
    </div>

    <div class="additional-options-list">
      <div class="additional-options-list__inner">
        <div v-for="electricMeter in electricMetersList"
             :key="electricMeter.id"
             class="added-item added-item--flex">
          <div class="added-item__info">
            <div class="added-item__name">{{ electricMeter.model }}</div>
            <div class="added-item__additional">
              #{{ electricMeter.number }} | {{formatDate(electricMeter.installationDate)}}
            </div>
          </div>
          <div
            class="added-item__right">
            <div @click="showElectricMeterPopup(electricMeter)">
              <icon-pencil class="svg added-item__tool added-item__tool--size-20" />
            </div>
            <div @click="deleteElectricMeter(electricMeter.id)">
              <icon-trash class="svg added-item__tool"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {showAlert, showToast} from "@/utils/notification";
import IconTrash from "@/components/Icons/IconTrash.vue";
import IconPlus from "@/components/Icons/IconPlus.vue";
import IconArrowDoubleUp from "@/components/Icons/IconArrowDoubleUp.vue";
import IconPencil from "@/components/Icons/IconPencil.vue";
import ElectricMeterForm from "@/components/EnergyConsumption/admin/ElectricMeterForm.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import {formatDate} from "@/utils/filters";

export default {
  name: "ElectricMetersList",
  components: {IconPencil, IconArrowDoubleUp, IconPlus, IconTrash},
  props: {
    energyConsumptionObjectId: {
      type: Number,
      required: true
    },
    electricMetersList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isCollapseVisible: false
    }
  },
  methods: {
    formatDate,
    showElectricMeterPopup(electricMeter = {}) {
      this.$modal.show(
        ElectricMeterForm,
        {
          'electricMeter': electricMeter,
          'createElectricMeter': this.createElectricMeter,
          'updateElectricMeter': this.updateElectricMeter
        },
        {
          name: 'electric-meter-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        }
      )
    },
    createElectricMeter(payload) {
      payload.electricity_consumer_facility_id = this.energyConsumptionObjectId;
      this.$store.dispatch('electricMeters/createElectricMeter', {
        energyConsumptionObjectId: this.energyConsumptionObjectId,
        payload
      })
        .then(() => {
          this.$store.dispatch('energyConsumptionObjects/getEnergyConsumptionObjectWithMeters',
            this.energyConsumptionObjectId);
          this.$modal.hide('electric-meter-form');
          showToast('Счётчик добавлен', 'success');
        })
        .catch(error => showAlert('Ошибка', error));
    },
    updateElectricMeter(electricMeterId, payload) {
      payload.electricity_consumer_facility_id = this.energyConsumptionObjectId;
      this.$store.dispatch('electricMeters/updateElectricMeter', {
        electricMeterId: electricMeterId,
        energyConsumptionObjectId: this.energyConsumptionObjectId,
        payload
      })
        .then(() => {
          this.$store.dispatch('energyConsumptionObjects/getEnergyConsumptionObjectWithMeters',
            this.energyConsumptionObjectId);
          this.$modal.hide('electric-meter-form');
          showToast('Счётчик обновлён', 'success');
        })
        .catch(error => showAlert('Ошибка', error));
    },
    deleteElectricMeter(id) {
      this.$store.dispatch('electricMeters/deleteElectricMeter', {
        electricMeterId: id,
        energyConsumptionObjectId: this.energyConsumptionObjectId
      })
          .then(() => {
            showToast('Счётчик удалён', 'success');
            this.$store.dispatch('energyConsumptionObjects/getEnergyConsumptionObjectWithMeters',
              this.energyConsumptionObjectId);
          })
          .catch(error => showAlert('Ошибка', error));
    }
  }
}
</script>

<style scoped>
.electric-meter__controls {
  display: flex;
  justify-content: center;
}
</style>