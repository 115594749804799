import * as API from './API';
import Damage from "@/models/serviceDesk/Damage";
import {getError} from "@/utils/helpers";

export default {
    fetchDamagesList(issueId) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/issues/${issueId}/damages`)
                .then(response => resolve(response.data.data
                    .map(item => new Damage({id: item.id, ...item.attributes}))))
                .catch(error => reject(getError(error)))
        })
    },
    createDamage(issueId, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`/service-desk/issues//${issueId}/damages`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateDamage(issueId, damageId, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/service-desk/issues/${issueId}/damages/${damageId}`, payload)
              .then(() => resolve())
              .catch(error => reject(getError(error)));
        })
    }
}