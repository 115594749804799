<template>
  <div class="base-date-range-picker">
    <div
      @click="getToday"
      :class="['base-date-range-picker__item', {'base-date-range-picker__item--active': 'Сегодня' === currentRange}]">
      Сегодня
    </div>
    <div
      @click="getYesterday"
      :class="['base-date-range-picker__item', {'base-date-range-picker__item--active': 'Вчера' === currentRange}]">
      Вчера
    </div>
    <div
      @click="getWeekDates"
      :class="['base-date-range-picker__item', 'base-date-range-picker__item--extra',
               {'base-date-range-picker__item--active': 'Неделя' === currentRange}]">
      Неделя
    </div>
    <div
      @click="getMonthDates"
      :class="['base-date-range-picker__item', 'base-date-range-picker__item--extra',
               {'base-date-range-picker__item--active': 'Месяц' === currentRange}]">
      Месяц
    </div>
    <div
      @click="getQuarterDates"
      :class="['base-date-range-picker__item', 'base-date-range-picker__item--extra',
               {'base-date-range-picker__item--active': 'Квартал' === currentRange}]">
      Квартал
    </div>
    <div
      @click="getYearDates"
      :class="['base-date-range-picker__item', 'base-date-range-picker__item--extra',
               'base-date-range-picker__item--right-side',
               {'base-date-range-picker__item--active': 'Год' === currentRange}]">
      Год
    </div>
    <div
      @click="toggleMenu"
      :class="['base-date-range-picker__item', 'base-date-range-picker__item--menu',
               'base-date-range-picker__item--right-side',
               {'base-date-range-picker__item--active': 'Год' === isMenuVisible}]">
      ...
    </div>
    <base-dropdown v-if="isMenuVisible">
      <span @click="getWeekDates"
            class="base-dropdown__option">Неделя</span>
      <span @click="getMonthDates"
            class="base-dropdown__option">Месяц</span>
      <span @click="getQuarterDates"
            class="base-dropdown__option">Квартал</span>
      <span @click="getYearDates"
            class="base-dropdown__option">Год</span>
    </base-dropdown>
  </div>
</template>

<script>
import BaseDropdown from "@/components/BaseDropdown.vue";

export default {
  name: 'BaseDateRangePicker',
  components: {BaseDropdown},
  data() {
    return {
      currentRange: 'Сегодня',
      isMenuVisible: false
    }
  },
  methods: {
    getYesterday() {
      this.currentRange = 'Вчера'
      const today = new Date();
      const yesterday = new Date(today.getFullYear(), today.getMonth(),today.getDate() - 1)
      this.$emit('updateDateRange', {
        start: this.formatDate(yesterday),
        end: this.formatDate(today)
      });
    },
    getToday() {
      this.currentRange = 'Сегодня'
      const today = new Date();
      const start = this.formatDate(today)
      const end = this.formatDate(today);
      const range = {start: start, end: end}
      this.$emit('updateDateRange', range)
    },
    getWeekDates() {
      this.closeMenu();
      this.currentRange = 'Неделя'
      let startOfWeek = this.$moment().startOf('week').toDate();
      let endOfWeek   = this.$moment().endOf('week').toDate();
      const start = this.formatDate(new Date(startOfWeek));
      const end = this.formatDate(new Date(endOfWeek));
      const range = {start: start, end: end}
      this.$emit('updateDateRange', range)
    },
    getMonthDates() {
      this.closeMenu();
      this.currentRange = 'Месяц'
      const today = new Date();
      const start = this.formatDate(new Date(today.getFullYear(), today.getMonth(), 1));
      const end =  this.formatDate(new Date(today.getFullYear(), today.getMonth() + 1, 0));
      const range = {start: start, end: end}
      this.$emit('updateDateRange', range)
    },
    getQuarterDates() {
      this.closeMenu();
      this.currentRange = 'Квартал'
      const today = new Date();
      const quarter = Math.floor((today.getMonth() + 3) / 3);
      const quarterX3 = quarter * 3;
      const start = this.formatDate(new Date(today.getFullYear(), quarterX3 - 3, 1)) ;
      const end = this.formatDate(new Date(today.getFullYear(), 3 * quarter, 0));
      const range = {start: start, end: end}
      this.$emit('updateDateRange', range)
    },
    getYearDates() {
      this.closeMenu();
      this.currentRange = 'Год'
      const today = new Date();
      const start = this.formatDate(new Date(today.getFullYear(), 0, 1));
      const end = this.formatDate(new Date(today.getFullYear(), 11, 31)) ;
      const range = {start: start, end: end}
      this.$emit('updateDateRange', range)
    },
     formatDate(date) {
      this.closeMenu();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    toggleMenu() {
      this.isMenuVisible = !this.isMenuVisible;
    },
    closeMenu() {
      this.isMenuVisible = false;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>