<template>
  <div
    class="base-table__row base-table__row--issue"
    @click="openIssueModal(issue.id)">
    <div class="base-table__column base-table__column--number">
      <div class="base-table__num">{{ issue.id }}</div>
    </div>
    <div class="base-table__column">
      <icon-flash-filled v-if="isIncident(issue)"/>
      <icon-clipboard-filled v-else/>
    </div>
    <div class="base-table__column">
      <div class="base-table__text">{{ formatDateTime(issue.planned_start_at) }}</div>
    </div>
    <div class="base-table__column base-table__column--number">
      <div class="base-table__text base-table__tooltip-wrapper base-table__indicator">
        <span
          class="base-table__indicator-circle"
          :style="{'background-color': issue.category.group_color}"/>
        {{ issue.category.name }}

        <div class="base-table__tooltip">
          {{ issue.category.group_name }}
        </div>
      </div>
    </div>
    <div class="base-table__column">
      <div class="base-table__text">{{ issue.getFullAddress() }}</div>
    </div>
    <div class="base-table__column base-table__column--appeals">
      <div class="base-table__text base-table__text--center">{{ issue.structuralUnit.name }}</div>
    </div>
    <div class="base-table__column base-table__column--state">
      <div class="base-table__tag"
           :class="[
             {'base-table__tag--new': issue.state.id === IssueState.NEW},
             {'base-table__tag--work': issue.state.id === IssueState.IN_WORK},
             {'base-table__tag--done': issue.state.id === IssueState.CLOSED},
             {'base-table__tag--canceled': issue.state.id === IssueState.CANCELED}
           ]">
        {{ issue.state.label }}
      </div>
    </div>
    <div class="base-table__column base-table__column--state">
      <issues-tasks :tasks="getTasksExpectCanceled(issue.tasks)"/>
    </div>
    <div class="base-table__column base-table__column--info base-table__column--content-right">
      <span v-if="issue.description"
            class="base-table__short-info">
        <icon-comment class="base-table__short-info-icon"/>
        <span class="base-table__short-info-counter">1</span>

        <div class="base-table__short-info-tooltip">
          <div class="base-table__short-info-tooltip-inner">
            {{ issue.description }}
          </div>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import IssuesTasks from "@/components/IssuesTasks.vue";
import IconClipboardFilled from "@/components/Icons/IconClipboardFilled.vue";
import IconFlashFilled from "@/components/Icons/IconFlashFilled.vue";
import IconComment from "@/components/Icons/IconComment.vue";
import sideModalControls from "@/mixins/sideModalControls";
import IssueState from "@/models/serviceDesk/IssueState";
import {formatDateTime} from "@/utils/filters";

export default {
  name: "IssuesItem",
  components: {IconComment, IconFlashFilled, IconClipboardFilled, IssuesTasks},
  mixins: [sideModalControls],
  props: {
    issue: {
      type: Object,
      required: true
    }
  },
  computed: {
    IssueState: () => IssueState
  },
  methods: {
    formatDateTime,
    isIncident(issue) {
      return issue.type_id === 1;
    },
    getTasksExpectCanceled(tasks) {
      return tasks.filter(task => !task.state.isCanceled);
    }
  }
}
</script>

<style scoped>

</style>