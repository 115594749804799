import {getError} from "@/utils/helpers";
import LayerService from "@/services/LayerService";

export const namespaced = true;

export const state  = {
    layers: [],
    layer: {},
    meta: null,
    links: null,
    loading: false,
    error: null
};

export const mutations = {
    SET_LAYERS(state, layers) {
        state.layers = layers;
    },
    SET_LAYER(state, layer) {
        state.layer = layer;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    DELETE_LAYER(state, layer_id){
        let index = state.layers.findIndex(layer => layer.id == layer_id);
        state.layers.splice(index, 1);
    },
    DELETE_SEMANTIC_SOURCE_FROM_LAYER(state, semanticSourceId) {
        const index = state.layer.semanticSources.find(source => source.id === semanticSourceId);
        state.layer.semanticSources.splice(index, 1);
    }
};

export const actions = {
    getLayers({ commit }) {
        commit("SET_LOADING", true);
        LayerService.getLayers()
            .then(layers => commit("SET_LAYERS", layers))
            .catch(error => commit("SET_ERROR", getError(error)))
            .finally(() => commit("SET_LOADING", false))

    },
    getLayer({commit}, layerId) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
            LayerService.getLayer(layerId)
                .then(layer => {
                    commit("SET_LAYER", layer);
                    resolve(layer)
                })
                .catch(error => reject(error))
                .finally(() => commit("SET_LOADING", false))
        })
    },
    createLayer(context, payload) {
        return new Promise((resolve, reject) => {
            LayerService.createLayer(payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateLayer(context, {layerId, payload}) {
        return new Promise((resolve, reject) => {
            LayerService.updateLayer(layerId, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteLayer(context, layerId) {
        return new Promise((resolve, reject) => {
            LayerService.deleteLayer(layerId)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    createSemanticSource(context, {layerId, payload}) {
        return new Promise((resolve, reject) => {
            LayerService.createSemanticSource(layerId, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteSemanticSource(context, {layerId, semanticSourceId}) {
        return new Promise((resolve, reject) => {
            LayerService.deleteSemanticSource(layerId, semanticSourceId)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
};

export const getters = {
    getLayer: state => id =>{
        return state.layers.find(layer => layer.id === parseInt(id));
    },
    layers: state => {
        return state.layers;
    },
    meta: state => {
        return state.meta;
    },
    links: state => {
        return state.links;
    },
    loading: state => {
        return state.loading;
    },
    error: state => {
        return state.error;
    }
}