import NetworkObject from "@/models/telemetry/NetworkObject";

export default class NetworkObjectTelemetry extends NetworkObject {
    constructor(params, showInList, showOnMap, fixOnMap) {
        super(params);

        this.showInList = showInList;
        this.showOnMap = showOnMap;
        this.fixOnMap = fixOnMap;
    }
}