<template>
  <div class="page-wrapper">
    <base-panel
      title="Фильтр по объектам"
      :is-add-button-visible="false"
    />

    <div class="billing-filter-container">
      <div class="tabs">
        <div class="tabs__header tabs__header--block">
          <div
            @click="activeTab = tabFilter"
            :class="{'active': activeTab === tabFilter}"
            class="tabs__header-item">
            Фильтр
          </div>
          <div
            @click="activeTab = tabResult"
            :class="{'active': activeTab === tabResult}"
            class="tabs__header-item">
            Результат
          </div>
          <div
            @click="activeTab = tabBuilding"
            :class="{'active': activeTab === tabBuilding}"
            class="tabs__header-item">
            Объект
          </div>
          <div
            @click="activeTab = tabClient"
            :class="{'active': activeTab === tabClient}"
            class="tabs__header-item">
            Абонент
          </div>
        </div>

        <div
          v-show="activeTab === tabFilter"
          :class="{'active': activeTab === tabFilter}"
          class="tabs__item">
          <billing-buildings-filter @data-complete="filter = $event"/>
        </div>

        <div
          v-if="activeTab === tabResult"
          :class="{'active': activeTab === tabResult}"
          class="tabs__item">
          <billing-buildings-filter-result :filter_params="filter"/>
        </div>

        <div
          v-if="activeTab === tabBuilding"
          :class="{'active': activeTab === tabBuilding}"
          class="tabs__item">
          <billing-building-detail/>
        </div>

        <div
          v-if="activeTab === tabClient"
          :class="{'active': activeTab === tabClient}"
          class="tabs__item">
          <billing-client-detail />
        </div>
      </div>

      <div>
        <billing-buildings-map />
      </div>
    </div>
  </div>
</template>

<script>
import BillingBuildingsFilter from "@/components/Billing/BillingFilters/BillingBuildingsFilter.vue";
import BillingBuildingsFilterResult from "@/components/Billing/BillingFilters/BillingBuildingsFilterResult.vue";
import BillingBuildingsMap from "@/components/Billing/BillingFilters/BillingBuildingsMap.vue";
import BillingBuildingDetail from "@/components/Billing/BillingFilters/BillingBuildingDetail.vue";
import BillingClientDetail from "@/components/Billing/BillingFilters/BillingClientDetail.vue";
import BasePanel from "@/components/BasePanel.vue";
import {showToast} from "@/utils/notification";

export default {
  name: "BillingBuildingsPage",
  components: {
    BasePanel,
    BillingClientDetail,
    BillingBuildingDetail,
    BillingBuildingsMap,
    BillingBuildingsFilterResult,
    BillingBuildingsFilter
  },
  data() {
    return {
      filter: null,
      tabFilter: 0,
      tabResult: 1,
      tabBuilding: 2,
      tabClient: 3
    }
  },
  computed: {
    activeTab: {
      get() {
        return this.$store.getters['building/getTabIndex']
      },
      set(index) {
        this.$store.commit('building/SET_TAB_INDEX', index)
      }
    }
  },
  mounted() {
    this.$root.$on("BaseMap::map-click", ({coords}) => {
      this.getBuildingsByCoords(coords)
    })
  },
  beforeDestroy() {
    this.$store.commit('building/SET_TAB_INDEX', this.tabFilter);
    this.$root.$off('BaseMap::map-click');
  },
  methods: {
    getBuildingsByCoords(coords) {
      let payload = {
        params: {
          distance: 20,
          latitude: coords.lat,
          longitude: coords.lng
        }
      }

      this.$store.dispatch('building/getBuildingsByCoords', payload)
          .then(() => {
            if (this.$store.state.building.buildings.length === 0) {
              showToast('Здания не найдены', 'warning');
            } else {
              this.$store.commit('building/SET_TAB_INDEX', 1);
            }
          })
          .catch(() => {
            showToast(this.$store.state.building.error, 'error');
          })
    }
  }
}
</script>

<style scoped>
</style>