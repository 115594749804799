<template>
  <div class="page-wrapper">
    <base-panel
      title="Роли"
      :is-add-button-visible="true"
      add-button-name="Добавить роль"
      @add-button-clicked="showPopup()"
    />

    <roles-list
      v-if="rolesList.length"
      :roles-list="rolesList"
      @open-role-modal="openRoleModal"
      @update-role="showPopup"
      @delete-role="deleteRole"
    />

    <base-content-loaders v-if="loading && !rolesList.length"/>

    <base-no-data v-if="!loading && !rolesList.length"/>

    <base-paginate
      v-if="meta && meta.last_page > 1"
      :meta="meta"
      action="role/getRoles"
      commit="role/SET_PAGE"
    />
  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel.vue";
import RolesList from "@/components/admin/RolesList.vue";
import BasePaginate from "@/components/BasePaginate.vue";
import {mapState} from "vuex";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import {showAlert, showToast, showConfirmationModal} from "@/utils/notification";
import {modalAdaptiveWidth} from "@/utils/helpers";
import RolesForm from "@/components/admin/RolesForm.vue";
export default {
  name: "RolesPage",
  components: {
    BaseNoData,
    BaseContentLoaders,
    BasePaginate,
    RolesList,
    BasePanel
  },
  data() {
    return {
      popupName: 'role-form'
    }
  },
  computed: {
    ...mapState({
      rolesList: state => state.role.roles,
      loading: state => state.role.loading,
      meta: state => state.role.meta
    })
  },
  mounted() {
    this.$store.dispatch('role/getRoles')
  },
  methods: {
    openRoleModal(roleId) {
      this.$store.dispatch('baseSideModal/openModal', {
        modalContentType: 'role',
        entityId: roleId
      });
    },
    showPopup(role = {}) {
      this.$modal.show(
        RolesForm,
        {
          'role': role,
          'popupName': this.popupName,
          'createRole': this.createRole,
          'updateRole': this.updateRole
        },
        {
          name: this.popupName,
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        }
      )
    },
    createRole(payload) {
      this.$store.dispatch('role/createRole', payload)
        .then(() => {
          this.$store.dispatch('role/getRoles');
          this.$modal.hide(this.popupName);
          showToast('Роль создана', 'success');
        })
        .catch(error => showAlert('Ошибка', error));
    },
    updateRole(roleId, payload) {
      this.$store.dispatch('role/updateRole', {
        roleId, payload
      })
        .then(() => {
          this.$store.dispatch('role/getRoles');
          this.$modal.hide(this.popupName);
          showToast('Роль обновлена', 'success');
        })
        .catch(error => showAlert('Ошибка', error));
    },
    deleteRole(roleId) {
      showConfirmationModal('Вы уверены, что хотите удалить роль? Это действие невозвратно.')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('role/deleteRole', roleId)
              .then(() => {
                this.$store.commit('role/DELETE_ROLE', roleId);
                showToast('Роль удалена', 'success');
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style scoped>

</style>