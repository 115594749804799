<template>
  <div class="appeal-map-popup">
    <div class="appeal-map-popup__content">
      <div class="appeal-map-popup__header">
        <div @click="openAppealModal(appeal.id)"
             class="appeal-map-popup__id">{{ appeal.id }}</div>
        <div class="appeal-map-popup__state"
             :class="[
               {'base-table__tag--new': appeal.state === AppealState.NEW},
               {'base-table__tag--work': appeal.state === AppealState.IN_WORK},
               {'base-table__tag--done': appeal.state === AppealState.CLOSED},
               {'base-table__tag--canceled': appeal.state === AppealState.CANCELED}
             ]">
          {{ appeal.getStateLabel() }}
        </div>
      </div>
      <div
        v-if="appeal.category"
        class="appeal-map-popup__category">
        <span class="appeal-map-popup__category-indicator"
              :style="{'background-color': appeal.category.group_color}"/>
        {{appeal.category.name}}
      </div>
      <div class="appeal-map-popup__address">{{appeal.getFullAddress()}}</div>
      <div class="appeal-map-popup__created-at">{{formatDateTime(appeal.created_at)}}</div>
      <div class="appeal-map-popup__footer">
        <div
          v-if="appeal.issue_id"
          @click="openIssueModal(appeal.issue_id)"
          class="appeal-map-popup__related-issue">
          <icon-link />
          <span>
            #{{appeal.issue_id}}
          </span>
        </div>
        <div
          v-if="appeal.message"
          class="appeal-map-popup__message-wrapper">
          <div class="appeal-map-popup__message">
            <icon-comment/>
            <span>1</span>
          </div>
          <div class="tooltip">
            {{appeal.message}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppealState from "@/models/callCenter/AppealState";
import IconLink from "@/components/Icons/IconLink.vue";
import IconComment from "@/components/Icons/IconComment.vue";
import store from "@/store";
import {formatDateTime} from "@/utils/filters";

export default  {
  name: "AppealMapPopupContent",
  components: {IconComment, IconLink},
  props: {
    appeal: {
      type: Object
    }
  },
  computed: {
    AppealState: () => AppealState
  },
  methods: {
    formatDateTime,
    openIssueModal(issueId) {
      store.dispatch('baseSideModal/openModal', {
        modalContentType: 'issue',
        entityId: issueId
      })
    },
    openAppealModal(appealId) {
      store.dispatch('baseSideModal/openModal', {
        modalContentType: 'appeal',
        entityId: appealId
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.appeal-map-popup {
  border-radius: 16px;
  border: 1px solid var(--border-main);

  background-color: var(--panel);
  box-shadow: 0px 6px 20px 0px rgba(6, 5, 50, 0.10);

  &__content {
    display: flex;
    flex-direction: column;
    gap: 12px;

    padding: 16px 24px;
  }

  &__header {
    display: flex;
    gap: 8px;
  }

  &__id {
    padding: 4px 8px;
    border-radius: 6px;
    background-color: var(--panel-bg);

    cursor: pointer;

    @include font-style(400, 14px, 16px, var(--text-primary));
  }

  &__state {
    padding: 4px 8px;
    border-radius: 8px;

    @include font-style(600, 14px, 16px, var(--text-panel));
  }

  &__category {
    display: flex;
    align-items: center;
    gap: 8px;

    @include font-style(600, 16px, 24px, var(--text-primary));
  }

  &__category-indicator {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  &__address {
    @include font-style(400, 14px, 16px, var(--text-primary));
  }

  &__created-at {
    @include font-style(400, 14px, 16px, var(--text-secondary));
  }

  &__footer {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }

  &__related-issue {
    display: flex;
    align-items: center;
    gap: 4px;

    cursor: pointer;

    & span {
      @include font-style(400, 14px, 16px, var(--text-link));
    }
  }

  &__message-wrapper {
    position: relative;

    &:hover .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }

  &__message {
    display: flex;
    align-items: center;
    gap: 8px;

    color: var(--text-link);
  }
}
</style>
