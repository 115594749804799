import TasksService from "@/services/TasksService";

export const namespaced = true;

export const state = {
    taskMechanicalEquipments: [],
    loading: false,
    error: null
}

export const mutations = {
    SET_MECHANICAL_EQUIPMENTS(state, equipments) {
        state.taskMechanicalEquipments = equipments;
    },
    ADD_MECHANICAL_EQUIPMENT(state, equipment) {
        state.taskMechanicalEquipments.push(equipment);
    },
    DELETE_MECHANICAL_EQUIPMENT(state, equipmentId) {
        const index = state.taskMechanicalEquipments.findIndex(item => item.id === equipmentId);
        state.taskMechanicalEquipments.splice(index, 1);
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getTaskMechanicalEquipments({commit}, taskId) {
        commit("SET_LOADING", true);
        TasksService.fetchTaskMechanicalEquipments(taskId)
            .then(equipments => commit("SET_MECHANICAL_EQUIPMENTS", equipments))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    addMechanicalEquipmentToTask({commit}, {taskId, payload}) {
        return new Promise((resolve, reject) => {
            TasksService.addMechanicalEquipment(taskId, payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    },
    removeMechanicalEquipmentFromTask({commit}, {taskId, payload}) {
        return new Promise((resolve, reject) => {
            TasksService.removeMechanicalEquipment(taskId, payload)
                .then(() => {
                    commit("DELETE_MECHANICAL_EQUIPMENT", payload.equipment_id);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    }
}