<template>
  <div class="vue-dadata-wrapper">
    <base-suggestions
      v-model="addressStringModel"
      type="address"
      placeholder="Введите адрес"
      @change="setAddress"
    />
  </div>
</template>

<script>
import BaseSuggestions from "@/components/BaseSuggestions.vue";

export default {
  name: "AddressSelector",
  components: {BaseSuggestions},
  props: {
    addressString: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      address: {}
    }
  },
  computed: {
    addressStringModel: {
      get() {
        return this.addressString;
      },
      set(value) {
        this.$emit('update:address-string', value);
      }
    }
  },
  methods: {
    setAddress(i) {
      if (i.data) {
        this.address.region = i.data.region;
        this.address.city = i.data.city;
        this.address.settlement = i.data.settlement_with_type;
        this.address.street = i.data.street_with_type;
        this.address.house = i.data.house;
        this.address.block = i.data.block;
        this.address.flat = i.data.flat;
        this.address.lat = parseFloat(i.data.geo_lat);
        this.address.lon = parseFloat(i.data.geo_lon);
        this.address.qc_geo = i.data.qc_geo;

        this.$emit('set-address', this.address);
      }
    }
  }
}
</script>

<style scoped>
.vue-dadata-wrapper {
  width: 100%;
}
 </style>