import * as API from './API'
import Leak from "@/models/serviceDesk/Leak";
import {getError} from "@/utils/helpers";
import OperationalLeak from "@/models/serviceDesk/operationalData/OperationalLeak";

export default {
    getLeaks(issue_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/issues/${issue_id}/leaks`)
                .then(response => resolve(response.data.data.map(leak => new Leak(leak.id, leak.attributes))))
                .catch(error => reject(getError(error)));
        })
    },
    createLeak({issue_id, payload}) {
        return API.apiClient.post(`service-desk/issues/${issue_id}/leaks`, payload);
    },
    finishLeak(issueId, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/service-desk/issues/${issueId}/leaks/finish`, payload)
                .then((() => resolve()))
                .catch(error => reject(getError(error)))
        })
    },
    getOperationalLeaksList() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/operational-data/leaks`)
                .then(response => resolve(response.data.data
                    .map(item => new OperationalLeak({id: item.id, ...item.attributes}))))
                .catch(error => reject(getError(error)))
        })
    },
    attachLeakToDamage(issueId, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/service-desk/issues/${issueId}/leaks/attach-to-damage`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    }
}
