<script>
export default {
  name: "IconEngineHours"
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none">
    <path
      opacity="0.4"
      d="M4 25.7599V22.2399C4 20.1599 5.7 18.4399 7.8 18.4399C11.42 18.4399 12.9 15.8799 11.08 12.7399C10.04
          10.9399 10.66 8.59987 12.48 7.55987L15.94 5.57987C17.52 4.63987 19.56 5.19987 20.5 6.77987L20.72
          7.15987C22.52 10.2999 25.48 10.2999 27.3 7.15987L27.52 6.77987C28.46 5.19987 30.5 4.63987 32.08 5.57987L35.54
           7.55987C37.36 8.59987 37.98 10.9399 36.94 12.7399C35.12 15.8799 36.6 18.4399 40.22 18.4399C42.3 18.4399
           44.02 20.1399 44.02 22.2399V25.7599C44.02 27.8399 42.32 29.5599 40.22 29.5599C36.6 29.5599 35.12 32.1199
           36.94 35.2599C37.98 37.0799 37.36 39.3999 35.54 40.4399L32.08 42.4199C30.5 43.3599 28.46 42.7999 27.52
           41.2199L27.3 40.8399C25.5 37.6999 22.54 37.6999 20.72 40.8399L20.5 41.2199C19.56 42.7999 17.52 43.3599
           15.94 42.4199L12.48 40.4399C10.66 39.3999 10.04 37.0599 11.08 35.2599C12.9 32.1199 11.42 29.5599 7.8
           29.5599C5.7 29.5599 4 27.8399 4 25.7599Z"
      fill="#B9BAD5"/>
    <path
      d="M24 30.5C27.5899 30.5 30.5 27.5899 30.5 24C30.5 20.4101 27.5899 17.5 24 17.5C20.4101 17.5 17.5 20.4101
      17.5 24C17.5 27.5899 20.4101 30.5 24 30.5Z"
      fill="#9192AD"/>
  </svg>
</template>

<style scoped>

</style>
