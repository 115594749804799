<template>
  <div class="additional-option-wrapper"
       :class="{'always-open': isCollapseVisible}">
    <div class="additional-option">
      <div class="additional-option__icon">
        <icon-task-square/>
      </div>
      <div class="additional-option__name"><span>Устройства</span>
        <span class="additional-option__quantity">{{ devicesList.length }}</span>
      </div>
      <div class="additional-option__buttons">
        <button
          class="additional-option__add"
          type="button"
          @click="showDevicePopup()">
          <icon-plus/>
        </button>
        <button
          @click="isCollapseVisible = !isCollapseVisible"
          class="additional-option__toggle"
          :class="{'rotate': isCollapseVisible}">
          <icon-arrow-double-up/>
        </button>
      </div>
    </div>
    <div class="additional-options-list">
      <div class="additional-options-list__inner">
        <div v-for="device in devicesList"
             :key="device.id"
             @click="showDeviceModal(device.id)"
             class="added-item added-item--flex added-item--cur-pointer">
          <div class="added-item__info">
            <div class="added-item__name">{{ device.deviceName }} - {{device.name}}</div>
            <div class="added-item__additional">
              {{device.connectionTypeName}} | {{device.stateLabel}}
            </div>
          </div>
          <div class="added-item__right">
            <div @click.stop="showDevicePopup(device)">
              <icon-pencil class="svg added-item__tool added-item__tool--size-20"/>
            </div>
            <div @click.stop="deleteDevice(device.id)">
              <icon-trash class="svg added-item__tool"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {showAlert, showToast, showConfirmationModal} from "@/utils/notification";
import IconTaskSquare from "@/components/Icons/IconTaskSquare.vue";
import IconTrash from "@/components/Icons/IconTrash.vue";
import IconPencil from "@/components/Icons/IconPencil.vue";
import IconArrowDoubleUp from "@/components/Icons/IconArrowDoubleUp.vue";
import IconPlus from "@/components/Icons/IconPlus.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import DeviceForm from "@/components/Telemetry/admin/NetworkObject/DeviceForm.vue";

export default {
  name: "DevicesList",
  components: {
    IconPlus,
    IconArrowDoubleUp,
    IconPencil,
    IconTrash,
    IconTaskSquare
  },
  props: {
    networkObjectId: {
      type: Number,
      required: true
    },
    devicesList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isCollapseVisible: true
    }
  },
  methods: {
    showDeviceModal(deviceId) {
      this.$store.dispatch('networkObjectPage/getDevice', deviceId);
      this.$store.dispatch('baseSideModal/openModal', {
        modalContentType: 'networkObjectDevice'
      })
    },
    showDevicePopup(device = {}) {
      this.$modal.show(
        DeviceForm,
        {
          'networkObjectId': this.networkObjectId,
          'device': device,
          'popupName': 'device-modal'
        },
        {
          name: 'device-modal',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460),
          scrollable: true
        })
    },
    deleteDevice(deviceId) {
      showConfirmationModal('Вы уверены, что хотите удалить устройство? Это действие невозвратно.')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('device/deleteDevice', deviceId)
              .then(() => {
                this.$store.dispatch('networkObjectPage/getNetworkObjectDevices', this.networkObjectId);
                showToast('Устройство удалено', 'success');
              })
              .catch(error => showAlert('Ошибка', error));
          }
        })
    }
  }
}
</script>

<style scoped>
.device__name {
  font-size: 18px;
  color: var(--text-link);
  font-weight: bold;
}

.device__info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  row-gap: 16px;
}
</style>
