import TasksService from "@/services/TasksService";
import TaskState from "@/models/serviceDesk/TaskState";

export const namespaced = true

export const state = {
    tasks: [],
    filterParams: {
        state: null,
        date: null,
        type: null
    },
    meta: null,
    links: null,
    loading: false,
    error: null
}

export const mutations = {
    SET_TASKS(state, tasks) {
        state.tasks = tasks;
    },
    SET_FILTER_PARAM(state, {name, value}) {
        state.filterParams[name] = value;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    UPDATE_TASK_STATE(state, {taskId, taskState}) {
        const taskIndex = state.tasks.findIndex(task => task.id === taskId);
        if (taskIndex > 0) state.tasks[taskIndex].state = new TaskState(taskState);
    }
}

export const actions = {
    getUserTasks({commit}, page) {
        commit("SET_LOADING", true);
        TasksService.getUserTasks(page)
            .then(({tasks, meta, links}) => {
                commit("SET_TASKS", tasks);
                commit("SET_META", meta);
                commit("SET_LINKS", links);
            })
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getTasks({commit, state}) {
        commit("SET_LOADING", true);
        TasksService.getTasks(state.filterParams)
            .then(tasks => {
                commit("SET_TASKS", tasks);
            })
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getAllTasks({commit}, filterParams) {
        commit("SET_LOADING", true);
        TasksService.fetchAllTasks(filterParams)
            .then(tasks => commit("SET_TASKS", tasks))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    }
}

export const getters = {
    getTasks: state => {
        return state.tasks;
    },
    getFilterParams: state => {
        return state.filterParams;
    }
}
