<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      <template v-if="!Object.keys(semanticProvider).length">Добавить провайдер</template>
      <template v-else>Редактирование провайдера</template>
      <span @click="$modal.hide('semantic-provider-form')"
            class="base-popup__close">
        <icon-close/>
      </span>
    </div>
    <div class="popup-form-group">
      <div class="form-item">
        <div class="form-item__name">Наименование</div>
        <div class="form-item__value">
          <input
            v-model="$v.payload.name.$model"
            type="text"
            class="base-input base-input--bordered"
            :class="{'base-input--error': $v.payload.name.$error}"
            placeholder="Введите наименование"
          >
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Тип провайдера</div>
        <div class="form-item__value">
          <v-select
            v-model="$v.payload.provider_type.$model"
            :options="semanticProviderTypesList"
            :reduce="type => type.id"
            :get-option-label="(option) => option.name"
            class="vue-select-input"
            :class="{'vue-select-input--error': $v.payload.provider_type.$error}"
            placeholder="Выберите из списка"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Адрес сервера</div>
        <div class="form-item__value">
          <input
            v-model="payload.url"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите адрес"
          >
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Логин</div>
        <div class="form-item__value">
          <input
            v-model="payload.login"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите логин"
          >
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Пароль</div>
        <div class="form-item__value">
          <input
            v-model="payload.password"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите пароль"
          >
        </div>
      </div>
    </div>
    <div class="base-popup__footer">
      <div @click="checkFormType()"
           class="button">
        Сохранить
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import IconClose from "@/components/Icons/IconClose.vue";

export default {
  name: "SemanticProvidersForm",
  components: {IconClose},
  mixins: [validationMixin],
  props: {
    semanticProviderTypesList: {
      type: Array,
      required: true
    },
    semanticProvider: {
      type: Object,
      required: true
    },
    createSemanticProvider: {
      type: Function,
      default: () => {}
    },
    updateSemanticProvider: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      payload: {
        name: null,
        provider_type: null,
        url: null,
        login: null,
        password: null
      }
    }
  },
  validations: {
    payload: {
      name: {required},
      provider_type: {required}
    }
  },
  mounted() {
    if (Object.keys(this.semanticProvider).length) {
      const {name, typeId, serverUrl, serverLogin, serverPassword} = this.semanticProvider;
      this.payload.name = name;
      this.payload.provider_type = typeId;
      this.payload.url = serverUrl;
      this.payload.login = serverLogin;
      this.payload.password = serverPassword;
    }
  },
  methods: {
    checkFormType() {
      this.$v.$touch()
      if (this.$v.$invalid) return false

      if (Object.keys(this.semanticProvider).length) this.updateSemanticProvider(this.semanticProvider.id, this.payload)
      else this.createSemanticProvider(this.payload)
    }
  }
}
</script>

<style scoped>

</style>