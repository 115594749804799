<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      <template v-if="!Object.keys(energyConsumptionObject).length">Добавить объект энергопотребления</template>
      <template v-else>Редактирование объекта энергопотребления</template>
      <span @click="$modal.hide('energy-consumption-object-form')"
            class="base-popup__close">
        <icon-close/>
      </span>
    </div>
    <div class="popup-form-group">
      <div class="form-item">
        <div class="form-item__name">Наименование</div>
        <div class="form-item__value">
          <input
            v-model="payload.name"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите наименование"
            required
          >
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Адрес</div>
        <div class="form-item__value">
          <address-selector
            :address-string="payload.address"
            @update:address-string="address => this.payload.address = address"/>
        </div>
      </div>
    </div>
    <div class="base-popup__footer">
      <div @click="checkFormType()"
           class="button">
        {{Object.keys(this.energyConsumptionObject).length ? 'Изменить' : 'Добавить'}}
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from "@/components/Icons/IconClose.vue";
import AddressSelector from "@/components/ServiceDesk/CommonComponents/AddressSelector/AddressSelector.vue";

export default {
  name: "EnergyConsumptionObjectForm",
  components: {AddressSelector, IconClose},
  props: {
    energyConsumptionObject: {
      type: Object,
      default: () => {}
    },
    createEnergyConsumptionObject: {
      type: Function,
      default: () => {}
    },
    updateEnergyConsumptionObject: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      payload: {
        name: null,
        address: ''
      }
    }
  },
  mounted() {
    if (Object.keys(this.energyConsumptionObject).length) {
      const {name, address} = this.energyConsumptionObject;
      this.payload.name = name;
      this.payload.address = address;
    }
  },
  methods: {
    checkFormType() {
      if (Object.keys(this.energyConsumptionObject).length) {
        this.updateEnergyConsumptionObject(this.energyConsumptionObject.id, this.payload)
      } else this.createEnergyConsumptionObject(this.payload)
    }
  }
}
</script>

<style scoped>

</style>
