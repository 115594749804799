import * as API from './API';
import TelemetryPersonalList from "@/models/telemetry/TelemetryPersonalList";
import {getError} from "@/utils/helpers";

export default {
    fetchPersonalLists() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/object-monitoring/personal-lists`)
                .then(response => {
                    resolve(response.data.data.map(item => new TelemetryPersonalList({
                        id: item.id, ...item.attributes
                    })))
                })
                .catch(error => reject(getError(error)))
        })
    },
    createPersonalList(payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`/object-monitoring/personal-lists`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updatePersonalList(id, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/object-monitoring/personal-lists/${id}`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deletePersonalList(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`/object-monitoring/personal-lists/${id}`)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}