export default class EngineeringNetwork {
    constructor(id, params) {
        this.id = id;
        this.name = params.name;
        this.typeId = params.type.id;
        this.typeName = params.type.name;
        this.active = params.active;
    }

    getActiveLabel() {
        switch (this.active) {
            case 1:
                return 'Активно'
            case 0:
                return 'Не активно'
        }
    }
}