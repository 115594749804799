<template>
  <div class="base-table">
    <div class="base-table__container">
      <div class="base-table__header base-table--notifications">
        <div class="base-table__header-cell">#</div>
        <div class="base-table__header-cell">Дата и время отправки</div>
        <div class="base-table__header-cell">Отправитель</div>
        <div class="base-table__header-cell">Адреса</div>
        <div class="base-table__header-cell">Статус</div>
        <div class="base-table__header-cell"/>
      </div>

      <div v-for="notification in notificationsList"
           :key="notification.id"
           @click="$emit('show-notification-modal', notification)"
           class="base-table__row base-table--notifications">
        <div class="base-table__column">
          <div class="base-table__text">{{ notification.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ formatDateTime(notification.shippedDate) }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ notification.author.name }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text base-table__text--overflow">{{ notification.messageBody }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ notification.state.name }}</div>
        </div>
        <div class="base-table__column base-table__column--content-right">
          <div
            v-if="notification.isStateDraft"
            class="base-table__short-info">
            <div @click.stop="$emit('edit-notification', notification)">
              <icon-pencil class="base-table__short-info--p-2"/>
            </div>
            <div @click.stop="$emit('delete-notification', notification.id)">
              <icon-trash/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconTrash from "@/components/Icons/IconTrash.vue";
import IconPencil from "@/components/Icons/IconPencil.vue";
import {formatDateTime} from "@/utils/filters";

export default {
  name: "ShutdownNotificationsList",
  components: {IconPencil, IconTrash},
  props: {
    notificationsList: {
      type: Array,
      required: true
    }
  },
  methods: {
    formatDateTime
  }
}
</script>

<style scoped>
.base-table--notifications {
  grid-template-columns: 6fr 18fr 20fr 70fr 12fr 10fr;
}
</style>