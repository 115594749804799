import SubscriptionsService from "@/services/SubscriptionsService";
import {getError} from "@/utils/helpers";

export const namespaced = true;

export const state = {
    subscriptionTypes: [],
    userSubscriptions: [],
    loading: false,
    error: null
}

export const mutations = {
    SET_SUBSCRIPTION_TYPES(state, types) {
        state.subscriptionTypes = types;
    },
    SET_USER_SUBSCRIPTIONS(state, subscriptions) {
        state.userSubscriptions = subscriptions;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    DELETE_SUBSCRIPTION(state, subscriptionId) {
        const index = state.userSubscriptions.findIndex(item => item.id === subscriptionId);
        state.userSubscriptions.splice(index, 1);
    }
}

export const actions = {
    getSubscriptionTypes({commit}) {
        SubscriptionsService.fetchSubscriptionsList()
            .then(subscriptionTypes => commit("SET_SUBSCRIPTION_TYPES", subscriptionTypes))
            .catch(error => commit("SET_ERROR", error))
    },
    getUserSubscriptions({commit}, userId) {
        commit("SET_LOADING", true);
        SubscriptionsService.fetchUserSubscriptions(userId)
            .then(userSubscriptions => commit("SET_USER_SUBSCRIPTIONS", userSubscriptions))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false))
    },
    createSubscription(context, payload) {
        return new Promise((resolve, reject) => {
            SubscriptionsService.createSubscription(payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteSubscription(context, subscriptionId) {
        return new Promise((resolve, reject) => {
            SubscriptionsService.deleteSubscription(subscriptionId)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}