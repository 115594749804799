import * as API from './API';
import Question from "@/models/TechnicalInspection/Question";
import {getError} from "@/utils/helpers";

export default {
    fetchQuestionsList(objectTypeId) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/technical-inspection/reference-books/questions?object_type=${objectTypeId}`)
                .then(response => resolve(response.data.data
                    .map(item => new Question({id: item.id, ...item.attributes}))))
                .catch(error => reject(getError(error)))
        })
    },
    createQuestion(payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`/technical-inspection/reference-books/questions`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateQuestion(id, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/technical-inspection/reference-books/questions/${id}`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteQuestion(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`/technical-inspection/reference-books/questions/${id}`)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}