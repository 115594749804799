<template>
  <svg width="24"
       height="24"
       viewBox="0 0 24 24"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <g id="Iconsax/Linear/arrowsquareleft">
      <g id="Group 42">
        <path id="Vector"
              d="M18.0859 18.0859L12.1029 12.0859L18.0859 6.08594"
              stroke=""
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"/>
        <path id="Vector_2"
              d="M11.8975 18.0859L5.91446 12.0859L11.8975 6.08594"
              stroke=""
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"/>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "IconArrowDouble"
}
</script>
