<template>
  <div class="links-pagination">
    <div v-for="(link, index) in meta.links"
         :key="index">
      <div v-if="index === 0"
           v-show="meta.current_page !== 1"
           @click="paginate(meta.current_page - 1)"
           class="nav-arrow">
        <icon-arrow-left/>
      </div>
      <div v-else-if="index === meta.links.length - 1"
           v-show="meta.current_page !== meta.last_page"
           @click="paginate(meta.current_page + 1)"
           class="nav-arrow">
        <icon-arrow-right/>
      </div>
      <div v-else-if="link.label === '...'"
           class="hidden-elements">
        {{ link.label }}
      </div>
      <div v-else
           @click="paginate(parseInt(link.label))"
           class="pagination-item"
           :class="{'pagination-item--active': link.active}">
        {{ link.label }}
      </div>
    </div>
  </div>
</template>

<script>
import IconArrowLeft from "@/components/Icons/IconArrowLeft.vue";
import IconArrowRight from "@/components/Icons/IconArrowRight.vue";

export default {
  name: "BasePaginate",
  components: {IconArrowRight, IconArrowLeft},
  props: {
    action: {
      type: String,
      required: true
    },
    commit: {
      type: String
    },
    meta: {
      type: Object,
      required: true
    }
  },
  methods: {
    paginate(page) {
      this.$store.commit(this.commit, page);
      this.$store.dispatch(this.action, page);
    }
  }
}
</script>

<style lang="scss">
.links-pagination {
  display: flex;
  margin-top: .5rem;
  justify-content: center;
}

.pagination-item {
  margin-right: 5px;
  padding: 8px 12px;
  color: var(--text-secondary);
  cursor: pointer;
  background-color: var(--panel);

  &--active {
    border-radius: 8px;
    border: 1px solid var(--border-main);
  }
}

.hidden-elements {
  display: flex;
  align-items: flex-end;
  color: var(--text-link);
  margin-right: 5px;
  font-size: 17px;
  height: 100%;
  padding: 0 5px;
}

.nav-arrow {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;

  & svg {
    & path {
      stroke: var(--ico-active);
    }
  }
}
</style>
