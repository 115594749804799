import * as API from './API';
import Violation from "@/models/TechnicalInspection/Violation";
import {getError} from "@/utils/helpers";

export default {
    fetchViolationsList(objectTypeId) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/technical-inspection/reference-books/violations?object_type=${objectTypeId}`)
                .then(response => resolve(response.data.data
                    .map(item => new Violation({id: item.id, ...item.attributes}))))
                .catch(error => reject(getError(error)))
        })
    },
    createViolation(payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`/technical-inspection/reference-books/violations`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateViolation(id, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/technical-inspection/reference-books/violations/${id}`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteViolation(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`/technical-inspection/reference-books/violations/${id}`)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteViolationField(id, fieldId) {
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`/technical-inspection/reference-books/violations/${id}/fields/${fieldId}`)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}