<template>
  <div class="i-modal__skeleton">
    <div class="i-modal__skeleton-header">
      <base-content-loaders-placeholder />
    </div>
    <div class="i-modal__skeleton-body">
      <base-content-loaders-placeholder />
      <base-content-loaders-placeholder />
      <base-content-loaders-placeholder />
    </div>
  </div>
</template>

<script>
import BaseContentLoadersPlaceholder from "@/components/BaseContentLoadersPlaceholder.vue";

export default {
  name: "BaseSideModalSkeleton",
  components: {BaseContentLoadersPlaceholder}
}
</script>

<style scoped>

</style>
