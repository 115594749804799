<template>
  <div
    class="map-marker">
    <map-icon-bg/>
    <div class="map-marker__inner">
      <div
        class="map-marker__img"
        :class="[
          {'map-marker__img--bg-new': issue.state.id === IssueState.NEW},
          {'map-marker__img--bg-work': issue.state.id === IssueState.IN_WORK},
          {'map-marker__img--bg-done': issue.state.id === IssueState.CLOSED},
          {'map-marker__img--bg-canceled': issue.state.id === IssueState.CANCELED}
        ]">
        <icon-document-text class="map-marker__img--white"/>
      </div>
    </div>
  </div>
</template>

<script>
import IconDocumentText from "@/components/Icons/IconDocumentText";
import MapIconBg from "@/components/Icons/MapIconBg";
import IssueState from "@/models/serviceDesk/IssueState";

export default {
  name: "IssueMapIcon",
  components: {MapIconBg, IconDocumentText},
  props: {
    issue: {
      type: Object
    }
  },
  computed: {
    IssueState: () => IssueState
  }
}
</script>

<style scoped>

</style>
