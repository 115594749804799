<template>
  <div class="scada-map">
    <base-map
      :center="center"
      :zoom="zoom"
      :base-layers="baseLayers"
      :markers="createMapMarkers()"
    />
  </div>
</template>

<script>
import BaseMap from "@/components/BaseMap";
import {mapGetters, mapState} from "vuex";
import Marker from "@/models/baseMap/Marker";
import Icon from '@/models/baseMap/Icon';
import ScadaMapIcon from "@/components/Telemetry/Scada/ScadaMapIcon";
import Vue from "vue";
import ScadaMapPopupContent from "@/components/Telemetry/Scada/ScadaMapPopupContent";

export default {
  name: "ScadaMap",
  components: {BaseMap},
  computed: {
    ...mapState({
      center: state => state.baseMap.center,
      zoom: state => state.baseMap.zoom,
      baseLayers: state => state.baseLayers.baseLayers,
      networkEquipments: state => state.telemetry.networkEquipments
    }),
    ...mapGetters({
      networkObjectDangerStatus: 'telemetry/getNetworkObjectDangerStatus',
      networkObjectEquipments: 'telemetry/getNetworkEquipmentByNetworkObjectId',
      networkEquipmentWithParametersValues: 'telemetry/getNetworkEquipmentsWithParameterValues',
      getNetworkObjectsContainEquipments: 'telemetry/getNetworkObjectsContainEquipments'
    })
  },
  mounted() {

  },
  methods: {
    createMapMarkers() {
      return this.getNetworkObjectsContainEquipments
          .concat()
          .filter(object => this.networkObjectEquipments(object.id).length)
          .map(object => {
            const ScadaMapIconClass = Vue.extend(ScadaMapIcon)
            const iconInstance = new ScadaMapIconClass();
            iconInstance.isDanger = this.networkObjectDangerStatus(object.id);
            iconInstance.iconGroup = object._type.groupName;

            const icon = iconInstance.$mount();
            const iconHtml = icon.$el.outerHTML;

            const ScadaMapPopupContentClass = Vue.extend(ScadaMapPopupContent);
            const popupInstance = new ScadaMapPopupContentClass();
            popupInstance.networkEquipments = this.networkEquipmentWithParametersValues(object.id);

            const popupContent = popupInstance.$mount();
            const popupContentHtml = popupContent.$el.outerHTML;

            const marker = new Marker({
              coords: object.coordinates,
              accumulation: true,
              icon: new Icon({
                type: 'div_icon',
                size: [36, 42],
                anchor: [18, 21],
                html: iconHtml
              }),
              popup_content: popupContentHtml
            })

            icon.$destroy();
            popupContent.$destroy();

            return marker
          })
    }
  }
}
</script>

<style lang="scss">
.scada-map {
  height: calc(100vh - 230px);
  position: relative;
  z-index: 9;

  & .leaflet-popup-close-button {
    display: none;
  }

  & .leaflet-popup-tip-container {
    display: none;
  }

  & .leaflet-popup-content-wrapper {
    background: none !important;
    backdrop-filter: none !important;
    box-shadow: none !important;
    opacity: 1;
  }

  & .leaflet-popup-content {
    margin: 0;
  }
}
</style>
