import * as API from './API'
import NetworkEquipmentType from "@/models/telemetry/NetworkEquipmentType";
import {getError} from "@/utils/helpers";

export default {
    fetchNetworkEquipmentTypesService() {
        return new Promise((resolve, reject) => {
            API.apiClient.get('/network-equipment-types')
                .then(response => resolve(response.data.data.map(item => new NetworkEquipmentType({
                    id: item.id,
                    ...item.attributes
                }))))
                .catch(error => reject(getError(error)))
        })
    },
    createNetworkEquipmentType(payload) {
        return API.apiClient.post('/network-equipment-types', payload);
    },
    deleteNetworkEquipmentType(id) {
        return API.apiClient.delete(`/network-equipment-types/${id}`);
    }
}