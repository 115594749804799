<template>
  <div
    class="base-table__header"
    :class="headerClassName">
    <div
      v-for="header in tableHeaders"
      :key="header.id"
      @click="toggleSort(header)"
      class="base-table__header-cell sort-disabled"
      :class="[
        {'base-table__header-cell-sort': header.sortName},
        {'desc': header.sortDir === 'desc'},
        {'asc': header.sortDir === 'asc'}
      ]"
    >
      {{ header.name }}
      <icon-sort v-if="header.sortName"/>
    </div>
  </div>
</template>

<script>
import IconSort from "@/components/Icons/IconSort.vue";

export default {
  name: "BaseTableHeader",
  components: {IconSort},
  props: {
    tableHeaders: {
      type: Array,
      required: true
    },
    headerClassName: {
      type: String
    }
  },
  methods: {
    toggleSort(header) {
      if (header.sortName) {
        header.sortDir = header.sortDir === 'desc' || header.sortDir === null ? 'asc' : 'desc';

        for (let i = 0; i < this.tableHeaders.length; i++) {
          if (header.id !== this.tableHeaders[i].id) this.tableHeaders[i].sortDir = null;
        }

        this.$emit('update-sort-by', header.sortName);
        this.$emit('update-sort-order', header.sortDir);
      }
    }
  }
}
</script>

<style scoped>

</style>