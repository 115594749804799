<template>
  <svg width="20"
       height="20"
       viewBox="0 0 20 20"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <g id="Group 48">
      <path id="Vector"
            d="M19 10C19 14.968 14.968 19 10 19C5.032 19 1 14.968 1 10C1 5.032 5.032 1 10 1C14.968 1 19 5.032 19 10Z"
            stroke="#9192AD"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"/>
      <path id="Vector_2"
            d="M13.34 12.8639L10.55 11.1989C10.064 10.9109 9.66797 10.2179 9.66797 9.65094V5.96094"
            stroke="#9192AD"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconHistoryToggle"
}
</script>

<style scoped>

</style>
