import NetworkElementTypesService from "@/services/NetworkElementTypesService";

export const namespaced = true;

export const state = {
    types: [],
    loading: true,
    error: null
}

export const mutations = {
    SET_TYPES(state, types) {
        state.types = types;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getNetworkElementTypes({commit}) {
        commit("SET_LOADING", true);
        NetworkElementTypesService.fetchNetworkTypes()
            .then(types => commit("SET_TYPES", types))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    }
}

export const getters = {
    getElementTypes: state => {
        return state.types;
    }
}