<script>
export default {
  name: "IconCloseSquare"
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none">
    <rect
      x="1"
      y="1"
      width="18"
      height="18"
      rx="5"
      fill="#FF3A81"/>
    <rect
      x="1"
      y="1"
      width="18"
      height="18"
      rx="5"
      stroke="white"
      stroke-width="2"/>
    <path
      d="M6.69827 13.2998L13.2979 6.70017M13.2979 13.2998L6.69827 6.70017"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>

<style scoped>

</style>