<template>
  <div class="base-table">
    <div class="base-table__container">
      <div class="base-table__header base-table__column--departments">
        <div class="base-table__header-cell">ID</div>
        <div class="base-table__header-cell">Наименование</div>
        <div class="base-table__header-cell">Статус</div>
        <div class="base-table__header-cell"/>
      </div>

      <div v-for="department in departmentsList"
           :key="department.id"
           @click="$emit('open-department-modal', department.id)"
           class="base-table__row base-table__column--departments">
        <div class="base-table__column">
          <div class="base-table__text">{{ department.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ department.name }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__num">{{ department.getActivityLabel() }}</div>
        </div>
        <div class="base-table__column base-table__column--content-right">
          <div class="base-table__short-info">
            <div @click.stop="$emit('edit-department', department)">
              <icon-pencil class="base-table__short-info--p-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconPencil from "@/components/Icons/IconPencil.vue";

export default {
  name: "DepartmentsList",
  components: {IconPencil},
  props: {
    departmentsList: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.base-table__column--departments {
  grid-template-columns: 4.07% 37.26% 37.26% 1fr;
}
</style>
