import NetworkObjectType from "@/models/telemetry/NetworkObjectType";
import StructuralUnit from "@/models/StructuralUnit";
import IconOtherType from "@/components/Icons/IconOtherType.vue";
import IconPump from "@/components/Icons/IconPump.vue";
import IconWaterTower from "@/components/Icons/IconWaterTower.vue";
import IconBuilding from "@/components/Icons/IconBuildingColored.vue";
import IconCounter from "@/components/Icons/IconCounter.vue";
import IconBoiler from "@/components/Icons/IconBoiler.vue";
import IconDrainage from "@/components/Icons/IconDrainage.vue";
import IconSource from "@/components/Icons/IconSource.vue";

export default class NetworkObject {
    constructor({id, attributes}) {
        this.id = id;
        this.name = attributes.name;
        this.type = attributes.construction_type;
        this.address = attributes.address;
        this.phone = attributes.phone;
        this.coordinates = attributes.coordinates ? attributes.coordinates.coordinates.reverse() : [];
        this.balanceSheetId = attributes.balance_sheet?.id;
        this.balanceSheetName = attributes.balance_sheet?.attributes.name;
        this._type = attributes.type ? new NetworkObjectType(attributes.type) : null;
        this.structuralUnits = attributes.structural_units.map(item => new StructuralUnit({
            id: item.id,
            ...item.attributes
        }));
        this.orderNumber = attributes.order_number;
    }

    get structuralUnitsNames() {
        return this.structuralUnits.map(item => item.name).join(', ');
    }

    getTypeLabel() {
        switch (this.type) {
            case 1:
                return 'ВНС'
            case 2:
                return 'РЧВ'
            case 3:
                return 'МКД'
            case 4:
                return 'КП'
            case 5:
                return 'Котельная'
            case 6:
                return 'Стоки'


        }
    }

    static getTypesArray() {
        return [
            {id: 1, label: 'ВНС'},
            {id: 2, label: 'РЧВ'},
            {id: 3, label: 'МКД'},
            {id: 4, label: 'КП'},
            {id: 5, label: 'Котельная'},
            {id: 6, label: 'Стоки'},
            {id: 7, label: 'Источники воды'}
        ]
    }

    static getIconComponentsMap() {
        return {
            'Другое': IconOtherType,
            'ВНС': IconPump,
            'РЧВ': IconWaterTower,
            'МКД': IconBuilding,
            'КП': IconCounter,
            'Котельная': IconBoiler,
            'Стоки': IconDrainage,
            'Источник воды': IconSource
        }
    }
}
