export const taskMaterialsReportPdf = tableData => {
    const docDefinition = {
        content: [
            {
                columns: [
                    {
                        width: '40%',
                        text: ''
                    },
                    {
                        width: '*',
                        text: ''
                    },
                    {
                        width: '40%',
                        text: `УТВЕРЖДАЮ\n\n\n\n\n\n\n`
                    }
                ]
            },
            {
                columns: [
                    {
                        width: '40%',
                        text: '____________________________________',
                        margin: 0

                    },
                    {
                        width: '*',
                        text: ''
                    },
                    {
                        width: '40%',
                        text: '____________________________________',
                        margin: 0
                    }
                ]
            },
            {
                columns: [
                    {
                        style: 'description',
                        width: '40%',
                        text: '(организация)'
                    },
                    {
                        width: '*',
                        text: ''
                    },
                    {
                        style: 'description',
                        width: '40%',
                        text: '(должность)'
                    }
                ],
                margin: [ 0, 0, 0, 10 ]
            },
            {
                columns: [
                    {
                        fontSize: 11,
                        width: '58%',
                        text: '____________________________________'
                    },
                    {
                        width: '*',
                        text: ''
                    },
                    {
                        width: '20%',
                        text: '___________________'
                    },
                    {
                        width: '2%',
                        text: ''
                    },
                    {
                        width: '20%',
                        text: '___________________'
                    }
                ],
                margin: 0
            },
            {
                columns: [
                    {
                        style: 'description',
                        width: '40%',
                        text: '(структурное подразделение)'
                    },
                    {
                        width: '*',
                        text: ''
                    },
                    {
                        style: 'description',
                        width: '17%',
                        text: '(подпись)'
                    },
                    {
                        width: '6%',
                        text: ''
                    },
                    {
                        style: 'description',
                        width: '17%',
                        text: '(расшифровка подписи)'
                    }
                ],
                margin: [ 0, 0, 0, 5 ]
            },
            {
                columns: [
                    {
                        width: '*',
                        text: ''
                    },
                    {
                        width: '25%',
                        text: '___________________'
                    }
                ],
                margin: [ 0, 0, 0, 20 ]
            },
            {
                text: 'Акт на списание материалов № ______ от ____________ г.',
                margin: [ 0, 0, 0, 0 ]
            },
            {
                text: `Настоящий акт составлен о том, что нижеперечисленные материалы израсходованы в полном объеме.
                    Цель расхода: Отпуск в производство`,
                margin: [ 0, 0, 0, 20 ]
            },
            {
                layout: 'lightHorizontalLines',
                style: 'table',
                table: {
                    headerRows: 1,
                    widths: ['10%','10%','10%','10%','10%','20%','10%','10%','10%'],

                    body: tableData
                }
            },
            {
                columns: [
                    {
                        fontSize: 9,
                        width: '20%',
                        text: 'Председатель комиссии:',
                        margin: [0,0,8,0]
                    },
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '___________________',
                        margin: [0,0,8,0]
                    },
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '___________________',
                        margin: [0,0,8,0]
                    },
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '___________________',
                        margin: [0,0,8,0]
                    }
                ]
            },
            {
                columns: [
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '',
                        margin: [0,0,8,10]
                    },
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '(должность)',
                        margin: [0,0,8,10]
                    },
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '(подпись)',
                        margin: [0,0,8,10]
                    },
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '(расшифровка подписи)',
                        margin: [0,0,8,10]
                    }
                ]
            },
            {
                columns: [
                    {
                        fontSize: 9,
                        width: '20%',
                        text: 'Члены комиссии:',
                        margin: [0,0,8,0]
                    },
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '___________________',
                        margin: [0,0,8,0]
                    },
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '___________________',
                        margin: [0,0,8,0]
                    },
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '___________________',
                        margin: [0,0,8,0]
                    }
                ]
            },
            {
                columns: [
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '',
                        margin: [0,0,8,10]
                    },
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '(должность)',
                        margin: [0,0,8,10]
                    },
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '(подпись)',
                        margin: [0,0,8,10]
                    },
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '(расшифровка подписи)',
                        margin: [0,0,8,10]
                    }
                ]
            },
            {
                columns: [
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '',
                        margin: [0,0,8,0]
                    },
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '___________________',
                        margin: [0,0,8,0]
                    },
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '___________________',
                        margin: [0,0,8,0]
                    },
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '___________________',
                        margin: [0,0,8,0]
                    }
                ]
            },
            {
                columns: [
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '',
                        margin: [0,0,8,10]
                    },
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '(должность)',
                        margin: [0,0,8,10]
                    },
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '(подпись)',
                        margin: [0,0,8,10]
                    },
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '(расшифровка подписи)',
                        margin: [0,0,8,10]
                    }
                ]
            },
            {
                columns: [
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '',
                        margin: [0,0,8,0]
                    },
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '___________________',
                        margin: [0,0,8,0]
                    },
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '___________________',
                        margin: [0,0,8,0]
                    },
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '___________________',
                        margin: [0,0,8,0]
                    }
                ]
            },
            {
                columns: [
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '',
                        margin: [0,0,8,10]
                    },
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '(должность)',
                        margin: [0,0,8,10]
                    },
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '(подпись)',
                        margin: [0,0,8,10]
                    },
                    {
                        fontSize: 9,
                        width: '20%',
                        text: '(расшифровка подписи)',
                        margin: [0,0,8,10]
                    }
                ]
            }
        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10]
            },
            subHeader: {
                fontSize: 16,
                bold: true,
                margin: [0, 0, 0, 10]
            },
            description: {
                fontSize: 9,
                margin: [0, 5, 0, 15]
            },
            table: {
                fontSize: 9,
                margin: [0, 5, 0, 15]
            }
        }
    }

    const pdfMake = require('pdfmake');
    pdfMake.createPdf(docDefinition).open();
}