<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="24"
       height="24"
       viewBox="0 0 24 24"
       fill="none">
    <path d="M9.32031 6.4975L11.8803 3.9375L14.4403 6.4975"
          stroke="#9192AD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M11.8789 14.1778V4.00781"
          stroke="#9192AD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M4 12C4 16.42 7 20 12 20C17 20 20 16.42 20 12"
          stroke="#9192AD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconExport2"
}
</script>

<style scoped>

</style>
