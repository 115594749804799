import ElectricMetersService from "@/services/ElectricMetersService";

export const namespaced = true;

export const state = {
    error: null
}

export const mutations = {
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    createElectricMeter(context, {energyConsumptionObjectId, payload}) {
        return new Promise((resolve, reject) => {
            ElectricMetersService.createElectricMeter(energyConsumptionObjectId, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    updateElectricMeter(context, {electricMeterId, energyConsumptionObjectId, payload}) {
        return new Promise((resolve, reject) => {
            ElectricMetersService.updateElectricMeter(electricMeterId, energyConsumptionObjectId, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    deleteElectricMeter(context, {electricMeterId, energyConsumptionObjectId}) {
        return new Promise((resolve, reject) => {
            ElectricMetersService.deleteElectricMeter(electricMeterId, energyConsumptionObjectId)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    }
}