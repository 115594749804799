export class IssueType {
    constructor(id, name) {
        this.id = id;
        this.name = name;
    }

    get isPlanned() {
        return this.id === 2;
    }
}
