import * as API from './API'
import NetworkElementType from "@/models/EngineeringNetworks/NetworkElementType";
import {getError} from "@/utils/helpers";

export default {
    fetchNetworkTypes() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`network-engineering/element-types`)
                .then(response => resolve(
                    response.data.data.map(item => new NetworkElementType(item.id, item.attributes))
                ))
                .catch(error => reject(getError(error)));
        })
    }
}