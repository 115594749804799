import {getError} from "@/utils/helpers";
import AutosService from "@/services/AutosService";
import moment from "moment";

export const namespaced = true;

export const state = {
    transportData: [],
    loading: false,
    error: null
};

export const mutations = {
    SET_DATA(state, data) {
        state.transportData = data;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
};

export const actions = {
    transportData({commit}) {
        commit("SET_LOADING", true);
        AutosService.searchTransportDataByIds()
            .then(response => {
                commit("SET_DATA", response.data.data);
                commit("SET_LOADING", false);
            })
            .catch(error => {
                commit("SET_LOADING", false);
                commit("SET_ERROR", getError(error));
            });
    }
};

export const getters = {
    complete: state => {
        return state.transportData != null;
    },
    getVehicleData: state => id => {
        return state.transportData.find(d => d.id === parseInt(id));
    },
    getLat: (state, getters) => id => {
        const vehicleData = getters.getVehicleData(id);
        return vehicleData ? vehicleData.attributes.lat : 0;
    },
    getLon: (state, getters) => id => {
        const vehicleData = getters.getVehicleData(id);
        return vehicleData ? vehicleData.attributes.lon : 0;
    },
    getSpeed: (state, getters) => id => {
        const vehicleData = getters.getVehicleData(id);
        return vehicleData ? vehicleData.attributes.speed : 0;
    },
    getVehicleSpeedStatus: (state, getters) => id => {
        const vehicleData = getters.getVehicleData(id);
        const speed = vehicleData?.attributes.speed;
        if (speed > 0 && speed <= 60) return 'success'
        else if (speed > 60 && speed <= 100) return 'warning'
        else if (speed > 100) return 'danger'
        else return 'normal'
    },
    getStreet: state => id => {
        if(state.transportData != null){
            let s = state.transportData.find(d => d.id == id);
            if(s != null){
                return s.attributes.address;
            }else{
                return '-';
            }
        }else{
            return '-';
        }
    },
    getSatellite: state => id => {
        if(state.transportData != null){
            let s = state.transportData.find(d => d.id == id);
            if(s != null){
                return s.attributes.satellite;
            }else{
                return 0;
            }
        }else{
            return 0;
        }
    },
    getShortStreet: state => id => {
        if (state.transportData != null) {
            let s = state.transportData.find(d => d.id == id);
            if(s != null){
                return s.attributes.address;
            }else{
                return '-';
            }
        } else {
            return '-';
        }
    },
    getEngineStatus: state => id => {
        if(state.transportData != null){
            let s = state.transportData.find(d => d.id == id);
            if(s != null){
                return s.attributes.engine;
            }else{
                return false;
            }
        }else{
            return false;
        }
    },
    getTimestamp: state => id => {
        if(state.transportData != null){
            let s = state.transportData.find(d => d.id == id);
            if(s != null){
                return moment(s.attributes.date).format("HH:mm");
            }else{
                return '-';
            }
        }else{
            return '-';
        }
    },
    getSortList: state => {
        if (state.transportData != null) {
            return state.transportData.map(function (d){
                return {id: d.id, speed: d.attributes.speed}
            }).sort((a,b) => b.speed - a.speed);
        } else {
            return [];
        }
    },
    loading: state => {
        return state.loading;
    },
    error: state => {
        return state.error;
    }
}
