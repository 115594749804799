export const namespaced = true;

export const state = {
    currentPageView: 'TABLE',
    filterEquipmentByDangerStatus: false,
    parameterNames: ['T1', 'T2', 'P1', 'P2', 'G1', 'G2', 'Q', '∆G', '∆Q'],
    filterByNameQuery: '',
    filterByStructuralUnitIds: []
}

export const mutations = {
    SET_CURRENT_PAGE_VIEW(state, pageView) {
        state.currentPageView = pageView;
    },
    TOGGLE_DANGER_STATUS_FILTER(state) {
        state.filterEquipmentByDangerStatus = !state.filterEquipmentByDangerStatus;
    },
    SET_FILTER_BY_NAME_QUERY(state, query) {
        state.filterByNameQuery = query;
    },
    SET_STRUCTURAL_UNIT_IDS(state, ids) {
        state.filterByStructuralUnitIds = ids;
    }
}