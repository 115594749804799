<template>
  <div class="balance-result">
    <div v-if="grouping === 0">
      <div
        v-for="balance in $store.getters['balance/balance']"
        :key="balance.id"
        class="balance-item"
      >
        <div class="group-title">
          <span>Зона: </span>
          <span>{{ balance.name }}</span>
        </div>

        <div class="balance-union-group">
          <div class="balance-union-group__table">
            <table class="table">
              <thead class="table-bg-color sticky-head">
                <tr>
                  <th>Наименование</th>
                  <th>Объём</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(value, key) in balance.getDevicesSupplySumForAllPeriods()"
                  :key="key"
                >
                  <td>{{ getDeviceName(key) }}</td>
                  <td>{{ value.toFixed(0) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="balance-union-group__chart">
            <div class="pie-chart-block">
              <pie
                :pie-chart-data="processPieChartData(balance.sumPeriodsConsumption(), balance.calculatePeriodsLosses())"
              />
            </div>
            <div class="pie-data">
              <div>
                <div v-if="isVs"
                     class="pie-data-title">
                  Подача воды в сеть:
                </div>
                <div v-else
                     class="pie-data-title">
                  Подача стоков в сеть:
                </div>
                <div class="pie-data-value">
                  {{ balance.sumPeriodsSupply() }} м<sup>3</sup>
                </div>
              </div>
              <div>
                <div class="pie-data-title">
                  Начисленный объём:
                </div>
                <div class="pie-data-value text-blue">
                  {{ balance.sumPeriodsConsumption() }} м<sup>3</sup>
                </div>
              </div>
              <div>
                <div v-if="isVs"
                     class="pie-data-title">
                  Потери:
                </div>
                <div v-else
                     class="pie-data-title">
                  Профицит стока:
                </div>
                <div class="pie-data-value text-red">
                  {{ balance.calculatePeriodsLosses() }} м<sup>3</sup>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr>
      </div>
    </div>

    <div v-else-if="grouping === 1">
      <div
        v-for="balance in $store.getters['balance/balance']"
        :key="balance.id"
        class="balance-item"
      >
        <div class="balance-months-group">
          <div class="balance-months-group__title">
            <div class="group-title">
              <span>Зона: </span>
              <span>{{ balance.name }}</span>
            </div>
            <div>
              <download-excel
                :data="processExportData(balance)"
                :header="balance.name"
                :worksheet="balance.name"
                class="btn custom-btn-blue-outline-xs"
                default-value="0"
                name="Баланс"
              >
                Экспорт в Excel
              </download-excel>
            </div>
          </div>

          <div class="balance-months-group__result">
            <div class="base-table">
              <div class="base-table__container">
                <div class="base-table__header base-table__row--balance">
                  <div class="base-table__header-cell"/>
                  <div class="base-table__header-cell">Месяц</div>
                  <div class="base-table__header-cell">Подача</div>
                  <div class="base-table__header-cell">Начисления</div>
                  <div
                    v-if="isVs"
                    class="base-table__header-cell">
                    Потери (%)
                  </div>
                  <div
                    v-else
                    class="base-table__header-cell">
                    Профицит (%)
                  </div>
                  <div
                    v-if="isVs"
                    class="base-table__header-cell">
                    Потери (₽)
                    <span
                      @click="showRatePopup(balance.periods)"
                      class="balance-rate-settings">
                      <icon-settins/>
                    </span>
                  </div>
                  <div
                    v-else
                    class="base-table__header-cell">
                    Профицит (₽)
                    <span
                      @click="showRatePopup(balance.periods)"
                      class="balance-rate-settings">
                      <icon-settins/>
                    </span>
                  </div>
                </div>

                <balance-result-row-item
                  v-for="period in balance.periods"
                  :key="period.period"
                  :period="period"
                  :get-losses-in-rubles="getLossesInRubles"
                  :get-device-name="getDeviceName"
                />
              </div>
            </div>

            <div class="balance-months-group__chart">
              <column
                ref="chart"
                :column-chart-data="generateChartData(balance)"
                :labels="setChartLabels"
              />
            </div>
          </div>
        </div>
        <hr>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Pie from "@/components/Billing/charts/PieChart";
import Column from "@/components/Billing/charts/ColumnChart";
import JsonExcel from "vue-json-excel";
import BalanceRateModalContent from "@/components/Billing/BalanceRateModalContent.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import BalanceResultRowItem from "@/components/Billing/BalanceResultRowItem.vue";
import IconSettins from "@/components/Icons/IconSettins.vue";

export default {
  name: "BalanceResult",
  components: {
    IconSettins,
    BalanceResultRowItem,
    Pie,
    Column,
    "download-excel": JsonExcel
  },
  props: ['filter', 'isVs'],
  data() {
    return {
      balance_data: [],
      items: [],
      groups: [],
      rates: []
    }
  },
  computed: {
    ...mapGetters({
      getDeviceName: 'device/name',
      grouping: 'balance/grouping'
    }),
    setChartLabels: function () {
      let months = []
      this.filter.period.forEach(item => {
        months.push(this.$moment(item).format('MMMM'));
      })
      return months
    },
    getLossesInRubles: function () {
      return (devices_supply, losses) => {
        let supply_sum = devices_supply.reduce((s, i) => s = s + i.value, 0);
        let losses_in_rub = 0;

        devices_supply.forEach(device => {
          let supply_percent = (device.value * 100) / supply_sum;
          let losses_by_percent = supply_percent / 100 * parseInt(losses);

          let el = this.rates.find(item => item.device_id == device.device_id);
          if (el !== undefined) losses_in_rub += losses_by_percent * el.value;
        })

        if (losses_in_rub !== (-Infinity) && !isNaN(losses_in_rub)) {
          return new Intl.NumberFormat('ru-RU',
            {style: 'currency', currency: 'RUB', minimumFractionDigits: 0}).format(losses_in_rub)
        } else {
          return '-'
        }
      }
    },
    processPieChartData: function () {
      return (consumption, losses) => {
        return [Number(consumption), Number(losses)]
      }
    }
  },
  methods: {
    showRatePopup(periods) {
      this.$modal.show(
        BalanceRateModalContent,
        {
          'popupName': 'balance-rate-form',
          'periods': periods,
          'setRate': this.setRate,
          'getDeviceName': this.getDeviceName
        },
        {
          name: 'balance-rate-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460),
          scrollable: true
        }
      )
    },
    makeTable(balance) {
      let items = []
      balance.periods.forEach(period => {
        items.push({
          month: this.$moment(period.period).format('MMMM'),
          supply: period.sumSupply(),
          devices_supply: period.supply,
          consumption: period.sumConsumption(),
          losses: period.calculateLosses(),
          losses_in_percent: period.calculateLossesInPercent(),
          losses_rub: 0
        })
      })
      return items
    },
    generateChartData(balance) {
      let sup = [];
      let los = [];
      balance.periods.forEach(period => {
        sup.push(period.sumSupply());
        los.push(period.calculateLosses());
      })

      let chartData = [{
        name: 'Потери',
        data: []
      }, {
        name: 'Подано',
        data: []
      }]
      chartData[0].data = los;
      chartData[1].data = sup;

      if (!this.isVs) chartData[0].name = 'Профицит'
      else chartData[0].name = 'Потери'

      return chartData
    },
    setRate(val, id) {
      let index = this.rates.findIndex(el => el.device_id == id)
      if (index !== -1) {
        this.rates.splice(index, 1)
      }
      this.rates.push({
        device_id: id,
        value: val
      })
    },
    processExportData(balance_group) {
      let exportData = this.makeTable(balance_group)
      exportData.forEach(item => {
        item["Месяц"] = item.month;
        item["Подача"] = item.supply;
        item["Начисления"] = item.consumption;
        if (this.isVs) item["Потери"] = item.losses;
        else item["Профицит"] = item.losses;
        if (this.rates.length != 0) {
          if (this.isVs) item["Потери (₽)"] = this.getLossesInRubles(item.devices_supply, item.losses);
          else item["Профицит (₽)"] = this.getLossesInRubles(item.devices_supply, item.losses);
        }
        item.devices_supply.forEach(device => {
          let nameOfDevice = this.getDeviceName(device.device_id)
          if (nameOfDevice == "ТГВ-7 (лиц.)") {
            item["ТГВ-7"] = device.value
          } else {
            item[nameOfDevice] = device.value
          }
        })
        delete item ['devices'];
        delete item ['month'];
        delete item ['supply'];
        delete item ['consumption'];
        delete item ['devices_supply'];
        delete item ['losses'];
        delete item ['losses_in_percent'];
        delete item ['losses_rub'];
      });
      return exportData
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/viriables";

table {
  border-collapse: separate;
  border-spacing: 0;
}

thead tr th {
  border-top: 1px solid var(--bg-accent);
  border-left: 1px solid var(--bg-accent);
  color: var(--text-panel);
  font-size: 12px;
  font-weight: 700;
  background-color: var(--bg-sidebar);
  text-align: center;
  vertical-align: middle;
}

thead tr th:first-child {
  border-radius: 5px 0 0 0;
}

thead tr th:last-child {
  border-right: 1px solid var(--bg-accent);
  border-radius: 0 5px 0 0;
}

tbody tr td {
  border-left: 1px solid var(--bg-accent);
  border-top: 1px solid var(--bg-accent);
  color: var(--text-primary);
  font-size: 16px;
  font-weight: 700;
  background-color: var(--panel);
  text-align: center;
}

tbody tr:last-child td {
  border-bottom: 1px solid var(--bg-accent);
}

tbody tr:last-child td:first-child {
  border-radius: 0 0 0 5px;
}

tbody tr:last-child td:last-child {
  border-radius: 0 0 5px 0;
}

tbody tr td:last-child {
  border-right: 1px solid var(--bg-accent);
}

.table thead th {
  border-bottom: 0;
}

.balance-result {
  padding-top: 24px;
}

.balance-union-group {
  display: flex;
  gap: 24px;

  &__table {
    flex: 1;
  }

  &__chart {
    flex: 1;
  }
}

.balance-months-group {
  &__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  &__result {

  }
}

.base-table__row--balance {
  grid-template-columns: 45px 10% 12% 16% 14% 21%;
}

.balance-rate-settings {
  & svg {
    width: 16px;
    height: 16px;
  }
}

.group-title {
  margin-bottom: 16px;
}

.group-title > span:first-child {
  color: var(--text-link);
  font-weight: 700;
  font-style: italic;
}

.group-title > span:last-child {
  color: var(--text-primary);
  font-weight: 700;
  font-style: italic;
}

.pie-chart-block {
  padding: 0 50px 10px 50px
}

.pie-data-title {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  color: var(--text-primary);
  line-height: 13px;
  margin-right: 5px;
}

.pie-data-value {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  color: var(--text-primary);
  line-height: 13px;
}

.text-blue {
  color: var(--text-link);
}

.text-red {
  color: var(--border-validation);
}

@media (max-width: $mobile-big) {
  .balance-union-group {
    flex-direction: column;
  }
}
</style>
