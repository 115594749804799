<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      <template v-if="!Object.keys(mechanicalEquipment).length">Добавить СММ</template>
      <template v-else>Редактирование СММ</template>
      <span @click="$modal.hide('mechanical-equipment-form')"
            class="base-popup__close">
        <icon-close/>
      </span>
    </div>
    <div class="popup-form-group">
      <div class="form-item">
        <div class="form-item__name">Наименование</div>
        <div class="form-item__value">
          <input
            v-model="payload.name"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите наименование СММ"
            required
          >
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Тип топлива</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.fuel_type"
            :options="fuelTypes"
            :reduce="type => type.id"
            :get-option-label="(option) => option.name"
            class="vue-select-input"
            placeholder="Выберите из списка"/>
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Расход топлива, л/ч</div>
        <div class="form-item__value">
          <input
            v-model="payload.fuel_consumption"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите количество литров в час"
            required
          >
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Тип масла</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.oil_type"
            :options="oilTypes"
            :reduce="type => type.id"
            :get-option-label="(option) => option.name"
            class="vue-select-input"
            placeholder="Выберите из списка"/>
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Расход масла, л/ч</div>
        <div class="form-item__value">
          <input
            v-model="payload.oil_consumption"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите количество литров в час"
            required
          >
        </div>
      </div>
    </div>
    <div class="base-popup__footer">
      <div @click="checkFormType()"
           class="button">
        Добавить
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from "@/components/Icons/IconClose.vue";

export default {
  name: "MechanicalEquipmentForm",
  components: {IconClose},
  props: {
    mechanicalEquipment: {
      type: Object,
      required: true
    },
    createMechanicalEquipment: {
      type: Function,
      default: () => {}
    },
    updateMechanicalEquipment: {
      type: Function,
      default: () => {}
    },
    fuelTypes: {
      type: Array,
      required: true
    },
    oilTypes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      payload: {
        name: null,
        fuel_type: null,
        oil_type: null,
        fuel_consumption: null,
        oil_consumption: null
      }
    }
  },
  mounted() {
    if (Object.keys(this.mechanicalEquipment).length) {
      const {name, fuelType, oilType, fuelConsumption, oilConsumption} = this.mechanicalEquipment;
      this.payload.name = name;
      this.payload.fuel_type = fuelType.id;
      this.payload.oil_type = oilType.id;
      this.payload.fuel_consumption = fuelConsumption;
      this.payload.oil_consumption = oilConsumption;
    }
  },
  methods: {
    checkFormType() {
      if (Object.keys(this.mechanicalEquipment).length) {
        this.updateMechanicalEquipment(this.mechanicalEquipment.id, this.payload)
      } else this.createMechanicalEquipment(this.payload)
    }
  }
}
</script>

<style scoped>

</style>
