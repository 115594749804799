<template>
  <base-map
    :center="center"
    :zoom="zoom"
    :base-layers="baseLayers"
    :layers="layers"
    :markers="createMarkers()"
  />
</template>

<script>
import BaseMap from "@/components/BaseMap.vue";
import {mapState} from "vuex";
import Vue from "vue";
import EmergencyElementsReportMarker
  from "@/components/ServiceDesk/ReportsPreviews/EmergencyElementsReport/EmergencyElementsReportMarker.vue";
import EmergencyElementsReportPopup
  from "@/components/ServiceDesk/ReportsPreviews/EmergencyElementsReport/EmergencyElementsReportPopup.vue";
import Marker from "@/models/baseMap/Marker";
import Icon from "@/models/baseMap/Icon";

export default {
  name: "EmergencyElementsReportMap",
  components: {BaseMap},
  props: {
    reportData: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState({
      center: state => state.baseMap.center,
      zoom: state => state.baseMap.zoom,
      layers: state => state.baseMap.layers,
      baseLayers: state => state.baseLayers.baseLayers
    })
  },
  methods: {
    createMarkers() {
      return this.reportData.map(emergElement => {
        const IssueMapIconClass = Vue.extend(EmergencyElementsReportMarker);
        const iconInstance = new IssueMapIconClass();
        iconInstance.emergencyElement = emergElement;

        const icon = iconInstance.$mount();
        const iconHtml = icon.$el.outerHTML;

        const IssueMapPopup = Vue.extend(EmergencyElementsReportPopup);
        const popupInstance = new IssueMapPopup();
        popupInstance.emergencyElement = emergElement;
        popupInstance.$on('open-modal', emergencyElement => {
          this.$emit('open-modal', emergencyElement);
        })

        const popup = popupInstance.$mount();

        let coords;

        if (emergElement?.coordinates?.some(item => Array.isArray(item))) {
          [coords] = emergElement.coordinates;
        } else coords = emergElement.coordinates;

        const marker = new Marker({
          coords: coords,
          icon: new Icon({
            type: 'div_icon',
            size: [36, 42],
            anchor: [18, 21],
            html: iconHtml
          }),
          accumulation: true,
          popup_content: popup.$el
        })

        icon.$destroy();
        popup.$destroy();

        return marker;
      })
    }
  }
}
</script>

<style scoped>

</style>
