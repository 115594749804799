<template>
  <div class="additional-options-list__inner">
    <div v-for="leak in leaksList"
         :key="leak.id"
         class="added-item added-item--emergency">
      <div class="added-item__number">
        #{{leak.id}}
      </div>
      <div class="added-item__info">
        <div class="added-item__name">
          <template v-if="!leak.damage">Утечка</template>
          <template v-else>Утечка связана с повреждением #{{leak.damage.id}}: {{leak.damage.defect.name}}</template>
        </div>
        <div class="added-item__additional">
          <div v-if="leak.actualStartDate"
               class="added-item__additional-time">
            {{formatDateTime(leak.actualStartDate)}}
          </div>
          <div class="added-item__additional-time">-</div>
          <div v-if="leak.actualEndDate"
               class="added-item__additional-time">
            {{ formatDateTime(leak.actualEndDate)}}
          </div>
        </div>
      </div>
      <div class="added-item__right">
        <button
          v-if="!leak.damage"
          @click="showAttachLeakModal(leak.id)"
          type="button"
          class="button button--mode-outline button--mode-skinny">
          Связать
        </button>
        <div class="leak__buttons">
          <div v-if="leak.actualEndDate"
               class="leak__volume">Потери: {{ leak.volume.toFixed(2) }} м<sup>3</sup>
          </div>
          <div v-if="!leak.actualEndDate"
               @click="finishLeak(leak.id)"
               @mouseenter="isButtonHovered = true"
               @mouseleave="isButtonHovered = false"
               class="leak__state">
            <span v-if="isButtonHovered">Закончить</span>
            <span v-else>
              <div class="leak__state-indicator" />
              Активна
            </span>
          </div>
        </div>
      </div>
    </div>

    <div
      @click="showAddLeaksModal()"
      class="button button--mode-outline">
      <icon-plus/>
      <span>Добавить новую</span>
    </div>
  </div>
</template>

<script>
import {modalAdaptiveWidth} from "@/utils/helpers";
import LeaksForm from "@/components/ServiceDesk/Issues/IssueComponents/Leaks/LeaksForm";
import {showAlert, showToast} from "@/utils/notification";
import IconPlus from "@/components/Icons/IconPlus.vue";
import LeaksAttachForm from "@/components/ServiceDesk/Issues/IssueComponents/Leaks/LeaksAttachForm.vue";
import {formatDateTime} from "@/utils/filters";
export default {
  name: "LeaksList",
  components: {IconPlus},
  props: {
    issueId: {
      type: Number,
      required: true
    },
    leaksList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isButtonHovered: false
    }
  },
  methods: {
    formatDateTime,
    showAddLeaksModal() {
      this.$modal.show(
        LeaksForm,
        {
          'issue-id': this.issueId,
          'popupName': 'issue-leaks-modal'
        },
        {
          name: 'issue-leaks-modal',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460),
          scrollable: true
        }
      )
    },
    showAttachLeakModal(leakId) {
      this.$modal.show(
        LeaksAttachForm,
        {
          'issueId': this.issueId,
          'leakId': leakId,
          'popupName': 'issue-leaks-attach-modal'
        },
        {
          name: 'issue-leaks-attach-modal',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        }
      )
    },
    finishLeak(leakId) {
      this.$store.dispatch('leaks/finishLeak', {
        issueId: this.issueId,
        payload: {
          leak_id: leakId,
          actual_end_date: this.$moment().format('YYYY-MM-DD HH:mm')
        }
      })
          .then(() => {
            this.$store.dispatch('issueModal/getLeaksList', this.issueId);
            showToast('Утечка завершена', 'success');
          })
          .catch(error => showAlert('Ошибка', error))
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.leak {
  &__buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 24px;
  }

  &__volume {
    padding: 6px 12px;
    border-radius: 8px;
    background-color: var(--panel-bg);

    @include font-style(600, 14px, 16px, var(--text-primary))
  }

  &__state {
    border: 1px solid var(--border-main);
    border-radius: 8px;
    padding: 6px 12px;
    cursor: pointer;

    @include font-style(400, 14px, 16px, var(--text-primary));

    & span {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  &__state-indicator {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--bg-accent);
  }
}
</style>
