<template>
  <div class="page-wrapper">
    <base-panel
      title="Телеметрия"
      :is-add-button-visible="false"
      :is-weather-visible="true"
      :is-time-visible="true"
    />

    <div class="tabs">
      <div class="tabs__header tabs__header--block">
        <div
          @click="showPersonalListForm()"
          class="tabs__header-item"
        >
          <icon-plus-squared/>
          <span>Добавить</span>
        </div>
        <div
          v-for="(list, index) in personalLists"
          :key="list.id"
          @click="activeTab = index"
          :class="{'active': activeTab === index}"
          class="tabs__header-item">
          {{ list.name }}
        </div>
      </div>

      <div
        v-for="(list, index) in personalLists"
        :key="list.id"
        v-show="activeTab === index"
        :class="{'active': activeTab === index}"
        class="tabs__item">
        <personal-list
          v-if="activeTab === index"
          :personal-list="list"
          @show-network-object-modal="showNetworkObjectModal"
          @update-personal-list="updatePersonalList"/>
      </div>
    </div>

    <base-content-loaders v-if="loading"/>

    <div
      v-if="!personalLists.length && !loading"
      class="personal-lists-no-data">
      <img
        src="@/assets/img/no-result.png"
        alt="Нет данных"
        class="personal-lists-no-data__img">

      <div class="personal-lists-no-data__title">
        Создайте список объектов

        <icon-telemetry-arrow/>
      </div>
      <div class="personal-lists-no-data__text">
        Нажмите на «Добавить» и выберите необходимые объекты для мониторинга
      </div>
    </div>

  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel.vue";
import IconPlusSquared from "@/components/Icons/IconPlusSquared.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import PersonalListsForm from "@/components/Telemetry/PersonalLists/PersonalListsForm.vue";
import {mapState} from "vuex";
import PersonalList from "@/components/Telemetry/PersonalLists/PersonalList.vue";
import IconTelemetryArrow from "@/components/Icons/IconTelemetryArrow.vue";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";

export default {
  name: "TelemetryListsPage",
  components: {BaseContentLoaders, IconTelemetryArrow, PersonalList, IconPlusSquared, BasePanel},
  data() {
    return {
      activeTab: 0,
      timing: null
    }
  },
  computed: {
    ...mapState({
      personalLists: state => state.telemetryPersonalLists.personalLists,
      networkObjectsList: state => state.networkObjects.networkObjects,
      loading: state => state.telemetryPersonalLists.loading
    })
  },
  mounted() {
    this.$store.dispatch('telemetryPersonalLists/getPersonalLists');
    this.$store.dispatch('networkObjects/getAllNetworkObjects');
    this.$store.dispatch('networkEquipments/getNetworkEquipments');
    this.$store.dispatch('telemetry/getParametersValues');

    this.timing = setInterval(this.getParametersValues, 60000)
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    getParametersValues() {
      this.$store.dispatch('telemetry/getParametersValues');
    },
    updatePersonalList(personalList) {
      this.showPersonalListForm({
        id: personalList.id,
        name: personalList.name,
        object_list: personalList.objectsList.map(object => object.object_id)
      });
    },
    showPersonalListForm(personalList = {}) {
      this.$modal.show(
        PersonalListsForm,
        {
          'popupName': 'personal-list-form',
          'personalList': personalList
        },
        {
          name: 'personal-list-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(1173)
        }
      )
    },
    showNetworkObjectModal(networkObjectId) {
      this.$store.dispatch('baseSideModal/openModal', {
        modalContentType: 'networkObject',
        entityId: networkObjectId,
        propsData: {
          isReadingsVisible: true
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.personal-lists-no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  &__img {
    width: 240px;
    padding: 30px 0;
  }

  &__title {
    position: relative;

    @include font-style(500, 24px, 32px, var(--text-primary));

    & svg {
      position: absolute;
      top: -264px;
      left: -485px;
    }
  }

  &__text {
    @include font-style(400, 16px, 24px, var(--text-secondary));
  }
}
</style>