<template>
  <div class="page-wrapper">
    <base-panel
      title="Словари"
      :is-add-button-visible="false"
    />

    <base-functional-line @search-change="event => $store.commit(
      'dictionaries/SET_DICTIONARIES_QUERY',event.target.value)">
      <template>
        <button
          @click="openDirectoryPopup()"
          class="button button--mode-primary">
          <icon-plus/>
          Добавить словарь
        </button>
      </template>
    </base-functional-line>

    <base-content-loaders v-if="loading && !dictionariesList.length" />

    <dictionaries-list
      v-if="dictionariesList.length"
      :dictionaries-list="dictionariesList"
      @open-dictionary-page="openDirectoryPage"
      @edit-dictionary="openDirectoryPopup"
      @delete-dictionary="deleteDirectory"
    />

    <base-no-data v-else-if="!loading"/>
  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel.vue";
import BaseFunctionalLine from "@/components/BaseFunctionalLine.vue";
import IconPlus from "@/components/Icons/IconPlus.vue";
import {mapGetters, mapState} from "vuex";
import DictionariesList from "@/components/TechnicalInspection/Dictionary/DictionariesList.vue";
import {showAlert, showToast, showConfirmationModal} from "@/utils/notification";
import DictionaryPopup from "@/components/TechnicalInspection/Dictionary/DictionaryPopup.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import BaseNoData from "@/components/BaseNoData.vue";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";

export default {
  name: "DictionariesListPage",
  components: {BaseContentLoaders, BaseNoData, DictionariesList, IconPlus, BaseFunctionalLine, BasePanel},
  data() {
    return {
      popupName: 'dictionary-popup'
    }
  },
  computed: {
    ...mapState({
      loading: state => state.dictionaries.loading
    }),
    ...mapGetters({
      dictionariesList: 'dictionaries/getDictionariesByQuery'
    })
  },
  mounted() {
    this.$store.dispatch('dictionaries/getDictionariesList');
  },
  methods: {
    openDirectoryPage(id) {
      this.$router.push(`/technical-inspection-dictionaries/${id}`);
    },
    openDirectoryPopup(directory = {}) {
      this.$modal.show(
        DictionaryPopup,
        {
          'popupName': this.popupName,
          'dictionary': directory,
          'createDictionary': this.createDirectory,
          'updateDictionary': this.updateDirectory
        },
        {
          name: this.popupName,
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        }
      )
    },
    createDirectory(payload) {
      this.$store.dispatch('dictionaries/createDictionary', payload)
        .then(() => {
          this.$store.dispatch('dictionaries/getDictionariesList');
          showToast('Словарь добавлен', 'success');
          this.$modal.hide(this.popupName);
        })
        .catch(error => showAlert('Ошибка', error))
    },
    updateDirectory(id, payload) {
      this.$store.dispatch('dictionaries/updateDictionary', {id, payload})
        .then(() => {
          this.$store.dispatch('dictionaries/getDictionariesList');
          showToast('Словарь обновлен', 'success');
          this.$modal.hide(this.popupName);
        })
        .catch(error => showAlert('Ошибка', error))
    },
    deleteDirectory(id) {
      showConfirmationModal('Вы уверены, что хотите удалить словарь? Это действие невозвратно.')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('dictionaries/deleteDictionary', id)
              .then(() => {
                this.$store.dispatch('dictionaries/getDictionariesList');
                showToast('Словарь удален', 'success');
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style scoped>

</style>