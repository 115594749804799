<script>
export default {
  name: "IconClouds"
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M7.27004 13.0098C6.74004 12.7398 6.15004 12.5998 5.55004 12.5998C0.870039 12.9298 0.870039 19.7398 5.55004 20.0698H16.64C17.99 20.0798 19.29 19.5798 20.28 18.6698C23.57 15.7998 21.81 10.0298 17.48 9.47976C15.92 0.109757 2.39004 3.66976 5.60004 12.5998" fill="#DBEAEE"/>
    <path d="M7.27004 13.0098C6.74004 12.7398 6.15004 12.5998 5.55004 12.5998C0.870039 12.9298 0.870039 19.7398 5.55004 20.0698H16.64C17.99 20.0798 19.29 19.5798 20.28 18.6698C23.57 15.7998 21.81 10.0298 17.48 9.47976C15.92 0.109757 2.39004 3.66976 5.60004 12.5998" stroke="#1AB9EB" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.8501 9.91977C16.3701 9.65977 16.9401 9.51977 17.5201 9.50977" stroke="#1AB9EB" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<style scoped>

</style>