export default {
    methods: {
        openIssueModal(issueId) {
            this.$store.dispatch('baseSideModal/openModal', {
                modalContentType: 'issue',
                entityId: issueId
            });
        },
        openTaskModal(taskId) {
            this.$store.dispatch('baseSideModal/openModal', {
                modalContentType: 'task',
                entityId: taskId
            });
        },
        openAppealModal(appealId) {
            this.$store.dispatch('baseSideModal/openModal', {
                modalContentType: 'appeal',
                entityId: appealId
            })
        },
        openCreateIssueModal(propsData = {}) {
            this.$store.dispatch('baseSideModal/openModal', {
                modalContentType: 'createIssue',
                entityId: null,
                propsData: propsData
            })
        },
        openWorkModal(workId) {
            this.$store.dispatch('baseSideModal/openModal', {
                modalContentType: 'work',
                entityId: workId
            })
        },
        openDepartmentModal(departmentId) {
            this.$store.dispatch('baseSideModal/openModal', {
                modalContentType: 'department',
                entityId: departmentId
            })
        },
        openTransportModal(autoId) {
            this.$store.dispatch('baseSideModal/openModal', {
                modalContentType: 'transport',
                entityId: autoId
            })
        }
    }
}
