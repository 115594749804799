<template>
  <svg width="25"
       height="24"
       viewBox="0 0 25 24"
       fill="#9192AD"
       xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.6665 12C22.6665 17.52 18.1865 22 12.6665 22C7.1465 22 3.7765 16.44 3.7765 16.44M3.7765
         16.44H8.2965M3.7765
       16.44V21.44M2.6665 12C2.6665 6.48 7.1065 2 12.6665 2C19.3365 2 22.6665 7.56 22.6665 7.56M22.6665
        7.56V2.56M22.6665 7.56H18.2265"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconRefresh"
}
</script>

<style scoped>

</style>