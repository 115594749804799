<template>
  <div
    class="popup-target"
    :class="{'popup-target--active': isActive}"
  >
    <div class="popup">
      <div class="popup__top">
        <div class="popup__top-name">{{title}}</div>
        <div class="popup__close"
             @click="closeModal()">
          <icon-close/>
        </div>
      </div>
      <div class="popup__body">
        <slot/>
      </div>
      <div class="popup__btns">
        <button class="button button--mode-popup delete"
                @click="deleteItem()">Удалить</button>
        <button class="button button--mode-popup cancel"
                @click="closeModal()">Отменить</button>
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from "@/components/Icons/IconClose.vue";

export default {
  name: "BasePopupTarget",
  components: {IconClose},
  props: {
    isActive: {
      type: Boolean,
      require: true
    },
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
      this.$store.dispatch('modal/togledModalDelete')
    },
    deleteItem() {
      this.$emit('deleteItem')
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-target {
  display: none;
}

.popup-target.popup-target--active {
  display:flex;
  align-items:center;
  justify-content:center;

  z-index: 410;

  position: fixed;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  animation: bg 0.5s ease;
  background: rgba(38, 47, 61, 0.50);
  backdrop-filter: blur(6px);

  .popup {
    position: relative;

    padding: 24px 36px;

    width: 80%;

    z-index: 8;

    max-width: 600px;
    max-height: 70%;

    transition: 0.5s;

    border-radius: 24px;
    background: var(--panel);

    overflow: auto;
    animation: scale 0.5s ease;

    &__top {
      padding-bottom: 24px;

      &-name {
        color: var(--text-primary);

        font-size: 36px;
        font-weight: 500;
        line-height: 48px;
      }
    }

    &__close {
      position: absolute;
      top: 24px;
      right: 30px;

      width: 24px;
      height: 24px;

      cursor: pointer;

      svg {
        width: 24px;
        height: 24px;

        stroke: var(--ico-active);
      }
    }

    &__body {
      padding-bottom: 24px;

      &-name {
        display: flex;
        align-items: center;
        gap: 4px;

        padding-bottom: 12px;

        &-icon {
          border-radius: 8px;
          background: var(--panel-bg);

          width: 46px;
          height: 40px;

          padding: 0 8px;

          display: flex;
          justify-content: center;
          align-items: center;

          margin-right: 4px;

          svg {
            width: 40px;
            height: 40px;
          }
        }
        &-id {
          border-radius: 8px;
          background: var(--panel-bg);

          min-width: 46px;
          height: 40px;

          display: flex;
          justify-content: center;
          align-items: center;

          padding: 0 8px;

          margin-right: 4px;

          span {
            color: var(--text-primary);

            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }
        }
        &-name-car {
          color: var(--text-primary);

          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }
      }

      &-text {
        color: var(--text-primary);

        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }

    &__btns {
      display: flex;
      align-items: center;
      justify-content: space-between;

      gap: 12px;
    }
  }
}

@keyframes scale {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes move {
  0% {
    right: -80px;
  }
  100% {
    right: 20px;
  }
}
@keyframes bg {
  0% {
    background: rgba(51, 122, 183, 0);
  }
  100% {
    background: rgba(38, 47, 61, 0.50);
  }
}

</style>
