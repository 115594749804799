import Swal from "sweetalert2";

export const showToast = (title, icon = 'info') => {
    Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
        title: title,
        icon: icon,
        customClass: {
            popup: 'base-toast'
        }
    })
}

export const showAlert = (title, text, icon = 'error') => {
    Swal.fire({
        title: title,
        text: text,
        icon: icon,
        confirmButtonColor: '#EB5757',
        customClass: {
            popup: 'base-alert'
        }
    })
}

export const showConfirmationModal = (
    title,
    confirmButtonText = 'Удалить',
    cancelButtonText = 'Отменить'
) => {
    return new Promise(resolve => {
        Swal.fire({
            title: title,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: cancelButtonText,
            confirmButtonText: confirmButtonText,
            confirmButtonColor: '#EB5757',
            customClass: {
                popup: 'base-alert'
            }
        }).then(result => resolve(result))
    })

}
