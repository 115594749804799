<script>
export default {
  name: "IconPump"
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none">
    <path
      d="M15.6228 4H13.3772C13.1689 4 13 3.7762 13 3.5C13 3.2238 13.1689 3 13.3772 3H15.6228C15.8311 3 16 3.2238 16
       3.5C16 3.7762 15.8311 4 15.6228 4Z"
      fill="#A4D9F6"/>
    <path
      d="M14.5001 7C14.2239 7 14 6.81027 14 6.57613V3.42388C14 3.18973 14.2239 3 14.5001 3C14.7761 3 15 3.18973 15
       3.42388V6.57607C15 6.81022 14.7761 7 14.5001 7Z"
      fill="#A4D9F6"/>
    <path
      d="M17.2987 17.2924L14.5515 17L11.7013 17.2924C11.7013 18.4574 11 18.758 11 18.758L14.5515 19L18 18.758C18
       18.758 17.2987 18.4357 17.2987 17.2924Z"
      fill="#262F3D"/>
    <path d="M8 14H0.845375C0.37851 14 0 13.6411 0 13.1984V11.8016C0 11.3588 0.37851 11 0.845375 11H8V14Z"
          fill="#262F3D"/>
    <path d="M21 14H23.0182C23.5604 14 24 13.6411 24 13.1984V11.8016C24 11.3589 23.5604 11 23.0182 11H21V14Z"
          fill="#262F3D"/>
    <path
      d="M16.7366 7.21814V6.73585C16.7366 6.32946 16.4064 6 15.999 6H12.8931C12.4857 6 12.1554 6.32946 12.1554
       6.73585V7.21814C12.1554 7.6093 11.8376 7.92633 11.4455 7.92633H9.27735L9 12.4632L9.27735 17H19.6149L20
       12.3407L19.6149 7.92633H17.4466C17.0545 7.92633 16.7366 7.60925 16.7366 7.21814Z"
      fill="#468FC5"/>
    <path d="M7.59624 17H9V8H7.59624C6.71466 8 6 8.61467 6 9.37289V15.627C6 16.3852 6.71466 17 7.59624 17Z"
          fill="#A4D9F6"/>
    <path d="M20 17H20.9358C21.5236 17 22 16.3853 22 15.6271V9.37289C22 8.61467 21.5236 8 20.9358 8H20V17Z"
          fill="#A4D9F6"/>
    <path
      d="M18.6695 21H9.33054C9.14797 21 9 20.8159 9 20.5888V19.4111C9 19.184 9.14797 19 9.33054 19H18.6695C18.852 19
      19 19.184 19 19.4111V20.5888C19 20.8159 18.852 21 18.6695 21Z"
      fill="#468FC5"/>
    <path
      d="M18.7193 19H18C18.155 19 18.2807 19.184 18.2807 19.4111V20.5888C18.2807 20.8159 18.155 21 18 21H18.7193C18.8744
       21 19 20.8159 19 20.5888V19.4111C19 19.184 18.8744 19 18.7193 19Z"
      fill="#468FC5"/>
    <path
      d="M3.08536 16H2.91463C1.85722 16 1 15.3737 1 14.6011V10.3989C1 9.62633 1.85722 9 2.91463 9H3.08536C4.14277 9 5
      9.62633 5 10.3989V14.6011C4.99994 15.3737 4.14277 16 3.08536 16Z"
      fill="#468FC5"/>
  </svg>
</template>

<style scoped>

</style>