export const namespaced = true;
export const state = {
    isActiveModal: false,
    isActiveModalCard: false,
    isActiveModalTargetDelete: false
}
export const getters = {
    getIsActiveModal: state => {
        return state.isActiveModal
    },
    getIsActiveModalCard: state => {
        return state.isActiveModalCard
    },
    getIsActiveModalTargetDelete: state => {
        return state.isActiveModalTargetDelete
    }
}
export const mutations = {
    TOGGLE_MODAL(state) {
        state.isActiveModal = !state.isActiveModal
    },
    TOGGLE_MODAL_CARD(state) {
        state.isActiveModalCard = !state.isActiveModalCard
    },
    DEACTIVATE_MODAL_CARD(state) {
        state.isActiveModalCard = false;
    },
    TOGGLE_MODAL_TARGET_DELETE(state) {
        state.isActiveModalTargetDelete = !state.isActiveModalTargetDelete
    }
}
export const actions = {
    togledModal({ commit }) {
        commit("TOGGLE_MODAL")
    },
    togledModalCard({ commit }) {
        commit("TOGGLE_MODAL_CARD")
    },
    deactivateModalCard({commit}) {
      commit("DEACTIVATE_MODAL_CARD")
    },
    togledModalDelete({commit}) {
        commit("TOGGLE_MODAL_TARGET_DELETE")
    }
}
