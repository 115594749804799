<template>
  <svg width="24"
       height="24"
       viewBox="0 0 24 24"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <g id="Iconsax/Linear/logout">
      <path id="Vector"
            d="M18.1626 14.7709L21 11.9335L18.1626 9.09606M9.65025 11.9335H20.9224M11.867
            20.734C6.96798 20.734 3 17.4089 3 11.867C3 6.32512 6.96798 3 11.867 3"
            stroke=""
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"/>
    </g>
  </svg>
</template>
<script>
export default {
  name: "IconLogout"
}
</script>
