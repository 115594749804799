<template>
  <div class="page-wrapper">
    <base-panel
      title="Зоны"
      :is-add-button-visible="true"
      add-button-name="Обновить зоны"
      @add-button-clicked="updateZonesList()"
    />

    <zones-list
      v-if="zonesList.length"
      :zones-list="zonesList"
      @edit-zone="showPopup"
      @delete-zone="deleteZone"
    />

    <base-content-loaders v-if="loading && zonesList.length" />

    <base-no-data v-if="!loading && !zonesList.length"/>

    <base-paginate
      v-if="meta && meta.last_page > 1"
      :meta="meta"
      commit="zone/SET_PAGE"
      action="zone/getZones"
    />
  </div>
</template>

<script>
import ZonesList from "@/components/Billing/admin/ZonesList";
import {modalAdaptiveWidth} from "@/utils/helpers";
import BasePanel from "@/components/BasePanel.vue";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import {mapState} from "vuex";
import BaseNoData from "@/components/BaseNoData.vue";
import BasePaginate from "@/components/BasePaginate.vue";
import ZonesForm from "@/components/Billing/admin/ZonesForm.vue";
import {showToast, showConfirmationModal, showAlert} from "@/utils/notification";

export default {
  name: "ZonesListPage",
  components: {
    BasePaginate,
    BaseNoData,
    BaseContentLoaders,
    BasePanel,
    ZonesList
  },
  data() {
    return {
      error: null
    }
  },
  computed: {
    ...mapState({
      zonesList: state => state.zone.zones,
      loading: state => state.zone.loading,
      meta: state => state.zone.meta
    })
  },
  mounted() {
    this.$store.dispatch('zone/getZones');
  },
  methods: {
    showPopup(zone) {
      this.$modal.show(
        ZonesForm,
        {
          'zone': zone,
          'updateZone': this.updateZone
        },
        {
          name: 'zone-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        }
      )
    },
    updateZonesList() {
      this.$store.dispatch('zone/updateZonesList')
        .then(() => {
          this.$store.dispatch('zone/getZones');
          showToast('Список зон обновлен', 'success')
        })
        .catch(error => showAlert('Ошибка', error))
    },
    updateZone(zoneId, payload) {
      this.$store.dispatch('zone/updateZone', {
        zoneId, payload
      })
        .then(() => {
          this.$store.dispatch('zone/getZones');
          this.$modal.hide('zone-form');
          showToast('Зона обновлена', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    deleteZone(zoneId) {
      showConfirmationModal('Вы уверены, что хотите удалить зону? Это действие невозвратно.')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('zone/deleteZone', zoneId)
              .then(() => {
                this.$store.commit('zone/DELETE_ZONES', zoneId);
                showToast('Зона удалена', 'success');
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style scoped>

</style>