import NetworkElementsService from "@/services/NetworkElementsService";

export const namespaced = true;

export const state = {
    elements: [],
    element: {},
    filterParams: null,
    loading: false,
    elementLoading: false,
    error: null
}

export const mutations = {
    SET_ELEMENTS(state, elements) {
        state.elements = elements;
    },
    SET_ELEMENT(state, element) {
        state.element = element;
    },
    SET_FILTER_PARAMS(state, filterParams) {
        state.filterParams = filterParams
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ELEMENT_LOADING(state, loading) {
        state.elementLoading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SELECT_ELEMENT(state, {newElementId, oldElementId}) {
        if (oldElementId) {
            const oldElement = state.elements.find(item => item.id === oldElementId);
            oldElement.isSelected = false;
        }

        const newElement = state.elements.find(item => item.id === newElementId);
        if (newElement) newElement.isSelected = true;
    },
    CHANGE_ELEMENT_MODE(state, {elementId, modeId, modeName}) {
        const element = state.elements.find(element => element.id === elementId);
        if (element) {
            element.currentMode.id = modeId;
            element.currentMode.name = modeName;
        }
        if (state.element.id === elementId) {
            state.element.currentMode.id = modeId;
            state.element.currentMode.name = modeName;
        }
    }
}

export const actions = {
    getNetworkElementsByCoords({commit, state}) {
        return new Promise((resolve, reject) => {
            commit("SET_LOADING", true);
            NetworkElementsService.fetchNetworkElementsByCoords(state.filterParams)
                .then(networkElements => {
                    commit("SET_ELEMENTS", networkElements);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                })
                .finally(() => commit("SET_LOADING", false));
        })
    },
    getNetworkElementById({commit}, {elementId, sourceId}) {
        return new Promise((resolve, reject) => {
            commit("SET_ELEMENT_LOADING", true);
            NetworkElementsService.fetchNetworkElementById(elementId, sourceId)
                .then(networkElement => {
                    commit("SET_ELEMENT", networkElement);
                    resolve(networkElement);
                })
                .catch(error => reject(error))
                .finally(() => commit("SET_ELEMENT_LOADING", false))
        })
    },
    changeNetworkElementMode(context, params) {
        return new Promise((resolve, reject) => {
            NetworkElementsService.changeNetworkElementMode(params)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    }
}

export const getters = {
    getNetworkElements: state => {
        return state.elements;
    },
    getNetworkElement: state => id => {
        const element = state.elements.find(element => element.id === id);
        if (element) return element;
    }
}