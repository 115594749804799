<template>
  <div class="page-wrapper">
    <base-panel
      title="Показатели в динамике"
      :is-add-button-visible="false"/>
    <issues-count-report-form :table-rows="tableRows"/>
    <base-content-loaders v-if="loading" />
    <div v-if="!reportData.length && !loading"
         class="no-result">
      <img src="@/assets/img/no-result-2.png"
           alt="не сформирован отчёт">
      <div class="no-result__text">
        Сформируйте запрос, чтобы получить данные
      </div>
    </div>
    <div v-if="reportData.length && !loading"
         id="issues-count-table"
         class="base-table">
      <div class="base-table__container">
        <div class="base-table__header base-table__header--issues-count">
          <div class="base-table__header-cell">Дата</div>
          <div class="base-table__header-cell">Остаток с прошлого периода</div>
          <div class="base-table__header-cell">Поступило за период</div>
          <div class="base-table__header-cell">Завершено за период</div>
          <div class="base-table__header-cell">Остаток на конец периода</div>
        </div>

        <div v-for="item in reportData"
             :key="item.date"
             class="base-table__row base-table__row--issues-count" >
          <div class="base-table__column">
            <div class="base-table__text">{{ item.date }}</div>
          </div>
          <div class="base-table__column">
            <div class="base-table__text">{{ item.count_transit }}</div>
          </div>
          <div class="base-table__column">
            <div class="base-table__text">{{ item.count_opened }}</div>
          </div>
          <div class="base-table__column">
            <div class="base-table__text">{{ item.count_closed }}</div>
          </div>
          <div class="base-table__column">
            <div class="base-table__text">{{ item.count_end_period }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IssuesCountReportForm from "@/components/ServiceDesk/ReportsForms/IssuesCountReportForm.vue";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import BasePanel from "@/components/BasePanel.vue";
import {mapState} from "vuex";

export default {
  name: 'IssuesCountReportPage',
  components: {BasePanel, BaseContentLoaders, IssuesCountReportForm},
  computed: {
    ...mapState({
      reportData: state => state.reportsData.reportData,
      loading: state => state.reportsData.loading
    }),
    tableRows() {
      const rows = [];
      if (this.reportData.length){
        rows.push(['Дата', 'Остаток с прошлого периода', 'Поступило за период', 'Завершено за период',
          'Остаток на конец периода']);
        for (let item of this.reportData) {
          rows.push([item.date, item.count_transit, item.count_opened, item.count_closed, item.count_end_period])
        }
      }
      return rows;
    }
  },
  created() {
    this.$store.commit('reportsData/SET_REPORT_DATA', []);
  }
}
</script>

<style lang="scss" scoped>

</style>