<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="24"
       height="24"
       viewBox="0 0 24 24"
       fill="none">
    <path d="M11 19.5H21M11 12.5H21M11 5.5H21M3 5.5L4 6.5L7 3.5M3 12.5L4 13.5L7 10.5M3 19.5L4 20.5L7 17.5"
          stroke="#9192AD"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconTasks"
}
</script>

<style scoped>

</style>
