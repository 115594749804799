<template>
  <div class="appeals-filter">
    <div class="filter__button tasks-filter__button"
         :class="{'opened': isFilterModalVisible}"
         @click="isFilterModalVisible = true">
      <icon-settings2/>
      <span>Фильтры</span>
    </div>
    <div class="functional-line__view-buttons">
      <div
        @click="$emit('set-list-view', true)"
        :class="{'active': isListView}">
        <icon-hamburger-menu/>
      </div>
      <div
        @click="$emit('set-list-view', false)"
        :class="{'active': !isListView}">
        <icon-map/>
      </div>
    </div>
    <div @click="openCreateAppealModal()"
         class="button button--mode-primary issues-filter__add-btn">
      <icon-plus/>
      <span>Новое обращение</span>
    </div>

    <base-filter-modal
      :is-visible="isFilterModalVisible"
      @toggle-modal="isVisible => this.isFilterModalVisible = isVisible">
      <div class="filter__modal-header">
        <div class="filter__modal-name">Фильтры</div>
        <div @click="resetFilters()"
             class="filter__modal-reset">Сбросить все
        </div>
      </div>
      <div class="filter__modal-form">
        <div>
          <label class="input-label">Дата открытия</label>
          <date-picker
            v-model="payload.appeal_date"
            :default-value="defaultValue"
            class="base-date-picker"
            placeholder="Выберите дату"
            type="date"
            format="DD.MM.YYYY"
            value-type="YYYY-MM-DD"
          />
        </div>
        <div>
          <label class="input-label">Категория</label>
          <div class="tags-select">
            <div v-for="group in categoryGroups"
                 :key="group.id"
                 @click="groupId = group.id"
                 class="tags-select__item"
                 :class="{'selected': group.id === groupId}">
              {{group.name}}
            </div>
          </div>
        </div>
        <div v-if="groupId">
          <label class="input-label">Проблема</label>
          <v-select
            class="vue-select-input"
            v-model="payload.category_id"
            :options="categoriesByGroupId(groupId)"
            :reduce="category => category.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите из списка"
          />
        </div>
      </div>
      <div class="filter__modal-buttons">
        <div @click="submitFilter()"
             class="button">Применить
        </div>
        <div @click="isFilterModalVisible = false"
             class="button button--mode-outline">Закрыть
        </div>
      </div>
    </base-filter-modal>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import IconSettings2 from "@/components/Icons/IconSettings2";
import BaseFilterModal from "@/components/BaseFilterModal";
import IconHamburgerMenu from "@/components/Icons/IconHamburgerMenu.vue";
import IconPlus from "@/components/Icons/IconPlus.vue";
import IconMap from "@/components/Icons/IconMap.vue";
import DatePicker from "vue2-datepicker";

export default {
  name: "AppealsListFilter",
  components: {
    IconMap,
    IconPlus,
    IconHamburgerMenu,
    BaseFilterModal,
    IconSettings2,
    DatePicker
  },
  props: {
    isListView: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isFilterModalVisible: false,
      groupId: null,
      defaultValue: new Date(),
      payload: {
        category_id: null,
        appeal_date: null
      }
    }
  },
  computed: {
    ...mapState({
      appealsPage: state => state.appeals.page
    }),
    ...mapGetters({
      categoriesByGroupId: 'serviceDeskCategories/getCategoriesByGroupId',
      categoryGroups: 'serviceDeskCategories/getGroupsContainingCategories'
    })
  },
  mounted() {
    this.$store.dispatch('serviceDeskCategories/getCategoryGroups');
    this.$store.dispatch('serviceDeskCategories/getAllCategories');
  },
  methods: {
    resetFilters() {
      this.groupId = null;
      this.payload = {
        category_id: null,
        appeal_date: null
      };
      Object.keys(this.payload).forEach(value => {
        this.$store.commit('appeals/SET_FILTER_PARAM', {
          key: value,
          value: null
        });
      })

      this.$store.dispatch("appeals/getAppeals", this.appealsPage);
    },
    submitFilter() {
      Object.keys(this.payload).forEach(value => {
        this.$store.commit('appeals/SET_FILTER_PARAM', {
          key: value,
          value: this.payload[value]
        });
      })
      this.$store.dispatch("appeals/getAppeals", 1)
        .then(() => {
          this.isFilterModalVisible = false;
        })
    },
    openCreateAppealModal() {
      this.$store.dispatch('baseSideModal/openModal', {
        modalContentType: 'createAppeal',
        entityId: null
      });
    }
  }
}
</script>

<style scoped>
.appeals-filter {
  display: flex;
  gap: 12px;

  position: relative;
}
</style>
