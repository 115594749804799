import * as API from './API';
import Layer from "@/models/baseMap/Layer";
import {getError} from "@/utils/helpers";

export default {
    getLayers(){
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/layers`)
                .then(response => resolve(response.data.data.map(item => new Layer({
                    id: item.id,
                    ...item.attributes
                }))))
                .catch(error => reject(getError(error)))
        })
    },
    getLayer(layerId){
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/layers/${layerId}`)
                .then(response => {
                    const {id, attributes} = response.data.data;
                    resolve(new Layer({id, ...attributes}))
                })
                .catch(error => reject(getError(error)));
        })
    },
    createLayer(data){
        return API.apiClient.post(`/layers`,data);
    },
    updateLayer(layer_id, data){
        return API.apiClient.patch(`/layers/${layer_id}`,data);
    },
    deleteLayer(layer_id){
        return API.apiClient.delete(`/layers/${layer_id}`);
    },
    createSemanticSource(layerId, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`/layers/${layerId}/semantic-sources`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteSemanticSource(layerId, semanticSourceId) {
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`/layers/${layerId}/semantic-sources/${semanticSourceId}`)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}