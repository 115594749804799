import {showToast} from "@/utils/notification";

export default {
    appealWsInit() {
        // eslint-disable-next-line no-undef
        Echo.private(`appeals`)
            .listen('.appeals.created', e => {
                showToast(
                    'Создано новое обращение №'+e.appeal.id+' пользователем '+e.appeal.attributes.appeal_author
                )
            });
    }
}
