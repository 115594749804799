import moment from "moment";
import File from "@/models/serviceDesk/File";
import AppealState from "@/models/callCenter/AppealState";

export default class Appeal {
    constructor(id, attributes) {
        this.id = id;
        this.address = attributes.address;
        this.coordinates = attributes.address.geometry.coordinates.reverse();
        this.author = attributes.appeal_author;
        this.message = attributes.appeal_message;
        this.source = attributes.appeal_source;
        this.state = attributes.appeal_state;
        this.applicant = attributes.applicant;
        this.category = attributes.category;
        this.created_at = attributes.created_at;
        this.issue_id = attributes.issue_id;
        this.structuralUnit = attributes.structural_unit || {name: ''};
        this.attachmentsList = attributes.attachments?.map(item => new File(item.id, item.attributes)) || []
    }

    getStateLabel() {
        switch (this.state) {
            case 1:
                return 'Новое'
            case 2:
                return 'Привязано'
            case 3:
                return 'В работе'
            case 4:
                return 'Отменено'
            case 5:
                return 'Закрыто'
        }
    }

    getApplicantTypeLabel() {
        switch (this.applicant.type) {
            case 1:
                return 'Физическое лицо'
            case 2:
                return 'Юридическое лицо'
            case 3:
                return 'Тех. лаборатория отдела ОРСиС'
            case 4:
                return 'ВКХ'
            case 5:
                return 'ИВР'
            case 6:
                return 'ЕДС'
            case 7:
                return 'Сотрудник'

        }
    }

    getCaption() {
        return `№${this.id} от ${moment(this.created_at).format(process.env.VUE_APP_BASE_DATE_TIME_FORMAT)},
        ${this.getFullAddress()}`
    }

    getSelectOptionData() {
        return {
            id: this.id,
            created_at: this.created_at,
            full_address: this.getFullAddress(),
            state_label: this.getStateLabel(),
            category_id: this.category_id,
            label: this.getCaption()
        }
    }

    getFullAddress() {
        return [this.address.city, this.address.settlement, this.address.street,
            this.address.house, this.address.block, this.address.flat]
            .filter(item => item !== null)
            .join(', ')
    }

    getSourceLabel() {
        switch (this.source) {
            case 1:
                return 'Сайт'
            case 2:
                return 'Звонок'
            case 3:
                return 'Мессенджер'
            case 4:
                return 'Система'
            case 5:
                return 'Техническая инспекция'
        }
    }

    getImageClassName() {
        switch (this.state) {
            case 1:
                return 'new'
            case 2:
                return 'appointed'
            case 3:
                return 'in-work'
            case 4:
                return 'canceled'
            case 5:
                return 'closed'
        }
    }

    get stateIsNew() {
        return this.state === AppealState.NEW;
    }
}
