<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      <template v-if="!Object.keys(balanceGroup).length">Добавить балансовую группу</template>
      <template v-else>Редактирование балансовой группы</template>
      <span @click="$modal.hide('balance-group-form')"
            class="base-popup__close">
        <icon-close/>
      </span>
    </div>
    <div class="popup-form-group">
      <div class="form-item">
        <div class="form-item__name">Наименование</div>
        <div class="form-item__value">
          <input
            v-model="payload.name"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите наименование"
            required
          >
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Входящие зоны</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.zones"
            :options="zonesList"
            :reduce="zone => zone.id"
            :get-option-label="(option) => option.attributes.name"
            class="vue-select-input"
            placeholder="Выберите из списка"
            multiple
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Расходомеры</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.devices"
            :options="devicesList"
            :reduce="device => Number.parseInt(device.id)"
            :get-option-label="(option) => option.name"
            class="vue-select-input"
            placeholder="Выберите из списка"
            multiple
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Исключаемые расходомеры</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.excludedDevices"
            :options="childrenDevicesList"
            :reduce="device => Number.parseInt(device.id)"
            :get-option-label="(option) => option.name"
            class="vue-select-input"
            placeholder="Выберите из списка"
            multiple
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Тип сети</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.network"
            :options="networkTypesList"
            :reduce="type => type.id"
            :get-option-label="(option) => option.name"
            class="vue-select-input"
            placeholder="Выберите из списка"
          />
        </div>
      </div>
      <div class="form-item">
        <label class="base-switcher">
          <input
            v-model="payload.active"
            class="base-switcher__checkbox"
            type="checkbox">
          <div class="base-switcher__switch"/>
          <span class="base-switcher__label">
            Активна
          </span>
        </label>
      </div>
      <div class="form-item">
        <label class="base-switcher">
          <input
            v-model="payload.balance_zone"
            class="base-switcher__checkbox"
            type="checkbox">
          <div class="base-switcher__switch"/>
          <span class="base-switcher__label">
            Балансовая зона
          </span>
        </label>
      </div>
    </div>
    <div class="base-popup__footer">
      <div @click="checkFormType()"
           class="button">
        Сохранить
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import IconClose from "@/components/Icons/IconClose.vue";

export default {
  name: "BalanceGroupsForm",
  components: {IconClose},
  props: {
    balanceGroup: {
      type: Object,
      required: true
    },
    createBalanceGroup: {
      type: Function,
      default: () => {}
    },
    updateBalanceGroup: {
      type: Function,
      default: () => {}
    },
    zonesList: {
      type: Array,
      default: () => []
    },
    devicesList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      payload: {
        name: '',
        zones: [],
        devices: [],
        excludedDevices: [],
        network: 1,
        active: true,
        balance_zone: false
      },
      networkTypesList: [
        {id: 1, name: 'Водоснабжение'},
        {id: 2, name: 'Водоотведение'}
      ]
    }
  },
  computed: {
    ...mapGetters({
      getChildrenDevices: 'device/getChildrenDevices'
    }),
    childrenDevicesList() {
      return this.getChildrenDevices(this.devicesList);
    }
  },
  mounted() {
    if (Object.keys(this.balanceGroup).length) {
      const {name, zones, devices, excludedDevices, network, isActive, isBalanceZone} = this.balanceGroup;
      this.payload.name = name;
      this.payload.zones = zones;
      this.payload.devices = devices;
      this.payload.excludedDevices = excludedDevices;
      this.payload.network = network;
      this.payload.active = isActive;
      this.payload.balance_zone = isBalanceZone;
    }
  },
  methods: {
    checkFormType() {
      if (Object.keys(this.balanceGroup).length) this.updateBalanceGroup(this.balanceGroup.id, this.payload)
      else this.createBalanceGroup(this.payload)
    }
  }
}
</script>

<style scoped>

</style>