export default class ZoneType {
    WATER_SUPPLY = 1;
    WATER_DISPOSAL = 2;

    constructor(id) {
        this.id = id;
    }

    get typeLabel() {
        switch (this.id) {
            case this.WATER_SUPPLY:
                return 'Водоснабжение'
            case this.WATER_DISPOSAL:
                return 'Водоотведение'
        }
        return ''
    }
}