<template>
  <div class="i-modal__content-wrapper">
    <div class="i-modal__content">
      <div v-if="Object.keys(layer).length && !loading"
           class="i-modal__inner">
        <div class="base-modal__left-side base-modal__left-side--hidden">
          <div class="base-modal-header">
            <span class="base-modal-title">{{layer.name}}</span>
          </div>

          <div class="base-modal-info base-modal-info--gap-24">
            <div class="base-modal-info__card">
              <div class="base-modal-info__card-title base-modal-info__card-title--loader">
                Детальная информация
              </div>

              <div class="base-modal-info__item">
                <div class="base-modal-info__name">
                  <span>URL</span>
                </div>
                <div class="base-modal-info__value">
                  <p class="base-modal-info__value-txt">{{ layer.url }}</p>
                </div>
              </div>

              <div class="base-modal-info__item">
                <div class="base-modal-info__name">
                  <span>Ресурс</span>
                </div>
                <div class="base-modal-info__value">
                  <p class="base-modal-info__value-txt">{{ layer.resource }}</p>
                </div>
              </div>

              <div class="base-modal-info__item">
                <div class="base-modal-info__name">
                  <span>Имя пользователя</span>
                </div>
                <div class="base-modal-info__value">
                  <p class="base-modal-info__value-txt">{{ layer.username }}</p>
                </div>
              </div>
            </div>

            <div class="base-modal-info__item base-modal-info__item--options">
              <layer-semantic-sources-list
                :layer-id="layer.id"
                :semantic-sources-list="layer.semanticSources" />
            </div>
          </div>
        </div>
      </div>
      <base-side-modal-skeleton v-if="loading" />
    </div>
    <div v-if="Object.keys(layer).length"
         class="i-modal-btn-wp i-modal-btn-wp--back">
      <div class="task-modal__mobile-actions">
        <button @click="$store.dispatch('baseSideModal/closeModal')"
                class="button button--mode-outline task-modal__mobile-back">
          <icon-arrow-left/>
        </button>
      </div>

    </div>
  </div>
</template>

<script>
import BaseSideModalSkeleton from "@/components/BaseSideModalSkeleton.vue";
import IconArrowLeft from "@/components/Icons/IconArrowLeft.vue";
import {mapState} from "vuex";
import LayerSemanticSourcesList from "@/components/EngineeringNetworks/MapLayers/LayerSemanticSourcesList.vue";

export default { 
  name: "LayerModalContent",
  components: {
    LayerSemanticSourcesList,
    IconArrowLeft,
    BaseSideModalSkeleton
  },
  computed: {
    ...mapState({
      layer: state => state.layer.layer,
      loading: state => state.layer.loading
    })
  }
}
</script>

<style scoped>

</style>