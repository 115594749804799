import NetworkObjectType from "@/models/telemetry/NetworkObjectType";

export default class Question {
    constructor(params) {
        this.id = params.id;
        this.question = params.question;
        this.answerType = params.answer_type;
        this.dictionary = params.dictionary;
        this.objectType = new NetworkObjectType({id: params.object_type.id, attributes: params.object_type})
    }

    static ANSWER_TEXT = {id: 1, label: 'Текстовое поле'};
    static ANSWER_NUM = {id: 2, label: 'Числовое поле'};
    static ANSWER_DICTIONARY = {id: 3, label: 'Словарь'};

    static getAnswerTypesList = () => {
        return [
            this.ANSWER_TEXT,
            this.ANSWER_NUM,
            this.ANSWER_DICTIONARY
        ]
    }
}