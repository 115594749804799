<template>
  <base-popup
    :title="popupTitle"
    :name="popupName"
  >
    <template #body>
      <div class="form-item">
        <div class="form-item__name">Наименование</div>
        <div class="form-item__value">
          <input
            v-model="payload.name"
            class="base-input base-input--bordered"
            type="text"
            placeholder="Введите наименование">
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Параметры</div>
        <div v-for="(field, index) in addedFieldsList"
             :key="field.id"
             class="form-item__value violation-form-item-value">
          <input
            v-model="field.name"
            class="base-input base-input--bordered"
            type="text"
            placeholder="Введите наименование"
            disabled
          >

          <div
            @click="deleteViolationFieldFromForm(field.id, index)">
            <icon-trash />
          </div>
        </div>
        <div v-for="(field, index) in payload.fields"
             :key="index"
             class="form-item__value violation-form-item-value">
          <input
            v-model="field.label"
            class="base-input base-input--bordered"
            type="text"
            placeholder="Введите наименование">

          <div
            v-if="index === payload.fields.length - 1"
            @click="addFieldToArr()">
            <icon-plus-squared />
          </div>

          <div
            v-else
            @click="deleteField(index)">
            <icon-trash />
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <button
        @click="submitForm()"
        class="button button--mode-primary">Сохранить</button>
    </template>
  </base-popup>
</template>

<script>
import BasePopup from "@/components/BasePopup.vue";
import IconPlusSquared from "@/components/Icons/IconPlusSquared.vue";
import IconTrash from "@/components/Icons/IconTrash.vue";

export default {
  name: "ViolationsForm",
  components: {IconTrash, IconPlusSquared, BasePopup},
  props: {
    popupName: {
      type: String
    },
    violation: {
      type: Object,
      required: true
    },
    createViolation: {
      type: Function,
      required: true
    },
    updateViolation: {
      type: Function,
      required: true
    },
    deleteViolationField: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      addedFieldsList: [],
      payload: {
        name: '',
        fields: [{label: ''}]
      }
    }
  },
  computed: {
    isCreateForm() {
      return !Object.keys(this.violation).length
    },
    popupTitle() {
      return this.isCreateForm ? 'Добавить нарушение' : 'Редактирование нарушения';
    }
  },
  mounted() {
    if (!this.isCreateForm) {
      const {name, fields} = this.violation;
      this.payload.name = name;
      this.addedFieldsList = fields;
    }
  },
  methods: {
    addFieldToArr() {
      this.payload.fields.push({label: ''});
    },
    deleteField(index) {
      this.payload.fields.splice(index, 1);
    },
    deleteViolationFieldFromForm(id, index) {
      this.deleteViolationField(this.violation.id, id);
      this.addedFieldsList.splice(index, 1);
    },
    submitForm() {
      const payload = {
        ...this.payload,
        fields: this.payload.fields
          .map(item => item.label)
          .filter(item => item.length),
        object_type_id: this.$route.params.id
      }

      if (this.isCreateForm) this.createViolation(payload)
      else this.updateViolation(this.violation.id, payload)
    }
  }
}
</script>

<style lang="scss">
.violation-form-item-value {
  gap: 24px;

  & svg {
    cursor: pointer;

    & path {
      stroke: var(--ico);
    }
  }
}
</style>