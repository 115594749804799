<template>
  <div>
    <base-functional-line @search-change="event => $store.commit('violations/SET_QUERY', event.target.value)">
      <template>
        <button
          @click="openViolationPopup()"
          class="button button--mode-primary">
          <icon-plus/>
          Добавить нарушение
        </button>
      </template>
    </base-functional-line>

    <base-content-loaders v-if="loading"/>

    <div
      v-if="violationsList.length"
      class="base-table">
      <div class="base-table__container">
        <div class="base-table__header base-table__column--violation">
          <div class="base-table__header-cell">ID</div>
          <div class="base-table__header-cell">Наименование</div>
          <div class="base-table__header-cell"/>
        </div>

        <div v-for="violation in violationsList"
             :key="violation.id"
             class="base-table__row base-table__column--violation">
          <div class="base-table__column">
            <div class="base-table__text">{{ violation.id }}</div>
          </div>
          <div class="base-table__column">
            <div class="base-table__text">{{ violation.name }}</div>
          </div>
          <div class="base-table__column base-table__column--content-right">
            <div class="base-table__short-info">
              <div @click="openViolationPopup(violation)">
                <icon-pencil class="base-table__short-info--p-2"/>
              </div>
              <div @click="deleteViolation(violation.id)">
                <icon-trash/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <base-no-data v-else-if="!loading"/>
  </div>
</template>

<script>
import BaseFunctionalLine from "@/components/BaseFunctionalLine.vue";
import IconPlus from "@/components/Icons/IconPlus.vue";
import IconPencil from "@/components/Icons/IconPencil.vue";
import IconTrash from "@/components/Icons/IconTrash.vue";
import ViolationsForm from "@/components/TechnicalInspection/NetworkObjectTypes/ViolationsForm.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import BaseNoData from "@/components/BaseNoData.vue";
import {showToast, showAlert, showConfirmationModal} from "@/utils/notification";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";

export default {
  name: "ViolationsList",
  components: {BaseContentLoaders, BaseNoData, IconTrash, IconPencil, IconPlus, BaseFunctionalLine},
  props: {
    violationsList: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      popupName: 'violations-popup'
    }
  },
  methods: {
    openViolationPopup(violation = {}) {
      this.$modal.show(
        ViolationsForm,
        {
          'popupName': this.popupName,
          'violation': violation,
          'createViolation': this.createViolation,
          'updateViolation': this.updateViolation,
          'deleteViolationField': this.deleteViolationField
        },
        {
          name: this.popupName,
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        }
      )
    },
    createViolation(payload) {
      this.$store.dispatch('violations/createViolation', payload)
        .then(() => {
          showToast('Нарушение добавлено', 'success');
          this.$modal.hide(this.popupName);
          this.$store.dispatch('violations/getViolationsList', this.$route.params.id);
        })
        .catch(error => showAlert('Ошибка', error));
    },
    updateViolation(id, payload) {
      this.$store.dispatch('violations/updateViolation', {id, payload})
        .then(() => {
          showToast('Нарушение изменено', 'success');
          this.$modal.hide(this.popupName);
          this.$store.dispatch('violations/getViolationsList', this.$route.params.id);
        })
        .catch(error => showAlert('Ошибка', error));
    },
    deleteViolation(id) {
      showConfirmationModal('Вы уверены, что хотите удалить нарушение?')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('violations/deleteViolation', id)
              .then(() => {
                showToast('Нарушение удалено', 'success');
                this.$store.dispatch('violations/getViolationsList', this.$route.params.id);
              })
              .catch(error => showAlert('Ошибка', error));
          }
        })
    },
    deleteViolationField(id, fieldId) {
      this.$store.dispatch('violations/deleteViolationField', {id, fieldId})
        .then(() => {
          showToast('Поле удалено', 'success');
          this.$store.dispatch('violations/getViolationsList', this.$route.params.id);
        })
        .catch(error => showAlert('Ошибка', error));
    }
  }
}
</script>

<style scoped>
.base-table__column--violation {
  grid-template-columns: 60px 1fr 100px;
}
</style>