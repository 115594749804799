import {getError} from "@/utils/helpers";
import ZonesService from "@/services/ZonesService";

export const namespaced = true;
export const state = {
    zones: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    page: 1,
    updateZonesLoading: false
};

export const mutations = {
    SET_ZONES(state, zones) {
        state.zones = zones;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    DELETE_ZONES(state, zone_id) {
        let index = state.zones.findIndex(zone => zone.id == zone_id);
        state.zones.splice(index, 1);
    },
    SET_PAGE(state, page) {
        state.page = page;
    },
    SET_UPDATE_ZONES_LOADING(state, loading) {
        state.updateZonesLoading = loading;
    }
};

export const actions = {
    getZones({commit, state}) {
        commit("SET_LOADING", true);
        ZonesService.getZones(state.page)
            .then(({zonesList, meta}) => {
                commit("SET_ZONES", zonesList);
                commit("SET_META", meta);
            })
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getAllZones({commit}) {
        commit("SET_LOADING", true);
        ZonesService.getAllZones()
            .then(response => {
                commit("SET_ZONES", response.data.data);
            })
            .catch(error => {
                commit("SET_LOADING", false);
                commit("SET_ERROR", getError(error));
            });
    },
    updateZonesList({commit}) {
        commit("SET_UPDATE_ZONES_LOADING", true)
        return new Promise((resolve, reject) => {
            ZonesService.updateZonesAuto()
                .then(() => resolve())
                .catch(error => reject(error))
                .finally(() => commit("SET_UPDATE_ZONES_LOADING", false))
        })
    },
    updateZone(context, {zoneId, payload}) {
        return new Promise((resolve, reject) => {
            ZonesService.updateZone(zoneId, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    deleteZone(context, zoneId) {
        return new Promise((resolve, reject) => {
            ZonesService.deleteZone(zoneId)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    }
};

export const getters = {
    getZone: state => id => {
        return state.zones.find(zone => zone.id == id);
    },
    getZonesByNetworkType: state => type => {
        return state.zones.filter(zone => zone.attributes.type === type);
    },
    getActiveZones: state => {
        return state.zones.filter(function (zone) {
            return zone.attributes.active == true
        })
    },
    getName: state => id => {
        if (state.zones != null) {
            let f = state.zones.find(zone => zone.id == id);
            if (f != null) {
                return f.attributes.name;
            } else {
                return 'Не найдено';
            }
        } else {
            return 0;
        }
    },
    getNameBySys: state => sys => {
        if (state.zones != null) {
            let f = state.zones.find(zone => zone.attributes.sys_id == sys);
            if (f != null) {
                return f.attributes.name;
            } else {
                return 'Не найдено';
            }
        } else {
            return 0;
        }
    },
    getNames: state => ids => {
        if (state.zones != null) {
            let names = state.zones.filter(zone => ids.indexOf(zone.id) != -1)
            return names.map(zone => zone.attributes.name).join(', ')
        } else {
            return ''
        }
    },
    zones: state => {
        return state.zones;
    },
    meta: state => {
        return state.meta;
    },
    links: state => {
        return state.links;
    },
    loading: state => {
        return state.loading;
    },
    error: state => {
        return state.error;
    }
};