<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      {{title}}
      <span @click="$modal.hide(name)"
            class="base-popup__close">
        <icon-close/>
      </span>
    </div>
    <div class="popup-form-group">
      <slot name="body"/>
    </div>
    <div class="base-popup__footer">
      <slot name="footer"/>
    </div>
  </div>
</template>

<script>
import IconClose from "@/components/Icons/IconClose.vue";

export default {
  name: "BasePopup",
  components: {IconClose},
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>