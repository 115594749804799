import * as API from './API'
import TransportDataServerType from "@/models/transport/TransportDataServerType";
import {getError} from "@/utils/helpers";

export default {
    fetchAllTransportDataServerTypes() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/transport-data-sources/types`)
                .then(response => resolve(response.data.data.map(item => new TransportDataServerType(item))))
                .catch(error => reject(getError(error)));
        })
    }
}