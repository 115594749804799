import NetworkEquipmentParametersService from "@/services/NetworkEquipmentParametersService";
import {getError} from "@/utils/helpers";

export const namespaced = true;

export const actions = {
    createNetworkEquipmentParameter(context, {equipmentId, payload}) {
        return new Promise((resolve, reject) => {
            NetworkEquipmentParametersService.createNetworkEquipmentParameter(equipmentId, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateNetworkEquipmentParameter(context, {equipmentId, parameterId, payload}) {
        return new Promise((resolve, reject) => {
            NetworkEquipmentParametersService.updateNetworkEquipmentParameter(equipmentId, parameterId, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteNetworkEquipmentParameter(context, {equipmentId, parameterId}) {
        return new Promise((resolve, reject) => {
            NetworkEquipmentParametersService.deleteNetworkEquipmentParameter(equipmentId, parameterId)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateParameterProfile(context, {equipmentId, parameterId, payload}) {
        return new Promise((resolve, reject) => {
            NetworkEquipmentParametersService.updateParameterProfile(equipmentId, parameterId, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    }
}