<template>
  <div
    class="i-modal is-default-hidden"
    :class="{'is-active': isActive, 'is-horizontal': isHorizontal}"
  >
    <div @click="closeModal()"
         class="i-modal__float-close">
      <icon-close-modal />
    </div>
    <div class="i-modal__content">
      <div class="i-modal__inner">
        <slot/>
      </div>
    </div>
    <div class="i-modal-btn-wp i-modal-btn-wp--back">
      <slot name="stickyBottom" />
    </div>
  </div>
</template>
<script>
import IconCloseModal from "@/components/Icons/IconCloseModal";

export default {
  name: "ModalCard",
  components: {IconCloseModal},
  props: {
    isActive: {
      type: Boolean,
      require: true
    },
    isHorizontal: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    }
  }
}
</script>
