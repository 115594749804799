<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="24"
       height="24"
       viewBox="0 0 24 24"
       fill="none">
    <path
      d="M12.1921 11.0599C12.1012 11.0508 11.9922 11.0508 11.8923 11.0599C10.8494 11.0245 9.86122 10.5846 9.13706
      9.83328C8.41289 9.08198 8.00958 8.07832 8.01255 7.03483C8.01255 4.80877 9.81157 3.00066 12.0467 3.00066C12.5759
       2.99111 13.1018 3.08589 13.5943 3.27958C14.0869 3.47327 14.5365 3.76207 14.9174 4.12951C15.2983 4.49695 15.6032
        4.93582 15.8145 5.42106C16.0258 5.90631 16.1395 6.42842 16.149 6.9576C16.1586 7.48678 16.0638 8.01266 15.8701
         8.50521C15.6764 8.99776 15.3876 9.44734 15.0202 9.82828C14.6528 10.2092 14.2139 10.514 13.7286 10.7254C13.2434
          10.9367 12.7213 11.0504 12.1921 11.0599ZM7.64911 14.4127C5.4503 15.8846 5.4503 18.2833 7.64911 19.7461C10.1478
           21.418 14.2455 21.418 16.7442 19.7461C18.943 18.2742 18.943 15.8755 16.7442 14.4127C14.2546 12.7499 10.1568
            12.7499 7.64911 14.4127Z"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconProfile"
}
</script>

<style scoped>

</style>
