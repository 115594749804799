import TasksService from "@/services/TasksService";

export const namespaced = true;

export const state = {
    files: [],
    loading: false,
    error: null
}

export const mutations = {
    SET_FILES(state, files) {
        state.files = files;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getTasksFiles({commit}, task_id) {
        commit("SET_LOADING", true);
        TasksService.getTaskFiles(task_id)
            .then(files => commit("SET_FILES", files))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    }
}

export const getters = {
    getFiles: state => {
        return state.files;
    }
}