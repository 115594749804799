import * as API from './API';
import Sensor from "@/models/telemetry/Sensor";
import {getError} from "@/utils/helpers";

export default {
    getAllSensors() {
        return API.apiClient.get(`/object-monitoring/sensors`);
    },
    getSensor(sensorId){
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/object-monitoring/sensors/${sensorId}`)
                .then(response => resolve(new Sensor(response.data.data)))
                .catch(error => reject(getError(error)))
        })
    },
    createSensor(payload){
        return API.apiClient.post(`/object-monitoring/sensors`, payload);
    },
    updateSensor(sensorId, payload){
        return API.apiClient.patch(`/object-monitoring/sensors/${sensorId}`, payload);
    },
    deleteSensor(sensorId){
        return API.apiClient.delete(`/object-monitoring/sensors/${sensorId}`);
    },
    fetchDeviceSensorsList(deviceId) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/object-monitoring/devices/${deviceId}/sensors`)
                .then(response => resolve(response.data.data.map(item => new Sensor({
                                id: item.id,
                                ...item.attributes
                            })
                        )))
                .catch(error => reject(getError(error)));
        })
    },
    attachSensorToParameter(sensorId, payload) {
        return API.apiClient.post(`/object-monitoring/sensors/${sensorId}/attach-parameter`, payload);
    },
    detachSensorFromParameter(sensorId) {
        return API.apiClient.post(`/object-monitoring/sensors/${sensorId}/detach-parameter`);
    }
}