<script>
export default {
  name: "IconCounter"
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none">
    <g clip-path="url(#clip0_1054_36583)">
      <path
        d="M12 0C18.6275 0 24 5.37258 24 12C24 18.6275 18.6275 24 12 24C5.37258 24 0 18.6275 0 12C0 5.37258
        5.37258 0 12 0Z"
        fill="#262F3D"/>
      <path
        d="M2.15899 6.5625H21.8413C23.0726 8.32378 23.8437 10.4306 23.9787 12.7087C23.8076 15.6467 22.5799 18.3011
        20.6693 20.2969H3.33086C1.42028 18.3011 0.192672 15.6467 0.0214844 12.7087C0.156625 10.4306 0.927625 8.32378
        2.15899 6.5625Z"
        fill="#262F3D"/>
      <path
        d="M24 11.7187C24 11.7341 23.9985 11.7491 23.9977 11.7643C23.9992 11.8427 24 11.9213 24 12C24 14.5542 23.2014
         16.9215 21.8412 18.8672H2.15888C0.79861 16.9215 0 14.5542 0 12C0 11.9213 0.000796875 11.8427 0.00229688
          11.7643C0.00154688 11.7491 0 11.7341 0 11.7187V5.15625C0 4.63847 0.419719 4.21875 0.9375
          4.21875H23.0625C23.5803 4.21875 24 4.63847 24 5.15625V11.7187Z"
        fill="#468FC5"/>
      <path
        d="M0 12C0 11.9213 0.000796875 11.8427 0.00229688 11.7643C0.00154688 11.7491 0 11.7341 0 11.7187V5.15625C0
        4.63847 0.419719 4.21875 0.9375 4.21875H2.8125C2.29472 4.21875 1.875 4.63847 1.875
        5.15625V10.7813V11.7187V18.4414C0.688406 16.5804 0 14.3708 0 12Z"
        fill="#468FC5"/>
      <path
        d="M7.79557 7.66797V15.4689C7.79557 15.9867 7.37585 16.4064 6.85806 16.4064H2.04785C1.53007 16.4064 1.11035
        15.9867 1.11035 15.4689V7.66797C1.11035 7.15019 1.53007 6.73047 2.04785 6.73047H6.85802C7.3758 6.73047 7.79557
        7.15019 7.79557 7.66797ZM14.405 6.73047H9.59473C9.07694 6.73047 8.65722 7.15019 8.65722 7.66797V15.4689C8.65722
         15.9867 9.07694 16.4064 9.59473 16.4064H14.4049C14.9227 16.4064 15.3424 15.9867 15.3424 15.4689V7.66797C15.3424
          7.15019 14.9227 6.73047 14.405 6.73047ZM21.9518 6.73047H17.1415C16.6238 6.73047 16.2041 7.15019 16.2041
          7.66797V15.4689C16.2041 15.9867 16.6238 16.4064 17.1415 16.4064H21.9517C22.4695 16.4064 22.8893 15.9867
          22.8893 15.4689V7.66797C22.8894 7.15019 22.4695 6.73047 21.9518 6.73047Z"
        fill="#262F3D"/>
      <path
        d="M5.19039 9.42622V14.5484C5.19039 14.8179 4.97121 15.0371 4.70171 15.0371H4.31092C4.04144 15.0371 3.82225
         14.8178 3.82225 14.5484V11.3484H3.50331C3.33662 11.3484 3.18944 11.2545 3.11922 11.1033C3.04895 10.9521
         3.07215 10.7791 3.17969 10.6517L4.2647 9.13414C4.37008 9.00916 4.52439 8.9375 4.68789 8.9375H4.70168C4.97116
         8.93754 5.19039 9.15673 5.19039 9.42622ZM14.3719 11.3096V12.665C14.3719 13.9751 13.3099 15.0371 11.9998
          15.0371C10.6898 15.0371 9.62773 13.9751 9.62773 12.665V11.3096C9.62773 9.99947 10.6898 8.9375 11.9998
          8.9375C13.3099 8.93754 14.3719 9.99957 14.3719 11.3096ZM13.0164 11.3096C13.0164 10.7481 12.5613 10.2929
          11.9998 10.2929C11.4384 10.2929 10.9832 10.7481 10.9832 11.3096V12.665C10.9832 13.2265 11.4384 13.6816
           11.9998 13.6816C12.5613 13.6816 13.0164 13.2265 13.0164 12.665V11.3096ZM21.9188 11.3096V12.665C21.9188
            13.9751 20.8567 15.0371 19.5467 15.0371C18.2366 15.0371 17.1747 13.9751 17.1747 12.665V11.3096C17.1747
            9.99947 18.2366 8.9375 19.5467 8.9375C20.8567 8.93754 21.9188 9.99957 21.9188 11.3096ZM20.5633
            11.3096C20.5633 10.7481 20.1081 10.2929 19.5467 10.2929C18.9852 10.2929 18.5301 10.7481 18.5301
             11.3096V12.665C18.5301 13.2265 18.9852 13.6816 19.5467 13.6816C20.1081 13.6816 20.5633 13.2265
             20.5633 12.665V11.3096Z"
        fill="#A4D9F6"/>
      <path
        d="M22.8894 7.66797V8.00594H16.2041V7.66797C16.2041 7.15019 16.6238 6.73047 17.1415 6.73047H21.9517C22.4695
        6.73047 22.8894 7.15019 22.8894 7.66797ZM14.405 6.73047H9.59473C9.07694 6.73047 8.65722 7.15019 8.65722
        7.66797V8.00594H15.3424V7.66797C15.3424 7.15019 14.9227 6.73047 14.405 6.73047ZM6.85806
        6.73047H2.04785C1.53007 6.73047 1.11035 7.15019 1.11035 7.66797V8.00594H7.79552V7.66797C7.79556 7.15019
        7.3758 6.73047 6.85806 6.73047Z"
        fill="#262F3D"/>
    </g>
    <defs>
      <clipPath id="clip0_1054_36583">
        <rect width="24"
              height="24"
              fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped>

</style>