<template>
  <div
    class="parameter__wrapper"
    :class="[{'sensor-block': map}]"
  >
    <div class="parameter__value">
      <div
        class="sensor-short-name"
        :class="{'sensor_danger_value': isValueOutOfProfile(
          parameterValue(parameter.id).value,
          parameterProfile(parameter.id)
        )}"
      >
        <div
          :id="`shortName_${parameter.id}`"
          class="sensor-short-name-content"
        >
          <span v-html="processParameterShortName()" />
        </div>
      </div>
      <div class="sensor-value-wrapper">
        <div class="sensor-value">
          <parameter-value
            :parameter-value="parameterValue(parameter.id)"
            :precision="parameter.precision"
          />
        </div>
        <div class="sensor-measure">
          {{ parameter.measure }}
        </div>
      </div>
    </div>
    <div class="parameter__value_time">
      <div class="time-block"
           :class="getParameterValueDateClass(parameter.id)">
        {{ formatParameterValueDate(parameter.id) }}
      </div>
    </div>


  </div>
</template>

<script>
import {mapGetters} from "vuex";
import sensorsTime from "@/mixins/sensorsTime";
import {roundedNumber} from "@/utils/helpers";
import ParameterValue from "@/components/Telemetry/ParameterValue.vue";
import {isValueOutOfProfile} from "@/utils/helpers";

export default {
  name: "ParameterItem",
  components: {ParameterValue},
  mixins: [sensorsTime],
  props: ["parameter", "map"],
  computed: {
    ...mapGetters({
      parameterValue: 'telemetry/getParameterValue',
      parameterProfile: 'telemetry/getParameterProfile'
    })
  },
  methods: {
    roundedNumber,
    isValueOutOfProfile,
    processParameterShortName() {
      let name = this.parameter.shortName
      if (name.length > 1) {
        let nameArr = name.split('')
        return `<div class="d-flex align-items-center">${nameArr[0]} <sub>${nameArr.splice(1).join()}</sub></div>`
      } else {
        return `<div>${name}</div>`
      }
    }
  }
}
</script>

<style scoped>
.parameter__wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 5px;
  z-index: 1;
}

.parameter__value {
  display: flex;
}

.parameter__value_time {}

.device-item {
  margin-top: 5px;
}

.data_border {
  border-left: 1px solid #0000001f;
  display: flex;
  padding: 0;
}

.data_border:nth-last-child(1) {
  border-left: none;
}

.data_border div {
  margin: auto;
  text-align: center
}

.sensor-short-name {
  position: relative;
  display: inline-block;
  font-weight: 600;
  color: var(--text-link);
  margin-right: 5px;
  width: 22px;
  height: 22px;
  min-width: 22px;
  background: url("@/assets/img/telemetry/sensor-border.svg") no-repeat;
}

.sensor-short-name-content {
  font-size: 15px;
  line-height: 20px;
}

.sensor-value {
  display: block;
  font-size: 14px;
  font-weight: 700;
  color: var(--text-primary);
  line-height: 14px;
}

.sensor-measure {
  display: block;
  font-size: 10px;
  font-weight: 600;
  color: var(--text-primary);
  line-height: 10px;
}

.sensor_danger_value {
  background: url("@/assets/img/telemetry/sensor-border-danger.svg") no-repeat !important;
}

.sensor-block:nth-child(1) {
  margin-bottom: 10px;
}

.sensor-value-wrapper {
  display: inline-block;
}
</style>
