export default class Layer {
    constructor(params) {
        this.id = params.id;
        this.name = params.name;
        this.url = params.url;
        this.resource = params.resource;
        this.username = params.username;
        this.password = params.password;
        this.authString = params.auth_string;
        this.semanticSources = params.semantic_sources;
    }
}