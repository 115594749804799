<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="24"
       height="13"
       viewBox="0 0 24 13"
       fill="none">
    <path
      d="M23.0256 6.13281V8.26561H0.590401V5.47468C0.590401 4.99023 0.766237 4.52225 1.08525 4.15767L4.12559
       0.682991C4.50537 0.248961 5.05402 0 5.63074 0H15.021C15.6048 0 16.1595 0.255126 16.5395 0.698418L19.4832
        4.13281H21.0256C22.1302 4.13281 23.0256 5.02824 23.0256 6.13281Z"
      fill="#6375D4"/>
    <path
      d="M17.2274 4.01102H11.6916C11.6392 4.01152 11.5872 4.0015 11.5387 3.98154C11.4902 3.96159 11.446 3.9321
       11.4088 3.89476C11.3716 3.85743 11.342 3.813 11.3219 3.76404C11.3017 3.71508 11.2913 3.66256 11.2913
        3.60952V1.34097C11.2913 1.28792 11.3017 1.23541 11.3219 1.18645C11.342 1.13749 11.3716 1.09306 11.4088
         1.05573C11.446 1.01839 11.4902 0.988898 11.5387 0.968944C11.5872 0.94899 11.6392 0.938973 11.6916
          0.939471H15.1152C15.1691 0.939567 15.2225 0.950819 15.2719 0.972537C15.3214 0.994255 15.3659 1.02598
           15.4029 1.06576L17.516 3.33432C17.569 3.39137 17.6045 3.4629 17.6178 3.54008C17.6312 3.61727 17.622
            3.69673 17.5913 3.76867C17.5606 3.84061 17.5098 3.90189 17.4452 3.94496C17.3805 3.98802 17.3048
            4.01098 17.2274 4.01102Z"
      fill="#A6B4FF"/>
    <path
      d="M9.74301 4.011H3.18951C3.1126 4.01108 3.03733 3.98847 2.97292 3.94593C2.9085 3.90338 2.85773 3.84275
       2.82682 3.77145C2.7959 3.70016 2.78619 3.62128 2.79885 3.54448C2.81152 3.46767 2.84602 3.39627 2.89814
        3.33901L4.95262 1.06952C4.98982 1.02856 5.035 0.995851 5.08531 0.973464C5.13562 0.951078 5.18997 0.939497
         5.24493 0.939453H9.74301C9.84818 0.939453 9.94905 0.981754 10.0234 1.05705C10.0978 1.13234 10.1396 1.23447
          10.1396 1.34095V3.6095C10.1396 3.66223 10.1293 3.71444 10.1094 3.76315C10.0895 3.81186 10.0602 3.85612
           10.0234 3.8934C9.9866 3.93069 9.94288 3.96026 9.89477 3.98044C9.84665 4.00062 9.79509 4.011 9.74301 4.011Z"
      fill="#A6B4FF"/>
    <path
      d="M13.115 5H11.2355C11.1054 5 11 5.10676 11 5.23845V5.48349C11 5.61518 11.1054 5.72194 11.2355
       5.72194H13.115C13.2451 5.72194 13.3505 5.61518 13.3505 5.48349V5.23845C13.3505 5.10676 13.2451 5 13.115 5Z"
      fill="#F9F6F9"/>
    <path
      d="M22 6.7059C22 6.31604 22.316 6 22.7059 6H23.4148V7.4118H22.7059C22.316 7.4118 22 7.09576 22 6.7059Z"
      fill="#F6F9FC"/>
    <path
      d="M5.11499 5H3.23552C3.10544 5 3 5.10676 3 5.23845V5.48349C3 5.61518 3.10544 5.72194 3.23552
      5.72194H5.11499C5.24507 5.72194 5.35051 5.61518 5.35051 5.48349V5.23845C5.35051 5.10676 5.24507 5 5.11499 5Z"
      fill="#F9F6F9"/>
    <path
      d="M23.0205 8.0835H0.776366C0.347591 8.0835 0 8.43541 0 8.86953V9.07027C0 9.50439 0.347591 9.8563
      0.776366 9.8563H23.0205C23.4492 9.8563 23.7968 9.50439 23.7968 9.07027V8.86953C23.7968 8.43541
      23.4492 8.0835 23.0205 8.0835Z"
      fill="#3348B7"/>
    <path
      d="M18.8022 7C17.2605 7 16 8.28297 16 9.85206C16 11.4211 17.2605 12.7011 18.8022 12.7011C20.3438 12.7011
      21.6014 11.4211 21.6014 9.85206C21.6014 8.28297 20.3438 7 18.8022 7Z"
      fill="#262F3D"/>
    <path
      d="M18.8019 8.62891C18.1439 8.62891 17.6001 9.18236 17.6001 9.85205C17.6001 10.5218 18.1439 11.0722 18.8019
       11.0722C19.4599 11.0722 20.0007 10.5218 20.0007 9.85205C20.0007 9.18236 19.4599 8.62891 18.8019 8.62891Z"
      fill="white"/>
    <path
      d="M4.80215 7C3.2605 7 2 8.28297 2 9.85206C2 11.4211 3.2605 12.7011 4.80215 12.7011C6.34381 12.7011 7.60137
       11.4211 7.60137 9.85206C7.60137 8.28297 6.34381 7 4.80215 7Z"
      fill="#262F3D"/>
    <path
      d="M4.80187 8.62891C4.14388 8.62891 3.60011 9.18236 3.6001 9.85205C3.6001 10.5218 4.14387 11.0722 4.80187
       11.0722C5.45988 11.0722 6.0007 10.5218 6.00069 9.85205C6.00068 9.18236 5.45987 8.62891 4.80187 8.62891Z"
      fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "IconCar"
}
</script>

<style scoped>

</style>
