<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="32"
       height="32"
       viewBox="0 0 32 32"
       fill="none">
    <path d="M9.40044 22.5997L22.5998 9.40034M22.5998 22.5997L9.40044 9.40034"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconCloseModal"
}
</script>

<style scoped>

</style>