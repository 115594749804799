<template>
  <base-popup
    title="Введите тариф"
    :name="popupName"
  >
    <template #body>
      <div
        v-for="supply in periods[0].supply"
        :key="supply.device_id"
      >
        <div class="form-group">
          <label class="form-label">{{ getDeviceName(supply.device_id) }}</label>
          <input
            class="base-input base-input--bordered"
            placeholder="Стоимость ресурса"
            type="text"
            @change="setRate($event.target.value, supply.device_id)"
          >
        </div>
      </div>
    </template>
  </base-popup>
</template>

<script>
import BasePopup from "@/components/BasePopup.vue";

export default {
  name: "BalanceRateModalContent",
  components: {BasePopup},
  props: {
    popupName: {
      type: String,
      required: true
    },
    periods: {
      type: Array
    },
    setRate: {
      type: Function
    },
    getDeviceName: {
      type: Function
    }
  }
}
</script>

<style scoped>

</style>