<template>
  <div class="base-table base-table--issues">
    <div class="base-table__container">
      <div class="base-table__header base-table__header--issues">
        <div class="base-table__header-cell">
          ID
        </div>
        <div class="base-table__header-cell">Тип</div>
        <div class="base-table__header-cell">Заплан. дата начала</div>
        <div class="base-table__header-cell">Проблема</div>
        <div class="base-table__header-cell">Адрес</div>
        <div class="base-table__header-cell">Подразделение</div>
        <div class="base-table__header-cell">Статус</div>
        <div class="base-table__header-cell">Задания</div>
        <div class="base-table__header-cell"/>
      </div>

      <issues-item
        v-for="issue in issuesList"
        :key="issue.id"
        :issue="issue"
      />
    </div>

  </div>
</template>

<script>
import IssuesItem from "@/components/ServiceDesk/Issues/IssuesItem.vue";

export default {
  name: "IssuesListView",
  components: {IssuesItem},
  props: {
    issuesList: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
</style>
