<template>
  <div class="page-wrapper">
    <base-panel
      title="Инженерные сети"
      :is-add-button-visible="true"
      add-button-name="Добавить сеть"
      @add-button-clicked="showPopup()"
    />

    <engineering-networks-list
      v-if="engineeringNetworksList.length"
      :engineering-networks-list="engineeringNetworksList"
      @update-engineering-network="showPopup"
      @switch-engineering-network-state="switchEngineeringNetworkState"
    />

    <base-content-loaders v-if="loading && !engineeringNetworksList.length"/>

    <base-no-data v-if="!loading && !engineeringNetworksList.length"/>

    <base-paginate
      v-if="meta && meta.last_page > 1"
      :meta="meta"
      action="engineeringNetworks/getEngineeringNetworks"
      commit="engineeringNetworks/SET_PAGE"
    />
  </div>
</template>

<script>
import EngineeringNetworksList from "@/components/EngineeringNetworks/EngineeringNetworksList";
import BasePanel from "@/components/BasePanel.vue";
import {mapState} from "vuex";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import BasePaginate from "@/components/BasePaginate.vue";
import EngineeringNetworkForm from "@/components/EngineeringNetworks/EngineeringNetworkForm.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import {showAlert, showToast} from "@/utils/notification";

export default {
  name: "EngineeringNetworksListPage",
  components: {BasePaginate, BaseNoData, BaseContentLoaders, BasePanel, EngineeringNetworksList},
  computed: {
    ...mapState({
      engineeringNetworksList: state => state.engineeringNetworks.engineeringNetworks,
      loading: state => state.engineeringNetworks.loading,
      meta: state => state.engineeringNetworks.meta
    })
  },
  mounted() {
    this.$store.dispatch('engineeringNetworks/getEngineeringNetworks');
  },
  methods: {
    showPopup(engineeringNetwork = {}) {
      this.$modal.show(
        EngineeringNetworkForm,
        {
          'engineeringNetwork': engineeringNetwork,
          'createEngineeringNetwork': this.createEngineeringNetwork,
          'updateEngineeringNetwork': this.updateEngineeringNetwork
        },
        {
          name: 'engineering-network-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        })
    },
    createEngineeringNetwork(payload) {
      this.$store.dispatch('engineeringNetworks/createEngineeringNetworks', payload)
        .then(() => {
          this.$store.dispatch('engineeringNetworks/getEngineeringNetworks');
          this.$modal.hide('engineering-network-form');
          showToast('Инженерная сеть создана', 'success');
        })
        .catch(error => showAlert('Ошибка', error, 'error'));
    },
    updateEngineeringNetwork(networkId, payload) {
      this.$store.dispatch('engineeringNetworks/updateEngineeringNetwork', {
        networkId, payload
      })
        .then(() => {
          this.$store.dispatch('engineeringNetworks/getEngineeringNetworks');
          this.$modal.hide('engineering-network-form');
          showToast('Инженерная сеть обновлена', 'success');
        })
        .catch(error => showAlert('Ошибка', error, 'error'));
    },
    switchEngineeringNetworkState({networkId, event}) {
      if (event) this.activateEngineeringNetwork(networkId)
      else this.deactivateEngineeringNetwork(networkId)
    },
    activateEngineeringNetwork(networkId) {
      this.$store.dispatch('engineeringNetworks/activateEngineeringNetwork', networkId)
        .then(() => {
          showToast('Сеть переведена в статус "Активна"', 'success');
          this.$store.commit('engineeringNetworks/TOGGLE_ENGINEERING_NETWORK_STATE', networkId);
        })
        .catch(error => showAlert('Ошибка', error, 'error'));
    },
    deactivateEngineeringNetwork(networkId) {
      this.$store.dispatch('engineeringNetworks/deactivateEngineeringNetwork', networkId)
        .then(() => {
          showToast('Сеть переведена в статус "Не активна"', 'success');
          this.$store.commit('engineeringNetworks/TOGGLE_ENGINEERING_NETWORK_STATE', networkId);
        })
        .catch(error => showAlert('Ошибка', error, 'error'));
    }
  }
}
</script>

<style scoped>

</style>