import { render, staticRenderFns } from "./IssueTasksList.vue?vue&type=template&id=ffc9b268&scoped=true&"
import script from "./IssueTasksList.vue?vue&type=script&lang=js&"
export * from "./IssueTasksList.vue?vue&type=script&lang=js&"
import style0 from "./IssueTasksList.vue?vue&type=style&index=0&id=ffc9b268&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffc9b268",
  null
  
)

export default component.exports