<template>
  <div class="appeals-map reset-leaflet-popup-styles">
    <base-map
      :center="center"
      :zoom="zoom"
      :base-layers="baseLayers"
      :layers="layers"
      :markers="createMarkers()"
    />
  </div>
</template>

<script>
import BaseMap from "@/components/BaseMap";
import {mapState} from "vuex";
import Vue from 'vue';
import AppealMapIcon from "@/components/CallCenter/AppealMapIcon.vue";
import AppealMapPopupContent from "@/components/CallCenter/AppealMapPopupContent.vue";
import Marker from "@/models/baseMap/Marker";
import Icon from "@/models/baseMap/Icon";

export default {
  name: "AppealsListMap",
  components: {BaseMap},
  props: {
    appealsList: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState({
      center: state => state.baseMap.center,
      zoom: state => state.baseMap.zoom,
      layers: state => state.baseMap.layers,
      baseLayers: state => state.baseLayers.baseLayers
    })
  },
  mounted() {
    this.$store.dispatch('baseMap/getLayers');
  },
  beforeDestroy() {
    this.$store.commit("baseMap/SET_LAYERS", []);
  },
  methods: {
    createMarkers() {
      return this.appealsList.map(appeal => {
        const AppealIconClass = Vue.extend(AppealMapIcon);
        const iconInstance = new AppealIconClass();
        iconInstance.appeal = appeal;

        const icon = iconInstance.$mount();
        const iconHtml = icon.$el.outerHTML;

        const AppealPopupClass = Vue.extend(AppealMapPopupContent);
        const popupInstance = new AppealPopupClass();
        popupInstance.appeal = appeal;

        const popup = popupInstance.$mount();

        const marker = new Marker({
          coords: appeal.coordinates,
          accumulation: true,
          icon: new Icon({
            type: 'div_icon',
            size: [36, 42],
            anchor: [18, 21],
            html: iconHtml
          }),
          popup_content: popup.$el
        })

        icon.$destroy();
        popup.$destroy();

        return marker;
      })
    }
  }
}
</script>

<style scoped>
.appeals-map {
  height: calc(100vh - 280px);
  position: relative;
  z-index: 9;
}
</style>
