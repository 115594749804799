import * as API from './API';
import Role from "@/models/Role";
import {getError} from "@/utils/helpers";

export default {
    getAllRoles() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/roles`)
                .then(response => resolve(response.data.data.map(item => new Role(item))))
                .catch(error => reject(getError(error)));
        })
    },
    getRoles(page){
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/roles`, {params: {page: page}})
                .then(response => resolve({
                    roles: response.data.data.map(item => new Role(item)),
                    meta: response.data.meta
                }))
                .catch(error => reject(getError(error)));
        })
    },
    getRole(role_id){
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/roles/${role_id}`)
                .then(response => resolve(new Role(response.data.data)))
                .catch(error => reject(getError(error)));
        })
    },
    createRole(data){
        return API.apiClient.post(`/roles`,data);
    },
    updateRole(role_id, data){
        return API.apiClient.patch(`/roles/${role_id}`,data);
    },
    deleteRole(role_id){
        return API.apiClient.delete(`/roles/${role_id}`);
    },
    assignPermission(roleId, permissionId) {
        return API.apiClient.post(`/roles/${roleId}/permissions/${permissionId}`);
    },
    removePermission(roleId, permissionId) {
        return API.apiClient.delete(`/roles/${roleId}/permissions/${permissionId}`);
    }
}