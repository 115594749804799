<template>
  <div class="scada-table">
    <div class="scada-table__header-wrapper">
      <div class="scada-table__header">
        <div>Сетевой объект</div>
        <div>Оборудование</div>
        <div>T1</div>
        <div>T2</div>
        <div>P1</div>
        <div>P2</div>
        <div>G1</div>
        <div>G2</div>
        <div>Q</div>
        <div class="scada-table__header-cell--pl">∆G</div>
        <div class="scada-table__header-cell--pl">∆Q</div>
      </div>
      <div class="scada-table__subheader">
        <div class="scada-table__subheader-title">Итого</div>
        <div class="scada-table__subheader-values">
          <div class="scada-table__subheader-total-value">
            <div class="scada-table__total-cell">
              {{ equipmentsParametersSumByName('Q').toFixed(1) }}
            </div>
          </div>
          <div class="scada-table__subheader-total-value
           scada-table__subheader-total-value--border-left scada-table__subheader-total-value--padding-left">
            <div class="scada-table__total-cell">
              {{ equipmentsParametersSumByName('∆G').toFixed(0) }}
            </div>
          </div>
          <div class="scada-table__subheader-total-value scada-table__subheader-total-value--padding-left">
            <div class="scada-table__total-cell">
              {{ equipmentsParametersSumByName('∆Q').toFixed(1) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-for="networkObject in networkObjectsContainEquipments"
         :key="networkObject.id"
         class="scada-table__row-group">
      <div class="scada-table__row-group-name">
        {{ networkObject.name }}
        <div class="scada-table__structural-init-tooltip">
          {{ networkObject.structuralUnitsNames }}
        </div>
      </div>
      <div class="scada-table__rows">
        <div v-for="equipment in networkEquipmentsWithParameterValues(networkObject.id)"
             :key="equipment.id"
             @click="openEquipmentModal(equipment)"
             class="scada-table__row"
             :class="{'danger': getNetworkEquipmentDangerStatus(getSortedEquipmentParameters(equipment.parameters))}">
          <div class="scada-table__equipment-name-cell">
            <div v-if="getNetworkEquipmentDangerStatus(getSortedEquipmentParameters(equipment.parameters))"
                 class="scada-table__row-danger-icon">
              <icon-flash/>
            </div>
            {{ equipment.name }}
          </div>
          <div v-for="parameter in getSortedEquipmentParameters(equipment.parameters)"
               :key="`${equipment.id}_${parameter.shortName}`"
               class="scada-table__cell"
               :class="[
                 {'scada-table__cell--border-left': parameter.shortName === '∆G'},
                 {'scada-table__cell--padding-left': parameter.shortName === '∆G' || parameter.shortName === '∆Q'}
               ]"
          >

            <div v-if="parameter.id && parameter.parameterValue.value"
                 :id="`${equipment.id}_${parameter.id}`"
                 class="scada-table__cell-inner">
              <div class="scada-table__cell-content"
                   :class="[
                     {'danger': isValueOutOfProfile(parameter.parameterValue.value, parameterProfile(parameter.id))},
                     {'highlight': parameter.shortName === '∆G' || parameter.shortName === '∆Q'}
                   ]">
                <div class="scada-table__cell-time-status"
                     :class="parameterTimeStatus(parameter.parameterValue)"/>
                <div v-if="Object.keys(parameter.parameterValue).length"
                     class="scada-table__cell-text">
                  {{ roundedNumber(parameter.parameterValue.value, parameter.precision) }}
                </div>
                <div v-else
                     class="scada-table__cell-text">—
                </div>
              </div>
              <parameter-tooltip
                :parameter="parameter"
                :parameter-value="parameter.parameterValue"
                :parameter-profile="parameterProfile(parameter.id)"
              />
            </div>

            <div v-else>—</div>

          </div>
        </div>
        <div
          v-if="networkEquipmentsWithParameterValues(networkObject.id).length > 1"
          class="scada-table__row scada-table__row--total">
          <div class="scada-table__equipment-name-cell scada-table__equipment-name-cell--total">Итого:</div>
          <div class="scada-table__equipment-name-cell">
            {{ networkObjectParametersSumByName(networkObject.id, 'Q').toFixed(1) }}
          </div>
          <div class="scada-table__equipment-name-cell scada-table__equipment-name-cell--border-left
           scada-table__equipment-name-cell--padding-left">
            <div class="scada-table__total-cell">
              {{ networkObjectParametersSumByName(networkObject.id, '∆G').toFixed(0) }}
            </div>
          </div>
          <div class="scada-table__equipment-name-cell scada-table__equipment-name-cell--padding-left">
            <div class="scada-table__total-cell">
              {{ networkObjectParametersSumByName(networkObject.id, '∆Q').toFixed(1) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="networkObjectsLoading">
      <base-content-loaders/>
    </div>
  </div>
</template>

<script>
import IconFlash from "@/components/Icons/IconFlash";
import {mapGetters, mapState} from "vuex";
import ParameterValue from "@/models/telemetry/ParameterValue"
import BaseContentLoaders from "@/components/BaseContentLoaders";
import {formatDate, formatDateTime, nullFilter} from "@/utils/filters";
import {roundedNumber, parameterTimeStatus, isValueOutOfProfile} from "@/utils/helpers";
import ParameterTooltip from "@/components/Telemetry/ParameterTooltip.vue";

export default {
  name: "ScadaTable",
  components: {ParameterTooltip, BaseContentLoaders, IconFlash},
  data() {
    return {
      isActiveModal: false,
      selectedEquipment: null
    }
  },
  computed: {
    ...mapGetters({
      networkObjectsContainEquipments: 'telemetry/getNetworkObjectsContainEquipments',
      networkEquipmentsWithParameterValues: 'telemetry/getNetworkEquipmentsWithParameterValues',
      networkObjectParametersSumByName: 'telemetry/getNetworkObjectParametersSumByName',
      equipmentsParametersSumByName: 'telemetry/getEquipmentsParametersSumByName',
      parameterProfile: 'telemetry/getParameterProfile'
    }),
    ...mapState({
      filterEquipmentsByDangerStatus: state => state.scadaPage.filterEquipmentByDangerStatus,
      parameterNames: state => state.scadaPage.parameterNames,
      networkObjectsLoading: state => state.telemetry.loading
    }),
    ParameterValue: () => ParameterValue
  },
  mounted() {
    const stickyElm = document.querySelector('.scada-table__header-wrapper');
    const observer = new IntersectionObserver(
      ([e]) => e.target.classList.toggle('isSticky', e.intersectionRatio < 1),
      {threshold: [1]}
    )
    observer.observe(stickyElm)
  },
  methods: {
    roundedNumber,
    nullFilter,
    formatDate,
    formatDateTime,
    isValueOutOfProfile,
    parameterTimeStatus,
    openEquipmentModal(equipment) {
      this.$store.dispatch('baseSideModal/openModal', {
        modalContentType: 'equipment',
        propsData: equipment
      })
    },
    getSortedEquipmentParameters(parameters) {
      return this.parameterNames.map(parameterName => {
        const parameter = parameters.find(parameter => parameter.shortName === parameterName);
        if (parameter) return {...parameter}
        else return {
          shortName: parameterName
        }
      })
    },
    getNetworkEquipmentDangerStatus(parameters) {
      return parameters.some(parameter => {
        return this.isValueOutOfProfile(parameter.parameterValue?.value, this.parameterProfile(parameter.id))
      });
    }
  }
}
</script>

<style scoped>

</style>
