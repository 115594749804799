var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-table__row-collapse",on:{"click":function($event){return _vm.toggleCollapse()}}},[_c('div',{staticClass:"base-table__row-collapse-content base-table__row--resource"},[_c('div',{staticClass:"base-table__column"},[_c('div',{staticClass:"base-table__collapse"},[(_vm.isCollapsed)?_c('icon-plus2'):_c('icon-minus')],1)]),_c('div',{staticClass:"base-table__column"},[_c('div',{staticClass:"base-table__text"},[_vm._v(_vm._s(_vm.resource.name))])]),_c('div',{staticClass:"base-table__column"},[_c('div',{staticClass:"base-table__text"},[_c('resource-load-indicator',{attrs:{"resource-tasks-list":_vm.getResourceTasks}})],1)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCollapsed),expression:"!isCollapsed"}],staticClass:"resource-collapse"},_vm._l((_vm.getResourceTasks),function(task){return _c('div',{key:task.id,staticClass:"base-table__row-collapse-item base-table__row--resource-task"},[_c('div',{staticClass:"base-table__column"},[_c('div',{staticClass:"base-table__num"},[_vm._v(_vm._s(task.id))])]),_c('div',{staticClass:"base-table__column"},[_c('div',{staticClass:"base-table__text"},[_vm._v(_vm._s(task.type_label))])]),_c('div',{staticClass:"base-table__column"},[_c('div',{staticClass:"base-table__text"},[_vm._v(_vm._s(task.plannedDatesRangeCaption))])]),_c('div',{staticClass:"base-table__column"},[_c('div',{staticClass:"base-table__text"},[_vm._v(_vm._s(task.issue.getFullAddress()))])]),_c('div',{staticClass:"base-table__column base-table__column--state"},[_c('div',{staticClass:"base-table__tag",class:[
               {'base-table__tag--new': task.state.id === _vm.TaskState.NEW},
               {'base-table__tag--work': task.state.id === _vm.TaskState.IN_WORK},
               {'base-table__tag--done': task.state.id === _vm.TaskState.CLOSED},
               {'base-table__tag--canceled': task.state.id === _vm.TaskState.CANCELED},
               {'base-table__tag--suspended': task.state.id === _vm.TaskState.SUSPENDED},
               {'base-table__tag--planned': task.state.id === _vm.TaskState.ON_THE_WAY}
             ]},[_vm._v(" "+_vm._s(task.state.label)+" ")])])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }