<script>
export default {
  name: "IconRouting"
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none">
    <path
      opacity="0.4"
      d="M10.94 18C14.806 18 17.94 14.866 17.94 11C17.94 7.13401 14.806 4 10.94 4C7.07401 4 3.94 7.13401 3.94
          11C3.94 14.866 7.07401 18 10.94 18Z"
      fill="#B9BAD5"/>
    <path
      opacity="0.4"
      d="M34 30H40C42.2 30 44 31.8 44 34V40C44 42.2 42.2 44 40 44H34C31.8 44 30 42.2 30 40V34C30 31.8 31.8 30
          34 30Z"
      fill="#B9BAD5"/>
    <path
      d="M24 39.5H18.64C16.32 39.5 14.3 38.1 13.5 35.94C12.68 33.78 13.28 31.4 15.02 29.86L31 15.88C31.96 15.04 31.98
       13.9 31.7 13.12C31.4 12.34 30.64 11.5 29.36 11.5H24C23.18 11.5 22.5 10.82 22.5 10C22.5 9.18 23.18 8.5 24
       8.5H29.36C31.68 8.5 33.7 9.9 34.5 12.06C35.32 14.22 34.72 16.6 32.98 18.14L17 32.12C16.04 32.96 16.02 34.1 16.3
        34.88C16.6 35.66 17.36 36.5 18.64 36.5H24C24.82 36.5 25.5 37.18 25.5 38C25.5 38.82 24.82 39.5 24 39.5Z"
      fill="#9192AD"/>
    <path
      d="M37.02 39C35.92 39 35.02 38.1 35.02 37C35.02 35.9 35.9 35 37.02 35H37.04C38.14 35 39.04 35.9 39.04 37C39.04
       38.1 38.14 39 37.02 39Z"
      fill="#9192AD"/>
    <path
      d="M11.02 13C9.92002 13 9.02002 12.1 9.02002 11C9.02002 9.9 9.90002 9 11.02 9H11.04C12.14 9 13.04 9.9 13.04
      11C13.04 12.1 12.14 13 11.02 13Z"
      fill="#9192AD"/>
  </svg>
</template>

<style scoped>

</style>
