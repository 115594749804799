<template>
  <modal-card
    :is-active="isModalActive"
    @closeModal="closeModal"
  >
    <div class="i-modal-header i-modal-header__create-car n-container">
      <div v-if="Object.keys(networkElement).length && !elementLoading"
           class="element-modal__header">
        <div class="element-modal__name">
          <span>{{ trimmedTypeName }}</span>
          #{{ networkElement.id }}
        </div>
        <div v-if="isVisibleModeSwitcher">
          <div
            class="i-select is-selected"
            :class="{'is-active': isChangeModeSelectVisible}"
            @click.stop="isChangeModeSelectVisible = !isChangeModeSelectVisible"
          >
            <input class="i-select__input"
                   hidden="hidden"
                   type="text"
                   name="networkElementMode">
            <div class="i-select__main"
                 tabindex="0"
                 role="combobox"
                 aria-expanded="false">
              <span class="i-select__selected"
                    data-placeholder="Выберите из списка">
                {{ networkElement.currentMode.name }}
              </span>
            </div>
            <div class="i-select__arrow">
              <icon-arrow/>
            </div>
            <div class="i-select__drop is-default-hidden">
              <div class="i-select__drop-inner">
                <ul class="i-select__list"
                    role="listbox">
                  <li
                    v-for="mode in networkElement.availableModes"
                    :key="mode.id"
                    class="i-select__item"
                    :class="{'is-active': mode.id === networkElement.currentMode.id}"
                    :data-value="mode.name"
                    role="option"
                    @click="changeElementMode(networkElement, mode.id, mode.name)"
                  >
                    <div class='list-item'>
                      <span class='list-item__txt'>{{ mode.name }}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="Object.keys(networkElement).length && !elementLoading"
         class="i-modal-data n-container">
      <div class="tabs">
        <div class="tabs__header">
          <div class="tabs__header-item"
               :class="{'active': activeTab === 1}"
               @click="activeTab = 1">
            Характеристики
          </div>
          <div v-if="relatedIssues.length"
               class="tabs__header-item"
               :class="{'active': activeTab === 2}"
               @click="activeTab = 2">
            Связанные заявки
          </div>
        </div>
        <div class="tabs__item"
             :class="{'active': activeTab === 1}">
          <div class="element-parameters">
            <div class="element-parameters__item">
              <div class="element-parameters__title">
                <icon-pin/>
                <span>Локация</span>
              </div>
              <div class="i-modal-location"
                   :class="{'is-open': isOpenMapCard}">
                <div class="i-modal-location__map">

                  <base-map
                    id-key="-element-modal"
                    :center="processCoordinates"
                    :zoom="17"
                    :base-layers="baseLayers"
                    :markers="createMarker()"
                    :need-resize="isOpenMapCard"
                  />

                </div>
                <button class="button button--mode-icon map-resize-btn"
                        @click="isOpenMapCard = !isOpenMapCard">
                  <icon-arrow-double-up/>
                </button>
              </div>
            </div>

            <div class="element-parameters__item">
              <div class="element-parameters__title">
                <icon-tasks/>
                <span>Характеристики</span>
              </div>
              <div class="i-modal-data__item">
                <div class="element-modal__param-list">
                  <div v-for="param in networkElement.params"
                       :key="param.id"
                       class="element-modal__param">
                    <div class="element-modal__param-name">{{ param.title }}</div>
                    <div class="element-modal__param-value">{{ nullFilter(param.value) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="relatedIssues.length"
             class="tabs__item"
             :class="{'active': activeTab === 2}">
          <div class="element-parameters">
            <div v-for="issue in relatedIssues"
                 :key="issue.id"
                 class="base-table__row base-table__row--network-element">
              <div class="base-table__column base-table__column--number">
                <div class="base-table__num">{{ issue.id }}</div>
              </div>
              <div class="base-table__column">
                <div class="base-table__text base-table__category">
                  <span class="category-group-indicator"
                        :style="{'background-color': issue.category.group_color}"/>
                  {{ issue.category.name }}

                  <div class="base-table__category-tooltip">
                    {{ issue.category.group_name }}
                  </div>
                </div>
              </div>
              <div class="base-table__column">
                <div class="base-table__text">{{ formatDateTime(issue.created_at) }}</div>
              </div>
              <div class="base-table__column">
                <div class="base-table__tag"
                     :class="[
                       {'base-table__tag--new': issue.state.id === IssueState.NEW},
                       {'base-table__tag--work': issue.state.id === IssueState.IN_WORK},
                       {'base-table__tag--done': issue.state.id === IssueState.CLOSED},
                       {'base-table__tag--canceled': issue.state.id === IssueState.CANCELED}
                     ]">
                  {{ issue.state.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="elementLoading"
         class="element-modal__placeholder">
      <base-content-loaders-placeholder/>
    </div>
    <template v-slot:stickyBottom>
      <div class="task-modal__mobile-actions">
        <button @click="closeModal"
                class="button button--mode-outline task-modal__mobile-back">
          <icon-arrow-left/>
        </button>
      </div>
    </template>
  </modal-card>
</template>

<script>
import IconArrow from "@/components/Icons/IconArrow.vue";
import ModalCard from "@/components/Modals/ModalCard.vue";
import BaseContentLoadersPlaceholder from "@/components/BaseContentLoadersPlaceholder.vue";
import {mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";
import IconTasks from "@/components/Icons/IconTasks.vue";
import IconPin from "@/components/Icons/IconPin.vue";
import BaseMap from "@/components/BaseMap.vue";
import IconArrowDoubleUp from "@/components/Icons/IconArrowDoubleUp.vue";
import Marker from "@/models/baseMap/Marker";
import Icon from "@/models/baseMap/Icon";
import IssueState from "@/models/serviceDesk/IssueState";
import IconArrowLeft from "@/components/Icons/IconArrowLeft.vue";
import {formatDateTime, nullFilter} from "@/utils/filters";

export default {
  name: "NetworkElementModal",
  components: {
    IconArrowLeft,
    IconArrowDoubleUp, BaseMap, IconPin, IconTasks, BaseContentLoadersPlaceholder, ModalCard, IconArrow
},
  props: {
    isModalActive: {
      type: Boolean,
      required: true
    },
    networkElement: {
      type: Object,
      required: true
    },
    isVisibleModeSwitcher: {
      type: Boolean,
      default: true
    },
    relatedIssues: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isChangeModeSelectVisible: false,
      activeTab: 1,
      isOpenMapCard: false
    }
  },
  computed: {
    ...mapState({
      elementLoading: state => state.networkElements.elementLoading,
      baseLayers: state => state.baseLayers.baseLayers
    }),
    trimmedTypeName() {
      const length = 10;
      return this.networkElement.type.name.length > length ?
        this.networkElement.type.name.substring(0, length - 3) + "..." :
        this.networkElement.type.name;
    },
    processCoordinates() {
      if (this.networkElement.coordinates.some(item => Array.isArray(item))) {
        let [coords] = this.networkElement.coordinates;
        return coords;
      } else return this.networkElement.coordinates;
    },
    IssueState: () => IssueState
  },
  methods: {
    nullFilter,
    formatDateTime,
    changeElementMode(element, modeId, modeName) {
      if (element.currentMode.id !== modeId) {
        this.$store.dispatch('networkElements/changeNetworkElementMode', {
          element_id: element.id,
          source_id: element.semanticSource.id,
          mode_id: modeId
        })
          .then(() => {
            this.$store.commit("networkElements/CHANGE_ELEMENT_MODE", {
              elementId: element.id,
              modeId: modeId,
              modeName: modeName
            })
            showToast('Режим элемента изменён', 'success');
            this.currentNetworkElementId = null;
          })
          .catch(error => showAlert('Ошибка', error))
      }
    },
    createMarker() {
      return [new Marker({
        coords: this.processCoordinates,
        icon: new Icon({
          type: 'icon',
          url: require('@/assets/img/icons/pin-low.svg'),
          size: [36, 36],
          anchor: [18, 36]
        })
      })]
    },
    closeModal() {
      this.$emit('close-modal')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.element-parameters {
  display: flex;
  flex-direction: column;
  gap: 32px;

  &__item {
    display: flex;
    flex-direction: column;
    gap: 12px;

    & .i-modal-location {
      margin-top: 0;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 8px;

    & span {
      @include font-style(400, 16px, 24px, var(--text-secondary));
    }
  }
}

.element-modal {
  &__header {
    display: flex;
    gap: 32px;
    align-items: center;
    justify-content: space-between;
    min-width: 550px;
  }

  &__id {
    padding: 4px 8px;

    border-radius: 8px;
    background: var(--panel-bg);

    @include font-style(400, 16px, 24px, var(--text-primary));
  }

  &__name {
    @include font-style(500, 36px, 48px, var(--text-primary));
  }

  &__param-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__mode, &__param {
    display: grid;
    grid-template-columns: 1fr 35%;
    gap: 24px;
  }

  &__param-name {
    @include font-style(400, 16px, 24px, var(--text-secondary));
  }

  &__param-value {
    @include font-style(400, 16px, 24px, var(--text-primary));
  }

  &__placeholder {
    padding: 64px;
    width: 678px;
  }

}

.base-table__row--network-element {
  grid-template-columns: 7.23% 26.55% 14.75% 19.47% 1fr;
}
</style>
