<template>
  <div class="page-wrapper">
    <base-panel
      title="Виды технического обслуживания"
      :is-add-button-visible="true">
      <template #buttons>
        <button
          @click="createTechnicalServiceWork()"
          class="button button--mode-primary">
          <icon-plus/>
          <span>Добавить</span>
        </button>
      </template>
    </base-panel>

    <technical-service-works-list
      v-if="technicalServiceWorksList.length"
      :technical-service-works-list="technicalServiceWorksList"/>

    <base-content-loaders v-else-if="!technicalServiceWorksList.length && loading"/>

    <div class="no-result-message"
         v-else>
      Виды работ технического обслуживания не найдены
    </div>
  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel";
import IconPlus from "@/components/Icons/IconPlus";
import TechnicalServiceWorksList from "@/components/Telemetry/admin/TechnicalService/TechnicalServiceWorksList";
import {mapState} from "vuex";
import BaseContentLoaders from "@/components/BaseContentLoaders";
import {modalAdaptiveWidth} from "@/utils/helpers";
import TechnicalServiceWorksModal from "@/components/Telemetry/admin/TechnicalService/TechnicalServiceWorksModal";

export default {
  name: "TechnicalServiceWorksPage",
  components: {BaseContentLoaders, TechnicalServiceWorksList, IconPlus, BasePanel},
  computed: {
    ...mapState({
      technicalServiceWorksList: state => state.technicalServiceWorks.technicalServiceWorksList,
      loading: state => state.technicalServiceWorks.loading
    })
  },
  mounted() {
    this.$store.dispatch('technicalServiceWorks/getTechnicalServiceWorks');
  },
  methods: {
    createTechnicalServiceWork() {
      this.$modal.show(
        TechnicalServiceWorksModal,
        {
          'technical-service-work': null
        },
        {
          name: 'technical-service-work-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(600),
          scrollable: true
        }
      )
    }
  }
}
</script>

<style scoped>
.no-result-message {
  font-size: 24px;
  text-align: center;
}
</style>
