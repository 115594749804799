<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="20"
       height="20"
       viewBox="0 0 20 20"
       fill="none">
    <path
      d="M10.1076 10.6997C10.0359 10.6907 9.96331 10.6907 9.89159 10.6997C9.12835 10.674 8.40497 10.3526 7.87429
       9.80347C7.3436 9.25431 7.04716 8.52037 7.04759 7.75669C7.04759 6.12769 8.36159 4.80469 9.99959 4.80469C10.7731
        4.80335 11.5161 5.10602 12.0685 5.64745C12.6209 6.18889 12.9384 6.92571 12.9526 7.69908C12.9668 8.47245
        12.6765 9.22041 12.1443 9.78171C11.6121 10.343 10.8806 10.6727 10.1076 10.6997ZM16.0656 16.6397C14.4116
        18.1599 12.246 19.0017 9.99959 18.9977C7.65959 18.9977 5.53559 18.1067 3.93359 16.6397C4.02359 15.7937
        4.56359 14.9657 5.52659 14.3177C7.99259 12.6797 12.0246 12.6797 14.4726 14.3177C15.4356 14.9657 15.9756
        15.7937 16.0656 16.6397Z"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M10 19C14.9707 19 19 14.9707 19 10C19 5.0293 14.9707 1 10 1C5.0293 1 1 5.0293 1 10C1 14.9707 5.0293 19 10 19Z"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconProfileCircle"
}
</script>

<style scoped>

</style>
