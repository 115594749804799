<template>
  <div class="transport-speed">
    <icon-speed-success v-if="speedStatus === 'success'" />
    <icon-speed-warning v-else-if="speedStatus === 'warning'" />
    <icon-speed-danger v-else-if="speedStatus === 'danger'" />
    <icon-speed-success v-else />
    <div
      class="transport-speed__value"
      :class="{'transport-speed__value--white': whiteSpeed}">
      {{transportSpeed}}
      <span>км/ч</span>
    </div>
  </div>
</template>

<script>

import IconSpeedSuccess from "@/components/Icons/IconSpeedSuccess.vue";
import IconSpeedWarning from "@/components/Icons/IconSpeedWarning.vue";
import IconSpeedDanger from "@/components/Icons/IconSpeedDanger.vue";
import store from "@/store";

export default {
  name: "TransportSpeed",
  components: {IconSpeedDanger, IconSpeedWarning, IconSpeedSuccess},
  props: {
    id: {
      type: Number,
      required: true
    },
    transportSpeed: {
      type: Number,
      required: true
    },
    whiteSpeed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    speedStatus() {
      return store.getters["transportData/getVehicleSpeedStatus"](this.id);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.transport-speed {
  display: flex;
  align-items: flex-end;
  gap: 4px;

  &__value {
    display: flex;
    gap: 2px;

    @include font-style(400, 12px, 14px, var(--text-primary));

    &--white {
      color: var(--text-panel);
    }

    & span {
      @include font-style(400, 10px, 12px, var(--text-secondary));
    }
  }
}
</style>
