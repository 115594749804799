import BillingService from "@/services/BillingService";
import {getError} from "@/utils/helpers";

export const namespaced = true;

function setPaginatedClients(commit, response) {
    response.data.data.forEach(el => {
        el.attributes.geometry.coordinates = el.attributes.geometry.coordinates.reverse()
    })
    commit("SET_CLIENTS", response.data.data);
    commit("SET_META", response.data.meta);
    commit("SET_LINKS", response.data.links);
    commit("SET_LOADING", false);
}

export const state = {
    clients: [],
    client: null,
    meta: null,
    links: null,
    loading: false,
    client_loading: false,
    error: null,
    filterParams: null,
    page: 1,
    tabIndex: 0
}

export const mutations = {
    SET_CLIENTS(state, clients) {
        state.clients = clients;
    },
    SET_CLIENT(state, clietn) {
        state.client = clietn;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_CLIENT_LOADING(state, loading) {
        state.client_loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_FILTER_PARAMS(state, filterParams) {
        state.filterParams = filterParams;
    },
    SET_PAGE(state, page) {
        state.page = page;
    },
    SET_TAB_INDEX(state, index) {
        state.tabIndex = index;
    }
}

export const actions = {
    getClients({commit, state}) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            BillingService.getClients(state.page, state.filterParams)
                .then(response => {
                    setPaginatedClients(commit, response);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", getError(error))
                    reject();
                })
                .finally(() => commit("SET_LOADING", false))
        })
    },
    getClient({commit}, id) {
        commit("SET_CLIENT_LOADING", true);
        return new Promise((resolve, reject) => {
            BillingService.getClientDetail(id)
                .then(response => {
                    commit("SET_CLIENT", response.data.data);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", getError(error));
                    reject();
                })
                .finally(() => commit("SET_CLIENT_LOADING", false));
        })
    }
}

export const getters = {
    getClient: state => id => {
        return state.clients.find(client => client.id === id);
    },
    getClients: state => {
        return state.clients;
    },
    getStoreStatus: state => {
        return state.clients.length !== 0;
    },
    getClientType: state => {
        switch (state.client.attributes.type) {
            case 1:
                return 'Физическое лицо'
            case 2:
                return 'Юридическое лицо'
        }
    },
    getClientName: state => {
        return state.client.attributes.name;
    },
    getClientAddress: state => {
        return state.client.attributes.address;
    },
    getClientObjectName: state => {
        return state.client.attributes.object_name;
    },
    getClientLS: state => {
        return state.client.attributes.ls;
    },
    getClientLsStatus: state => {
        switch (state.client.attributes.ls_status) {
            case 1:
                return 'Открыт'
            case 2:
                return 'Закрыт'
        }
    },
    getClientReadings: state => {
        return state.client.attributes.readings;
    },
    getClientMeters: state => {
        return state.client.attributes.meters;
    },
    getClientBalance: state => {
        return state.client.attributes.saldo.toFixed(2);
    },
    getClientLoading: state => {
        return state.client_loading;
    },
    getTabIndex: state => {
        return state.tabIndex;
    }
}