<template>
  <base-popup
    :title="`${parameter.name} - параметры профиля`"
    :name="popupName"
  >
    <template #body>
      <table class="base-modal-table">
        <thead>
          <tr>
            <th class="base-modal-table__header-cell base-modal-table__header-cell--center">Время</th>
            <th class="base-modal-table__header-cell">Мин</th>
            <th class="base-modal-table__header-cell">Базовое</th>
            <th class="base-modal-table__header-cell">Макс</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="profileItem in parameter.profile"
            :key="profileItem.id"
          >
            <td
              class="base-modal-table__body-cell base-modal-table__body-cell--center
            base-modal-table__body-cell--bg-header"
              :class="{'base-modal-table__body-cell--fade': isPercentFieldActive &&
                activeProfileHour !== profileItem.hour}"
            >
              {{ profileItem.hour < 10 ? `0${profileItem.hour}:00` : `${profileItem.hour}:00` }}
            </td>
            <td
              class="base-modal-table__body-cell base-modal-table__body-cell--p-0"
              :class="{'base-modal-table__body-cell--fade': isPercentFieldActive
                && activeProfileHour !== profileItem.hour}"
            >
              <equipment-profile-input v-model="profileItem.min"/>
            </td>
            <td
              class="base-modal-table__body-cell base-modal-table__body-cell--p-0"
              :class="[
                {'base-modal-table__body-cell--active': activeProfileHour === profileItem.hour},
                {'base-modal-table__body-cell--fade': isPercentFieldActive && activeProfileHour !== profileItem.hour}
              ]"
            >
              <equipment-profile-input
                v-model="profileItem.base"
                :is-base-profile="true"
                :profile-hour="profileItem.hour"
                @update-min-max="updateProfileMinMax"
                @copy-values-for-next-line="copyValuesForNextLines"
                @percents-field-active="processActivePercentField"
              />
            </td>
            <td
              class="base-modal-table__body-cell base-modal-table__body-cell--p-0"
              :class="{'base-modal-table__body-cell--fade': isPercentFieldActive
                && activeProfileHour !== profileItem.hour}"
            >
              <equipment-profile-input v-model="profileItem.max"/>
            </td>
          </tr>
        </tbody>
      </table>
    </template>

    <template #footer>
      <button
        @click="updateProfile()"
        class="button button--mode-primary">Сохранить</button>
    </template>
  </base-popup>
</template>

<script>
import BasePopup from "@/components/BasePopup.vue";
import EquipmentProfileInput from "@/components/Telemetry/admin/NetworkObject/EquipmentProfileInput.vue";
import {showToast, showAlert} from "@/utils/notification";
import {mapState} from "vuex";

export default {
  name: "EquipmentParameterProfile",
  components: {EquipmentProfileInput, BasePopup},
  props: {
    popupName: {
      type: String,
      required: true
    },
    parameter: {
      type: Object,
      required: true
    },
    equipmentId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isPercentFieldActive: false,
      activeProfileHour: 0
    }
  },
  computed: {
    ...mapState({
      networkObject: state => state.networkObjectPage.networkObject
    })
  },
  methods: {
    updateProfile() {
      this.$store.dispatch('networkEquipmentParameters/updateParameterProfile', {
        equipmentId: this.equipmentId,
        parameterId: this.parameter.id,
        payload: {
          profiles: this.parameter.profile
        }
      })
        .then(() => {
          showToast('Профиль параметра обновлен', 'success');
          this.$modal.hide(this.popupName);
          this.$store.dispatch('networkObjectPage/getNetworkObjectEquipments', this.networkObject.id)
            .then(() => this.$store.dispatch('networkObjectPage/getEquipment', this.equipmentId))
        })
        .catch(error => showAlert('Ошибка', error))
    },
    updateProfileMinMax({percent, hour}) {
      const index = this.parameter.profile.findIndex(profileItem => profileItem.hour === hour);
      let {base} = this.parameter.profile[index];
      const intBase = parseInt(base);
      this.parameter.profile[index].min = intBase - (intBase / 100 * percent);
      this.parameter.profile[index].max = intBase + (intBase / 100 * percent);
    },
    copyValuesForNextLines(hour) {
      const profileItem = this.parameter.profile.find(profileItem => profileItem.hour === hour);
      const {min, max, base} = profileItem;

      this.parameter.profile.forEach((profileItem, index) => {
        if (hour < profileItem.hour) {
          this.parameter.profile[index].min = min;
          this.parameter.profile[index].max = max;
          this.parameter.profile[index].base = base;
        }
      })
    },
    processActivePercentField(isActive, profileHour) {
      this.isPercentFieldActive = isActive;
      this.activeProfileHour = profileHour;
    }
  }
}
</script>

<style scoped>

</style>