import DictionariesService from "@/services/DictionariesService";

export const namespaced = true;

export const state = {
    dictionariesList: [],
    dictionary: null,
    error: null,
    loading: false,
    dictionaryLoading: false,
    dictionariesQuery: '',
    dictionaryItemsQuery: ''
}

export const mutations = {
    SET_DICTIONARIES(state, dictionaries) {
        state.dictionariesList = dictionaries;
    },
    SET_DICTIONARY(state, dictionary) {
        state.dictionary = dictionary;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_DICTIONARY_LOADING(state, loading) {
        state.dictionaryLoading = loading;
    },
    SET_DICTIONARIES_QUERY(state, query) {
        state.dictionariesQuery = query;
    },
    SET_DICTIONARY_ITEMS_QUERY(state, query) {
        state.dictionaryItemsQuery = query;
    }
}

export const actions = {
    getDictionariesList({commit}) {
        commit("SET_LOADING", true);
        DictionariesService.fetchDictionariesList()
            .then(dictionariesList => commit("SET_DICTIONARIES", dictionariesList))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false))
    },
    getDictionary({commit}, id) {
        commit("SET_DICTIONARY_LOADING", true);
        DictionariesService.fetchDictionary(id)
            .then(dictionary => commit("SET_DICTIONARY", dictionary))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_DICTIONARY_LOADING", false))
    },
    createDictionary(context, payload) {
        return new Promise((resolve, reject) => {
            DictionariesService.createDictionary(payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    createDictionaryItem(context, {id, payload}) {
        return new Promise((resolve, reject) => {
            DictionariesService.createDictionaryItem(id, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    updateDictionary(context, {id, payload}) {
        return new Promise((resolve, reject) => {
            DictionariesService.updateDictionary(id, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    updateDictionaryItem(context, {id, itemId, payload}) {
        return new Promise((resolve, reject) => {
            DictionariesService.updateDictionaryItem(id, itemId, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    deleteDictionary(context, id) {
        return new Promise((resolve, reject) => {
            DictionariesService.deleteDictionary(id)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    deleteDictionaryItem(context, {id, itemId}) {
        return new Promise((resolve, reject) => {
            DictionariesService.deleteDictionaryItem(id, itemId)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    }
}

export const getters = {
    getDictionariesByQuery: state => {
        return state.dictionariesQuery.length ? state.dictionariesList
                .filter(dictionary => dictionary.name.toLowerCase().includes(state.dictionariesQuery.toLowerCase())) :
            state.dictionariesList;
    },
    getDictionary: state => id => {
        const dictionary = state.dictionariesList.find(dictionary => dictionary.id === id);
        return dictionary ? dictionary : null;
    },
    getDictionaryItemsByQuery: state => {
        return state.dictionaryItemsQuery.length ? state.dictionary.items
            .filter(item => item.name.toLowerCase().includes(state.dictionaryItemsQuery.toLowerCase())) :
            state.dictionary.items
    }
}