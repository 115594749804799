<template>
  <div class="transport-filter__wrapper">
    <div class="filter__button transport-filter__button"
         :class="{'opened': isFilterModalVisible}"
         @click="isFilterModalVisible = true"
         data-modal-toggle="filter-modal">
      <icon-settings2/>
      <span>Фильтры</span>
    </div>

    <div class="filter__button transport-filter__button"
         :class="{'opened': isSortingModalVisible}"
         @click="isSortingModalVisible = true"
         data-modal-toggle="sorting-modal">
      <icon-sort2/>
      <span>Сортировка</span>
    </div>

    <base-filter-modal
      :is-visible="isFilterModalVisible"
      @toggle-modal="isVisible => this.isFilterModalVisible = isVisible">
      <div class="filter__modal-header">
        <div class="filter__modal-name">Фильтры</div>
        <div @click="resetFilters()"
             class="filter__modal-reset">Сбросить все
        </div>
      </div>
      <div class="filter__modal-form filter__modal-form--hidden-footer">
        <div class="filter__modal-form-group">
          <div class="filter__modal-form-label">Тип транспорта</div>
          <label class="base-switcher">
            <input
              v-model="vehicleTypes"
              :value="Vehicle.PASSENGER_VEHICLE"
              class="base-switcher__checkbox"
              type="checkbox">
            <div class="base-switcher__switch"/>
            <span class="base-switcher__label">
              <div class="base-switcher__label--icon">
                <icon-car/>
                Легковая
              </div>
            </span>
          </label>

          <label class="base-switcher">
            <input
              v-model="vehicleTypes"
              :value="Vehicle.TRUCK_VEHICLE"
              class="base-switcher__checkbox"
              type="checkbox">
            <div class="base-switcher__switch"/>
            <span class="base-switcher__label">
              <div class="base-switcher__label--icon">
                <icon-truck/>
                Грузовая
              </div>
            </span>
          </label>

          <label class="base-switcher">
            <input
              v-model="vehicleTypes"
              :value="Vehicle.SPECIAL_VEHICLE"
              class="base-switcher__checkbox"
              type="checkbox">
            <div class="base-switcher__switch"/>
            <span class="base-switcher__label">
              <div class="base-switcher__label--icon">
                <icon-special-equipment/>
                Спец. техника
              </div>
            </span>
          </label>

          <label class="base-switcher">
            <input
              v-model="vehicleTypes"
              :value="Vehicle.WATER_CARRIER"
              class="base-switcher__checkbox"
              type="checkbox">
            <div class="base-switcher__switch"/>
            <span class="base-switcher__label">
              <div class="base-switcher__label--icon">
                <icon-water-carrier/>
                Водовозка
              </div>
            </span>
          </label>

        </div>

        <div class="filter__modal-form-group">
          <div class="filter__modal-form-label">Статус ТС</div>
          <label class="base-switcher">
            <input
              v-model="vehicleState"
              :value="VehicleState.ACTIVE"
              class="base-switcher__checkbox"
              type="checkbox">
            <div class="base-switcher__switch"/>
            <span class="base-switcher__label">
              <div class="base-switcher__label--icon">
                <icon-tick-square />
                Доступно
              </div>
            </span>
          </label>

          <label class="base-switcher">
            <input
              v-model="vehicleState"
              :value="VehicleState.IN_REPAIR"
              class="base-switcher__checkbox"
              type="checkbox">
            <div class="base-switcher__switch"/>
            <span class="base-switcher__label">
              <div class="base-switcher__label--icon">
                <icon-wrench/>
                В ремонте
              </div>
            </span>
          </label>
        </div>

        <div class="filter__modal-form-group">
          <div class="filter__modal-form-label">Состояние двигателя</div>
          <label class="base-switcher">
            <input
              v-model="vehicleEngineStatus"
              class="base-switcher__checkbox"
              type="checkbox">
            <div class="base-switcher__switch"/>
            <span class="base-switcher__label">
              <div class="base-switcher__label--icon">
                Запущен
              </div>
            </span>
          </label>

          <label class="base-switcher">
            <input
              v-model="vehicleInMotion"
              class="base-switcher__checkbox"
              type="checkbox">
            <div class="base-switcher__switch"/>
            <span class="base-switcher__label">
              <div class="base-switcher__label--icon">
                В движении
              </div>
            </span>
          </label>
        </div>

      </div>
    </base-filter-modal>

    <base-filter-modal
      :is-visible="isSortingModalVisible"
      @toggle-modal="isVisible => this.isSortingModalVisible = isVisible">
      <div class="filter__modal-header">
        <div class="filter__modal-name">Сортировка</div>
        <div @click="resetSorting()"
             class="filter__modal-reset">По умолчанию
        </div>
      </div>
      <div class="filter__modal-form filter__modal-form--hidden-footer">
        <div class="filter__modal-form-group">
          <div class="filter__modal-form-label">Номер ID</div>
          <label class="custom-control custom-radio">
            <input
              v-model="sorting"
              value="id.asc"
              class="custom-control-input"
              name="custom-radio"
              type="radio">
            <span class="custom-control-label">Сначала первый</span>
          </label>

          <label class="custom-control custom-radio">
            <input
              v-model="sorting"
              value="id.desk"
              class="custom-control-input"
              name="custom-radio"
              type="radio">
            <span class="custom-control-label">Сначала последний</span>
          </label>
        </div>

        <div class="filter__modal-form-group">
          <div class="filter__modal-form-label">Скорость</div>
          <label class="custom-control custom-radio">
            <input
              v-model="sorting"
              value="speed.asc"
              class="custom-control-input"
              name="custom-radio"
              type="radio">
            <span class="custom-control-label">Сначала самый быстрый</span>
          </label>

          <label class="custom-control custom-radio">
            <input
              v-model="sorting"
              value="speed.desk"
              class="custom-control-input"
              name="custom-radio"
              type="radio">
            <span class="custom-control-label">Сначала самый медленный</span>
          </label>

        </div>
      </div>
    </base-filter-modal>

  </div>
</template>

<script>
import IconSettings2 from "@/components/Icons/IconSettings2.vue";
import BaseFilterModal from "@/components/BaseFilterModal.vue";
import IconSort2 from "@/components/Icons/IconSort2.vue";
import IconCar from "@/components/Icons/IconCar.vue";
import IconSpecialEquipment from "@/components/Icons/IconSpecialEquipment.vue";
import IconTruck from "@/components/Icons/IconTruck2.vue";
import IconWaterCarrier from "@/components/Icons/IconWaterCarrier.vue";
import IconTickSquare from "@/components/Icons/IconTickSquare.vue";
import IconWrench from "@/components/Icons/IconWrench.vue";
import Vehicle from "@/models/transport/Vehicle";
import VehicleState from "@/models/transport/VehicleState";

export default {
  name: "TransportTrackingFilter",
  components: {
    IconWrench,
    IconTickSquare,
    IconWaterCarrier,
    IconTruck,
    IconSpecialEquipment,
    IconCar,
    IconSort2,
    BaseFilterModal,
    IconSettings2
  },
  data() {
    return {
      isFilterModalVisible: false,
      isSortingModalVisible: false
    }
  },
  computed: {
    Vehicle: () => Vehicle,
    VehicleState: () => VehicleState,
    vehicleTypes: {
      get() {
        return this.$store.state.autos.vehicleTypes;
      },
      set(value) {
        this.$store.commit('autos/SET_VEHICLE_TYPES', value);
      }
    },
    vehicleState: {
      get() {
        return this.$store.state.autos.vehicleState;
      },
      set(value) {
        this.$store.commit('autos/SET_VEHICLE_STATES', value);
      }
    },
    vehicleEngineStatus: {
      get() {
        return this.$store.state.autos.vehicleEngineStatus;
      },
      set(value) {
        this.$store.commit('autos/SET_VEHICLE_ENGINE_STATUS', value);
      }
    },
    vehicleInMotion: {
      get() {
        return this.$store.state.autos.vehicleInMotion;
      },
      set(value) {
        this.$store.commit('autos/SET_VEHICLE_IN_MOTION', value);
      }
    },
    sorting: {
      get() {
        return this.$store.state.autos.sorting;
      },
      set(value) {
        this.$store.commit('autos/SET_SORTING', value);
      }
    }
  },
  watch: {
    isFilterModalVisible: function (value) {
      if (value) this.isSortingModalVisible = false;
    },
    isSortingModalVisible: function (value) {
      if (value) this.isFilterModalVisible = false;
    }
  },
  methods: {
    resetFilters() {
      this.$store.commit('autos/SET_VEHICLE_TYPES', [
        Vehicle.WATER_CARRIER,
        Vehicle.SPECIAL_VEHICLE,
        Vehicle.TRUCK_VEHICLE,
        Vehicle.PASSENGER_VEHICLE
      ]);
      this.$store.commit('autos/SET_VEHICLE_STATES', [
        VehicleState.ACTIVE,
        VehicleState.IN_REPAIR
      ]);
      this.$store.commit('autos/SET_VEHICLE_ENGINE_STATUS', false);
      this.$store.commit('autos/SET_VEHICLE_IN_MOTION', false);
    },
    resetSorting() {
      this.$store.commit('autos/SET_SORTING', 'id.asc');
    }
  }
}
</script>

<style lang="scss" scoped>
.transport-filter {
  &__wrapper {
    display: flex;
    gap: 12px;
    position: relative;
  }
}
</style>
