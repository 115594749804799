<script>
export default {
  name: "IconCourseMarker"
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="26"
    viewBox="0 0 10 13"
    fill="none">
    <path
      d="M5.52918 7.98531L5.38962 7.68917L5.06237 7.69868L1.45804 7.80338L9.1818 1.51331L7.06639 11.2471L5.52918
      7.98531Z"
      fill="#FF3A81"
      stroke="#FF3A81"/>
  </svg>
</template>

<style scoped>

</style>
