import WeatherService from "@/services/WeatherService";

export const namespaced = true;

export const state = {
    currentWeather: null,
    weatherArchive: [],
    error: null
}

export const mutations = {
    SET_CURRENT_WEATHER: (state, currentWeather) => {
        state.currentWeather = currentWeather;
    },
    SET_WEATHER_ARCHIVE(state, archive) {
        state.weatherArchive = archive;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getCurrentWeather({commit}) {
        WeatherService.getCurrentWeather()
            .then(weather => commit("SET_CURRENT_WEATHER", weather))
            .catch(error => commit("SET_ERROR", error))
    },
    getWeatherArchive({commit}, params) {
        WeatherService.getWeatherArchive(params)
            .then(weatherArchive => commit("SET_WEATHER_ARCHIVE", weatherArchive))
            .catch(error => commit("SET_ERROR", error))
    }
}