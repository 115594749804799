<template>
  <div class="page-wrapper">
    <base-panel
      title="Автопарк"
      add-button-name="Добавить транспорт"
      @add-button-clicked="openVehicleCreateModal()"
    />

    <transport-list
      v-if="transportList.length"
      :transport-list="transportList"/>

    <div v-else-if="loading">
      <base-content-loaders/>
    </div>

    <base-no-data
      v-else
      title="Транспорт не найден"/>

    <base-paginate
      v-if="meta && meta.last_page > 1"
      action="autos/getAutos"
      commit="autos/SET_PAGE"
      :meta="meta" />
  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel.vue";
import TransportList from "@/components/Transport/admin/TransportList.vue";
import {mapState} from "vuex";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import BasePaginate from "@/components/BasePaginate.vue";

export default {
  name: "TransportCatalogPage",
  components: {BasePaginate, BaseNoData, BaseContentLoaders, TransportList, BasePanel},
  computed: {
    ...mapState({
      transportList: state => state.autos.autos,
      meta: state => state.autos.meta,
      loading: state => state.autos.loading
    })
  },
  mounted() {
    this.$store.commit('autos/SET_PAGE', 1);
    this.$store.dispatch('autos/getAutos');
  },
  beforeDestroy() {
    this.$store.dispatch('baseSideModal/closeModal');
  },
  methods: {
    openVehicleCreateModal() {
      this.$store.dispatch('baseSideModal/openModal', {
        modalContentType: 'vehicleForm',
        entityId: null,
        propsData: {
          vehicle: true
        }
      });
    }
  }
}
</script>

<style scoped>

</style>