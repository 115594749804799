<template>
  <img
    :src="url"
    :alt="name"
    class="files-list__item-img">
</template>

<script>
export default {
  name: "PhotoPreview",
  props: {
    name: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  }
}
</script>

<style scoped>

</style>