<template>
  <div
    class="map-marker emergency-element-marker">
    <map-icon-bg/>
    <div class="emergency-element-marker__incidents-count">{{emergencyElement.countIncidents}}</div>
    <div class="map-marker__inner">
      <div
        class="map-marker__img map-marker__number"
        :class="getBackgroundClass">
        <icon-flash-filled />
      </div>
    </div>
  </div>
</template>

<script>
import MapIconBg from "@/components/Icons/MapIconBg.vue";
import store from "@/store";
import moment from "moment";
import IconFlashFilled from "@/components/Icons/IconFlashFilled.vue";
export default {
  name: "EmergencyElementsReportMarker",
  components: {IconFlashFilled, MapIconBg},
  props: {
    emergencyElement: {
      type: Object
    }
  },
  computed: {
    getBackgroundClass() {
      const startDate = moment(store.state.reportsData.reportParams.period_start);
      const endDate = moment(store.state.reportsData.reportParams.period_end);
      const diff = endDate.diff(startDate, 'days');

      if (diff <= 30) return 'map-marker__img--bg-work'
      else {
        if (this.emergencyElement.countIncidents === 1) return 'map-marker__img--bg-done'
        else if (this.emergencyElement.countIncidents <= 3) return 'map-marker__img--bg-suspended'
        else return 'map-marker__img--bg-danger'
      }
    }
  }
}
</script>

<style lang="scss">
.emergency-element-marker {
  &__incidents-count {
    position: absolute;
    right: -6px;
    top: -8px;
    height: 20px;
    width: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: var(--status-new);
    border-radius: 50%;
    border: 2px solid var(--border-validation);
    z-index: 1;

    color: var(--text-panel);
    font-size: 12px;
  }

  & .map-marker__img svg path {
    fill: var(--ico-panel);
  }
}
</style>
