<template>
  <div class="questions-list">
    <div
      v-for="(question, index) in questionsList"
      :key="question.id"
      class="question">

      <div class="question__wrapper">
        <div class="question__number">{{index + 1}}</div>
        <div class="question__text">{{question.question}}</div>
      </div>

      <div class="question__answer">
        <answer-dictionary
          v-if="isAnswerTypeDictionary(question.answerType.id)"
          :answer="answerByQuestionId(question.id)"
          :dictionary-items="question.dictionary ? question.dictionary.attributes.items : []"
        />

        <answer-text
          v-else
          :answer="answerByQuestionId(question.id)"
        />
      </div>

    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import AnswerText from "@/components/TechnicalInspection/Tasks/AnswerText.vue";
import AnswerDictionary from "@/components/TechnicalInspection/Tasks/AnswerDictionary.vue";
import Question from "@/models/TechnicalInspection/Question";

export default {
  name: "QuestionsList",
  components: {AnswerDictionary, AnswerText},
  props: {
    questionsList: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      answerByQuestionId: 'technicalInspectionTasks/getAnswerByQuestionId'
    })
  },
  methods: {
    isAnswerTypeDictionary(answerType) {
      return answerType === Question.ANSWER_DICTIONARY.id;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.questions-list {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.question {
  display: flex;

  padding: 16px;
  border: 1px solid var(--border-main);
  background-color: var(--bg-main);
  border-radius: 16px;

  &__wrapper {
    flex: 5;
    display: flex;
    align-items: flex-start;
    gap: 16px;

    padding-right: 16px;
  }

  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    width: 22px;
    height: 22px;

    background-color: var(--panel-bg);
    border-radius: 50%;
  }

  &__text {
    @include font-style(400, 16px, 24px, var(--text-primary));
  }

  &__answer {
    flex: 2;

    padding-left: 32px;
    border-left: 1px solid var(--border-main);
  }
}
</style>