<template>
  <div class="resource-popup">
    <div class="resource-popup__name">
      <span>{{ resource.name }}</span>
    </div>
    <div class="resource-popup__datetime">
      <span>{{formatDateTime(resource.lastLocationDate)}}</span>
    </div>
  </div>
</template>

<script>
import {formatDateTime} from "@/utils/filters";

export default {
  name: "ResourcePopup",
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  methods: {formatDateTime}
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.resource-popup {
  display: flex;
  flex-direction: column;
  gap: 4px;

  padding: 4px;
  border-radius: 4px;
  border: 1px solid var(--text-dark-grey);
  background: var(--bg-sidebar);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.20);

  &__name {
    @include font-style(400, 10px, 12px, var(--text-panel));
  }

  &__datetime {
    @include font-style(600, 10px, 12px, var(--text-tertiary));
  }
}
</style>