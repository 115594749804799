<template>
  <div class="functional-line">
    <div class="functional-line__inner">
      <div class="search">
        <input type="text"
               name="search"
               :placeholder="searchPlaceholder"
               @input="$emit('search-change', $event)">
        <icon-search class="search__icon" />
        <div class="search__clear" />
        <span class="search__ruller"/>
      </div>
      <div class="functional-line__btns-wrapper">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import IconSearch from "@/components/Icons/IconSearch";
export default {
  name: "BaseFunctionalLine",
  components: {IconSearch},
  props: {
    searchPlaceholder: {
      type: String,
      default: 'Найти'
    }
  }
}
</script>

<style scoped>

</style>
