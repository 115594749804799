import * as API from './API';
import SemanticProvider from "@/models/EngineeringNetworks/SemanticProvider";
import {getError} from "@/utils/helpers";
import SemanticProviderType from "@/models/EngineeringNetworks/SemanticProviderType";

export default {
    fetchSemanticProvidersList() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/network-engineering/semantic-providers`)
                .then(response => resolve(response.data.data
                    .map(item => new SemanticProvider({
                        id: item.id,
                        ...item.attributes
                    }))))
                .catch(error => reject(getError(error)))
        })
    },
    fetchSemanticProvider(semanticProviderId) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/network-engineering/semantic-providers/${semanticProviderId}`)
                .then(response => {
                    const {id, attributes} = response.data.data;
                    resolve(new SemanticProvider({id, ...attributes}))
                })
                .catch(error => reject(getError(error)))
        })
    },
    fetchSemanticProviderTypesList() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/network-engineering/semantic-providers/types`)
                .then(response => resolve(response.data.data
                    .map(item => new SemanticProviderType({
                        id: item.id,
                        ...item.attributes
                    }))))
                .catch(error => reject(getError(error)))
        })
    },
    createSemanticProvider(payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`/network-engineering/semantic-providers`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateSemanticProvider(providerId, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/network-engineering/semantic-providers/${providerId}`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteSemanticProvider(providerId) {
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`/network-engineering/semantic-providers/${providerId}`)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}