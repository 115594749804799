<script>
export default {
  name: "IconActivity"
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none">
    <path
      d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M7.33008 14.49L9.71008 11.4C10.0501 10.96 10.6801 10.88 11.1201 11.22L12.9501 12.66C13.3901 13 14.0201 12.92
      14.3601 12.49L16.6701 9.51001"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>

<style scoped>

</style>