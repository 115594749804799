<script>
export default {
  name: "IconOtherType"
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none">
    <rect
      width="2.89588"
      height="19.3059"
      transform="matrix(0.707106 -0.707107 0.707106 0.707107 3.07129 7.16602)"
      fill="#A4D9F6"/>
    <rect
      width="2.89588"
      height="19.3059"
      transform="matrix(0.707106 -0.707107 0.707106 0.707107 5.11914 5.11914)"
      fill="#468FC5"/>
    <rect
      width="8.68763"
      height="2.89588"
      rx="1.44794"
      transform="matrix(0.707106 -0.707107 0.707106 0.707107 0 6.14258)"
      fill="#262F3D"/>
    <rect
      width="8.68763"
      height="2.89588"
      rx="1.44794"
      transform="matrix(0.707106 -0.707107 0.707106 0.707107 15.6992 21.8418)"
      fill="#262F3D"/>
    <rect
      width="2.89588"
      height="19.3059"
      transform="matrix(0.707106 0.707107 -0.707106 0.707107 16.835 3.18164)"
      fill="#A4D9F6"/>
    <rect
      width="2.89588"
      height="19.3059"
      transform="matrix(0.707106 0.707107 -0.707106 0.707107 18.8818 5.22852)"
      fill="#468FC5"/>
    <rect
      width="8.68763"
      height="2.89588"
      rx="1.44794"
      transform="matrix(0.707106 0.707107 -0.707106 0.707107 17.8584 0.109375)"
      fill="#262F3D"/>
    <rect
      width="8.68763"
      height="2.89588"
      rx="1.44794"
      transform="matrix(0.707106 0.707107 -0.707106 0.707107 2.15918 15.8086)"
      fill="#262F3D"/>
  </svg>
</template>

<style scoped>

</style>