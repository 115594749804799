<script>
export default {
  name: "IconArrowDownCircle"
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
    <path d="M8.33366 14.6667C12.0156 14.6667 15.0003 11.6819 15.0003 8.00001C15.0003 4.31811 12.0156 1.33334 8.33366 1.33334C4.65176 1.33334 1.66699 4.31811 1.66699 8.00001C1.66699 11.6819 4.65176 14.6667 8.33366 14.6667Z" stroke="#9192AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.33398 5.66666V9.66666" stroke="#9192AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.33398 8.33334L8.33398 10.3333L10.334 8.33334" stroke="#9192AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<style scoped>

</style>