import IssuesStatisticService from "@/services/IssuesStatisticService";

export const namespaced = true;

export const state = {
    issuesStatistic: [],
    issuesStatisticLoading: false,
    issuesStatisticError: null
}

export const mutations = {
    SET_ISSUES(state, issues) {
        state.issuesStatistic = issues;
    },
    SET_ISSUES_LOADING(state, loading) {
        state.issuesStatisticLoading = loading;
    },
    SET_ISSUES_ERROR(state, error) {
        state.issuesStatisticError = error;
    }
}

export const actions = {
    fetchIssuesStatistic({commit}, filter) {
        commit("SET_ISSUES_LOADING", true);
        return new Promise((resolve, reject) => {
            IssuesStatisticService.fetchIssuesStatistic(filter)
                .then(data => {
                    commit("SET_ISSUES", data);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ISSUES_ERROR", error);
                    reject();
                })
                .finally(() => commit("SET_ISSUES_LOADING", false));
        })
    }
}

export const getters = {
    getIssuesStatistic: state => {
        return state.issuesStatistic
    }
}