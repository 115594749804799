<template>
  <div class="page-wrapper">
    <base-panel
      title="Балансовые группы"
      :is-add-button-visible="true"
      add-button-name="Добавить балансовую группу"
      @add-button-clicked="showPopup()"
    />

    <balance-group-list
      v-if="balanceGroupsList.length"
      :balance-groups-list="balanceGroupsList"
      @edit-balance-group="showPopup"
      @delete-balance-group="deleteBalanceGroup"
    />

    <base-content-loaders v-if="loading && !balanceGroupsList.length"/>

    <base-no-data v-if="!loading && !balanceGroupsList.length"/>

    <base-paginate
      v-if="meta && meta.last_page > 1"
      :meta="meta"
      action="balanceGroup/getBalanceGroups"
      commit="balanceGroup/SET_PAGE"
    />
  </div>
</template>

<script>
import BalanceGroupList from "@/components/Billing/admin/BalanceGroupsList";
import BasePanel from "@/components/BasePanel.vue";
import {mapState} from "vuex";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import BasePaginate from "@/components/BasePaginate.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import BalanceGroupsForm from "@/components/Billing/admin/BalanceGroupsForm.vue";
import {showAlert, showToast, showConfirmationModal} from "@/utils/notification";

export default {
  name: "BalanceGroupPage",
  components: {
    BasePaginate,
    BaseNoData,
    BaseContentLoaders,
    BasePanel,
    BalanceGroupList
  },
  computed: {
    ...mapState({
      balanceGroupsList: state => state.balanceGroup.balanceGroups,
      loading: state => state.balanceGroup.loading,
      meta: state => state.balanceGroup.meta,
      zonesList: state => state.zone.zones,
      devicesList: state => state.device.devices
    })
  },
  mounted() {
    this.$store.dispatch('zone/getAllZones');
    this.$store.dispatch('device/getAllDevices');
    this.$store.dispatch('balanceGroup/getBalanceGroups');
  },
  methods: {
    showPopup(balanceGroup = {}) {
      this.$modal.show(
        BalanceGroupsForm,
        {
          'balanceGroup': balanceGroup,
          'createBalanceGroup': this.createBalanceGroup,
          'updateBalanceGroup': this.updateBalanceGroup,
          'zonesList': this.zonesList,
          'devicesList': this.devicesList
        },
        {
          name: 'balance-group-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460),
          scrollable: true
        })
    },
    createBalanceGroup(payload) {
      this.$store.dispatch('balanceGroup/createBalanceGroup', payload)
        .then(() => {
          this.$store.dispatch('balanceGroup/getBalanceGroups');
          this.$modal.hide('balance-group-form');
          showToast('Балансовая группа добавлена', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    updateBalanceGroup(balanceGroupId, payload) {
      this.$store.dispatch('balanceGroup/updateBalanceGroup', {
        balanceGroupId, payload
      })
        .then(() => {
          this.$store.dispatch('balanceGroup/getBalanceGroups');
          this.$modal.hide('balance-group-form');
          showToast('Балансовая группа обновлена', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    deleteBalanceGroup(balanceGroupId) {
      showConfirmationModal('Вы уверены, что хотите удалить балансовую группу? Это действие невозвратно.')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('balanceGroup/deleteBalanceGroup', balanceGroupId)
              .then(() => {
                this.$store.commit('balanceGroup/DELETE_BALANCE_GROUP', balanceGroupId);
                showToast('Балансовая группа удалена', 'success');
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style scoped>

</style>