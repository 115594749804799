<template>
  <div class="resource-dashboard-filter">
    <div class="resource-dashboard-filter__date-row">
      <div class="base-date-range-picker">
        <div v-for="date in dateList"
             :key="date.name"
             @click="setCurrentDate(date)"
             :class="['base-date-range-picker__item',
                      {'base-date-range-picker__item--active': date.name === currentDate.name}]">{{ date.name }}
        </div>
      </div>
      <date-picker
        v-model="range"
        :default-value="defaultValue"
        class="base-date-picker"
        placeholder="Выберите период"
        type="date"
        range
        @input="fetchData"
      />
    </div>
    <div class="resource-dashboard-filter__dropdowns-row">
      <v-select
        class="vue-select-input"
        v-model="department"
        :options="departments"
        :reduce="device => device.id"
        :get-option-label="(option) => option.name"
        placeholder="Выберите подразделение"
        @input="fetchData"
      />
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import {mapGetters} from "vuex";

export default {
  name: 'ResourceDashboardFilter',
  components: {
    DatePicker
  },
  data() {
    return {
      department: null,
      taskType: null,
      range: [new Date(), new Date()],
      defaultValue: new Date(),

      currentDate: {
        name: 'Сегодня',
        days: 0
      },
      dateList: [
        {
          name: 'Сегодня',
          days: 0
        },
        {
          name: 'Вчера',
          days: 1
        },
        {
          name: 'Неделя',
          days: 7
        },
        {
          name: 'Месяц',
          days: 30
        },
        {
          name: 'Квартал',
          days: 90
        },
        {
          name: 'Год',
          days: 365
        }
      ],

      currentDepartment: null,
      dropdownValue: null
    }
  },
  computed: {
    ...mapGetters({
      taskTypes: 'task_types/getTaskTypes',
      departments: 'departments/getDepartments',
      resourceData: 'reportsData/getReportData',
      resourceParams: 'reportsData/getReportParams'
    })
  },
  mounted() {
    this.getTaskTypes();
    this.getDepartment();
    this.fetchData();
  },
  methods: {
    setParams() {
      const [startDate, endDate] = this.range;
      endDate.setHours(23)
      endDate.setMinutes(59)

      const params = {
        period_start: startDate,
        period_end: endDate,
        department_id: this.department || null,
        task_type_id: this.taskType || null
      };
      this.$store.commit('reportsData/SET_REPORT_PARAMS', params);
    },
    getTaskTypes() {
      this.$store.dispatch('task_types/getAllTaskTypes');
    },
    getDepartment() {
      this.$store.dispatch('departments/getAllDepartments');
    },
    fetchData() {
      this.setParams()
      this.$store.dispatch('reportsData/getResourceRatingsData', this.resourceParams);
      this.$emit("updateDashboardView")
    },
    setCurrentDate(date) {
      this.currentDate = date;
      this.getDateNDaysAgo(date.days);
    },
    getDateNDaysAgo(n) {
      const today = new Date();
      const date = new Date();
      date.setDate(date.getDate() - n);
      this.range = [date, today];
      this.fetchData();
    }
  }
}
</script>

<style lang="scss" scoped>
.mx-datepicker {
  min-width: 250px;
  width: initial;
}
</style>