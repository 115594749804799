<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      <template v-if="isCreateForm">Добавить тип задания</template>
      <template v-else>Редактирование типа задания</template>
      <span @click="$modal.hide('task-type-form')"
            class="base-popup__close">
        <icon-close/>
      </span>
    </div>
    <div class="popup-form-group">
      <div class="form-item">
        <div class="form-item__name">Наименование</div>
        <div class="form-item__value">
          <input
            v-model="payload.name"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите наименование"
            required
          >
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Тег</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.tag"
            :options="tag.tags"
            :reduce="tag => tag.id"
            :get-option-label="(option) => option.label"
            class="vue-select-input"
            placeholder="Выберите из списка"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Группы проблем</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.category_groups"
            :options="categoryGroupsList"
            :reduce="group => group.id"
            :get-option-label="(option) => option.name"
            class="vue-select-input"
            placeholder="Выберите из списка"
            multiple
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Компоненты</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.features"
            :options="featuresList"
            :reduce="feature => feature.slug"
            :get-option-label="(option) => option.name"
            class="vue-select-input"
            placeholder="Выберите из списка"
            multiple
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Максимальное время выполнения в часах</div>
        <div class="form-item__value">
          <input
            v-model="payload.lead_time"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите количество часов"
            required
          >
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Этап</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.stage"
            :options="stagesList"
            :reduce="stage => stage.id"
            :get-option-label="(option) => option.name"
            class="vue-select-input"
            placeholder="Выберите из списка"
          />
        </div>
      </div>
      <div
        v-if="!isCreateForm"
        class="form-item">
        <label class="base-switcher">
          <input
            v-model="payload.active"
            class="base-switcher__checkbox"
            type="checkbox">
          <div class="base-switcher__switch"/>
          <span class="base-switcher__label">
            Активна
          </span>
        </label>
      </div>
    </div>
    <div class="base-popup__footer">
      <div @click="checkFormType()"
           class="button">
        Сохранить
      </div>
    </div>
  </div>
</template>

<script>
import Tag from '@/models/serviceDesk/Tag'
import IconClose from "@/components/Icons/IconClose.vue";

export default {
  name: "TaskTypesForm",
  components: {IconClose},
  props: {
    taskType: {
      type: Object,
      required: true
    },
    categoryGroupsList: {
      type: Array,
      required: true
    },
    featuresList: {
      type: Array,
      required: true
    },
    createTaskType: {
      type: Function,
      default: () => {}
    },
    updateTaskType: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      payload: {
        name: null,
        tag: null,
        features: null,
        category_groups: [],
        lead_time: null,
        stage: null
      },
      stagesList: [
        {id: 1, name: 'Подготовка'},
        {id: 2, name: 'Устранение проблемы'}
      ]
    }
  },
  computed: {
    tag: () => Tag,
    isCreateForm() {
      return !Object.keys(this.taskType).length
    }
  },
  mounted() {
    if (!this.isCreateForm) {
      const {name, tag, features, categoryGroups, leadTime, stage, active} = this.taskType;
      this.payload.name = name;
      this.payload.tag = tag;
      this.payload.features = features.map(item => item.slug);
      this.payload.category_groups = categoryGroups.map(item => item.id);
      this.payload.lead_time = leadTime;
      this.payload.stage = stage.id;
      this.payload.active = active;
    }
  },
  methods: {
    checkFormType() {
      if (!this.isCreateForm) this.updateTaskType(this.taskType.id, this.payload)
      else this.createTaskType(this.payload);
    }
  }
}
</script>

<style scoped>

</style>
