import * as API from './API';
import BaseLayer from "@/models/baseMap/BaseLayer";
import {getError} from "@/utils/helpers";

export default {
    fetchBaseLayers() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/basemaps`)
                .then(response => resolve(response.data.data.map(item => new BaseLayer({
                    id: item.id,
                    ...item.attributes
                }))))
                .catch(error => reject(getError(error)))
        })
    }
}