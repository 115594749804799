<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="24"
       height="24"
       viewBox="0 0 12 12"
       fill="none">
    <g clip-path="url(#clip0_2869_37414)">
      <path d="M-1 6H13M6 13V-1"
            stroke="#9192AD"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_2869_37414">
        <rect width="12"
              height="12"
              fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "IconPlus2"
}
</script>

<style scoped>

</style>
