<template>
  <div class="archive-journal-no-content">
    <img src="@/assets/img/no-result-2.png"
         alt="человек с карандашом">
    <span v-if="title">{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: 'ArchiveJournalNoContent',
  props: {
    title: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>

</style>