<template>
  <base-popup
    :title="popupTitle"
    :name="popupName"
  >
    <template #body>
      <div class="form-item">
        <div class="form-item__name">Вопрос</div>
        <div class="form-item__value">
          <textarea
            v-model="payload.question"
            class="base-textarea"
            type="text"
            placeholder="Напишите текст вопроса"/>
        </div>
      </div>

      <div
        v-if="isCreateForm"
        class="form-item">
        <div class="form-item__name">Тип ответа</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.answer_type"
            :options="questionClass.getAnswerTypesList()"
            :reduce="type => type.id"
            :get-option-label="(option) => option.label"
            placeholder="Выберите тип ответа"
            class="vue-select-input"
          />
        </div>
      </div>

      <div
        v-if="payload.answer_type === questionClass.ANSWER_DICTIONARY.id && isCreateForm"
        class="form-item">
        <div class="form-item__name">Справочник</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.dictionary_id"
            :options="dictionariesList"
            :reduce="type => type.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите справочник"
            class="vue-select-input"
          />
        </div>
      </div>

      <question-preview
        v-if="payload.question && payload.answer_type"
        :answer-type="payload.answer_type"
        :question="payload.question"
        :dictionary="dictionaryItems"
      />
    </template>

    <template #footer>
      <button
        @click="submitForm()"
        class="button button--mode-primary">Сохранить</button>
    </template>
  </base-popup>
</template>

<script>
import BasePopup from "@/components/BasePopup.vue";
import Question from "@/models/TechnicalInspection/Question";
import QuestionPreview from "@/components/TechnicalInspection/NetworkObjectTypes/QuestionPreview.vue";

export default {
  name: "QuestionsForm",
  components: {QuestionPreview, BasePopup},
  props: {
    popupName: {
      type: String,
      required: true
    },
    question: {
      type: Object,
      required: true
    },
    createQuestion: {
      type: Function,
      required: true
    },
    updateQuestion: {
      type: Function,
      required: true
    },
    dictionariesList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      payload: {
        question: null,
        answer_type: null,
        dictionary_id: null
      }
    }
  },
  computed: {
    isCreateForm() {
      return !Object.keys(this.question).length;
    },
    popupTitle() {
      return this.isCreateForm ? 'Добавить вопрос' : 'Редактирование вопроса';
    },
    questionClass() {
      return Question
    },
    dictionaryItems() {
      const dictionary = this.dictionariesList.find(dictionary => dictionary.id === this.payload.dictionary_id);
      return dictionary ? dictionary.items : [];
    }
  },
  mounted() {
    if (!this.isCreateForm) {
      const {question, answerType, dictionary} = this.question;

      this.payload.question = question;
      this.payload.answer_type = answerType.id;
      this.payload.dictionary_id = dictionary ? dictionary.id : null;
    }
  },
  methods: {
    submitForm() {
      this.payload.object_type_id = this.$route.params.id;

      if (this.isCreateForm) this.createQuestion(this.payload)
      else this.updateQuestion(this.question.id, this.payload)
    }
  }
}
</script>

<style scoped>

</style>