<template>
  <div class="page-wrapper">
    <base-panel
      title="Обращения"
      :is-add-button-visible="false"
      :is-weather-visible="true"
      :is-time-visible="true"
    />

    <div class="base-tables-container">
      <div class="base-table__tabs">
        <div class="base-table__tab"
             :class="{'active': activeFilterState === 0}"
             @click="filterAppeals(0); activeFilterState = 0">Все активные
        </div>
        <div class="base-table__tab"
             :class="{'active': activeFilterState === 1}"
             @click="filterAppeals(AppealState.NEW); activeFilterState = 1">Новые
        </div>
        <div class="base-table__tab"
             :class="{'active': activeFilterState === 2}"
             @click="filterAppeals(AppealState.IN_WORK); activeFilterState = 2">В работе
        </div>
        <div class="base-table__tab base-table__tab--float-right"
             :class="{'active': activeFilterState === 3}"
             @click="activeFilterState = 3">Архив обращений
        </div>
      </div>

      <div v-if="activeFilterState === 3">
        <appeals-archive-form/>

        <appeals-list
          v-if="appealsArchiveList.length && !appealsArchiveLoading"
          :appeals-list="appealsArchiveList"
        />

        <base-content-loaders v-if="appealsArchiveLoading"/>

        <div v-if="!appealsArchiveList.length && !appealsArchiveLoading"
             class="no-result">
          <img src="@/assets/img/no-result-2.png"
               alt="не сформирован отчёт">
          <div class="no-result__text">
            Сформируйте запрос и получите список
          </div>
          <div class="no-result__subtext">
            Выберите параметры выше и нажмите на  «Сформировать», чтобы получить список архивных обращений
          </div>
        </div>
      </div>

      <div v-else>
        <base-functional-line @search-change="searchAppealsByAddress">
          <appeals-list-filter
            :is-list-view="isListView"
            @set-list-view="value => this.isListView = value"
            @open-create-appeal-modal="isCreateAppealModalVisible = true"/>
        </base-functional-line>

        <div v-if="isListView">
          <div v-if="appealsList.length">
            <appeals-list :appeals-list="appealsList"/>

            <base-paginate
              v-if="appealsMeta.last_page > 1"
              :meta="appealsMeta"
              action="appeals/getAppeals"
              commit="appeals/SET_PAGE"
            />
          </div>

          <base-content-loaders
            v-else-if="!appealsList.length && appealsLoading"/>

          <div v-else
               class="no-result">
            <img src="@/assets/img/no-result.png"
                 alt="Нет данных"
                 class="no-result__img">
            <div class="no-result__text">
              Не найдено обращений
            </div>
          </div>
        </div>
        <div v-else>
          <appeals-list-map :appeals-list="appealsList"/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import AppealsListFilter from "@/components/CallCenter/AppealsListFilter";
import AppealsListMap from "@/components/CallCenter/AppealsListMap";
import AppealsList from "@/components/CallCenter/AppealsList";
import {mapState} from "vuex";
import BasePaginate from "@/components/BasePaginate";
import BasePanel from "@/components/BasePanel";
import BaseFunctionalLine from "@/components/BaseFunctionalLine";
import AppealState from "@/models/callCenter/AppealState";
import BaseContentLoaders from "@/components/BaseContentLoaders";
import searchAppealsByAddress from "@/mixins/searchAppealsByAddress";
import AppealsArchiveForm from "@/components/CallCenter/AppealsArchiveForm.vue";

export default {
  name: "AppealsListPage",
  components: {
    AppealsArchiveForm,
    BaseContentLoaders,
    BaseFunctionalLine,
    BasePanel,
    AppealsList,
    AppealsListMap,
    AppealsListFilter,
    BasePaginate
  },
  mixins: [searchAppealsByAddress],
  data() {
    return {
      timing: null,
      isListView: true,
      activeFilterState: 0,
      isCreateAppealModalVisible: false
    }
  },
  computed: {
    ...mapState({
      appealsList: state => state.appeals.appeals,
      appealsPage: state => state.appeals.page,
      appealsMeta: state => state.appeals.meta,
      appealsLoading: state => state.appeals.loading,
      appealsArchiveLoading: state => state.appeals.allAppealsLoading,
      appealModalVisible: state => state.appeals.isAppealModalVisible,
      issueModalVisible: state => state.issue.isIssueModalVisible,
      appealsArchiveList: state => state.appeals.allAppealsList
    }),
    AppealState: () => AppealState,
    isAppealModalVisible: {
      get() {
        return this.appealModalVisible
      },
      set(value) {
        this.$store.commit('appeals/SET_APPEAL_MODAL_VISIBLE', value);
      }
    },
    isIssueModalVisible: {
      get() {
        return this.issueModalVisible;
      },
      set(value) {
        this.$store.commit('issue/SET_ISSUE_MODAL_VISIBLE_STATE', value);
      }
    }
  },
  watch: {
    activeFilterState: function (newVal, oldVal) {
      if (oldVal === 3) this.$store.commit('appeals/SET_ALL_APPEALS', []);
    }
  },
  mounted() {
    this.fetchData();

    this.timing = setInterval(this.fetchData, process.env.VUE_APP_SERVICE_DESK_GET_DATA_TIMING);
  },
  beforeDestroy() {
    this.$store.commit("appeals/SET_FILTER_PARAMS", {});
    this.$store.commit('appeals/SET_APPEALS', []);
    this.$store.dispatch('baseSideModal/closeModal');

    clearInterval(this.timing);
  },
  methods: {
    filterAppeals(appealState) {
      this.$store.commit('appeals/SET_APPEALS', []);

      if (appealState === 0) {
        this.$store.commit('appeals/SET_FILTER_PARAM', {
          key: 'appeal_status',
          value: null
        })
      } else {
        this.$store.commit('appeals/SET_FILTER_PARAM', {
          key: 'appeal_status',
          value: typeof appealState === 'number' ? appealState : appealState.join(',')
        })
      }

      this.$store.dispatch("appeals/getAppeals", this.appealsPage);
    },
    fetchData() {
      this.$store.dispatch("appeals/getAppeals", this.appealsPage);
    }
  }
}
</script>

<style scoped>
.list-header {
  font-size: 22px;
  font-weight: 600;
  color: var(--text-primary);
}
</style>
