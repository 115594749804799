import * as API from './API'
import Work from "@/models/serviceDesk/Work";
import {getError} from "@/utils/helpers";
import TaskWork from "@/models/serviceDesk/TaskWork";
import EmergencyElement from "@/models/serviceDesk/EmergencyElement";

export default {
    getWorks(page) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/works?page=${page}`)
                .then(response => {
                    resolve({
                        works: response.data.data.map(work => new Work(work.id, work.attributes.name,
                            work.attributes.group, work.attributes.active)),
                        meta: response.data.meta,
                        links: response.data.links
                    })
                })
                .catch(error => reject(getError(error)));
        })
    },
    getAllWorks(params) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/works/all`, {params: params})
                .then(response => resolve(response.data.data.map(work =>
                    new Work(work.id, work.attributes.name, work.attributes.group, work.attributes.active))))
                .catch(error => reject(getError(error)));
        })
    },
    createWork(payload) {
        return API.apiClient.post(`service-desk/works`, payload);
    },
    updateWork({work_id, payload}) {
        return API.apiClient.patch(`service-desk/works/${work_id}`, payload);
    },
    deleteWork(work_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`service-desk/works/${work_id}`)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    getTaskWorks(task_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/tasks/${task_id}/works`)
                .then(response => {
                    resolve(response.data.data.map(work => {
                        const taskWork = new TaskWork(work.id, work.attributes);
                        if (work.attributes.element) {
                            taskWork.element = new EmergencyElement(
                                work.attributes.element.id,
                                work.attributes.element.attributes)
                        }

                        return taskWork;
                    }))
                })
                .catch(error => reject(getError(error)));
        })
    },
    addActualConsumptionToWork(taskId, workId, payload) {
        return API.apiClient.post(`/service-desk/tasks/${taskId}/works/${workId}/actual-consumptions`, payload);
    },
    updateActualConsumptionInWork(taskId, workId, payload) {
        return API.apiClient.patch(`/service-desk/tasks/${taskId}/works/${workId}/actual-consumptions`, payload);
    },
    deleteActualConsumption(taskId, workId, consumptionId) {
        return API.apiClient.delete(
            `/service-desk/tasks/${taskId}/works/${workId}/actual-consumptions/${consumptionId}`
        );
    }
}