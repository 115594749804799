<template>
  <svg width="24"
       height="24"
       viewBox="0 0 24 24"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M6.08998 13.2799H9.17998V20.4799C9.17998 22.1599 10.09 22.4999 11.2 21.2399L18.77
    12.6399C19.7 11.5899 19.31 10.7199 17.9 10.7199H14.81V3.5199C14.81 1.8399 13.9 1.4999 12.79
    2.7599L5.21998 11.3599C4.29998 12.4199 4.68998 13.2799 6.08998 13.2799Z"
          stroke="#9192AD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconEngineNew"
}
</script>
