import ResourcesService from "@/services/ResourcesService";

export const namespaced = true;

export const state = {
    resourcesList: [],
    loading: false,
    page: 1,
    meta: null,
    error: null,
    query: '',
    sortBy: '',
    sortOrder: ''
}

export const mutations = {
    SET_RESOURCES(state, resources) {
        state.resourcesList = resources;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_PAGE(state, page) {
        state.page = page;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    DELETE_RESOURCE(state, resourceId) {
        const index = state.resourcesList.findIndex(item => item.id === resourceId);
        state.resourcesList.splice(index, 1);
    },
    SET_QUERY(state, query) {
        state.query = query;
    },
    SET_SORT_BY(state, sortBy) {
        state.sortBy = sortBy;
    },
    SET_SORT_ORDER(state, sortOrder) {
        state.sortOrder = sortOrder;
    }
}

export const actions = {
    getResourcesList({commit, state}) {
        commit("SET_LOADING", true);
        ResourcesService.fetchResources(state.page, state.query, state.sortBy, state.sortOrder)
            .then(({resourcesList, meta}) => {
                commit("SET_RESOURCES", resourcesList);
                commit("SET_META", meta);
            })
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getAllResourcesList({commit}) {
        commit("SET_LOADING", true);
        ResourcesService.fetchAllResources()
            .then(resources => commit("SET_RESOURCES", resources))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getAvailableResourcesList({commit}, filterParams) {
        commit("SET_LOADING", true);
        ResourcesService.fetchAvailableResources(filterParams)
            .then(resources => commit("SET_RESOURCES", resources))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    createResource(context, payload) {
        return new Promise((resolve, reject) => {
            ResourcesService.createResource(payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    updateResource(context, {resourceId, payload}) {
        return new Promise((resolve, reject) => {
            ResourcesService.updateResource(resourceId, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    deleteResource(context, resourceId) {
        return new Promise((resolve, reject) => {
            ResourcesService.deleteResource(resourceId)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    }
}

export const getters = {
    getResourcesByDepartmentId: state => departmentId => {
        return state.resourcesList.filter(resource => resource.department.id === departmentId);
    }
}