export const namespaced = true;

export const state = {
    selectedDate: new Date()
}

export const mutations = {
    SET_DATE(state, date) {
        state.selectedDate = date;
    }
}