import { render, staticRenderFns } from "./DefectsList.vue?vue&type=template&id=90162e58&scoped=true&"
import script from "./DefectsList.vue?vue&type=script&lang=js&"
export * from "./DefectsList.vue?vue&type=script&lang=js&"
import style0 from "./DefectsList.vue?vue&type=style&index=0&id=90162e58&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90162e58",
  null
  
)

export default component.exports