<template>
  <span>{{parameterValue.value ? roundedNumber(parameterValue.value, precision) : '—'}}</span>
</template>

<script>
import {roundedNumber} from "@/utils/helpers";

export default {
  name: "ParameterValue",
  props: {
    precision: {
      type: Number,
      required: true
    },
    parameterValue: {
      type: Object,
      required: true
    }
  },
  methods: {roundedNumber}
}
</script>

<style scoped>

</style>