export class WorkShift {
    constructor(id, attributes) {
        this.id = id;
        this.resourceId = attributes.resource.id;
        this.resourceName = attributes.resource.name;
        this.departmentName = attributes.resource.department.name;
        this.workDay = attributes.work_day;
    }

    getCalendarEvent() {
        return {
            id: this.id,
            title: `${this.departmentName}: ${this.resourceName}`,
            allDay: true,
            start: this.workDay,
            end: this.workDay,
            classNames: ['calendar-event'],
            display: 'block'
        }
    }
}