<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="18"
       height="18"
       viewBox="0 0 18 18"
       fill="none">
    <path d="M7.83781 5.03998L5.04779 2.25L2.25781 5.03998"
          stroke="#9192AD"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M5.04688 15.75V2.25"
          stroke="#9192AD"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M10.1621 12.9609L12.9521 15.7509L15.7421 12.9609"
          stroke="#468FC5"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M12.9531 2.25V15.75"
          stroke="#468FC5"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconSort"
}
</script>

<style scoped>

</style>
