export default {
    switchColorScheme(config, scheme) {
        localStorage.setItem('selectedColorTheme', scheme);
        switch (scheme) {
            case 'light':
                config.setLightColorSchema();
                break;
            case 'dark':
                config.setDarkColorSchema();
                break;
            case 'system':
                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    config.setDarkColorSchema();
                } else config.setLightColorSchema();
                break;
        }
    }
}