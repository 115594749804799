<template>
  <div class="i-modal__content-wrapper">
    <div class="i-modal__content">
      <div v-if="Object.keys(user).length && !loading"
           class="i-modal__inner">
        <div class="base-modal__left-side base-modal__left-side--hidden">
          <div class="base-modal-header base-modal-header--padding-s">
            <span class="base-modal-title">{{user.name}}</span>
          </div>

          <div class="base-modal-info base-modal-info--gap-24 base-modal-info--padding-s">
            <div class="base-modal-info__card">
              <div class="base-modal-info__card-title base-modal-info__card-title--loader">
                Детальная информация

                <div
                  @click="openEditModal()"
                  class="base-modal-info__card-title-subtext">
                  <icon-pencil/>
                  <span>Редактировать</span>
                </div>
              </div>

              <div class="base-modal-info__item">
                <div class="base-modal-info__name">
                  <span>Email</span>
                </div>
                <div class="base-modal-info__value">
                  <p class="base-modal-info__value-txt">{{ user.email }}</p>
                </div>
              </div>

              <div class="base-modal-info__item">
                <div class="base-modal-info__name">
                  <span>Телефон</span>
                </div>
                <div class="base-modal-info__value">
                  <p class="base-modal-info__value-txt">{{ nullFilter(user.phone) }}</p>
                </div>
              </div>

              <div class="base-modal-info__item">
                <div class="base-modal-info__name">
                  <span>Должность</span>
                </div>
                <div class="base-modal-info__value">
                  <p class="base-modal-info__value-txt">{{ nullFilter(user.job_title) }}</p>
                </div>
              </div>

              <div class="base-modal-info__item">
                <div class="base-modal-info__name">
                  <span>Структ. подразделение</span>
                </div>
                <div class="base-modal-info__value">
                  <p class="base-modal-info__value-txt">{{ user.structuralUnit.name }}</p>
                </div>
              </div>

              <div class="base-modal-info__item">
                <div class="base-modal-info__name">
                  <span>Пароль</span>
                </div>
                <div class="base-modal-info__value">
                  <p
                    @click="showChangeUserPasswordForm()"
                    class="base-modal-info__value-txt base-modal-info__value-text--accent
                  base-modal-info__value-text--pointer">Изменить пароль</p>
                </div>
              </div>

              <div
                class="base-modal-info__item">
                <div class="base-modal-info__name">
                  <span>Дата создания</span>
                </div>
                <div class="base-modal-info__value">
                  <p class="base-modal-info__value-txt">{{ formatDateTime(user.createdAt) }}</p>
                </div>
              </div>

              <div
                v-if="user.blockedAt"
                class="base-modal-info__item">
                <div class="base-modal-info__name">
                  <span>Дата блокировки</span>
                </div>
                <div class="base-modal-info__value">
                  <p class="base-modal-info__value-txt">{{ formatDateTime(user.blockedAt) }}</p>
                </div>
              </div>
            </div>

            <div class="base-modal-info__item--options">
              <user-roles-list
                :roles-list="user.roles"
                :user-id="user.id"
              />

              <user-subscriptions-list
                :subscriptions-list="subscriptionsList"
                :user-id="user.id"
              />
            </div>

            <div
              v-if="!user.blockedAt"
              @click="blockUser()"
              class="button button--mode-outline text-danger">
              Заблокировать пользователя
            </div>
            <div
              v-else
              @click="unblockUser()"
              class="button button--mode-outline text-danger">
              Разблокировать пользователя
            </div>

          </div>
        </div>
      </div>
      <base-side-modal-skeleton v-if="loading" />
    </div>
    <div class="i-modal-btn-wp i-modal-btn-wp--back">
      <div class="task-modal__mobile-actions">
        <button @click="$store.dispatch('baseSideModal/closeModal')"
                class="button button--mode-outline task-modal__mobile-back">
          <icon-arrow-left/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSideModalSkeleton from "@/components/BaseSideModalSkeleton.vue";
import IconArrowLeft from "@/components/Icons/IconArrowLeft.vue";
import IconPencil from "@/components/Icons/IconPencil.vue";
import {mapState} from "vuex";
import UserRolesList from "@/components/admin/User/UserRolesList.vue";
import UserSubscriptionsList from "@/components/admin/User/UserSubscriptionsList.vue";
import {formatDateTime, nullFilter} from "@/utils/filters";
import UserChangePasswordForm from "@/components/admin/User/UserChangePasswordForm.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import {showToast, showAlert, showConfirmationModal} from "@/utils/notification";

export default {
  name: "UserModalContent",
  components: {UserSubscriptionsList, UserRolesList, IconPencil, IconArrowLeft, BaseSideModalSkeleton},
  computed: {
    ...mapState({
      user: state => state.user.user,
      loading: state => state.user.userLoading,
      subscriptionsList: state => state.user.userSubscriptions
    })
  },
  mounted() {
    this.$store.dispatch('role/getAllRoles');
    this.$store.dispatch('userSubscriptions/getSubscriptionTypes');
  },
  methods: {
    formatDateTime,
    nullFilter,
    openEditModal() {
      this.$store.dispatch('baseSideModal/openModal', {
        modalContentType: 'userForm',
        propsData: this.user
      })
    },
    showChangeUserPasswordForm() {
      this.$modal.show(
        UserChangePasswordForm,
        {
          'userId': this.user.id,
          'popupName': 'change-user-password-modal'
        },
        {
          name: 'change-user-password-modal',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        })
    },
    blockUser() {
      showConfirmationModal('Вы уверены, что хотите заблокировать пользователя?', 'Заблокировать')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('user/blockUser', this.user.id)
              .then(() => {
                this.$store.dispatch('user/getUser', this.user.id);
                showToast('Пользователь заблокирован', 'success');
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    },
    unblockUser() {
      showConfirmationModal('Вы уверены, что хотите разблокировать пользователя?', 'Разблокировать')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('user/unblockUser', this.user.id)
              .then(() => {
                this.$store.dispatch('user/getUser', this.user.id);
                showToast('Пользователь разблокирован', 'success');
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style scoped>

</style>