<template>
  <form class="emergency-elements-report-form">
    <div class="emergency-elements-report-form__left">
      <base-date-range-picker @updateDateRange="setDateRange($event)" />
      <date-picker
        class="base-date-picker"
        placeholder="Выберите период"
        v-model="reportPeriod"
        :default-value="defaultValue"
        format="DD.MM.YYYY"
        type="day"
        range
        value-type="YYYY-MM-DD"
        @input="getReportData"/>
    </div>
    <button @click.prevent="getExcel(tableRows)"
            class="button button--mode-outline"
            :disabled="!reportDataLoaded">
      <icon-export/>
      <span>Экспорт</span>
    </button>
    <v-select
      v-model="mapLayerId"
      :options="layers"
      :reduce="layer => layer.id"
      :get-option-label="(option) => option.name"
      class="vue-select-input"
      placeholder="Выберите из списка"
      @input="getReportData"/>
  </form>
</template>

<script>

import {mapState} from "vuex";
import DatePicker from "vue2-datepicker";
import IconExport from "@/components/Icons/IconExport.vue";
import {getExcel} from "@/utils/table";
import BaseDateRangePicker from "@/components/BaseDateRangePicker.vue";

export default {
  name: "EmergencyElementsReportForm",
  components: {BaseDateRangePicker, IconExport, DatePicker},
  props: {
    tableRows: {
      required: true
    }
  },
  data() {
    return {
      defaultValue: this.$moment().format('YYYY-MM-DD')
    }
  },
  computed: {
    ...mapState({
      layers: state => state.baseMap.layers,
      reportParams: state => state.reportsData.reportParams,
      reportData: state => state.reportsData.reportData,
      loading: state => state.reportsData.loading
    }),
    reportDataLoaded() {
      return !!this.reportData?.length && !this.loading;
    },
    mapLayerId: {
      get() {
        return this.reportParams.map_layer_id
      },
      set(value) {
        this.$store.commit('reportsData/SET_REPORT_PARAM', {
          key: 'map_layer_id',
          value: value
        });
      }
    },
    reportPeriod: {
      get() {
        const {period_start, period_end} = this.reportParams;
        return [period_start, period_end];
      },
      set(value) {
        const [period_start, period_end] = value;

        this.$store.commit('reportsData/SET_REPORT_PARAM', {
          key: 'period_start',
          value: period_start
        });
        this.$store.commit('reportsData/SET_REPORT_PARAM', {
          key: 'period_end',
          value: period_end
        })
      }
    }
  },
  mounted() {
    this.$store.dispatch('baseMap/getLayers');
    const reportParams = {map_layer_id: null, period_start: this.defaultValue, period_end: this.defaultValue};
    this.$store.commit('reportsData/SET_REPORT_PARAMS', reportParams);
    this.getReportData();
  },
  methods: {
    getExcel,
    getReportData() {
      this.$store.dispatch('reportsData/getEmergencyElementsReportData', this.reportParams);
    },
    setDateRange(range) {
      const reportParams = {period_start: range.start, period_end: range.end};
      this.$store.commit('reportsData/SET_REPORT_PARAMS', reportParams);
      this.getReportData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/viriables";
.emergency-elements-report-form {
  display: grid;
  grid-template-columns: 796px 144px;
  gap: 20px;
  width: 100%;
  margin-bottom: 32px;
  justify-content: space-between;

  .base-date-range-picker {
    justify-self: flex-start;
  }

  .vue-select-input {
    width: 320px;
    @media (max-width: $tablet) {
      width: 100%;
    }
  }

  &__left {
    display: grid;
    grid-template-columns: 452px 1fr;
    gap: 20px;

    @media (max-width: $tablet) {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: $tablet) {
    grid-template-columns: 1fr;
  }
}
</style>
