<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="20"
       height="20"
       viewBox="0 0 20 20"
       fill="none">
    <path d="M14.9257 8.93146H12.3507V2.93146C12.3507 1.53146 11.5924 1.24812 10.6674 2.29812L10.0007
     3.05646L4.35908 9.47312C3.58408 10.3481 3.90908 11.0648 5.07574 11.0648H7.65074V17.0648C7.65074
      18.4648 8.40908 18.7481 9.33408 17.6981L10.0007 16.9398L15.6424 10.5231C16.4174 9.64812 16.0924
       8.93146 14.9257 8.93146Z"
          fill="#9192AD"/>
  </svg>
</template>

<script>
export default {
  name: "IconFlashFilled"
}
</script>

<style scoped>

</style>
