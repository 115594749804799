<script>
export default {
  name: "IconVideoPlay"
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none">
    <circle
      cx="16"
      cy="16"
      r="16"
      fill="#262F3D"/>
    <path
      d="M22.5 15.134C23.1667 15.5189 23.1667 16.4811 22.5 16.866L13.5 22.0622C12.8333 22.4471 12 21.966 12 21.1962L12
       10.8038C12 10.034 12.8333 9.55292 13.5 9.93782L22.5 15.134Z"
      fill="white"/>
  </svg>
</template>

<style scoped>

</style>