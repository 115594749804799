<template>
  <div class="page-wrapper">
    <base-panel
      title="Ресурсы"
      :is-add-button-visible="false" />

    <resources-load-filter
      @get-resources="getResources"
      @get-tasks="getTasks" />

    <resources-list
      v-if="resourcesList.length && tasksList.length && departmentId"
      :resources-list="resourcesList(departmentId)"
      :tasks-list="tasksList"
      :department-id="departmentId" />
  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel.vue";
import ResourcesLoadFilter from "@/components/ServiceDesk/ResourcesLoad/ResourcesLoadFilter.vue";
import ResourcesList from "@/components/ServiceDesk/ResourcesLoad/ResourcesList.vue";
import {mapGetters, mapState} from "vuex";

export default {
  name: "ResourcesLoadPage",
  components: {ResourcesList, ResourcesLoadFilter, BasePanel},
  data() {
    return {
      departmentId: 0
    }
  },
  computed: {
    ...mapState({
      tasksList: state => state.tasks.tasks
    }),
    ...mapGetters({
      resourcesList: "resources/getResourcesByDepartmentId"
    })
  },
  methods: {
    getResources(departmentId) {
      this.departmentId = departmentId;
      this.$store.dispatch('resources/getAllResourcesList');
    },
    getTasks(params) {
      this.$store.dispatch('tasks/getAllTasks', params);
    }
  }
}
</script>

<style scoped>

</style>
