import * as API from './API';
import Weather from "@/models/Weather";
import {getError} from "@/utils/helpers";

export default {
    getCurrentWeather() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/weather/current`)
                .then(response => resolve(new Weather({...response.data.data.attributes})))
                .catch(error => reject(getError(error)));
        })
    },
    getWeatherArchive(params) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/weather/history`, {params})
                .then(response => {
                    const weatherArchive = response.data.data
                        .map(item => new Weather({...item.attributes}))
                    resolve(weatherArchive.sort((a, b) => new Date(a.weatherDate) - new Date(b.weatherDate)))
                })
                .catch(error => reject(getError(error)));
        })
    }
}