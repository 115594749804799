<template>
  <base-popup
    :title="popupTitle"
    :name="popupName"
  >
    <template #body>
      <div class="form-item">
        <div class="form-item__name">Наименование</div>
        <div class="form-item__value">
          <input
            v-model="payload.name"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите наименование"
            required
          >
        </div>
      </div>
    </template>

    <template #footer>
      <div @click="checkFormType()"
           class="button">
        Сохранить
      </div>
    </template>
  </base-popup>
</template>

<script>
import BasePopup from "@/components/BasePopup.vue";

export default {
  name: "RolesForm",
  components: {BasePopup},
  props: {
    role: {
      type: Object,
      required: true
    },
    popupName: {
      type: String,
      required: true
    },
    createRole: {
      type: Function,
      required: true
    },
    updateRole: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      payload: {
        name: ''
      }
    }
  },
  computed: {
    popupTitle() {
      return !Object.keys(this.role).length ? 'Добавить роль' : 'Редактирование роли'
    }
  },
  mounted() {
    if (Object.keys(this.role).length) {
      const {name} = this.role;
      this.payload.name = name;
    }
  },
  methods: {
    checkFormType() {
      if (!Object.keys(this.role).length) this.createRole(this.payload)
      else this.updateRole(this.role.id, this.payload)
    }
  }
}
</script>

<style scoped>

</style>