import ResourceStatisticService from "@/services/ResourceStatisticService";

export const namespaced = true;

export const state = {
    resourceStatistic: null,
    resourceStatisticLoading: false,
    resourceStatisticError: null
}

export const mutations = {
    SET_RESOURCE_STATISTIC(state, statistic) {
        state.resourceStatistic = statistic;
    },
    SET_STATISTIC_LOADING(state, loading) {
        state.resourceStatisticLoading = loading;
    },
    SET_STATISTIC_ERROR(state, error) {
        state.resourceStatisticError = error;
    }
}

export const actions = {
    getResourceStatistic({commit}, {resourceId, params}) {
        commit("SET_STATISTIC_LOADING", true);
        ResourceStatisticService.fetchResourceStatistic(resourceId, params)
            .then(statistic => commit("SET_RESOURCE_STATISTIC", statistic))
            .catch(error => commit("SET_STATISTIC_ERROR", error))
            .finally(() => commit("SET_STATISTIC_LOADING", false))
    }
}