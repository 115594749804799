<template>
  <div>
    <base-panel
      title="Отчеты по телеметрии"
      :is-add-button-visible="false"/>
    <form @submit.prevent="submitFilter"
          class="archive-journal-filter">
      <div class="archive-journal-filter__inputs">
        <div class="archive-journal-filter__input">
          <label>Оборудование</label>
          <base-select
            v-model="networkEquipmentId"
            :options="networkEquipments"
            :reduce="device => device.id"
            placeholder="Выберите устройство"
          />
        </div>
        <div class="archive-journal-filter__input">
          <label>Детализация по</label>
          <base-select
            v-model="group"
            :options="groupOptions"
            :reduce="option => option.id"
          />
        </div>
        <div class="archive-journal-filter__input">
          <label class="form-label">Период</label>
          <date-picker
            v-model="range"
            :default-value="defaultValue"
            class="base-date-picker"
            placeholder="Выберите период"
            :type="typeDataInput"
            range
          />
        </div>
        <div class="form__buttons">
          <button
            class="button button--mode-primary w-100">
            Сформировать
          </button>
          <button class="button button--mode-outline button--mode-icon"
                  type="button"
                  @click="resetFilter"
          >
            <icon-refresh/>
          </button>
        </div>
      </div>
      <button @click="getExcelTable"
              class="button button--mode-outline">
        <icon-upload/>
        <span>
          Экспорт
        </span>
      </button>
    </form>

    <archive-journal-no-content v-if="!parametersValues.length"
                                title="Сформируйте запрос, чтобы получить данные"/>

    <div v-if="parametersValues.length"
         class="base-chart"
         id="apexcharts-line"
    >
      <div class="base-chart__header">
        <span @click="toggleChartDropdown"
              class="base-chart__title">{{ currentChartType }}</span>
        <icon-arrow-linear-down
          :class="{'toggle': showChartDropdown}"/>
        <div class="tags-select">
          <div v-for="chartType in createParametersChartData()"
               :key="chartType.id"
               @click="setCurrentChartType(chartType.parameterName)"
               class="tags-select__item"
               :class="{
                 'selected': currentChartType === chartType.parameterName,
               }">
            {{ chartType.parameterName }}
          </div>
        </div>
        <base-dropdown class="base-chart__dropdown"
                       v-if="showChartDropdown">
          <span v-for="chart in createParametersChartData()"
                :key="chart.id"
                @click="setCurrentChartType(chart.parameterName)"
                class="base-dropdown__option">{{ chart.parameterName }}</span>
        </base-dropdown>

      </div>

      <archive-charts
        :parameter-series="currentParameterChartSeries"
        :current-chart-type="currentChartType"
        :group="group"
        :is-date-time-xaxis="isDateTimeXaxis"
      />
    </div>

    <div v-if="parametersValues.length"
         class="table-controls">
      <span class="table-controls__title">
        Сортировать
      </span>
      <div @click="isAscSort = !isAscSort; sortHoursArray()"
           class="table-controls__control">
        <span>
          По времени
        </span>
        <icon-arrow-linear-down
          :class="{'toggle': isAscSort}"/>
      </div>
    </div>
    <div v-if="parametersValues.length"
         class="base-table">
      <div class="base-table__container">
        <div
          :class="['base-table__header', 'base-table__header--archive-journal']"
          :style="{gridTemplateColumns: calculateTableRows(networkEquipmentParameters(networkEquipmentId))}">
          <div @click="isAscSort = !isAscSort; sortHoursArray()"
               class="base-table__header-cell base-table__header-cell-sort"
               :class="{'toggled': !isAscSort}">
            Время
            <icon-sort/>
          </div>
          <div
            v-for="parameter in networkEquipmentParameters(networkEquipmentId)"
            :key="parameter.id"
            class="base-table__header-cell"
          >
            {{ parameter.name }}
          </div>
        </div>

        <div v-for="(dateLabel, index) in this.hours_array"
             :key="`table_label`+index"
             :class="['base-table__row', 'base-table__row--archive-journal']"
             :style="{gridTemplateColumns: calculateTableRows(networkEquipmentParameters(networkEquipmentId))}">
          <div
            class="base-table__column base-table__column--archive-journal">
            <div class="base-table__text">{{ dateLabel }}</div>
          </div>
          <div v-for="(parameter, idx) in networkEquipmentParameters(networkEquipmentId)"
               :key="`value_table_`+parameter.id"
               class="base-table__column base-table__column--archive-journal">
            <div class="base-table__text-label base-table__text-label--archive-journal">
              {{ networkEquipmentParameters(networkEquipmentId)[idx].name }}
            </div>
            <div class="base-table__text--archive-journal">
              {{ getParameterValue(dateLabel, parameter.id) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {mapGetters, mapState} from "vuex";
import IconSort from "@/components/Icons/IconSort.vue";
import BasePanel from "@/components/BasePanel.vue";
import IconRefresh from "@/components/Icons/IconRefresh.vue";
import IconArrowLinearDown from "@/components/Icons/IconArrowLinearDown.vue";
import ArchiveJournalNoContent from "@/components/Telemetry/ArchiveJournal/ArchiveJournalNoContent.vue";
import IconUpload from "@/components/Icons/IconUpload.vue";
import BaseDropdown from "@/components/BaseDropdown.vue";
import {getExcel} from "@/utils/table";
import ArchiveCharts from "@/components/Telemetry/ArchiveJournal/ArchiveCharts.vue";
import BaseSelect from "@/components/BaseSelect.vue";

export default {
  name: "ArchiveJournalPage",
  components: {
    BaseSelect,
    ArchiveCharts,
    BaseDropdown,
    IconUpload,
    ArchiveJournalNoContent,
    IconArrowLinearDown,
    IconRefresh,
    BasePanel,
    IconSort,
    DatePicker
  },
  data() {
    return {
      networkEquipmentId: null,
      range: [],
      group: 'day',
      hours_array: null,
      chartSensors: [],
      defaultValue: new Date(),
      isAscSort: false,
      groupOptions: [
        {
          id: 'momentary',
          name: 'Минутам'
        },
        {
          id: "hour",
          name: 'Часам'
        },
        {
          id: "day",
          name: 'Дням'
        },
        {
          id: "month",
          name: 'Месяцам'
        },
        {
          id: "quarter",
          name: 'Кварталам'
        },
        {
          id: "year",
          name: 'Годам'
        }
      ],
      currentChartType: null,
      showChartDropdown: false
    }
  },
  computed: {
    typeDataInput: function () {
      let type = 'date';
      switch (this.group) {
        case 'momentary':
          type = 'date'
          break;
        case 'hour':
          type = 'date';
          break;
        case 'day':
          type = 'date';
          break;
        case 'month':
          type = 'month';
          break;
        case 'quarter':
          type = 'year';
          break;
        case 'year':
          type = 'year';
          break;
      }
      return type;
    },
    currentParameterChartSeries() {
      const currentChartSeries = this.createParametersChartData()
        .find(item => item.parameterName === this.currentChartType);
      return currentChartSeries ? currentChartSeries.series : []
    },
    isDateTimeXaxis() {
      return this.group === 'momentary' || this.group === 'hour' || this.group === 'day'
    },
    ...mapGetters({
      networkEquipmentName: 'networkEquipments/getNetworkEquipmentName',
      networkEquipmentParameters: 'networkEquipments/getNetworkEquipmentParameters'
    }),
    ...mapState({
      networkEquipments: state => state.networkEquipments.networkEquipments,
      parametersValues: state => state.archivePage.parametersValues,
      loading: state => state.archivePage.loading
    })
  },
  watch: {
    group: function () {
      this.$store.commit("archivePage/SET_PARAMETERS_VALUES", []);
    }
  },
  created() {
    this.fetchData();
  },
  beforeDestroy() {
    this.$store.commit("archivePage/SET_PARAMETERS_VALUES", []);
  },
  methods: {
    getExcel,
    getExcelTable() {
      const rows = [];
      rows.push(['Время']);
      for (let parameter of this.networkEquipmentParameters(this.networkEquipmentId)) {
         rows[rows.length - 1].push(parameter.name);
      }
      for (let i = 0; i < this.hours_array.length; i++) {
        rows.push([]);
        rows[i+1].push(this.hours_array[i]);
        const parameters = this.networkEquipmentParameters(this.networkEquipmentId);

        for (let j = 0; j < parameters.length; j++) {
          const parameterValue = this.getParameterValue(this.hours_array[i], parameters[j].id);
          rows[i+1].push(parameterValue.replace('.', ','));
        }
      }
      this.getExcel(rows);
    },
    resetFilter() {
      this.range = [];
      this.group = 'hour';
      this.hours_array = null;
      this.networkEquipmentId = null;

      this.$store.commit('archivePage/SET_PARAMETERS_VALUES', []);
    },
    fetchData() {
      this.$store.dispatch('networkEquipments/getNetworkEquipments');
    },
    submitFilter() {
      const [startDate, endDate] = this.range;
      endDate.setHours(23)
      endDate.setMinutes(59)

      const params = {
        start_period: this.$moment(startDate).format('DD.MM.YYYY, HH:mm'),
        end_period: this.$moment(endDate).format('DD.MM.YYYY, HH:mm')
      }

      this.$store.dispatch('archivePage/getNetworkEquipmentArchive', {
        networkEquipmentId: this.networkEquipmentId,
        params: params,
        routeGroup: this.group
      })
          .then(() => {

            this.hours_array = Array.from(new Set(this.parametersValues.map(item => item.label)));
            this.setDefaultChartType();
          })

      if (this.group === 'hour') {
        this.$store.dispatch('weather/getWeatherArchive', {
          start: this.$moment(startDate).format('DD.MM.YYYY, HH:mm'),
          end: this.$moment(endDate).format('DD.MM.YYYY, HH:mm')
        })
      }
    },
    sortHoursArray() {
      if (this.isAscSort) {
        this.hours_array = this.hours_array.sort(function (a, b) {
          if (a > b) {
            return -1;
          }
          if (a < b) {
            return 1;
          }
          return 0;
        });
      } else {
        this.hours_array = this.hours_array.sort(function (a, b) {
          if (a > b) {
            return 1;
          }
          if (a < b) {
            return -1;
          }
          return 0;
        });
      }
    },
    setCurrentChartType(type) {
      this.currentChartType = type;
      this.showChartDropdown = false;
    },
    setDefaultChartType() {
      const chartsData = this.createParametersChartData();
      this.currentChartType = chartsData[0].parameterName;
    },
    getParameterValue(dateLabel, parameterId) {
      let item = this.parametersValues.find(item =>
          item.parameterId === parameterId
          && item.label === dateLabel
      );

      if (item) {
        return parseFloat(item.value).toFixed(2);
      } else {
        return '-';
      }
    },
    createParametersChartData: function () {
      return this.networkEquipmentParameters(this.networkEquipmentId)
          .map(parameter => {
            return {
              id: parameter.id,
              parameterName: parameter.name,
              series: [
                {
                  name: parameter.name,
                  type: 'area',
                  data: this.parametersValues
                      .filter(item => item.parameterId === parameter.id)
                      .map(item => {
                        return {
                          x: this.isDateTimeXaxis ? new Date(item.label) : item.label,
                          y: item.value.toFixed(2)
                        }
                      })
                }
              ]
            }
          })
    },
    calculateTableRows(equipment) {
      const equipmentParametersLength = equipment.length + 1 || 2;
      return `repeat(${equipmentParametersLength}, 1fr)`
    },
    toggleChartDropdown() {
      this.showChartDropdown = !this.showChartDropdown;
    }
  }
}
</script>

<style lang="scss">
.apexcharts-tooltip {
  color: var(--text-panel) !important;
  font-size: 12px !important;
  background: var(--bg-sidebar) !important;

  border: none !important;

  .apexcharts-tooltip-title {
    background: var(--bg-sidebar) !important;
    border: none !important;
  }
}


.mx-datepicker {
  position: relative;
  display: block !important;
  width: auto;
}

.active_chart_icon {
  color: var(--status-done);
}

.base-chart {
  position: relative;
  width: 100%;
  padding: 24px;
  margin-bottom: 32px;

  border-radius: 16px;
  border: 1px solid var(--border-main);
  background: var(--panel);

  &__title {
    color: var(--text-primary);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;

    @media (max-width: 767px) {
      gap: 8px;
      cursor: pointer;
    }

    svg {
      display: none;
      @media (max-width: 767px) {
        display: block;
      }

      &.toggle {
        transform: rotate(180deg);
      }
    }

    .tags-select {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  &__dropdown {
    position: absolute;
    top: 24px;
    right: 24px;
    display: none;

    @media (max-width: 767px) {
      display: block;
    }
  }
}

.table-controls {
  display: none;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  @media (max-width: 479px) {
    display: flex;
  }

  &__title {
    color: var(--text-secondary);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  &__control {
    font-size: 16px;
    cursor: pointer;

    svg {
      &.toggle {
        transform: rotate(180deg);
      }
    }
  }
}

#archive-table {
  width: 100%;
  text-align: center
}

.excel-btn {
  float: right;
}

.form__buttons {
  display: flex;
  align-items: center;
  gap: 10px;

  &:last-child {
    display: flex;
    gap: 20px;
  }
}

@media (max-width: 767px) {
  .excel-btn {
    float: left;
    display: block;
    margin-bottom: 20px;
  }
}
</style>