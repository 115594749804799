<template>
  <div class="additional-options-list__inner">
    <div v-for="damage in damagesList"
         :key="damage.id"
         class="added-item added-item--emergency shutdown">
      <div class="added-item__number">
        #{{ damage.id }}
      </div>
      <div class="added-item__info">
        <div class="added-item__name">
          {{ damage.defect.name }}

          <div v-if="damage.isDefectWithWormholes"
               class="added-item__name--inline">
            {{ damage.defectParams.wormhole_count }} шт.
          </div>
          <div v-else-if="damage.isDefectWithFlaw || damage.isDefectWithBreak"
               class="added-item__name--inline">
            {{ damage.defectParams.width }}x{{ damage.defectParams.height }}мм
          </div>
          <div v-else-if="damage.isDefectWithHole"
               class="added-item__name--inline">
            Ø{{ damage.defectParams.diameter }}мм
          </div>
        </div>
        <div class="added-item__additional">
          <div class="added-item__additional-time">
            {{ formatDateTime(damage.startDate) }}
          </div>
          <div
            v-if="damage.endDate"
            class="added-item__additional-time">-
          </div>
          <div class="added-item__additional-time">
            {{ formatDateTime(damage.endDate) }}
          </div>
        </div>
      </div>
      <div class="added-item__right">
        <div @click.stop="showModal(damage)">
          <icon-pencil class="svg added-item__tool added-item__tool--size-20"/>
        </div>
      </div>
    </div>

    <div
      @click="showModal()"
      class="button button--mode-outline">
      <icon-plus/>
      <span>Добавить новое</span>
    </div>
  </div>
</template>

<script>
import IconPlus from "@/components/Icons/IconPlus.vue";
import IconPencil from "@/components/Icons/IconPencil.vue";
import DamagesForm from "@/components/ServiceDesk/Issues/IssueComponents/Damages/DamagesForm.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import {showAlert, showToast} from "@/utils/notification";
import {formatDateTime} from "@/utils/filters";

export default {
  name: "DamagesList",
  components: {IconPencil, IconPlus},
  props: {
    issueId: {
      type: Number,
      required: true
    },
    damagesList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      popupName: 'damages-form'
    }
  },
  methods: {
    formatDateTime,
    showModal(damage = {}) {
      this.$modal.show(
        DamagesForm,
        {
          'damage': damage,
          'popupName': this.popupName,
          'createDamage': this.createDamage,
          'updateDamage': this.updateDamage
        },
        {
          name: this.popupName,
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(1144),
          scrollable: true
        },
        {
          'before-close': this.$store.commit('networkElements/SET_ELEMENTS', [])
        }
      )
    },
    createDamage(payload) {
      this.$store.dispatch('damages/createDamage', {
        issueId: this.issueId,
        payload
      })
        .then(() => {
          this.$store.dispatch('issueModal/getDamagesList', this.issueId);
          this.$modal.hide(this.popupName);
          showToast('Повреждение добавлено', 'success');
        })
        .catch(error => showAlert('Ошибка', error));
    },
    updateDamage(damageId, payload) {
      this.$store.dispatch('damages/updateDamage', {
        issueId: this.issueId,
        damageId,
        payload
      })
        .then(() => {
          this.$store.dispatch('issueModal/getDamagesList', this.issueId);
          this.$modal.hide(this.popupName);
          showToast('Повреждение обновлено', 'success');
        })
        .catch(error => showAlert('Ошибка', error));
    }
  }
}
</script>

<style scoped>

</style>