<template>
  <div class="i-modal__content-wrapper">
    <div class="i-modal__content">
      <div v-if="Object.keys(equipment).length"
           class="i-modal__inner">
        <div class="base-modal__left-side base-modal__left-side--hidden">
          <div class="base-modal-header base-modal-header--padding-s">
            <span class="base-modal-title">{{equipment.typeName}} - {{equipment.name}}</span>
          </div>

          <div class="base-modal-info base-modal-info--gap-24 base-modal-info--padding-s">
            <div class="base-modal-info__card">
              <div class="base-modal-info__card-title base-modal-info__card-title--loader">
                Детальная информация
              </div>

              <div class="base-modal-info__item">
                <div class="base-modal-info__name">
                  <span>Тип</span>
                </div>
                <div class="base-modal-info__value">
                  <p class="base-modal-info__value-txt">{{ equipment.typeName }}</p>
                </div>
              </div>

              <div class="base-modal-info__item">
                <div class="base-modal-info__name">
                  <span>Серийны номер</span>
                </div>
                <div class="base-modal-info__value">
                  <p class="base-modal-info__value-txt">{{ nullFilter(equipment.serial) }}</p>
                </div>
              </div>

              <div class="base-modal-info__item">
                <div class="base-modal-info__name">
                  <span>Инвентарный номер</span>
                </div>
                <div class="base-modal-info__value">
                  <p class="base-modal-info__value-txt">{{ equipment.inventoryNumber }}</p>
                </div>
              </div>

              <div class="base-modal-info__item">
                <div class="base-modal-info__name">
                  <span>Срок службы (дней)</span>
                </div>
                <div class="base-modal-info__value">
                  <p class="base-modal-info__value-txt">{{ equipment.durability }}</p>
                </div>
              </div>

              <div class="base-modal-info__item">
                <div class="base-modal-info__name">
                  <span>Дата изготовления</span>
                </div>
                <div class="base-modal-info__value">
                  <p class="base-modal-info__value-txt">{{ equipment.buildDate }}</p>
                </div>
              </div>

              <div class="base-modal-info__item">
                <div class="base-modal-info__name">
                  <span>Дата установки</span>
                </div>
                <div class="base-modal-info__value">
                  <p class="base-modal-info__value-txt">{{ equipment.installationDate }}</p>
                </div>
              </div>

              <div class="base-modal-info__item">
                <div class="base-modal-info__name">
                  <span>Тип расположения</span>
                </div>
                <div class="base-modal-info__value">
                  <p class="base-modal-info__value-txt">{{ equipment.locationTypeName }}</p>
                </div>
              </div>

            </div>

            <div class="base-modal-info__item base-modal-info__item--options">
              <equipment-parameters-list
                :equipment-id="equipment.id"
                :parameters-list="equipment.parameters"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="Object.keys(equipment).length"
         class="i-modal-btn-wp i-modal-btn-wp--back">
      <div class="task-modal__mobile-actions">
        <button @click="$store.dispatch('baseSideModal/closeModal')"
                class="button button--mode-outline task-modal__mobile-back">
          <icon-arrow-left/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrowLeft from "@/components/Icons/IconArrowLeft.vue";
import {mapState} from "vuex";
import EquipmentParametersList from "@/components/Telemetry/admin/NetworkObject/EquipmentParametersList.vue";
import {nullFilter} from "@/utils/filters";

export default {
  name: "EquipmentModalContent",
  components: {EquipmentParametersList, IconArrowLeft},
  computed: {
    ...mapState({
      equipment: state => state.networkObjectPage.equipment
    })
  },
  methods: {
    nullFilter
  }
}
</script>

<style scoped>

</style>