<template>
  <div
    class="spinner-border mr-2"
    :class="colorClass"
    role="status"
    :style="{width: width, height: height}"
  >
    <span class="sr-only">Загрузка...</span>
  </div>
</template>

<script>
export default {
  name: "BaseLoader",
  props: {
    width: {
      default: '1.5rem !important',
      type: String
    },
    height: {
      default: '1.5rem !important',
      type: String
    },
    colorClass: {
      default: 'color-loader',
      type: String
    }
  }
}
</script>

<style scoped>
.color-loader {
  color: var(--text-link)!important;
}

.color-white {
  color: var(--text-panel)
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}
</style>
