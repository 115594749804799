<template>
  <div class="role-permissions">
    <div class="role-permissions__title">Разрешения</div>
    <div class="role-permissions__list">
      <label
        v-for="permission in permissionsList"
        :key="permission.id"
        class="base-checkbox base-checkbox--squared">
        <input
          v-model="rolePermissions"
          :value="permission.id"
          :disabled="loading"
          @change="toggleRolePermission(permission.id, $event.target.checked)"
          type="checkbox">
        <span>{{ permission.customName ? permission.customName : permission.name }}</span>
        <div class="role-permissions__item-icon">
          <icon-questions v-if="permission.description"/>
          <div class="role-permissions__item-popover">
            {{permission.description}}
          </div>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {showToast} from "@/utils/notification";
import IconQuestions from "@/components/Icons/IconQuestions.vue";

export default {
  name: "RolesPermissionsList",
  components: {IconQuestions},
  props: {
    roleId: {
      type: Number,
      required: true
    },
    rolePermissionsList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      rolePermissions: [],
      loading: false
    }
  },
  computed: {
    ...mapState({
      permissionsList: state => state.user.permissions
    })
  },
  watch: {
    rolePermissionsList: function (value) {
      this.rolePermissions = value.map(item => item.id);
    }
  },
  mounted() {
    this.$store.dispatch('user/getPermissions');
    this.rolePermissions = this.rolePermissionsList.map(item => item.id);
  },
  methods: {
    toggleRolePermission(id, checked) {
      this.loading = true;
      if (checked) {
        this.$store.dispatch('role/assignPermission', {
          roleId: this.roleId,
          permissionId: id
        })
          .then(() => showToast('Разрешение добавлено', 'success'))
          .finally(() => {
            this.loading = false;
            this.$store.dispatch('role/getRoles');
          })
      } else {
        this.$store.dispatch('role/removePermission', {
          roleId: this.roleId,
          permissionId: id
        })
          .then(() => showToast('Разрешение удалено', 'success'))
          .finally(() => {
            this.loading = false;
            this.$store.dispatch('role/getRoles');
          })
      }

    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.role-permissions {
  width: 100%;

  &__title {
    margin-bottom: 24px;
    @include font-style(500, 20px, 28px, var(--text-primary))
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  & .base-checkbox {
    display: flex;
    align-items: center;
    gap: 8px;

    & span {
      gap: 8px;
    }
  }

  &__item-icon {
    position: relative;

    &:hover .role-permissions__item-popover{
      display: block;
    }
  }

  &__item-popover {
    display: none;

    position: absolute;
    width: 200px;
    padding: 16px;

    border-radius: 16px;
    background: rgba(38, 47, 61, 0.70);

    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(5px);

    @include font-style(400, 12px, 14px, var(--text-panel));
  }
}
</style>