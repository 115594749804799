import Geometry from "@/models/Geometry";

export default class EmergencyElement {
    constructor(id, attributes) {
        this.id = id;
        this.elementType = attributes.element_type;
        this.semanticSource = attributes.semantic_source;
        this.networkElementId = attributes.network_element;
        const {type, coordinates} = attributes.geometry;
        this.geometry = new Geometry(type, coordinates);
        this.damage = attributes.damage;
        this.params = attributes.params;
    }

    getCaption() {
        return `${this.elementType.name} #${this.id}`
    }


}
