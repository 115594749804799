<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="20"
       height="20"
       viewBox="0 0 20 20"
       fill="none">
    <path
      d="M6.8325 16.2542H6.38385C2.79462 16.2542 1 15.3569 1 10.8704V6.38385C1 2.79462 2.79462 1 6.38385
      1H13.5623C17.1515 1 18.9462 2.79462 18.9462 6.38385V10.8704C18.9462 14.4596 17.1515 16.2542 13.5623
       16.2542H13.1137C12.8355 16.2542 12.5663 16.3888 12.3958 16.6132L11.0499 18.4078C10.4576 19.1974 9.48853
        19.1974 8.89631 18.4078L7.55035 16.6132C7.40678 16.4158 7.07478 16.2542 6.8325 16.2542Z"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path d="M5.48438 6.38281H14.4575M5.48438 10.8694H10.8682"
          stroke="#9192AD"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconComment"
}
</script>

<style scoped>

</style>
