<template>
  <div
    class="map-marker">
    <map-icon-bg/>
    <div class="map-marker__inner">
      <div
        class="map-marker__img"
        :class="`map-marker__img--bg-${actualLocationStatus}`">
        <icon-user-octagon/>
      </div>
    </div>
  </div>
</template>

<script>
import MapIconBg from "@/components/Icons/MapIconBg.vue";
import IconUserOctagon from "@/components/Icons/IconUserOctagon.vue";

export default {
  name: "ResourceIcon",
  components: {IconUserOctagon, MapIconBg},
  props: {
    actualLocationStatus: {
      type: String,
      default: 'danger'
    }
  }
}
</script>

<style lang="scss" scoped>
.map-marker__img {
  & svg {
    width: 21px;
    height: 21px;
  }
}
</style>