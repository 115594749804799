<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="24"
       height="24"
       viewBox="0 0 24 24"
       fill="none">
    <path
      d="M12.8301 3.18899C12.6733 3.06652 12.4801 3 12.2811 3C12.0822 3 11.8889 3.06652 11.7321 3.18899C10.0221
       4.49399 4.97311 8.75099 5.00011 13.71C5.00011 17.724 8.26711 21 12.2901 21C16.3131 21 19.5801 17.733 19.5801
        13.719C19.5891 8.83199 14.5311 4.50299 12.8301 3.18899Z"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-miterlimit="10"/>
  </svg>
</template>

<script>
export default {
  name: "IconDrop"
}
</script>

<style scoped>

</style>
