<template>
  <apexchart
    ref="chartComponent"
    type="bar"
    height="350"
    :options="chartOptions"
    :series="columnChartData"
  />
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  name: "ColumnChart",
  components: {'apexchart': VueApexCharts},
  props: ['columnChartData', 'labels'],
  data () {
    return {
      chartOptions: {
        legend: {
          show: true,
          markers: {
            fillColors: ['#0A6187', '#A4D9F6']
          }
        },
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
            borderRadius: 5
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: this.labels
        },
        yaxis: {
          title: {
            text: 'Баланс'
          }
        },
        fill: {
          opacity: 1,
          colors: ['#0A6187', '#A4D9F6'],
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: "vertical",
            shadeIntensity: 0.3,
            gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
            colorStops: []
          }
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " м<sup>3</sup>"
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>