<template>
  <div class="appeal-actions">
    <div @click="openCreateIssueModal({
           relatedAppealId: appealId
         })"
         class="button button--mode-primary">
      <span>Создать новую заявку</span>
    </div>

    <div @click="showLinkAppealModal()"
         class="button button--mode-outline">
      <span>Связать с заявкой</span>
    </div>
  </div>
</template>

<script>
import LinkAppealForm from "@/components/ServiceDesk/Issues/LinkAppealForm";
import {modalAdaptiveWidth} from "@/utils/helpers";
import sideModalControls from "@/mixins/sideModalControls";

export default {
  name: "AppealActions",
  mixins: [sideModalControls],
  props: {
    appealId: {
      type: Number,
      required: true
    }
  },
  methods: {
    showLinkAppealModal() {
      this.$modal.show(
        LinkAppealForm,
        {
          'appeal-id': this.appealId
        },
        {
          name: 'link-appeal-modal',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(600),
          scrollable: true
        }
      )
    }
  }
}
</script>

<style scoped>
.appeal-actions {
  display: flex;
  gap: 12px;
}

.appeal-actions .button {
  flex: 1;
}
</style>
