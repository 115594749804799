import * as API from './API'
import EmergencyElement from "@/models/serviceDesk/EmergencyElement";
import {getError} from "@/utils/helpers";

export default {
    getEmergencyElements(issue_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/issues/${issue_id}/emergency-elements`)
                .then(response => resolve(
                    response.data.data.map(element => new EmergencyElement(element.id, element.attributes))
                ))
                .catch(error => reject(getError(error)));
        })
    },
    createEmergencyElement({issue_id, payload}) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`service-desk/issues/${issue_id}/emergency-elements`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    updateEmergencyElement({issue_id, element_id, payload}) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`service-desk/issues/${issue_id}/emergency-elements/${element_id}`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    deleteEmergencyElement(issue_id, element_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`service-desk/issues/${issue_id}/emergency-elements/${element_id}`)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}
