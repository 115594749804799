<template>
  <div class="wrapper temp-bg-color"
       :class="[{'sidebar-blur': getIsActiveSidebar}, {'modal-blur': getIsActiveModal}]">
    <the-sidebar />
    <div class="page-blur"/>
    <div class="main"
         :class="{'page-layout': getIsActiveSidebar}">
      <base-side-modal />
      <header class="header">
        <div class="header__menu">
          <div class="header__menu-btn"
               @click="isToggled">
            <div class="header-menu__nav-icon"
                 :class="{'open': !getIsActiveSidebar}">
              <span/>
            </div>
          </div>
          <div class="header-menu__logo">
            <router-link to="/">
              <img :class="`${getProjectImageUrlClassPosition}`"
                   :src="`${getProjectImageUrl}`"
                   alt="logo">
            </router-link>
          </div>
          <div class="header-menu__notifications">
            <div class="header-menu__notifications-note"/>
            <icon-notify/>
          </div>
        </div>
      </header>
      <main class="content">
        <slot/>
      </main>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

import TheSidebar from "@/layout/TheSidebar";
import IconNotify from "@/components/Icons/IconNotify.vue";
import BaseSideModal from "@/components/BaseSideModal.vue";

export default {
  name: "MainTemplate",
  components: {BaseSideModal, IconNotify, TheSidebar},
  computed: {
    ...mapGetters({
      getIsActiveSidebar: 'sidebar/getIsActiveSidebar',
      getIsActiveModal: 'modal/getIsActiveModal',
      getProjectImageUrl: 'projectConfig/getProjectImageUrl',
      getProjectImageUrlClassPosition: "projectConfig/getProjectImageUrlClassPosition"

    }),
    isDashboard() {
      return this.$route.name === 'DashboardPage'
    }
  },
  methods: {
    isToggled() {
      this.$store.dispatch("sidebar/togledSidebar")
    }
  }
}
</script>

<style scoped>
</style>
