<template>
  <div class="additional-option-wrapper"
       :class="{'always-open': isCollapseVisible}">
    <div class="additional-option additional-option--no-icon">
      <div class="additional-option__name"><span>Семантические источники</span><span
        class="additional-option__quantity">{{ semanticSourcesList.length }}</span>
      </div>
      <div class="additional-option__buttons">
        <button
          class="additional-option__add"
          type="button"
          @click="showAddSemanticSourceModal()">
          <icon-plus/>
        </button>
        <button
          @click="isCollapseVisible = !isCollapseVisible"
          class="additional-option__toggle"
          :class="{'rotate': isCollapseVisible}">
          <icon-arrow-double-up/>
        </button>
      </div>
    </div>

    <div class="additional-options-list">
      <div class="additional-options-list__inner">
        <div v-for="semanticSource in semanticSourcesList"
             :key="semanticSource.id"
             class="added-item added-item--flex">
          <div class="added-item__info">
            <div class="added-item__name">{{ semanticSource.attributes.resource_name }}</div>
            <div class="added-item__additional">{{ semanticSource.attributes.provider.name }}</div>
          </div>
          <div
            class="added-item__right"
            @click="deleteSemanticSource(semanticSource.id)">
            <icon-trash class="svg added-item__tool"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconTrash from "@/components/Icons/IconTrash.vue";
import IconPlus from "@/components/Icons/IconPlus.vue";
import IconArrowDoubleUp from "@/components/Icons/IconArrowDoubleUp.vue";
import {showAlert, showToast, showConfirmationModal} from "@/utils/notification";
import LayerSemanticSourceCreateModal
  from "@/components/EngineeringNetworks/MapLayers/LayerSemanticSourceCreateModal.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import {mapState} from "vuex";

export default {
  name: "LayerSemanticSourcesList",
  components: {IconArrowDoubleUp, IconPlus, IconTrash},
  props: {
    layerId: {
      type: Number,
      required: true
    },
    semanticSourcesList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isCollapseVisible: false
    }
  },
  computed: {
    ...mapState({
      semanticProvidersList: state => state.semanticProviders.semanticProvidersList
    })
  },
  methods: {
    showAddSemanticSourceModal() {
      this.$modal.show(
        LayerSemanticSourceCreateModal,
        {
          'semanticProvidersList': this.semanticProvidersList,
          'createSemanticSource': this.createSemanticSource
        },
        {
          name: 'semantic-source-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        }
      )
    },
    createSemanticSource(payload) {
      this.$store.dispatch('layer/createSemanticSource', {
        layerId: this.layerId,
        payload
      })
        .then(() => {
          this.$store.dispatch('layer/getLayer', this.layerId);
          this.$modal.hide('semantic-source-form');
          showToast('Семантический источник добавлен', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    deleteSemanticSource(semanticSourceId) {
      showConfirmationModal('Вы уверены, что хотите удалить семантический источник? Это действие невозвратно.')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('layer/deleteSemanticSource', {
              layerId: this.layerId,
              semanticSourceId
            })
              .then(() => {
                this.$store.dispatch('layer/getLayer', this.layerId);
                showToast('Семантический источник удален', 'success');
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style scoped>

</style>