<script>
export default {
  name: "IconRowVertical"
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="18"
       height="18"
       viewBox="0 0 18 18"
       fill="none">
    <path
      d="M15.32 10.2H2.68C1.48 10.2 1 10.712 1 11.984V15.216C1 16.488 1.48 17 2.68 17H15.32C16.52 17 17 16.488 17
      15.216V11.984C17 10.712 16.52 10.2 15.32 10.2ZM15.32 1H2.68C1.48 1 1 1.512 1 2.784V6.016C1 7.288 1.48 7.8
      2.68 7.8H15.32C16.52 7.8 17 7.288 17 6.016V2.784C17 1.512 16.52 1 15.32 1Z"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>

<style scoped>

</style>