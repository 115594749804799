import MechanicalEquipmentsService from "@/services/MechanicalEquipmentsService";
import {getError} from "@/utils/helpers";

export const namespaced = true;

export const state = {
    mechanicalEquipments: [],
    fuelTypes: [],
    oilTypes: [],
    meta: {},
    page: 1,
    loading: false,
    error: null
}

export const mutations = {
    SET_MECHANICAL_EQUIPMENTS(state, equipments) {
        state.mechanicalEquipments = equipments;
    },
    SET_FUEL_TYPES(state, types) {
        state.fuelTypes = types;
    },
    SET_OIL_TYPES(state, types) {
        state.oilTypes = types;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_PAGE(state, page) {
        state.page = page;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    DELETE_MECHANICAL_EQUIPMENT(state, equipmentId) {
        const index = state.mechanicalEquipments.findIndex(equipment => equipment.id === equipmentId);
        state.mechanicalEquipments.splice(index, 1);
    }
}

export const actions = {
    getMechanicalEquipments({commit, state}) {
        commit("SET_LOADING", true);
        MechanicalEquipmentsService.fetchMechanicalEquipments(state.page)
            .then(({equipments, meta}) => {
                commit("SET_MECHANICAL_EQUIPMENTS", equipments);
                commit("SET_META", meta);
            })
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getAllMechanicalEquipments({commit}) {
        MechanicalEquipmentsService.fetchAllMechanicalEquipments()
            .then(equipments => commit("SET_MECHANICAL_EQUIPMENTS", equipments))
            .catch(error => commit("SET_ERROR", error));
    },
    createMechanicalEquipment({commit}, payload) {
        return new Promise((resolve, reject) => {
            MechanicalEquipmentsService.createMechanicalEquipment(payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", getError(error));
                    reject(getError(error));
                });
        })
    },
    updateMechanicalEquipment({commit}, {id, payload}) {
        return new Promise((resolve, reject) => {
            MechanicalEquipmentsService.updateMechanicalEquipment(id, payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", getError(error));
                    reject(getError(error));
                });
        })
    },
    deleteMechanicalEquipment({commit}, id) {
        return new Promise((resolve, reject) => {
            MechanicalEquipmentsService.deleteMechanicalEquipment(id)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", getError(error));
                    reject(getError(error));
                });
        })
    },
    getFuelTypes({commit}) {
        MechanicalEquipmentsService.fetchFuelTypes()
            .then(fuelTypes => commit("SET_FUEL_TYPES", fuelTypes))
            .catch(error => commit("SET_ERROR", error));
    },
    getOilTypes({commit}) {
        MechanicalEquipmentsService.fetchOilTypes()
            .then(oilTypes => commit("SET_OIL_TYPES", oilTypes))
            .catch(error => commit("SET_ERROR", error));
    }
}

export const getters = {
    getMechanicalEquipments: state => {
        return state.mechanicalEquipments;
    },
    getMechanicalEquipment: state => id => {
        return state.mechanicalEquipments.find(item => item.id === parseInt(id));
    }
}
