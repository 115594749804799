import * as API from './API'
import {getError} from "@/utils/helpers";

export default {
    fetchIssuesStatistic(filter) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/statistic/issues`, {params: filter})
                .then(response => resolve(response.data.data))
                .catch(error => reject(getError(error)));
        })
    }
}
