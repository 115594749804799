import * as API from './API';
import Subscription from "@/models/Subscription";
import {getError} from "@/utils/helpers";
import UserSubscription from "@/models/UserSubscription";

export default {
    fetchSubscriptionsList() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/subscriptions/types`)
                .then(response => resolve(response.data.data.map(item => new Subscription(item))))
                .catch(error => reject(getError(error)))
        })
    },
    fetchUserSubscriptions(userId) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/subscriptions`, {params: {user_id: userId}})
                .then(response => resolve(response.data.data.map(item => new UserSubscription(item))))
                .catch(error => reject(getError(error)))
        })
    },
    createSubscription(payload) {
        return API.apiClient.post(`/subscriptions`, payload);
    },
    deleteSubscription(subscriptionId) {
        return API.apiClient.delete(`/subscriptions/${subscriptionId}`);
    }
}