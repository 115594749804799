import { render, staticRenderFns } from "./TransportSpeed.vue?vue&type=template&id=36e67c24&scoped=true&"
import script from "./TransportSpeed.vue?vue&type=script&lang=js&"
export * from "./TransportSpeed.vue?vue&type=script&lang=js&"
import style0 from "./TransportSpeed.vue?vue&type=style&index=0&id=36e67c24&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36e67c24",
  null
  
)

export default component.exports