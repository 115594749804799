<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none">
    <g clip-path="url(#clip0_5044_1128)">
      <path
        d="M4.9518 16.9413V18.353L6.83822 18.8236L8.48883 18.353L8.96049 17.6471L8.48883 16.9413L6.79106
        16.4707L4.9518 16.9413Z"
        fill="#262F3D"/>
      <path d="M12.0259 16.9413L10.2102 16.4707L8.48888 16.9413V18.3531L10.2574 18.8236L12.0259 18.3531V16.9413Z"
            fill="#262F3D"/>
      <path d="M2.82966 18.353V24.0001H8.48893L8.96057 21.0197L8.48893 18.353H2.82966Z"
            fill="#262F3D"/>
      <path d="M8.48898 18.353H14.1483V24.0001H8.48898V18.353Z"
            fill="#262F3D"/>
      <path d="M23.3445 2.82373V12.0002L21.9297 12.4708L20.5149 12.0002V2.82373H23.3445Z"
            fill="#E19421"/>
      <path
        d="M13.8402 6.7039L15.9765 3.29276C16.1593 3.00082 16.4796 2.82353 16.824 2.82353H20.5149L21.9297
        0H15.9628C15.2739 0 14.6335 0.354538 14.2678 0.938366L11.3186 5.64706L13.8402 6.7039Z"
        fill="#E19421"/>
      <path
        d="M21.9297 3.52941C22.9065 3.52941 23.6982 2.73933 23.6982 1.7647C23.6982 0.790086 22.9065 0 21.9297
        0C20.9529 0 20.1612 0.790086 20.1612 1.7647C20.1612 2.73933 20.9529 3.52941 21.9297 3.52941Z"
        fill="#262F3D"/>
      <path
        d="M20.5149 12C20.2791 13.4118 19.6659 14.8706 18.4869 16.2824L21.9696 16.3146C23.3345 16.3449 24.3218
         15.023 23.9026 13.7265L23.3445 12H20.5149Z"
        fill="#262F3D"/>
      <path
        d="M14.8556 11.2941L14.1482 11.7646L13.4407 11.2941L8.48887 7.05874L8.09588 6.35286L8.48887
        5.64697H11.3185C13.2689 5.64697 14.8556 7.23026 14.8556 9.1764V11.2941Z"
        fill="#262F3D"/>
      <path
        d="M13.4408 9.17626C13.4408 8.00857 12.4887 7.05859 11.3186 7.05859H8.4889L8.0173 9.88212L8.4889
        11.2939H13.4408V9.17626Z"
        fill="#FFEDD3"/>
      <path
        d="M8.48885 9.88226L7.16836 10.3528L5.65922 9.88226V7.64697C5.65922 6.5424 6.55465 5.64697 7.65922
         5.64697H8.48885V9.88226Z"
        fill="#262F3D"/>
      <path
        d="M8.48892 9.88232H4.12224C3.01767 9.88232 2.12224 10.7778 2.12224 11.8823V12.7058L2.59385
        13.4412L2.12224 14.1176V15.5294L5.2796 16L8.48892 15.5294L9.19629 13.4118L8.48892 11.2941V9.88232Z"
        fill="#E19421"/>
      <path
        d="M14.8556 11.2939H8.48888V15.5293L11.6722 15.9998L14.8556 15.5293V14.1175L14.384 13.3645L14.8556
        12.7057V11.2939Z"
        fill="#FFC670"/>
      <path
        d="M2.12224 15.5293H8.48892V16.9411H3.53404C2.75433 16.9411 2.12224 16.309 2.12224 15.5293Z"
        fill="#FFC670"/>
      <path
        d="M8.48888 15.5293H14.8556C14.8556 16.309 14.2235 16.9411 13.4438 16.9411H8.48888V15.5293Z"
        fill="#E19421"/>
      <path
        d="M5.2795 19.7646V22.5882H8.48888L8.96049 21.3176L8.48888 19.7646H5.2795Z"
        fill="white"/>
      <path
        d="M8.48888 19.7646H11.6983V22.5882H8.48888V19.7646Z"
        fill="white"/>
      <path
        d="M2.82963 24.0001C4.39239 24.0001 5.65927 22.7359 5.65927 21.1765C5.65927 19.6171 4.39239 18.353 2.82963
         18.353C1.26687 18.353 0 19.6171 0 21.1765C0 22.7359 1.26687 24.0001 2.82963 24.0001Z"
        fill="#262F3D"/>
      <path
        d="M2.82964 22.5882C3.61103 22.5882 4.24446 21.9561 4.24446 21.1764C4.24446 20.3967 3.61103 19.7646 2.82964
         19.7646C2.04826 19.7646 1.41483 20.3967 1.41483 21.1764C1.41483 21.9561 2.04826 22.5882 2.82964 22.5882Z"
        fill="white"/>
      <path
        d="M14.1482 24.0001C15.7109 24.0001 16.9778 22.7359 16.9778 21.1765C16.9778 19.6171 15.7109 18.353 14.1482
         18.353C12.5854 18.353 11.3186 19.6171 11.3186 21.1765C11.3186 22.7359 12.5854 24.0001 14.1482 24.0001Z"
        fill="#262F3D"/>
      <path
        d="M14.1482 22.5882C14.9295 22.5882 15.563 21.9561 15.563 21.1764C15.563 20.3967 14.9295 19.7646 14.1482
        19.7646C13.3668 19.7646 12.7334 20.3967 12.7334 21.1764C12.7334 21.9561 13.3668 22.5882 14.1482 22.5882Z"
        fill="white"/>
      <path
        d="M13.4408 13.412C13.4408 13.0221 13.7568 12.7061 14.1467 12.7061H14.8556V14.1179H14.1467C13.7568 14.1179
         13.4408 13.8018 13.4408 13.412Z"
        fill="#F6F9FC"/>
      <path
        d="M2.12224 12.7061H2.83116C3.22102 12.7061 3.53706 13.0221 3.53706 13.412C3.53706 13.8018 3.22102 14.1179
         2.83116 14.1179H2.12224V12.7061Z"
        fill="#F6F9FC"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconSpecialEquipment"
}
</script>

<style scoped>

</style>
