<script>
export default {
  name: "IconChart"
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
    <path d="M5.00098 7.16V9.29334" stroke="#9192AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.33398 6V10.4533" stroke="#9192AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.667 7.16V9.29334" stroke="#9192AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.33366 14.6667H10.3337C13.667 14.6667 15.0003 13.3333 15.0003 10V6.00001C15.0003 2.66668 13.667 1.33334 10.3337 1.33334H6.33366C3.00033 1.33334 1.66699 2.66668 1.66699 6.00001V10C1.66699 13.3333 3.00033 14.6667 6.33366 14.6667Z" stroke="#9192AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<style scoped>

</style>