import TechnicalInspectionTasksService from "@/services/TechnicalInspectionTasksService";
import NetworkObjectsService from "@/services/NetworkObjectsService";

export const namespaced = true;

export const state = {
    tasksList: [],
    task: null,
    answersList: [],
    error: null,
    loading: false,
    taskLoading: false,
    history: [],
    objectsList: [],
    violationsList: [],
    query: ''
}

export const mutations = {
    SET_TASKS_LIST(state, tasks) {
        state.tasksList = tasks;
    },
    SET_TASK(state, task) {
        state.task = task;
    },
    SET_ANSWERS_LIST(state, list) {
        state.answersList = list;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_QUERY(state, query) {
        state.query = query;
    },
    SET_OBJECTS_LIST(state, objects) {
        state.objectsList = objects;
    },
    SET_TASK_LOADING(state, loading) {
        state.taskLoading = loading;
    },
    SET_HISTORY(state, history) {
        state.history = history;
    },
    SET_VIOLATIONS_LIST(state, violations) {
        state.violationsList = violations;
    }
}

export const actions = {
    getTasksList({commit}) {
        commit("SET_LOADING", true);
        TechnicalInspectionTasksService.fetchTasksList()
            .then(tasksList => commit("SET_TASKS_LIST", tasksList))
            .catch(error => commit('SET_ERROR', error))
            .finally(() => commit("SET_LOADING", false))
    },
    async getTaskData({dispatch, commit}, id) {
        commit("SET_TASK_LOADING", true);

        await Promise.all([
            dispatch('getTask', id)
                .then(task => dispatch('questions/getQuestionsList', task.objectType.id, {root: true})),
            dispatch('getTaskHistory', id),
            dispatch('getTaskAnswersList', id),
            dispatch('getTaskViolations', id)
        ]).finally(() => commit("SET_TASK_LOADING", false))
    },
    getTask({commit}, id) {
        return new Promise((resolve, reject) => {
            TechnicalInspectionTasksService.fetchTask(id)
                .then(task => {
                    commit("SET_TASK", task);
                    resolve(task)
                })
                .catch(error => reject(error))
        })
    },
    createTask(context, payload) {
        return new Promise((resolve, reject) => {
            TechnicalInspectionTasksService.createTask(payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    returnToWorkTask(context, id) {
        return new Promise((resolve, reject) => {
            TechnicalInspectionTasksService.returnToWorkTask(id)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    finishTask(context, id) {
        return new Promise((resolve, reject) => {
            TechnicalInspectionTasksService.finishTask(id)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    cancelTask(context, id) {
        return new Promise((resolve, reject) => {
            TechnicalInspectionTasksService.cancelTask(id)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    getObjectsList({commit}, bounds) {
        NetworkObjectsService.fetchNetworkObjectsByBounds(bounds)
            .then(objectsList => commit("SET_OBJECTS_LIST", objectsList))
            .catch(error => commit("SET_ERROR", error))
    },
    getTaskHistory({commit}, id) {
        TechnicalInspectionTasksService.fetchTaskHistory(id)
            .then(history => commit("SET_HISTORY", history))
            .catch(error => commit("SET_ERROR", error))
    },
    getTaskAnswersList({commit}, id) {
        TechnicalInspectionTasksService.fetchTaskAnswers(id)
            .then(answersList => commit("SET_ANSWERS_LIST", answersList))
            .catch(error => commit("SET_ERROR", error))
    },
    getTaskViolations({commit}, id) {
        TechnicalInspectionTasksService.fetchTaskViolationsList(id)
            .then(violationsList => commit("SET_VIOLATIONS_LIST", violationsList))
            .catch(error => commit("SET_ERROR", error))
    }
}

export const getters = {
    getTasksListByQueryAndState: state => taskStates => {
        let taskList = state.tasksList;

        if (state.query.length) taskList = taskList.filter(task => task.object.address.toLowerCase()
            .includes(state.query.toLowerCase()));
        if (taskStates.length) taskList = taskList.filter(task => taskStates.includes(task.state.id));

        return taskList;
    },
    getAnswerByQuestionId: state => questionId => {
        const answer = state.answersList.find(answer => answer.questionId === questionId);
        return answer ? answer : null;
    }
}