import * as API from './API';
import ShutdownNotification from "@/models/serviceDesk/ShutdownNotification";
import {getError} from "@/utils/helpers";

export default {
    fetchShutdownNotificationsList() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/shutdown-notifications`)
                .then(response => resolve(response.data.data
                    .map(item => new ShutdownNotification({id: item.id, ...item.attributes}))))
                .catch(error => reject(getError(error)))
        })
    },
    fetchShutdownNotification(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/shutdown-notifications/${id}`)
                .then(response => {
                    const {id, attributes} = response.data.data;
                    resolve(new ShutdownNotification({id: id, attributes}));
                })
                .catch(error => reject(getError(error)));
        })
    },
    createShutdownNotification(payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`service-desk/shutdown-notifications`, payload)
               .then(() => resolve())
               .catch(error => reject(getError(error)));
        })
    },
    updateShutdownNotification(id, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`service-desk/shutdown-notifications/${id}`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    deleteShutdownNotification(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`service-desk/shutdown-notifications/${id}`)
               .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    fetchAddressesByCoordinates(payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`/service-desk/shutdown-notifications/addresses`, payload)
                .then(response => resolve(response.data))
                .catch(error => reject(getError(error)))
        })
    }
}