<template>
  <div class="page-wrapper">
    <base-panel
      title="Сервера данных транспорта"
      :is-add-button-visible="true"
      add-button-name="Добавить"
      @add-button-clicked="showPopup()"
    />

    <transport-data-servers-list
      v-if="transportDataServers.length"
      :transport-data-servers-list="transportDataServers"
      @edit-transport-data-server="showPopup"
      @delete-transport-data-server="deleteTransportDataServer"
    />

    <base-content-loaders v-if="loading && !transportDataServers.length" />
  </div>
</template>

<script>
import TransportDataServersList from "@/components/Transport/admin/TransportDataServersList";
import BasePanel from "@/components/BasePanel.vue";
import {mapState} from "vuex";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import {showConfirmationModal, showAlert, showToast} from "@/utils/notification";
import {modalAdaptiveWidth} from "@/utils/helpers";
import TransportDataServerForm from "@/components/Transport/admin/TransportDataServerForm.vue";
import {deleteObjectNullProperties} from "@/utils/helpers";

export default {
  name: "TransportDataServersListPage",
  components: {BaseContentLoaders, BasePanel, TransportDataServersList},
  computed: {
    ...mapState({
      transportDataServers: state => state.transportDataServers.dataServers,
      loading: state => state.transportDataServers.loading,
      dataServerTypesList: state => state.transportDataServerTypes.dataServerTypes
    })
  },
  mounted() {
    this.$store.dispatch('transportDataServerTypes/getAllTransportDataServerTypes');
    this.$store.dispatch('transportDataServers/getAllTransportDataServers');
  },
  methods: {
    showPopup(transportDataServer = {}) {
      this.$modal.show(
        TransportDataServerForm,
        {
          'transportDataServer': transportDataServer,
          'dataServerTypesList': this.dataServerTypesList,
          'createTransportDataServer': this.createTransportDataServer,
          'updateTransportDataServer': this.updateTransportDataServer
        },
        {
          name: 'transport-data-server-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        })
    },
    createTransportDataServer(payload) {
      this.$store.dispatch('transportDataServers/createTransportDataServer', deleteObjectNullProperties(payload))
        .then(() => {
          this.$store.dispatch('transportDataServers/getAllTransportDataServers');
          this.$modal.hide('transport-data-server-form');
          showToast('Сервер данных транспорта добавлен', 'success');
        })
        .catch(error => showAlert('Ошибка', error, 'error'));
    },
    updateTransportDataServer(id, payload) {
      this.$store.dispatch('transportDataServers/updateTransportDataServer', {
        dataServerId: id,
        payload: deleteObjectNullProperties(payload)
      })
        .then(() => {
          this.$store.dispatch('transportDataServers/getAllTransportDataServers');
          this.$modal.hide('transport-data-server-form');
          showToast('Сервер данных транспорта обновлен', 'success');
        })
        .catch(error => showAlert('Ошибка', error, 'error'));
    },
    deleteTransportDataServer(id) {
      showConfirmationModal('Вы уверены, что хотите удалить сервер данных транспорта? Это действие безвозвратно.')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('transportDataServers/deleteTransportDataServer', id)
              .then(() => {
                this.$store.dispatch('transportDataServers/getAllTransportDataServers');
                showToast('Сервер данных транспорта удалён', 'success');
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })

    }
  }
}
</script>

<style scoped>

</style>