import ProjectConfigService from "@/services/ProjectConfigService";
import Echo from "laravel-echo";
import ColorSchemaService from "@/services/ColorSchemaService";

export const namespaced = true;

export const state = {
    projectConfig: null,
    selectedColorSchema: '',
    error: null,
    loading: false
}

export const mutations = {
    SET_PROJECT_CONFIG(state, config) {
        state.projectConfig = config;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_SELECTED_COLOR_SCHEMA(state, scheme) {
        state.selectedColorSchema = scheme;
    },
    SET_WS_CLIENT(state, ws_app_key) {
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: ws_app_key ? ws_app_key : '',
            httpHost: window.location.hostname,
            wsHost: window.location.hostname,
            wsPort: window.location.port,
            wssPort: window.location.port,
            wsPath: '/websocket',
            cluster: "mt1",
            forceTLS: false,
            authEndpoint: '/auth/broadcasting/auth',
            enabledTransports: ['ws', 'wss']
        });
    }
}

export const actions = {
    getProjectConfig({commit}) {
        commit("SET_LOADING", true);
        ProjectConfigService.getProjectConfig()
            .then(({projectConfig, localStorageTheme}) => {
                commit("SET_PROJECT_CONFIG", projectConfig);
                commit("baseMap/SET_CENTER_ZOOM", {
                    center: projectConfig.coordinates,
                    zoom: +projectConfig.zoom
                }, {root: true});
                commit("SET_LOADING", false);
                commit('SET_WS_CLIENT', projectConfig.ws_app_key);
                commit("SET_SELECTED_COLOR_SCHEMA", localStorageTheme ? localStorageTheme : 'light');
            })
            .catch(error => {
                commit("SET_LOADING", false);
                commit("SET_ERROR", error)
            })
    },
    setColorScheme({commit, state}, scheme) {
        commit("SET_SELECTED_COLOR_SCHEMA", scheme);
        ColorSchemaService.switchColorScheme(state.projectConfig, scheme);
    }
}

export const getters = {
    isLoading: state => {
        return state.loading
    },
    getProjectName: state => {
        if (state.projectConfig) return state.projectConfig.name;
    },
    getProjectCoordinates: state => {
        if (state.projectConfig) return state.projectConfig.coordinates;
    },
    getProjectZoom: state => {
        if (state.projectConfig) return state.projectConfig.zoom;
    },
    getProjectImageUrl: state => {
        if (state.projectConfig) return state.projectConfig.logo_url
    },
    getProjectImageUrlClassPosition: state => {
        if (state.projectConfig) return state.projectConfig.logo_position
    },
    getProjectLocation: state => {
        return state.projectConfig?.location;
    },
    getProjectPhoneMask: state => {
        if (state.projectConfig) return state.projectConfig.phone_mask || '+7 (###) ###-##-##'
    }
}
