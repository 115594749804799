<template>
  <div class="i-modal__content-wrapper">
    <div class="i-modal__content">
      <div v-if="Object.keys(vehicle).length"
           class="i-modal__inner">
        <div class="base-modal__left-side base-modal__left-side--hidden">
          <div class="base-modal-header base-modal-header--horizontal-centered">
            <div class="transport-header">
              <div class="transport-header__cards">
                <div class="base-modal-header__id">#{{ vehicle.id }}</div>
                <div class="base-modal-header__icon">
                  <icon-car v-if="vehicle.isPassengerVehicle()"/>
                  <icon-special-equipment v-else-if="vehicle.isSpecialVehicle()"/>
                  <icon-truck v-else-if="vehicle.isTruck()"/>
                  <icon-water-carrier v-else-if="vehicle.isWaterCarrier()"/>
                </div>
              </div>
              <span class="base-modal-title">{{ vehicle.attributes.label }}</span>
            </div>
            <div v-if="isCatalogPage">
              <div
                class="i-select i-select--city is-selected"
                :class="{'is-active': isActiveVehicleStatusSelect}"
                @click.stop="isActiveVehicleStatusSelect = !isActiveVehicleStatusSelect"
              >
                <input class="i-select__input"
                       hidden="hidden"
                       type="text"
                       name="executor">
                <div class="i-select__main"
                     tabindex="0"
                     role="combobox"
                     aria-expanded="false">
                  <icon-available v-if="vehicle.isActive()"/>
                  <icon-broken v-else/>
                  <span class="i-select__selected"
                        data-placeholder="Выберите подразделение">
                    {{ vehicle.attributes.state.name }}
                  </span>
                </div>
                <div class="i-select__arrow">
                  <icon-arrow/>
                </div>
                <div class="i-select__drop is-default-hidden">
                  <div class="i-select__drop-inner">
                    <ul class="i-select__list"
                        role="listbox">
                      <li
                        class="i-select__item"
                        :class="{'is-active': vehicle.isActive()}"
                        data-value="Доступно"
                        role="option"
                        @click="changeVehicleState(vehicle, 1)"
                      >
                        <icon-available/>
                        <div class='list-item'>
                          <span class='list-item__txt'>Доступно</span>
                        </div>
                      </li>
                      <li
                        class="i-select__item"
                        :class="{'is-active': !vehicle.isActive()}"
                        data-value="В ремонте"
                        role="option"
                        @click="changeVehicleState(vehicle, 2)"
                      >
                        <icon-broken/>
                        <div class='list-item'>
                          <span class='list-item__txt'>В ремонте</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div
                v-if="vehicle.isActive()"
                class="transport-header__status">
                <icon-tick-square/>
                Доступно
              </div>
              <div
                v-else
                class="transport-header__status">
                <icon-wrench/>
                В ремонте
              </div>
            </div>
          </div>
          <div class="base-modal-info base-modal-info--padding-m base-modal-info--gap-24">
            <div
              v-if="!isCatalogPage"
              class="base-modal-info__card">
              <div class="transport-parameters">
                <div class="transport-parameters__item">
                  <div class="transport-parameter">
                    <div class="transport-parameter__icon">
                      <icon-speed-success v-if="speedStatus(vehicle.id) === 'success'"/>
                      <icon-speed-warning v-else-if="speedStatus(vehicle.id) === 'warning'"/>
                      <icon-speed-danger v-else-if="speedStatus(vehicle.id) === 'danger'"/>
                      <icon-speed-normal v-else/>
                    </div>
                    <div class="transport-parameter__content">
                      <div class="transport-parameter__label">
                        Текущая скорость
                      </div>
                      <div class="transport-parameter__value">
                        {{ speed(vehicle.id) }}
                        <span>км/ч</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="transport-parameters__item">
                  <div class="transport-parameter">
                    <div class="transport-parameter__icon">
                      <icon-routing/>
                    </div>
                    <div class="transport-parameter__content">
                      <div class="transport-parameter__label">
                        Суточный пробег
                      </div>
                      <div class="transport-parameter__value">
                        {{ dailyMileage(vehicle.id) }}
                        <span>км</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="transport-parameters__item">
                  <div class="transport-parameter">
                    <div class="transport-parameter__icon">
                      <icon-engine-hours/>
                    </div>
                    <div class="transport-parameter__content">
                      <div class="transport-parameter__label">
                        Моточасы, сутки
                      </div>
                      <div class="transport-parameter__value">
                        {{ dailyEngineHours(vehicle.id) }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="transport-parameters__item transport-parameters__item--full-w">
                  <div class="transport-engine-status">
                    <div class="transport-engine-status__icon">
                      <icon-engine-new/>
                    </div>
                    <div class="transport-engine-status__label">
                      Двигатель
                      <span
                        v-if="engineStatus(vehicle.id)"
                        class="transport-engine-status__label--active">запущен</span>
                      <span
                        v-else
                        class="transport-engine-status__label--in-active">не запущен</span>
                    </div>
                  </div>
                </div>
              </div>
              <transport-modal-map
                :vehicle="vehicle"
                :center="[lat(vehicle.id), lon(vehicle.id)]"/>
            </div>
            <div class="base-modal-info__card">
              <div class="base-modal-info__card-title base-modal-info__card-title--loader">
                Детальная информация
                <div
                  v-if="isCatalogPage"
                  @click="openEditModal()"
                  class="base-modal-info__card-title-subtext">
                  <icon-pencil/>
                  <span>Редактировать</span>
                </div>
              </div>

              <div class="base-modal-info__item">
                <div class="base-modal-info__name">
                  <span>Тип транспорта</span>
                </div>
                <div class="base-modal-info__value">
                  <p class="base-modal-info__value-txt">{{ vehicle.getTypeLabel() }}</p>
                </div>
              </div>

              <div class="base-modal-info__item">
                <div class="base-modal-info__name">
                  <span>Гос. номер</span>
                </div>
                <div class="base-modal-info__value">
                  <p class="base-modal-info__value-txt">{{ vehicle.attributes.gov_number }}</p>
                </div>
              </div>

              <div class="base-modal-info__item">
                <div class="base-modal-info__name">
                  <span>Внешний ID</span>
                </div>
                <div class="base-modal-info__value">
                  <p class="base-modal-info__value-txt">{{ vehicle.attributes.external_id }}</p>
                </div>
              </div>

              <div class="base-modal-info__item">
                <div class="base-modal-info__name">
                  <span>Источник данных</span>
                </div>
                <div class="base-modal-info__value">
                  <p class="base-modal-info__value-txt">{{ dataSourceName(vehicle.attributes.data_source_id) }}</p>
                </div>
              </div>
            </div>
            <transport-movement-history-form
              v-if="!isCatalogPage"
              :vehicle-id="vehicle.id"
              @get-movement-history="openHistoryModal()"/>

            <div
              v-if="isCatalogPage"
              class="i-modal-btn-wp delete-btn">
              <button class="button button--mode-shadow cancel"
                      @click="deleteVehicle()">
                <icon-trash/>
                <span>Удалить</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="Object.keys(vehicle).length"
         class="i-modal-btn-wp i-modal-btn-wp--back">
      <div class="task-modal__mobile-actions">
        <button @click="$store.dispatch('baseSideModal/closeModal')"
                class="button button--mode-outline task-modal__mobile-back">
          <icon-arrow-left/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrowLeft from "@/components/Icons/IconArrowLeft.vue";
import {mapGetters, mapState} from "vuex";
import IconCar from "@/components/Icons/IconCar.vue";
import IconSpecialEquipment from "@/components/Icons/IconSpecialEquipment.vue";
import IconWaterCarrier from "@/components/Icons/IconWaterCarrier.vue";
import IconTruck from "@/components/Icons/IconTruck2.vue";
import IconTickSquare from "@/components/Icons/IconTickSquare.vue";
import IconWrench from "@/components/Icons/IconWrench.vue";
import TransportModalMap from "@/components/Transport/TransportModalMap.vue";
import IconSpeedWarning from "@/components/Icons/IconSpeedWarning.vue";
import IconSpeedSuccess from "@/components/Icons/IconSpeedSuccess.vue";
import IconSpeedDanger from "@/components/Icons/IconSpeedDanger.vue";
import IconRouting from "@/components/Icons/IconRouting.vue";
import IconEngineHours from "@/components/Icons/IconEngineHours.vue";
import IconSpeedNormal from "@/components/Icons/IconSpeedNormal.vue";
import IconEngineNew from "@/components/Icons/IconEngineNew.vue";
import TransportMovementHistoryForm from "@/components/Transport/TransportMovementHistoryForm.vue";
import IconPencil from "@/components/Icons/IconPencil.vue";
import IconTrash from "@/components/Icons/IconTrash.vue";
import {showConfirmationModal, showAlert, showToast} from "@/utils/notification";
import IconAvailable from "@/components/Icons/IconAvailable.vue";
import IconArrow from "@/components/Icons/IconArrow.vue";
import IconBroken from "@/components/Icons/IconBroken.vue";

export default {
  name: "TransportModalContent",
  components: {
    IconBroken, IconArrow, IconAvailable,
    IconTrash,
    IconPencil,
    TransportMovementHistoryForm,
    IconEngineNew,
    IconSpeedNormal,
    IconEngineHours,
    IconRouting,
    IconSpeedDanger,
    IconSpeedSuccess,
    IconSpeedWarning,
    TransportModalMap,
    IconWrench,
    IconTickSquare,
    IconTruck,
    IconWaterCarrier,
    IconSpecialEquipment,
    IconCar,
    IconArrowLeft
  },
  data() {
    return {
      isActiveVehicleStatusSelect: false
    }
  },
  computed: {
    ...mapState({
      vehicle: state => state.autos.auto,
      movementHistory: state => state.autos.movement_history
    }),
    ...mapGetters({
      lat: 'transportData/getLat',
      lon: 'transportData/getLon',
      speed: 'transportData/getSpeed',
      speedStatus: 'transportData/getVehicleSpeedStatus',
      dailyMileage: 'transportDayStat/getMileage',
      dailyEngineHours: 'transportDayStat/getEngineHours',
      engineStatus: 'transportData/getEngineStatus',
      dataSourceName: 'transportDataServers/getTransportDataServerName',
      contentPropData: 'baseSideModal/getPropData'
    }),
    isCatalogPage() {
      return this.contentPropData('isCatalogPage');
    }
  },
  mounted() {
    this.$store.dispatch('transportDataServers/getAllTransportDataServers');
  },
  methods: {
    openHistoryModal() {
      if (this.movementHistory.length) {
        this.$store.dispatch('baseSideModal/openModal', {
          modalContentType: 'transportMovementHistory',
          entityId: this.vehicle.id
        });
      }
    },
    openEditModal() {
      this.$store.dispatch('baseSideModal/openModal', {
        modalContentType: 'vehicleForm',
        entityId: null,
        propsData: {
          isEditForm: true
        }
      })
    },
    changeVehicleState(vehicle, stateId) {
      const storePath = vehicle.isActive()
        ? 'autos/sendVehicleToRepair'
        : 'autos/makeVehicleAvailable'

      if (vehicle.attributes.state.id !== stateId) {
        this.$store.dispatch(`${storePath}`, vehicle.id)
          .then(() => {
            showToast('Статус изменён', 'success');
            this.isActiveVehicleStatusSelect = false;
          })
          .catch(error => showAlert('Ошибка', error))
      }
    },
    deleteVehicle() {
      showConfirmationModal('Вы уверены, что хотите удалить транспорт? Это действие невозвратно.')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('autos/deleteAuto', this.vehicle.id)
              .then(() => {
                this.$store.dispatch('baseSideModal/goBack');
                showToast('Транспорт удален', 'success');
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.transport-header {
  display: flex;
  gap: 24px;

  &__cards {
    display: flex;
    gap: 4px;
  }

  &__status {
    display: flex;
    align-items: center;
    gap: 8px;

    @include font-style(400, 16px, 24px, var(--text-primary));
  }
}

.transport-parameters {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 8px;

  &__item {
    padding: 12px 24px;
    border-radius: 8px;
    background: var(--panel);

    &--full-w {
      grid-column: 1 / 4;
    }
  }
}

.transport-parameter {
  display: flex;
  gap: 12px;

  &__icon {
    width: 48px;
    height: 48px;

    & svg {
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &__label {
    @include font-style(400, 14px, 16px, var(--text-primary));
  }

  &__value {
    margin-right: 2px;

    @include font-style(500, 20px, 28px, var(--text-primary));

    & span {
      @include font-style(400, 14px, 16px, var(--text-secondary));
    }
  }
}

.transport-engine-status {
  display: flex;
  align-items: center;
  gap: 8px;

  &__icon {
    width: 24px;
    height: 24px;
  }

  &__label {
    @include font-style(400, 14px, 16px, var(--text-primary));

    &--in-active {
      @include font-style(600, 14px, 16px, var(--text-secondary));
    }

    &--active {
      @include font-style(600, 14px, 16px, var(--text-green));
    }
  }
}
</style>
