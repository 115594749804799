<template>
  <div class="i-modal-location"
       :class="{'is-open': isCollapseOpened}">
    <div class="i-modal-location__map">

      <base-map
        id-key="-create-defected-problem"
        :center="center"
        :zoom="zoom"
        :base-layers="baseLayers"
        :markers="marker"
        :need-resize="isCollapseOpened"
      />

    </div>
    <button class="button button--mode-icon map-resize-btn"
            @click="isCollapseOpened = !isCollapseOpened">
      <icon-arrow-double-up/>
    </button>
  </div>
</template>

<script>
import IconArrowDoubleUp from "@/components/Icons/IconArrowDoubleUp.vue";
import BaseMap from "@/components/BaseMap.vue";
import {mapState} from "vuex";
import {showToast} from "@/utils/notification";
import Vue from "vue";
import DefectedProblemMapMarker
  from '@/components/ServiceDesk/Tasks/TaskComponents/DetectedProblems/DetectedProblemMapMarker.vue'
import Marker from "@/models/baseMap/Marker";
import Icon from '@/models/baseMap/Icon';

export default {
  name: "DetectedProblemMap",
  components: {BaseMap, IconArrowDoubleUp},
  data() {
    return {
      isCollapseOpened: false,
      marker: []
    }
  },
  computed: {
    ...mapState({
      center: state => state.baseMap.center,
      zoom: state => state.baseMap.zoom,
      baseLayers: state => state.baseLayers.baseLayers
    })
  },
  mounted() {
    this.$root.$on('fit-bounds-map', dadataCallback => {
      let qc_geo = parseInt(dadataCallback.qc_geo)
      if (qc_geo <= 1) {
        this.$store.commit('baseMap/SET_CENTER_ZOOM', {
          center: dadataCallback.coords,
          zoom: 18
        })
        this.createMarker(dadataCallback.coords);

      } else if (qc_geo > 1 && qc_geo < 4) {
        this.$store.commit('baseMap/SET_CENTER_ZOOM', {
          center: dadataCallback.coords,
          zoom: 16
        })
        this.createMarker(dadataCallback.coords);

      } else {
        showToast('Координаты по указанному адресу не найдены, поставьте отметку вручную', 'warning')
        this.isCollapseOpened = true;
      }
    })

    this.$root.$on('BaseMap::map-click', ({coords}) => {
      this.$emit('update:coords', coords);
      this.createMarker(coords);
    })
  },
  methods: {
    createMarker(coords) {
      const DefectedProblemIconClass = Vue.extend(DefectedProblemMapMarker);
      const iconInstance = new DefectedProblemIconClass();

      const icon = iconInstance.$mount();
      const iconHtml = icon.$el.outerHTML;

      this.marker = [
        new Marker({
          coords: coords,
          icon: new Icon({
            type: 'div_icon',
            size: [36, 42],
            anchor: [18, 21],
            html: iconHtml
          })
        })
      ]

      icon.$destroy();
    }
  }
}
</script>

<style scoped>

</style>