<template>
  <div>

    <div class="loader-position"
         v-if="loading">
      <base-loader/>
    </div>

    <base-no-data
      v-if="this.$store.state.client.clients.length === 0"
      title="Абоненты не найдены"/>

    <div
      v-if="storeStatus"
      id="filter-result"
      class="clients-filter-result"
    >
      <div
        v-for="client_group in this.$store.state.client.clients"
        :key="client_group.id"
        class="base-card"
      >

        <base-collapse>
          <template #toggle="{toggle, isCollapsed}">
            <div class="client-group">
              <div class="client-group__address">
                {{ getFullAddress(client_group) }}
              </div>

              <div class="client-group__actions">
                <div
                  class="client-group__view-map"
                  @click="$store.commit(
                    'baseMap/SET_CENTER_ZOOM',
                    {center: client_group.attributes.geometry.coordinates, zoom: 17}
                  )">
                  <icon-map />
                </div>

                <div
                  @click="toggle()"
                  class="client-group__toggle"
                  :class="{'client-group__toggle--collapsed': isCollapsed}"
                >
                  <icon-arrow-down/>
                </div>
              </div>

            </div>
          </template>

          <template #body>
            <div
              v-if="client_group.attributes.consumers.filter(
                client => client.attributes.client_type === 1).length !== 0"
            >
              <div class="collapse-block-title">
                Физические лица:
              </div>
              <ol type="1">
                <li
                  v-for="consumer in client_group.attributes.consumers.filter(
                    consumer => consumer.attributes.client_type === 1)"
                  :key="consumer.id"
                  class="collapse-list-item"
                  @click="getClientDetail(consumer.id)"
                >
                  №{{ consumer.attributes.ls }} от {{ $moment(consumer.attributes.ls_date).format('DD-MM-YYYY') }}
                  {{ describeLsStatus(consumer.attributes.status) }} <br>
                  {{ consumer.attributes.client_name }} <br>
                  <span
                    v-if="consumer.attributes.saldo < 0"
                    class="text-danger"
                  >
                    Задолженность: {{ Math.abs(consumer.attributes.saldo) }}</span>
                </li>
              </ol>
            </div>

            <div
              v-if="client_group.attributes.consumers.filter(consumer =>
                consumer.attributes.client_type === 2).length !== 0"
            >
              <div class="collapse-block-title">
                Юридические лица:
              </div>
              <ol type="1">
                <li
                  v-for="consumer in client_group.attributes.consumers.filter(
                    consumer => consumer.attributes.client_type === 2)"
                  :key="consumer.id"
                  class="collapse-list-item"
                  @click="getClientDetail(consumer.id)"
                >
                  №{{ consumer.attributes.ls }} от {{ $moment(consumer.attributes.ls_date).format('DD-MM-YYYY') }}
                  {{ describeLsStatus(consumer.attributes.status) }} <br>
                  {{ consumer.attributes.client_name }} <br>
                  <span
                    v-if="consumer.attributes.saldo < 0"
                    class="text-danger"
                  >
                    Задолженность: {{ Math.abs(consumer.attributes.saldo) }}</span>
                </li>
              </ol>
            </div>
          </template>
        </base-collapse>


      </div>
    </div>

    <base-paginate
      v-if="meta && meta.last_page > 1"
      :meta="meta"
      action="client/getClients"
      commit="client/SET_PAGE"
    />
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {getError} from "@/utils/helpers";
import BaseLoader from "@/layout/BaseLoader.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import BaseCollapse from "@/components/BaseCollapse.vue";
import IconArrowDown from "@/components/Icons/IconArrowDown.vue";
import IconMap from "@/components/Icons/IconMap.vue";
import BasePaginate from "@/components/BasePaginate.vue";
import {showAlert} from "@/utils/notification";

export default {
  name: "BillingClientsFilterResult",
  components: {BasePaginate, IconMap, IconArrowDown, BaseCollapse, BaseNoData, BaseLoader},
  props: ['filter_params'],
  computed: {
    ...mapState({
      meta: state => state.client.meta
    }),
    ...mapGetters({
      storeStatus: 'client/getStoreStatus',
      loading: 'client/getClientLoading'
    }),
    describeLsStatus: function () {
      return i => {
        switch (i) {
          case 1:
            return 'Открыт'
          case 2:
            return 'Закрыт'
          case 3:
            return 'Не активен'
        }
      }
    },
    getFullAddress: function () {
      return building => {
        let full_address_arr = [building.attributes.city, building.attributes.settlement, building.attributes.street,
          building.attributes.house, building.attributes.block].filter(item => item !== null)
        return full_address_arr.join(', ')
      }
    }
  },
  methods: {
    getClientDetail(id) {
      this.$store.dispatch('client/getClient', id)
        .then(() => {
          this.$store.commit("client/SET_TAB_INDEX", 2);
        })
        .catch(() => {
          showAlert('Ошибка', getError(this.$store.state.client.error), 'error')
        })
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/helpers/mixins";

.clients-filter-result {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.client-group {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__address {
    display: flex;
    @include font-style(500, 16px, 24px, var(--text-primary));
    cursor: pointer;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__toggle {
    transform: rotateZ(180deg);
    cursor: pointer;

    & svg {
      & path {
        stroke: var(--ico);
      }
    }

    &--collapsed {
      transform: rotateZ(0);
    }
  }

  &__view-map {
    cursor: pointer;
  }
}

.collapse-block-title {
  @include font-style(500, 14px, 18px, var(--text-tertiary));

  margin-top: 12px;
  margin-bottom: 6px;
}

.loader-position {
  position: absolute;
  right: 20px;
  top: 50px;
}

.collapse-list-item {
  @include font-style(400, 14px, 18px, var(--text-dark-grey));
  cursor: pointer;
  transition: .3s;
  margin-bottom: 2px;

  &:hover {
    color: var(--text-link);
  }
}
</style>
