<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      Добавить задание
      <span @click="$modal.hide('issue-tasks-form')"
            class="base-popup__close">
        <icon-close/>
      </span>
    </div>
    <div class="popup-form-group">
      <div class="form-item">
        <div class="form-item__name">Тип задания</div>
        <div class="form-item__value">
          <v-select
            v-model="task_type_id"
            :options="taskTypesList"
            :reduce="type => type.id"
            :get-option-label="(option) => option.name"
            class="vue-select-input"
            placeholder="Выберите из списка"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Подразделение</div>
        <div class="form-item__value">
          <v-select
            v-model="department_id"
            :options="departmentsList"
            :reduce="department => department.id"
            :get-option-label="(option) => option.name"
            class="vue-select-input"
            placeholder="Выберите из списка"
          />
        </div>
      </div>
    </div>
    <div class="base-popup__footer">
      <div @click="submitForm()"
           class="button">
        Добавить
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from "@/components/Icons/IconClose";
import {showAlert, showToast} from "@/utils/notification";
export default {
  name: "IssueTasksForm",
  components: {IconClose},
  props: {
    issueId: {
      type: Number,
      required: true
    },
    departmentsList: {
      type: Array,
      required: true
    },
    taskTypesList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      task_type_id: null,
      department_id: null
    }
  },
  methods: {
    submitForm() {
      const payload = {
        issue_id: this.issueId,
        params: {
          task_type_id: this.task_type_id,
          department_id: this.department_id
        }
      }
      this.$store.dispatch('issue/AddTaskToIssue', payload)
        .then(() => {
          this.$modal.hide('issue-tasks-form');
          showToast('Задание добавлено в заявку', 'success');
          this.$store.dispatch('issueModal/getIssue', this.issueId);
          this.$store.dispatch('issueModal/getIssueHistory', this.issueId);
        })
        .catch(error => showAlert('Ошибка', error, 'error'))
    }
  }
}
</script>

<style scoped>

</style>
