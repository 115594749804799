import * as API from './API';
import NetworkObjectType from "@/models/telemetry/NetworkObjectType";
import {getError} from "@/utils/helpers";
import NetworkObjectTypeGroup from "@/models/telemetry/NetworkObjectTypeGroup";

export default {
    fetchNetworkObjectTypesList() {
        return new Promise((resolve, reject) => {
            API.apiClient.get('/network-object-types')
                .then(response => resolve(response.data.data.map(item => new NetworkObjectType(item))))
                .catch(error => reject(getError(error)))
        })
    },
    fetchNetworkObject(typeId) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/network-object-types/${typeId}`)
                .then(response => resolve(new NetworkObjectType(response.data.data)))
                .catch(error => reject(getError(error)));
        })
    },
    createNetworkObjectType(payload) {
        return API.apiClient.post('/network-object-types', payload);
    },
    updateNetworkObjectType(typeId, payload) {
        return API.apiClient.patch(`/network-object-types/${typeId}`, payload);
    },
    deleteNetworkObjectType(typeId) {
        return API.apiClient.delete(`/network-object-types/${typeId}`);
    },
    fetchNetworkObjectTypeGroups() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/network-object-types/groups`)
                .then(response => resolve(response.data.data.map(item => new NetworkObjectTypeGroup({
                    id: item.id,
                    ...item.attributes
                }))))
                .catch(error => reject(getError(error)))
        })
    }
}