import * as API from './API'
import {IssueType} from "@/models/serviceDesk/IssueType";
import {getError} from "@/utils/helpers";

export default {
    getIssueTypes() {
        return new Promise((resolve, reject) => {
            API.apiClient.get('service-desk/issues/types')
                .then(response => resolve(response.data.data.map(item => new IssueType(item.id, item.name))))
                .catch(error => reject(getError(error)));
        })
    }
}