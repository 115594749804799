<script>
export default {
  name: "IconDrainage"
}
</script>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.0136 8.18262C13.9933 8.16587 13.9711 8.15032 13.9502 8.13477C13.9771 8.1545 14.0034 8.17484 14.0285
       8.19458C14.0238 8.19458 14.019 8.1868 14.0136 8.18262Z"
      fill="#B2CECB"/>
    <path d="M13.4195 7.88469L13.3525 7.86914C13.3777 7.87452 13.4028 7.88051 13.4273 7.88709L13.4195 7.88469Z"
          fill="#B2CECB"/>
    <path
      d="M14.5078 8.92188C14.512 8.93503 14.5162 8.9476 14.5198 8.96016C14.5156 8.9464 14.5114 8.93444 14.5078 8.92188Z"
      fill="#B2CECB"/>
    <path
      d="M14.4923 8.86728C14.487 8.85293 14.4816 8.83857 14.4756 8.82422C14.4816 8.83857 14.487 8.85233 14.4923
       8.86728Z"
      fill="#B2CECB"/>
    <path
      d="M13.9298 8.11925C13.8933 8.09353 13.8563 8.0696 13.8174 8.04688C13.8557 8.0696 13.8927 8.09353 13.9298
      8.11925Z"
      fill="#B2CECB"/>
    <path
      d="M11.3432 4.72082C11.3432 4.93555 11.3432 5.13952 11.3432 5.32973H13.4318C14.4165 5.32973 15.3608 5.72088
      16.057 6.41714C16.7533 7.1134 17.1444 8.05773 17.1444 9.04239V12.0151H17.7796C19.3635 12.0151 20.296 12.0151
      20.8456 12.0959V9.65608C20.8456 7.32333 19.9831 4.94811 18.1188 3.45814C17.2772 2.78524 16.5188 2.33603 15.499
      1.96758C14.8814 1.75459 14.2372 1.62837 13.585 1.59254C13.0975 1.55426 12.2146 1.53273 11.1416 1.52734C11.4275
      2.0549 11.3432 3.00655 11.3432 4.72082Z"
      fill="#A4D9F6"/>
    <path
      d="M20.8448 12.0964C20.2951 12.0156 19.3626 12.0156 17.7788 12.0156H17.1436V14.3544H17.7788C21.6337 14.3544
      21.6337 14.3544 21.6337 13.1862C21.6337 12.4953 21.6331 12.213 20.8448 12.0964Z"
      fill="#262F3D"/>
    <path
      d="M14.827 14.2864C14.7869 14.2817 14.748 14.2769 14.7109 14.2715C14.7504 14.2769 14.7893 14.2817 14.827 14.2864Z"
      fill="#97AEAC"/>
    <path
      d="M13.9443 13.6985C13.9413 13.6787 13.9389 13.6584 13.9365 13.6387C13.9389 13.6572 13.9413 13.6776 13.9443
       13.6985Z"
      fill="#97AEAC"/>
    <path
      d="M13.9357 13.6204C13.9357 13.6 13.9321 13.5797 13.9309 13.5605C13.9297 13.5773 13.9339 13.6 13.9357 13.6204Z"
      fill="#97AEAC"/>
    <path
      d="M14.1916 14.1167C14.1743 14.1054 14.1575 14.094 14.1426 14.082C14.1575 14.0922 14.1743 14.1036 14.1916
      14.1167Z"
      fill="#97AEAC"/>
    <path
      d="M14.0916 14.0335C14.0788 14.0204 14.0668 14.0066 14.0557 13.9922C14.0666 14.0068 14.0786 14.0206 14.0916
      14.0335Z"
      fill="#97AEAC"/>
    <path
      d="M13.9443 12.6284C13.9485 12.6009 13.9527 12.574 13.9581 12.5488C13.9527 12.5745 13.9485 12.6009 13.9443
       12.6284Z"
      fill="#97AEAC"/>
    <path
      d="M13.9346 12.7219C13.9346 12.6908 13.94 12.6621 13.9441 12.6328C13.94 12.6609 13.937 12.6908 13.9346 12.7219Z"
      fill="#97AEAC"/>
    <path
      d="M13.9297 13.54C13.9297 13.519 13.9297 13.4981 13.9268 13.4766C13.9297 13.4981 13.9297 13.5178 13.9297 13.54Z"
      fill="#97AEAC"/>
    <path
      d="M13.9265 13.4504C13.9265 13.4301 13.9265 13.4098 13.9265 13.3906C13.9253 13.4098 13.9259 13.4301
      13.9265 13.4504Z"
      fill="#97AEAC"/>
    <path
      d="M13.9248 13.0337C13.9248 13.0056 13.9248 12.9775 13.9248 12.9512C13.9254 12.9799 13.9248 13.005
       13.9248 13.0337Z"
      fill="#97AEAC"/>
    <path
      d="M13.9258 12.9233C13.9258 12.8934 13.9258 12.8635 13.9258 12.8359C13.93 12.8641 13.9264 12.8934
      13.9258 12.9233Z"
      fill="#97AEAC"/>
    <path
      d="M14.7071 14.2699C14.67 14.2639 14.6341 14.2585 14.6006 14.252C14.6329 14.2585 14.6688 14.2639 14.7071 14.2699Z"
      fill="#97AEAC"/>
    <path
      d="M15.2811 14.3234C15.2267 14.3234 15.1752 14.3174 15.125 14.3145C15.1752 14.3174 15.2267 14.321
       15.2811 14.3234Z"
      fill="#97AEAC"/>
    <path
      d="M10.2121 8.42773C10.1846 8.43491 10.1523 8.44149 10.126 8.44807C10.1553 8.44149 10.1846 8.43252
       10.2121 8.42773Z"
      fill="#97AEAC"/>
    <path
      d="M4.58772 8.38721C4.56439 8.37943 4.54166 8.37166 4.51953 8.36328C4.53927 8.37465 4.56439 8.37943
       4.58772 8.38721Z"
      fill="#97AEAC"/>
    <path
      d="M4.07258 8.0803C4.05763 8.06474 4.04268 8.048 4.02832 8.03125C4.04268 8.048 4.06062 8.06474 4.07258 8.0803Z"
      fill="#97AEAC"/>
    <path
      d="M3.97805 7.97017C3.96609 7.95402 3.95472 7.93727 3.94336 7.91992C3.95472 7.93727 3.96609 7.95641
       3.97805 7.97017Z"
      fill="#97AEAC"/>
    <path
      d="M17.1444 12.0152V9.04242C17.1444 8.05776 16.7533 7.11343 16.0571 6.41717C15.3608 5.72091 14.4165 5.32976
       13.4319 5.32976H11.3432C11.3432 5.13775 11.3432 4.93558 11.3432 4.72085C11.3432 3.00658 11.4275 2.05494
       11.1434 1.52618C11.0494 1.35036 10.9008 1.2099 10.7199 1.12602C10.1888 0.865234 9.22463 0.865234 7.47211
       0.865234C5.78659 0.865234 4.83496 0.865234 4.29725 1.18404C4.15201 1.2683 4.02897 1.38601 3.93837
        1.52738C3.59923 2.05673 3.59863 3.00777 3.59863 4.72085C3.59863 4.93558 3.59863 5.13955 3.59863
        5.32976H11.3432C11.3402 6.15818 11.3247 6.77606 11.2427 7.23544C11.2096 7.45796 11.1377 7.67295
        11.0304 7.87066C10.8641 8.15657 10.6117 8.32644 10.2175 8.42753C10.6087 8.32644 10.8641 8.15657
        11.031 7.87066C11.1679 7.87066 11.3031 7.87485 11.4347 7.87784H11.4473L11.5561 7.88083H11.5884L11.6895
        7.88382H11.7248L11.8235 7.88741H11.8582H11.9545H11.9898L12.0837 7.891H12.1172L12.2111 7.89518H12.2356C12.3831
         7.90236 12.5241 7.91074 12.6585 7.92031H12.6782L12.7584 7.92629H12.7907L12.8636 7.93287L12.8989
         7.93586L12.9683 7.94244L13.0024 7.94543L13.07 7.95261L13.1023 7.95619L13.1681 7.96397L13.1968 7.96756L13.262
          7.97593L13.2853 7.97892L13.3523 7.98849H13.3565C13.4343 8.00006 13.5066 8.01262 13.5736
          8.02618H13.5874L13.6388 8.03754L13.6597 8.04173L13.7052 8.05309L13.7267 8.05848L13.7686 8.06984L13.7889
           8.07582L13.8272 8.08779L13.8458 8.09377L13.8817 8.10633L13.8972 8.11291C13.9086 8.1171 13.9199 8.12128
           13.9301 8.12607L13.9427 8.13205L13.9744 8.147H13.9785C13.9981 8.15713 14.0169 8.16873 14.0348 8.18169C14.0653
            8.20741 14.0946 8.23313 14.1233 8.26125L14.1365 8.27441C14.1592 8.29713 14.1807 8.32046 14.2017
            8.34439C14.251 8.40069 14.296 8.46067 14.3362 8.52383C14.3476 8.54178 14.3596 8.56032 14.3703
            8.57886V8.58245C14.4004 8.63482 14.4274 8.68895 14.4511 8.74455C14.4524 8.74824 14.454 8.75184 14.4559
             8.75531C14.4624 8.77086 14.4678 8.78761 14.4738 8.80376C14.4798 8.81991 14.4852 8.83247 14.4905
             8.84683C14.4959 8.86118 14.5013 8.88272 14.5067 8.90066C14.5121 8.9186 14.5145 8.92518 14.5181
              8.93715C14.5216 8.9521 14.5258 8.96765 14.5294 8.98201C14.5576 9.08855 14.5741 9.19786 14.5785
              9.30799C14.5994 9.84931 14.5755 10.3966 14.5755 10.9331V12.0559C15.0366 12.002 15.8166 12.0116
              17.1444 12.0152ZM13.3409 7.86647C13.3188 7.86169 13.2973 7.8581 13.2751 7.85451C13.2997 7.86049 13.3212
               7.86408 13.3433 7.86887L13.3409 7.86647ZM13.4169 7.88382H13.4247C13.4002 7.87724 13.375 7.87126 13.3499
                7.86588L13.4169 7.88382ZM13.9271 8.1159C13.8906 8.09018 13.8535 8.06625 13.8147 8.04352C13.8559 8.06865
                 13.893 8.09257 13.9301 8.11829L13.9271 8.1159ZM14.0114 8.1799C14.0168 8.18409 14.0216 8.18947 14.0264
                  8.19366C14.0013 8.17212 13.975 8.15179 13.948 8.13384C13.9714 8.14999 13.9935 8.16554 14.0138
                   8.18229L14.0114 8.1799Z"
      fill="#468FC5"/>
    <path
      d="M17.1437 12.0161C15.8158 12.0125 15.0359 12.0029 14.5771 12.0789C14.4461 12.0957 14.319 12.1352 14.2015
      12.1955H14.1985C14.1777 12.2078 14.1577 12.2214 14.1387 12.2362C14.0835 12.2798 14.0392 12.3357 14.0095
      12.3995C14.0095 12.3995 14.0095 12.3995 14.0095 12.4025C13.9998 12.4233 13.9914 12.4446 13.9844
      12.4665V12.4725C13.9772 12.4946 13.9706 12.5179 13.9652 12.5424C13.9655 12.5442 13.9655 12.546 13.9652
      12.5478C13.9599 12.573 13.9557 12.5999 13.9515 12.6274V12.6304C13.9473 12.6585 13.9443 12.6902 13.9419
      12.7195C13.9416 12.7225 13.9416 12.7255 13.9419 12.7285C13.9395 12.7568 13.9377 12.7861 13.9365 12.8164C13.9365
       12.8224 13.9365 12.8284 13.9365 12.8343C13.9365 12.8625 13.9365 12.8942 13.9365 12.9217C13.9365 12.9306 13.9365
        12.9396 13.9365 12.9486C13.9365 12.9749 13.9365 13.003 13.9365 13.0311C13.9365 13.0796 13.9365 13.1292 13.9365
         13.1825C13.9365 13.2357 13.9365 13.2752 13.9365 13.3188V13.3481C13.9365 13.3613 13.9365 13.3751 13.9365
         13.3876C13.9365 13.4002 13.9365 13.4289 13.9365 13.4474C13.9365 13.4558 13.9365 13.4642 13.9365 13.4726C13.9365
         13.4941 13.9365 13.515 13.9395 13.536C13.9395 13.5419 13.9395 13.5485 13.9395 13.5545C13.9395 13.576 13.9425
         13.5964 13.9443 13.6143C13.9446 13.6191 13.9446 13.6239 13.9443 13.6287C13.9443 13.6496 13.9491 13.6699 13.9521
         13.6885V13.6993C13.9551 13.719 13.9587 13.7387 13.9628 13.7591V13.7656C13.967 13.786 13.9724 13.8051 13.9778
         13.8255C13.9836 13.8444 13.9904 13.8629 13.9981 13.8811C14.0057 13.8987 14.0143 13.9159 14.0238
         13.9325V13.9355C14.0328 13.9513 14.0428 13.9665 14.0538 13.981L14.0567 13.9852C14.0679 13.9996 14.0799 14.0134
         14.0926 14.0264L14.0968 14.0306C14.1099 14.0441 14.1239 14.0567 14.1387 14.0683L14.1435 14.0719C14.1584 14.0839
         14.1752 14.0952 14.1925 14.1066H14.1973C14.2166 14.1181 14.2366 14.1285 14.2571 14.1377H14.2607C14.2829 14.1486
          14.3057 14.1584 14.3289 14.167C14.4159 14.1991 14.5056 14.2233 14.5969 14.2394H14.601C14.6345 14.246 14.6704
          14.2513 14.7075 14.2573H14.7147C14.7518 14.2627 14.7907 14.2675 14.8307 14.2723H14.8403L14.9671
          14.2854H14.9779L15.1178 14.2968H15.1256C15.1758 14.2998 15.2273 14.3034 15.2817 14.3058H15.2853C15.5102
          14.3171 15.7722 14.3243 16.0772 14.3285H16.0826H16.3129H16.3248H16.5689H16.5844H16.8488H17.1425L17.1437
          12.0161Z"
      fill="#262F3D"/>
    <path
      d="M11.0303 7.87114C11.1375 7.67398 11.2093 7.45961 11.2427 7.23771C11.3246 6.77834 11.3401 6.16105 11.3431
      5.33203H3.60156C3.60635 6.66708 3.64403 7.45364 3.9407 7.916L3.94429 7.92078C3.95565 7.93813 3.96702 7.95488
      3.97898 7.97103L3.98496 7.98C3.99752 7.99675 4.01008 8.0123 4.02324 8.02845L4.02863 8.03443C4.04298 8.05118
      4.05793 8.06793 4.07289 8.08348C4.14022 8.15326 4.21668 8.21362 4.30018 8.26292C4.32051 8.27488 4.34204 8.28685
      4.36358 8.29761L4.37075 8.3012L4.43057 8.33051L4.44074 8.3353L4.50414 8.36102L4.5149 8.3652C4.53703 8.37358
      4.55976 8.38135 4.58309 8.38913L4.59266 8.39212C4.61778 8.40049 4.6435 8.40827 4.67042 8.41605C4.79526 8.45009
      4.92211 8.47626 5.05023 8.4944H5.05382L5.1543 8.50816H5.16866C5.19976 8.51235 5.22847 8.51594 5.26316
      8.51893H5.2835L5.3786 8.5279H5.40013L5.50122 8.53567H5.52155L5.6334 8.54285H5.64656C5.82002 8.55242 6.00843
      8.5584 6.21239 8.56259H6.23632H6.36372H6.40439H6.52402H8.42726H8.56603H8.58576C8.74786 8.56259 8.89978 8.55661
      9.04333 8.55182H9.05529L9.1815 8.54644H9.20064L9.31488 8.54046H9.33522L9.44647 8.53268H9.46142L9.57746
      8.52311H9.58225C9.74175 8.50876 9.88909 8.48882 10.0243 8.4633L10.1176 8.44476H10.1241C10.1535 8.43818 10.184
      8.4316 10.2103 8.42442H10.2157C10.6086 8.32692 10.864 8.15705 11.0303 7.87114Z"
      fill="#468FC5"/>
    <path
      d="M6.18747 5.30312V4.69421C6.18747 2.66651 6.21678 1.51569 6.12287 0.861323C5.99906 5.70432e-07 5.66231 0
      4.76512 0C3.34277 0 3.34277 -4.56345e-07 3.34277 4.69421V5.30312H6.18747Z"
      fill="#262F3D"/>
    <path
      d="M6.07442 8.51774C6.17551 7.93097 6.18627 6.94882 6.18747 5.30273H3.34277C3.34277 6.84773 3.35354 7.80835
      3.43788 8.40649C3.44146 8.43161 3.44565 8.45614 3.44924 8.48006C3.44924 8.48006 3.44924 8.48484 3.44924
      8.48664L3.4624 8.54885C3.4624 8.55244 3.4624 8.55662 3.4624 8.56021C3.46599 8.57875 3.46898 8.5973 3.47317
      8.61524C3.47317 8.61943 3.47317 8.62421 3.47317 8.629C3.47317 8.64575 3.47974 8.6619 3.48393 8.67805L3.48752
      8.693L3.49829 8.73726C3.49829 8.74264 3.50128 8.74803 3.50247 8.75281C3.50367 8.7576 3.51025 8.77973 3.51384
      8.79289L3.51922 8.80904C3.52281 8.8216 3.527 8.83356 3.53059 8.84552C3.53417 8.85749 3.53417 8.85629 3.53657
      8.86167C3.53896 8.86706 3.54434 8.88381 3.54853 8.89517C3.55272 8.90654 3.55272 8.90534 3.55451 8.91072L3.56707
      8.94123C3.56707 8.94661 3.57186 8.9514 3.57365 8.95678L3.58741 8.98489C3.58935 8.98979 3.59155 8.99458 3.59399
      8.99925L3.60834 9.02556L3.61552 9.03932L3.63047 9.06325L3.63825 9.07581C3.64363 9.08358 3.64842 9.09136 3.6538
      9.09854L3.66217 9.1099C3.66756 9.11768 3.67354 9.12486 3.67892 9.13144C3.6843 9.13801 3.6843 9.13861 3.6873
      9.1416C3.69029 9.1446 3.69986 9.15596 3.70644 9.16254L3.71361 9.17091C3.72079 9.17869 3.72857 9.18587 3.73694
      9.19364L3.74113 9.19723C3.75969 9.21447 3.7797 9.23008 3.80094 9.24389L3.80991 9.24987L3.83204 9.26422L3.8452
      9.2714L3.86494 9.28217L3.87989 9.28935L3.89963 9.29832L3.91518 9.3049L3.93492 9.31267L3.95227 9.31865L3.972
      9.32523L3.99055 9.33121L4.01088 9.3366L4.03002 9.34138L4.05156 9.34677L4.07129 9.35095L4.09342 9.35514L4.11436
      9.35873L4.13709 9.36232H4.15862L4.18255 9.36531H4.20468L4.2292
      9.3683H4.25253H4.27765H4.30217H4.32789H4.35301H4.38053H4.40565H4.43555H5.05462H5.10486H5.11503C5.19817 9.36531
      5.27413 9.36052 5.34471 9.35215C5.36146 9.35215 5.3782 9.34796 5.39435 9.34497H5.39854L5.4446 9.3372C5.7951
      9.28396 5.97454 9.08777 6.07442 8.51774Z"
      fill="#262F3D"/>
    <path
      d="M17.938 14.9531C16.3544 17.4217 14.9463 20.1034 14.9463 21.0912C14.9675 21.8699 15.2919 22.6095 15.8505
      23.1528C16.409 23.696 17.1576 24 17.9369 24C18.7163 24 19.4649 23.696 20.0234 23.1528C20.5819 22.6095 20.9063
      21.8699 20.9276 21.0912C20.9276 20.1034 19.5194 17.4217 17.938 14.9531Z"
      fill="#468FC5"/>
    <path d="M18.93 22.4991C16.2252 23.4378 15.3241 21.6349 16.3214 19.832L18.93 22.4991Z"
          fill="white"/>
  </svg>

</template>

<style scoped>

</style>