<template>
  <base-popup
    title="Объект сети"
    :name="popupName"
  >
    <template #body>
      <div class="text-list">

        <div class="text-list__item">
          <div class="text-list__item-name">
            Наименование объекта
          </div>
          <div class="text-list__item-value">
            {{ networkObject.name }}
          </div>
        </div>

        <div class="text-list__item">
          <div class="text-list__item-name">
            Адрес
          </div>
          <div class="text-list__item-value">
            {{ networkObject.address }}
          </div>
        </div>

        <div class="text-list__item">
          <div class="text-list__item-name">
            Телефон
          </div>
          <div class="text-list__item-value">
            {{ networkObject.phone }}
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div
        @click="$modal.hide(popupName)"
        class="button">
        Вернуться к списку
      </div>
    </template>
  </base-popup>
</template>

<script>
import BasePopup from "@/components/BasePopup.vue";

export default {
  name: "NetworkObjectInfoPopupContent",
  components: {BasePopup},
  props: {
    popupName: {
      type: String,
      required: true
    },
    networkObject: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>