<template>
  <div class="base-table">
    <div class="base-table__container">
      <div class="base-table__header base-table__column--work">
        <div class="base-table__header-cell">ID</div>
        <div class="base-table__header-cell">Наименование</div>
        <div class="base-table__header-cell">Группа</div>
        <div class="base-table__header-cell"/>
      </div>

      <div v-for="work in worksList"
           :key="work.id"
           @click="$emit('open-work-modal', work.id)"
           class="base-table__row base-table__column--work">
        <div class="base-table__column">
          <div class="base-table__text">{{ work.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ work.name }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ work.group.name }}</div>
        </div>
        <div class="base-table__column base-table__column--content-right">
          <div class="base-table__short-info">
            <div @click.stop="$emit('edit-work', work)">
              <icon-pencil class="base-table__short-info--p-2" />
            </div>
            <div @click.stop="$emit('delete-work', work.id)">
              <icon-trash />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconTrash from "@/components/Icons/IconTrash.vue";
import IconPencil from "@/components/Icons/IconPencil.vue";

export default {
  name: "WorksList",
  components: {IconPencil, IconTrash},
  props: {
    worksList: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.base-table__column--work {
  grid-template-columns: 1fr 3fr 3fr 3fr;
}
</style>
