import { render, staticRenderFns } from "./ChangeStructuralUnitForm.vue?vue&type=template&id=234dc90e&scoped=true&"
import script from "./ChangeStructuralUnitForm.vue?vue&type=script&lang=js&"
export * from "./ChangeStructuralUnitForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "234dc90e",
  null
  
)

export default component.exports