<template>
  <div class="page-wrapper">
    <base-panel
      title="Трекинг"
      :is-add-button-visible="false" />

    <base-functional-line @search-change="event => $store.commit('autos/SET_SEARCH_QUERY', event.target.value)">
      <transport-tracking-filter />
    </base-functional-line>

    <div class="tracking-wrapper">
      <transports-list />
      <transports-map />
    </div>
  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel.vue";
import BaseFunctionalLine from "@/components/BaseFunctionalLine.vue";
import TransportTrackingFilter from "@/components/Transport/TransportTrackingFilter.vue";
import TransportsList from "@/components/Transport/TransportsList.vue";
import TransportsMap from "@/components/Transport/TransportsMap.vue";

export default {
  name: "TransportTrackingPage",
  components: {
    TransportsMap,
    TransportsList,
    TransportTrackingFilter,
    BaseFunctionalLine,
    BasePanel
  },
  data() {
    return {
      transportDataTiming: null,
      transportDayStatisticTiming: null
    };
  },
  mounted() {
    this.$store.dispatch("autos/getAllAutos");
    this.fetchTransportData();
    this.fetchTransportDayStatistic();

    this.transportDataTiming = setInterval(this.fetchTransportData, 60000);
    this.transportDayStatisticTiming = setInterval(this.fetchTransportDayStatistic, 1800000);
  },
  beforeDestroy() {
    this.$store.commit('autos/SET_AUTOS', []);
    this.$store.commit('transportData/SET_DATA', []);
    this.$store.commit('transportDayStat/SET_STAT', []);

    this.$store.dispatch('baseSideModal/closeModal');

    clearInterval(this.transportDataTiming);
    clearInterval(this.transportDayStatisticTiming);
  },
  methods: {
    fetchTransportData() {
      this.$store.dispatch('transportData/transportData');
    },
    fetchTransportDayStatistic() {
      this.$store.dispatch('transportDayStat/statData');
    }
  }
}
</script>

<style scoped>
.tracking-wrapper {
  display: flex;
  flex-direction: row;
  gap: 24px;
}
</style>
