<template>
  <div class="placeholder-loader"/>
</template>

<script>
export default {
  name: "BaseContentLoadersPlaceholder"
}
</script>

<style lang="scss" scoped>
.placeholder-loader {
  height: 150px;
  border-radius: 7px;
  width: 100%;

  background: linear-gradient(100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(70, 143, 197, 0.16) 50%,
      rgba(255, 255, 255, 0) 60%) var(--panel-bg);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

@keyframes loading {
  to {
    background-position-x: -30%;
  }
}

</style>
