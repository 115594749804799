<template>
  <div class="transport-orders-filter">
    <div class="transport-orders-filter__buttons-wrapper">
      <div class="filter__button"
           :class="{'opened': isFilterModalVisible}"
           @click="isFilterModalVisible = true">
        <icon-settings2/>
        <span>Фильтр</span>
      </div>
    </div>

    <base-filter-modal
      :is-visible="isFilterModalVisible"
      @toggle-modal="isVisible => this.isFilterModalVisible = isVisible">
      <div class="filter__modal-header">
        <div class="filter__modal-name">Фильтры</div>
        <div @click="resetFilters()"
             class="filter__modal-reset">Сбросить все
        </div>
      </div>
      <div class="filter__modal-form">
        <div>
          <label class="input-label">Тип транспорта</label>
          <div class="custom-radio-buttons">
            <div v-for="type in transportTypes"
                 :key="type.id"
                 @click="transport_type = type.id"
                 :class="{'active': transport_type === type.id}"
                 class="custom-radio-buttons__input">
              {{type.name}}
            </div>
          </div>
        </div>
      </div>
      <div class="filter__modal-buttons">
        <div @click="submitFilter()"
             class="button">Применить
        </div>
        <div @click="isFilterModalVisible = false"
             class="button button--mode-outline">Закрыть
        </div>
      </div>
    </base-filter-modal>
  </div>
</template>

<script>
import Vehicle from "@/models/transport/Vehicle";
import IconSettings2 from "@/components/Icons/IconSettings2";
import BaseFilterModal from "@/components/BaseFilterModal";
export default {
  name: "TransportOrdersFilter",
  components: {BaseFilterModal, IconSettings2},
  data() {
    return {
      transport_type: null,
      isFilterModalVisible: false
    }
  },
  computed: {
    transportTypes: () => Vehicle.getTransportTypes()
  },
  methods: {
    submitFilter() {
      this.$store.commit('transportOrders/SET_TRANSPORT_ORDERS_LIST', []);
      this.$store.commit('transportOrders/SET_LIST_FILTER_PARAMS', {
        prop: 'transport_type',
        value: this.transport_type
      });
      this.$store.dispatch('transportOrders/getTransportOrdersList');
      this.isFilterModalVisible = false;
    },
    resetFilters() {
      this.transport_type = null;
      this.submitFilter();
    }
  }
}
</script>

<style lang="scss" scoped>
.transport-orders-filter {
  position: relative;
}
</style>
