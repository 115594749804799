<template>
  <div>
    <div v-if="isResourceListLoaded"
         class="base-table">
      <div class="base-table__container">
        <div class="base-table__header base-table__header--resource-dashboard">
          <div
            class="base-table__header-cell base-table__header-cell-sort"
            :class="{'toggled': !isAscSort}"
            @click="toggleAscSort">
            Ресурс
            <icon-sort/>
          </div>
          <div class="base-table__header-cell">Всего выполнено</div>
          <div class="base-table__header-cell">Выполнены в срок
            <div class="base-table__header-cell-circle base-table__header-cell-circle--green"/>
          </div>
          <div class="base-table__header-cell">Выполнены с превышением
            <div class="base-table__header-cell-circle base-table__header-cell-circle--blue"/>
          </div>
        </div>

        <div v-for="resource in sortedResourceList"
             :key="resource.id"
             @click="setCurrentBrigade(resource)"
             class="base-table__row base-table__row--resource-dashboard">
          <div class="base-table__column base-table__column--resource-dashboard">
            <div class="base-table__text">{{ resource.name }}</div>
          </div>
          <div class="base-table__column base-table__column--resource-dashboard">
            <div class="base-table__text-label">Выполнены</div>
            <div class="base-table__column-bar">
              <div class="base-table__text">{{ resource.ratings.completed }}</div>
              <div class="base-table__resource-bar"
                   :style="calculateResourceBarGridProportions(resource)">
                <div class="base-table__resource-bar-item base-table__resource-bar-item--left"/>
                <div class="base-table__resource-bar-item base-table__resource-bar-item--right"/>
              </div>
            </div>
          </div>
          <div class="base-table__column base-table__column--resource-dashboard">
            <div class="base-table__text-label">Выполнены в срок</div>
            <div class="base-table__text">{{ resource.ratings.completed_on_time }}</div>
          </div>
          <div class="base-table__column base-table__column--resource-dashboard">
            <div class="base-table__text-label">Выполнены с превышением</div>
            <div class="base-table__text">{{ resource.ratings.completed_overtime }}</div>
          </div>
        </div>
      </div>
    </div>
    <base-content-loaders v-if="loading"/>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import IconSort from "@/components/Icons/IconSort.vue";

export default {
  name: 'ResourceDashboardList',
  components: {IconSort, BaseContentLoaders},
  data() {
    return {
      isAscSort: false
    }
  },
  computed: {
    ...mapGetters({
      resourceData: 'reportsData/getReportData',
      loading: 'reportsData/getReportLoading'
    }),
    isResourceListLoaded() {
      return this.resourceData.length && !this.loading;
    },
    sortedResourceList() {
      if (this.isAscSort) {
        return [...this.resourceData].sort((a, b) => {
          if (a.name < b.name) {
            return 1;
          }
          if (a.name > b.name) {
            return -1;
          }
          return 0;
        })
      } else {
        return [...this.resourceData].sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
      }
    },
    completedSum() {
      return this.resourceData.reduce((sum, resource) =>
              sum + resource.ratings.completed
          , 0)
    },
    completedOnTimeSum() {
      return this.resourceData.reduce((sum, resource) =>
              sum + resource.ratings.completed_on_time
          , 0)
    },
    completedOvertimeSum() {
      return this.resourceData.reduce((sum, resource) =>
              sum + resource.ratings.completed_overtime
          , 0)
    }
  },
  methods: {
    toggleAscSort() {
      this.isAscSort = !this.isAscSort;
    },
    calculateResourceBarGridProportions(resource) {
      const completedOnTimePercent = Math.round(resource.ratings.completed_on_time / resource.ratings.completed * 100);
      const completedOvertimePercent = 100 - completedOnTimePercent;
      return {gridTemplateColumns: `${completedOnTimePercent}% ${completedOvertimePercent}%`};
    },
    setCurrentBrigade(brigade) {
      this.$emit("setCurrentBrigade", brigade)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>