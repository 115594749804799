<template>
  <div :style="{width: containerWidth}">
    <slot
      name="toggle"
      :toggle="toggleCollapse"
      :isCollapsed="isCollapsed"
    />
    <transition name="fadeHeight">
      <slot
        v-if="!isCollapsed"
        name="body"
      />
    </transition>
  </div>
</template>

<script>
export default {
  name: "BaseCollapse",
  props: {
    defaultCollapseState: {
      type: Boolean,
      default: false
    },
    containerWidth: {
      default: 'auto'
    }
  },
  data() {
    return {
      isCollapsed: false
    }
  },
  beforeMount() {
    this.isCollapsed = this.defaultCollapseState;
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    }
  }
}
</script>

<style scoped>
.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all .3s linear;
  max-height: 100vh;
}

.fadeHeight-enter,
.fadeHeight-leave-to {
  max-height: 0;
  opacity: 0;
}
</style>