<template>
  <div class="base-table">
    <div class="base-table__container">
      <div class="base-table__header base-table__column--structural-units">
        <div class="base-table__header-cell">ID</div>
        <div class="base-table__header-cell">Наименование</div>
        <div class="base-table__header-cell">Руководитель</div>
        <div class="base-table__header-cell">Кому подчиняется</div>
        <div class="base-table__header-cell"/>
      </div>

      <div v-for="structuralUnit in structuralUnitList"
           :key="structuralUnit.id"
           class="base-table__row base-table__column--structural-units">
        <div class="base-table__column">
          <div class="base-table__text">{{ structuralUnit.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ structuralUnit.name }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ structuralUnit.headName }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ structuralUnit.parentName }}</div>
        </div>
        <div class="base-table__column base-table__column--content-right">
          <div class="base-table__short-info">
            <div @click="$emit('update-structural-unit', structuralUnit)">
              <icon-pencil class="base-table__short-info--p-2" />
            </div>
            <div @click="$emit('delete-structural-unit', structuralUnit.id)">
              <icon-trash />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconTrash from "@/components/Icons/IconTrash.vue";
import IconPencil from "@/components/Icons/IconPencil.vue";

export default {
  name: "StructuralUnitsList",
  components: {IconPencil, IconTrash},
  props: {
    structuralUnitList: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.base-table__column--structural-units {
  grid-template-columns: 1fr 3fr 2fr 2fr 1fr;
}
</style>