<template>
  <div
    id="map-block"
    class="map-base-height reset-leaflet-popup-styles"
  >
    <div class="telemetry-type-block">
      <div
        class="telemetry-type"
        @click="changeTypeTelemetry(1)">
        <img
          alt="ВНС"
          src="@/assets/img/telemetry/pump.svg">
      </div>
      <div
        class="telemetry-type"
        @click="changeTypeTelemetry(2)">
        <img
          alt="РЧВ"
          src="@/assets/img/telemetry/tank.svg">
      </div>
      <div
        class="telemetry-type"
        @click="changeTypeTelemetry(3)">
        <img
          alt="МКД"
          src="@/assets/img/telemetry/mkd.svg">
      </div>
      <div
        class="telemetry-type"
        @click="changeTypeTelemetry(4)">
        <img
          alt="КП"
          src="@/assets/img/telemetry/counter.svg">
      </div>
      <div
        class="telemetry-type"
        @click="changeTypeTelemetry(5)">
        <img
          alt="Котельная"
          src="@/assets/img/telemetry/boiler.svg">
      </div>
      <div
        class="telemetry-type"
        @click="changeTypeTelemetry(0)">
        <img
          alt="Все устройства"
          src="@/assets/img/telemetry/telemetry-all.svg">
      </div>
    </div>

    <base-map :center="center"
              :zoom="zoom"
              :base-layers="baseLayers"
              :layers="layers"
              :markers="createMapMarkers()"/>

  </div>
</template>

<script>
import formatDate from "@/mixins/formatDate";
import BaseMap from "@/components/BaseMap";
import Marker from "@/models/baseMap/Marker";
import Icon from '@/models/baseMap/Icon'
import {mapGetters, mapState} from "vuex";
import sensorsTime from "@/mixins/sensorsTime";
import {roundedNumber} from "@/utils/helpers";
import {isValueOutOfProfile} from "@/utils/helpers";

export default {
  name: "TelemetryMap",
  components: {
    BaseMap
  },
  mixins: [formatDate, sensorsTime],
  props: [
    'activeTab'
  ],
  computed: {
    ...mapGetters({
      parameterValue: 'telemetry/getParameterValue',
      networkObjectsByType: 'telemetry/getNetworkObjectsByType',
      networkObjectEquipments: 'telemetry/getNetworkEquipmentByNetworkObjectId',
      parameterProfile: 'telemetry/getParameterProfile'
    }),
    ...mapState({
      center: state => state.baseMap.center,
      zoom: state => state.baseMap.zoom,
      layers: state => state.baseMap.layers,
      baseLayers: state => state.baseLayers.baseLayers
    }),
    getIconClass() {
      return construction_type => {
        switch (construction_type) {
          case 1:
            return 'map-pump-svg'
          case 2:
            return 'map-tank-svg'
          case 3:
            return 'map-mkd-svg'
          case 4:
            return 'map-counter-svg'
          case 5:
            return 'map-boiler-svg'
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch('baseMap/getLayers');
  },
  methods: {
    isValueOutOfProfile,
    createMapMarkers() {
      let markers = []

      this.networkObjectsByType(this.activeTab).forEach(networkObject => {
        if (networkObject.showOnMap && this.networkObjectEquipments(networkObject.id).length) {
          if (networkObject.fixOnMap) {
            let html = '<div class="headline">';
            this.networkObjectEquipments(networkObject.id).forEach(equipment => {
              html += '<div class="">'
              html += `<div>${equipment.name}</div>`

              if (equipment.parameters.length) {
                equipment.parameters.forEach(parameter => {
                  html += `<div>${parameter.shortName}: ` +
                      `${roundedNumber(
                        this.parameterValue(parameter.id).value,
                        parameter.precision)} ${parameter.measure}</div>`
                })
              }

              html += '</div>'
            })
            html += '</div>'

            markers.push(
                new Marker({
                  coords: networkObject.coordinates,
                  icon: new Icon({
                    type: 'div_icon',
                    anchor: [15, 15],
                    class: this.getIconClass(networkObject.type) + ' telemetry-map-icon',
                    html: html
                  })
                }))
          } else if (!networkObject.fixOnMap) {
            let popupContent = '<div class="network-object-popup-wrapper">'
            popupContent += '<div class="network-object-popup-wrapper__inner">'

            this.networkObjectEquipments(networkObject.id).forEach(equipment => {
              popupContent += this.createEquipmentHtml(equipment);

              if (equipment.parameters.length) {
                equipment.parameters.forEach(parameter => {
                  popupContent += this.createParameterHtml(parameter)
                })
              } else popupContent += `<div>Параметры отсутствуют</div>`
            })

            popupContent += '</div></div>'

            markers.push(
                new Marker({
                  coords: networkObject.coordinates,
                  popup_content: popupContent,
                  icon: new Icon({
                    type: 'div_icon',
                    anchor: [15, 15],
                    class: this.getIconClass(networkObject.type) + ' telemetry-map-icon'
                  })
                }))
          }
        }
      })

      return markers
    },
    createEquipmentHtml(equipment) {
      const networkObject = this.networkObjectsByType(this.activeTab).length ?
        this.networkObjectsByType(this.activeTab).find(object => object.id === equipment.networkObjectId) : {}

      return `<div class=" map-device-item">
              <div class="">
                <div class="${this.getIconClass(networkObject?.type)} map-device-icon"></div>
              </div>
                <div class="device-name">${equipment.name}</div>
              </div>`
    },
    createParameterHtml(parameter) {
      let html = `<div class=" parameter">
                    <div class="parameter__value_wrapper">`

      if (this.statusValue(parameter)) {
        html += '<div class="sensor-short-name sensor_danger_value">'
      } else {
        html += '<div class="sensor-short-name">'
      }

      html += `<div id="shortName_${parameter.id}"
                    class=" sensor-short-name-content">
                   ${this.processParameterShortName(parameter)}
               </div>
               </div>
               <div class="">
                  <div class="sensor-value">
                    ${roundedNumber(this.parameterValue(parameter.id).value, parameter.precision)}
                  </div>
                  <div class="sensor-measure">
                    ${parameter.measure}
                  </div>
               </div>
               </div>
               <div class="time-block ${this.getParameterValueDateClass(parameter.id)}">
                    ${this.formatParameterValueDate(parameter.id)}
               </div>
              </div>`

      return html
    },
    processParameterShortName(parameter) {
      let name = parameter.shortName
      if (name.length > 1) {
        let nameArr = name.split('')
        return `<div class="">${nameArr[0]} <sub>${nameArr.splice(1).join()}</sub></div>`
      } else {
        return `<div>${name}</div>`
      }
    },
    changeTypeTelemetry(type) {
      this.$emit('change-active-tab', type)
    },
    statusValue(parameter) {
      const sensor_value = this.parameterValue(parameter.id);
      return isValueOutOfProfile(sensor_value.value, this.parameterProfile(parameter.id))
    }
  }
}

</script>

<style lang="scss">
.parameter {
  display: flex;
  flex-direction: column;
}

.parameter__value_wrapper {
  display: flex;
}

.map_tooltip_sensor {
  text-align: center;
  padding: 3px;
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  margin-bottom: 4px;
  background-color: #8080801a;
}

.map_tooltip_time {
  border-radius: 10px;
  color: var(--text-panel);
  font-size: 12px;
  padding: 3px 7px;
}

.time_success {
  color: #04BD00;
}

.time_warn {
  color: #FFBF1C;
}

.time_error {
  color: #E02800;
}

.headline {
  width: 80px;
  background-color: var(--panel);
  color: var(--text-primary);
  margin-left: -20px;
  border-radius: 10px;
  padding: 5px;
  margin-top: 5px;
  font-size: 10px;
}

.headline-right {
  float: right;
  background-color: var(--panel);
  border-radius: 10px;
  padding: 5px;
  font-size: 10px;
}

.icon-img {
  display: inline;
}

.icon-block {
  min-width: 150px;
}

.danger_value {
  color: var(--border-validation);
}

.telemetry-type-block {
  position: absolute;
  z-index: 999;
  right: 22px;
  top: calc(50% - 157px);
  background-color: rgba(10, 97, 135, 0.7);
  padding: 10px;
  border-radius: 14px;
  backdrop-filter: blur(4px);
}

.telemetry-type {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: #EAF9FF;
  border: 2px solid var(--status-in-work);
  border-radius: 7px;
  backdrop-filter: blur(2px);
  margin-bottom: 15px;
}

.telemetry-type:nth-last-child(1) {
  margin-bottom: 0;
}

.icon-bg {
  width: 30px !important;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  padding: 3px !important;
}

.time-block {
  font-size: 12px;
  font-weight: 700;
}

.device-name {
  display: block;
  font-size: 14px;
  font-weight: 700;
  color: var(--text-link);
}

.map-device-item:not(.map-device-item:first-child) {
  padding-top: 10px;
}

.network-object-popup-wrapper {
  background-color: var(--panel);
  padding: 8px;
  border-radius: 12px;

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}

@media (max-height: 500px) {
  .telemetry-type-block {
    display: flex;
    top: 10px;
    right: calc(50% - 167px);
  }

  .telemetry-type {
    margin-bottom: 0;
    margin-right: 15px;
  }

  .telemetry-type:nth-last-child(1) {
    margin-right: 0;
  }
}
</style>
