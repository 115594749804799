<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="17"
    viewBox="0 0 24 17"
    fill="none">
    <g clip-path="url(#clip0_5058_3508)">
      <path
        d="M2.39763 10.4644V12.0936H13.6007V10.4644H2.39763Z"
        fill="#089F7A"/>
      <path
        d="M16.4 1.9142C15.7421 1.9142 15.1977 2.46833 15.1977 3.13794V12.0939H23.2V7.20679C23.1996 7.1671 23.1935
        7.12772 23.182 7.08987L21.9836 3.02021L21.9891 3.05036C21.9312 2.77554 21.8223 2.50859 21.6266 2.2902C21.4309
        2.07181 21.1311 1.91406 20.7992 1.91406L16.4 1.9142Z"
        fill="#27D8AD"/>
      <path d="M22 9.7059C22 9.31604 22.316 9 22.7059 9H23.4148V10.4118H22.7059C22.316 10.4118 22 10.0958 22 9.7059Z"
            fill="#CEFFDC"/>
      <path
        d="M16.7984 3.94904V6.39257C16.7976 6.61866 16.9779 6.80216 17.1999 6.8013H18.2336L18.9156 7.49548C18.9907
         7.57226 19.0928 7.61551 19.1992 7.61551H21.1273C21.3963 7.61593 21.5891 7.35182 21.5109 7.08991L20.5515
         3.83212C20.5001 3.66006 20.3439 3.54271 20.1672 3.54346H17.1793C16.9539 3.56439 16.7982 3.73974 16.7984
         3.94904Z"
        fill="#C3FFD7"/>
      <path
        d="M1.19919 11.6846C1.1466 11.6847 1.09456 11.6955 1.04606 11.7162C0.99756 11.7369 0.953546 11.7671 0.916543
         11.8052C0.879532 11.8432 0.850258 11.8883 0.83039 11.9379C0.810522 11.9875 0.800451 12.0405 0.800754
         12.094V13.7225C0.801171 13.83 0.843283 13.9329 0.917915 14.0088C0.992546 14.0847 1.09365 14.1276 1.19919
          14.128H23.5984C23.7045 14.1285 23.8064 14.0859 23.8817 14.0099C23.957 13.9339 23.9996 13.8305 23.9999
          13.7225V12.094C24.0003 12.0402 23.9901 11.9869 23.97 11.9372C23.9499 11.8874 23.9204 11.8421 23.8831
          11.8041C23.8457 11.766 23.8013 11.7358 23.7525 11.7153C23.7036 11.6948 23.6512 11.6843 23.5984
          11.6846H1.19919Z"
        fill="#262F3D"/>
      <path
        d="M18.8003 10.8701C17.2587 10.8701 15.9982 12.1531 15.9982 13.7222C15.9982 15.2913 17.2587 16.5712 18.8003
        16.5712C20.342 16.5712 21.5996 15.2913 21.5996 13.7222C21.5996 12.1531 20.342 10.8701 18.8003 10.8701Z"
        fill="#262F3D"/>
      <path
        d="M18.8004 12.499C18.1424 12.499 17.5986 13.0525 17.5986 13.7222C17.5986 14.3919 18.1424 14.9423 18.8004
         14.9423C19.4584 14.9423 19.9992 14.3919 19.9992 13.7222C19.9992 13.0525 19.4584 12.499 18.8004 12.499Z"
        fill="white"/>
      <path
        d="M13.1992 0.285645C13.1465 0.285649 13.0943 0.296275 13.0456 0.316872C12.9969 0.337469 12.9528 0.367636
         12.9156 0.40567L11.0343 2.32284H2.96485L1.88126 1.21993C1.80694 1.14388 1.70616 1.10075 1.60079
         1.0999H0.398446C0.292364 1.10033 0.190794 1.14366 0.116074 1.22031C0.0413546 1.29694 -0.000395364 1.40066
          6.45634e-06 1.50862V8.83526C-0.000292089 8.88879 0.00976586 8.94176 0.0296056 8.99122C0.0494454 9.04076
          0.0786783 9.08584 0.115635 9.1239L1.71563 10.7532C1.79121 10.8288 1.89321 10.8709 1.99923
          10.8701H13.9992C14.1047 10.8696 14.2059 10.8269 14.2805 10.7508C14.3551 10.6749 14.3973 10.572 14.3977
           10.4646V2.49151L15.8828 0.98307C15.9396 0.926208 15.9783 0.853407 15.9942 0.773965C16.0101 0.694522
           16.0023 0.612057 15.9718 0.537153C15.9414 0.46225 15.8896 0.398294 15.8233 0.353499C15.7569 0.308705
            15.6789 0.285132 15.5992 0.285738L13.1992 0.285645Z"
        fill="#27D8AD"/>
      <path
        d="M2.75808 3.95172C2.65927 3.96177 2.56767 4.00887 2.50109 4.08386C2.43451 4.15885 2.39771 4.25636 2.39785
         4.35744V8.83538C2.39764 8.88908 2.4079 8.9424 2.428 8.99203C2.44811 9.04174 2.47768 9.08683 2.51502
         9.1248C2.55235 9.16286 2.5967 9.19294 2.64551 9.21343C2.69432 9.23383 2.74663 9.24428 2.79942
         9.24411H4.39982C4.45236 9.24394 4.50435 9.23323 4.55282 9.21257C4.60129 9.19191 4.64529 9.16174 4.68231
         9.12377C4.71933 9.08589 4.74863 9.0408 4.76856 8.99134C4.78849 8.94188 4.79864 8.88883 4.79843
         8.83538V4.35744C4.79806 4.24995 4.75594 4.14699 4.68127 4.07099C4.6066 3.99499 4.50542 3.9521 4.39982
         3.95172H2.79942C2.78566 3.95099 2.77185 3.95099 2.75808 3.95172Z"
        fill="#089F7A"/>
      <path
        d="M6.36044 3.95213C6.26164 3.96218 6.17003 4.00928 6.10346 4.08428C6.03687 4.15926 6.00007 4.25678 6.00021
        4.35785V8.8358C6.00001 8.88924 6.01016 8.9423 6.03008 8.99175C6.05001 9.04121 6.07932 9.0863 6.11634
        9.12418C6.15336 9.16215 6.19736 9.19232 6.24583 9.21298C6.2943 9.23364 6.34629 9.24435 6.39884
        9.24452H7.99922C8.05177 9.24435 8.10376 9.23364 8.15223 9.21298C8.2007 9.19232 8.24471 9.16215 8.28172
        9.12418C8.31874 9.0863 8.34805 9.04121 8.36798 8.99175C8.3879 8.9423 8.39805 8.88924 8.39785
        8.8358V4.35785C8.39748 4.25036 8.35536 4.14741 8.28068 4.0714C8.20601 3.9954 8.10483 3.95252 7.99922
        3.95213H6.39884C6.38605 3.9515 6.37323 3.9515 6.36044 3.95213Z"
        fill="#089F7A"/>
      <path
        d="M9.95991 3.95213C9.86109 3.96218 9.76946 4.00928 9.70286 4.08428C9.63634 4.15926 9.59949 4.25678 9.59966
        4.35785V8.8358C9.59949 8.88924 9.6096 8.9423 9.62949 8.99175C9.64946 9.04121 9.67877 9.0863 9.7158
         9.12418C9.75283 9.16215 9.7968 9.19232 9.84523 9.21298C9.89374 9.23364 9.94569 9.24435 9.99823
         9.24452H11.5987C11.6515 9.2447 11.7038 9.23432 11.7525 9.21384C11.8014 9.19335 11.8457 9.16327 11.8831
         9.1253C11.9204 9.08724 11.9499 9.04215 11.9701 8.99244C11.9902 8.94281 12.0004 8.8895 12.0003
         8.8358V4.35785C12.0001 4.30437 11.9895 4.25147 11.9692 4.20214C11.9489 4.1528 11.9193 4.108 11.8821
         4.07032C11.8448 4.03265 11.8005 4.00284 11.7519 3.98256C11.7033 3.96228 11.6512 3.95192 11.5987
         3.95213H9.99823C9.98546 3.9515 9.97269 3.9515 9.95991 3.95213Z"
        fill="#089F7A"/>
      <path
        d="M5.2001 10.8701C3.65845 10.8701 2.39795 12.1531 2.39795 13.7222C2.39795 15.2913 3.65845 16.5712 5.2001
         16.5712C6.74176 16.5712 7.99932 15.2913 7.99932 13.7222C7.99932 12.1531 6.74176 10.8701 5.2001 10.8701Z"
        fill="#262F3D"/>
      <path
        d="M5.19982 12.499C4.54183 12.499 3.99806 13.0525 3.99805 13.7222C3.99805 14.3919 4.54182 14.9423 5.19982
         14.9423C5.85783 14.9423 6.39865 14.3919 6.39864 13.7222C6.39863 13.0525 5.85782 12.499 5.19982 12.499Z"
        fill="white"/>
      <path
        d="M17.1587 8.42974C17.053 8.43586 16.9539 8.48433 16.8832 8.56456C16.8125 8.64479 16.7759 8.75027 16.7814
        8.85787C16.7868 8.96553 16.8339 9.06659 16.9124 9.13893C16.9909 9.21136 17.0943 9.24916 17.2
        9.24419H18.0003C18.053 9.24445 18.1052 9.23416 18.154 9.21376C18.2028 9.19345 18.2471 9.16353 18.2845
        9.12565C18.3219 9.08785 18.3515 9.04284 18.3717 8.9933C18.392 8.94376 18.4024 8.89062 18.4024
        8.83696C18.4024 8.7833 18.392 8.73019 18.3717 8.68063C18.3515 8.63109 18.3219 8.58611 18.2845 8.54826C18.2471
         8.51042 18.2028 8.48046 18.154 8.46012C18.1052 8.43978 18.053 8.42946 18.0003 8.42974H17.2C17.1863 8.42902
         17.1725 8.42902 17.1587 8.42974Z"
        fill="#CEFFDC"/>
    </g>
  </svg>
</template>
<script>
export default {
  name: "IconTruck2"
}
</script>
