<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      Изменить структурное подразделение
      <span @click="$modal.hide('change-structural-unit-modal')"
            class="base-popup__close">
        <icon-close/>
      </span>
    </div>
    <div class="popup-form-group">
      <div class="form-item">
        <div class="form-item__name">Структурное подразделение</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.structural_unit_id"
            :options="structuralUnitsList"
            :reduce="type => type.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите из списка"
            class="vue-select-input"
          />
        </div>
      </div>

    </div>
    <div class="base-popup__footer">
      <div @click="changeStructuralUnit(payload)"
           class="button">
        Сохранить
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from "@/components/Icons/IconClose.vue";

export default {
  name: "ChangeStructuralUnitForm",
  components: {IconClose},
  props: {
    structuralUnitsList: {
      type: Array,
      required: true
    },
    changeStructuralUnit: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      payload: {
        structural_unit_id: null
      }
    }
  }
}
</script>

<style scoped>

</style>