import * as API from "@/services/API";
import {getError} from "@/utils/helpers";
import Department from "@/models/serviceDesk/Department";

export default {
    getAllDepartments() {
        return new Promise((resolve, reject) => {
            API.apiClient.get('service-desk/departments/all')
                .then(response => {
                    resolve(response.data.data.map(department =>
                        new Department(department.id, department.attributes)))
                })
                .catch(error => reject(getError(error)));
        });
    },
    getDepartments(page) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/departments?page=${page}`)
                .then(response => {
                    resolve({
                        departments: response.data.data.map(department => {
                            return new Department(department.id, department.attributes)
                        }),
                        meta: response.data.meta,
                        links: response.data.links
                    })
                })
                .catch(error => reject(getError(error)));
        })
    },
    fetchDepartment(department_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/departments/${department_id}`)
                .then(response => {
                    const {id, attributes} = response.data.data
                    resolve(new Department(id, attributes));
                })
                .catch(error => reject(getError(error)));
        })
    },
    createDepartment(payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`service-desk/departments`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateDepartment({department_id, payload}) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`service-desk/departments/${department_id}`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    activateDepartment(department_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`service-desk/departments/${department_id}/activate`)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    deactivateDepartment(department_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`service-desk/departments/${department_id}/deactivate`)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    }
}