import FuelType from "@/models/serviceDesk/FuelType";
import OilType from "@/models/serviceDesk/OilType";

export default class MechanicalEquipment {
    constructor(id, params) {
        this.id = id;
        this.name = params.name;
        this.fuelType = new FuelType(params.fuel_type);
        this.oilType = new OilType(params.oil_type);
        this.fuelConsumption = params.fuel_consumption;
        this.oilConsumption = params.oil_consumption;
    }
}