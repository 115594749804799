<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      Вид работ технического обслуживания
      <span @click="$modal.hide('technical-service-work-form')"
            class="base-popup__close">
        <icon-close/>
      </span>
    </div>
    <div class="popup-form-group">
      <div class="form-item">
        <div class="form-item__name">Наименование</div>
        <div class="form-item__value">
          <input
            v-model="payload.name"
            class="base-input base-input--bordered"
            type="text"
            placeholder="Введите наименование">
        </div>
      </div>
    </div>
    <div class="base-popup__footer">
      <div v-if="technicalServiceWork"
           @click="checkType()"
           class="button">
        Изменить
      </div>
      <div v-else
           @click="checkType()"
           class="button">
        Добавить
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from "@/components/Icons/IconClose";
import {showToast, showAlert} from "@/utils/notification";

export default {
  name: "TechnicalServiceWorksModal",
  components: {IconClose},
  props: {
    technicalServiceWork: {}
  },
  data() {
    return {
      payload: {
        name: null
      }
    }
  },
  mounted() {
    if (this.technicalServiceWork) {
      this.payload.name = this.technicalServiceWork.name;
    }
  },
  methods: {
    checkType() {
      if (this.technicalServiceWork) this.updateTechnicalServiceWork()
      else this.createTechnicalServiceWork()
    },
    createTechnicalServiceWork() {
      this.$store.dispatch('technicalServiceWorks/createTechnicalServiceWork', this.payload)
          .then(() => {
            this.payload.name = null;
            showToast('Вид работ технического обслуживания добавлен', 'success');
            this.$store.dispatch('technicalServiceWorks/getTechnicalServiceWorks');
            this.$modal.hide('technical-service-work-form');
          })
          .catch(error => showAlert('Ошибка', error))
    },
    updateTechnicalServiceWork() {
      this.$store.dispatch('technicalServiceWorks/updateTechnicalServiceWork', {
        id: this.technicalServiceWork.id,
        payload: this.payload
      })
        .then(() => {
          this.payload.name = null;
          showToast('Вид работ технического обслуживания изменён', 'success');
          this.$store.dispatch('technicalServiceWorks/getTechnicalServiceWorks');
          this.$modal.hide('technical-service-work-form');
        })
        .catch(error => showAlert('Ошибка', error))
    }
  }
}
</script>

<style scoped>

</style>
