import * as API from './API';
import Dictionary from "@/models/TechnicalInspection/Dictionary";
import {getError} from "@/utils/helpers";

export default {
    fetchDictionariesList() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/technical-inspection/reference-books/dictionaries`)
                .then(response => resolve(response.data.data
                    .map(item => new Dictionary({id: item.id, ...item.attributes}))))
                .catch(error => reject(getError(error)))
        })
    },
    fetchDictionary(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/technical-inspection/reference-books/dictionaries/${id}`)
                .then(response =>
                    resolve(new Dictionary({id: response.data.data.id, ...response.data.data.attributes})))
                .catch(error => reject(getError(error)))
        })
    },
    createDictionary(payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`/technical-inspection/reference-books/dictionaries`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    createDictionaryItem(id, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`/technical-inspection/reference-books/dictionaries/${id}/items`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateDictionary(id, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/technical-inspection/reference-books/dictionaries/${id}`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateDictionaryItem(id, itemId, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/technical-inspection/reference-books/dictionaries/${id}/items/${itemId}`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteDictionary(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`/technical-inspection/reference-books/dictionaries/${id}`)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteDictionaryItem(id, itemId) {
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`/technical-inspection/reference-books/dictionaries/${id}/items/${itemId}`)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}