<template>
  <div class="page-wrapper">
    <div class="panel">
      <button
        v-if="isBrigadeInfoVisible"
        @click="toggleDashBoardView"
        class="button button--mode-skinny"
      ><img src="@/assets/img/icons/arrowSquareLeft.svg"
            alt="стрелочка">Статистика по ресурсам</button>
      <base-panel
        :title="pageTitle"
        :is-add-button-visible="false"/>
    </div>
    <resource-dashboard-filter @updateDashboardView="toggleDashBoardView"/>
    <resource-dashboard-item v-if="isBrigadeInfoVisible"
                             :brigade="currentBrigade" />
    <resource-dashboard-list v-else
                             @setCurrentBrigade="toggleBrigadeView($event)"/>

  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel.vue";
import ResourceDashboardFilter from "@/components/ServiceDesk/ResourceDashboardFilter.vue";
import ResourceDashboardList from "@/components/ServiceDesk/ResourceDashboardList.vue";
import ResourceDashboardItem from "@/components/ServiceDesk/ResourceDashboardItem.vue";

export default {
  components: {
    ResourceDashboardItem,
    ResourceDashboardList,
    ResourceDashboardFilter,
    BasePanel
  },
  data() {
    return {
      currentBrigade: null,
      isBrigadeInfoVisible: false
    }
  },
  computed: {
    pageTitle() {
      return this.isBrigadeInfoVisible ? this.currentBrigade.name : 'Статистика по ресурсам'
    }
  },
  methods: {
    toggleBrigadeView(brigade) {
      this.isBrigadeInfoVisible = true;
      this.currentBrigade = brigade;
    },
    toggleDashBoardView() {
      this.isBrigadeInfoVisible = false;
    }
  }

}
</script>

<style lang="scss" scoped>
</style>