import * as API from './API';
import {getError} from "@/utils/helpers";
import ParameterValue from "@/models/telemetry/ParameterValue";

export default {
    createNetworkEquipmentParameter(equipmentId, payload) {
        return API.apiClient.post(`/network-equipments/${equipmentId}/parameters`, payload);
    },
    updateNetworkEquipmentParameter(equipmentId, parameterId, payload) {
        return API.apiClient.patch(`/network-equipments/${equipmentId}/parameters/${parameterId}`, payload);
    },
    deleteNetworkEquipmentParameter(equipmentId, parameterId) {
        return API.apiClient.delete(`/network-equipments/${equipmentId}/parameters/${parameterId}`);
    },
    fetchLatestParametersValues() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/network-equipments/momentary-readings`)
                .then(response => {
                    const {readings, profiles} = response.data.data;
                    resolve({
                        parametersValues: readings.map(item => new ParameterValue({
                            id: item.id,
                            ...item.attributes
                        })),
                        profiles
                    })
                })
                .catch(error => reject(getError(error)))
        })
    },
    updateParameterProfile(equipmentId, parameterId, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/network-equipments/${equipmentId}/parameters/${parameterId}/profile`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    getParameterProfile(equipmentId, parameterId) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/network-equipments/${equipmentId}/parameters/${parameterId}/profile`)
                .then(response => resolve(response.data.data))
                .catch(error => reject(getError(error)))
        })
    }
}