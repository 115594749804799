<script>
export default {
  name: "IconSettings4"
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M17.5 5.95833H13M5.5 5.95833H2.5M17.5 14.2083H14.5M7 14.2083H2.5M8.5 8.58333C8.84472 8.58333 9.18606 8.51544 9.50454 8.38352C9.82302 8.2516 10.1124 8.05824 10.3562 7.81449C10.5999 7.57073 10.7933 7.28136 10.9252 6.96288C11.0571 6.6444 11.125 6.30305 11.125 5.95833C11.125 5.61361 11.0571 5.27227 10.9252 4.95379C10.7933 4.63531 10.5999 4.34593 10.3562 4.10218C10.1124 3.85842 9.82302 3.66507 9.50454 3.53315C9.18606 3.40123 8.84472 3.33333 8.5 3.33333C7.80381 3.33333 7.13613 3.60989 6.64384 4.10218C6.15156 4.59446 5.875 5.26214 5.875 5.95833C5.875 6.65453 6.15156 7.32221 6.64384 7.81449C7.13613 8.30677 7.80381 8.58333 8.5 8.58333ZM11.5 16.8333C12.1962 16.8333 12.8639 16.5568 13.3562 16.0645C13.8484 15.5722 14.125 14.9045 14.125 14.2083C14.125 13.5121 13.8484 12.8445 13.3562 12.3522C12.8639 11.8599 12.1962 11.5833 11.5 11.5833C10.8038 11.5833 10.1361 11.8599 9.64384 12.3522C9.15156 12.8445 8.875 13.5121 8.875 14.2083C8.875 14.9045 9.15156 15.5722 9.64384 16.0645C10.1361 16.5568 10.8038 16.8333 11.5 16.8333Z" stroke="#9192AD" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<style scoped>

</style>