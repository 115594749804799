import { render, staticRenderFns } from "./IconSort2.vue?vue&type=template&id=7ec9373a&scoped=true&"
import script from "./IconSort2.vue?vue&type=script&lang=js&"
export * from "./IconSort2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ec9373a",
  null
  
)

export default component.exports