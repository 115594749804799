<template>
  <svg width="24"
       height="24"
       viewBox="0 0 24 24"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M9.32007 6.5L11.8801 3.94L14.4401 6.5"
          stroke="#9192AD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M11.8799 14.18V4.01"
          stroke="#9192AD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M4 12C4 16.42 7 20 12 20C17 20 20 16.42 20 12"
          stroke="#9192AD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
  name: 'IconUpload'
}
</script>

<style lang="scss" scoped>

</style>