<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="24"
       height="24"
       viewBox="0 0 24 24"
       fill="none">
    <path
      d="M18.0712 8.50867L9.51124 4.22867C3.76124 1.34867 1.40124 3.70867 4.28124 9.45867L5.15124 11.1987C5.40124
       11.7087 5.40124 12.2987 5.15124 12.8087L4.28124 14.5387C1.40124 20.2887 3.75124 22.6487 9.51124 19.7687L18.0712
        15.4887C21.9112 13.5687 21.9112 10.4287 18.0712 8.50867ZM14.8412 12.7487H9.44124C9.03124 12.7487 8.69124
        12.4087 8.69124 11.9987C8.69124 11.5887 9.03124 11.2487 9.44124 11.2487H14.8412C15.2512 11.2487 15.5912 11.5887
         15.5912 11.9987C15.5912 12.4087 15.2512 12.7487 14.8412 12.7487Z"
      fill="#9192AD"/>
  </svg>
</template>

<script>
export default {
  name: "IconSend"
}
</script>

<style scoped>

</style>
