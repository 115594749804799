<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      <template v-if="!Object.keys(layer).length">Добавить слой</template>
      <template v-else>Редактирование слоя</template>
      <span @click="$modal.hide('layer-form')"
            class="base-popup__close">
        <icon-close/>
      </span>
    </div>
    <div class="popup-form-group">
      <div class="form-item">
        <div class="form-item__name">Наименование</div>
        <div class="form-item__value">
          <input
            v-model="payload.name"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите наименование"
            required
          >
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">URL</div>
        <div class="form-item__value">
          <input
            v-model="payload.url"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите URL"
            required
          >
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Ресурс</div>
        <div class="form-item__value">
          <input
            v-model="payload.resource"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите ресурс"
            required
          >
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Имя пользователя</div>
        <div class="form-item__value">
          <input
            v-model="payload.username"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите имя пользователя"
            required
          >
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Пароль</div>
        <div class="form-item__value">
          <input
            v-model="payload.password"
            type="password"
            class="base-input base-input--bordered"
            placeholder="Введите пароль"
            required
          >
        </div>
      </div>
    </div>
    <div class="base-popup__footer">
      <div @click="checkFormType()"
           class="button">
        Добавить
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from "@/components/Icons/IconClose.vue";

export default {
  name: "LayersForm",
  components: {IconClose},
  props: {
    layer: {
      type: Object,
      required: true
    },
    createLayer: {
      type: Function,
      default: () => {}
    },
    updateLayer: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      payload: {
        name: null,
        url: null,
        resource: null,
        username: null,
        password: null
      }
    }
  },
  mounted() {
    if (Object.keys(this.layer).length) {
      const {name, url, resource, username, password} = this.layer;
      this.payload.name = name;
      this.payload.url = url;
      this.payload.resource = resource;
      this.payload.username = username;
      this.payload.password = password;
    }
  },
  methods: {
    checkFormType() {
      if (Object.keys(this.layer).length) this.updateLayer(this.layer.id, this.payload)
      else this.createLayer(this.payload)
    }
  }
}
</script>

<style scoped>

</style>