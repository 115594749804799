import DamagesService from "@/services/DamagesService";

export const namespaced = true

export const actions = {
    createDamage(context, {issueId, payload}) {
        return new Promise((resolve, reject) => {
            DamagesService.createDamage(issueId, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    updateDamage(context, {issueId, damageId, payload}) {
        return new Promise((resolve, reject) => {
            DamagesService.updateDamage(issueId, damageId, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    }
}