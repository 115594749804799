<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      <template v-if="!Object.keys(transportDataServer).length">Добавить сервер данных</template>
      <template v-else>Редактирование сервера данных</template>
      <span @click="$modal.hide('transport-data-server-form')"
            class="base-popup__close">
        <icon-close/>
      </span>
    </div>
    <div class="popup-form-group">
      <div class="form-item">
        <div class="form-item__name">Наименование</div>
        <div class="form-item__value">
          <input
            v-model="payload.name"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите наименование"
            required
          >
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Тип</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.type"
            :options="dataServerTypesList"
            :reduce="type => type.id"
            :get-option-label="(option) => option.name"
            class="vue-select-input"
            placeholder="Выберите из списка"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Адрес</div>
        <div class="form-item__value">
          <input
            v-model="payload.address"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите адрес"
            required
          >
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Тип аутентификации</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.auth_type"
            :options="authTypes"
            :reduce="authType => authType.id"
            class="vue-select-input"
            placeholder="Выберите из списка"
          />
        </div>
      </div>
      <div
        v-if="payload.auth_type === 'login_and_password'"
        class="form-item">
        <div class="form-item__name">Логин</div>
        <div class="form-item__value">
          <input
            v-model="payload.username"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите логин"
            required
          >
        </div>
      </div>
      <div
        v-if="payload.auth_type === 'login_and_password'"
        class="form-item">
        <div class="form-item__name">Пароль</div>
        <div class="form-item__value">
          <input
            v-model="payload.password"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите пароль"
            required
          >
        </div>
      </div>

      <div
        v-if="payload.auth_type === 'token'"
        class="form-item">
        <div class="form-item__name">Ключ</div>
        <div class="form-item__value">
          <input
            v-model="payload.token"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите ключ"
            required
          >
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Идентификатор суточного отчёта</div>
        <div class="form-item__value">
          <input
            v-model="payload.statistic_report_id"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите идентификатор"
          >
        </div>
      </div>
    </div>
    <div class="base-popup__footer">
      <div @click="checkFormType()"
           class="button">
        Добавить
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from "@/components/Icons/IconClose.vue";

export default {
  name: "TransportDataServerForm",
  components: {IconClose},
  props: {
    dataServerTypesList: {
      type: Array,
      required: true
    },
    transportDataServer: {
      type: Object,
      default: () => {}
    },
    createTransportDataServer: {
      type: Function,
      default: () => {}
    },
    updateTransportDataServer: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      payload: {
        name: null,
        type: null,
        address: null,
        auth_type: null,
        username: null,
        password: null,
        token: null,
        is_active: true,
        statistic_report_id: null
      },
      authTypes: [
        {id: 'no_auth', label: 'Без аутентификации'},
        {id: 'login_and_password', label: 'Логин и пароль'},
        {id: 'token', label: 'По ключу'}
      ]
    }
  },
  mounted() {
    if (Object.keys(this.transportDataServer).length) {
      const {
        id,
        name,
        type,
        address,
        auth_type,
        username,
        password,
        token,
        is_active,
        statisticReportId
      } = this.transportDataServer;
      this.dataServerId = id;
      this.payload.name = name;
      this.payload.type = type.id;
      this.payload.address = address;
      this.payload.auth_type = auth_type;
      this.payload.username = username;
      this.payload.password = password;
      this.payload.token = token;
      this.payload.is_active = is_active;
      this.payload.statistic_report_id = statisticReportId;
    }
  },
  methods: {
    checkFormType() {
      if (Object.keys(this.transportDataServer).length) {
        this.updateTransportDataServer(this.transportDataServer.id, this.payload);
      }
      else this.createTransportDataServer(this.payload)
    }
  }
}
</script>

<style scoped>

</style>