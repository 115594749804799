<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="18"
       height="20"
       viewBox="0 0 18 20"
       fill="none">
    <path
      d="M8.7618 11.2892C9.13064 11.2892 9.49587 11.2166 9.83663 11.0754C10.1774 10.9343 10.487 10.7274 10.7478
      10.4666C11.0086 10.2058 11.2155 9.89615 11.3567 9.55538C11.4978 9.21462 11.5705 8.84939 11.5705 8.48055C11.5705
       8.11171 11.4978 7.74648 11.3567 7.40572C11.2155 7.06495 11.0086 6.75533 10.7478 6.49452C10.487 6.23371 10.1774
        6.02682 9.83663 5.88567C9.49587 5.74452 9.13064 5.67187 8.7618 5.67188C8.01689 5.67188 7.3025 5.96779 6.77577
         6.49452C6.24904 7.02125 5.95312 7.73564 5.95312 8.48055C5.95313 9.22546 6.24904 9.93986 6.77577 10.4666C7.3025
          10.9933 8.01689 11.2892 8.7618 11.2892Z"
      stroke="#9192AD"
      stroke-width="1.5"/>
    <path
      d="M1.21853 6.84241C2.99196 -0.953468 14.5417 -0.944465 16.3062 6.85141C17.3414 11.4245 14.4967 15.2954 12.0031
       17.69C11.1318 18.5304 9.96842 19 8.75785 19C7.54728 19 6.38391 18.5304 5.51257 17.69C3.02797 15.2954 0.183284
        11.4155 1.21853 6.84241Z"
      stroke="#9192AD"
      stroke-width="1.5"/>
  </svg>
</template>

<script>
export default {
  name: "IconPin"
}
</script>

<style scoped>

</style>
