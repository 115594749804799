export default class ShutdownNotification {
    static STATE_DRAFT = 1;
    static STATE_SENT = 2;

    constructor(params) {
        this.id = params.id;
        this.author = params.author;
        this.state = params.state;
        this.channel = params.channel;
        this.startDate = params.start_date;
        this.endDate = params.end_date;
        this.messageHeader = params.message_header;
        this.messageBody = params.message_body;
        this.messageFooter = params.message_footer;
        this.shippedDate = params.shipped_date;
    }

    get isStateSent() {
        return this.state.id === ShutdownNotification.STATE_SENT;
    }

    get isStateDraft() {
        return this.state.id === ShutdownNotification.STATE_DRAFT;
    }
}