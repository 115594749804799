<template>
  <div class="page-wrapper">
    <base-panel
      title="Задания на обход"
      :is-add-button-visible="false"
    />

    <div class="tabs">
      <div class="tabs__header tabs__header--block">
        <div
          @click="activeTab = 0"
          :class="{'active': activeTab === 0}"
          class="tabs__header-item">
          Все задания
        </div>

        <div
          @click="activeTab = 1"
          :class="{'active': activeTab === 1}"
          class="tabs__header-item">
          В работе
        </div>

        <div
          @click="activeTab = 2"
          :class="{'active': activeTab === 2}"
          class="tabs__header-item">
          На проверку
        </div>
      </div>
    </div>

    <div class="tabs__item active">
      <base-functional-line
        @search-change="event => $store.commit('technicalInspectionTasks/SET_QUERY', event.target.value)"
      >
        <button
          @click="createTask()"
          class="button button--mode-primary">
          <icon-plus/>
          Создать задание
        </button>
      </base-functional-line>

      <tasks-list :tasks-list="getTaskListByActiveTab"/>
    </div>

  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel.vue";
import BaseFunctionalLine from "@/components/BaseFunctionalLine.vue";
import IconPlus from "@/components/Icons/IconPlus.vue";
import TasksList from "@/components/TechnicalInspection/Tasks/TasksList.vue";
import {mapGetters} from "vuex";
import {TechnicalInspectionTaskState} from "@/models/TechnicalInspection/TechnicalInspectionTaskState";

export default {
  name: "TechnicalInspectionTasksListPage",
  components: {TasksList, IconPlus, BaseFunctionalLine, BasePanel},
  data() {
    return {
      activeTab: 0
    }
  },
  computed: {
    ...mapGetters({
      tasksList: 'technicalInspectionTasks/getTasksListByQueryAndState'
    }),
    getTaskListByActiveTab: function () {
      switch (this.activeTab) {
        case 0:
          return this.tasksList([TechnicalInspectionTaskState.NEW,
            TechnicalInspectionTaskState.IN_WORK,
            TechnicalInspectionTaskState.REVIEW])
        case 1:
          return this.tasksList([TechnicalInspectionTaskState.IN_WORK])
        case 2:
          return this.tasksList([TechnicalInspectionTaskState.REVIEW])
        default:
          return []
      }
    }
  },
  mounted() {
    this.$store.dispatch('technicalInspectionTasks/getTasksList');
    this.$store.dispatch('resources/getAllResourcesList');
  },
  methods: {
    createTask() {
      this.$store.dispatch('baseSideModal/openModal', {
        modalContentType: 'createTechnicalInspectionTask'
      })
    }
  }
}
</script>

<style scoped>

</style>