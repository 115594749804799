<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="25"
       height="24"
       viewBox="0 0 25 24"
       fill="none">
    <path d="M21.5 7.15H16.1M7.1 7.15H3.5M21.5 17.05H17.9M8.9 17.05H3.5M10.7 10.3C11.1137 10.3 11.5233 10.2185
     11.9055 10.0602C12.2876 9.90192 12.6349 9.66989 12.9274 9.37739C13.2199 9.08488 13.4519 8.73763 13.6102
      8.35545C13.7685 7.97328 13.85 7.56366 13.85 7.15C13.85 6.73634 13.7685 6.32672 13.6102 5.94455C13.4519
       5.56237 13.2199 5.21512 12.9274 4.92261C12.6349 4.63011 12.2876 4.39808 11.9055 4.23978C11.5233 4.08148
        11.1137 4 10.7 4C9.86457 4 9.06335 4.33187 8.47261 4.92261C7.88187 5.51335 7.55 6.31457 7.55 7.15C7.55
         7.98543 7.88187 8.78665 8.47261 9.37739C9.06335 9.96813 9.86457 10.3 10.7 10.3ZM14.3 20.2C15.1354 20.2
          15.9366 19.8681 16.5274 19.2774C17.1181 18.6866 17.45 17.8854 17.45 17.05C17.45 16.2146 17.1181 15.4134
           16.5274 14.8226C15.9366 14.2319 15.1354 13.9 14.3 13.9C13.4646 13.9 12.6634 14.2319 12.0726 14.8226C11.4819
            15.4134 11.15 16.2146 11.15 17.05C11.15 17.8854 11.4819 18.6866 12.0726 19.2774C12.6634 19.8681 13.4646
             20.2 14.3 20.2Z"
          stroke="#262F3D"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconSettings2"
}
</script>

<style scoped>

</style>