<template>
  <div class="page-wrapper">
    <div v-if="brigade"
         class="base-table">
      <div class="base-table__container">
        <div class="base-table__header base-table__header--resource-dashboard">
          <div class="base-table__header-cell">ID</div>
          <div class="base-table__header-cell">Задание</div>
          <div class="base-table__header-cell">Выполнены с превышением
            <div class="base-table__header-cell-circle base-table__header-cell-circle--blue"/>
          </div>
        </div>

        <div v-for="task in brigade.ratings.tasks_types"
             :key="task.id"
             class="base-table__row base-table__row--resource-dashboard">
          <div class="base-table__column base-table__column--resource-dashboard">
            <div class="base-table__num">{{ task.id }}</div>
          </div>
          <div class="base-table__column base-table__column--resource-dashboard">
            <div class="base-table__text">{{ task.name }}</div>
          </div>
          <div class="base-table__column base-table__column--resource-dashboard">
            <div class="base-table__text">{{ task.count_overtime }}</div>
          </div>
        </div>
      </div>
    </div>
    <!--    <base-content-loaders v-if="loading"/>-->

  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ResourceDashboardItem",
  props: {
    brigade: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      resourceData: 'reportsData/getReportData',
      loading: 'reportsData/getReportLoading'
    }),
    isResourceListLoaded() {
      return this.resourceData.length && !this.loading;
    },
    sortedResourceList() {
      if (this.isAscSort) {
        return [...this.resourceData].sort((a, b) => {
          if (a.name < b.name) {
            return 1;
          }
          if (a.name > b.name) {
            return -1;
          }
          return 0;
        })
      } else {
        return [...this.resourceData].sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
      }
    },
    completedSum() {
      return this.resourceData.reduce((sum, resource) =>
              sum + resource.ratings.completed
          , 0)
    },
    completedOnTimeSum() {
      return this.resourceData.reduce((sum, resource) =>
              sum + resource.ratings.completed_on_time
          , 0)
    },
    completedOvertimeSum() {
      return this.resourceData.reduce((sum, resource) =>
              sum + resource.ratings.completed_overtime
          , 0)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>