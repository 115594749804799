import * as API from './API';
import {getError} from "@/utils/helpers";

export default {
    createElectricMeter(energyConsumptionObjectId, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient
                .post(`/energy-consumption/electricity-consumer-facilities/${energyConsumptionObjectId}/meters`
                    , payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        });
    },
    updateElectricMeter(electricMeterId, energyConsumptionObjectId, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient
                .patch(`/energy-consumption/electricity-consumer-facilities/${energyConsumptionObjectId}
                /meters/${electricMeterId}`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        });
    },
    deleteElectricMeter(electricMeterId, energyConsumptionObjectId) {
        return new Promise((resolve, reject) => {
            API.apiClient
                .delete(`/energy-consumption/electricity-consumer-facilities/${energyConsumptionObjectId}
                /meters/${electricMeterId}`)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        });
    }
}