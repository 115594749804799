import { render, staticRenderFns } from "./DeviceForm.vue?vue&type=template&id=cf50e82c&scoped=true&"
import script from "./DeviceForm.vue?vue&type=script&lang=js&"
export * from "./DeviceForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf50e82c",
  null
  
)

export default component.exports