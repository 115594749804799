import { render, staticRenderFns } from "./WorksListPage.vue?vue&type=template&id=1c3b69fa&scoped=true&"
import script from "./WorksListPage.vue?vue&type=script&lang=js&"
export * from "./WorksListPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c3b69fa",
  null
  
)

export default component.exports