<template>
  <div >
    <div class="base-table__container"
         id="daily-report-table">
      <div class="base-table__header base-table__header--daily">
        <div class="base-table__header-cell">#</div>
        <div class="base-table__header-cell">Открыта</div>
        <div class="base-table__header-cell">Закрыта</div>
        <div class="base-table__header-cell">Адрес</div>
        <div class="base-table__header-cell">Отключения</div>
        <div class="base-table__header-cell">Подразделения</div>
        <div class="base-table__header-cell">Задания</div>
      </div>

      <div v-for="item in issues"
           :key="item.date"
           class="base-table__row base-table__row--daily" >
        <div class="base-table__column">
          <div class="base-table__num">{{ item.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ formatDateTime(item.created_at) }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ formatDateTime(item.closed_at) }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">
            {{ item.getFullAddress() }}
          </div>
        </div>
        <div class="base-table__column">
          <div v-if="item.shutdowns.length">
            <div v-for="shutdown in item.shutdowns"
                 :key="shutdown.id"
                 class="shutdown-item mb-2">
              <div v-if="shutdown.actual_start_date">
                Факт. дата начала: <span>{{ formatDateTime(shutdown.actual_start_date) }}</span>
              </div>
              <div v-if="shutdown.actual_end_date">
                Факт. дата окончания: <span>{{ formatDateTime(shutdown.actual_end_date) }}</span>
              </div>
              <div>
                Комментарий:
                <span>{{ shutdown.comment }}</span>
              </div>
            </div>
          </div>
          <div
            v-else
            class="text-center">-</div>
        </div>
        <div class="base-table__column">
          <div v-if="item.tasks.length">
            <div>{{item.tasks[0].department ? item.tasks[0].department.name : '-'}}</div>
            <div>{{item.tasks[0].brigade_name}}</div>
          </div>
        </div>
        <div class="base-table__column">
          <div v-if="item.tasks.length">
            <div>{{item.tasks[0].type_label}}</div>
            <div class="mb-2">{{formatDateTime(item.tasks[0].actual_start_date)}} -
              {{formatDateTime(item.tasks[0].actual_end_date)}}</div>
            <div v-if="item.tasks[0].works.length">
              <ul v-for="element in item.emergencyElements"
                  :key="element.id"
                  class="mb-2">
                <li v-if="item.tasks[0].works.filter(work => {
                      return work.element ? work.element.id === element.id : false}).length"
                    class="list-title">
                  <div>{{element.element_type.name}}</div>
                  <div v-for="feature in element.features"
                       :key="feature.id">
                    {{feature.feature.name}}: {{feature.value}}{{feature.unit}}
                  </div>
                  <div>{{element.damageName}}</div>
                </li>
                <li v-for="work in item.tasks[0].works.filter(work => {
                      return work.element ? work.element.id === element.id : false})"
                    :key="work.id">
                  {{work.name}}
                </li>
              </ul>
              <ul v-if="item.tasks[0].works.filter(work => !work.element).length">
                <li class="list-title">
                  <div>Без аварийного элемента</div>
                </li>
                <li v-for="work in item.tasks[0].works.filter(work => !work.element)"
                    :key="work.id">
                  {{work.name}}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {formatDateTime} from "@/utils/filters";

export default {
  name: "DailyReportTable",
  props: {
    issues: {
      required: true,
      type: Array
    }
  },
  computed: {
    ...mapGetters({
      departmentName: 'departments/getDepartmentName'
    })
  },
  methods: {
    formatDateTime
  }
}
</script>

<style scoped>

.shutdown-item div {
  color: var(--text-primary);
}

.shutdown-item div span {
  color: var(--text-primary);
}

.daily-report-table thead td {
  border-right: 1px solid var(--bg-accent);
  border-top: 1px solid var(--bg-accent);
  background-color: var(--bg-sidebar);
  color: var(--text-panel);
  font-weight: 700;
  font-size: 16px;
  padding: 8px;
}

.daily-report-table thead td:first-child {
  border-left: 1px solid var(--bg-accent);
  border-radius: 10px 0 0 0;
}

.daily-report-table thead td:last-child {
  border-radius: 0 10px 0 0;
}

.daily-report-table tbody td {
  border-right: 1px solid var(--bg-accent);
  border-bottom: 1px solid var(--bg-accent);
  color: var(--text-primary);
  padding: 8px;
  text-align: left;
}

.daily-report-table tbody tr:first-child td:first-child {
  border-left: 1px solid var(--bg-accent);
}

.second-row td:first-child {
  border-left: none !important;
  border-radius: 0 !important;
}

.second-row td:last-child {
  border-radius: 0 !important;
}

.list-title {
  list-style-type: none;
  margin-left: -40px;
}

.list-title:first-child {
  font-weight: 600;
}
</style>
