<template>
  <div class="i-modal__content-wrapper">
    <div class="i-modal__content">
      <div class="i-modal__inner">
        <div class="base-modal__left-side base-modal__left-side--hidden">
          <div class="base-modal-header base-modal-header--padding-s">
            <span class="base-modal-title">Оповещение #{{ notification.id }}</span>
            <div class="base-modal-header__status">
              <div class="application-status-wrapper">
                <div class="application-status base-table__tag--done"
                     :class="[
                       {'base-table__tag--done': notification.isStateSent},
                       {'base-table__tag--canceled': notification.isStateDraft}
                     ]">
                  {{ notification.state.name }}
                </div>
              </div>
            </div>
          </div>

          <div class="base-modal-info base-modal-info--gap-24 base-modal-info--padding-s">
            <div class="base-modal-info__card">
              <div class="base-modal-info__card-title base-modal-info__card-title--loader">
                Детальная информация
              </div>

              <div class="base-modal-info__item">
                <div class="base-modal-info__name">
                  <span>Дата и время отключения</span>
                </div>
                <div class="base-modal-info__value">
                  <p class="base-modal-info__value-txt">{{ formatDateTime(notification.startDate) }}</p>
                </div>
              </div>

              <div class="base-modal-info__item">
                <div class="base-modal-info__name">
                  <span>Дата и время включения</span>
                </div>
                <div class="base-modal-info__value">
                  <p class="base-modal-info__value-txt">{{ formatDateTime(notification.endDate) }}</p>
                </div>
              </div>

              <hr class="base-hr">

              <div class="base-modal-info__item">
                <div class="base-modal-info__name">
                  <span>Сообщение</span>
                </div>
                <div class="base-modal-info__value">
                  <p class="base-modal-info__value-txt">{{ notification.messageHeader }}</p>
                  <p class="base-modal-info__value-txt">{{ notification.messageFooter }}</p>
                  <p class="base-modal-info__value-txt">{{ notification.messageBody }}</p>
                </div>
              </div>
            </div>

            <div class="base-modal-btn-wp">
              <button
                @click="$store.dispatch('baseSideModal/goBack')"
                class="button button--mode-outline close-notification-modal">Закрыть</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="i-modal-btn-wp i-modal-btn-wp--back">
      <div class="task-modal__mobile-actions">
        <button @click="$store.dispatch('baseSideModal/closeModal')"
                class="button button--mode-outline task-modal__mobile-back">
          <icon-arrow-left/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrowLeft from "@/components/Icons/IconArrowLeft.vue";
import {mapState} from "vuex";
import {formatDateTime} from "@/utils/filters";

export default {
  name: "ShutdownNotificationModalContent",
  components: {
    IconArrowLeft
  },
  computed: {
    ...mapState({
      notification: state => state.baseSideModal.componentPropsData
    })
  },
  methods: {
    formatDateTime
  }
}
</script>

<style scoped>
.close-notification-modal {
  width: 100%;
}
</style>