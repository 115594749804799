import ZoneType from "@/models/billing/ZoneType";

export default class Zone {
    constructor(props) {
        this.id = props.id;
        this.name = props.name;
        this.type = new ZoneType(props.type);
        this.sys = props.sys_id;
        this.cooridnates = props.geometry?.coordinates || [];
        this.active = props.active;
    }
}