export default class NetworkElement {
    constructor(params) {
        this.id = params.id;
        this.geometryType = params.geometry.type;
        this.coordinates = params.geometry.coordinates;
        this.semanticSource = params.semantic_source;
        this.type = params.type;
        this.currentMode = params.current_mode;
        this.availableModes = params.available_modes;
        this.isSelected = false;
    }
}
