<template>
  <div class="page-wrapper">
    <base-panel
      title="Провайдеры семантических данных"
      :is-add-button-visible="true"
      add-button-name="Добавить"
      @add-button-clicked="showPopup()"
    />

    <semantic-providers-list
      :semantic-providers-list="semanticProvidersList"
      @edit-semantic-provider="showPopup"
      @delete-semantic-provider="deleteSemanticProvider"
    />

    <base-content-loaders v-if="loading && !semanticProvidersList.length"/>

    <base-no-data v-if="!loading && !semanticProvidersList.length"/>
  </div>
</template>

<script>
import {mapState} from "vuex";
import SemanticProvidersList from "@/components/EngineeringNetworks/SemanticProvidersList";
import {showConfirmationModal, showAlert, showToast} from "@/utils/notification";
import BasePanel from "@/components/BasePanel.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import SemanticProvidersForm from "@/components/EngineeringNetworks/SemanticProvidersForm.vue";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import BaseNoData from "@/components/BaseNoData.vue";

export default {
  name: "SemanticProvidersListPage",
  components: {BaseNoData, BaseContentLoaders, BasePanel, SemanticProvidersList},
  computed: {
    ...mapState({
      semanticProvidersList: state => state.semanticProviders.semanticProvidersList,
      loading: state => state.semanticProviders.loading,
      semanticProviderTypes: state => state.semanticProviders.semanticProviderTypes
    })
  },
  mounted() {
    this.$store.dispatch('semanticProviders/getSemanticProviderTypes');
    this.$store.dispatch('semanticProviders/getSemanticProvidersList');
  },
  methods: {
    showPopup(semanticProvider = {}) {
      this.$modal.show(
        SemanticProvidersForm,
        {
          'semanticProviderTypesList': this.semanticProviderTypes,
          'semanticProvider': semanticProvider,
          'createSemanticProvider': this.createSemanticProvider,
          'updateSemanticProvider': this.updateSemanticProvider
        },
        {
          name: 'semantic-provider-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460),
          scrollable: true
        })
    },
    createSemanticProvider(payload) {
      this.$store.dispatch('semanticProviders/createSemanticProvider', payload)
        .then(() => {
          this.$store.dispatch('semanticProviders/getSemanticProvidersList');
          this.$modal.hide('semantic-provider-form');
          showToast('Провайдер семантических данных добавлен', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    updateSemanticProvider(providerId, payload) {
      this.$store.dispatch('semanticProviders/updateSemanticProvider', {
        providerId, payload
      })
        .then(() => {
          this.$store.dispatch('semanticProviders/getSemanticProvidersList');
          this.$modal.hide('semantic-provider-form');
          showToast('Провайдер семантических данных обновлён', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    deleteSemanticProvider(providerId) {
      showConfirmationModal('Вы уверены, что хотите удалить провайдер семантических данных?' +
        ' Это действие невозвратно.')
          .then(result => {
            if (result.isConfirmed) {
              this.$store.dispatch('semanticProviders/deleteSemanticProvider', providerId)
                  .then(() => {
                    this.$store.commit('semanticProviders/DELETE_SEMANTIC_PROVIDER', providerId);
                    showToast('Провайдеры семантических данных удалён', 'success');
                  })
                  .catch(error => showAlert('Ошибка', error))
            }
          })
    }
  }
}
</script>

<style scoped>

</style>