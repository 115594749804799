import ShutdownNotificationsService from "@/services/ShutdownNotificationsService";

export const namespaced = true;

export const state = {
    shutdownNotificationsList: [],
    loading: false,
    addressesLoading: false,
    error: null
}

export const mutations = {
    SET_SHUTDOWN_NOTIFICATIONS_LIST(state, shutdownNotificationsList) {
        state.shutdownNotificationsList = shutdownNotificationsList;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ADDRESSES_LOADING(state, loading) {
        state.addressesLoading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    DELETE_SHUTDOWN_NOTIFICATION(state, id) {
        const index = state.shutdownNotificationsList.findIndex(notification => notification.id === id);
        state.shutdownNotificationsList.splice(index, 1);
    }
}

export const actions = {
    getShutdownNotificationsList({commit}) {
        commit("SET_LOADING", true);
        ShutdownNotificationsService.fetchShutdownNotificationsList()
            .then(shutdownNotificationsList => commit("SET_SHUTDOWN_NOTIFICATIONS_LIST", shutdownNotificationsList))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false))
    },
    createShutdownNotification(context, payload) {
        return new Promise((resolve, reject) => {
            ShutdownNotificationsService.createShutdownNotification(payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    updateShutdownNotification(context, {id, payload}) {
        return new Promise((resolve, reject) => {
            ShutdownNotificationsService.updateShutdownNotification(id, payload)
               .then(() => resolve())
               .catch(error => reject(error))
        })
    },
    deleteShutdownNotification(context, id) {
        return new Promise((resolve, reject) => {
            ShutdownNotificationsService.deleteShutdownNotification(id)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    getAddressesByCoordinates({commit}, payload) {
        commit("SET_ADDRESSES_LOADING", true);
        return new Promise((resolve, reject) => {
            ShutdownNotificationsService.fetchAddressesByCoordinates(payload)
                .then(addresses => resolve(addresses))
                .catch(error => reject(error))
                .finally(() => commit("SET_ADDRESSES_LOADING", false));
        })
    }
}

export const getters = {
    getShutdownNotificationsByState: state => stateId => {
        return state.shutdownNotificationsList.filter(notification => notification.state.id === stateId);
    }
}