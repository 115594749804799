<template>
  <div class="leak-popup">
    <div class="leak-popup__content">
      <div class="leak-popup__issue-header">
        <div
          @click="openIssueModal(leak.issueId)"
          class="leak-popup__issue-id">#{{ leak.issueId }}
        </div>
        <div
          class="leak-popup__issue-state"
          :class="[
            {'base-table__tag--new': leak.issueStateId === IssueState.NEW},
            {'base-table__tag--work': leak.issueStateId === IssueState.IN_WORK},
            {'base-table__tag--done': leak.issueStateId === IssueState.CLOSED},
            {'base-table__tag--canceled': leak.issueStateId === IssueState.CANCELED}
          ]">
          {{ leak.issueStateName }}
        </div>
      </div>
      <div class="leak-popup__issue-body">
        <div class="leak-popup__issue-category">{{leak.issueCategoryName}}</div>
        <div class="leak-popup__issue-created-at">{{ formatDateTime(leak.issueCreatedAt) }}</div>
      </div>
      <div class="leak-popup__issue-footer">
        {{leak.issueAddress.fullAddress}}
      </div>
    </div>
  </div>
</template>

<script>
import IssueState from "@/models/serviceDesk/IssueState";
import store from "@/store";
import {formatDateTime} from "@/utils/filters";

export default {
  name: "LeakPopupContent",
  props: {
    leak: {
      type: Object
    }
  },
  computed: {
    IssueState: () => IssueState
  },
  methods: {
    formatDateTime,
    openIssueModal(issueId) {
      store.dispatch('baseSideModal/openModal', {
        modalContentType: 'issue',
        entityId: issueId
      })
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/helpers/mixins";

.leak-popup {
  border-radius: 16px;
  border: 1px solid var(--border-main);
  background: var(--panel);

  box-shadow: 0px 8px 16px 0px var(--shadow);

  &__content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 20px;
  }

  &__issue-header {
    display: flex;
    gap: 8px;
  }

  &__issue-id {
    padding: 4px 8px;
    border-radius: 6px;
    background: var(--panel-bg);

    cursor: pointer;
  }

  &__issue-state {
    padding: 4px 8px;
    border-radius: 8px;
    color: var(--text-panel);
    font-weight: 600;
  }

  &__issue-body {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__issue-category {
    @include font-style(600, 16px, 24px, var(--text-primary));
  }

  &__issue-created-at {
    color: var(--text-secondary);
  }

  &__issue-footer {
    padding-top: 12px;
    border-top: 1px solid var(--border-main);

    @include font-style(400, 14px, 16px, var(--text-primary));
  }
}
</style>
