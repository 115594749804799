<template>
  <div class="additional-option-wrapper"
       :class="{'always-open': isCollapseVisible}">
    <div class="additional-option">
      <div class="additional-option__icon">
        <icon-flash-circle />
      </div>
      <div class="additional-option__name">
        <span>Обнаруженные проблемы</span>
        <span class="additional-option__quantity">{{ detectedProblemsList.length }}</span>
      </div>
      <div class="additional-option__buttons">
        <button
          v-show="isActionsVisible"
          class="additional-option__add"
          type="button"
          @click="showModal()">
          <icon-plus/>
        </button>
        <button
          @click="isCollapseVisible = !isCollapseVisible"
          class="additional-option__toggle"
          :class="{'rotate': isCollapseVisible}">
          <icon-arrow-double-up/>
        </button>
      </div>
    </div>
    <div class="additional-options-list">
      <div v-for="detectedProblem in detectedProblemsList"
           :key="detectedProblem.id"
           @click="showModal(detectedProblem)"
           class="additional-options-list__inner">
        <div class="added-item added-item--flex added-item--cur-pointer">
          <div class="added-item__info">
            <div class="added-item__name">{{ detectedProblem.category.name }}</div>
            <div class="added-item__additional">{{ detectedProblem.category.group_name }}</div>
          </div>
          <div
            v-show="isActionsVisible"
            class="added-item__right"
            @click.stop="deleteDetectedProblem(detectedProblem.id, detectedProblem.taskId)">
            <icon-trash class="svg added-item__tool"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconTrash from "@/components/Icons/IconTrash.vue";
import IconArrowDoubleUp from "@/components/Icons/IconArrowDoubleUp.vue";
import IconPlus from "@/components/Icons/IconPlus.vue";
import IconFlashCircle from "@/components/Icons/IconFlashCircle.vue";
import {showAlert, showToast, showConfirmationModal} from "@/utils/notification";
import {modalAdaptiveWidth} from "@/utils/helpers";
import DetectedProblemsForm
  from "@/components/ServiceDesk/Tasks/TaskComponents/DetectedProblems/DetectedProblemsForm.vue";
export default {
  name: "DetectedProblemsList",
  components: {IconFlashCircle, IconPlus, IconArrowDoubleUp, IconTrash},
  props: {
    taskId: {
      type: Number,
      required: true
    },
    detectedProblemsList: {
      type: Array,
      required: true
    },
    isActionsVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isCollapseVisible: false
    }
  },
  methods: {
    showModal(detectedProblem = null) {
      if (this.isActionsVisible) {
        this.$modal.show(
          DetectedProblemsForm,
          {
            'task-id': this.taskId,
            'detected-problem': detectedProblem
          },
          {
            name: 'detected-problems-form',
            classes: 'base-popup',
            height: 'auto',
            width: modalAdaptiveWidth(640),
            scrollable: true
          }
        )
      }
    },
    deleteDetectedProblem(detectedProblemId, taskId) {
      showConfirmationModal('Вы уверены, что хотите удалить обнаруженную проблему?')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('detectedProblems/deleteDetectedProblem', {
              taskId,
              detectedProblemId
            })
              .then(() => {
                this.$store.dispatch('taskModal/getDetectedProblemsList', taskId);
                showToast('Обнаруженная проблема удалена')
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style scoped>

</style>