import moment from "moment";
import IssueState from "@/models/serviceDesk/IssueState";
import Appeal from "@/models/callCenter/Appeal";
import Task from "@/models/serviceDesk/Task";
import {getDatesRangeCaption} from "@/utils/helpers";
import File from "@/models/serviceDesk/File";
export default class Issue {
    constructor(id, attributes) {
        this.id = id;
        this.tags = attributes.tags;
        this.author = attributes.author || null;
        this.created_at = attributes.created_at;
        this.closed_at = attributes.closed_at || null;
        this.state = new IssueState(attributes.state);
        this.based_on = attributes.based_on;
        this.department_id = attributes.department?.id;
        this.department_name = attributes.department?.name;
        this.address = attributes.address;
        this.coordinates = attributes.address.geometry?.coordinates.reverse() || null;
        this.appeals_count = attributes.appeals_count || 0;
        this.category = attributes.category || null;
        this.category_id = attributes.category_id || null;
        this.type_id = attributes.type?.id;
        this.type_name = attributes.type?.name;
        this.activated_in = attributes.activated_in || null;
        this.description = attributes.description;
        this.planned_start_at = attributes.planned_start_at || null;
        this.planned_end_at = attributes.planned_end_at || null;
        this.structuralUnit = attributes.structural_unit || {name: ''};
        this.tasks = attributes.tasks ? attributes.tasks
            .map(({id, ...rest}) => new Task(id, rest)) : [];
        this.relatedIssuesList = attributes.children ?
            attributes.children.map(item => new Issue(item.id, item.attributes)) : [];
        this.linkedAppealsList = attributes.appeals ?
            attributes.appeals.map(item => new Appeal(item.id, item.attributes)) : [];
        this.attachments = attributes.attachments ?
            attributes.attachments.map(item => new File(item.id, item.attributes)) : [];
    }

    getCaption() {
        return `№${this.id} от ${moment(this.created_at).format(process.env.VUE_APP_BASE_DATE_TIME_FORMAT)},
         ${this.getFullAddress()}`
    }

    getFullAddress(joinSymbol = ', ') {
        return [this.address.city, this.address.settlement, this.address.street,
            this.address.house, this.address.block, this.address.flat]
            .filter(item => item !== null)
            .join(joinSymbol)
    }

    get plannedDatesRangeCaption() {
        const start = moment(this.planned_start_at);
        const end = moment(this.planned_end_at);

        return getDatesRangeCaption(start, end);
    }

    getCalendarEvent() {
        return {
            id: this.id,
            eventContent: {
                categoryName: this.category.name,
                categoryGroupName: this.category.group_name,
                address: this.getFullAddress(),
                classByState: `issue-calendar-event--${this.state.getStateName()}`
            },
            start: this.planned_start_at,
            end: this.planned_end_at,
            classNames: ['issue-calendar-event__wrapper'],
            display: 'block'
        }
    }
}
