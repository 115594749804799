<template>
  <div class="page-wrapper">
    <base-panel
      title="Дефекты"
      :is-add-button-visible="true"
      add-button-name="Добавить дефект"
      @add-button-clicked="showPopup()"
    />

    <base-functional-line @search-change="event => $store.commit('defects/SET_SEARCH_QUERY', event.target.value)">
      <defects-filter />
    </base-functional-line>

    <defects-list
      v-if="defectsList.length"
      :defects-list="defectsList"
      @update-defect="showPopup"
      @delete-defect="deleteDefect"
    />

    <base-content-loaders v-if="loading && !defectsList.length"/>

    <base-no-data v-if="!loading && !defectsList.length"/>
  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel.vue";
import DefectsList from "@/components/ServiceDesk/admin/DefectsList.vue";
import {mapGetters, mapState} from "vuex";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import {showToast, showAlert, showConfirmationModal} from "@/utils/notification";
import {modalAdaptiveWidth} from "@/utils/helpers";
import DefectsForm from "@/components/ServiceDesk/admin/DefectsForm.vue";
import BaseFunctionalLine from "@/components/BaseFunctionalLine.vue";
import DefectsFilter from "@/components/ServiceDesk/admin/DefectsFilter.vue";

export default {
  name: "DefectsListPage",
  components: {
    DefectsFilter,
    BaseFunctionalLine,
    BaseNoData,
    BaseContentLoaders,
    DefectsList,
    BasePanel
  },
  data() {
    return {
      popupName: 'defects-form'
    }
  },
  computed: {
    ...mapState({
      loading: state => state.defects.loading,
      defectTypesList: state => state.defects.defectTypesList
    }),
    ...mapGetters({
      defectsList: 'defects/getFilteredDefectsList'
    })
  },
  mounted() {
    this.$store.dispatch('defects/getDefectList');
    this.$store.dispatch('defects/getDefectTypesList');
  },
  methods: {
    showPopup(defect = {}) {
      this.$modal.show(
        DefectsForm,
        {
          'defect': defect,
          'createDefect': this.createDefect,
          'updateDefect': this.updateDefect,
          'defectTypesList': this.defectTypesList,
          'popupName': this.popupName
        },
        {
          name: this.popupName,
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        })
    },
    createDefect(payload) {
      this.$store.dispatch('defects/createDefect', payload)
        .then(() => {
          this.$store.dispatch('defects/getDefectList');
          this.$modal.hide(this.popupName);
          showToast('Дефект создан', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    updateDefect(defectId, payload) {
      this.$store.dispatch('defects/updateDefect', {
        defectId, payload
      })
        .then(() => {
          this.$store.dispatch('defects/getDefectList');
          this.$modal.hide(this.popupName);
          showToast('Дефект обновлен', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    deleteDefect(defectId) {
      showConfirmationModal('Вы уверены, что хотите удалить дефект? Это действие невозвратно.')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('defects/deleteDefect', defectId)
              .then(() => {
                this.$store.commit('defects/DELETE_DEFECT', defectId);
                showToast('Дефект удален', 'success');
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style scoped>

</style>