<template>
  <div class="not-found-page">
    <div class="not-found-page__title">404 - Страница не найдена</div>
    <div class="not-found-page__text">
      К сожалению, запрашиваемая вами страница не существует.
      <br>
      Возможно, вы ввели неправильный адрес или страница была перемещена.
    </div>
  </div>
</template>


<script>
export default {
  name: "NotFoundPage"
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.not-found-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 6px;

  height: 100%;

  &__title {
    @include font-style(600, 32px, 46px, var(--text-primary));
  }

  &__text {
    text-align: center;
    @include font-style(400, 16px, 24px, var(--text-secondary));
  }
}
</style>