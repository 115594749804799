<template>
  <div class="base-table base-table--issues">
    <div class="base-table__container">
      <div class="base-table__header base-table__header--transport-orders">
        <div class="base-table__header-cell">Транспорт</div>
        <div class="base-table__header-cell">Тип</div>
        <div class="base-table__header-cell">Задание</div>
        <div class="base-table__header-cell">Адрес</div>
        <div class="base-table__header-cell">Период работ</div>
        <div class="base-table__header-cell">Ресурс</div>
        <div class="base-table__header-cell">Статус</div>
        <div class="base-table__header-cell"/>
      </div>

      <div v-for="transportOrder in transportOrdersList"
           :key="transportOrder.id"
           class="base-table__row base-table__row--transport-order"
           @click="openModal(transportOrder)">
        <div class="base-table__column">
          <div class="base-table__text">
            {{
              transportOrder.transport ?
                `${transportOrder.transport.name} ${transportOrder.transport.gov_number}` :
                '—'
            }}
          </div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">
            {{transportOrder.transportType.name}}
          </div>
        </div>

        <div class="base-table__column">
          <div class="base-table__text">
            <div @click.stop="openTaskModal(transportOrder.task.id)"
                 class="transport-order-cell__task-number">#{{transportOrder.task.id}}</div>
            <div class="transport-order-cell__task-type">{{transportOrder.task.type.name}}</div>
          </div>
        </div>

        <div class="base-table__column">
          <div class="base-table__text">
            {{transportOrder.fullAddress}}
          </div>
        </div>

        <div class="base-table__column">
          <div class="base-table__text">
            {{transportOrder.plannedDatesCaption}}
          </div>
        </div>

        <div class="base-table__column">
          <div class="base-table__text">
            {{transportOrder.brigade ? transportOrder.brigade.name : '—'}}
          </div>
        </div>

        <div class="base-table__column base-table__column--state">
          <div class="base-table__tag"
               :class="[
                 {'base-table__tag--new': transportOrder.state.id === 1},
                 {'base-table__tag--done': transportOrder.state.id === 2},
                 {'base-table__tag--canceled': transportOrder.state.id === 3},
               ]">
            {{ transportOrder.state.name }}
          </div>
        </div>

        <div class="base-table__column base-table__column--info">
          <span v-if="transportOrder.comment"
                class="base-table__short-info">
            <icon-comment class="base-table__short-info-icon"/>
            <span class="base-table__short-info-counter">1</span>

            <div class="base-table__short-info-tooltip">
              <div class="base-table__short-info-tooltip-inner">
                {{ transportOrder.comment }}
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import TransportOrdersForm from "@/components/ServiceDesk/TransportOrders/TransportOrdersForm";
import {showToast} from "@/utils/notification";
import IconComment from "@/components/Icons/IconComment";
import {modalAdaptiveWidth} from "@/utils/helpers";
import sideModalControls from "@/mixins/sideModalControls";

export default {
  name: "TransportOrdersList",
  components: {IconComment},
  mixins: [sideModalControls],
  props: {
    transportOrdersList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedTransportOrder: null
    }
  },
  methods: {
    openModal(transportOrder) {
      if (transportOrder.isCanceled) {
        showToast('Заказ транспорта отменён')
      } else {
        this.$modal.show(
          TransportOrdersForm,
          {
            'transport-order': transportOrder
          },
          {
            name: 'transport-order-modal',
            classes: 'base-popup',
            height: 'auto',
            width: modalAdaptiveWidth(600),
            scrollable: true
          }
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.transport-order-cell {
  &__task-number {
    display: inline-block;
    margin-right: 8px;
    color: var(--text-link);
    cursor: pointer;
  }

  &__task-type {
    display: inline-block;
  }
}
</style>
