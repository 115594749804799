<script>
export default {
  name: "IconArrowUp"
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none">
    <path
      d="M7.52904 3.9875L4.99987 1.45833L2.4707 3.9875"
      stroke="#FF3A81"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M5 8.54167V1.52917"
      stroke="#FF3A81"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>

<style scoped>

</style>