<template>
  <div class="base-table">
    <div class="base-table__container">
      <div class="base-table__header base-table__column--data-server">
        <div class="base-table__header-cell">Наименование</div>
        <div class="base-table__header-cell">Тип</div>
        <div class="base-table__header-cell">Адрес</div>
        <div class="base-table__header-cell">Тип аутентификации</div>
        <div class="base-table__header-cell">Идентификатор суточного отчета</div>
        <div class="base-table__header-cell"/>
      </div>

      <div v-for="dataServer in transportDataServersList"
           :key="dataServer.id"
           class="base-table__row base-table__column--data-server">
        <div class="base-table__column">
          <div class="base-table__text">{{ dataServer.name }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ dataServer.type.name }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ dataServer.address }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ dataServer.getAuthTypeLabel() }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ dataServer.statisticReportId }}</div>
        </div>
        <div class="base-table__column base-table__column--content-right">
          <div class="base-table__short-info">
            <div @click="$emit('edit-transport-data-server', dataServer)">
              <icon-pencil class="base-table__short-info--p-2" />
            </div>
            <div @click="$emit('delete-transport-data-server', dataServer.id)">
              <icon-trash />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconPencil from "@/components/Icons/IconPencil.vue";
import IconTrash from "@/components/Icons/IconTrash.vue";

export default {
  name: "TransportDataServersList",
  components: {IconTrash, IconPencil},
  props: {
    transportDataServersList: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.base-table__column--data-server {
  grid-template-columns: 2fr 1fr 4fr 2fr 2fr 1fr;
}
</style>
