import * as API from './API';
import TaskConsumptionRate from "@/models/serviceDesk/TaskConsumptionRate";
import {getError} from "@/utils/helpers";
export default {
    fetchTaskConsumptionsList(taskId) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/tasks/${taskId}/task-materials`)
                .then(response => resolve(response.data.data
                    .map(item => new TaskConsumptionRate(item))))
                .catch(error => reject(getError(error)))
        })
    },
    createTaskConsumptions(taskId, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`/service-desk/tasks/${taskId}/task-materials`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteTaskConsumption(taskId, consumptionId) {
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`/service-desk/tasks/${taskId}/task-materials/${consumptionId}`)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}
