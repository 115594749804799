import AppealsStatisticService from "@/services/AppealsStatisticService";

export const namespaced = true;

export const state = {
    appealsSummary: [],
    appealsCountByDay: [],
    appealsCountByCategory: [],
    loading: false,
    error: null
}

export const mutations = {
    SET_APPEALS_SUMMARY(state, appeals) {
        state.appealsSummary = appeals;
    },
    SET_APPEALS_COUNT_BY_DAY(state, appeals) {
        state.appealsCountByDay = appeals;
    },
    SET_APPEALS_COUNT_BY_CATEGORY(state, appeals) {
        state.appealsCountByCategory = appeals;
    },
    SET_LOADING(state, value) {
        state.loading = value;
    },
    SET_ERROR(state, value) {
        state.error = value;
    }
}

export const actions = {
    getAppealsStatistic({commit}, params) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
        AppealsStatisticService.fetchAppeals(params)
            .then(data => {
                commit("SET_APPEALS_SUMMARY", data.summary);
                commit("SET_APPEALS_COUNT_BY_DAY", data.opened_by_days);
                commit("SET_APPEALS_COUNT_BY_CATEGORY", data.categories);
                resolve()
            })
            .catch(error => {
                commit("SET_ERROR", error);
                reject(error);
            })
            .finally(() => commit("SET_LOADING", false))
    })
    }
}

export const getters = {
    getAppealsCountByDay: state => {
        return state.appealsCountByDay;
    },
    getAppealsCountByCategory: state => {
        return state.appealsCountByCategory;
    }
}