export default class Balance {
    constructor(id, name, periods) {
        this.id = id;
        this.name = name;
        this.periods = periods;
    }

    sumPeriodsSupply() {
        let sum = 0;
        this.periods.forEach(period => {
            sum += period.sumSupply()
        })
        return sum
    }

    sumPeriodsConsumption() {
        let sum = 0;
        this.periods.forEach(period => {
            sum += period.sumConsumption()
        })
        return sum
    }

    calculatePeriodsLosses() {
        return (this.sumPeriodsSupply() - this.sumPeriodsConsumption()).toFixed(0)
    }

    calculatePeriodsLossesInPercent() {
        return this.calculatePeriodsLosses() * 100 / this.sumPeriodsSupply()
    }

    getDevicesSupplySumForAllPeriods() {
        let devices_supply = {}
        this.periods.forEach(period => {
            period.supply.forEach(item => {
                if (devices_supply[item.device_id] === undefined) {
                    devices_supply[item.device_id] = item.value;
                } else {
                    devices_supply[item.device_id] += item.value;
                }
            })
        })
        return devices_supply
    }
}