<template>
  <div class="loaders-inner">
    <div class="loaders-ring"><div/><div/><div/><div/></div>
  </div>
</template>
<script>
export default {
  name: "BaseContentLoaders"
}
</script>
<style lang="scss" scoped>
.loaders-inner {
  height: 100px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.loaders-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.loaders-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 48px;
  height: 48px;
  margin: 8px;
  border: 5px solid var(--text-link);
  border-radius: 50%;
  animation: loaders-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.loaders-ring div {
  border-color: var(--text-link) transparent transparent transparent;
}
.loaders-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.loaders-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.loaders-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loaders-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
