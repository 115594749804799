<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      Добавить тип сетевого оборудования
      <span @click="$modal.hide('network-equipment-type-form')"
            class="base-popup__close">
        <icon-close/>
      </span>
    </div>
    <div class="popup-form-group">
      <div class="form-item">
        <div class="form-item__name">Наименование</div>
        <div class="form-item__value">
          <input
            v-model="payload.name"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите наименование"
            required
          >
        </div>
      </div>
    </div>
    <div class="base-popup__footer">
      <div @click="submitForm()"
           class="button">
        Добавить
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from "@/components/Icons/IconClose.vue";

export default {
  name: "NetworkEquipmentTypesForm",
  components: {IconClose},
  props: {
    createNetworkEquipmentType: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      payload: {
        name: null
      }
    }
  },
  methods: {
    submitForm() {
      this.createNetworkEquipmentType(this.payload);
    }
  }
}
</script>

<style scoped>

</style>