<template>
  <div class="additional-option-wrapper"
       :class="{'always-open': isCollapseVisible}">
    <div class="additional-option">
      <div class="additional-option__icon">
        <icon-task-square/>
      </div>
      <div class="additional-option__name"><span>Роли</span>
        <span class="additional-option__quantity">{{ rolesList.length }}</span>
      </div>
      <div class="additional-option__buttons">
        <button
          class="additional-option__add"
          type="button"
          @click="showAddRolePopup()">
          <icon-plus/>
        </button>
        <button
          @click="isCollapseVisible = !isCollapseVisible"
          class="additional-option__toggle"
          :class="{'rotate': isCollapseVisible}">
          <icon-arrow-double-up/>
        </button>
      </div>
    </div>
    <div class="additional-options-list">
      <div class="additional-options-list__inner">
        <div v-for="role in rolesList"
             :key="role.id"
             class="added-item added-item--flex added-item--cur-pointer">
          <div class="added-item__info">
            <div class="added-item__name">{{ role.attributes.name }}</div>
          </div>
          <div class="added-item__right">
            <div @click.stop="deleteRole(role.id)">
              <icon-trash class="svg added-item__tool"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconPlus from "@/components/Icons/IconPlus.vue";
import IconTrash from "@/components/Icons/IconTrash.vue";
import IconTaskSquare from "@/components/Icons/IconTaskSquare.vue";
import IconArrowDoubleUp from "@/components/Icons/IconArrowDoubleUp.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import {showAlert, showToast, showConfirmationModal} from "@/utils/notification";
import UserChangeRoleForm from "@/components/admin/User/UserChangeRoleForm.vue";
export default {
  name: "UserRolesList",
  components: {IconArrowDoubleUp, IconTaskSquare, IconTrash, IconPlus},
  props: {
    rolesList: {
      type: Array,
      required: true
    },
    userId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isCollapseVisible: true
    }
  },
  methods: {
    showAddRolePopup() {
      this.$modal.show(
        UserChangeRoleForm,
        {
          'popupName': 'user-add-role-form'
        },
        {
          name: 'user-add-role-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        })
    },
    deleteRole(roleId) {
      showConfirmationModal('Вы уверены, что хотите удалить роль? Это действие невозвратно.')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('user/removeRole', {
              user_id: this.userId,
              role_id: roleId
            })
              .then(() => {
                this.$store.dispatch('user/getUser', this.userId);
                showToast('Роль удалена', 'success');
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style scoped>

</style>