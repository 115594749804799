<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="24"
       height="24"
       viewBox="0 0 24 24"
       fill="none">

    <path d="M6 9L12 14.983L18 9"
          stroke="#B9BAD5"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconArrowLinearDown'
}
</script>

<style lang="scss" scoped>

</style>