<template>
  <div class="page-wrapper">
    <base-panel
      title="Заказы транспорта"
      :is-add-button-visible="false"/>

    <base-functional-line
      search-placeholder="Найти по гос номеру"
      @search-change="event => searchTransportOrders(event)">
      <transport-orders-filter/>
    </base-functional-line>

    <div class="base-tables-container">
      <div class="base-table__tabs">
        <div class="base-table__tab"
             :class="{'active': activeFilterState === 0}"
             @click="filterTransportOrdersList(0)">Все
        </div>
        <div v-for="state in TransportOrderStates"
             :key="state.id"
             class="base-table__tab"
             :class="{'active': activeFilterState === state.id}"
             @click="filterTransportOrdersList(state.id)">
          {{ state.name }}
        </div>
      </div>

      <transport-orders-list
        v-if="filteredTransportOrders.length"
        :transport-orders-list="filteredTransportOrders"
        @open-task-modal="openTaskModal"/>

      <base-content-loaders v-else-if="!filteredTransportOrders.length && loading"/>

      <div class="no-result-message"
           v-else>
        Заказы транспорта не найдены
      </div>
    </div>
  </div>
</template>

<script>
import TransportOrdersFilter from "@/components/ServiceDesk/TransportOrders/TransportOrdersFilter";
import {mapGetters, mapState} from "vuex";
import TransportOrdersList from "@/components/ServiceDesk/TransportOrders/TransportOrdersList";
import BasePanel from "@/components/BasePanel";
import BaseFunctionalLine from "@/components/BaseFunctionalLine";
import TransportOrder from "@/models/serviceDesk/TransportOrder";
import BaseContentLoaders from "@/components/BaseContentLoaders";

export default {
  name: "TransportOrdersListPage",
  components: {
    BaseContentLoaders,
    BaseFunctionalLine,
    BasePanel,
    TransportOrdersList,
    TransportOrdersFilter
  },
  data() {
    return {
      activeFilterState: 1,
      isTaskModalActive: false
    }
  },
  computed: {
    ...mapState({
      transportOrders: state => state.transportOrders.transportOrdersList,
      loading: state => state.transportOrders.loading
    }),
    ...mapGetters({
      filteredTransportOrders: 'transportOrders/getFilteredAndSortedTransportOrders'
    }),
    TransportOrderStates: () => TransportOrder.getStates()
  },
  mounted() {
    this.$store.commit("transportOrders/SET_LIST_FILTER_PARAMS", {
      prop: 'state',
      value: 1
    });
    this.$store.dispatch('transportOrders/getTransportOrdersList');
  },
  beforeDestroy() {
    this.$store.dispatch('baseSideModal/closeModal');
  },
  methods: {
    searchTransportOrders(event) {
      this.$store.commit('transportOrders/SET_SEARCH_QUERY', event.target.value);
    },
    filterTransportOrdersList(transportOrderState) {
      this.activeFilterState = transportOrderState;

      this.$store.commit("transportOrders/SET_TRANSPORT_ORDERS_LIST", []);

      if (transportOrderState > 0) {
        this.$store.commit('transportOrders/SET_LIST_FILTER_PARAMS', {
          prop: 'state',
          value: transportOrderState
        })
      } else {
        this.$store.commit('transportOrders/SET_LIST_FILTER_PARAMS', {
          prop: 'state',
          value: null
        })
      }

      this.$store.dispatch('transportOrders/getTransportOrdersList');
    },
    openTaskModal(taskId) {
      this.$store.dispatch('history/getTaskHistory', taskId);
      this.$store.dispatch('files/getTasksFiles', taskId);
      this.$store.dispatch('works/getTaskWorks', taskId);
      this.$store.dispatch('comments/getTaskComments', taskId);
      this.$store.dispatch('taskMechanicalEquipments/getTaskMechanicalEquipments', taskId);
      this.$store.dispatch('waterFlows/getTaskWaterFlows', taskId);
      this.$store.commit('transportOrders/SET_FILTER_PARAM', {
        prop: 'task_id',
        value: taskId
      });
      this.$store.dispatch('transportOrders/getTransportOrders');
      this.$store.dispatch('networkSpread/getTaskNetworkSpread', taskId);

      this.$store.dispatch('task/getTask', taskId)
        .then(task => {
          this.$store.dispatch('emergencyElements/getEmergencyElements', task.issue.id)
          this.isTaskModalActive = true;
        })
    }
  }
}
</script>

<style scoped>
.no-result-message {
  font-size: 24px;
  text-align: center;
}
</style>
