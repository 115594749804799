import * as API from './API';
import NetworkLength from "@/models/EngineeringNetworks/NetworkLength";
import {getError} from "@/utils/helpers";

export default {
    fetchNetworksLength(params) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/network-engineering/network-lengths`, {params: params})
                .then(response => resolve(response.data.data
                    .map(item => new NetworkLength({id: item.id, ...item.attributes}))))
                .catch(error => reject(getError(error)))
        })
    },
    changeNetworkLength(payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/network-engineering/network-lengths/`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}
