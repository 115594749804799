<template>
  <div class="page-wrapper">
    <base-panel
      title="Конфигурация типов объектов"
      :is-add-button-visible="false"
    />

    <network-object-types-list
      :network-object-types-list="networkObjectTypesList"
      @open-type-config-page="id => this.$router.push('/technical-inspection-network-object-types' + `/${id}`)"
    />
  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel.vue";
import NetworkObjectTypesList from "@/components/TechnicalInspection/NetworkObjectTypes/NetworkObjectTypesList.vue";
import {mapState} from "vuex";

export default {
  name: "NetworkObjectTypesListPage",
  components: {NetworkObjectTypesList, BasePanel},
  computed: {
    ...mapState({
      networkObjectTypesList: state => state.networkObjectTypes.networkObjectTypes
    })
  },
  mounted() {
    this.$store.dispatch('networkObjectTypes/getNetworkObjectTypesList');
  }
}
</script>

<style scoped>

</style>