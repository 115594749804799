import ShutdownsService from "@/services/ShutdownsService";
import moment from "moment";

export const namespaced = true;

export const state = {
    shutdowns: [],
    error: null,
    loading: false
}

export const mutations = {
    SET_SHUTDOWNS(state, shutdowns) {
        state.shutdowns = shutdowns;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    }
}

export const actions = {
    getIssueShutdowns({commit}, issue_id) {
        commit("SET_LOADING", true);
        ShutdownsService.getIssueShutdowns(issue_id)
            .then(shutdowns => commit("SET_SHUTDOWNS", shutdowns))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    createShutdown({commit}, {payload, issue_id}) {
        return new Promise((resolve, reject) => {
            ShutdownsService.createShutdown({payload, issue_id})
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    },
    updateShutdown(context, {issueId, shutdownId, payload}) {
        return new Promise((resolve, reject) => {
            ShutdownsService.updateShutdown(issueId, shutdownId, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    startShutdown({commit}, {issue_id, shutdown_id}) {
        return new Promise((resolve, reject) => {
            ShutdownsService.startShutdown({issue_id, shutdown_id})
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    },
    finishShutdown({commit}, {issue_id, shutdown_id}) {
        return new Promise((resolve, reject) => {
            ShutdownsService.finishShutdown({issue_id, shutdown_id})
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    },
    deleteShutdown({commit}, {issue_id, shutdown_id}) {
        return new Promise((resolve, reject) => {
            ShutdownsService.deleteShutdown({issue_id, shutdown_id})
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    }
}

export const getters = {
    getShutdowns: state => {
        return state.shutdowns;
    },
    isAccident: state => {
        const shutdownsDuration = state.shutdowns.map(shutdown => {
            return moment(shutdown.actual_end_date)
                .diff(moment(shutdown.actual_start_date), 'hours', true);
        })
        return shutdownsDuration.some(duration => duration > 48);
    }
}