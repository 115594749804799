<template>
  <div class="page-wrapper">
    <base-panel
      class="w-100"
      :title="'Расписание ресурсов'"
      :is-add-button-visible="true"
      :add-button-name="'Добавить событие'"
      @add-button-clicked="isAddModalVisible = true"/>
    <brigades-work-shift-modal
      :is-add-modal-visible="isAddModalVisible"
      @close-add-modal="isAddModalVisible = false"/>
    <base-popup-target
      :is-active="isActiveModalTargetDelete"
      :title="'Удаление события'"
      @deleteItem="deleteWorkShift"
      @closeModal="closeDeletionModal"
    >
      <div class="popup__body-text">
        Вы уверены, что хотите удалить событие? Это действие невозвратно.
      </div>
    </base-popup-target>
    <div class="card-body">
      <base-calendar :events="workShifts.map(workShift => workShift.getCalendarEvent())">
        <template v-slot:eventItem="{eventData}">
          <div class="base-calendar__content">
            {{ eventData.title }}
            <div data-modal-toggle
                 @click="toggleDeletionModal(eventData)">
              <icon-close/>
            </div>
          </div>
        </template>
      </base-calendar>
    </div>
  </div>
</template>

<script>
import BaseCalendar from "@/components/BaseCalendar";
import {mapState} from "vuex";
import BasePanel from "@/components/BasePanel.vue";
import BrigadesWorkShiftModal from "@/components/ServiceDesk/BrigadesWorkShiftModal.vue";
import BasePopupTarget from "@/components/BasePopupTarget.vue";
import IconClose from "@/components/Icons/IconClose.vue";

export default {
  name: "BrigadesWorkShiftPage",
  components: {IconClose, BasePopupTarget, BrigadesWorkShiftModal, BasePanel, BaseCalendar},
  data() {
    return {
      workShiftId: null,
      isAddModalVisible: false,
      isActiveModalTargetDelete: false
    }
  },
  computed: {
    ...mapState({
      workShifts: state => state.workShift.workShifts,
      filterParams: state => state.workShift.filterParams
    })
  },
  mounted() {
    this.$store.dispatch('departments/getAllDepartments');
    this.$store.dispatch('workShift/getWorkShifts', this.filterParams);
    this.addEventListeners();
  },
  methods: {
    getWorkShifts() {
      this.$store.dispatch('workShift/getWorkShifts', this.filterParams);
    },
    addEventListeners() {
      let self = this
      const prevButtons = document.getElementsByClassName('fc-prev-button');
      const nextButtons = document.getElementsByClassName('fc-next-button');
      const todayButtons = document.getElementsByClassName('fc-today-button');
      if (prevButtons.length) prevButtons[0].addEventListener('click', function () {
        self.getWorkShifts()
      })
      if (nextButtons.length) nextButtons[0].addEventListener('click', function () {
        self.getWorkShifts()
      })
      if (todayButtons.length) todayButtons[0].addEventListener('click', function () {
        self.getWorkShifts()
      })
    },
    toggleDeletionModal(workShift) {
      this.isActiveModalTargetDelete = true;
      this.workShiftId = +workShift.id;
    },
    closeDeletionModal() {
      this.isActiveModalTargetDelete = false;
    },
    deleteWorkShift() {
      this.$store.dispatch("workShift/deleteWorkShift", this.workShiftId);
      this.closeDeletionModal();
    }
  }
}
</script>

<style scoped>

</style>