<template>
  <div class="additional-option-wrapper always-open">
    <div class="additional-option">
      <div class="additional-option__icon">
        <icon-activity/>
      </div>
      <div class="additional-option__name"><span>Датчики</span>
        <span class="additional-option__quantity">{{ sensorsList.length }}</span>
      </div>
      <div class="additional-option__buttons">
        <button
          class="additional-option__add"
          type="button"
          @click="showSensorFormPopup()">
          <icon-plus/>
        </button>
      </div>
    </div>
    <div class="additional-options-list">
      <div class="additional-options-list__inner">
        <table class="base-modal-table">
          <thead>
            <tr>
              <th class="base-modal-table__header-cell">Полное наименование</th>
              <th class="base-modal-table__header-cell base-modal-table__header-cell--center">
                Краткое <br>наименование
              </th>
              <th class="base-modal-table__header-cell base-modal-table__header-cell--center">Ед. изм.</th>
              <th class="base-modal-table__header-cell base-modal-table__header-cell--center
               base-modal-table__header-cell--fixed-300">Связанный параметр</th>
              <th class="base-modal-table__header-cell base-modal-table__header-cell--center"/>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="sensor in sensorsList"
              :key="sensor.id"
            >
              <td class="base-modal-table__body-cell">{{ sensor.sensor_name }}</td>
              <td class="base-modal-table__body-cell base-modal-table__body-cell--center">
                {{ sensor.sensor_short_name }}
              </td>
              <td class="base-modal-table__body-cell base-modal-table__body-cell--center">
                {{ sensor.sensor_measure }}
              </td>
              <td class="base-modal-table__body-cell base-modal-table__body-cell--center
               base-modal-table__body-cell--fixed-300">
                <v-select
                  class="vue-select-input-borderless vue-select-input-borderless--arrow-right"
                  :value="sensor.parameter ? sensor.parameter.id : null"
                  :options="parameters"
                  :reduce="parameter => parameter.id"
                  :get-option-label="option => option.equipmentName + ': ' + option.shortName"
                  placeholder="Выберите параметр"
                  @input="saveDeviceParameter($event, sensor.id)"
                  :disabled="attachedParameterInputDisabled"
                />
              </td>
              <td class="base-modal-table__body-cell base-modal-table__body-cell--center">
                <div class="base-modal-table__flex-block base-modal-table__flex-block--centered">
                  <div @click="showSensorFormPopup(sensor)">
                    <icon-pencil class="svg added-item__tool added-item__tool--size-20"/>
                  </div>
                  <div @click="deleteSensor(sensor.id)">
                    <icon-trash class="svg added-item__tool"/>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {showAlert, showToast, showConfirmationModal} from "@/utils/notification";
import {mapGetters, mapState} from "vuex";
import IconPlus from "@/components/Icons/IconPlus.vue";
import IconTrash from "@/components/Icons/IconTrash.vue";
import IconPencil from "@/components/Icons/IconPencil.vue";
import IconActivity from "@/components/Icons/IconActivity.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import DeviceSensorsForm from "@/components/Telemetry/admin/NetworkObject/DeviceSensorsForm.vue";

export default {
  name: "DeviceSensorsList",
  components: {IconActivity, IconPencil, IconTrash, IconPlus},
  props: {
    sensorsList: {
      type: Array,
      required: true
    },
    deviceId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      attachedParameterInputDisabled: false
    }
  },
  computed: {
    ...mapGetters({
      parameters: 'networkObjectPage/getEquipmentsParametersList'
    }),
    ...mapState({
      networkObject: state => state.networkObjectPage.networkObject
    })
  },
  methods: {
    showSensorFormPopup(sensor = {}) {
      this.$modal.show(
        DeviceSensorsForm,
        {
          'popupName': 'device-sensor-form',
          'deviceId': this.deviceId,
          'sensor': sensor
        },
        {
          name: 'device-sensor-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460),
          scrollable: true
        }
      )
    },
    deleteSensor(sensorId) {
      showConfirmationModal('Вы уверены, что хотите удалить датчик? Это действие невозвратно.')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('sensor/deleteSensor', sensorId)
              .then(() => {
                this.$store.dispatch('networkObjectPage/getNetworkObjectDevices', this.networkObject.id)
                  .then(() => this.$store.dispatch('networkObjectPage/getDevice', this.deviceId))
                showToast('Датчик удалён', 'success');
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    },
    saveDeviceParameter(event, sensorId) {
      this.attachedParameterInputDisabled = true;
      if (event) {
        this.$store.dispatch('sensor/attachToParameter', {
          sensorId: sensorId,
          payload: {
            parameter_id: event
          }
        })
            .then(() => {
              showToast('Параметр привязан', 'success');
              this.$store.dispatch('networkObjectPage/getNetworkObjectDevices', this.networkObject.id)
                .then(() => this.$store.dispatch('networkObjectPage/getDevice', this.deviceId))
            })
            .catch(error => showAlert('Ошибка', error))
            .finally(() => this.attachedParameterInputDisabled = false)
      } else  {
        this.$store.dispatch('sensor/detachParameter', sensorId)
            .then(() => {
              showToast('Параметр отвязан', 'success');
              this.$store.dispatch('networkObjectPage/getNetworkObjectDevices', this.networkObject.id)
                .then(() => this.$store.dispatch('networkObjectPage/getDevice', this.deviceId))
            })
            .catch(error => showAlert('Ошибка', error))
            .finally(() => this.attachedParameterInputDisabled = false)
      }
    }
  }
}
</script>

<style scoped>
.sensor {
  padding: 10px;
}

.sensor__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.sensor__name {
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  color: var(--text-primary);
  line-height: 1;
}

.sensor__actions {
  display: flex;
}

.sensor__text span {
  font-size: 14px;
  font-weight: 600;
}

.sensor__text span:first-child {
  color: var(--text-link);
  margin-right: 3px;
}

.sensor__text span:last-child {
  color: var(--text-primary);
}

.sensor__add_btn {
  width: 100%;
  text-align: center;
  cursor: pointer;
  color: var(--text-link);
  font-weight: 600;
}
</style>
