import * as API from "@/services/API";
import Shutdown from "@/models/serviceDesk/Shutdown";
import {getError} from "@/utils/helpers";

export default {
    getIssueShutdowns(issue_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/issues/${issue_id}/shutdowns`)
                .then(response => resolve(
                    response.data.data.map(shutdown => new Shutdown(shutdown.id, shutdown.attributes))
                ))
                .catch(error => reject(getError(error)));
        })
    },
    createShutdown({payload, issue_id}) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`service-desk/issues/${issue_id}/shutdowns`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    updateShutdown(issueId, shutdownId, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`service-desk/issues/${issueId}/shutdowns/${shutdownId}`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    startShutdown({issue_id, shutdown_id}) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`service-desk/issues/${issue_id}/shutdowns/${shutdown_id}/start`)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    finishShutdown({issue_id, shutdown_id}) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`service-desk/issues/${issue_id}/shutdowns/${shutdown_id}/finish`)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    deleteShutdown({issue_id, shutdown_id}) {
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`service-desk/issues/${issue_id}/shutdowns/${shutdown_id}`)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    }
}