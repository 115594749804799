import IssueTypesService from "@/services/IssueTypesService";

export const namespaced = true;

export const state = {
    types: [],
    error: null
}

export const mutations = {
    SET_TYPES(state, types) {
        state.types = types;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getIssueTypes({commit}) {
        return new Promise((resolve, reject) => {
            IssueTypesService.getIssueTypes()
                .then(types => {
                    commit("SET_TYPES", types);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                })
        })
    }
}

export const getters = {
    getIssueTypes: state => {
        return state.types;
    }
}