import TransportDataServerTypesService from "@/services/TransportDataServerTypesService";

export const namespaced = true;

export const state = {
    dataServerTypes: [],
    error: null,
    loading: false
}

export const mutations = {
    SET_DATA_SERVER_TYPES(state, types) {
        state.dataServerTypes = types;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    }
}

export const actions = {
    getAllTransportDataServerTypes({commit}) {
        commit("SET_LOADING", true);
        TransportDataServerTypesService.fetchAllTransportDataServerTypes()
            .then(types => commit("SET_DATA_SERVER_TYPES", types))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    }
}

export const getters = {
    getDataServerTypes: state => {
        return state.dataServerTypes;
    }
}