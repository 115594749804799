<template>
  <div>
    <base-no-data
      v-if="$store.state.client.client === null"
      title="Абонент не найден"
    />

    <div
      v-else
      class="text-list"
    >
      <div class="text-list__item">
        <div class="text-list__item-name">
          Тип абонента:
        </div>
        <div class="text-list__item-value">
          {{ clientType }}
        </div>
      </div>
      <div class="text-list__item">
        <div class="text-list__item-name">
          ФИО / Наименование:
        </div>
        <div class="text-list__item-value">
          {{ clientName }}
        </div>
      </div>
      <div class="text-list__item">
        <div class="text-list__item-name">
          Адрес абонента:
        </div>
        <div class="text-list__item-value">
          {{ clientAddress }}
        </div>
      </div>
      <div class="text-list__item">
        <div class="text-list__item-name">
          Номер договора:
        </div>
        <div class="text-list__item-value">
          {{ clientLS }}
        </div>
      </div>
      <div class="text-list__item">
        <div class="text-list__item-name">
          Текущий статус договора:
        </div>
        <div class="text-list__item-value">
          {{ clientLsStatus }}
        </div>
      </div>
      <div
        v-if="clientReadings.length !== 0"
        class="text-list__item"
      >
        <base-collapse
          :default-collapse-state="true"
          container-width="100%">
          <template #toggle="{toggle, isCollapsed}">
            <div
              @click="toggle()"
              class="text-list__item-name text-list__item-name--cur-pointer text-list__item-name--full-width"
            >
              <img
                id="readings_icon"
                alt="Раскрыть начисления"
                class="collapse-arrow"
                :class="{'rotateZ-90deg': !isCollapsed}"
                src="../../../assets/img/billing/arrow-right.svg"
              >
              Начисления за имеющиеся периоды
            </div>
          </template>

          <template #body>
            <div class="client-readings">
              <div class="client-readings__switcher">

                <div class="billing-view-switcher">
                  <div @click="readingsView = 'list'"
                       :class="{active: readingsView === 'list'}">
                    <icon-tasks />
                  </div>
                  <div @click="readingsView = 'table'"
                       :class="{active: readingsView === 'table'}">
                    <icon-row-vertical/>
                  </div>
                  <div @click="readingsView = 'table-years'"
                       :class="{active: readingsView === 'table-years'}">
                    <icon-sort2/>
                  </div>
                  <div @click="showClientReadingsPopup()">
                    <icon-activity/>
                  </div>
                </div>
              </div>

              <div class="client-readings__content">
                <div v-if="readingsView === 'list'"
                     class="client-readings__list">
                  <div
                    v-for="reading in clientReadings"
                    :key="reading.id"
                    class="client-readings__list-item"
                  >
                    <div class="text-list__item-name">
                      {{ setMonth(reading.period) }}
                    </div>
                    <div class="text-list__item-value">
                      Хол. вода {{ reading.hw }}м<sup>3</sup>
                    </div>
                    <div class="text-list__item-value">
                      Стоки хол. воды {{ reading.shw }}м<sup>3</sup>
                    </div>
                    <div class="text-list__item-value">
                      Стоки гор. воды {{ reading.sgw }}м<sup>3</sup>
                    </div>
                  </div>
                </div>
              </div>


              <table
                v-if="readingsView === 'table'"
                class="admin-table">
                <thead class="admin-table-header">
                  <tr>
                    <td>Период</td>
                    <td>ХВС, м<sup>3</sup></td>
                    <td>Стоки, м<sup>3</sup></td>
                  </tr>
                </thead>
                <tbody class="admin-table-body">
                  <tr v-for="reading in clientReadings"
                      :key="reading.id">
                    <td>{{ setMonth(reading.period) }}</td>
                    <td>{{ reading.hw }}</td>
                    <td>{{ reading.shw + reading.sgw }}</td>
                  </tr>
                </tbody>
              </table>

              <table
                v-if="readingsView === 'table-years'"
                class="admin-table">
                <thead class="admin-table-header">
                  <tr>
                    <td rowspan="2">Месяц</td>
                    <td :colspan="getTableYears(clientReadings).size">
                      <span v-if="tableYearsDrain">Стоки, м<sup>3</sup></span>
                      <span v-else>ХВС, м<sup>3</sup></span>
                      <div
                        @click="tableYearsDrain = !tableYearsDrain"
                        class="billing-client-table-change-icon">
                        <icon-refresh/>
                      </div>
                    </td>
                  </tr>
                  <tr class="table-header-second-row">
                    <td v-for="year in getTableYears(clientReadings)"
                        :key="year">
                      {{ year }}
                    </td>
                  </tr>
                </thead>
                <tbody class="admin-table-body">
                  <tr v-for="reading_group in getSortedReadingsForTable(clientReadings)"
                      :key="reading_group.month">
                    <td>{{ reading_group.month }}</td>
                    <td v-for="(reading, index) in reading_group.readings"
                        :key="index">
                      {{ reading }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
        </base-collapse>
      </div>
      <div
        v-if="clientMeters.length !== 0"
        class="text-list__item"
      >
        <base-collapse
          :default-collapse-state="true"
          container-width="100%">
          <template #toggle="{toggle, isCollapsed}">
            <div
              @click="toggle()"
              class="text-list__item-name text-list__item-name--cur-pointer text-list__item-name--full-width"
            >
              <img
                id="meters_icon"
                alt="Раскрыть приборы учёта"
                class="collapse-arrow"
                :class="{'rotateZ-90deg': !isCollapsed}"
                src="../../../assets/img/billing/arrow-right.svg"
              >
              Установленные приборы учета (ПУ) с сопутствующей
              информацией
            </div>
          </template>

          <template #body>
            <div class="client-meters">
              <div
                v-for="meter in clientMeters"
                :key="meter.id"
                class="client-meters__item"
              >
                <div class="text-list__item">
                  <div class="text-list__item-name">
                    Тип ПУ:
                  </div>
                  <div class="text-list__item-value">
                    {{ nullFilter(meter.attributes.type) }}
                  </div>
                </div>
                <div class="text-list__item">
                  <div class="text-list__item-name">
                    Дата установки:
                  </div>
                  <div class="text-list__item-value">
                    {{ nullFilter(meter.attributes.install_date) }}
                  </div>
                </div>
                <div class="text-list__item">
                  <div class="text-list__item-name">
                    Дата проверки:
                  </div>
                  <div class="text-list__item-value">
                    {{ nullFilter(meter.attributes.check_date) }}
                  </div>
                </div>
                <div class="text-list__item">
                  <div class="text-list__item-name">
                    Номер ПУ:
                  </div>
                  <div class="text-list__item-value">
                    {{ nullFilter(meter.attributes.number) }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </base-collapse>
      </div>
      <div class="text-list__item">
        <div class="text-list__item-name">
          Сальдо текущее:
        </div>
        <div class="text-list__item-value">
          {{ clientBalance }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import BaseNoData from "@/components/BaseNoData.vue";
import BillingClientReadingsModalContent
  from "@/components/Billing/BillingFilters/BillingClientReadingsModalContent.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import BaseCollapse from "@/components/BaseCollapse.vue";
import {nullFilter} from "@/utils/filters";
import IconTasks from "@/components/Icons/IconTasks.vue";
import IconRowVertical from "@/components/Icons/IconRowVertical.vue";
import IconActivity from "@/components/Icons/IconActivity.vue";
import IconSort2 from "@/components/Icons/IconSort2.vue";
import IconRefresh from "@/components/Icons/IconRefresh.vue";

export default {
  name: "BillingClientDetail",
  components: {IconRefresh, IconSort2, IconActivity, IconRowVertical, IconTasks, BaseCollapse, BaseNoData},
  data() {
    return {
      chartOptions: null,
      chartData: [],
      readingsView: 'list',
      tableYearsDrain: false
    }
  },
  computed: {
    ...mapGetters({
      clientType: "client/getClientType",
      clientName: "client/getClientName",
      clientAddress: "client/getClientAddress",
      clientObjectName: "client/getClientObjectName",
      clientLS: "client/getClientLS",
      clientLsStatus: "client/getClientLsStatus",
      clientReadings: "client/getClientReadings",
      clientMeters: "client/getClientMeters",
      clientBalance: "client/getClientBalance"
    }),
    setMonth: function () {
      return period => {
        return this.$moment(period).format('MMMM-Y')
      }
    },
    getTableYears: function () {
      return readings => {
        const periods = readings.concat().reverse().map(reading => new Date(reading.period).getFullYear());
        return new Set(periods);
      }
    },
    getSortedReadingsForTable: function () {
      return readings => {
        const months = this.$moment.months()
        const table_readings = months.map(month => {
          return {
            month: month,
            readings: []
          }
        })

        this.getTableYears(readings).forEach(year => {

          table_readings.forEach(item => {
            let current_reading = readings.find(reading => {
              let reading_month = this.$moment(reading.period).format('MMMM');
              let reading_year = this.$moment(reading.period).format('YYYY');

              if (reading_month === item.month && parseInt(reading_year) === year) {
                return reading
              }
            })

            if (current_reading !== undefined) {
              if (this.tableYearsDrain) {
                item.readings.push(current_reading.sgw + current_reading.shw);
              } else {
                item.readings.push(current_reading.hw);
              }
            } else {
              item.readings.push('-')
            }
          })
        })
        return table_readings
      }
    }
  },
  mounted() {
    this.$root.$on("bv::collapse::state", (collapseID, opened) => {
      let arrow_element = document.getElementById(collapseID + '_icon')
      if (opened) arrow_element.style.transform = 'rotateZ(90deg)'
      else arrow_element.style.transform = 'rotate(0deg)'
    })
  },
  methods: {
    nullFilter,
    showClientReadingsPopup() {
      this.$modal.show(
        BillingClientReadingsModalContent,
        {},
        {
          name: 'billing-client-readings',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(880)
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.client-readings {
  width: 100%;

  &__switcher {
    display: flex;
    justify-content: flex-end;
  }

  &__content {
    padding-top: 20px;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;

    &-item {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }
  }
}

.client-meters {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  padding-top: 20px;

  &__item {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
}

.collapse-arrow {
  margin-right: 6px;
  transition: 0.2s;
}

.table-header-second-row td:first-child {
  border-radius: 0;
  border-left: none;
}

.table-header-second-row td:last-child {
  border-radius: 0;
}

.mt-2p {
  margin-top: 2px;
}
</style>
