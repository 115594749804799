import NetworksLengthService from "@/services/NetworksLengthService";
import moment from "moment";

export const namespaced = true;

export const state = {
    networksLength: [],
    loading: false,
    error: null
}

export const mutations = {
    SET_NETWORKS_LENGTH(state, networksLength) {
        state.networksLength = networksLength;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getNetworksLength({commit}, params) {
        commit("SET_LOADING", true);
        NetworksLengthService.fetchNetworksLength(params)
            .then(networksLength => commit("SET_NETWORKS_LENGTH", networksLength))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false))
    },
    changeNetworkLength(context, payload) {
        return new Promise((resolve, reject) => {
            NetworksLengthService.changeNetworkLength(payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    }
}

export const getters = {
    getNetworkLengthByIdAndDate: state => (networkId, month) => {
        const networkLength = state.networksLength.find(networkLength => {
            const networkLengthDate = moment(networkLength.date);
            return networkLength.network.id === networkId
                && networkLengthDate.month() + 1 === month;
        })
        return networkLength ? networkLength.length : ''
    }
}
