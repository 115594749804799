<template>
  <base-popup
    :title="popupTitle"
    :name="popupName"
  >
    <template #body>
      <div class="form-item">
        <div class="form-item__name">Наименование</div>
        <div class="form-item__value">
          <input
            v-model="payload.name"
            class="base-input base-input--bordered"
            type="text"
            placeholder="Введите наименование">
        </div>
      </div>
    </template>

    <template #footer>
      <button
        @click="submitForm()"
        class="button button--mode-primary">Сохранить</button>
    </template>
  </base-popup>
</template>

<script>
import BasePopup from "@/components/BasePopup.vue";

export default {
  name: "DictionaryItemPopup",
  components: {BasePopup},
  props: {
    popupName: {
      type: String,
      required: true
    },
    dictionaryItem: {
      type: Object,
      required: true
    },
    createDictionaryItem: {
      type: Function,
      required: true
    },
    updateDictionaryItem: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      payload: {
        name: null
      }
    }
  },
  computed: {
    isCreateForm() {
      return !Object.keys(this.dictionaryItem).length
    },
    popupTitle() {
      return this.isCreateForm ? 'Добавить значение' : 'Редактирование значения';
    }
  },
  mounted() {
    if (!this.isCreateForm) {
      const {name} = this.dictionaryItem;
      this.payload.name = name;
    }
  },
  methods: {
    submitForm() {
      if (this.isCreateForm) this.createDictionaryItem(this.$route.params.id, this.payload)
      else this.updateDictionaryItem(this.$route.params.id, this.dictionaryItem.id, this.payload)
    }
  }
}
</script>

<style scoped>

</style>