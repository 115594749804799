<template>
  <div class="base-table">
    <div class="base-table__container">
      <div class="base-table__header base-table__column--network-object-types">
        <div class="base-table__header-cell">ID</div>
        <div class="base-table__header-cell">Тип объекта</div>
        <div class="base-table__header-cell">Группа</div>
      </div>

      <div v-for="type in networkObjectTypesList"
           :key="type.id"
           @click="$emit('open-type-config-page', type.id)"
           class="base-table__row base-table__column--network-object-types">
        <div class="base-table__column">
          <div class="base-table__text">{{ type.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ type.name }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ type.groupName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "NetworkObjectTypesList",
  props: {
    networkObjectTypesList: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.base-table__column--network-object-types {
  grid-template-columns: 80px 2fr 1fr;
}
</style>