export const namespaced = true;

export const state = {
    appVersion: null,
    loading: false,
    error: null
}

export const mutations = {
    SET_APP_VERSION(state, version) {
        state.appVersion = version;
    },
    SET_LOADING(state, value) {
        state.loading = value;
    },
    SET_ERROR(state, value) {
        state.error = value;
    }
}

export const actions = {
    fetchAppVersion() {}
}

export const getters = {
    isUpdateAvailable() {
        return false;
    },
    localAppVersion() {
        return process.env.VUE_APP_VERSION;
    }
}
