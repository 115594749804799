<template>
  <div class="i-modal__content-wrapper">
    <div class="i-modal__content">
      <div class="i-modal__inner">
        <div class="base-modal__left-side">
          <div class="base-modal-header">
            <span class="base-modal-title">Новое задание на обход</span>
          </div>
          <div class="base-modal-info base-modal-info--padding-s">
            <div class="create-task-modal">
              <div class="create-task-modal__map">
                <task-create-map
                  :selected-object="payload.object_id"
                  @network-object-marker-click="handleMapMarkerClick"/>
              </div>
              <div class="create-task-modal__right">
                <div class="create-task-modal__form">
                  <div class="form-item">
                    <div class="form-item__name">Обходчик</div>
                    <div class="form-item__value">
                      <v-select
                        v-model="payload.resource_id"
                        :options="resourcesList"
                        :reduce="resource => resource.id"
                        :get-option-label="(option) => option.name"
                        placeholder="Выберите из списка"
                        class="vue-select-input"
                      />
                    </div>
                  </div>

                  <div class="form-item">
                    <div class="form-item__name">Плановая дата обхода</div>
                    <div class="form-item__value">
                      <date-picker
                        v-model="payload.planned_date"
                        :default-value="date"
                        type="date"
                        format="DD.MM.YYYY"
                        value-type="YYYY-MM-DD"
                        class="base-date-picker"
                        placeholder="Выберите дату"
                      />
                    </div>
                  </div>

                  <div class="form-item">
                    <div class="form-item__name">Приоритет</div>
                    <div class="form-item__value">
                      <v-select
                        v-model="payload.priority_id"
                        :options="prioritiesList"
                        :reduce="priority => priority.id"
                        :get-option-label="(option) => option.label"
                        placeholder="Выберите из списка"
                        class="vue-select-input"
                      />
                    </div>
                  </div>

                  <div class="form-item">
                    <div class="form-item__name">Объект</div>
                    <div class="form-item__value form-item__value--column form-item__value--items-left">
                      <v-select
                        v-model="objectSelectValue"
                        :options="objectsList"
                        :reduce="object => object"
                        :get-option-label="(option) => option.name"
                        placeholder="Выберите из списка"
                        class="vue-select-input"
                        @input="selectObject"
                      >
                        <template #no-options="">
                          Приближайте и перемещайте карту, чтобы загрузить объекты
                        </template>
                      </v-select>

                      <div
                        v-if="selectedObject"
                        class="selected-object">
                        <div
                          @click="deselectObject()"
                          class="selected-object__icon"><icon-close/></div>
                        {{selectedObject.name}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="create-task-modal__form-buttons">
                  <button
                    @click="createTask()"
                    class="button button--mode-primary">Создать и отправить</button>
                  <button
                    @click="$store.dispatch('baseSideModal/goBack')"
                    class="button button--mode-outline">Отменить</button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TaskCreateMap from "@/components/TechnicalInspection/Tasks/TaskCreateMap.vue";
import {mapState} from "vuex";
import DatePicker from "vue2-datepicker";
import {showToast, showAlert} from "@/utils/notification";
import IconClose from "@/components/Icons/IconClose.vue";

export default {
  name: "TaskCreateModalContent",
  components: {IconClose, TaskCreateMap, DatePicker},
  data() {
    return {
      date: new Date(),
      prioritiesList: [
        {id: 1, label: 'Плановый'},
        {id: 2, label: 'Не плановый'}
      ],
      objectSelectValue: null,
      selectedObject: null,
      payload: {
        resource_id: null,
        object_id: null,
        priority_id: null,
        planned_date: null
      }
    }
  },
  computed: {
    ...mapState({
      resourcesList: state => state.resources.resourcesList,
      objectsList: state => state.technicalInspectionTasks.objectsList
    })
  },
  methods: {
    createTask() {
      this.payload.planned_date = this.$moment(this.payload.planned_date).format('YYYY-MM-DD 00:00');
      this.$store.dispatch('technicalInspectionTasks/createTask', this.payload)
        .then(() => {
          this.$store.dispatch('technicalInspectionTasks/getTasksList');
          this.$store.dispatch('baseSideModal/goBack');
          showToast('Задание на обход добавлено', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    handleMapMarkerClick({params}) {
      const object = this.objectsList.find(object => object.id === params.objectId);
      this.selectObject(object);
    },
    selectObject(object) {
      this.selectedObject = object;
      this.payload.object_id = object.id;
      this.objectSelectValue = null;
    },
    deselectObject() {
      this.selectedObject = null;
      this.payload.object_id = null;
    }
  }
}
</script>

<style scoped lang="scss">
.base-modal__left-side {
  max-width: 100%;
}

.base-modal-info {
  width: calc(100vw - 350px);
}

.create-task-modal {
  display: flex;
  gap: 36px;

  &__map {
    flex: 2;

    height: calc(100vh - 200px);
  }

  &__right {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__form-buttons {
    display: flex;
    gap: 20px;

    & .button {
      flex: 1;
    }
  }
}

.selected-object {
  display: flex;
  align-items: center;
  line-height: 1;

  &__icon {
    cursor: pointer;
  }
}
</style>