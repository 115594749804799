<template>
  <base-popup
    :title="popupTitle"
    :name="popupName"
  >
    <template v-slot:body>
      <div class="form-item">
        <div class="form-item__name">Сетевой объект</div>
        <div class="form-item__value">
          <v-select
            class="vue-select-input"
            :class="{'vue-select-input--error': $v.payload.construction_id.$error}"
            v-model="$v.payload.construction_id.$model"
            :options="networkObjects"
            :reduce="networkObject => networkObject.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите сетевой объект"/>
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Тип подключения</div>
        <div class="form-item__value">
          <v-select
            class="vue-select-input"
            :class="{'vue-select-input--error': $v.payload.connection.$error}"
            v-model="$v.payload.connection.$model"
            :options="connectionTypes"
            :reduce="type => type.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите тип подключения"/>
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Наименование</div>
        <div class="form-item__value">
          <input
            v-model="$v.payload.name.$model"
            class="base-input base-input--bordered"
            :class="{'base-input--error': $v.payload.name.$error}"
            type="text"
            placeholder="Введите наименование"
            maxlength="21">
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Наименование устройства</div>
        <div class="form-item__value">
          <input
            v-model="$v.payload.device_name.$model"
            class="base-input base-input--bordered"
            :class="{'base-input--error': $v.payload.device_name.$error}"
            type="text"
            placeholder="Введите наименование устройства">
        </div>
      </div>

      <div class="form-item">
        <label class="base-switcher">
          <input
            v-model="payload.active"
            class="base-switcher__checkbox"
            type="checkbox">
          <div class="base-switcher__switch"/>
          <span class="base-switcher__label">
            Активна
          </span>
        </label>
      </div>
    </template>

    <template v-slot:footer>
      <div
        @click="checkFormType()"
        class="button">
        Сохранить
      </div>
    </template>
  </base-popup>
</template>

<script>
import {mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";
import Device from "@/models/telemetry/Device";
import BasePopup from "@/components/BasePopup.vue";
import {validationMixin} from "vuelidate";
import {required} from 'vuelidate/lib/validators'

export default {
  name: "DeviceForm",
  components: {BasePopup},
  mixins: [validationMixin],
  props: {
    networkObjectId: {
      type: Number,
      required: true
    },
    device: {
      required: true
    },
    popupName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      payload: {
        construction_id: null,
        connection: null,
        name: null,
        device_name: null,
        active: true
      }
    }
  },
  validations: {
    payload: {
      construction_id: {required},
      connection: {required},
      name: {required},
      device_name: {required},
      active: {required}
    }
  },
  computed: {
    ...mapState({
      networkObjects: state => state.networkObjects.networkObjects
    }),
    connectionTypes: () => Device.connectionTypesList,
    popupTitle() {
      return !Object.keys(this.device).length ? 'Добавление устройства' : 'Редактирование устройства'
    }
  },
  mounted() {
    this.$store.dispatch('networkObjects/getAllNetworkObjects');
    this.$store.dispatch('device/getDevices');

    this.payload.construction_id = this.networkObjectId;
    if (Object.keys(this.device).length) {
      const {networkObject, dataSource, name, deviceName, active} = this.device;

      this.payload.construction_id = networkObject.id;
      this.payload.connection = dataSource;
      this.payload.name = name;
      this.payload.device_name = deviceName;
      this.payload.active = active;
    }
  },
  methods: {
    checkFormType() {
      this.$v.$touch()
      if (this.$v.$invalid) return false

      if (Object.keys(this.device).length) this.updateDevice()
      else this.createDevice()
    },
    createDevice() {
      this.$store.dispatch('device/createDevice', this.payload)
          .then(() => {
            this.$store.dispatch('networkObjectPage/getNetworkObjectDevices', this.networkObjectId);
            this.$modal.hide(this.popupName);
            showToast('Устройство добавлено', 'success');
          })
          .catch(error => showAlert('Ошибка', error))
    },
    updateDevice() {
      this.$store.dispatch('device/updateDevice', {
        deviceId: this.device.id,
        payload: this.payload
      })
          .then(() => {
            this.$store.dispatch('networkObjectPage/getNetworkObjectDevices', this.networkObjectId);
            this.$modal.hide(this.popupName);
            showToast('Устройство обновлено', 'success');
          })
          .catch(error => showAlert('Ошибка', error))
    }
  }
}
</script>

<style scoped>

</style>