<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      <template v-if="!Object.keys(electricMeter).length">Добавить счетчик</template>
      <template v-else>Редактирование счетчика</template>
      <span @click="$modal.hide('electric-meter-form')"
            class="base-popup__close">
        <icon-close/>
      </span>
    </div>
    <div class="popup-form-group">
      <div class="form-item">
        <div class="form-item__name">Номер</div>
        <div class="form-item__value">
          <input
            v-model="payload.number"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите номер"
            required
          >
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Модель</div>
        <div class="form-item__value">
          <input
            v-model="payload.model"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите модель"
            required
          >
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Дата установки</div>
        <div class="form-item__value">
          <date-picker
            v-model="payload.installation_date"
            :default-value="defaultValue"
            class="base-date-picker"
            placeholder="Выберите дату установки"
            type="day"
            format="DD.MM.YYYY"
            value-type="YYYY-MM-DD"
          />
        </div>
      </div>
    </div>
    <div class="base-popup__footer">
      <div @click="checkFormType()"
           class="button">
        Сохранить
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import IconClose from "@/components/Icons/IconClose.vue";

export default {
  name: "ElectricMeterForm",
  components: {IconClose, DatePicker},
  props: {
    electricMeter: {
      type: Object,
      required: false
    },
    createElectricMeter: {
      type: Function,
      default: () => {}
    },
    updateElectricMeter: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      payload: {
        number: null,
        model: null,
        installation_date: null
      },
      defaultValue: new Date()
    }
  },
  mounted() {
    if (Object.keys(this.electricMeter).length) {
      const {number, model, installationDate} = this.electricMeter;
      this.payload.number = number;
      this.payload.model = model;
      this.payload.installation_date = installationDate;
    }
  },
  methods: {
    checkFormType() {
      if (Object.keys(this.electricMeter).length) this.updateElectricMeter(this.electricMeter.id, this.payload)
      else this.createElectricMeter(this.payload)
    }
  }
}
</script>

<style scoped>

</style>