<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="18"
       height="20"
       viewBox="0 0 18 20"
       fill="none">
    <path
      d="M8.875 19C4.528 19 1 15.472 1 11.125C1 6.778 4.528 3.25 8.875 3.25C13.222 3.25 16.75 6.778 16.75
       11.125M8.875 6.4V10.9"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path d="M6.17578 1H11.5758"
          stroke="#9192AD"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M15.1766 14.5V18.1M12.4766 14.5V18.1"
          stroke="#9192AD"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconTimerPause"
}
</script>

<style scoped>

</style>
