export default class IssueState {
    static NEW = 1;
    static IN_WORK = 4;
    static CANCELED = 6;
    static CLOSED = 7;

    constructor(id) {
        this.id = id;
        this.label = this.getStateLabel();
        this.color = this.getStateColor();
    }

    getStateLabel() {
        switch (this.id) {
            case IssueState.NEW:
                return 'Новая'
            case IssueState.IN_WORK:
                return 'В работе'
            case IssueState.CANCELED:
                return 'Отменена'
            case IssueState.CLOSED:
                return 'Закрыта'
        }
    }

    getStateColor() {
        switch (this.id) {
            case IssueState.NEW:
                return '#468FC5'
            case IssueState.IN_WORK:
                return '#FFBF1C'
            case IssueState.CANCELED:
                return '#4f4f4f'
            case IssueState.CLOSED:
                return '#4f4f4f'
        }
    }

    getStateName() {
        switch (this.id) {
            case IssueState.NEW:
                return 'new'
            case IssueState.IN_WORK:
                return 'in-work'
            case IssueState.CANCELED:
                return 'canceled'
            case IssueState.CLOSED:
                return 'closed'
        }
    }

    get isNew() {
        return this.id === IssueState.NEW;
    }
}
