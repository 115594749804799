<template>
  <div class="base-table">
    <div class="base-table__container">
      <base-table-header
        :table-headers="tableHeaders"
        header-class-name="base-table__column--users-list"
        @update-sort-by="sortName => $emit('update-sort-by', sortName)"
        @update-sort-order="sortOrder => $emit('update-sort-order', sortOrder)"
      />

      <div v-for="user in usersList"
           :key="user.id"
           @click="$emit('show-user-modal', user.id)"
           class="base-table__row base-table__column--users-list">
        <div class="base-table__column">
          <div class="base-table__text">{{ user.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ user.name }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ user.email }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ user.structuralUnit.name }}</div>
        </div>
        <div class="base-table__column base-table__column--content-right">
          <div class="base-table__short-info">
            <div @click.stop="$emit('update-user', user)">
              <icon-pencil class="base-table__short-info--p-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconPencil from "@/components/Icons/IconPencil.vue";
import BaseTableHeader from "@/components/BaseTableHeader.vue";

export default {
  name: "UsersList",
  components: {BaseTableHeader, IconPencil},
  props: {
    usersList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      tableHeaders: [
        {id: 1, name: 'ID', sortName: null, sortDir: null},
        {id: 2, name: 'Имя', sortName: 'name', sortDir: null},
        {id: 3, name: 'E-mail', sortName: 'email', sortDir: null},
        {id: 4, name: 'Структурное подразделение', sortName: 'unit', sortDir: null},
        {id: 7, name: '', sortName: null, sortDir: null}
      ]
    }
  }
}
</script>

<style>
.base-table__column--users-list {
  grid-template-columns: 1fr 3fr 3fr 3fr 1fr;
}
</style>
