<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      <template v-if="!Object.keys(consumptionRate).length">Добавить норму расходования материала</template>
      <template v-else>Редактирование нормы расходования материала</template>
      <span @click="$modal.hide('consumption-rate-form')"
            class="base-popup__close">
        <icon-close/>
      </span>
    </div>
    <div class="popup-form-group">
      <div class="form-item">
        <div class="form-item__name">Расходный материал</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.consumable_id"
            :options="consumablesList"
            :reduce="consumable => consumable.id"
            :get-option-label="(option) => option.name"
            class="vue-select-input"
            placeholder="Выберите из списка"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Количество</div>
        <div class="form-item__value">
          <input
            v-model="payload.amount"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите количество"
            required
          >
        </div>
      </div>
    </div>
    <div class="base-popup__footer">
      <div @click="checkFormType()"
           class="button">
        Добавить
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from "@/components/Icons/IconClose.vue";

export default {
  name: "WorkConsumptionRatesForm",
  components: {IconClose},
  props: {
    consumptionRate: {
      type: Object,
      required: true
    },
    consumablesList: {
      type: Array,
      required: true
    },
    createConsumptionRate: {
      type: Function,
      default: () => {}
    },
    updateConsumptionRate: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      payload: {
        consumable_id: null,
        amount: null
      }
    }
  },
  mounted() {
    if (Object.keys(this.consumptionRate).length) {
      const {consumable, amount} = this.consumptionRate;
      this.payload.consumable_id = consumable.id;
      this.payload.amount = amount;
    }
  },
  methods: {
    checkFormType() {
      if (Object.keys(this.consumptionRate).length) this.updateConsumptionRate(this.consumptionRate.id, this.payload)
      else this.createConsumptionRate(this.payload)
    }
  }
}
</script>

<style scoped>

</style>
