<template>
  <div class="appeals-filter">
    <div class="filter__button tasks-filter__button"
         :class="{'opened': isFilterModalVisible}"
         @click="isFilterModalVisible = true">
      <icon-settings2/>
      <span>Фильтры</span>
    </div>

    <base-filter-modal
      :is-visible="isFilterModalVisible"
      @toggle-modal="isVisible => this.isFilterModalVisible = isVisible">
      <div class="filter__modal-header">
        <div class="filter__modal-name">Фильтры</div>
        <div @click="resetFilters()"
             class="filter__modal-reset">Сбросить все
        </div>
      </div>
      <div class="filter__modal-form">
        <div>
          <label class="input-label">Категория</label>
          <div class="tags-select">
            <div v-for="group in categoryGroups"
                 :key="group.id"
                 @click="groupId = group.id"
                 class="tags-select__item"
                 :class="{'selected': group.id === groupId}">
              {{group.name}}
            </div>
          </div>
        </div>
        <div v-if="groupId">
          <label class="input-label">Проблема</label>
          <v-select
            class="vue-select-input"
            v-model="payload.category_id"
            :options="categoriesByGroupId(groupId)"
            :reduce="category => category.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите из списка"
          />
        </div>
      </div>
      <div class="filter__modal-buttons">
        <div @click="submitFilter()"
             class="button">Применить
        </div>
        <div @click="isFilterModalVisible = false"
             class="button button--mode-outline">Закрыть
        </div>
      </div>
    </base-filter-modal>
  </div>
</template>

<script>
import IconSettings2 from "@/components/Icons/IconSettings2";
import BaseFilterModal from "@/components/BaseFilterModal";
import {mapGetters, mapState} from "vuex";
export default {
  name: "AppealsListFilter",
  components: {BaseFilterModal, IconSettings2},
  data() {
    return {
      isFilterModalVisible: false,
      groupId: null,
      payload: {
        category_id: null
      }
    }
  },
  computed: {
    ...mapState({
      appealsPage: state => state.appeals.page
    }),
    ...mapGetters({
      categoriesByGroupId: 'serviceDeskCategories/getCategoriesByGroupId',
      categoryGroups: 'serviceDeskCategories/getGroupsContainingCategories'
    })
  },
  mounted() {
    this.$store.dispatch('serviceDeskCategories/getCategoryGroups');
    this.$store.dispatch('serviceDeskCategories/getAllCategories');
  },
  methods: {
    resetFilters() {
      this.groupId = null;
      this.payload.category_id = null;
      this.$store.dispatch("appeals/getAppeals", this.appealsPage);
    },
    submitFilter() {
      this.$store.commit('appeals/SET_FILTER_PARAMS', this.payload);
      this.$store.dispatch("appeals/getAppeals", 1)
          .then(() => {
            this.isFilterModalVisible = false;
          })
    }
  }
}
</script>

<style scoped>
.appeals-filter {
  position: relative;
}
</style>
