<template>
  <form class="daily-report-form">
    <div class="daily-report-form__left">
      <base-date-range-picker @updateDateRange="setDateRange($event)" />
      <date-picker
        class="base-date-picker"
        placeholder="Выберите период"
        v-model="reportDate"
        :default-value="defaultValue"
        format="DD.MM.YYYY"
        type="day"
        range
        value-type="YYYY-MM-DD"
        @input="getReportData"/>
    </div>
    <button @click.prevent="getExcel(tableRows)"
            class="button button--mode-outline"
            :disabled="!reportDataLoaded">
      <icon-export/>
      <span>Экспорт</span>
    </button>
  </form>
</template>

<script>
import DatePicker from "vue2-datepicker";
import {mapState} from "vuex";
import BaseDateRangePicker from "@/components/BaseDateRangePicker.vue";
import IconExport from "@/components/Icons/IconExport.vue";
import {getExcel} from "@/utils/table";

export default {
  name: "DailyReportForm",
  components: {IconExport, BaseDateRangePicker, DatePicker},
  props: {
    tableRows: {
      required: true
    }
  },
  data() {
    return {
      defaultValue: this.$moment().format('YYYY-MM-DD')
    }
  },
  computed: {
    ...mapState({
      reportParams: state => state.reportsData.reportParams,
      reportData: state => state.reportsData.reportData,
      loading: state => state.reportsData.loading
    }),
    reportDataLoaded() {
      return !!this.reportData?.length && !this.loading;
    },
    reportDate: {
      get() {
        const {date_start, date_end} = this.reportParams;
        return [date_start, date_end]
      },
      set(value) {
        const [startDate, endDate] = value;
        this.$store.commit('reportsData/SET_REPORT_PARAM', {
          key: 'date_start',
          value: startDate
        });
        this.$store.commit('reportsData/SET_REPORT_PARAM', {
          key: 'date_end',
          value: endDate
        });
      }
    }
  },
  mounted() {
    const reportParams = {date_start: this.defaultValue, date_end: this.defaultValue};
    this.$store.commit('reportsData/SET_REPORT_PARAMS', reportParams);
    this.getReportData();
  },
  methods: {
    getExcel,
    getReportData() {
      this.$store.dispatch('reportsData/getDailyReportData', this.reportParams);
    },
    setDateRange(range) {
      const reportParams = {date_start: range.start, date_end: range.end};
      this.$store.commit('reportsData/SET_REPORT_PARAMS', reportParams);
      this.getReportData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/viriables";

.daily-report-form {
  display: grid;
  grid-template-columns: 870px 144px;
  gap: 20px;
  width: 100%;
  margin-bottom: 32px;
  justify-content: space-between;

  .base-date-range-picker {
    justify-self: flex-start;
  }

  &__left {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    justify-content: center;

    @media (max-width: $tablet) {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: $tablet) {
    grid-template-columns: 1fr;
  }
}
</style>
