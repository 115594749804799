<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      Редактирование зоны
      <span @click="$modal.hide('zone-form')"
            class="base-popup__close">
        <icon-close/>
      </span>
    </div>
    <form
      @submit.prevent="updateZone(zone.id, payload)"
      class="popup-form-group">
      <div class="form-item">
        <div class="form-item__name">Наименование</div>
        <div class="form-item__value">
          <input
            id="zone-name"
            v-model="payload.name"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите наименование"
          >
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Sys</div>
        <div class="form-item__value">
          <input
            id="zone-sys"
            v-model="payload.sys_id"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите наименование"
          >
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Тип зоны</div>
        <div class="form-item__value">
          <v-select
            id="zone-type"
            v-model="payload.type"
            :options="zoneTypes"
            :reduce="type => type.id"
            :get-option-label="(option) => option.name"
            class="vue-select-input"
            placeholder="Выберите из списка"
          />
        </div>
      </div>
      <div class="form-item">
        <label class="base-switcher">
          <input
            id="zone-active"
            v-model="payload.active"
            class="base-switcher__checkbox"
            type="checkbox">
          <div class="base-switcher__switch"/>
          <span class="base-switcher__label">
            <div class="base-switcher__label--icon">
              Активна
            </div>
          </span>
        </label>
      </div>
    </form>
    <div class="base-popup__footer">
      <button
        type="submit"
        class="button">
        Сохранить
      </button>
    </div>
  </div>
</template>

<script>
import IconClose from "@/components/Icons/IconClose.vue";

export default {
  name: "ZonesForm",
  components: {IconClose},
  props: {
    zone: {
      type: Object,
      default: () => {}
    },
    updateZone: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      payload: {
        name: null,
        sys_id: null,
        type: null,
        active: 1
      },
      zoneTypes: [
        {id: 1, name: 'Водоснабжение'},
        {id: 2, name: 'Водоотведение'}
      ]
    }
  },
  mounted() {
    const {name, sys, type, active} = this.zone;
    this.payload.name = name;
    this.payload.sys_id = sys;
    this.payload.type = type.id;
    this.payload.active = active;
  }
}
</script>

<style scoped>

</style>