<template>
  <div class="page-wrapper">
    <base-panel
      title="Отчет по расходам и потерям воды"
      :is-add-button-visible="false"/>
    <water-flow-report-form :table-rows="tableRows" />
    <base-content-loaders v-if="loading" />
    <div v-if="!isReportData && !loading"
         class="no-result">
      <img src="@/assets/img/no-result-2.png"
           alt="не сформирован отчёт">
      <div class="no-result__text">
        Сформируйте запрос, чтобы получить данные
      </div>
    </div>
    <div v-if="isReportData && !loading"
         id="water-flow-table"
         class="base-table">
      <div class="base-table__container">
        <div
          :class="['base-table__header', 'base-table__header--water-flow']">
          <div
            class="base-table__header-cell"
          >
            Название
          </div>
          <div
            class="base-table__header-cell"
          >
            Значение, м³
          </div>
        </div>
        <div
          :class="['base-table__row', 'base-table__row--water-flow']">
          <div class="base-table__sub-row base-table__sub-row--water-flow">
            <div class="base-table__column base-table__column--water-flow">
              <div class="base-table__text">
                Потеря воды
              </div>
            </div>
            <div class="base-table__column base-table__column--water-flow">
              <div class="base-table__text">
                {{ reportData.loss_volume.summary_volume }}
              </div>
            </div>
          </div>
          <div v-for="(item, index) in reportData.loss_volume.details"
               :key="`costs-${index}`"
               class="base-table__sub-row base-table__sub-row--water-flow">
            <div class="base-table__column base-table__column--water-flow">
              <div class="base-table__text--padding">
                - {{item.name}}
              </div>
            </div>
            <div class="base-table__column base-table__column--water-flow">
              <div class="base-table__text">
                {{item.volume}}
              </div>
            </div>
          </div>
        </div>
        <div
          :class="['base-table__row', 'base-table__row--water-flow']">
          <div class="base-table__sub-row base-table__sub-row--water-flow">
            <div class="base-table__column base-table__column--water-flow">
              <div class="base-table__text">
                Списания воды
              </div>
            </div>
            <div class="base-table__column base-table__column--water-flow">
              <div class="base-table__text">
                {{ reportData.own_costs_volume.summary_volume }}
              </div>
            </div>
          </div>
          <div v-for="(item, index) in reportData.own_costs_volume.details"
               :key="`costs-${index}`"
               class="base-table__sub-row base-table__sub-row--water-flow">
            <div class="base-table__column base-table__column--water-flow">
              <div class="base-table__text--padding">
                - {{item.name}}
              </div>
            </div>
            <div class="base-table__column base-table__column--water-flow">
              <div class="base-table__text">
                {{item.volume}}
              </div>
            </div>
          </div>
        </div>
        <div
          :class="['base-table__row', 'base-table__row--water-flow']">
          <div class="base-table__sub-row base-table__sub-row--water-flow">
            <div class="base-table__column base-table__column--water-flow">
              <div class="base-table__text">
                Реализованная вода
              </div>
            </div>
            <div class="base-table__column base-table__column--water-flow">
              <div class="base-table__text">
                {{ reportData.realization_volume.summary_volume }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel.vue";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import {mapState} from "vuex";
import WaterFlowReportForm from "@/components/ServiceDesk/ReportsForms/WaterFlowReportForm.vue";

export default {
  name: "WaterFlowReportPage",
  components: {WaterFlowReportForm, BaseContentLoaders, BasePanel},
  computed: {
    ...mapState({
      reportData: state => state.reportsData.reportData,
      loading: state => state.reportsData.loading
    }),
    isReportData() {
      return !!(this.reportData?.loss_volume?.summary_volume || this.reportData?.own_costs_volume?.summary_volume ||
      this.reportData?.realization_volume?.summary_volume)
    },
    tableRows() {
      const rows = [];
      if (this.isReportData.length) {
        rows.push(['Название','Значение, м³']);
        rows.push(['Потеря воды', this.reportData.loss_volume.summary_volume]);
        for (let item of this.reportData.loss_volume.details) {
          rows.push([`- ${item.name}`, item.volume]);
        }
        rows.push([]);
        rows.push(['Списания воды', this.reportData.own_costs_volume.summary_volume]);
        for (let item of this.reportData.own_costs_volume.details) {
          rows.push([`- ${item.name}`, item.volume]);
        }
        rows.push([]);
        rows.push(['Реализованная воды', this.reportData.realization_volume.summary_volume]);
      }
      return rows;
    }
  },
  created() {
    this.$store.commit('reportsData/SET_REPORT_DATA', []);
  }
}
</script>

<style lang="scss" scoped>

</style>
