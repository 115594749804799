import * as API from './API'
import Category from "@/models/serviceDesk/Category";
import {getError} from "@/utils/helpers";
import CategoryGroup from "@/models/serviceDesk/CategoryGroup";

export default {
    fetchCategories(page = 1) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/categories?page=${page}`)
                .then(response => resolve({
                    categories: response.data.data.map(item => new Category(item.id, item.attributes)),
                    meta: response.data.meta
                }))
                .catch(error => reject(getError(error)));
        })
    },
    fetchAllCategories() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/categories/all`)
                .then(response => resolve(response.data.data.map(item => new Category(item.id, item.attributes))))
                .catch(error => reject(getError(error)));
        })
    },
    fetchCategoryGroups() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/categories/groups`)
                .then(response => resolve(response.data.data.map(item => new CategoryGroup({
                    id: item.id, ...item.attributes
                }))))
                .catch(error => reject(getError(error)));
        })
    },
    createCategory(payload) {
        return API.apiClient.post(`service-desk/categories`, payload);
    },
    updateCategory(id, payload) {
        return API.apiClient.patch(`service-desk/categories/${id}`, payload);
    },
    deleteCategory(id) {
        return API.apiClient.delete(`service-desk/categories/${id}`);
    }
}