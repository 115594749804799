<template>
  <div class="additional-option-wrapper"
       :class="{'always-open': isCollapseVisible}">
    <div class="additional-option">
      <div class="additional-option__icon">
        <icon-task-square/>
      </div>
      <div class="additional-option__name"><span>Подписки</span>
        <span class="additional-option__quantity">{{ subscriptionsList.length }}</span>
      </div>
      <div class="additional-option__buttons">
        <button
          class="additional-option__add"
          type="button"
          @click="showAddSubscriptionPopup()">
          <icon-plus/>
        </button>
        <button
          @click="isCollapseVisible = !isCollapseVisible"
          class="additional-option__toggle"
          :class="{'rotate': isCollapseVisible}">
          <icon-arrow-double-up/>
        </button>
      </div>
    </div>
    <div class="additional-options-list">
      <div class="additional-options-list__inner">
        <div v-for="subscription in subscriptionsList"
             :key="subscription.id"
             class="added-item added-item--flex added-item--cur-pointer">
          <div class="added-item__info">
            <div class="added-item__name">{{ subscription.typeName }}</div>
          </div>
          <div class="added-item__right">
            <div @click.stop="deleteSubscription(subscription.id)">
              <icon-trash class="svg added-item__tool"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconPlus from "@/components/Icons/IconPlus.vue";
import IconTrash from "@/components/Icons/IconTrash.vue";
import IconTaskSquare from "@/components/Icons/IconTaskSquare.vue";
import IconArrowDoubleUp from "@/components/Icons/IconArrowDoubleUp.vue";
import UserSubscriptionsForm from "@/components/admin/User/UserSubscriptionsForm.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import {showToast, showAlert, showConfirmationModal} from "@/utils/notification";

export default {
  name: "UserSubscriptionsList",
  components: {IconArrowDoubleUp, IconTaskSquare, IconTrash, IconPlus},
  props: {
    subscriptionsList: {
      type: Array,
      required: true
    },
    userId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isCollapseVisible: true
    }
  },
  methods: {
    showAddSubscriptionPopup() {
      this.$modal.show(
        UserSubscriptionsForm,
        {
          'popupName': 'user-add-subscription-form'
        },
        {
          name: 'user-add-subscription-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        })
    },
    deleteSubscription(subscriptionId) {
      showConfirmationModal('Вы уверены, что хотите удалить подписку? Это действие невозвратно.')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('userSubscriptions/deleteSubscription', subscriptionId)
              .then(() => {
                this.$store.dispatch('user/getUserSubscriptions', this.userId);
                showToast('Подписка удалена', 'success')
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style scoped>

</style>