<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="24"
       height="24"
       viewBox="0 0 24 24"
       fill="none">
    <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22ZM16.5299
           10.0308C16.823 9.73816 16.8234 9.26329 16.5308 8.97014C16.2382 8.67699 15.7633 8.67657 15.4701 8.9692L10.664
           13.7669L8.53033 11.6332C8.23744 11.3403 7.76256 11.3403 7.46967 11.6332C7.17678 11.9261 7.17678 12.401
            7.46967 12.6939L10.1332 15.3574C10.4259 15.6501 10.9004 15.6503 11.1934 15.3579L16.5299 10.0308Z"
          fill="#27D8AD"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCheckInRound'
}
</script>

<style lang="scss" scoped>

</style>