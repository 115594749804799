<script>
export default {
  name: "IconWaterTower"
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none">
    <g clip-path="url(#clip0_1054_36600)">
      <path d="M5.66016 6.97266H18.338V16.0652H5.66016V6.97266Z"
            fill="#468FC5"/>
      <path
        d="M13.5744 12.2906C13.5791 12.4973 13.5418 12.7029 13.4649 12.8951C13.3879 13.0875 13.2728 13.2627 13.1262
        13.4105C12.9797 13.5583 12.8047 13.6757 12.6115 13.756C12.4183 13.8361 12.2108 13.8774 12.0013 13.8774C11.7917
         13.8774 11.5842 13.8361 11.391 13.756C11.1978 13.6757 11.0228 13.5583 10.8763 13.4105C10.7297 13.2627 10.6146
         13.0875 10.5377 12.8951C10.4607 12.7029 10.4235 12.4973 10.4281 12.2906C10.4273 11.4335 12.0009 9.19531 12.0009
          9.19531C12.0009 9.19531 13.5744 11.4335 13.5744 12.2906Z"
        fill="white"/>
      <path
        d="M19.2183 5.42969H4.78168C4.34997 5.42969 4 5.77491 4 6.20077C4 6.62662 4.34997 6.97185 4.78168
        6.97185H19.2183C19.65 6.97185 20 6.62662 20 6.20077C20 5.77491 19.65 5.42969 19.2183 5.42969Z"
        fill="#A4D9F6"/>
      <path
        d="M19.2183 16.0645H4.78168C4.34997 16.0645 4 16.4098 4 16.8356C4 17.2615 4.34997 17.6067 4.78168
        17.6067H19.2183C19.65 17.6067 20 17.2615 20 16.8356C20 16.4098 19.65 16.0645 19.2183 16.0645Z"
        fill="#A4D9F6"/>
      <path d="M12.4707 0H11.5274L5.66016 4.06535V5.42979H18.338V4.08675L12.4707 0Z"
            fill="#262F3D"/>
      <path d="M6.07419 24.0006H4L5.7834 17.6074H7.85739L6.07419 24.0006Z"
            fill="#468FC5"/>
      <path d="M17.9258 24.0006H19.9998L18.2166 17.6074H16.1426L17.9258 24.0006Z"
            fill="#468FC5"/>
      <path d="M16.8346 20.0859H7.16645L6.73633 21.6281H17.2647L16.8346 20.0859Z"
            fill="#468FC5"/>
    </g>
    <defs>
      <clipPath id="clip0_1054_36600">
        <rect width="24"
              height="24"
              fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped>

</style>