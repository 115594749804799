import DetectedProblemsService from "@/services/DetectedProblemsService";

export const namespaced = true;

export const actions = {
    createDetectedProblem(context, {taskId, payload}) {
        return new Promise((resolve, reject) => {
            DetectedProblemsService.createDetectedProblem(taskId, payload)
                .then(detectedProblem => resolve(detectedProblem.id))
                .catch(error => reject(error))
        })
    },
    updateDetectedProblem(context, {taskId, detectedProblemId, payload}) {
        return new Promise((resolve, reject) => {
            DetectedProblemsService.updateDetectedProblem(taskId, detectedProblemId, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    deleteDetectedProblem(context, {taskId, detectedProblemId}) {
        return new Promise((resolve, reject) => {
            DetectedProblemsService.deleteDetectedProblem(taskId, detectedProblemId)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    addDetectedProblemAttachments(context, {taskId, detectedProblemId, payload}) {
        return new Promise((resolve, reject) => {
            DetectedProblemsService.addDetectedProblemAttachments(taskId, detectedProblemId, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    removeDetectedProblemAttachment(context, {taskId, detectedProblemId, attachmentId}) {
        return new Promise((resolve, reject) => {
            DetectedProblemsService.removeDetectedProblemAttachment(taskId, detectedProblemId, attachmentId)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    }
}