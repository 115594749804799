import NetworkEquipmentsService from "@/services/NetworkEquipmentsService";
import NetworkEquipmentParametersService from "@/services/NetworkEquipmentParametersService";
import NetworkObjectsService from "@/services/NetworkObjectsService";
import {isValueOutOfProfile} from "@/utils/helpers";

function setLocalStorageItem(ls_key, id) {
    let ls = JSON.parse(localStorage.getItem(ls_key))
    if (ls.includes(id)) {
        let index = ls.indexOf(id)
        ls.splice(index, 1)
    } else {
        ls.push(id)
    }
    localStorage.setItem(ls_key, JSON.stringify(ls))
}

export const namespaced = true;

export const state = {
    networkObjects: [],
    networkEquipments: [],
    parametersValues: [],
    currentProfiles: [],
    filterParams: null,
    loading: false,
    error: null
}

export const mutations = {
    SET_NETWORK_OBJECTS(state, networkObjects) {
        state.networkObjects = networkObjects;
    },
    SET_NETWORK_EQUIPMENTS(state, equipments) {
        state.networkEquipments = equipments;
    },
    SET_PARAMETERS_VALUES(state, values) {
        state.parametersValues = values;
    },
    SET_CURRENT_PROFILES(state, profiles) {
        state.currentProfiles = profiles;
    },
    SET_FILTER_PARAMS(state, filterParams) {
        state.filterParams = filterParams;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    TOGGLE_NETWORK_OBJECT_ON_MAP(state, id) {
        setLocalStorageItem('hiddenOnMap', id)

        let networkObject = state.networkObjects.find(networkObject => networkObject.id === id);
        networkObject.showOnMap = !networkObject.showOnMap
    },
    TOGGLE_NETWORK_OBJECT_FIX_MAP(state, id) {
        setLocalStorageItem('fixedOnMap', id)

        let networkObject = state.networkObjects.find(networkObject => networkObject.id === id);
        networkObject.fixOnMap = !networkObject.fixOnMap
    },
    TOGGLE_NETWORK_OBJECT_IN_LIST(state, id) {
        setLocalStorageItem('hiddenInList', id);

        let networkObject = state.networkObjects.find(networkObject => networkObject.id === id);
        networkObject.showInList = !networkObject.showInList;
    }
}

export const actions = {
    getNetworkObjectsTelemetry({commit}) {
        commit("SET_LOADING", true);
        NetworkObjectsService.fetchTelemetryNetworkObjects()
            .then(networkObjects => commit("SET_NETWORK_OBJECTS", networkObjects))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false))
    },
    getNetworkObjects({commit, state}) {
        commit("SET_LOADING", true);
        NetworkObjectsService.getAllNetworkObjects(state.filterParams)
            .then(networkObjects => commit("SET_NETWORK_OBJECTS", networkObjects))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false))
    },
    getNetworkEquipments({commit}) {
        NetworkEquipmentsService.fetchNetworkEquipments()
            .then(equipments => commit("SET_NETWORK_EQUIPMENTS", equipments))
            .catch(error => commit("SET_ERROR", error));
    },
    getScadaNetworkEquipments({commit}) {
        NetworkEquipmentsService.fetchScadaEquipments()
            .then(equipments => commit("SET_NETWORK_EQUIPMENTS", equipments))
            .catch(error => commit("SET_ERROR", error))
    },
    getParametersValues({commit}) {
        NetworkEquipmentParametersService.fetchLatestParametersValues()
            .then(({parametersValues, profiles}) => {
                commit("SET_PARAMETERS_VALUES", parametersValues);
                commit("SET_CURRENT_PROFILES", profiles);
            })
            .catch(error => commit("SET_ERROR", error))
    }
}

export const getters = {
    getParameterProfile: state => parameterId => {
        const profile = state.currentProfiles.find(profile => profile.parameter_id === parameterId);
        return profile ? profile : null
    },
    getNetworkObjectsByType: state => type => {
        if (type === 0) return state.networkObjects
        else return state.networkObjects.filter(networkObject => networkObject.type === type)
    },
    getNetworkObject: state => id => {
        const networkObject = state.networkObjects.find(object => object.id === id);
        return networkObject ? networkObject : {};
    },
    getNetworkEquipmentByNetworkObjectId: state => networkObjectId => {
        return state.networkEquipments.filter(equipment => equipment.networkObjectId === networkObjectId);
    },
    getParameter: state => (parameterId, equipmentId) => {
        const equipment = state.networkEquipments.find(equipment => equipment.id === equipmentId);
        return equipment.parameters.find(parameter => parameter.id === parameterId);
    },
    getParameterValue: state => parameterId => {
        const parameterValue = state.parametersValues.find(value => value.parameterId === parameterId);
        return parameterValue ? parameterValue : {}
    },
    getParameterDangerStatus: (state, getters, rootState) => parameter => {
        if (rootState.scadaPage.parameterNames.includes(parameter.shortName)) {
            const parameterValue = getters.getParameterValue(parameter.id);
            return isValueOutOfProfile(parameterValue.value, getters.getParameterProfile(parameter.id))
        } else return false
    },
    getNetworkObjectDangerStatus: (state, getters) => networkObjectId => {
        const networkEquipments = getters.getNetworkEquipmentByNetworkObjectId(networkObjectId);
        return networkEquipments
            .some(equipment => equipment.parameters.some(parameter => getters.getParameterDangerStatus(parameter)))
    },
    getNetworkObjectsContainEquipments: (state, getters) => {
        let objects = state.networkObjects
            .filter(object => getters.getNetworkEquipmentByNetworkObjectId(object.id).length);

        objects = getters.filterObjectByStructuralUnits(objects);
        objects = getters.filterObjectByDangerStatus(objects);
        objects = getters.filterObjectByName(objects);
        objects = objects.sort((a, b) => a.orderNumber - b.orderNumber)

        return objects;
    },
    filterObjectByStructuralUnits: (state, getters, rootState) => objects => {
        return rootState.scadaPage.filterByStructuralUnitIds.length ?
            objects.filter(object => object.structuralUnits
                .some(unit => rootState.scadaPage.filterByStructuralUnitIds.includes(unit.id))) :
            objects
    },
    filterObjectByDangerStatus: (state, getters, rootState) => objects => {
        return rootState.scadaPage.filterEquipmentByDangerStatus ? objects
            .filter(object => getters.getNetworkObjectDangerStatus(object.id)) : objects
    },
    filterObjectByName: (state, getters, rootState) => objects => {
        return rootState.scadaPage.filterByNameQuery.length ? objects
            .filter(object => object.name.toLowerCase().includes(rootState.scadaPage.filterByNameQuery.toLowerCase())) :
            objects
    },
    getNetworkEquipmentsWithParameterValues: (state, getters, rootState) => networkObjectId => {
        let networkEquipments = [];
        if (rootState.scadaPage.filterEquipmentByDangerStatus) {
            networkEquipments = getters.getNetworkEquipmentByNetworkObjectId(networkObjectId)
                .filter(equipment => {
                    return equipment.parameters.some(parameter => getters.getParameterDangerStatus(parameter))
                })
        } else {
            networkEquipments = getters.getNetworkEquipmentByNetworkObjectId(networkObjectId);
        }
        return networkEquipments.map(equipment => {
            const {parameters, ...rest} = equipment;
            return {
                parameters: parameters.map(parameter => {
                    return {
                        ...parameter,
                        parameterValue: getters.getParameterValue(parameter.id)
                    }
                }),
                ...rest
            }
        })
    },
    getNetworkObjectParametersSumByName: (state, getters) => (objectId, parameterName) => {
        const equipments = getters.getNetworkEquipmentsWithParameterValues(objectId);
        return equipments.length ? equipments.reduce((accum, equipment) => {
            const parameter =  equipment.parameters.find(parameter => parameter.shortName === parameterName);
            return accum + (parameter?.parameterValue.value ? parameter.parameterValue.value : 0);
        }, 0) : 0;
    },
    getEquipmentsParametersSumByName: (state, getters) => parameterName => {
        const objects = getters.getNetworkObjectsContainEquipments;
        return objects.length ? objects.reduce((accum, object) => {
            const equipmentParametersSum = getters.getNetworkObjectParametersSumByName(object.id, parameterName);
            return accum + equipmentParametersSum;
        }, 0) : 0
    }
}