<template>
  <div class="resource-load"
       :class="{'resource-load--align-reverse': isReversedAlign}">
    <div class="resource-load__tasks-count"
         :class="{'resource-load__tasks-count--text-right': isReversedAlign}">
      {{ getResourceTasksLength }}
    </div>
    <div class="base-table__load">
      <div v-for="count in getResourceLoad"
           :key="`tasks_${count}`"
           class="base-table__load-item"
           :class="[
             {'base-table__load-item--success': getResourceLoad <= 2},
             {'base-table__load-item--warning': getResourceLoad >2
               && getResourceLoad <= 5},
             {'base-table__load-item--error': getResourceLoad >5}
           ]"/>
      <div v-for="count in 7 - getResourceLoad"
           :key="`indicators_${count}`"
           class="base-table__load-item"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResourceLoadIndicator",
  props: {
    resourceTasksList: {
      type: Array,
      required: true
    },
    isReversedAlign: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getResourceTasksLength() {
      return this.resourceTasksList.length;
    },
    getResourceLoad() {
      const tasksCount = this.getResourceTasksLength;
      if (tasksCount >= 7) return 7
      else return tasksCount
    }
  }
}
</script>

<style lang="scss" scoped>
.resource-load {
  display: flex;
  align-items: center;

  &--align-reverse {
    flex-direction: row-reverse;
  }

  &__tasks-count {
    width: 30px;

    &--text-right {
      text-align: right;
    }
  }
}
</style>
