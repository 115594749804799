import NetworkObject from "@/models/telemetry/NetworkObject";
import Sensor from "@/models/telemetry/Sensor";

export default class Device {
    constructor(params = {}) {
        this.id = params.id;
        this.name = params.name;
        this.deviceName = params.device_name;
        this.parentId = params.parent_id || null;
        this.dataSource = params.data_source || null;
        this.active = params.active;
        this.networkObject = params.network_object ? new NetworkObject(params.network_object) : null;
        this.sensors = params.sensors ? params.sensors.map(sensor => new Sensor({
            id: sensor.id,
            ...sensor.attributes
        })) : null;
    }

    static connectionTypesList = [
        {id: 1, name: 'Zulu OPC'},
        {id: 2, name: '1SIM.ru - API'},
        {id: 3, name: 'Ручной ввод'},
        {id: 4, name: 'Взлёт OPC'},
        {id: 5, name: 'Овен OPC'},
        {id: 6, name: 'Мегафон'},
        {id: 8, name: 'ELDIS'}
    ]

    get connectionTypeName() {
        const type = Device.connectionTypesList.find(type => type.id === this.dataSource);
        return type ? type.name : ''
    }

    get stateLabel() {
        return this.active ? 'Активно' : 'Не активно';
    }
}
