<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="18"
       height="20"
       viewBox="0 0 18 20"
       fill="none">
    <path d="M6.67969 12.4297L8.02969 13.7797L11.6297 10.1797"
          stroke="#9192AD"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"/>
    <path
      d="M7.3 4.6H10.9C12.7 4.6 12.7 3.7 12.7 2.8C12.7 1 11.8 1 10.9 1H7.3C6.4 1 5.5 1 5.5 2.8C5.5 4.6 6.4 4.6 7.3 4.6Z"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M12.7 2.82031C15.697 2.98231 17.2 4.08931 17.2 8.20231V13.6023C17.2 17.2023 16.3 19.0023 11.8 19.0023H6.4C1.9
       19.0023 1 17.2023 1 13.6023V8.20231C1 4.09831 2.503 2.98231 5.5 2.82031"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconClipboard"
}
</script>

<style scoped>

</style>
