import HistoryService from "@/services/HistoryService";
import AppealsService from "@/services/AppealsService";

export const namespaced = true;

export const state = {
    appeal: {},
    appealHistory: [],
    loading: false,
    error: {}
}

export const mutations = {
    SET_APPEAL(state, appeal) {
        state.appeal = appeal;
    },
    SET_APPEAL_HISTORY(state, history) {
        state.appealHistory = history;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    async getAppealInfo({commit, dispatch}, appealId) {
        commit("SET_LOADING", true);

        await Promise.all([
            dispatch('getAppeal', appealId),
            dispatch('getAppealHistory', appealId)
        ])
            .finally(() => commit("SET_LOADING", false))
    },
    getAppeal({commit}, appealId) {
        return new Promise((resolve, reject) => {
            AppealsService.getAppeal(appealId)
                .then(appeal => {
                    commit("SET_APPEAL", appeal);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                })
        })
    },
    getAppealHistory({commit}, appealId) {
        return new Promise((resolve, reject) => {
            HistoryService.getAppealHistory(appealId)
                .then(history => {
                    commit("SET_APPEAL_HISTORY", history);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                })
        })
    }
}

export const getters = {
    getHistoryFirstItem: state => {
        return state.appealHistory[0];
    }
}
