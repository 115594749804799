<template>
  <div class="page-wrapper">
    <base-panel
      title="Расход ГСМ"
      :is-add-button-visible="false"/>
    <fuel-and-lubricants-report-form :table-rows="tableRows" />
    <base-content-loaders v-if="loading" />
    <div v-if="!reportData.length && !loading"
         class="no-result">
      <img src="@/assets/img/no-result-2.png"
           alt="не сформирован отчёт">
      <div class="no-result__text">
        Сформируйте запрос, чтобы получить данные
      </div>
    </div>
    <div v-if="reportData.length && !loading"
         id="fuel-and-lubricants-table"
         class="base-table">
      <div class="base-table__container">
        <div
          :class="['base-table__header', 'base-table__header--fuel-and-lubricants']">
          <div
            class="base-table__header-cell"
          >
            Заявка
          </div>
          <div
            class="base-table__header-cell"
          >
            Топливо
          </div>
          <div
            class="base-table__header-cell"
          >
            Масло
          </div>
        </div>
        <div v-for="data in reportData"
             :key="data.issue_id"
             :class="['base-table__row', 'base-table__row--fuel-and-lubricants']">
          <div
            class="base-table__column base-table__column--fuel-and-lubricants">
            <div class="base-table__text--fuel-and-lubricants">
              {{`Заявка #${data.issue_id}`}}
            </div>
          </div>
          <div
            class="base-table__column base-table__column--fuel-and-lubricants">
            <div class="base-table__text-label base-table__text-label--fuel-and-lubricants">
              {{}}
            </div>
            <div class="base-table__text--fuel-and-lubricants">
              {{data.consumptions.fuel[0].type.attributes.name}}
            </div>
          </div>
          <div
            class="base-table__column base-table__column--fuel-and-lubricants">
            <div class="base-table__text-label base-table__text-label--fuel-and-lubricants">
              {{}}
            </div>
            <div class="base-table__text--fuel-and-lubricants">
              {{data.consumptions.fuel[0].amount}} л.
            </div>
          </div>
          <div
            class="base-table__column base-table__column--fuel-and-lubricants">
            <div class="base-table__text-label base-table__text-label--fuel-and-lubricants">
              {{}}
            </div>
            <div class="base-table__text--fuel-and-lubricants">
              {{data.consumptions.oil[0].type.attributes.name}}
            </div>
          </div>
          <div
            class="base-table__column base-table__column--fuel-and-lubricants">
            <div class="base-table__text-label base-table__text-label--fuel-and-lubricants">
              {{}}
            </div>
            <div class="base-table__text--fuel-and-lubricants">
              {{data.consumptions.oil[0].amount}} л.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel.vue";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import {mapState} from "vuex";
import FuelAndLubricantsReportForm from "@/components/ServiceDesk/ReportsForms/FuelAndLubricantsReportForm.vue";

export default {
  name: "FuelAndLubricantsReportPage",
  components: {FuelAndLubricantsReportForm, BaseContentLoaders, BasePanel},
  computed: {
    ...mapState({
      reportData: state => state.reportsData.reportData,
      loading: state => state.reportsData.loading
    }),
    tableRows() {
      const rows = [];
      if (this.reportData.length){
        rows.push(['Заявка', 'Топливо', 'Масло']);
        for (let item of this.reportData) {
          rows.push([`Заявка #${item.issue_id}`, item.consumptions.fuel[0].amount, item.consumptions.oil[0].amount])
        }
      }
      return rows;
    }
  },
  created() {
    this.$store.commit('reportsData/SET_REPORT_DATA', []);
  }
}
</script>

<style lang="scss" scoped>

</style>
