import * as API from './API'
import MechanicalEquipment from "@/models/serviceDesk/MechanicalEquipment";
import {getError} from "@/utils/helpers";
import FuelType from "@/models/serviceDesk/FuelType";
import OilType from "@/models/serviceDesk/OilType";

export default {
    fetchMechanicalEquipments(page = 1) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/mechanical-equipments?page=${page}`)
                .then(response => resolve({
                    equipments: response.data.data.map(item => new MechanicalEquipment(item.id, item.attributes)),
                    meta: response.data.meta
                }))
                .catch(error => reject(getError(error)));
        })
    },
    fetchAllMechanicalEquipments() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/mechanical-equipments/all`)
                .then(response => resolve(response.data.data
                    .map(item => new MechanicalEquipment(item.id, item.attributes))))
                .catch(error => reject(getError(error)));
        })
    },
    createMechanicalEquipment(payload) {
        return API.apiClient.post(`service-desk/mechanical-equipments`, payload);
    },
    updateMechanicalEquipment(id, payload) {
        return API.apiClient.patch(`service-desk/mechanical-equipments/${id}`, payload);
    },
    deleteMechanicalEquipment(id) {
        return API.apiClient.delete(`service-desk/mechanical-equipments/${id}`);
    },
    fetchFuelTypes() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/mechanical-equipments/types/fuel`)
                .then(response => resolve(response.data.data.map(item => new FuelType({
                    id: item.id,
                    ...item.attributes
                }))))
                .catch(error => reject(getError(error)))
        })
    },
    fetchOilTypes() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/mechanical-equipments/types/oil`)
                .then(response => resolve(response.data.data.map(item => new OilType({
                    id: item.id,
                    ...item.attributes
                }))))
                .catch(error => reject(getError(error)))
        })
    }
}