import * as API from "@/services/API";
import Issue from "@/models/serviceDesk/Issue";
import {getError} from "@/utils/helpers";

export default {
    fetchIssuesListItems(filter, page = 1) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/issues`, {params: {page: page, ...filter}})
                .then(response => {
                    resolve({
                            issues: response.data.data.map(issue => {
                            return new Issue(issue.id, issue.attributes)}),
                            meta: response.data.meta,
                            links: response.data.links
                        })
                })
                .catch(error => {
                    reject(getError(error))
                })
        })
    },
    getAllIssues(params) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/issues/all`, {params: params})
                .then(response => {
                    resolve(response.data.data.map(issue => {
                        return new Issue(issue.id, issue.attributes)}))
                })
                .catch(error => reject(getError(error)));
        })
    },
    getIssue(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/issues/${id}`)
                .then(response => {
                    const issue = response.data.data;
                    resolve(new Issue(issue.id, issue.attributes));

                })
                .catch(error => reject(getError(error)))
        })
    },
    createIssue(payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post('service-desk/issues', payload)
                .then(response => resolve(response.data.data.id))
                .catch(error => reject(getError(error)));
        })
    },
    addTaskToIssue(payload) {
        return API.apiClient.post(`service-desk/issues/${payload.issue_id}/tasks`, payload.params);
    },
    cancelIssue(issue_id) {
        return API.apiClient.post(`service-desk/issues/${issue_id}/cancel`);
    },
    closeIssue(issue_id) {
        return API.apiClient.post(`service-desk/issues/${issue_id}/close`);
    },
    postponeIssue(issue_id, payload) {
        return API.apiClient.post(`service-desk/issues/${issue_id}/postpone`, payload);
    },
    planIssue(issue_id, payload) {
        return API.apiClient.post(`service-desk/issues/${issue_id}/planning`, payload);
    },
    changeIssueCategory(issue_id, payload) {
        return API.apiClient.post(`service-desk/issues/${issue_id}/change-category`, payload);
    },
    mergeIssue(issue_id, payload) {
        return API.apiClient.post(`service-desk/issues/${issue_id}/merge`, payload);
    },
    changePlanning(issue_id, payload) {
        return API.apiClient.patch(`/service-desk/issues/${issue_id}/change-planning`, payload);
    },
    updateIssue(issue_id, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/service-desk/issues/${issue_id}`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    }
}
