<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      Расход материалов
      <span @click="$modal.hide('consumptions-form')"
            class="base-popup__close">
        <icon-close />
      </span>
    </div>
    <div class="popup-form-group">
      <div class="consumptions-form">
        <v-select
          v-model="payload.consumable_id"
          :options="consumablesList"
          :reduce="equipment => equipment.id"
          :get-option-label="(option) => option.name"
          placeholder="Выберите из списка"
          class="vue-select-input"
        />

        <input
          v-model="payload.amount"
          class="base-input base-input--bordered"
          type="text"
          name="material"
          placeholder="Количество">
      </div>

    </div>
    <div class="base-popup__footer">
      <div @click="submitForm()"
           class="button">
        Добавить
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from "@/components/Icons/IconClose.vue";
import {showAlert, showToast} from "@/utils/notification";

export default {
  name: "ConsumptionsForm",
  components: {IconClose},
  props: {
    taskId: {
      type: Number,
      required: true
    },
    consumablesList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      payload: {
        consumable_id: null,
        amount: null
      }
    }
  },
  methods: {
    submitForm() {
      this.$store.dispatch('taskConsumptions/createTaskConsumptions', {
        taskId: this.taskId,
        payload: this.payload
      })
        .then(() => {
          this.$store.dispatch('taskModal/getConsumptionsList', this.taskId)
          this.$modal.hide('consumptions-form');
          showToast('Расход материалов добавлен', 'success');
        })
        .catch(error => showAlert('Ошибка', error, 'error'))
    }
  }
}
</script>

<style lang="scss" scoped>
.consumptions-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.consumptions-form {
  display: grid;
  grid-template-columns: 60% 1fr;
  gap: 24px;
  align-items: center;

  &__icon {
    cursor: pointer;

    & svg {
      stroke: var(--ico);
    }
  }
}
</style>
