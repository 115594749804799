<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="24"
       height="24"
       viewBox="0 0 24 24"
       fill="none">
    <path d="M14.9922 17.9922L9.00918 11.9922L14.9922 5.99219"
          stroke="#9192AD"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconArrowLeft"
}
</script>

<style scoped>

</style>
