<template>
  <div class="page-wrapper">
    <base-panel
      title="Суточный отчет ЕДС"
      :is-add-button-visible="false"/>
    <daily-report-form :table-rows="tableRows" />
    <base-content-loaders v-if="loading" />
    <div v-if="!reportData.length && !loading"
         class="no-result">
      <img src="@/assets/img/no-result-2.png"
           alt="не сформирован отчёт">
      <div class="no-result__text">
        Сформируйте запрос, чтобы получить данные
      </div>
    </div>

    <div v-for="categoryGroup in categoryGroups"
         :key="categoryGroup.id"
         class="mb-3">
      <div v-if="issuesByCategoryGroup(categoryGroup.id).length && !loading">

        <div v-for="type in issueTypes"
             :key="type.id">
          <div v-if="issuesByCategoryGroup(type.id).filter(item => {
            return item.type_id ? item.type_id === type.id : false
          }).length">
            <div class="type-title mb-4">
              {{ categoryGroup.name }} - {{ type.name }}
            </div>
            <daily-report-table
              :issues="issuesByCategoryGroup(type.id).filter(item => {
                return item.type_id ? item.type_id === type.id : false
              })"/>
          </div>
        </div>

        <div v-if="issuesWithoutCategory.length">
          <div v-for="type in issueTypes"
               :key="type.id">
            <div v-if="issuesWithoutCategory.filter(item => {
              return item.type_id ? item.type_id === type.id : false
            }).length">
              <div class="type-title mb-4">
                Другое - {{ type.name }}
              </div>

              <daily-report-table
                :issues="issuesWithoutCategory.filter(item => {
                  return item.type_id ? item.type_id === type.id : false
                })"/>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import BasePanel from "@/components/BasePanel.vue";
import {mapGetters, mapState} from "vuex";
import DailyReportForm from "@/components/ServiceDesk/ReportsForms/DailyReportForm.vue";
import DailyReportTable from "@/components/ServiceDesk/ReportsPreviews/DailyReportTable.vue";
import {formatDateTime} from "@/utils/filters";

export default {
  name: 'DailyReportPage',
  components: {DailyReportTable, DailyReportForm, BasePanel, BaseContentLoaders},
  computed: {
    ...mapState({
      categoryGroups: state => state.serviceDeskCategories.categoryGroups,
      reportData: state => state.reportsData.reportData,
      loading: state => state.reportsData.loading,
      issueTypes: state => state.issueTypes.types
    }),
    ...mapGetters({
      issuesByCategoryGroup: 'reportsData/getIssuesByCategoryGroup',
      issuesWithoutCategory: 'reportsData/getIssuesWithoutCategory'
    }),
    tableRows() {
      const rows = [];
     if (this.reportData.length) {
        rows.push(['#', 'Открыта', 'Закрыта', 'Адрес',
          'Отключения', 'Подразделения', 'Задания']);
        for (let i = 0; i < this.reportData.length; i++) {
          rows.push([this.reportData[i].id, formatDateTime(this.reportData[i].created_at),
            formatDateTime(this.reportData[i].created_at), this.reportData[i].getFullAddress()]);

          if (this.reportData[i].shutdowns.length) {
            let shutdownData = ''
            for (let shutdown of this.reportData[i].shutdowns) {
              if (shutdown.actual_start_date) {
                shutdownData = shutdownData + ` Факт. дата начала:
               ${formatDateTime(shutdown.actual_start_date)}`;
              }
              if (shutdown.actual_end_date) {
                shutdownData = shutdownData + ` Факт. дата окончания:
               ${formatDateTime(shutdown.actual_start_date)}`;
              }
              shutdownData = shutdownData + ' ' + shutdown.comment;
            }
            rows[i + 1].push(shutdownData)
          } else {
            rows[i + 1].push('-')
          }

          if (this.reportData[i].tasks.length) {
            const tasks = (this.reportData[i].tasks[0].department ? this.reportData[i].tasks[0].department.name :
                    '-') +
                this.reportData[i].tasks[0].brigade_name
            rows[i + 1].push(tasks);
          }

          let label = '';
          let start = '';
          let end = '';
          if (this.reportData[i].tasks.length) {
            label = this.reportData[i].tasks[0].type_label;
            start = formatDateTime(this.reportData[i].tasks[0].actual_start_date);
            end = formatDateTime(this.reportData[i].tasks[0].actual_end_date);
          }
          let works = '';
          if (this.reportData[i].tasks[0].works.length) {
            for (let element of this.reportData[i].emergencyElements) {
              if (this.reportData[i].tasks[0].works.filter(work => {
                return work.element ? work.element.id === element.id : false
              })) {
                works += element.element_type.name + ',';
                for (let feature of element.features) {
                  works += `${feature.feature.name} : ${feature.value} ${feature.unit}`
                }
                works += element.damageName;
              }
              for (let work of this.reportData[i].tasks[0].works.filter(work => {
                return work.element ? work.element.id === element.id : false
              })) {
                works += work.name;
              }
            }
            if (this.reportData[i].tasks[0].works.filter(work => !work.element).length) {
              works += 'Без аварийного элемента: ';
              for (let work of this.reportData[i].tasks[0].works.filter(work => !work.element)) {
                works += `${work.name} `
              }
            }
          }
          let tasks = `
        ${label}
        ${start} -
        ${end}
        ${works}
        `
          rows[i + 1].push(tasks);
        }
      }
      return rows;
    }
  },
  created() {
    this.$store.commit('reportsData/SET_REPORT_DATA', []);
  },
  mounted() {
    this.$store.dispatch('serviceDeskCategories/getCategoryGroups');
    this.$store.dispatch('issueTypes/getIssueTypes');
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";
.type-title {
  @include font-style(500, 20px, 28px, var(--text-primary));
}
</style>