import QuestionsService from "@/services/QuestionsService";

export const namespaced = true;

export const state = {
    questionsList: [],
    error: null,
    loading: false,
    query: ''
}

export const mutations = {
    SET_QUESTIONS_LIST(state, questionsList) {
        state.questionsList = questionsList;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_QUERY(state, query) {
        state.query = query;
    }
}

export const actions = {
    getQuestionsList({commit}, objectTypeId) {
        commit("SET_LOADING", true);
        QuestionsService.fetchQuestionsList(objectTypeId)
            .then(questionsList => commit("SET_QUESTIONS_LIST", questionsList))
            .catch(error => commit('SET_ERROR', error))
            .finally(() => commit("SET_LOADING", false));
    },
    createQuestion(context, payload) {
        return new Promise((resolve, reject) => {
            QuestionsService.createQuestion(payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    updateQuestion(context, {id, payload}) {
        return new Promise((resolve, reject) => {
            QuestionsService.updateQuestion(id, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    deleteQuestion(context, id) {
        return new Promise((resolve, reject) => {
            QuestionsService.deleteQuestion(id)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    }
}

export const getters = {
    getQuestionsByQuery: state => {
        return state.query.length ? state.questionsList.filter(question => question.question.toLowerCase()
            .includes(state.query.toLowerCase())) : state.questionsList;
    }
}