export default class Shutdown {
    constructor(id, attributes) {
        this.id = id;
        this.state = attributes.state;
        this.planned_start_date = attributes.planned_start_date;
        this.planned_end_date = attributes.planned_end_date;
        this.actual_start_date = attributes.actual_start_date || null;
        this.actual_end_date = attributes.actual_end_date || null;
        this.comment = attributes.comment;
    }

    getStateLabel() {
        switch (this.state) {
            case 0:
                return 'Не активно'
            case 1:
                return 'Активно'
        }
    }

    getStateColor() {
        switch (this.state) {
            case 0:
                return '#4f4f4f'
            case 1:
                return '#468FC5'
        }
    }
}