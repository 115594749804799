<template>
  <base-popup
    title="Привязать утечку к повреждению"
    :name="popupName">
    <template #body>
      <div class="form-item">
        <div class="form-item__name">Повреждение</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.damage_id"
            :options="damagesList"
            :reduce="damage => damage.id"
            :get-option-label="option => `#${option.id} ${option.defect.name}`"
            class="vue-select-input"
            placeholder="Выберите из списка"
          />
        </div>
      </div>
    </template>

    <template #footer>
      <div
        @click="submitForm()"
        class="button">
        Сохранить
      </div>
    </template>
  </base-popup>
</template>

<script>
import BasePopup from "@/components/BasePopup.vue";
import {mapState} from "vuex";
import {showToast, showAlert} from "@/utils/notification";

export default {
  name: "LeaksAttachForm",
  components: {BasePopup},
  props: {
    popupName: {
      type: String,
      required: true
    },
    issueId: {
      type: Number,
      required: true
    },
    leakId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      payload: {
        damage_id: null,
        leak_id: null
      }
    }
  },
  computed: {
    ...mapState({
      damagesList: state => state.issueModal.damagesList
    })
  },
  methods: {
    submitForm() {
      this.payload.leak_id = this.leakId;
      this.$store.dispatch('leaks/attachLeakToDamage', {
        issueId: this.issueId,
        payload: this.payload
      })
        .then(() => {
          this.$store.dispatch('issueModal/getLeaksList', this.issueId);
          this.$modal.hide(this.popupName);
          showToast('Утечка привязана к дефекту', 'success');
        })
        .catch(error => showAlert('Ошибка', error));
    }
  }
}
</script>

<style scoped>

</style>