<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="10"
       height="11"
       viewBox="0 0 10 11"
       fill="none">
    <path d="M5.63164 1.71954L1.52663 6.06454C1.37163 6.22954 1.22163 6.55454 1.19163 6.77954L1.00663
    8.39954C0.941635 8.98454 1.36163 9.38454 1.94163 9.28454L3.55164 9.00954C3.77664 8.96954 4.09163
    8.80454 4.24663 8.63454L8.35164 4.28954C9.06164 3.53954 9.38164 2.68454 8.27664 1.63954C7.17663
    0.604542 6.34164 0.969542 5.63164 1.71954Z"
          stroke="#9192AD"
          stroke-width="1"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M4.94678 2.44458C5.0516 3.11501 5.37608 3.73165 5.86928 4.1977C6.36249 4.66376 6.9965
    4.95284 7.67178 5.01958"
          stroke="#9192AD"
          stroke-width="1"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconPencil"
}
</script>

<style scoped>

</style>
