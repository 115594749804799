<template>
  <div class="i-modal-location"
       :class="{'is-open': isMapCollapsed}">
    <div class="i-modal-location__map">

      <base-map
        id-key="transport-map"
        :center="center"
        :zoom="17"
        :base-layers="baseLayers"
        :markers="marker"
        :need-resize="isMapCollapsed"
      />

    </div>
    <button class="button button--mode-icon map-resize-btn"
            @click="isMapCollapsed = !isMapCollapsed">
      <icon-arrow-double-up/>
    </button>
  </div>
</template>

<script>
import IconArrowDoubleUp from "@/components/Icons/IconArrowDoubleUp.vue";
import BaseMap from "@/components/BaseMap.vue";
import Vehicle from "@/models/transport/Vehicle";
import {mapState} from "vuex";
import Vue from "vue";
import TransportMapIcon from "@/components/Transport/TransportMapIcon.vue";
import Marker from "@/models/baseMap/Marker";
import Icon from "@/models/baseMap/Icon";

export default {
  name: "TransportModalMap",
  components: {BaseMap, IconArrowDoubleUp},
  props: {
    center: {
      type: Array,
      required: true
    },
    vehicle: {
      type: Vehicle,
      required: true
    }
  },
  data() {
    return {
      isMapCollapsed: false,
      marker: []
    }
  },
  computed: {
    ...mapState({
      baseLayers: state => state.baseLayers.baseLayers
    })
  },
  watch: {
    center: function () {
      this.createMarker();
    }
  },
  methods: {
    createMarker() {
      const TransportIconClass = Vue.extend(TransportMapIcon);
      const iconInstance = new TransportIconClass();
      iconInstance.vehicle = this.vehicle;
      const icon = iconInstance.$mount();

      this.marker = [
        new Marker({
          coords: this.center,
          icon: new Icon({
            type: 'div_icon',
            size: [36, 42],
            anchor: [18, 21],
            html: icon.$el
          }),
          accumulation: true
        })
      ]

      icon.$destroy();
    }
  }
}
</script>

<style scoped>

</style>
