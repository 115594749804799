<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="20"
       height="20"
       viewBox="0 0 20 20"
       fill="none">
    <path d="M13.5339 3.03906H6.46719C4.40885 3.03906 2.74219 4.71406 2.74219 6.76406V14.6057C2.74219 16.6557 4.41719
     18.3307 6.46719 18.3307H13.5255C15.5839 18.3307 17.2505 16.6557 17.2505 14.6057V6.76406C17.2589 4.70573 15.5839
      3.03906 13.5339 3.03906Z"
          fill="#9192AD"
          stroke="#9192AD"/>
    <path d="M11.9585 1.16406H8.0418C6.90245 1.16406 5.9668 2.08447 5.9668 3.23073V4.01406C5.9668 5.15687
     6.89065 6.08073 8.03346 6.08073H11.9585C13.1013 6.08073 14.0251 5.15687 14.0251 4.01406V3.233C14.0348
     2.08324 13.096 1.16406 11.9585 1.16406Z"
          fill="#9192AD"
          stroke="white"/>
    <path d="M9.00768 14.1281C8.84935 14.1281 8.69102 14.0698 8.56602 13.9448L7.31602 12.6948C7.07435 12.4531
     7.07435 12.0531 7.31602 11.8115C7.55768 11.5698 7.95768 11.5698 8.19935 11.8115L9.00768 12.6198L11.8993
      9.72812C12.141 9.48646 12.541 9.48646 12.7827 9.72812C13.0243 9.96979 13.0243 10.3698 12.7827 10.6115L9.44935
       13.9448C9.33268 14.0698 9.16602 14.1281 9.00768 14.1281Z"
          fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "IconClipboardFilled"
}
</script>

<style scoped>

</style>
