import SemanticProvidersService from "@/services/SemanticProvidersService";

export const namespaced = true;

export const state = {
    semanticProvidersList: [],
    semanticProvider: {},
    semanticProviderTypes: [],
    loading: false,
    error: null
}

export const mutations = {
    SET_SEMANTIC_PROVIDERS_LIST(state, semanticProvidersList) {
        state.semanticProvidersList = semanticProvidersList;
    },
    SET_SEMANTIC_PROVIDER(state, semanticProvider) {
        state.semanticProvider = semanticProvider
    },
    SET_SEMANTIC_PROVIDER_TYPES(state, semanticProviderTypes) {
        state.semanticProviderTypes = semanticProviderTypes;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    DELETE_SEMANTIC_PROVIDER(state, providerId) {
        const semanticProviderIndex = state.semanticProvidersList.findIndex(provider => provider.id === providerId);
        state.semanticProvidersList.splice(semanticProviderIndex, 1);
    }
}

export const actions = {
    getSemanticProvidersList({commit}) {
        commit("SET_LOADING", true);
        SemanticProvidersService.fetchSemanticProvidersList()
            .then(semanticProviders => commit("SET_SEMANTIC_PROVIDERS_LIST", semanticProviders))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false))
    },
    getSemanticProvider({commit}, semanticProviderId) {
        SemanticProvidersService.fetchSemanticProvider(semanticProviderId)
            .then(semanticProvider => commit("SET_SEMANTIC_PROVIDER", semanticProvider))
            .catch(error => commit("SET_ERROR", error))
    },
    getSemanticProviderTypes({commit}) {
        SemanticProvidersService.fetchSemanticProviderTypesList()
            .then(semanticProviderTypes => commit("SET_SEMANTIC_PROVIDER_TYPES",semanticProviderTypes))
            .catch(error => commit("SET_ERROR", error))
    },
    createSemanticProvider(context, payload) {
        return new Promise((resolve, reject) => {
            SemanticProvidersService.createSemanticProvider(payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    updateSemanticProvider(context, {providerId, payload}) {
        return new Promise((resolve, reject) => {
            SemanticProvidersService.updateSemanticProvider(providerId, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    deleteSemanticProvider(context, providerId) {
        return new Promise((resolve, reject) => {
            SemanticProvidersService.deleteSemanticProvider(providerId)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    }
}

export const getters = {
    getSemanticProvider: state => providerId => {
        return state.semanticProvidersList.find(provider => provider.id === +providerId);
    }
}