<template>
  <div class="page-wrapper">
    <base-panel
      title="Объекты энергопотребления"
      :is-add-button-visible="true"
      add-button-name="Добавить объект"
      @add-button-clicked="showPopup()"
    />

    <energy-consumption-objects-list
      v-if="energyConsumptionObjects.length"
      :energy-consumption-objects-list="energyConsumptionObjects"
      @open-energy-consumption-object-modal="showModal"
      @edit-energy-consumption-object="showPopup"
      @delete-energy-consumption-object="deleteEnergyConsumptionObject"
    />

    <base-content-loaders v-if="loading && !energyConsumptionObjects.length"/>

    <base-no-data v-if="!loading && !energyConsumptionObjects.length"/>

    <base-paginate
      v-if="meta && meta.last_page > 1"
      :meta="meta"
      action="energyConsumptionObjects/getEnergyConsumptionObjectsListWithMeters"
      commit="energyConsumptionObjects/SET_PAGE"
    />
  </div>
</template>

<script>
import EnergyConsumptionObjectsList from "@/components/EnergyConsumption/admin/EnergyConsumptionObjectsList";
import {mapState} from "vuex";
import BasePanel from "@/components/BasePanel.vue";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import BasePaginate from "@/components/BasePaginate.vue";
import EnergyConsumptionObjectForm from "@/components/EnergyConsumption/admin/EnergyConsumptionObjectForm.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import {showAlert, showToast, showConfirmationModal} from "@/utils/notification";

export default {
  name: "EnergyConsumptionObjectsListPage",
  components: {
    BasePaginate,
    BaseNoData,
    BaseContentLoaders, BasePanel, EnergyConsumptionObjectsList
  },
  computed: {
    ...mapState({
      energyConsumptionObjects: state => state.energyConsumptionObjects.energyConsumptionObjectsList,
      meta: state => state.energyConsumptionObjects.meta,
      loading: state => state.energyConsumptionObjects.loading
    })
  },
  mounted() {
    this.$store.dispatch('energyConsumptionObjects/getEnergyConsumptionObjectsListWithMeters');
  },
  methods: {
    showModal(id) {
      this.$store.dispatch('baseSideModal/openModal', {
        modalContentType: 'energyConsumptionObject',
        entityId: id
      })
    },
    showPopup(energyConsumptionObject = {}) {
      this.$modal.show(
        EnergyConsumptionObjectForm,
        {
          'energyConsumptionObject': energyConsumptionObject,
          'createEnergyConsumptionObject': this.createEnergyConsumptionObject,
          'updateEnergyConsumptionObject': this.updateEnergyConsumptionObject
        },
        {
          name: 'energy-consumption-object-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        }
      )
    },
    createEnergyConsumptionObject(payload) {
      this.$store.dispatch('energyConsumptionObjects/createEnergyConsumptionObject', payload)
        .then(() => {
          this.$store.dispatch('energyConsumptionObjects/getEnergyConsumptionObjectsListWithMeters');
          this.$modal.hide('energy-consumption-object-form');
          showToast('Объект энергопотребления добавлен', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    updateEnergyConsumptionObject(id, payload) {
      this.$store.dispatch('energyConsumptionObjects/updateEnergyConsumptionObject', {
        id, payload
      })
        .then(() => {
          this.$store.dispatch('energyConsumptionObjects/getEnergyConsumptionObjectsListWithMeters');
          this.$modal.hide('energy-consumption-object-form');
          showToast('Объект энергопотребления обновлен', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    deleteEnergyConsumptionObject(id) {
      showConfirmationModal('Вы уверены, что хотите удалить объект энергопотребления? Это действие невозвратно.')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('energyConsumptionObjects/deleteEnergyConsumptionObject', id)
              .then(() => {
                this.$store.commit('energyConsumptionObjects/DELETE_ENERGY_CONSUMPTION_OBJECT', id);
                showToast('Объект энергопотребления удален', 'success');
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style scoped>

</style>