<template>
  <div class="i-modal__content-wrapper">
    <div class="i-modal__content">
      <div class="i-modal__inner">
        <div class="base-modal__left-side">
          <div class="base-modal-header">
            <span
              v-if="!Object.keys(componentPropsData).length"
              class="base-modal-title">Новый объект</span>
            <span
              v-else
              class="base-modal-title">{{componentPropsData.name}}</span>
          </div>
          <div class="base-modal-info">
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <span>Наименование</span>
              </div>
              <div class="base-modal-info__value">
                <input
                  type="text"
                  v-model="payload.name"
                  class="base-input base-input--bordered"
                  placeholder="Введите наименование"
                >
              </div>
            </div>

            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <span>Тип</span>
              </div>
              <div class="base-modal-info__value">
                <v-select
                  class="vue-select-input"
                  v-model="payload.construction_type"
                  :options="NetworkObjectTypes"
                  :reduce="type => type.id"
                  :get-option-label="(option) => option.label"
                  placeholder="Выберите тип объекта"
                />
              </div>
            </div>

            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <span>Тип (из справочника)</span>
              </div>
              <div class="base-modal-info__value">
                <v-select
                  class="vue-select-input"
                  v-model="payload.type_id"
                  :options="networkObjectTypesList"
                  :reduce="type => type.id"
                  :get-option-label="(option) => option.name"
                  placeholder="Выберите тип объекта"
                />
              </div>
            </div>

            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <span>Телефон</span>
              </div>
              <div class="base-modal-info__value">
                <input
                  type="text"
                  v-model="payload.phone"
                  class="base-input base-input--bordered"
                  placeholder="Введите телефон"
                >
              </div>
            </div>

            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <span>Балансовая принадлежность</span>
              </div>
              <div class="base-modal-info__value">
                <v-select
                  class="vue-select-input"
                  v-model="payload.balance_sheet_id"
                  :options="balanceSheets"
                  :reduce="balanceSheet => balanceSheet.id"
                  :get-option-label="(option) => option.name"
                  placeholder="Выберите балансовую принадлежность"
                />
              </div>
            </div>

            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <span>Структурное подразделение</span>
              </div>
              <div class="base-modal-info__value">
                <v-select
                  class="vue-select-input"
                  v-model="payload.structural_unit_ids"
                  :options="structuralUnits"
                  :reduce="structuralUnit => structuralUnit.id"
                  :get-option-label="(option) => option.name"
                  placeholder="Выберите структурыне подразделения"
                  multiple
                />
              </div>
            </div>

            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <span>Адрес</span>
              </div>
              <div class="base-modal-info__value">
                <address-selector
                  :address-string="payload.address"
                  @set-address="setCoordinates"
                  @update:address-string="value => this.payload.address = value"
                />
              </div>
            </div>

            <div class="base-modal-info__item--small-margin">
              <network-object-form-map
                :lat="payload.lat"
                :lon="payload.lng"
                @update-lat="lat => this.payload.lat = lat"
                @update-lon="lon => this.payload.lng = lon"
              />
            </div>

            <div class="base-modal-btn-wp create-network-object-buttons">
              <button
                v-if="!Object.keys(componentPropsData).length"
                @click="createNetworkObject()"
                class="button button--mode-primary">
                <span>Создать</span>
              </button>
              <button
                v-else
                @click="updateNetworkObject()"
                class="button button--mode-primary">
                <span>Изменить</span>
              </button>
              <div @click="cancelForm()"
                   class="button button--mode-outline">
                <span>Отменить</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="i-modal-btn-wp i-modal-btn-wp--back">
      <div class="task-modal__mobile-actions">
        <button @click="$store.dispatch('baseSideModal/goBack')"
                class="button button--mode-outline task-modal__mobile-back">
          <icon-arrow-left/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrowLeft from "@/components/Icons/IconArrowLeft.vue";
import NetworkObject from "@/models/telemetry/NetworkObject";
import {mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";
import NetworkObjectFormMap from "@/components/Telemetry/admin/NetworkObject/NetworkObjectFormMap.vue";
import AddressSelector from "@/components/ServiceDesk/CommonComponents/AddressSelector/AddressSelector.vue";

export default {
  name: "NetworkObjectFormModalContent",
  components: {
    AddressSelector,
    NetworkObjectFormMap,
    IconArrowLeft
  },
  data() {
    return {
      payload: {
        construction_type: null,
        type_id: null,
        name: null,
        address: '',
        phone: null,
        lat: 0,
        lng: 0,
        balance_sheet_id: null,
        structural_unit_ids: null
      }
    }
  },
  computed: {
    NetworkObjectTypes: () => NetworkObject.getTypesArray(),
    ...mapState({
      balanceSheets: state => state.balanceSheets.balanceSheetsList,
      networkObjectTypesList: state => state.networkObjectTypes.networkObjectTypes,
      structuralUnits: state => state.structuralUnits.structuralUnits,
      componentPropsData: state => state.baseSideModal.componentPropsData
    })
  },
  mounted() {
    this.$store.dispatch('balanceSheets/getBalanceSheetsList');
    this.$store.dispatch('networkObjectTypes/getNetworkObjectTypesList');
    this.$store.dispatch('structuralUnits/getUserStructuralUnitsList');

    if (Object.keys(this.componentPropsData).length) {
      const {type, _type, name, address, phone, coordinates, balanceSheetId, structuralUnits} = this.componentPropsData;
      const [lat, lon] = coordinates;

      this.payload.construction_type = type;
      this.payload.type_id = _type.id;
      this.payload.name = name;
      this.payload.address = address;
      this.payload.phone = phone;
      this.payload.balance_sheet_id = balanceSheetId;
      this.payload.structural_unit_ids = structuralUnits.map(item => item.id);

      this.setCoordinates({lat, lon});
    }
  },
  methods: {
    createNetworkObject() {
      this.$store.dispatch('networkObjects/createNetworkObject', this.payload)
        .then(() => {
          this.$store.dispatch('networkObjects/getAllNetworkObjects');
          this.$store.dispatch('baseSideModal/goBack');
          showToast('Объект сети добавлен', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    updateNetworkObject() {
      this.$store.dispatch('networkObjects/updateNetworkObject', {
        networkObjectId: this.componentPropsData.id,
        payload: this.payload
      })
        .then(() => {
          this.$store.dispatch('networkObjects/getAllNetworkObjects');
          this.$store.dispatch('baseSideModal/goBack');
          showToast('Объект сети изменен', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    cancelForm() {
      this.$store.dispatch('baseSideModal/goBack');
    },
    setCoordinates({lat, lon}) {
      this.$store.commit('baseMap/SET_CENTER_ZOOM', {
        center: [lat, lon],
        zoom: 18
      });

      this.payload.lat = lat;
      this.payload.lng = lon;
    }
  }
}
</script>

<style lang="scss" scoped>
.create-network-object-buttons {
  display: flex;
  gap: 12px;

  & .button {
    flex: 1;
  }
}

.base-modal-info__item {
  align-items: baseline;
}
</style>