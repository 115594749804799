<template>
  <div class="base-table">
    <div class="base-table__container">
      <div class="base-table__header base-table__column--network-object">
        <div class="base-table__header-cell">ID</div>
        <div class="base-table__header-cell">Наименование</div>
        <div class="base-table__header-cell">Тип</div>
        <div class="base-table__header-cell">Адрес</div>
        <div class="base-table__header-cell">Балансовая принадлежность</div>
        <div class="base-table__header-cell">Структурное подразделение</div>
      </div>

      <div v-for="networkObject in networkObjectsList"
           :key="networkObject.id"
           @click="$emit('open-network-object-modal', networkObject.id);"
           class="base-table__row base-table__column--network-object">
        <div class="base-table__column">
          <div class="base-table__text">{{ networkObject.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ networkObject.name }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ networkObject.getTypeLabel() }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ networkObject.address }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{nullFilter(networkObject.balanceSheetName) }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ networkObject.structuralUnitsNames }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {nullFilter} from "@/utils/filters";

export default {
  name: "NetworkObjectsList",
  props: {
    networkObjectsList: {
      type: Array,
      required: true
    }
  },
  methods: {nullFilter}
}
</script>

<style scoped>
.base-table__column--network-object {
  grid-template-columns: 80px 4fr 1fr 4fr 3fr 3fr;
}
</style>