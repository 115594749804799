import { render, staticRenderFns } from "./MenuLink.vue?vue&type=template&id=4add4a20&scoped=true&"
import script from "./MenuLink.vue?vue&type=script&lang=js&"
export * from "./MenuLink.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4add4a20",
  null
  
)

export default component.exports