<template>
  <div class="page-wrapper">
    <base-panel
      title="Картографические слои"
      :is-add-button-visible="true"
      add-button-name="Добавить слой"
      @add-button-clicked="showPopup()"/>

    <layers-list
      v-if="layersList.length"
      :layers-list="layersList"
      @show-layer-modal="showLayerModal"
      @edit-layer="showPopup"
      @delete-layer="deleteLayer"
    />

    <base-content-loaders v-if="loading && !layersList.length" />
  </div>
</template>

<script>
import LayersList from "@/components/EngineeringNetworks/MapLayers/LayersList.vue";
import BasePanel from "@/components/BasePanel.vue";
import {mapState} from "vuex";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import LayersForm from "@/components/EngineeringNetworks/MapLayers/LayersForm.vue";
import {showAlert, showToast, showConfirmationModal} from "@/utils/notification";

export default {
  name: "LayersCatalogPage",
  components: {
    BaseContentLoaders,
    BasePanel,
    LayersList
  },
  computed: {
    ...mapState({
      layersList: state => state.layer.layers,
      loading: state => state.layer.loading
    })
  },
  mounted() {
    this.$store.dispatch('layer/getLayers');
    this.$store.dispatch('semanticProviders/getSemanticProvidersList');
  },
  methods: {
    showLayerModal(layerId) {
      this.$store.dispatch('baseSideModal/openModal', {
        modalContentType: 'layer',
        entityId: layerId
      });
    },
    showPopup(layer = {}) {
      this.$modal.show(
        LayersForm,
        {
          layer: layer,
          createLayer: this.createLayer,
          updateLayer: this.updateLayer
        },
        {
          name: 'layer-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        }
      )
    },
    createLayer(payload) {
      this.$store.dispatch('layer/createLayer', payload)
        .then(() => {
          this.$store.dispatch('layer/getLayers');
          this.$modal.hide('layer-form');
          showToast('Картографический слой добавлен', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    updateLayer(layerId, payload) {
      this.$store.dispatch('layer/updateLayer', {
        layerId, payload
      })
        .then(() => {
          this.$store.dispatch('layer/getLayers');
          this.$modal.hide('layer-form');
          showToast('Картографический слой обновлен', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    deleteLayer(layerId) {
      showConfirmationModal('Вы уверены, что хотите удалить картографический слой? Это действие невозвратно.')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('layer/deleteLayer', layerId)
              .then(() => {
                this.$store.dispatch('layer/getLayers');
                showToast('Картографический слой удален', 'success');
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style scoped>

</style>