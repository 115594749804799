<script>
export default {
  name: "IconSort2"
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none">
    <path
      d="M3 7H21M6 12H18M10 17H14"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-linecap="round"/>
  </svg>
</template>

<style scoped>

</style>
