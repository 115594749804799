import DadataService from "@/services/DadataService";

export const namespaced = true;

export const state = {
    suggestionsList: [],
    error: null
}

export const mutations = {
    SET_SUGGESTIONS_LIST(state, list) {
        state.suggestionsList = list;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getSuggestionsList({commit}, {query, type, count}) {
        DadataService.getSuggestions(query, type, count)
            .then(suggestions => commit("SET_SUGGESTIONS_LIST", suggestions))
            .catch(error => commit("SET_ERROR", error))
    }
}