<template>
  <div class="base-table">
    <div class="base-table__container">
      <div class="base-table__header base-table__column--task-types">
        <div class="base-table__header-cell">ID</div>
        <div class="base-table__header-cell">Наименование</div>
        <div class="base-table__header-cell">Тег</div>
        <div class="base-table__header-cell">Макс. время выполнения</div>
        <div class="base-table__header-cell">Группы проблем</div>
        <div class="base-table__header-cell">Компоненты</div>
        <div class="base-table__header-cell"/>
      </div>

      <div v-for="taskType in taskTypesList"
           :key="taskType.id"
           class="base-table__row base-table__column--task-types">
        <div class="base-table__column">
          <div class="base-table__text">{{ taskType.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ taskType.name }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ Tag.getTagLabel(taskType.tag) }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ taskType.leadTime }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ taskType.categoryGroups.map(item => item.name).join(', ') }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ taskType.features.map(item => item.name).join(', ') }}</div>
        </div>
        <div class="base-table__column base-table__column--content-right">
          <div class="base-table__short-info">
            <div @click="$emit('edit-task-type', taskType)">
              <icon-pencil class="base-table__short-info--p-2" />
            </div>
            <div @click="$emit('delete-task-type', taskType.id)">
              <icon-trash />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tag from '@/models/serviceDesk/Tag'
import IconTrash from "@/components/Icons/IconTrash.vue";
import IconPencil from "@/components/Icons/IconPencil.vue";

export default {
  name: "TaskTypesList",
  components: {IconPencil, IconTrash},
  props: {
    taskTypesList: {
      type: Array,
      required: true
    }
  },
  computed: {
    Tag: () => Tag
  }
}
</script>

<style scoped>
.base-table__column--task-types {
  grid-template-columns: 4.07% 16.26% 13.55% 8.13% 25.07% 17.62% 1fr;
}
</style>
