<script>
export default {
  name: "IconArrowRight"
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none">
    <path
      d="M9.00839 17.9915L14.9914 11.9915L9.00839 5.99152"
      stroke="#468FC5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>

<style scoped>

</style>