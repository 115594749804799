<template>
  <div class="base-table base-table--tasks">
    <div class="base-table__container">
      <div class="base-table__header base-table__header--task">
        <div class="base-table__header-cell">ID</div>
        <div class="base-table__header-cell">Работы</div>
        <div class="base-table__header-cell">План. нач/оконч</div>
        <div class="base-table__header-cell">Адрес</div>
        <div class="base-table__header-cell">Заявка</div>
        <div class="base-table__header-cell">Бригада</div>
        <div class="base-table__header-cell">Статус</div>
        <div class="base-table__header-cell"/>
      </div>

      <div v-for="task in tasksList"
           :key="task.id"
           class="base-table__row base-table__row--task"
           @click="openTaskModal(task.id)">
        <div class="base-table__column base-table__column--number">
          <div class="base-table__num">{{ task.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ task.type_label }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ task.plannedDatesRangeCaption }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ task.issue.getFullAddress() }}</div>
        </div>
        <div class="base-table__column base-table__column--issue">
          <div @click.stop="openIssueModal(task.issue.id)"
               class="base-table__join-link">
            <icon-link/>
            <div class="base-table__join-counter">#{{ task.issue.id }}</div>
          </div>
        </div>
        <div class="base-table__column">
          <div class="base-table__tag base-table__tag--secondary">{{ task.brigade ? task.brigade.name : '—' }}</div>
        </div>
        <div class="base-table__column base-table__column--state">
          <div class="base-table__tag"
               :class="[
                 {'base-table__tag--new': task.state.id === TaskState.NEW},
                 {'base-table__tag--work': task.state.id === TaskState.IN_WORK},
                 {'base-table__tag--done': task.state.id === TaskState.CLOSED},
                 {'base-table__tag--canceled': task.state.id === TaskState.CANCELED},
                 {'base-table__tag--suspended': task.state.id === TaskState.SUSPENDED},
                 {'base-table__tag--planned': task.state.id === TaskState.ON_THE_WAY}
               ]">
            {{ task.state.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconLink from "@/components/Icons/IconLink";
import TaskState from "@/models/serviceDesk/TaskState";
import sideModalControls from "@/mixins/sideModalControls";

export default {
  name: "TasksList",
  components: {IconLink},
  mixins: [sideModalControls],
  props: {
    tasksList: {
      type: Array,
      required: true
    }
  },
  computed: {
    TaskState: () => TaskState
  },
  methods: {
    openModal(task) {
      this.$emit('open-task-modal', task);
    }
  }
}
</script>

<style scoped>
.base-table--tasks {
  margin-bottom: 24px;
}
</style>
