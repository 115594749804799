<template>
  <div class="task-modal__actions">
    <div v-if="taskStateId === TechnicalInspectionTaskState.NEW"
         @click="doTaskAction('technicalInspectionTasks/cancelTask', 'Задание отменено')"
         class="button button--mode-outline button--mode-outline-text-danger">
      <span>Отменить</span>
    </div>

    <div v-if="taskStateId === TechnicalInspectionTaskState.REVIEW"
         @click="doTaskAction('technicalInspectionTasks/finishTask', 'Задание завершено')"
         class="button button--mode-primary">
      <span>Завершить</span>
    </div>

    <div v-if="taskStateId === TechnicalInspectionTaskState.REVIEW"
         @click="doTaskAction('technicalInspectionTasks/returnToWorkTask', 'Задание возвращено в работу')"
         class="button button--mode-primary">
      <span>Вернуть в работу</span>
    </div>
  </div>
</template>

<script>
import {TechnicalInspectionTaskState} from "@/models/TechnicalInspection/TechnicalInspectionTaskState";
import {showAlert, showToast} from "@/utils/notification";

export default {
  name: "TaskStateActions",
  props: {
    taskId: {
      type: Number,
      required: true
    },
    taskStateId: {
      type: Number,
      required: true
    }
  },
  computed: {
    TechnicalInspectionTaskState() {
      return TechnicalInspectionTaskState
    }
  },
  methods: {
    doTaskAction(action, successMessage) {
      this.$store.dispatch(action, this.taskId)
        .then(() => {
          showToast(successMessage, 'success');
          this.$store.dispatch('technicalInspectionTasks/getTaskData', this.taskId);
        })
        .catch(error => {
          showAlert('Ошибка', error, 'error');
        })
    }
  }
}
</script>

<style scoped>
.task-modal__actions .button {
  flex: 1;
}
</style>