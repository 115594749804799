 import * as API from './API'
 import {getError} from "@/utils/helpers";
 import {WorkShift} from "@/models/serviceDesk/WorkShift";

export default {
    getWorkShift(params) {
        return new Promise((resolve, reject) => {
            API.apiClient.get('service-desk/work-shifts', {params: params})
                .then(response => {
                    resolve(response.data.data.map(workShift => new WorkShift(workShift.id, workShift.attributes)))
                })
                .catch(error => reject(getError(error)));
        })
    },
    createWorkShiftItem(payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post('service-desk/work-shifts', payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    deleteWorkShiftItem(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`service-desk/work-shifts/${id}`)
                .then(()=> resolve())
                .catch(error=> reject(getError(error)));
        })
    }
}