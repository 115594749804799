import Exceljs from "exceljs";
import {addExcelBorder} from "@/utils/addExcelBorder";
import {saveAs} from 'file-saver'
export const taskMaterialsReportExcel = tableData => {
    const workBook = new Exceljs.Workbook;
    const worksheet = workBook.addWorksheet("Отчет");

    worksheet.addRow([]);
    worksheet.getColumn('V').width= 16;
    worksheet.getColumn('P').width = 12
    worksheet.getColumn('J').width = 2;
    worksheet.getColumn('B').width = 2;
    worksheet.getColumn('S').width = 2;
    worksheet.getColumn('J').width = 2;
    worksheet.getColumn('J').width = 2;
    worksheet.getColumn('J').width = 2;
    worksheet.addRow([]);
    worksheet.addRow(['',
        '',
        '','','','','','','','','','','','','','','','','','УТВЕРЖДАЮ','','','']);
    worksheet.getRow(3).height = 80;
    worksheet.getCell('T3').alignment = { vertical: 'top', horizontal: 'center' };
    worksheet.getCell('B3').alignment = { vertical: 'top', horizontal: 'center' };
    worksheet.mergeCells('T3:W3');
    worksheet.addRow(['',
        '','','','','','','','','','','','','','','','','','',''
    ]);
    worksheet.mergeCells('B3:K3');
    worksheet.mergeCells('B4:K4')
    worksheet.mergeCells('T4:W4');

    worksheet.addRow(['', '(организация)','','','','','','','','','','','','','','','','','','(должность)']);
    worksheet.mergeCells('B5:K5');
    worksheet.mergeCells('T5:W5');

    worksheet.addRow(['', '','','','','','','','','','','','','','','',
        '','','','','','','']);
    worksheet.mergeCells('B6:K6');
    worksheet.mergeCells('T6:U6');
    worksheet.mergeCells('V6:W6');

    worksheet.addRow(['', '','','','','','','','','','','','','','','','','','','(подпись)','',
        '(расшифровка подписи)','']);
    worksheet.mergeCells('T7:U7');
    worksheet.mergeCells('V7:W7');

    worksheet.addRow(['',`Акт на списание материалов № ________ от ______________ г.`]);
    worksheet.mergeCells('B8:W8');

    worksheet.addRow([])
    worksheet.addRow(['',
        `Настоящий акт составлен о том, что нижеперечисленные материалы израсходованы в полном объеме.
       Цель расхода:     Отпуск в производство`]);
    worksheet.mergeCells('B10:S10');

    worksheet.addRow([])
    worksheet.addRow(['','№', '','Наименование','','','','Единица измерения','','','','инв. №',
        '№ компонента ОС','','','Куда израсходованы','','Количество','','','Цена','','Сумма']);
    worksheet.mergeCells('B12:C12');
    worksheet.mergeCells('D12:G12');
    worksheet.mergeCells('H12:K12');
    worksheet.mergeCells('M12:O12');
    worksheet.mergeCells('P12:Q12');
    worksheet.mergeCells('R12:T12');
    worksheet.mergeCells('U12:V12');

    let consumptionNumber = 0;

    const tableStart = {
        col: 2,
        row: worksheet.rowCount
    }
    for (let material of tableData) {
        consumptionNumber++;
        worksheet.addRow(['',consumptionNumber, '',material.consumable.name,'','','',
            material.consumable.measure,'','','',
            '', '','','','','',material.amount,'','','','','']);

        const currentRowIdx = worksheet.rowCount;
        worksheet.mergeCells(`B${currentRowIdx}:C${currentRowIdx}`);
        worksheet.mergeCells(`D${currentRowIdx}:G${currentRowIdx}`);
        worksheet.mergeCells(`H${currentRowIdx}:K${currentRowIdx}`);
        worksheet.mergeCells(`M${currentRowIdx}:O${currentRowIdx}`);
        worksheet.mergeCells(`P${currentRowIdx}:Q${currentRowIdx}`);
        worksheet.mergeCells(`R${currentRowIdx}:T${currentRowIdx}`);
        worksheet.mergeCells(`U${currentRowIdx}:V${currentRowIdx}`);
    }
    const tableEnd = {
        col: 23,
        row: worksheet.rowCount
    }
    addExcelBorder(worksheet, tableStart,tableEnd)

    worksheet.addRow([])
    worksheet.addRow(['','','','','','','','','','','','','','','','','Итого','',''])
    let currentRowIdx = worksheet.rowCount;
    worksheet.mergeCells(`U${currentRowIdx}:V${currentRowIdx}`);

    worksheet.addRow([])
    worksheet.addRow(['','Всего по настоящему акту списано материалов на общую сумму:'])
    currentRowIdx = worksheet.rowCount;
    worksheet.mergeCells(`B${currentRowIdx}:T${currentRowIdx}`);

    worksheet.addRow([])
    currentRowIdx = worksheet.rowCount;
    worksheet.mergeCells(`B${currentRowIdx}:T${currentRowIdx}`);

    worksheet.addRow([])


    worksheet.addRow(['','','Председатель комиссии:','','','','_______________________', '', '',
        '_______________________', '', '','_______________________', '', '']);
    currentRowIdx = worksheet.rowCount;
    worksheet.mergeCells(`C${currentRowIdx}:F${currentRowIdx}`);
    worksheet.mergeCells(`G${currentRowIdx}:I${currentRowIdx}`);
    worksheet.mergeCells(`J${currentRowIdx}:L${currentRowIdx}`);
    worksheet.mergeCells(`M${currentRowIdx}:O${currentRowIdx}`);
    worksheet.addRow(['','','','','','','(должность)', '', '',
        '(подпись)', '', '','(расшифровка подписи)', '', '']);
    currentRowIdx = worksheet.rowCount;
    worksheet.mergeCells(`C${currentRowIdx}:F${currentRowIdx}`);
    worksheet.mergeCells(`G${currentRowIdx}:I${currentRowIdx}`);
    worksheet.mergeCells(`J${currentRowIdx}:L${currentRowIdx}`);
    worksheet.mergeCells(`M${currentRowIdx}:O${currentRowIdx}`);
    worksheet.addRow(['','','Члены комиссии:','','','','_______________________', '', '',
        '_______________________', '', '','_______________________', '', '']);
    currentRowIdx = worksheet.rowCount;
    worksheet.mergeCells(`C${currentRowIdx}:F${currentRowIdx}`);
    worksheet.mergeCells(`G${currentRowIdx}:I${currentRowIdx}`);
    worksheet.mergeCells(`J${currentRowIdx}:L${currentRowIdx}`);
    worksheet.mergeCells(`M${currentRowIdx}:O${currentRowIdx}`);
    worksheet.addRow(['','','','','','','(должность)', '', '',
        '(подпись)', '', '','(расшифровка подписи)', '', '']);
    currentRowIdx = worksheet.rowCount;
    worksheet.mergeCells(`C${currentRowIdx}:F${currentRowIdx}`);
    worksheet.mergeCells(`G${currentRowIdx}:I${currentRowIdx}`);
    worksheet.mergeCells(`J${currentRowIdx}:L${currentRowIdx}`);
    worksheet.mergeCells(`M${currentRowIdx}:O${currentRowIdx}`);
    worksheet.addRow(['','','','','','','_______________________', '', '',
        '_______________________', '', '','_______________________', '', '']);
    currentRowIdx = worksheet.rowCount;
    worksheet.mergeCells(`C${currentRowIdx}:F${currentRowIdx}`);
    worksheet.mergeCells(`G${currentRowIdx}:I${currentRowIdx}`);
    worksheet.mergeCells(`J${currentRowIdx}:L${currentRowIdx}`);
    worksheet.mergeCells(`M${currentRowIdx}:O${currentRowIdx}`);
    worksheet.addRow(['','','','','','','(должность)', '', '',
        '(подпись)', '', '','(расшифровка подписи)', '', '']);
    currentRowIdx = worksheet.rowCount;
    worksheet.mergeCells(`C${currentRowIdx}:F${currentRowIdx}`);
    worksheet.mergeCells(`G${currentRowIdx}:I${currentRowIdx}`);
    worksheet.mergeCells(`J${currentRowIdx}:L${currentRowIdx}`);
    worksheet.mergeCells(`M${currentRowIdx}:O${currentRowIdx}`);
    worksheet.addRow(['','','','','','','_______________________', '', '',
        '_______________________', '', '','_______________________', '', '']);
    currentRowIdx = worksheet.rowCount;
    worksheet.mergeCells(`C${currentRowIdx}:F${currentRowIdx}`);
    worksheet.mergeCells(`G${currentRowIdx}:I${currentRowIdx}`);
    worksheet.mergeCells(`J${currentRowIdx}:L${currentRowIdx}`);
    worksheet.mergeCells(`M${currentRowIdx}:O${currentRowIdx}`);
    worksheet.addRow(['','','','','','','(должность)', '', '',
        '(подпись)', '', '','(расшифровка подписи)', '', '']);
    currentRowIdx = worksheet.rowCount;
    worksheet.mergeCells(`C${currentRowIdx}:F${currentRowIdx}`);
    worksheet.mergeCells(`G${currentRowIdx}:I${currentRowIdx}`);
    worksheet.mergeCells(`J${currentRowIdx}:L${currentRowIdx}`);
    worksheet.mergeCells(`M${currentRowIdx}:O${currentRowIdx}`);
    worksheet.addRow(['','','','','','','_______________________', '', '',
        '_______________________', '', '','_______________________', '', '']);
    currentRowIdx = worksheet.rowCount;
    worksheet.mergeCells(`C${currentRowIdx}:F${currentRowIdx}`);
    worksheet.mergeCells(`G${currentRowIdx}:I${currentRowIdx}`);
    worksheet.mergeCells(`J${currentRowIdx}:L${currentRowIdx}`);
    worksheet.mergeCells(`M${currentRowIdx}:O${currentRowIdx}`);
    worksheet.addRow(['','','','','','','(должность)', '', '',
        '(подпись)', '', '','(расшифровка подписи)', '', '']);
    currentRowIdx = worksheet.rowCount;
    worksheet.mergeCells(`C${currentRowIdx}:F${currentRowIdx}`);
    worksheet.mergeCells(`G${currentRowIdx}:I${currentRowIdx}`);
    worksheet.mergeCells(`J${currentRowIdx}:L${currentRowIdx}`);
    worksheet.mergeCells(`M${currentRowIdx}:O${currentRowIdx}`);


    workBook.xlsx.writeBuffer().then(function (data) {
        let blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"})
        saveAs(blob, "Отчет.xlsx")
    })
}