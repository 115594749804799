<script>
export default {
  name: "IconBuilding"
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none">
    <path
      d="M1.66675 18.3333H18.3334"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M14.1667 1.66666H5.83333C3.33333 1.66666 2.5 3.15832 2.5 4.99999V18.3333H17.5V4.99999C17.5 3.15832 16.6667
       1.66666 14.1667 1.66666Z"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M5.83325 13.75H8.33325"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M11.6667 13.75H14.1667"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M5.83325 10H8.33325"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M11.6667 10H14.1667"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M5.83325 6.25H8.33325"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M11.6667 6.25H14.1667"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>

<style scoped>

</style>