<template>
  <div class="map-marker">
    <map-icon-bg/>
    <div class="map-marker__inner">
      <div
        class="map-marker__img map-marker__img--bg-new">
        <icon-comment class="map-marker__img--white"/>
      </div>
    </div>
  </div>
</template>

<script>
import MapIconBg from "@/components/Icons/MapIconBg.vue";
import IconComment from "@/components/Icons/IconComment.vue";

export default {
  name: "DetectedProblemMapMarker",
  components: {IconComment, MapIconBg}
}
</script>

<style scoped>

</style>