<template>
  <div class="page-wrapper">
    <base-panel
      title="Протяженность сетей"
      :is-add-button-visible="false"/>

    <div class="networks-length-filter">
      <date-picker
        v-model="selectedYear"
        :default-value="defaultValue"
        class="base-date-picker"
        placeholder="Выберите период"
        type="year"
        format="YYYY"
        value-type="YYYY"
        @change="getNetworksLength()"
      />
    </div>

    <engineering-networks-length-list
      :engineering-networks-list="engineeringNetworksList"
      :selected-year="selectedYear" />
  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel.vue";
import DatePicker from "vue2-datepicker";
import EngineeringNetworksLengthList from "@/components/EngineeringNetworks/EngineeringNetworksLengthList.vue";
import {mapState} from "vuex";

export default {
  name: "EngineeringNetworksLengthPage",
  components: {EngineeringNetworksLengthList, BasePanel, DatePicker},
  data() {
    return {
      selectedYear: new Date().getFullYear().toString(),
      defaultValue: new Date()
    }
  },
  computed: {
    ...mapState({
      engineeringNetworksList: state => state.engineeringNetworks.engineeringNetworks
    })
  },
  mounted() {
    this.getNetworksLength();
    this.$store.dispatch('engineeringNetworks/getAllEngineeringNetworks');
  },
  methods: {
    getNetworksLength() {
      this.$store.dispatch('networksLength/getNetworksLength', {
        year_date: this.selectedYear
      });
    }
  }
}
</script>

<style scoped>
.networks-length-filter {
  width: 320px;
  margin-bottom: 32px;
}
</style>
