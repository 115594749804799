<script>
export default {
  name: "IconAtmosphere"
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M21 17H2.5L3 13L5.5 11.5L16 10L21 17Z" fill="#DBEAEE"/>
    <path d="M20.5 17C23.77 14.14 21.7497 9.01093 17.4397 8.47093C15.8997 -0.869075 2.42973 2.67093 5.61973 11.5609" fill="#DBEAEE"/>
    <path d="M20.5 17C23.77 14.14 21.7497 9.01093 17.4397 8.47093C15.8997 -0.869075 2.42973 2.67093 5.61973 11.5609" stroke="#1AB9EB" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.28035 11.9708C6.75035 11.7008 6.16035 11.5608 5.57035 11.5708C2.51864 11.7869 1.4697 14.7692 2.41512 16.8946" stroke="#1AB9EB" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.8203 8.89047C16.3403 8.63047 16.9003 8.49047 17.4803 8.48047L15.8203 8.89047Z" fill="#DBEAEE"/>
    <path d="M15.8203 8.89047C16.3403 8.63047 16.9003 8.49047 17.4803 8.48047" stroke="#1AB9EB" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15 17H8" stroke="#468FC5" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M11 20H1" stroke="#468FC5" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M15 23H5" stroke="#468FC5" stroke-width="1.5" stroke-linecap="round"/>
  </svg>
</template>

<style scoped>

</style>