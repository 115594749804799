import * as API from './API'
import {getError} from "@/utils/helpers";

export default {
    fetchAppeals(params) {
        return new Promise((resolve, reject) => {
            API.apiClient.get('call-center/statistic', {params: params})
                .then(response => resolve(response.data.data))
                .catch(error => reject(getError(error)))
        })
}
}