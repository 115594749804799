export class ProjectConfig {
    constructor(configuration) {
        this.configuration = configuration
        this.name = configuration.main.project_name;
        const {coordinates_lat, coordinates_lng} = configuration.map;
        this.coordinates = [coordinates_lat, coordinates_lng];
        this.zoom = configuration.map.zoom;
        this.ws_app_key = configuration.main.ws_app_key
        this.logo_url = configuration.main.logo_url
        this.logo_position = configuration.main.logo_position
        this.location = configuration.location;
        this.phone_mask = configuration.main.phone_mask;
    }

    setLightColorSchema() {
        const {color_schema} = this.configuration
        this.setColorSchema(color_schema);
    }

    setDarkColorSchema() {
        const {dark_color_schema} = this.configuration;
        this.setColorSchema(dark_color_schema);
    }

    setColorSchema(color_schema) {
        for (const conf in color_schema) {
            document.body.style.setProperty(`--${conf}`, `${color_schema[conf]}`)
        }
    }
}
