export default class Resource {
    constructor(params) {
        this.id = params.id;
        this.name = params.name;
        this.master = params.master || {
            id: null,
            name: '—'
        };
        this.transport = params.transport || {
            id: null,
            name: '—'
        };
        this.department = params.department;
        this.structuralUnit = params.structural_unit;
        this.lastLocation = params.last_location;
        this.lastLocationDate = params.last_location_date;
    }
}