import DeviceService from "@/services/DeviceService";
import NetworkEquipmentsService from "@/services/NetworkEquipmentsService";
import NetworkObjectsService from "@/services/NetworkObjectsService";
import NetworkEquipmentParametersService from "@/services/NetworkEquipmentParametersService";

export const namespaced = true;

export const state = {
    networkObject: {},
    devicesList: [],
    equipmentsList: [],
    equipment: {},
    device: {},
    parameterProfile: [],
    loading: false
}

export const mutations = {
    SET_NETWORK_OBJECT(state, networkObject) {
        state.networkObject = networkObject;
    },
    SET_DEVICES_LIST(state, devicesList) {
        state.devicesList = devicesList;
    },
    SET_EQUIPMENTS_LIST(state, equipmentsList) {
        state.equipmentsList = equipmentsList;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_EQUIPMENT(state, equipment) {
        state.equipment = equipment;
    },
    SET_DEVICE(state, device) {
        state.device = device;
    },
    SET_PARAMETER_PROFILE(state, profile) {
        state.parameterProfile = profile;
    }
}

export const actions = {
    getNetworkObject({commit}, networkObjectId) {
        NetworkObjectsService.fetchNetworkObject(networkObjectId)
            .then(networkObject => commit("SET_NETWORK_OBJECT", networkObject))
    },
    getNetworkObjectDevices({commit}, networkObjectId) {
        return new Promise((resolve, reject) => {
            DeviceService.getDevices({construction_id: networkObjectId})
                .then(devices => {
                    commit("SET_DEVICES_LIST", devices);
                    resolve();
                })
                .catch(error => reject(error))
        })
    },
    getNetworkObjectEquipments({commit}, networkObjectId) {
        return new Promise((resolve, reject) => {
            NetworkEquipmentsService.fetchNetworkEquipments({object_id: networkObjectId})
                .then(equipments => {
                    commit("SET_EQUIPMENTS_LIST", equipments);
                    resolve();
                })
                .catch(error => reject(error))
        })
    },
    async getNetworkObjectWithDevicesAndEquipments({commit, dispatch}, networkObjectId) {
        commit("SET_LOADING", true);

        await Promise.all([
            dispatch('getNetworkObject', networkObjectId),
            dispatch('getNetworkObjectDevices', networkObjectId),
            dispatch('getNetworkObjectEquipments', networkObjectId)
        ])
            .finally(() => commit("SET_LOADING", false))
    },
    getEquipment({commit, state}, equipmentId) {
        const equipment = state.equipmentsList.find(equipment => equipment.id === equipmentId);
        commit("SET_EQUIPMENT", equipment);
    },
    getDevice({commit, state}, deviceId) {
        const device = state.devicesList.find(device => device.id === deviceId);
        commit("SET_DEVICE", device);
    },
    getParameterProfile({commit}, {equipmentId, parameterId}) {
        return new Promise((resolve, reject) => {
            NetworkEquipmentParametersService.getParameterProfile(equipmentId, parameterId)
                .then(profile => {
                    commit("SET_PARAMETER_PROFILE", profile);
                    resolve(profile)
                })
                .catch(error => reject(error))
        })

    }
}

export const getters = {
    getEquipmentsParametersList: state => {
        return state.equipmentsList.map(item => item.parameters.map(parameter => {
            return {
                ...parameter,
                equipmentName: item.name
            }
        })).flat();
    }
}