<template>
  <div class="additional-option-wrapper"
       :class="{'always-open': isCollapseVisible}">
    <div class="additional-option additional-option--no-icon">
      <div class="additional-option__name">
        <span>Нормы расходования материалов</span>
        <span class="additional-option__quantity">{{ workConsumptionRatesList.length }}</span>
      </div>
      <div class="additional-option__buttons">
        <button class="additional-option__add"
                type="button"
                @click="showModal()">
          <icon-plus/>
        </button>
        <button @click="isCollapseVisible = !isCollapseVisible"
                class="additional-option__toggle"
                :class="{'rotate': isCollapseVisible}">
          <icon-arrow-double-up />
        </button>
      </div>
    </div>
    <div class="additional-options-list">
      <div v-for="consumptionRate in workConsumptionRatesList"
           :key="consumptionRate.id"
           class="additional-options-list__inner">
        <div class="added-item consumption-rate">
          <div class="added-item__info">
            <div class="added-item__name">{{ consumptionRate.consumable.name }}</div>
          </div>
          <div class="consumption-rate__amount">
            <span>Количество</span>
            {{consumptionRate.amount}}
          </div>
          <div class="added-item__right">
            <div @click="showModal(consumptionRate)">
              <icon-pencil class="svg added-item__tool added-item__tool--size-20"/>
            </div>
            <div @click="deleteConsumptionRate(consumptionRate.id)">
              <icon-trash class="svg added-item__tool"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconTrash from "@/components/Icons/IconTrash.vue";
import IconArrowDoubleUp from "@/components/Icons/IconArrowDoubleUp.vue";
import IconPlus from "@/components/Icons/IconPlus.vue";
import IconPencil from "@/components/Icons/IconPencil.vue";
import {showConfirmationModal, showAlert, showToast} from "@/utils/notification";
import WorkConsumptionRatesForm from "@/components/ServiceDesk/admin/Works/WorkConsumptionRatesForm.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import {mapState} from "vuex";

export default {
  name: "WorkConsumptionRates",
  components: {IconPencil, IconPlus, IconArrowDoubleUp, IconTrash},
  props: {
    workId: {
      type: Number,
      required: true
    },
    workConsumptionRatesList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isCollapseVisible: false
    }
  },
  computed: {
    ...mapState({
      consumablesList: state => state.consumables.consumables
    })
  },
  mounted() {
    this.$store.dispatch('consumables/getConsumablesList');
  },
  methods: {
    showModal(consumptionRate = {}) {
      this.$modal.show(
        WorkConsumptionRatesForm,
        {
          'consumptionRate': consumptionRate,
          'consumablesList': this.consumablesList,
          'createConsumptionRate': this.createConsumptionRate,
          'updateConsumptionRate': this.updateConsumptionRate
        },
        {
          name: 'consumption-rate-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        }
      )
    },
    createConsumptionRate(payload) {
      this.$store.dispatch('workConsumptionRates/createWorkConsumptionRate', {
        workId: this.workId,
        payload: payload
      })
        .then(() => {
          showToast('Норма расходования материала добавлена', 'success');
          this.$store.dispatch('works/getWorkConsumptionRates', this.workId);
          this.$modal.hide('consumption-rate-form');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    updateConsumptionRate(consumptionRateId, payload) {
      this.$store.dispatch('workConsumptionRates/updateWorkConsumptionRate', {
        workId: this.workId,
        rateId: consumptionRateId,
        payload: payload
      })
        .then(() => {
          showToast('Норма расходования материала обновлена', 'success');
          this.$store.dispatch('works/getWorkConsumptionRates', this.workId);
          this.$modal.hide('consumption-rate-form');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    deleteConsumptionRate(consumptionRateId) {
      showConfirmationModal('Вы уверены, что хотите удалить норму расходования материала?')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('workConsumptionRates/deleteWorkConsumptionRate', {
              workId: this.workId,
              rateId: consumptionRateId
            })
              .then(() => {
                showToast('Норма расходования материала удалена', 'success');
                this.$store.commit('works/DELETE_WORK_CONSUMPTION_RATE', consumptionRateId);
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/helpers/mixins";

.consumption-rate {
  display: grid;
  grid-template-columns: 38.43% 38.43% 1fr;

  &__amount {
    display: flex;
    gap: 8px;

    @include font-style(400, 14px, 16px, var(--text-primary));

    & span {
      color: var(--text-secondary);
    }
  }

  & .added-item__right {
    justify-content: flex-end;
  }
}
</style>
