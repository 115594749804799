import NetworkEquipmentsService from "@/services/NetworkEquipmentsService";
import {getError} from "@/utils/helpers";

export const namespaced = true;

export const state = {
    networkEquipments: [],
    networkEquipment: null,
    loading: false,
    error: null
}

export const mutations = {
    SET_NETWORK_EQUIPMENTS(state, equipments) {
        state.networkEquipments = equipments;
    },
    SET_NETWORK_EQUIPMENT(state, equipment) {
        state.networkEquipment = equipment;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    DELETE_NETWORK_EQUIPMENT(state, equipmentId) {
        const index = state.networkEquipments.findIndex(item => item.id === equipmentId);
        state.networkEquipments.splice(index, 1);
    }
}

export const actions = {
    getNetworkEquipments({commit}, filterParams = {}) {
        commit("SET_LOADING", true);
        NetworkEquipmentsService.fetchNetworkEquipments(filterParams)
            .then(equipments => commit("SET_NETWORK_EQUIPMENTS", equipments))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getNetworkEquipment({commit}, equipmentId) {
        return new Promise((resolve, reject) => {
            NetworkEquipmentsService.fetchNetworkEquipment(equipmentId)
                .then(equipment => {
                    commit("SET_NETWORK_EQUIPMENT", equipment);
                    resolve();
                })
                .catch(error => reject(error))
        })
    },
    createNetworkEquipment(context, payload) {
        return new Promise((resolve, reject) => {
            NetworkEquipmentsService.createNetworkEquipment(payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateNetworkEquipment(context, {equipmentId, payload}) {
        return new Promise((resolve, reject) => {
            NetworkEquipmentsService.updateNetworkEquipment(equipmentId, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteNetworkEquipment(context, equipmentId) {
        return new Promise((resolve, reject) => {
            NetworkEquipmentsService.deleteNetworkEquipment(equipmentId)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}

export const getters = {
    getNetworkEquipmentById: state => id => {
        const equipment = state.networkEquipments.find(item => item.id === parseInt(id));
        return equipment ? equipment : null
    },
    getNetworkEquipmentName: (state, getters) => id => {
        const equipment = getters.getNetworkEquipmentById(id)
        return equipment ? equipment.name : null
    },
    getNetworkEquipmentParameters: (state, getters) => id => {
        const equipment = getters.getNetworkEquipmentById(id);
        return equipment ? equipment.parameters : []
    },
    getNetworkEquipmentsParametersByNetworkObjectId: state => networkObject => {
        if (networkObject) {
            const equipments = state.networkEquipments
                .filter(item => item.networkObjectId === parseInt(networkObject.id));
            return equipments.map(item => item.parameters.map(parameter => {
                return {
                    ...parameter,
                    equipmentName: item.name
                }
            })).flat();
        } else return [];
    }
}