<template>
  <div class="current-datetime">
    <span>{{currentDate}}</span>
    <span>{{currentTime}}</span>
  </div>
</template>

<script>
export default {
  name: "BaseCurrentTime",
  data() {
    return {
      timer: null,
      currentTime: null,
      currentDate: null
    };
  },
  mounted() {
    this.setCurrentTime();
    this.setCurrentDate();

    this.timer = setInterval(() => {
      this.setCurrentTime();
      this.setCurrentDate();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    setCurrentDate() {
      this.currentDate = this.$moment().lang('en').format('DD MMM');
    },
    setCurrentTime() {
      this.currentTime = this.$moment().format('HH:mm');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.current-datetime {
  display: flex;
  gap: 16px;

  font-family: DS-DIGI, sans-serif;
  @include font-style(400, 26px, 28px, var(--text-primary));
}
</style>