<template>
  <svg width="24"
       height="24"
       viewBox="0 0 24 24"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M19.976 17.064C19.976 17.352 19.912 17.648 19.776 17.936C19.64 18.224 19.464 18.496 19.232
    18.752C18.84 19.184 18.408 19.496 17.92 19.696C17.44 19.896 16.92 20 16.36 20C15.544 20 14.672 19.808
    13.752 19.416C12.832 19.024 11.912 18.496 11 17.832C10.0705 17.1521 9.19335 16.4033 8.376 15.592C7.56701
    14.7776 6.82088 13.9031 6.144 12.976C5.488 12.064 4.96 11.152 4.576 10.248C4.192 9.336 4 8.464 4 7.632C4
    7.088 4.096 6.568 4.288 6.088C4.48 5.6 4.784 5.152 5.208 4.752C5.72 4.248 6.28 4 6.872 4C7.096 4 7.32 4.048
    7.52 4.144C7.728 4.24 7.912 4.384 8.056 4.592L9.912 7.208C10.056 7.408 10.16 7.592 10.232 7.768C10.304
    7.936 10.344 8.104 10.344 8.256C10.344 8.448 10.288 8.64 10.176 8.824C10.072 9.008 9.92 9.2 9.728
    9.392L9.12 10.024C9.032 10.112 8.992 10.216 8.992 10.344C8.992 10.408 9 10.464 9.016 10.528C9.04 10.592
    9.064 10.64 9.08 10.688C9.224 10.952 9.472 11.296 9.824 11.712C10.184 12.128 10.568 12.552 10.984
    12.976C11.416 13.4 11.832 13.792 12.256 14.152C12.672 14.504 13.016 14.744 13.288 14.888C13.328 14.904
    13.376 14.928 13.432 14.952C13.496 14.976 13.56 14.984 13.632 14.984C13.768 14.984 13.872 14.936 13.96
    14.848L14.568 14.248C14.768 14.048 14.96 13.896 15.144 13.8C15.328 13.688 15.512 13.632 15.712
    13.632C15.864 13.632 16.024 13.664 16.2 13.736C16.376 13.808 16.56 13.912 16.76 14.048L19.408
    15.928C19.616 16.072 19.76 16.24 19.848 16.44C19.928 16.64 19.976 16.84 19.976 17.064Z"
          stroke=""
          stroke-width="1.5"
          stroke-miterlimit="10"/>
    <path d="M17.2004 9.6C17.2004 9.12 16.8244 8.384 16.2644 7.784C15.7524 7.232 15.0724 6.8 14.4004
    6.8M20.0004 9.6C20.0004 6.504 17.4964 4 14.4004 4"
          stroke=""
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  name: "IconCall"
}
</script>
