<template>
  <div class="page-wrapper">
    <base-panel
      title="Типы сетевого оборудования"
      :is-add-button-visible="true"
      @add-button-clicked="showPopup()"
    />

    <base-functional-line
      @search-change="event => $store.commit('networkEquipmentTypes/SET_SEARCH_QUERY', event.target.value)"
    />

    <network-equipment-types-list
      v-if="networkEquipmentTypes.length"
      :network-equipment-types-list="networkEquipmentTypes"
      @delete-network-equipment-type="deleteNetworkEquipmentType"
    />

    <base-content-loaders v-if="loading" />

    <base-no-data v-if="!networkEquipmentTypes.length && !loading" />
  </div>
</template>

<script>
import NetworkEquipmentTypesList from "@/components/Telemetry/admin/NetworkEquipmentTypesList";
import {mapGetters, mapState} from "vuex";
import BasePanel from "@/components/BasePanel.vue";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import {showAlert, showToast, showConfirmationModal} from "@/utils/notification";
import NetworkEquipmentTypesForm from "@/components/Telemetry/admin/NetworkEquipmentTypesForm.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import BaseFunctionalLine from "@/components/BaseFunctionalLine.vue";
export default {
  name: "NetworkEquipmentTypesListPage",
  components: {BaseFunctionalLine, BaseNoData, BaseContentLoaders, BasePanel, NetworkEquipmentTypesList},
  computed: {
    ...mapState({
      loading: state => state.networkEquipmentTypes.loading
    }),
    ...mapGetters({
      networkEquipmentTypes: 'networkEquipmentTypes/getNetworkEquipmentTypesByQuery'
    })
  },
  mounted() {
    this.$store.dispatch('networkEquipmentTypes/getNetworkEquipmentTypes');
  },
  methods: {
    showPopup() {
      this.$modal.show(
        NetworkEquipmentTypesForm,
        {
          'createNetworkEquipmentType': this.createNetworkEquipmentType
        },
        {
          name: 'network-equipment-type-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        })
    },
    createNetworkEquipmentType(payload) {
      this.$store.dispatch('networkEquipmentTypes/createNetworkEquipmentType', payload)
        .then(() => {
          this.$store.dispatch('networkEquipmentTypes/getNetworkEquipmentTypes');
          this.$modal.hide('network-equipment-type-form');
          showToast('Тип сетевого оборудования добавлен', 'success');
        })
        .catch(error => showAlert('Ошибка', error));
    },
    deleteNetworkEquipmentType(typeId) {
      showConfirmationModal('Вы уверены, что хотите удалить тип сетевого оборудования? Это действие невозвратно.')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('networkEquipmentTypes/deleteNetworkEquipmentType', typeId)
              .then(() => {
                this.$store.commit('networkEquipmentTypes/DELETE_NETWORK_EQUIPMENT_TYPE', typeId);
                showToast('Тип сетевого оборудования удалён', 'success')
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style scoped>

</style>