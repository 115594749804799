<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="24"
       height="24"
       viewBox="0 0 24 24"
       fill="none">
    <path
      d="M6 3V21M6 4.8H16.08C18.51 4.8 19.05 6.15 17.34 7.86L16.26 8.94C15.54 9.66 15.54 10.83 16.26
       11.46L17.34 12.54C19.05 14.25 18.42 15.6 16.08 15.6H6"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconFlag"
}
</script>

<style scoped>

</style>
