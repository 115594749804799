<template>
  <div class="base-statistic-cards">
    <div class="base-statistic-cards__card">
      <img class="base-statistic-cards__image"
           src="@/assets/img/icons/clipboard.svg"
           alt="Блокнот">
      <div class="base-statistic-cards__content">
        <span class="base-statistic-cards__quantity">{{all}}</span>
        <span class="base-statistic-cards__name">Всего поступило</span>
      </div>
    </div>
    <div class="base-statistic-cards__card">
      <img class="base-statistic-cards__image"
           src="@/assets/img/icons/note.svg"
           alt="Блокнот">
      <div class="base-statistic-cards__content">
        <span class="base-statistic-cards__quantity">{{open}}</span>
        <span class="base-statistic-cards__name">Открыты</span>
      </div>
    </div>
    <div class="base-statistic-cards__card">
      <img class="base-statistic-cards__image"
           src="@/assets/img/icons/clipboard-tick.svg"
           alt="Блокнот">
      <div class="base-statistic-cards__content">
        <span class="base-statistic-cards__quantity">{{close}}</span>
        <span class="base-statistic-cards__name">Закрыты</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseStatisticCards',
  props: {
    all: {
      type: Number,
      default: 0
    },
    open: {
      type: Number,
      default: 0
    },
    close: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/viriables";
@import "@/assets/scss/helpers/mixins";

.base-statistic-cards {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;
  margin-bottom: 32px;

  @media (max-width: $media-breakpoint-lg) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  &__card {
    display: flex;
    padding: 24px;
    align-items: center;
    gap: 24px;
    flex: 1 0 0;

    border-radius: 16px;
    border: 1px solid var(--border-main);

    background: var(--panel);
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
  }

  &__quantity {
    @include font-style(500, 36px, 48px, var(--text-primary));
  }

  &__name {
    @include font-style(400, 16px, 24px, var(--text-secondary));
  }
}
</style>