import * as API from './API'
import {getError} from "@/utils/helpers";
import Appeal from "@/models/callCenter/Appeal";

export default {
    createAppeal(payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post('call-center/appeals', payload)
                .then(response => {
                    resolve(response.data.data.id);
                })
                .catch(error => reject(getError(error)))
        })

    },
    getAppeal(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`call-center/appeals/${id}`)
                .then(response => {
                    const {data: {data}} = response;
                    resolve(new Appeal(data.id, data.attributes))
                })
                .catch(error => reject(getError(error)));
        })
    },
    getAppeals(filter, page) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`call-center/appeals`, {params: {...filter, page: page}})
                .then(response => {
                    resolve(
                        {
                            appeals: response.data.data.map(appeal => {
                                return new Appeal(appeal.id, appeal.attributes)
                            }),
                            meta: response.data.meta,
                            links: response.data.links
                        }
                    )
                })
                .catch(error => reject(getError(error)));
        })
    },
    getAllAppeals(params) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`call-center/appeals/all`, {params: params})
                .then(response => resolve(response.data.data
                    .map(appeal => new Appeal(appeal.id, appeal.attributes))))
                .catch(error => reject(getError(error)));
        })
    },
    getAppealsByStatus(status) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`call-center/appeals/all?appeal_status=${status}`)
                .then(response => resolve(response.data.data.map(appeal => new Appeal(appeal.id, appeal.attributes))))
                .catch(error => reject(getError(error)));
        })
    },
    changeAppealAddress(id, payload) {
        return API.apiClient.patch(`call-center/appeals/${id}/address`, payload);
    },
    changeAppealApplicant(id, payload) {
        return API.apiClient.patch(`call-center/appeals/${id}/applicant`, payload);
    },
    cancelAppeal(id) {
        return API.apiClient.post(`call-center/appeals/${id}/cancel`);
    },
    linkAppeal(payload) {
        return API.apiClient.post(`call-center/appeals/${payload.appeal_id}/link-issue`, payload.params);
    },
    unlinkAppeal(appeal_id) {
        return API.apiClient.post(`call-center/appeals/${appeal_id}/unlink-issue`);
    },
    changeAppealStructuralUnit(appealId, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/call-center/appeals/${appealId}/change-structural-unit`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateAppeal(appealId, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/call-center/appeals/${appealId}`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}
