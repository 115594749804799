import Exceljs from "exceljs";
import {saveAs} from 'file-saver'

export const getExcel = async (
    rows,
    isAutoResize = false,
    workBookName = 'Отчет',
    worksheetName = 'Отчет',
    wrapTextColumns = null
) => {
    const workBook = new Exceljs.Workbook;
    const worksheet = workBook.addWorksheet(worksheetName);

    for (let i = 0; i < rows.length; i++) {
        worksheet.addRow(rows[i]);
    }

    if (wrapTextColumns) {
        wrapTextColumns.forEach(column => {
            worksheet.getColumn(column).eachCell(cell => cell.alignment = {wrapText: true})
        })
    }

    if (isAutoResize) {
        worksheet.columns.forEach(function (column) {
            let maxLength = 0;
            column["eachCell"]({includeEmpty: true}, function (cell) {
                let columnLength = cell.value ? cell.value.toString().length : 10;
                if (columnLength > maxLength) {
                    maxLength = columnLength;
                }
            });
            column.width = maxLength < 6 ? 6 : maxLength;
        });
    }

    workBook.xlsx.writeBuffer().then(function (data) {
        let blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"})
        saveAs(blob, `${workBookName}.xlsx`)
    })
}
