<template>
  <div
    class="map-marker">
    <map-icon-bg/>
    <div class="map-marker__inner">
      <div class="map-marker__img map-marker__img--leak">
        <icon-drop/>
      </div>
    </div>
  </div>
</template>

<script>
import MapIconBg from "@/components/Icons/MapIconBg";
import IconDrop from "@/components/Icons/IconDrop";
export default {
  name: "LeakIcon",
  components: {IconDrop, MapIconBg}
}
</script>

<style scoped>

</style>
