<template>
  <apexchart
    width="100%"
    height="auto"
    type="donut"
    :options="options"
    :series="pieChartData"
  />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "PieChart",
  components: {
    'apexchart': VueApexCharts
  },
  props: ['pieChartData', 'width'],
  data () {
    return {
      options: {
        legend: {
          show: false
        },
        colors: ['#468FC5', '#E02800'],
        grid: {
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            colors: ['#fff']
          },
          dropShadow: {
            enabled: true,
            top: 0,
            left: 0,
            blur: 1,
            color: '#000',
            opacity: 0.9
          }
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              size: '60%'
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>