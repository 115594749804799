<template>
  <div class="page-wrapper">
    <base-panel
      title="Структурные подразделения"
      :is-add-button-visible="false"
    />

    <base-functional-line @search-change="setSearchQuery">
      <div class="structural-unit-view">
        <div class="structural-unit-view__wrapper">
          <div class="functional-line__view-buttons">
            <div
              @click="isListView = true"
              :class="{'active': isListView}">
              <icon-hamburger-menu/>
            </div>
            <div
              @click="isListView = false"
              :class="{'active': !isListView}">
              <icon-data />
            </div>
          </div>
          <div @click="openPopup()"
               class="button button--mode-primary structural-unit-view__add-btn">
            <icon-plus/>
            <span>Добавить</span>
          </div>
        </div>
      </div>
    </base-functional-line>

    <structural-units-list
      v-if="isListView"
      :structural-unit-list="structuralUnits"
      @update-structural-unit="openPopup"
      @delete-structural-unit="deleteStructuralUnit"
    />

    <structural-units-flow-chart
      v-else
    />

    <base-content-loaders v-if="loading && !structuralUnits.length"/>

    <base-no-data v-if="!loading && !structuralUnits.length"/>
  </div>
</template>

<script>
import StructuralUnitsList from "@/components/admin/StructuralUnitsList";
import {mapGetters, mapState} from "vuex";
import StructuralUnitsFlowChart from "@/components/admin/StructuralUnitsFlowChart";
import BasePanel from "@/components/BasePanel.vue";
import BaseFunctionalLine from "@/components/BaseFunctionalLine.vue";
import IconPlus from "@/components/Icons/IconPlus.vue";
import IconHamburgerMenu from "@/components/Icons/IconHamburgerMenu.vue";
import IconData from "@/components/Icons/IconData.vue";
import {showAlert, showToast, showConfirmationModal} from "@/utils/notification";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import StructuralUnitsForm from "@/components/admin/StructuralUnitsForm.vue";

export default {
  name: "StructuralUnitsListPage",
  components: {
    BaseNoData,
    BaseContentLoaders,
    IconData,
    IconHamburgerMenu,
    IconPlus,
    BaseFunctionalLine,
    BasePanel,
    StructuralUnitsFlowChart,
    StructuralUnitsList
  },
  data() {
    return {
      isListView: true,
      popupName: 'structural-unit-form'
    }
  },
  computed: {
    ...mapState({
      loading: state => state.structuralUnits.loading
    }),
    ...mapGetters({
      structuralUnits: 'structuralUnits/getStructuralUnitByQuery'
    })
  },
  mounted() {
    this.$store.dispatch('structuralUnits/getStructuralUnitsList');
  },
  methods: {
    openPopup(structuralUnit = {}) {
      this.$modal.show(
        StructuralUnitsForm,
        {
          'structuralUnit': structuralUnit,
          'createStructuralUnit': this.createStructuralUnit,
          'updateStructuralUnit': this.updateStructuralUnit,
          'popupName': this.popupName,
          'structuralUnitsList': this.structuralUnits
        },
        {
          name: this.popupName,
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        }
      )
    },
    createStructuralUnit(payload) {
      this.$store.dispatch('structuralUnits/createStructuralUnit', payload)
        .then(() => {
          this.$store.dispatch('structuralUnits/getStructuralUnitsList');
          this.$modal.hide(this.popupName);
          showToast('Структурное подразделение добавлено', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    updateStructuralUnit(structuralUnitId, payload) {
      this.$store.dispatch('structuralUnits/updateStructuralUnit', {
        id: structuralUnitId, payload
      })
        .then(() => {
          this.$store.dispatch('structuralUnits/getStructuralUnitsList');
          this.$modal.hide(this.popupName);
          showToast('Структурное подразделение обновлено', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    deleteStructuralUnit(id) {
      showConfirmationModal('Вы уверены, что хотите удалить структурное подразделение? Это действие невозвратно.')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('structuralUnits/deleteStructuralUnit', id)
              .then(() => {
                showToast('Структурное подразделение удалено', 'success');
                this.$store.dispatch('structuralUnits/getStructuralUnitsList');
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    },
    setSearchQuery(event) {
      this.$store.commit('structuralUnits/SET_QUERY', event.target.value);
    }
  }
}
</script>

<style scoped>
.structural-unit-view__wrapper {
  display: flex;
  gap: 12px;
}
</style>