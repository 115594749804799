<template>
  <div class="defect-list__filter">
    <div class="filter__button tasks-filter__button"
         :class="{'opened': isFilterModalVisible}"
         @click="isFilterModalVisible = true">
      <icon-settings2/>
      <span>Фильтры</span>
    </div>

    <base-filter-modal
      :is-visible="isFilterModalVisible"
      @toggle-modal="isVisible => this.isFilterModalVisible = isVisible"
    >
      <div class="filter__modal-header">
        <div class="filter__modal-name">Фильтры</div>
        <div @click="resetFilters()"
             class="filter__modal-reset">Сбросить все
        </div>
      </div>
      <div class="filter__modal-form">
        <div>
          <label class="input-label">Тип</label>
          <v-select
            class="vue-select-input"
            v-model="defectTypeFilter"
            :options="defectTypesList"
            :reduce="type => type.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите из списка"
          />
        </div>
      </div>

      <div class="filter__modal-buttons filter__modal-buttons--single">
        <div @click="isFilterModalVisible = false"
             class="button button--mode-outline">Закрыть
        </div>
      </div>
    </base-filter-modal>
  </div>
</template>

<script>
import IconSettings2 from "@/components/Icons/IconSettings2.vue";
import BaseFilterModal from "@/components/BaseFilterModal.vue";
import {mapState} from "vuex";

export default {
  name: "DefectsFilter",
  components: {BaseFilterModal, IconSettings2},
  data() {
    return {
      isFilterModalVisible: false
    }
  },
  computed: {
    ...mapState({
      defectTypesList: state => state.defects.defectTypesList,
      defectTypeFilterState: state => state.defects.defectTypeFilter
    }),
    defectTypeFilter: {
      get() {
        return this.defectTypeFilterState
      },
      set(value) {
        this.$store.commit('defects/SET_DEFECT_TYPE_FILTER', value)
      }
    }
  },
  methods: {
    resetFilters() {
      this.$store.commit('defects/SET_DEFECT_TYPE_FILTER', null);

      this.isFilterModalVisible = false;
    }
  }
}
</script>

<style scoped>
.defect-list__filter {
  position: relative;
}
</style>