import TaskConsumptionsService from "@/services/TaskConsumptionsService";

export const namespaced = true;

export const state = {
    loading: false
}

export const mutations = {
    SET_LOADING(state, loading) {
        state.loading = loading;
    }
}

export const actions = {
    createTaskConsumptions({commit}, {taskId, payload}) {
        commit('SET_LOADING', true);
        return new Promise((resolve, reject) => {
            TaskConsumptionsService.createTaskConsumptions(taskId, payload)
                .then(() => resolve())
                .catch(error => reject(error))
                .finally(() => commit("SET_LOADING", false))
        })
    },
    deleteTaskConsumption({commit}, {taskId, consumptionId}) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            TaskConsumptionsService.deleteTaskConsumption(taskId, consumptionId)
                .then(() => resolve())
                .catch(error => reject(error))
                .finally(() => commit("SET_LOADING", false))
        })
    }
}
