<template>
  <div class="base-table">
    <div class="base-table__container">
      <div class="base-table__header base-table__column--network-equipment-type">
        <div class="base-table__header-cell">ID</div>
        <div class="base-table__header-cell">Наименование</div>
        <div class="base-table__header-cell"/>
      </div>

      <div v-for="type in networkEquipmentTypesList"
           :key="type.id"
           class="base-table__row base-table__column--network-equipment-type">
        <div class="base-table__column">
          <div class="base-table__text">{{ type.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ type.name }}</div>
        </div>
        <div class="base-table__column base-table__column--content-right">
          <div class="base-table__short-info">
            <div @click="$emit('delete-network-equipment-type', type.id)">
              <icon-trash />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconTrash from "@/components/Icons/IconTrash.vue";

export default {
  name: "NetworkEquipmentTypesList",
  components: {IconTrash},
  props: {
    networkEquipmentTypesList: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.base-table__column--network-equipment-type {
  grid-template-columns: 1fr 10fr 1fr;
}
</style>