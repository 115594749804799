<template>
  <div class="task-modal__actions">
    <div v-if="issueStateId === IssueState.NEW"
         @click="doIssueAction('issue/cancelIssue', 'Заявка отменена')"
         class="button button--mode-outline button--mode-outline-text-danger">
      <span>Отменить</span>
    </div>

    <div v-if="issueStateId === IssueState.IN_WORK"
         @click="doIssueAction('issue/closeIssue', 'Заявка завершена')"
         class="button button--mode-primary">
      <span>Завершить</span>
    </div>
  </div>
</template>

<script>
import {showAlert, showToast} from "@/utils/notification";
import IssueState from "@/models/serviceDesk/IssueState";

export default {
  name: "IssueStateActions",
  props: {
    issueId: {
      type: Number,
      required: true
    },
    issueStateId: {
      type: Number,
      required: true
    }
  },
  computed: {
    IssueState: () => IssueState
  },
  methods: {
    doIssueAction(action, successMessage) {
      this.$store.dispatch(action, this.issueId)
        .then(() => {
          showToast(successMessage, 'success');
          this.$store.dispatch('issueModal/getIssue', this.issueId);
          this.$store.dispatch('issueModal/getIssueHistory', this.issueId);
        })
        .catch(error => {
          showAlert('Ошибка', error, 'error');
        })
    }
  }
}
</script>

<style scoped>
.task-modal__actions .button {
  flex: 1 1 0;
}
</style>
