<template>
  <div v-if="reportData.length"
       class="custom-card">
    <div class="card-body">
      <div class="chart-title">Переходящие заявки</div>
      <apexchart
        type="line"
        :options="chartData.options"
        :series="chartSeries"
        height="400px"
      />
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "TransitIssues",
  components: {'apexchart': VueApexCharts},
  props: {
    timePeriod: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapState({
      reportData: state => state.reportsData.reportData
    }),
    chartData() {
      return {
        options: {
          chart: {
            type: 'bar',
            fontFamily: 'Lato',
            foreColor: '#9192AD'
          },
          colors: ['#6375D4', '#27D8AD', '#E19421', '#FF3A81'],
          dataLabels: {
            enabled: false
          },
          legend: {
            position: 'top',
            fontSize: '12px'
          },
          grid: {
            borderColor: 'rgba(0, 0, 0, 0.20)',
            strokeDashArray: 2,
            position: 'back'
          },
          xaxis: {
            categories: this.reportData.map(report => this.getChartDate(report.date)),
            labels: {
              show: true,
              rotateAlways: true
            }
          }
        }
      }
    },
    chartSeries() {
      const series = {
        opened: {
          name: 'Поступило',
          data: []
        },
        closed: {
          name: 'Завершено',
          data: []
        },
        transit: {
          name: 'Остаток на начало дня',
          data: []
        },
        countEndPeriod: {
          name: 'Остаток на конец дня',
          data: []
        }
      };
      for (let report of this.reportData) {
        series.opened.data.push(report.count_opened);
        series.closed.data.push(report.count_closed);
        series.transit.data.push(report.count_transit);
        series.countEndPeriod.data.push(report.count_end_period);
      }
      return Object.values(series);
    }
  },
  methods: {
    getRussianMonth(date) {
      const russianMonths = ['Янв','Фев','Мар','Апр','Май','Июн','Июл','Авг','Сен','Окт','Ноя','Дек'];
      const currentMonthIndex = new Date(date).getMonth();
      return russianMonths[currentMonthIndex] ?? date;
    },
    getChartDate(date) {
      return this.timePeriod > 30 ? this.getRussianMonth(date) : date;
    }
  }
}
</script>

<style lang="scss" scoped>
.chart-title {
  font-size: 22px;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: auto;
}
</style>
