<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      Протяженность задействованной сети
      <span @click="$modal.hide('network-spread-form')"
            class="base-popup__close">
        <icon-close/>
      </span>
    </div>
    <div class="popup-form-group">
      <div class="form-item">
        <div class="form-item__name">Протяженность, м</div>
        <div class="form-item__value">
          <input
            v-model="$v.payload.spread.$model"
            class="base-input base-input--bordered"
            :class="{'base-input--error': $v.payload.spread.$error}"
            type="text"
            name="material"
            placeholder="Укажите протяженность в метрах">
        </div>
      </div>
    </div>
    <div class="base-popup__footer">
      <div
        v-show="isActionsVisible"
        @click="submitForm()"
        class="button">
        Сохранить
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from "@/components/Icons/IconClose";
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import {showAlert, showToast} from "@/utils/notification";

const aboveZero = value => value > 0;

export default {
  name: "NetworkSpreadForm",
  components: {IconClose},
  mixins: [validationMixin],
  props: {
    networkSpread: {
      type: Object,
      required: true
    },
    taskId: {
      type: Number,
      required: true
    },
    isModalOpened: {
      type: Boolean,
      required: true
    },
    isActionsVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      payload: {
        spread: null
      }
    }
  },
  validations: {
    payload: {
      spread: {required, aboveZero}
    }
  },
  mounted() {
    this.fillFields();
  },
  methods: {
    fillFields() {
      if (Object.keys(this.networkSpread).some(item => item === 'spread')) {
        this.payload.spread = this.networkSpread.spread;
      }
    },
    submitForm() {
      this.$v.$touch()
      if (this.$v.$invalid) return false

      this.$store.dispatch('networkSpread/updateNetworkSpread', {
        taskId: this.taskId,
        payload: this.payload
      })
        .then(() => {
          this.$store.dispatch('taskModal/getNetworkSpread', this.taskId);
          showToast('Протяженность задействованной сети сохранена', 'success');
          this.$modal.hide('network-spread-form');
        })
        .catch(error => showAlert('Ошибка', error, 'error'))
    }
  }
}
</script>

<style scoped>

</style>
