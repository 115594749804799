<script>
export default {
  name: "IconRain"
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M19.5002 20H3.50024L2.00024 17L3.50024 13.5L15.9999 12L19.5002 20Z" fill="#DBEAEE"/>
    <path d="M19.5002 19.499C24.5002 15.999 21.75 10.0099 17.44 9.46995C15.9 0.129949 2.42998 3.66995 5.61998 12.5599" fill="#DBEAEE"/>
    <path d="M19.5002 19.499C24.5002 15.999 21.75 10.0099 17.44 9.46995C15.9 0.129949 2.42998 3.66995 5.61998 12.5599" stroke="#1AB9EB" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.28011 12.9701C6.75011 12.7001 6.16011 12.5601 5.57011 12.5701C1.75441 12.8403 1.06979 17.435 3.49976 19.2722" stroke="#1AB9EB" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.8201 9.88998C16.3401 9.62998 16.9001 9.48998 17.4801 9.47998L15.8201 9.88998Z" fill="#DBEAEE"/>
    <path d="M15.8201 9.88998C16.3401 9.62998 16.9001 9.48998 17.4801 9.47998" stroke="#1AB9EB" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 20L7 23" stroke="#468FC5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17 18L13 22" stroke="#468FC5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9 16L5 20" stroke="#468FC5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.97 16L11.97 18" stroke="#468FC5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<style scoped>

</style>