import DepartmnetsService from "@/services/DepartmnetsService";
import {getError} from "@/utils/helpers";

export const namespaced = true;

export const state = {
    brigades: [],
    meta: null,
    loading: false,
    error: null
}

export const mutations = {
    SET_BRIGADES(state, brigades) {
        state.brigades = brigades;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    DELETE_BRIGADE(state, brigadeId) {
        const index = state.brigades.findIndex(item => item.id === brigadeId);
        state.brigades.splice(index, 1);
    }
}

export const actions = {
    getDepartmentBrigadesList({commit}, {department_id, page}) {
        commit("SET_LOADING", true);
        DepartmnetsService.fetchDepartmentBrigadesList(department_id, page)
            .then(({brigades, meta}) => {
                commit("SET_BRIGADES", brigades);
                commit("SET_META", meta);
            })
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getAllDepartmentBrigades({commit}, department_id) {
        DepartmnetsService.fetchAllDepartmentBrigades(department_id)
            .then(brigades => commit("SET_BRIGADES", brigades))
            .catch(error => commit("SET_ERROR", error));
    },
    createDepartmentBrigade(context, {department_id, payload}) {
        return new Promise((resolve, reject) => {
            DepartmnetsService.createDepartmentBrigade(department_id, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateDepartmentBrigade(context, {department_id, brigade_id, payload}) {
        return new Promise((resolve, reject) => {
            DepartmnetsService.updateDepartmentBrigade(department_id, brigade_id, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteDepartmentBrigade({commit}, {department_id, brigade_id}) {
        return new Promise((resolve, reject) => {
            DepartmnetsService.deleteDepartmentBrigade(department_id, brigade_id)
                .then(() => {
                    commit("DELETE_BRIGADE", brigade_id);
                    resolve();
                })
                .catch(error => reject(getError(error)))
        })
    }
}

export const getters = {
    getBrigadeById: state => brigadeId => {
        return state.brigades.find(item => item.id === parseInt(brigadeId));
    }
}
