import WaterFlowsService from "@/services/WaterFlowsService";
import {getError} from "@/utils/helpers";

export const namespaced = true;

export const state = {
    taskWaterFlows: [],
    loading: false,
    error: null
}

export const mutations = {
    SET_TASK_WATER_FLOWS(state, waterFlows) {
        state.taskWaterFlows = waterFlows;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    DELETE_WATER_FLOW(state, waterFlowId) {
        const index = state.taskWaterFlows.findIndex(item => item.id === waterFlowId);
        state.taskWaterFlows.splice(index, 1);
    }
}

export const actions = {
    getTaskWaterFlows({commit}, taskId) {
        commit("SET_LOADING", true);
        WaterFlowsService.fetchTaskWaterFlowsList(taskId)
            .then(waterFlows => commit("SET_TASK_WATER_FLOWS", waterFlows))
            .catch(error => commit("SET_ERROR", error))
    },
    addWaterFlow(context, {taskId, payload}) {
        return new Promise((resolve, reject) => {
            WaterFlowsService.addWaterFlow(taskId, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    removeWaterFlow(context, {taskId, waterFlowId}) {
        return new Promise((resolve, reject) => {
            WaterFlowsService.removeWaterFlow(taskId, waterFlowId)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}