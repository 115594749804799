<script>
export default {
  name: "IconBook3"
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M2.91666 15.0001V5.83341C2.91666 2.50008 3.74999 1.66675 7.08332 1.66675H12.9167C16.25 1.66675 17.0833 2.50008 17.0833 5.83341V14.1667C17.0833 14.2834 17.0833 14.4001 17.075 14.5167" stroke="#9192AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.29166 12.5H17.0833V15.4167C17.0833 17.025 15.775 18.3333 14.1667 18.3333H5.83332C4.22499 18.3333 2.91666 17.025 2.91666 15.4167V14.875C2.91666 13.5667 3.98332 12.5 5.29166 12.5Z" stroke="#9192AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.66666 5.83325H13.3333" stroke="#9192AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.66666 8.75H10.8333" stroke="#9192AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<style scoped>

</style>