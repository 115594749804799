<template>
  <div class="base-modal-info__card">
    <div class="base-modal-info__card-title base-modal-info__card-title--loader">
      {{formTitle}}
      <base-loader v-if="loadingHistory"/>
    </div>
    <div class="transport-history">
      <date-picker
        v-model="historyFrom"
        :default-value="defaultValue"
        placeholder="Начало периода"
        type="datetime"
        format="DD.MM.YYYY, HH:mm"
        value-type="YYYY-MM-DD HH:mm"
        class="base-date-picker"
      />
      <div class="transport-history__dash"/>
      <date-picker
        v-model="historyTo"
        :default-value="defaultValue"
        placeholder="Конец периода"
        type="datetime"
        format="DD.MM.YYYY, HH:mm"
        value-type="YYYY-MM-DD HH:mm"
        class="base-date-picker"
        :disabled-date="disableDaily"
        :disabled-time="disableHours"
      />
      <button
        @click="getVehicleMovementHistory()"
        :disabled="loadingHistory"
        class="button button--mode-primary transport-history__button">Показать
      </button>
    </div>
    <div
      v-if="isEmptyData"
      class="transport-history__error">История перемещения в указанный период не найдена.</div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import BaseLoader from "@/layout/BaseLoader.vue";
import {mapState} from "vuex";

export default {
  name: "TransportMovementHistoryForm",
  components: {BaseLoader, DatePicker},
  props: {
    formTitle: {
      type: String,
      default: 'История передвижения'
    },
    vehicleId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      defaultValue: new Date(),
      historyFrom: null,
      historyTo: null,
      loadingHistory: false,
      isEmptyData: false
    }
  },
  computed: {
    ...mapState({
      movementHistoryFrom: state => state.autos.movementHistoryFrom,
      movementHistoryTo: state => state.autos.movementHistoryTo
    })
  },
  mounted() {
    this.historyFrom = this.movementHistoryFrom;
    this.historyTo = this.movementHistoryTo;
  },
  methods: {
    getVehicleMovementHistory() {
      this.loadingHistory = true;

      let from = this.$moment(this.historyFrom).format('YYYY-MM-DD HH:mm:ss');
      let to = this.$moment(this.historyTo).format('YYYY-MM-DD HH:mm:ss');

      this.$store.dispatch('autos/getMovementHistory', {from, to, id: this.vehicleId})
        .then(movementHistory => {
          this.$emit('get-movement-history')
          this.$store.commit('autos/SET_MOVEMENT_HISTORY_FROM', from);
          this.$store.commit('autos/SET_MOVEMENT_HISTORY_TO', to);

          this.isEmptyData = !movementHistory.length;
        })
        .finally(() => {
          this.loadingHistory = false;
        })
    },
    disableDaily(date) {
      let from = new Date(this.historyFrom);
      let h = from.getHours();
      let m = from.getMinutes();
      let d1 = date;
      return d1.setHours(h, m) < from || date > new Date(from.getTime() + 86400000);
    },
    disableHours(date) {
      let from = new Date(this.historyFrom);
      let d1 = new Date(date);
      return d1 < from || date > new Date(from.getTime() + 86500000);
    }
  }
}
</script>

<style lang="scss">
.transport-history {
  display: flex;
  align-items: center;
  gap: 24px;

  & .base-date-picker {
    width: 250px;
  }

  &__dash {
    height: 1px;
    width: 12px;
    background-color: var(--bg-secondary);
  }

  &__button {
    flex: 1;
  }

  &__error {
    margin-top: 16px;
    color: var(--border-validation);
  }
}

.transport-end-maker {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--bg-sidebar);
}
</style>
