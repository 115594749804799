import moment from "moment";

export const formatDateTime = date => {
    if (date) return moment(date).format(process.env.VUE_APP_BASE_DATE_TIME_FORMAT);
    else return '-'
}

export const formatDate = date => {
    if (date) return moment(date).format('DD.MM.YYYY');
    else return '-'
}

export const nullFilter = value => {
    return value ? value : '—';
}