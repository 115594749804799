<template>
  <svg width="22"
       height="16"
       viewBox="0 0 22 16"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M3.91 7.83998C8.21 4.51998 13.8 4.51998 18.1 7.83998M1 4.35998C7.06 -0.320024
    14.94 -0.320024 21 4.35998M5.79 11.49C8.94 9.04998 13.05 9.04998 16.2 11.49M8.4 15.15C9.98
    13.93 12.03 13.93 13.61 15.15"
          stroke=""
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  name: "IconWifi"
}
</script>
