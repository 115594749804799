import {getError} from "@/utils/helpers";
import AutosService from "@/services/AutosService";

export const namespaced = true;

export const state = {
    stat: [],
    loading: false,
    error: null
};

export const mutations = {
    SET_STAT(state, stat) {
        state.stat = stat;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
};

export const actions = {
    statData({commit}) {
        commit("SET_LOADING", true);
        AutosService.getWorkDayStat()
            .then(response => {
                commit("SET_STAT", response.data.data);
                commit("SET_LOADING", false);
            })
            .catch(error => {
                commit("SET_LOADING", false);
                commit("SET_ERROR", getError(error));
            });
    }
};

export const getters = {
    getFuelCons: state => id => {
        if (state.stat != null){
            let s = state.stat.find(d => d.id == id);
            if (s != null){
                return s.attributes.avg_fuel;
            } else {
                return 0
            }

        }else{
            return 0
        }
    },
    getMileage: state => id => {
        if (state.stat != null){
            let s = state.stat.find(d => d.id == id);
            if (s != null){
                return s.attributes.mileage;
            } else {
                return 0
            }
        }else{
            return 0
        }
    },
    getEngineHours: state => id => {
        if (state.stat != null){
            let s = state.stat.find(d => d.id == id);
            if (s != null){
                return s.attributes.duration;
            } else {
                return 0
            }
        }else{
            return 0
        }
    },
    loading: state => {
        return state.loading;
    },
    error: state => {
        return state.error;
    }
}
