import * as API from './API';
import {getError} from "@/utils/helpers";
import NetworkSpread from "@/models/serviceDesk/NetworkSpread";

export default {
    fetchNetworkSpread(taskId) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/tasks/${taskId}/network-spreads`)
                .then(response => {
                    const {id, attributes} = response.data.data;
                    resolve(new NetworkSpread({id, ...attributes}))
                })
                .catch(error => reject(getError(error)))
        })
    },
    updateTaskNetworkSpread(taskId, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`/service-desk/tasks/${taskId}/network-spreads`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}
