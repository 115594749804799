import * as API from './API';
import Defect from "@/models/serviceDesk/Defect";
import {getError} from "@/utils/helpers";

export default {
    fetchDefectsList() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/defects`)
                .then(response => resolve(response.data.data
                    .map(item => new Defect({id: item.id, ...item.attributes}))))
                .catch(error => reject(getError(error)))
        })
    },
    fetchDefect(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/defects/${id}`)
                .then(response => {
                    const {id, attributes} = response.data.data;
                    resolve(new Defect({id, ...attributes}))
                })
                .catch(error => reject(getError(error)));
        })
    },
    createDefect(payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`service-desk/defects`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    updateDefect(id, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`service-desk/defects/${id}`, payload)
               .then(() => resolve())
               .catch(error => reject(getError(error)));
        })
    },
    deleteDefect(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`service-desk/defects/${id}`)
               .then(() => resolve())
               .catch(error => reject(getError(error)));
        })
    },
    fetchDefectTypes() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/defects/types`)
                .then(response => resolve(response.data.data.map(item => new Defect(item))))
                .catch(error => reject(getError(error)));
        })
    }
}