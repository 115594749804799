<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      <template v-if="!Object.keys(balanceSheet).length">Добавить балансовую принадлежность</template>
      <template v-else>Редактирование балансовой принадлежности</template>
      <span @click="$modal.hide('balance-sheet-form')"
            class="base-popup__close">
        <icon-close/>
      </span>
    </div>
    <div class="popup-form-group">
      <div class="form-item">
        <div class="form-item__name">Наименование</div>
        <div class="form-item__value">
          <input
            v-model="payload.name"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите наименование"
            required
          >
        </div>
      </div>
    </div>
    <div class="base-popup__footer">
      <div @click="checkFormType()"
           class="button">
        Сохранить
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from "@/components/Icons/IconClose.vue";

export default {
  name: "BalanceSheetsForm",
  components: {IconClose},
  props: {
    balanceSheet: {
      type: Object,
      default: () => {}
    },
    createBalanceSheet: {
      type: Function,
      default: () => {}
    },
    updateBalanceSheet: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      payload: {
        name: null
      }
    }
  },
  mounted() {
    if (Object.keys(this.balanceSheet).length) {
      const {name} = this.balanceSheet;
      this.payload.name = name;
    }
  },
  methods: {
    checkFormType() {
      if (Object.keys(this.balanceSheet).length) this.updateBalanceSheet(this.balanceSheet.id, this.payload)
      else this.createBalanceSheet(this.payload)
    }
  }
}
</script>

<style scoped>

</style>