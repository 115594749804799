import IssueService from "@/services/IssueService";

export const namespaced = true;

export const state = {
    issues: [],
    page: null,
    filterParams: null,
    meta: null,
    links: null,
    loading: false,
    error: null
}

export const mutations = {
    SET_ISSUES(state, issues) {
        state.issues = issues;
    },
    SET_FILTER_PARAMS(state, params) {
        state.filterParams = params;
    },
    SET_PAGE(state, page) {
        state.page = page;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getIssues({ commit, state }, page) {
        commit("SET_LOADING", true);
        IssueService.fetchIssuesListItems(state.filterParams, page)
            .then(({issues, meta, links}) => {
                commit("SET_ISSUES", issues);
                commit("SET_META", meta);
                commit("SET_LINKS", links);
            })
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    }
}

export const getters = {
    getIssues: state => {
        return state.issues;
    }
}