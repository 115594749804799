import {getError} from "@/utils/helpers";
import EmployeeService from "@/services/EmployeeService";

export const namespaced = true

export const state = {
    staff: [],
    meta: null,
    links: null,
    loading: false,
    error: null
}

export const mutations = {
    SET_STAFF(state, staff) {
        state.staff = staff;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    DELETE_EMPLOYEE(state, uuid) {
        let index = state.staff.findIndex(employee => employee.uuid == uuid)
        state.staff.splice(index, 1)
    }
}

export const actions = {
    getStaff({commit}) {
        commit("SET_LOADING", true);
        EmployeeService.getStaff()
            .then(response => {
                commit("SET_LOADING", false);
                commit('SET_STAFF', response.data.data)
            })
            .catch(error => {
                commit("SET_LOADING", false);
                commit("SET_ERROR", getError(error));
            })
    }
}

export const getters = {
    getStaff: state => {
        return state.staff
    },
    getEmployee: state => uuid => {
        return state.staff.find(employee => employee.uuid == uuid);
    },
    getEmployeeName: state => id => {
        return state.staff.find(employee => employee.id == id).attributes.name
    },
    meta: state => {
        return state.meta;
    },
    links: state => {
        return state.links;
    },
    loading: state => {
        return state.loading;
    },
    error: state => {
        return state.error;
    }
}