import {getError} from "@/utils/helpers";
import AutosService from "@/services/AutosService";
import Vehicle from "@/models/transport/Vehicle";
import VehicleState from "@/models/transport/VehicleState";

export const namespaced = true;

export const state  = {
    autos: [],
    auto: {},
    page: null,
    movement_history: [],
    movementHistoryFrom: null,
    movementHistoryTo: null,
    display_type: 1,
    meta: null,
    links: null,
    loading: false,
    error: null,
    isMarkersLabelVisible: false,
    vehicleTypes: [
        Vehicle.PASSENGER_VEHICLE,
        Vehicle.TRUCK_VEHICLE,
        Vehicle.SPECIAL_VEHICLE,
        Vehicle.WATER_CARRIER
    ],
    vehicleState: [VehicleState.ACTIVE, VehicleState.IN_REPAIR],
    vehicleEngineStatus: false,
    vehicleInMotion: false,
    sorting: 'id.asc',
    searchQuery: ''
};

export const mutations = {
    SET_AUTOS(state, autos) {
        state.autos = autos;
    },
    CONCAT_AUTOS(state, autos) {
        state.autos = state.autos.concat(autos)
    },
    SET_AUTO(state, autoId) {
        state.auto = state.autos.find(auto => auto.id === autoId);
    },
    SET_VEHICLE_TYPES(state, types) {
        state.vehicleTypes = types;
    },
    SET_VEHICLE_STATES(state, states) {
        state.vehicleState = states;
    },
    SET_VEHICLE_ENGINE_STATUS(state, status) {
        state.vehicleEngineStatus = status;
    },
    SET_VEHICLE_IN_MOTION(state, motion) {
        state.vehicleInMotion = motion;
    },
    SET_SORTING(state, sorting) {
        state.sorting = sorting;
    },
    SET_SEARCH_QUERY(state, query) {
        state.searchQuery = query;
    },
    TOGGLE_MARKERS_LABEL_VISIBLE(state) {
        state.isMarkersLabelVisible = !state.isMarkersLabelVisible;
    },
    SET_MOVEMENT_HISTORY_FROM(state, date) {
        state.movementHistoryFrom = date;
    },
    SET_MOVEMENT_HISTORY_TO(state, date) {
        state.movementHistoryTo = date;
    },
    UPDATE_AUTOS_STATUS(state, params) {
        let auto = state.autos.find(item => item.id === params.vehicle);
        auto.attributes.state.id = params.status.id
        auto.attributes.state.name = params.status.name
    },
    SET_PAGE(state, page) {
        state.page = page;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_MOVEMENT_HISTORY(state, movement_history) {
        state.movement_history = movement_history;
    },
    DELETE_AUTOS(state, id){
        let index = state.autos.findIndex(auto => auto.id == id);
        state.autos.splice(index, 1);
    },
    SET_DISPLAY_TYPE(state, type) {
        state.display_type = type;
    },
    CLEAR_MOVEMENT_HISTORY(state) {
        state.movement_history = []
    },
    CLEAR_AUTOS(state){
        state.autos = []
    }
};

export const actions = {
    getAutos({ commit, state }) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            AutosService.getAutos(state.page)
                .then(({autos, meta}) => {
                    commit("SET_AUTOS", autos);
                    commit("SET_META", meta);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", getError(error));
                    reject();
                })
                .finally(() => commit("SET_LOADING", false))
        })
    },
    getAuto({commit}, autoId) {
        commit("SET_AUTO", autoId);
    },
    getAllAutos({ commit }, filterParams) {
        commit("SET_LOADING", true);
        AutosService.getAllAutos(filterParams)
            .then(vehicles => commit("SET_AUTOS", vehicles))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false))
    },
    getMovementHistory({commit}, payload) {
        return new Promise((resolve, reject) => {
            AutosService.doYouKnowTheWay(payload.from, payload.to, payload.id)
                .then(response => {
                    commit('SET_MOVEMENT_HISTORY', response.data.data);
                    resolve(response.data.data);
                })
                .catch(error => {
                    commit('SET_ERROR', getError(error))
                    reject();
                })
        })
    },
    sendVehicleToRepair(context, id) {
        return new Promise((resolve, reject) => {
            AutosService.sendVehicleToRepair(id)
                .then(() => {
                    context.commit('UPDATE_AUTOS_STATUS',
                        {
                            vehicle: id,
                            status: {
                                id: 2,
                                name: 'В ремонте'
                            }
                        })
                    resolve()
                })
                .catch(error => reject(getError(error)))
        })
    },
    makeVehicleAvailable(context, id) {
        return new Promise((resolve, reject) => {
            AutosService.makeVehicleAvailable(id)
                .then(() => {
                    context.commit('UPDATE_AUTOS_STATUS',
                        {
                            vehicle: id,
                            status: {
                                id: 1,
                                name: 'Доступно'
                            }
                        })
                    resolve()
                })
                .catch(error => reject(getError(error)))
        })
    },
    createAuto(context, payload) {
        return new Promise((resolve, reject) => {
            AutosService.createAuto(payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    updateAuto(context, {autoId, payload}) {
        return new Promise((resolve, reject) => {
            AutosService.updateAuto(autoId, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    deleteAuto(context, autoId) {
        return new Promise((resolve, reject) => {
            AutosService.deleteAuto(autoId)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    }
};

export const getters = {
    getAutos: state => {
        return state.autos
    },
    getAuto: state => id =>{
        return state.autos.find(auto => auto.id == id);
    },
    getFilteredAndSortedAutos: (state, getters) => {
        let {autos} = state;

        autos = getters.filterAutosByType(autos, state.vehicleTypes);
        autos = getters.filterAutosByState(autos, state.vehicleState);
        autos = getters.filterAutosByEngineStatus(autos, state.vehicleEngineStatus);
        autos = getters.filterAutosByMotion(autos, state.vehicleInMotion);
        autos = getters.searchAutos(autos, state.searchQuery);
        autos = getters.sortAutos(autos, state.sorting);

        return autos;
    },
    filterAutosByType: () => (autos, types) => {
        return autos.filter(auto => types.includes(auto.attributes.type));
    },
    filterAutosByState: () => (autos, states) => {
        return autos.filter(auto => states.includes(auto.attributes.state.id));
    },
    filterAutosByEngineStatus: (state, getters, rootState) => (autos, engineStatus) => {
        return engineStatus ? autos.filter(auto => {
            const autoData = rootState.transportData.transportData
                .find(data => data.id === parseInt(auto.id))
            return autoData?.attributes.engine
        }) : autos
    },
    filterAutosByMotion: (state, getters, rootState) => (autos, motion) => {
        return motion ? autos.filter(auto => {
            const autoData = rootState.transportData.transportData
                .find(data => data.id === parseInt(auto.id))
            return autoData?.attributes.speed > 0
        }) : autos
    },
    sortAutos: (state, getters, rootState) => (autos, sortParams) => {
        if (sortParams === 'id.asc') return autos.sort((a, b) => a.id - b.id)
        else if (sortParams === 'id.desk') return autos.sort((a, b) => b.id - a.id)
        else return autos.sort((a,b) => {
            const aData = rootState.transportData.transportData
                .find(data => data.id === parseInt(a.id));
            const bData = rootState.transportData.transportData
                .find(data => data.id === parseInt(b.id));

            if (aData && bData) {
                if (sortParams === 'speed.asc')  return bData.attributes.speed - aData.attributes.speed;
                else if (sortParams === 'speed.desk')return aData.attributes.speed - bData.attributes.speed;
            } return 0
        });
    },
    searchAutos: () => (autos, query) => {
        return query.length ? autos.filter(auto =>
            auto.attributes.label.toLowerCase().includes(query.toLowerCase()) ||
            auto.attributes.gov_number.toLowerCase().includes(query.toLowerCase()))
            : autos
    },
    autos: state => {
        if (state == null) {
            return [];
        } else {
            return state.autos;
        }
    },
    getMovementHistory: state => {
        return state.movement_history;
    },
    meta: state => {
        return state.meta;
    },
    links: state => {
        return state.links;
    },
    loading: state => {
        return state.loading;
    },
    error: state => {
        return state.error;
    }
}
