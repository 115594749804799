<script>
export default {
  name: "IconBuildingColored"
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="24"
       height="24"
       viewBox="0 0 24 24"
       fill="none">
    <g clip-path="url(#clip0_1054_36498)">
      <path d="M19.7424 23.9864H4.17953L4.16504 1.0957H19.7279L19.7424 23.9864Z"
            fill="#468FC5"/>
      <path d="M19.7288 1.0957H17.5693L17.5838 23.9864H19.7433L19.7288 1.0957Z"
            fill="#468FC5"/>
      <path
        d="M13.8732 23.9857H10.0361V16.7662C10.0361 16.6299 10.1466 16.5195 10.2827 16.5195H13.6265C13.7627 16.5195
        13.8731 16.6299 13.8731 16.7662L13.8732 23.9857Z"
        fill="#A4D9F6"/>
      <path
        d="M8.36028 19.7671H6.01381C5.93554 19.7671 5.87207 19.7036 5.87207 19.6253V16.6613C5.87207 16.5831 5.93554
        16.5195 6.01381 16.5195H8.36028C8.43856 16.5195 8.50204 16.583 8.50204 16.6613V19.6253C8.50204 19.7036 8.43856
        19.7671 8.36028 19.7671Z"
        fill="#A4D9F6"/>
      <path
        d="M17.8935 19.7671H15.5471C15.4687 19.7671 15.4053 19.7036 15.4053 19.6253V16.6613C15.4053 16.5831 15.4687
        16.5195 15.5471 16.5195H17.8935C17.9718 16.5195 18.0353 16.583 18.0353 16.6613V19.6253C18.0353 19.7036 17.9718
        19.7671 17.8935 19.7671Z"
        fill="#A4D9F6"/>
      <path
        d="M20.5075 0H3.39941C3.34115 0 3.29395 0.0472031 3.29395 0.105468V1.31335C3.29395 1.37161 3.34115 1.41881
        3.39941 1.41881H20.5075C20.5656 1.41881 20.6129 1.37161 20.6129 1.31335V0.105468C20.613 0.0472031 20.5656
        0 20.5075 0Z"
        fill="#A4D9F6"/>
      <path
        d="M8.36028 13.0659H6.01381C5.93554 13.0659 5.87207 13.0025 5.87207 12.9242V9.96015C5.87207 9.88191 5.93554
        9.81836 6.01381 9.81836H8.36028C8.43856 9.81836 8.50204 9.88182 8.50204 9.96015V12.9242C8.50204 13.0025 8.43856
        13.0659 8.36028 13.0659Z"
        fill="#A4D9F6"/>
      <path
        d="M13.1279 13.0659H10.7814C10.7031 13.0659 10.6396 13.0025 10.6396 12.9242V9.96015C10.6396 9.88191 10.7031
        9.81836 10.7814 9.81836H13.1279C13.2062 9.81836 13.2697 9.88182 13.2697 9.96015V12.9242C13.2696 13.0025 13.2062
        13.0659 13.1279 13.0659Z"
        fill="#A4D9F6"/>
      <path
        d="M17.8935 13.0659H15.5471C15.4687 13.0659 15.4053 13.0025 15.4053 12.9242V9.96015C15.4053 9.88191 15.4687
        9.81836 15.5471 9.81836H17.8935C17.9718 9.81836 18.0353 9.88182 18.0353 9.96015V12.9242C18.0353 13.0025 17.9718
        13.0659 17.8935 13.0659Z"
        fill="#A4D9F6"/>
      <path
        d="M8.36028 6.90375H6.01381C5.93554 6.90375 5.87207 6.84027 5.87207 6.76195V3.79799C5.87207 3.71972 5.93554
        3.65625 6.01381 3.65625H8.36028C8.43856 3.65625 8.50204 3.71972 8.50204 3.79799V6.76195C8.50204 6.84023 8.43856
        6.90375 8.36028 6.90375Z"
        fill="#A4D9F6"/>
      <path
        d="M13.1279 6.90375H10.7814C10.7031 6.90375 10.6396 6.84027 10.6396 6.76195V3.79799C10.6396 3.71972 10.7031
        3.65625 10.7814 3.65625H13.1279C13.2062 3.65625 13.2697 3.71972 13.2697 3.79799V6.76195C13.2696 6.84023 13.2062
        6.90375 13.1279 6.90375Z"
        fill="#A4D9F6"/>
      <path
        d="M17.8935 6.90375H15.5471C15.4687 6.90375 15.4053 6.84027 15.4053 6.76195V3.79799C15.4053 3.71972 15.4687
        3.65625 15.5471 3.65625H17.8935C17.9718 3.65625 18.0353 3.71972 18.0353 3.79799V6.76195C18.0353 6.84023 17.9718
        6.90375 17.8935 6.90375Z"
        fill="#A4D9F6"/>
      <path
        d="M7.14823 22.9845C7.13586 22.285 6.60186 21.7129 5.91852 21.6409C5.60141 21.1342 5.03863 20.7969 4.39672
        20.7969C3.40583 20.7969 2.60254 21.6002 2.60254 22.591C2.60254 22.7263 2.61796 22.8578 2.64632 22.9844L7.14823
        22.9845Z"
        fill="#262F3D"/>
      <path
        d="M16.7588 22.9845C16.7712 22.285 17.3052 21.7129 17.9885 21.6409C18.3056 21.1342 18.8684 20.7969 19.5102
        20.7969C20.5012 20.7969 21.3045 21.6002 21.3045 22.591C21.3045 22.7263 21.289 22.8578 21.2607 22.9844L16.7588
        22.9845Z"
        fill="#262F3D"/>
      <path
        d="M12.8805 18.9844C12.686 18.9844 12.5283 19.142 12.5283 19.3366V20.0644C12.5283 20.2589 12.686 20.4166
        12.8805 20.4166C13.075 20.4166 13.2327 20.2589 13.2327 20.0644V19.3366C13.2327 19.142 13.075 18.9844 12.8805
        18.9844Z"
        fill="#262F3D"/>
      <path
        d="M20.5626 0.015625H18.4541V1.41882H20.5081C20.5664 1.41882 20.6135 1.37162 20.6135 1.31336V0.105484C20.6135
        0.0672812 20.593 0.0341405 20.5626 0.015625Z"
        fill="#A4D9F6"/>
      <path
        d="M22.197 22.7793H1.71099C1.37387 22.7793 1.10059 23.0526 1.10059 23.3898C1.10059 23.7268 1.37387 24.0001
        1.71099 24.0001H22.197C22.5341 24.0001 22.8073 23.7268 22.8073 23.3898C22.8073 23.0526 22.5341 22.7793 22.197
        22.7793Z"
        fill="#262F3D"/>
      <path
        d="M22.6923 23.7455C22.6978 23.7379 22.7033 23.7303 22.7084 23.7224C22.7135 23.7143 22.7184 23.7061 22.7232
        23.6978C22.7281 23.6897 22.733 23.6815 22.7374 23.6731C22.7419 23.6646 22.7458 23.6559 22.7498 23.6472C22.7539
        23.6385 22.7581 23.6299 22.7617 23.6209C22.7654 23.612 22.7685 23.6029 22.7716 23.5938C22.7749 23.5846 22.7783
        23.5756 22.7812 23.5662C22.784 23.557 22.7861 23.5474 22.7885 23.5379C22.7909 23.5284 22.7935 23.5191 22.7954
        23.5094C22.7975 23.4993 22.7987 23.489 22.8001 23.4788C22.8015 23.4693 22.8033 23.46 22.8043 23.4505C22.8063
        23.4306 22.8073 23.4103 22.8073 23.3898C22.8073 23.3732 22.8056 23.3572 22.8043 23.341V23.3289C22.804 23.3258
        22.8033 23.3229 22.803 23.3198C22.8022 23.3123 22.8008 23.3049 22.7997 23.2975C22.7973 23.2817 22.7944 23.2661
        22.7908 23.2508C22.7891 23.2433 22.7873 23.2358 22.7853 23.2284C22.7804 23.2101 22.7745 23.1923 22.7679
        23.1747C22.7666 23.1715 22.7657 23.1679 22.7644 23.1646C22.7564 23.1443 22.747 23.1246 22.7368 23.1053C22.7357
        23.1033 22.7351 23.1012 22.7339 23.0992C22.7338 23.0994 22.734 23.099 22.7339 23.0992C22.6307 22.9085 22.4291
        22.7793 22.197 22.7793H20.6448C20.6448 23.1326 20.3585 23.419 20.0052 23.419H1.71098C1.47904 23.419 1.27734
        23.2896 1.17407 23.0992C1.12012 23.1987 1.09298 23.3149 1.10244 23.438C1.12719 23.7588 1.4076 24 1.7293
        24H20.6448H22.197C22.2174 24 22.2376 23.999 22.2575 23.9971C22.2643 23.9964 22.2708 23.995 22.2773
        23.9942C22.2905 23.9924 22.3036 23.9908 22.3165 23.9882C22.3243 23.9867 22.3318 23.9846 22.3394 23.9827C22.3509
        23.98 22.3622 23.9774 22.3733 23.9739C22.3814 23.9715 22.3892 23.9685 22.3971 23.9658C22.4074 23.9622 22.4179
        23.9587 22.4279 23.9545C22.436 23.9513 22.4437 23.9475 22.4516 23.9439C22.4612 23.9396 22.4708 23.9351 22.48
        23.9301C22.4879 23.9261 22.4953 23.9217 22.503 23.9173C22.5119 23.9121 22.5207 23.9069 22.5292 23.9013C22.5367
        23.8964 22.544 23.8913 22.5512 23.8861C22.5594 23.8802 22.5674 23.8743 22.5753 23.868C22.5824 23.8625 22.5893
        23.8567 22.5961 23.8508C22.6036 23.8443 22.6109 23.8377 22.6181 23.8309C22.6247 23.8245 22.6312 23.8181 22.6375
        23.8115C22.6442 23.8045 22.6508 23.7972 22.6572 23.7898C22.6632 23.7829 22.6693 23.7758 22.6751 23.7686C22.681
        23.7612 22.6867 23.7534 22.6923 23.7455Z"
        fill="#262F3D"/>
      <path
        d="M8.35976 3.65625H7.18652V6.90375H8.35976C8.43804 6.90375 8.5015 6.84027 8.5015 6.76195V3.79799C8.5015 3.71972
        8.43804 3.65625 8.35976 3.65625Z"
        fill="#A4D9F6"/>
      <path
        d="M13.1274 3.65625H11.9541V6.90375H13.1274C13.2057 6.90375 13.2691 6.84027 13.2691 6.76195V3.79799C13.2692
        3.71972 13.2057 3.65625 13.1274 3.65625Z"
        fill="#A4D9F6"/>
      <path
        d="M17.8949 3.65625H16.7217V6.90375H17.8949C17.9732 6.90375 18.0367 6.84027 18.0367 6.76195V3.79799C18.0367
        3.71972 17.9732 3.65625 17.8949 3.65625Z"
        fill="#A4D9F6"/>
      <path
        d="M8.35976 9.81836H7.18652V13.0658H8.35976C8.43804 13.0658 8.5015 13.0024 8.5015 12.9241V9.96015C8.5015
        9.88191 8.43804 9.81836 8.35976 9.81836Z"
        fill="#A4D9F6"/>
      <path
        d="M13.1274 9.81836H11.9541V13.0658H13.1274C13.2057 13.0658 13.2691 13.0024 13.2691 12.9241V9.96015C13.2692
        9.88191 13.2057 9.81836 13.1274 9.81836Z"
        fill="#A4D9F6"/>
      <path
        d="M17.8949 9.81836H16.7217V13.0658H17.8949C17.9732 13.0658 18.0367 13.0024 18.0367 12.9241V9.96015C18.0367
        9.88191 17.9732 9.81836 17.8949 9.81836Z"
        fill="#A4D9F6"/>
      <path
        d="M8.35976 16.5195H7.18652V19.767H8.35976C8.43804 19.767 8.5015 19.7036 8.5015 19.6252V16.6612C8.5015 16.583
        8.43804 16.5195 8.35976 16.5195Z"
        fill="#A4D9F6"/>
      <path
        d="M17.8949 16.5195H16.7217V19.767H17.8949C17.9732 19.767 18.0367 19.7036 18.0367 19.6252V16.6612C18.0367
        16.583 17.9732 16.5195 17.8949 16.5195Z"
        fill="#A4D9F6"/>
    </g>
    <defs>
      <clipPath id="clip0_1054_36498">
        <rect width="24"
              height="24"
              fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped>

</style>