<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      <template v-if="isCreateForm">Добавить работу</template>
      <template v-else>Редактирование работы</template>
      <span @click="$modal.hide('work-form')"
            class="base-popup__close">
        <icon-close/>
      </span>
    </div>
    <div class="popup-form-group">
      <div class="form-item">
        <div class="form-item__name">Наименование</div>
        <div class="form-item__value">
          <input
            v-model="payload.name"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите наименование"
            required
          >
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Группа</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.group_id"
            :options="groups"
            :reduce="group => group.id"
            :get-option-label="(option) => option.name"
            class="vue-select-input"
            placeholder="Выберите группу" />
        </div>
      </div>
      <div
        v-if="!isCreateForm"
        class="form-item">
        <label class="base-switcher">
          <input
            v-model="payload.active"
            class="base-switcher__checkbox"
            type="checkbox">
          <div class="base-switcher__switch"/>
          <span class="base-switcher__label">
            Активна
          </span>
        </label>
      </div>
    </div>
    <div class="base-popup__footer">
      <div @click="checkFormType()"
           class="button">
        Сохранить
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from "@/components/Icons/IconClose.vue";
import {mapGetters} from "vuex";
import vSelect from 'vue-select';

export default {
  name: "WorksForm",
  components: {IconClose, vSelect},
  props: {
    work: {
      type: Object,
      required: true
    },
    createWork: {
      type: Function,
      default: () => {}
    },
    updateWork: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      payload: {
        name: null,
        group_id: null
      }
    }
  },
  computed: {
    ...mapGetters({
      groups: 'serviceDeskCategories/getCategoryGroups'
    }),
    isCreateForm() {
      return !Object.keys(this.work).length;
    }
  },
  mounted() {
    if (!this.isCreateForm) {
      const {name, active} = this.work
      this.payload.name = name;
      this.payload.group_id = this.work.group.id;
      this.payload.active = active;
    }
  },
  methods: {
    checkFormType() {
      if (!this.isCreateForm) this.updateWork(this.work.id, this.payload)
      else this.createWork(this.payload)
    }
  }
}
</script>

<style scoped>

</style>
