import ElectricMetersReadingsService from "@/services/ElectricMetersReadingsService";
import moment from "moment";

export const namespaced = true;

export const state = {
    monthReadings: [],
    filterParams: null,
    error: null,
    loading: false
}

export const mutations = {
    SET_MONTH_READINGS(state, monthReadings) {
        state.monthReadings = monthReadings;
    },
    SET_FILTER_PARAMS(state, filterParams) {
        state.filterParams = filterParams;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    }
}

export const actions = {
    getElectricMetersMonthReading({commit, state}) {
        commit("SET_LOADING", true);
        ElectricMetersReadingsService.fetchElectricMeterMonthReadings(state.filterParams)
            .then(monthReadings => commit("SET_MONTH_READINGS", monthReadings))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit('SET_LOADING', false));
    },
    createElectricMeterMonthReading(context, {electricMeterId, payload}) {
        return new Promise((resolve, reject) => {
            ElectricMetersReadingsService.createElectricMonthReading(electricMeterId, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    }
}

export const getters = {
    getElectricMeterMonthReading: state => (electricMeterId, monthNumber) => {
        const meterReading = state.monthReadings
            .find(reading => reading.electricMeterId === electricMeterId
                && moment(reading.readingAt).month() === monthNumber)
        return meterReading?.value
    }
}
