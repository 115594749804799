<template>
  <div class="tabs">
    <div class="tabs__header tabs__header--block">
      <div
        v-for="tab in tabsList"
        :key="tab.id"
        @click="changeActiveTab(tab.id)"
        :class="{'active': tab.active}"
        class="tabs__header-item">
        {{ tab.name }}
      </div>
    </div>

    <div
      class="tabs__item active">
      <div class="base-table base-table--mb-0 base-table--responsive-x">
        <div class="base-table__container">
          <div class="base-table__header base-table--logbook">
            <div class="base-table__header-cell">Объект</div>
            <div class="base-table__header-cell">Оборудование</div>
            <div class="base-table__header-cell">Параметр</div>
            <div
              class="base-table__header-cell base-table__header-cell--center"
              v-for="index in 24"
              :key="`${index}_head_hours`">
              {{ index - 1 + ':00' + '-' + index + ':00' }}
            </div>
          </div>

          <template v-for="networkObject in networkObjects.filter(item => item.typeId === activeTabId)">
            <template v-for="(equipment, equipmentKey) in networkObject.equipments">
              <div
                v-for="(parameter, parameterKey) in equipment.parameters"
                :key="parameter.id"
                class="base-table__row base-table--logbook">

                <div class="base-table__column">
                  <div
                    v-if="equipmentKey === 0 && parameterKey === 0"
                    class="base-table__text">{{ networkObject.name }}
                  </div>
                </div>

                <div class="base-table__column">
                  <div
                    v-if="parameterKey === 0"
                    class="base-table__text">{{ equipment.name }}
                  </div>
                </div>

                <div class="base-table__column">
                  <div class="base-table__text base-table__tooltip-wrapper">
                    {{ parameter.name }} ({{ parameter.short_name }})

                    <div class="base-table__tooltip">
                      <div>Максимальный уровень: {{ parameter.max }}</div>
                      <div>Минимальный уровень: {{ parameter.min }}</div>
                    </div>
                  </div>
                </div>

                <div
                  v-for="hour in 24"
                  :key="`${hour}_hours`"
                  class="base-table__column">
                  <div class="base-table__text">
                    <div class="logbook-input">
                      <input
                        v-mask="parameter.input_mask"
                        :value="parameterValue(parameter.id, hour)"
                        class="parameter__value-input"
                        type="text"
                        placeholder="—"
                        @change="saveParameterValue($event, parameter.id, hour, equipment.id)"
                        @keypress="isNumber($event)"
                      >
                    </div>
                  </div>

                </div>
              </div>
            </template>
          </template>
        </div>
      </div>

      <button
        @click="showToast('Значения сохранены', 'success')"
        class="button button--w-100 button--mode-outline">
        Сохранить значения
      </button>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import Inputmask from "bitweb-inputmask";
import Vue from "vue";
import {showToast} from "@/utils/notification";
import {isNumber} from "@/utils/helpers";

const VueInputMask = {
  install: function (Vue) {
    Vue.directive('mask', {
      bind: function (el, binding) {
        Inputmask(binding.value).mask(el);
      }
    });
  }
}
Vue.use(VueInputMask);

export default {
  name: "LogbookTable",
  props: {
    tabsList: {
      type: Array,
      required: true
    },
    changeActiveTab: {
      type: Function,
      required: true
    }
  },
  computed: {
    ...mapState({
      networkObjects: state => state.logbookPage.networkObjects,
      currentDate: state => state.logbookPage.currentLogbookDate
    }),
    ...mapGetters({
      parameterValue: 'logbookPage/getParameterValueByIdAndHour'
    }),
    isNumber: () => isNumber,
    activeTabId() {
      const tab = this.tabsList.find(item => item.active);
      return tab ? tab.id : 0
    }
  },
  methods: {
    showToast,
    saveParameterValue(event, parameterId, hour, equipmentId) {
      const payload = {
        equipment_id: equipmentId,
        parameter_id: parameterId,
        value: event.target.value.replace(',', '.')
      }
      if (hour === 24) {
        const date = this.$moment(this.currentDate).add(1, 'days');
        payload.reading_at = date.format('YYYY-MM-DD 00:00')
      } else {
        payload.reading_at = this.$moment(this.currentDate).set('hour', hour).format('YYYY-MM-DD HH:00')
      }

      if (event.target.value) this.$store.dispatch('logbookPage/saveParameterValue', payload)
        .catch(error => showToast(error, 'error'));
    }
  }
}
</script>

<style lang="scss" scoped>
.base-table--logbook {
  grid-template-columns: repeat(3, 120px) repeat(24, 120px);
}

.logbook-input {
  & input {
    width: 100%;
    border: none;
    outline: none;
    padding: 12px;
    text-align: center;
    min-width: 120px;
    background-color: var(--panel);
    color: var(--text-primary);

    &:focus {
      border-radius: 16px;
      border: 1px solid var(--border-grey);
      background: var(--panel);
      margin: -6px 0;
    }
  }
}
</style>
