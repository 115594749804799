import * as API from './API';
import Comment from '@/models/serviceDesk/Comment'
import {getError} from "@/utils/helpers";

export default {
    getTaskComments(task_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/tasks/${task_id}/comments`)
                .then(response => resolve(response.data.data
                    .map(comment => new Comment(comment.id, comment.attributes))))
                .catch(error => reject(getError(error)));
        })
    },
    fetchIssueCommentItems(issue_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/issues/${issue_id}/comments`)
                .then(response => resolve(response.data.data
                    .map(comment => new Comment(comment.id, comment.attributes))))
                .catch(error => reject(getError(error)));
        })
    }
}