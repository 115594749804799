import BalanceSheetsService from "@/services/BalanceSheetsService";

export const namespaced = true;

export const state = {
    balanceSheetsList: [],
    balanceSheet: null,
    loading: false,
    error: null
}

export const mutations = {
    SET_BALANCE_SHEETS_LIST(state, balanceSheetsList) {
        state.balanceSheetsList = balanceSheetsList;
    },
    SET_BALANCE_SHEET(state, balanceSheet) {
        state.balanceSheet = balanceSheet;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getBalanceSheetsList({commit}) {
        commit("SET_LOADING", true);
        BalanceSheetsService.fetchBalanceSheetsList()
            .then(balanceSheets => commit("SET_BALANCE_SHEETS_LIST", balanceSheets))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false))
    },
    getBalanceSheet({commit}, id) {
        return new Promise((resolve, reject) => {
            BalanceSheetsService.fetchBalanceSheet(id)
                .then(balanceSheet => {
                    commit("SET_BALANCE_SHEET", balanceSheet)
                    resolve(balanceSheet);
                })
                .catch(error => reject(error))
        })
    },
    createBalanceSheet(context, payload) {
        return new Promise((resolve, reject) => {
            BalanceSheetsService.createBalanceSheet(payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    updateBalanceSheet(context, {id, payload}) {
        return new Promise((resolve, reject) => {
            BalanceSheetsService.updateBalanceSheet(id, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    deleteBalanceSheet(context, id) {
        return new Promise((resolve, reject) => {
            BalanceSheetsService.deleteBalanceSheet(id)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    }
}