import LeaksService from "@/services/LeaksService";
import ShutdownsService from "@/services/ShutdownsService";
import HistoryService from "@/services/HistoryService";
import IssueService from "@/services/IssueService";
import DamagesService from "@/services/DamagesService";

export const namespaced = true;

export const state = {
    issue: {},
    shutdownsList: [],
    damagesList: [],
    leaksList: [],
    history: [],
    loading: false,
    error: null
}

export const mutations = {
    SET_ISSUE(state, issue) {
        state.issue = issue;
    },
    SET_SHUTDOWNS_LIST(state, shutdownsList) {
        state.shutdownsList = shutdownsList;
    },
    SET_DAMAGES_LIST(state, damagesList) {
        state.damagesList = damagesList;
    },
    SET_LEAKS_LIST(state, leaksList) {
        state.leaksList = leaksList;
    },
    SET_HISTORY(state, history) {
        state.history = history;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getIssue({commit}, issueId) {
        return new Promise((resolve, reject) => {
            IssueService.getIssue(issueId)
                .then(issue => {
                    commit("SET_ISSUE", issue);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                })
        })
    },
    async getIssueInfo({commit, dispatch}, issueId) {
        commit("SET_LOADING", true);

        await Promise.all([
            dispatch('getIssue', issueId),
            dispatch('getLeaksList', issueId),
            dispatch('getShutdownsList', issueId),
            dispatch('getIssueHistory', issueId),
            dispatch('getLeaksList', issueId)
        ])
            .finally(() => {
                commit("SET_LOADING", false);
                dispatch('getDamagesList', issueId);
            })
    },
    getShutdownsList({commit}, issueId) {
        return new Promise((resolve, reject) => {
            ShutdownsService.getIssueShutdowns(issueId)
                .then(shutdownsList => {
                    commit("SET_SHUTDOWNS_LIST", shutdownsList);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                })
        })
    },
    getIssueHistory({commit}, issueId) {
        return new Promise((resolve, reject) => {
            HistoryService.getIssueHistory(issueId)
                .then(history => {
                    commit("SET_HISTORY", history);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                })
        })
    },
    getDamagesList({commit}, issueId) {
        return new Promise((resolve, reject) => {
            DamagesService.fetchDamagesList(issueId)
                .then(damagesList => {
                    commit("SET_DAMAGES_LIST", damagesList);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                })
        })
    },
    getLeaksList({commit}, issueId) {
        return new Promise((resolve, reject) => {
            LeaksService.getLeaks(issueId)
                .then(leaksList => {
                    commit("SET_LEAKS_LIST", leaksList);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                })
        })
    }
}

export const getters = {
    getHistoryFirstItem: state => {
        return state.history[0]
    }
}
