<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="16"
       height="17"
       viewBox="0 0 16 17"
       fill="none">
    <path
      d="M14.6692 5.15507V12.6058C14.6692 14.8411 13.5516 16.3312 10.9438 16.3312H4.9832C2.37543 16.3312 1.25781
      14.8411 1.25781 12.6058V5.15507C1.25781 2.91984 2.37543 1.42969 4.9832 1.42969H10.9438C13.5516 1.42969 14.6692
       2.91984 14.6692 5.15507Z"
      stroke="white"
      stroke-width="1.03483"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M9.82738 3.28906V4.77922C9.82738 5.5988 10.4979 6.26937 11.3175 6.26937H12.8077M4.98438 9.62222H7.96468M4.98438
       12.6025H10.945"
      stroke="white"
      stroke-width="1.03483"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconDocumentText"
}
</script>

<style scoped>

</style>
