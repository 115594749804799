<template>
  <svg width="24"
       height="24"
       viewBox="0 0 24 24"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M4.49109 12L9.49113 17.0001L19.5089 7"
          stroke="#21B1BA"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  name: "IconAvailable"
}
</script>
