<template>
  <svg width="17"
       height="25"
       viewBox="0 0 17 25"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M16.6193 4.41742H13.6984C13.4897 4.41742 13.3206 4.58641 13.3206 4.79507C13.3206 5.00374
    13.4897 5.17273 13.6984 5.17273H16.2417V6.9355H13.6984C13.4897 6.9355 13.3206 7.10449 13.3206
    7.31316C13.3206 7.52182 13.4897 7.691 13.6984 7.691H16.2417V9.45359H13.6984C13.4897 9.45359 13.3206
    9.62258 13.3206 9.83124C13.3206 10.0399 13.4897 10.2091 13.6984 10.2091H16.2417V11.9717H13.6984C13.4897
     11.9717 13.3206 12.1409 13.3206 12.3493C13.3206 12.558 13.4897 12.7272 13.6984
     12.7272H16.2417V14.4898H13.6984C13.4897 14.4898 13.3206 14.6589 13.3206 14.8676C13.3206 15.0763 13.4897
      15.2453 13.6984 15.2453H16.6193C16.828 15.2453 16.997 15.0763 16.997 14.8676V4.79507C16.997 4.58641
      16.828 4.41742 16.6193 4.41742Z"
          fill="#A4D9F6"/>
    <path d="M2.64803 17.7881L0.685566 24.5493C0.620144 24.7747 0.789326 25 1.02412 25H1.63027C1.78553 25
    1.92267 24.8983 1.96768 24.7496L3.77623 18.7773L2.64803 17.7881Z"
          fill="#A4D9F6"/>
    <path d="M11.9568 17.7881L13.9195 24.5493C13.9849 24.7747 13.8157 25 13.5807 25H12.9748C12.8193 25 12.6824
    24.8983 12.6374 24.7496L10.8286 18.7773L11.9568 17.7881Z"
          fill="#A4D9F6"/>
    <path d="M12.9938 2.87704H7.35095V0.439453C7.35095 0.196648 7.5476 0 7.7904 0H12.5544C12.797 0 12.9938
    0.196648 12.9938 0.439453V2.87704Z"
          fill="#A4D9F6"/>
    <path d="M3.5248 2.87704H1.51196V0.439453C1.51196 0.196648 1.70861 0 1.95142 0H3.08534C3.32815 0 3.5248
    0.196648 3.5248 0.439453V2.87704Z"
          fill="#A4D9F6"/>
    <path d="M14.0059 17.1879L8.01678 22.4386C7.63684 22.7718 7.06883 22.7718 6.68888 22.4386L0.699806
    17.1879C0.255013 16.798 0 16.2353 0 15.6441V3.15931C0 2.60294 0.450897 2.15204 1.00727 2.15204H13.6984C14.2546
    2.15204 14.7055 2.60294 14.7055 3.15931V15.6439C14.7055 16.2353 14.4507 16.798 14.0059 17.1879Z"
          fill="#262F3D"/>
    <path d="M8.03132 18.3405L11.283 15.4898C11.5057 15.2943 11.6335 15.0124 11.6335 14.716V6.46192C11.6335
    6.08427 11.3274 5.77814 10.9498 5.77814H3.75581C3.37815 5.77814 3.07202 6.08427 3.07202 6.46192V14.716C3.07202
    15.0124 3.2 15.2943 3.42278 15.4898L6.67443 18.3405C7.06258 18.6808 7.64298 18.6808 8.03132 18.3405Z"
          fill="#003A54"/>
    <path d="M11.5584 8.18005C10.4519 8.18005 10.4519 8.79594 9.34548 8.79594C8.23903 8.79594 8.23903 8.18005
    7.13258 8.18005C6.02631 8.18005 6.02631 8.79594 4.91986 8.79594C3.95112 8.79594 3.82981 8.32406 3.07202
    8.20676V14.716C3.07202 15.0124 3.2 15.2943 3.42278 15.4898L6.67443 18.3405C7.06258 18.6808 7.64298 18.6808
    8.03132 18.3405L11.283 15.4898C11.5057 15.2943 11.6335 15.0124 11.6335 14.716V8.1812C11.6089 8.18063 11.5841
     8.18005 11.5584 8.18005Z"
          fill="#468FC5"/>
    <path d="M5.83441 11.1914C5.83441 11.4168 5.6515 11.5995 5.42624 11.5995C5.20079 11.5995 5.01807 11.4168
    5.01807 11.1914C5.01807 10.9659 5.20079 10.7832 5.42624 10.7832C5.6515 10.7832 5.83441 10.9659 5.83441 11.1914Z"
          fill="white"/>
    <path d="M7.96625 13.3878C7.96625 13.6133 7.78352 13.796 7.55807 13.796C7.33282 13.796 7.1499 13.6133 7.1499
    13.3878C7.1499 13.1624 7.33282 12.9797 7.55807 12.9797C7.78352 12.9797 7.96625 13.1624 7.96625 13.3878Z"
          fill="white"/>
    <path d="M9.0406 12.3493C9.0406 12.5747 8.85788 12.7575 8.63242 12.7575C8.40697 12.7575 8.22424 12.5747 8.22424
    12.3493C8.22424 12.1238 8.40697 11.9411 8.63242 11.9411C8.85788 11.9411 9.0406 12.1238 9.0406 12.3493Z"
          fill="white"/>
    <path d="M7.14989 15.6258C7.14989 15.8512 6.96717 16.0339 6.74191 16.0339C6.51646 16.0339 6.33374 15.8512 6.33374
     15.6258C6.33374 15.4005 6.51646 15.2176 6.74191 15.2176C6.96717 15.2176 7.14989 15.4005 7.14989 15.6258Z"
          fill="white"/>
  </svg>

</template>

<script>
export default {
  name: "IconBoiler"
}
</script>

<style scoped>

</style>