import * as API from './API';
import {saveAs} from 'file-saver'
import ArchiveParameterValue from "@/models/telemetry/ArchiveParameterValue";
import {getError} from "@/utils/helpers";

export default {
    getArchive(networkEquipmentId, params, routeGroup){
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/network-equipments/${networkEquipmentId}/${routeGroup}-archive`,
                {params: params})
                .then(response => resolve(response.data.data.map(item => new ArchiveParameterValue(item.attributes))))
                .catch(error => reject(getError(error)))
        })
    },
    getExcel(wb) {
        wb.xlsx.writeBuffer().then(function (data) {
            let blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"})
            saveAs(blob, "Отчет.xlsx")
        })
    }
}