var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrapper"},[_c('base-panel',{attrs:{"title":"Суточный отчет ЕДС","is-add-button-visible":false}}),_c('daily-report-form',{attrs:{"table-rows":_vm.tableRows}}),(_vm.loading)?_c('base-content-loaders'):_vm._e(),(!_vm.reportData.length && !_vm.loading)?_c('div',{staticClass:"no-result"},[_c('img',{attrs:{"src":require("@/assets/img/no-result-2.png"),"alt":"не сформирован отчёт"}}),_c('div',{staticClass:"no-result__text"},[_vm._v(" Сформируйте запрос, чтобы получить данные ")])]):_vm._e(),_vm._l((_vm.categoryGroups),function(categoryGroup){return _c('div',{key:categoryGroup.id,staticClass:"mb-3"},[(_vm.issuesByCategoryGroup(categoryGroup.id).length && !_vm.loading)?_c('div',[_vm._l((_vm.issueTypes),function(type){return _c('div',{key:type.id},[(_vm.issuesByCategoryGroup(type.id).filter(function (item) {
          return item.type_id ? item.type_id === type.id : false
        }).length)?_c('div',[_c('div',{staticClass:"type-title mb-4"},[_vm._v(" "+_vm._s(categoryGroup.name)+" - "+_vm._s(type.name)+" ")]),_c('daily-report-table',{attrs:{"issues":_vm.issuesByCategoryGroup(type.id).filter(function (item) {
              return item.type_id ? item.type_id === type.id : false
            })}})],1):_vm._e()])}),(_vm.issuesWithoutCategory.length)?_c('div',_vm._l((_vm.issueTypes),function(type){return _c('div',{key:type.id},[(_vm.issuesWithoutCategory.filter(function (item) {
            return item.type_id ? item.type_id === type.id : false
          }).length)?_c('div',[_c('div',{staticClass:"type-title mb-4"},[_vm._v(" Другое - "+_vm._s(type.name)+" ")]),_c('daily-report-table',{attrs:{"issues":_vm.issuesWithoutCategory.filter(function (item) {
                return item.type_id ? item.type_id === type.id : false
              })}})],1):_vm._e()])}),0):_vm._e()],2):_vm._e()])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }