export class TechnicalInspectionTaskState {
    static NEW = 1;
    static IN_WORK = 2;
    static REVIEW = 3;
    static FINISHED = 4;
    static CANCELED = 5;

    constructor(id, name) {
        this.id = id;
        this.name = name;
    }

    static getStatesList = () => {
        return [
            {id: this.NEW, name: 'Новое'},
            {id: this.IN_WORK, name: 'В работе'},
            {id: this.REVIEW, name: 'На проверке'},
            {id: this.FINISHED, name: 'Завершено'},
            {id: this.CANCELED, name: 'Отменено'}
        ]
    }
}