import IssueService from "@/services/IssueService";
import {getError} from "@/utils/helpers";

export const namespaced = true

export const state = {
    issue: null,
    loading: false,
    action_loading: false,
    error: null,
    isIssueModalVisible: false
}

export const mutations = {
    SET_ISSUE(state, issue) {
        state.issue = issue;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ACTION_LOADING(state, loading) {
        state.action_loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_ISSUE_MODAL_VISIBLE_STATE(state, value) {
        state.isIssueModalVisible = value;
    }
}

export const actions = {
    getIssue({commit}, id) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            IssueService.getIssue(id)
                .then(issue => {
                    commit("SET_ISSUE", issue);
                    resolve(issue);
                })
                .catch(error => {
                    commit("SET_ERROR", getError(error));
                    reject();
                })
                .finally(() => commit("SET_LOADING", false));
        })
    },
    AddTaskToIssue({commit}, payload) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            IssueService.addTaskToIssue(payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    changePlanning({commit}, {issue_id, payload}) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            IssueService.changePlanning(issue_id, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
                .finally(() => commit("SET_ACTION_LOADING", false))
        })
    },
    cancelIssue({commit}, issue_id) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            IssueService.cancelIssue(issue_id)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    closeIssue({commit}, issue_id) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            IssueService.closeIssue(issue_id)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    postponeIssue({commit}, {issueId, payload}) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            IssueService.postponeIssue(issueId, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    planIssue({commit}, {issueId, payload}) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            IssueService.planIssue(issueId, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    changeIssueCategory({commit}, {issue_id, payload}) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            IssueService.changeIssueCategory(issue_id, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    mergeIssue({commit}, {issue_id, payload}) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            IssueService.mergeIssue(issue_id, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    updateIssue({commit}, {issue_id, payload}) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            IssueService.updateIssue(issue_id, payload)
                .then(() => resolve())
                .catch(error => reject(error))
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    }
}

export const getters = {
    getIssue: state => {
        return state.issue
    },
    getIssueId: state => {
        return state.issue.id;
    }
}
