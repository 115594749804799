<template>
  <div class="base-table">
    <div class="base-table__container">
      <div class="base-table__header base-table__column--zone">
        <div class="base-table__header-cell">ID</div>
        <div class="base-table__header-cell">Sys</div>
        <div class="base-table__header-cell">Наименование</div>
        <div class="base-table__header-cell">Тип</div>
        <div class="base-table__header-cell"/>
      </div>

      <div v-for="zone in zonesList"
           :key="zone.id"
           class="base-table__row base-table__column--zone">
        <div class="base-table__column">
          <div class="base-table__text">{{ zone.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ zone.sys }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ zone.name }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ zone.type.typeLabel }}</div>
        </div>
        <div class="base-table__column base-table__column--content-right">
          <div class="base-table__short-info">
            <div @click="$emit('edit-zone', zone)">
              <icon-pencil class="base-table__short-info--p-2" />
            </div>
            <div @click="$emit('delete-zone', zone.id)">
              <icon-trash />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconTrash from "@/components/Icons/IconTrash.vue";
import IconPencil from "@/components/Icons/IconPencil.vue";

export default {
  name: "ZonesList",
  components: {IconPencil, IconTrash},
  props: {
    zonesList: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.base-table__column--zone {
  grid-template-columns: 80px 80px 1fr 1fr 80px;
}
</style>