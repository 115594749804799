import NetworkElementWithParams from "@/models/EngineeringNetworks/NetworkElementWithParams";

export default class NetworkElementStatistic extends NetworkElementWithParams {
    constructor(params) {
        super(params);

        this.type = params.element_type;
        this.countIncidents = params.count_incidents;
        this.networkElementId = params.network_element;
    }
}
