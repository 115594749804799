<template>
  <svg width="37"
       height="42"
       viewBox="0 0 36 42"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path id="Union"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18 0C8.05888 0 0 8.05888 0 18C0 26.2693 5.57626 33.2362 13.1737 35.3456C14.6868 35.7657
           16.2397 36.4931 16.6982 37.9951L17.5219 40.6935C17.666 41.1656 18.3343 41.1656 18.4783
           40.6935L19.3021 37.9951C19.7605 36.4931 21.3135 35.7657 22.8267 35.3455C30.4239 33.236
            36 26.2692 36 18C36 8.05888 27.9411 0 18 0Z"
          fill="#262F3D"/>
  </svg>

</template>

<script>
export default {
  name: "MapIconBg"
}
</script>

<style scoped>

</style>