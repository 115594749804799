export default class DepartmentBrigade {
    constructor({id, attributes}) {
        this.id = id;
        this.name = attributes.name;
        this.masterId = attributes.master?.id || null;
        this.masterName = attributes.master?.name || null;
        this.departmentId = attributes.department?.id || null;
        this.departmentName = attributes.department?.name || null;
        this.transportId = attributes.transport?.id || null;
        this.transportName = attributes.transport?.name || null;
        this.transportGovNumber = attributes.transport?.gov_number || null;
    }
}