class Polygon {
    constructor(options = {}) {
        this.coords = options.coords;
        this.color = options.color || '#d6d6d6';
        this.fill = options.fill || '#d6d6d6';
        this.fillOpacity = options.fillOpacity || 0.45;
        this.weight = options.weight || 1;
        this.popup_content = options.popup_content || null;
    }
}

export default Polygon