import Consumable from "@/models/serviceDesk/Consumable";

export default class TaskConsumptionRate {
    constructor(params) {
        this.id = params.id;
        this.taskId = params.attributes.task_id;
        this.consumable = new Consumable(params.attributes.consumable);
        this.amount = params.attributes.amount;
    }
}
