<template>
  <div class="network-object-types__filter">
    <div class="filter__button tasks-filter__button"
         :class="{'opened': isFilterModalVisible}"
         @click="isFilterModalVisible = true">
      <icon-settings2/>
      <span>Фильтры</span>
    </div>

    <base-filter-modal
      :is-visible="isFilterModalVisible"
      @toggle-modal="isVisible => this.isFilterModalVisible = isVisible">
      <div class="filter__modal-header">
        <div class="filter__modal-name">Фильтры</div>
        <div @click="resetFilters()"
             class="filter__modal-reset">Сбросить все
        </div>
      </div>

      <div class="filter__modal-form">
        <div>
          <label class="input-label">Группа</label>
          <v-select
            class="vue-select-input"
            v-model="groupFilter"
            :options="networkObjectTypeGroupsList"
            :reduce="group => group.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите из списка"
          />
        </div>
      </div>

      <div class="filter__modal-buttons">
        <div @click="isFilterModalVisible = false"
             class="button button--mode-outline">Закрыть
        </div>
      </div>
    </base-filter-modal>
  </div>
</template>

<script>
import IconSettings2 from "@/components/Icons/IconSettings2.vue";
import BaseFilterModal from "@/components/BaseFilterModal.vue";
import {mapState} from "vuex";

export default {
  name: "NetworkObjectTypesListFilter",
  components: {BaseFilterModal, IconSettings2},
  data() {
    return {
      isFilterModalVisible: false
    }
  },
  computed: {
    ...mapState({
      groupFilterState: state => state.networkObjectTypes.groupFilter,
      networkObjectTypeGroupsList: state => state.networkObjectTypes.networkObjectTypeGroups
    }),
    groupFilter: {
      get() {
        return this.groupFilterState
      },
      set(value) {
        this.$store.commit('networkObjectTypes/SET_GROUP_FILTER', value)
      }
    }
  },
  methods: {
    resetFilters() {
      this.$store.commit('networkObjectTypes/SET_GROUP_FILTER', null);
      this.isFilterModalVisible = false;
    }
  }
}
</script>

<style scoped>
.network-object-types__filter {
  position: relative;
}

.filter__modal-buttons {
  grid-template-columns: 1fr;
}
</style>