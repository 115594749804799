<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="24"
       height="24"
       viewBox="0 0 24 24"
       fill="none">
    <path d="M3 7H21M3 12H21M3 17H21"
          stroke="#9192AD"
          stroke-width="1.5"
          stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconHamburgerMenu"
}
</script>

<style scoped>

</style>