<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      Добавить работу
      <span @click="$modal.hide('work-form')"
            class="base-popup__close">
        <icon-close />
      </span>
    </div>
    <div class="popup-form-group">
      <div class="form-item">
        <div class="form-item__name">Работа</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.work_id"
            :options="workTypesList"
            :reduce="work => work.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите из списка"
            class="vue-select-input"
          />
        </div>
      </div>
      <div class="form-item form-item--vertical">
        <div class="form-item__name">Комментарий</div>
        <div class="form-item__value">
          <textarea
            v-model="payload.comment"
            class="base-textarea"
            type="text"
            placeholder="Напишите текст"/>
        </div>
      </div>
    </div>
    <div class="base-popup__footer">
      <div @click="submitForm()"
           class="button">
        Добавить
      </div>
    </div>
  </div>
</template>

<script>
import {showToast, showAlert} from "@/utils/notification";
import IconClose from "@/components/Icons/IconClose";

export default {
  name: "WorksForm",
  components: {IconClose},
  props: {
    workTypesList: {
      type: Array,
      required: true
    },
    taskId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      payload: {
        work_id: null,
        comment: null
      }
    }
  },
  methods: {
    submitForm() {
      this.$store.dispatch('task/addWork', {
        task_id: this.taskId,
        payload: this.payload
      })
        .then(() => {
          this.payload = {
            work_id: null,
            comment: null
          };
          this.$store.dispatch('taskModal/getWorksList', this.taskId);
          this.$modal.hide('work-form');
          showToast('Работа добавлена', 'success');
          this.$store.dispatch('taskModal/getHistory', this.taskId);
        })
        .catch(error => showAlert('Ошибка', error, 'error'));
    }
  }
}
</script>

<style scoped>

</style>
