<script>
export default {
  name: "IconTickSquare"
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none">
    <path
      d="M1.4911 6.00004L6.49114 11.0001L16.5089 1"
      stroke="#27D8AD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>

<style scoped>

</style>
