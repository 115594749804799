export default class NetworkEquipmentParameter {
    constructor(params) {
        this.id = params.id;
        this.name = params.name;
        this.shortName = params.short_name;
        this.measure = params.measure;
        this.inputMask = params.input_mask;
        this.precision = params.precision;
        this.calculatedMethod = params.calculate_method;
    }

    static calculatedMethodsList = [
        {id: null, label: 'Без расчета'},
        {id: 1, label: 'Подпитка т/ч'},
        {id: 2, label: 'Тепловая нагрузка (Гкал/час)'},
        {id: 3, label: 'Расход теплоты на подпитку (Гкал/час)'}
    ]
}