export default class BalanceGroup {
    constructor(params) {
        this.id = params.id;
        this.name = params.name;
        this.network = params.network;
        this.zones = params.zones;
        this.devices = params.devices;
        this.excludedDevices = params.excludedDevices;
        this.isBalanceZone = params.balance_zone;
        this.isActive = params.active;
    }
}