import NetworkObjectTypesService from "@/services/NetworkObjectTypesService";
import {getError} from "@/utils/helpers";

export const namespaced = true;

export const state = {
    networkObjectTypes: [],
    networkObjectType: null,
    networkObjectTypeGroups: [],
    error: null,
    loading: false,
    searchQuery: '',
    groupFilter: null
};

export const mutations = {
    SET_NETWORK_OBJECT_TYPES(state, types) {
        state.networkObjectTypes = types;
    },
    SET_NETWORK_OBJECT_TYPE(state, type) {
        state.networkObjectType = type;
    },
    SET_NETWORK_OBJECT_TYPE_GROUPS(state, groups) {
        state.networkObjectTypeGroups = groups;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    DELETE_NETWORK_OBJECT_TYPE(state, typeId) {
        const index = state.networkObjectTypes.findIndex(type => type.id === typeId);
        state.networkObjectTypes.splice(index, 1);
    },
    SET_SEARCH_QUERY(state, query) {
        state.searchQuery = query;
    },
    SET_GROUP_FILTER(state, group) {
        state.groupFilter = group;
    }
};

export const actions = {
    getNetworkObjectTypesList({commit}) {
        commit("SET_LOADING", true);
        NetworkObjectTypesService.fetchNetworkObjectTypesList()
            .then(types => commit("SET_NETWORK_OBJECT_TYPES", types))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false))
    },
    getNetworkObject({commit}, typeId) {
        return new Promise((resolve, reject) => {
            NetworkObjectTypesService.fetchNetworkObject(typeId)
                .then(type => {
                    commit("SET_NETWORK_OBJECT_TYPE", type);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                });
        })
    },
    createNetworkObjectType(context, payload) {
        return new Promise((resolve, reject) => {
            NetworkObjectTypesService.createNetworkObjectType(payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    updateNetworkObjectType(context, {typeId, payload}) {
        return new Promise((resolve, reject) => {
            NetworkObjectTypesService.updateNetworkObjectType(typeId, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    deleteNetworkObjectType(context, typeId) {
        return new Promise((resolve, reject) => {
            NetworkObjectTypesService.deleteNetworkObjectType(typeId)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    getNetworkObjectTypeGroups({commit}) {
        NetworkObjectTypesService.fetchNetworkObjectTypeGroups()
            .then(groups => commit("SET_NETWORK_OBJECT_TYPE_GROUPS", groups))
            .catch(error => commit("SET_ERROR", error))
    }
};

export const getters = {
    getNetworkObjectTypeById: state => id => {
        const networkObjectType = state.networkObjectTypes.find(type => type.id === parseInt(id));
        return networkObjectType ? networkObjectType : null;
    },
    isNetworkObjectTypesListEmpty: state => {
        return !state.networkObjectTypes.length
    },
    getFilteredNetworkObjectTypesList: state => {
        let networkObjectTypesList = state.networkObjectTypes;

        if (state.searchQuery.length) networkObjectTypesList = networkObjectTypesList
            .filter(type => type.name.toLowerCase().includes(state.searchQuery.toLowerCase()))
        if (state.groupFilter !== null) networkObjectTypesList = networkObjectTypesList
            .filter(type => type.groupId === state.groupFilter)

        return networkObjectTypesList
    }
};