import LeaksService from "@/services/LeaksService";

export const namespaced = true;

export const state = {
    leaksList: [],
    errors: [],
    isIssueModalActive: false
}

export const mutations = {
    SET_LEAKS_LIST(state, leaks) {
        state.leaksList = leaks;
    },
    SET_ERRORS(state, error) {
        state.errors.push(error);
    },
    SET_ISSUE_MODAL_STATE(state, value) {
        state.isIssueModalActive = value;
    }
}

export const actions = {
    getOperationalData({dispatch}) {
        dispatch('getLeaksList');
    },
    getLeaksList({commit}) {
        LeaksService.getOperationalLeaksList()
            .then(leaksList => commit("SET_LEAKS_LIST", leaksList))
            .catch(error => commit("SET_ERRORS", error))
    }
}
