import * as API from './API'
import BalanceGroup from "@/models/billing/BalanceGroup";
import {getError} from "@/utils/helpers";

export default {
    getBalanceGroup(zoneId) {
        return API.apiClient.get(`/balanceGroups/${zoneId}`);
    },
    getBalanceGroups(page) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/balanceGroups/?page=${page}`)
                .then(response => resolve({
                    balanceGroupsList: response.data.data.map(item =>
                        new BalanceGroup({id: item.id, ...item.attributes})),
                    meta: response.data.meta
                }))
                .catch(error => reject(getError(error)))
        })
    },
    getAllBalanceGroups() {
        return API.apiClient.get('/balanceGroups/all')
    },
    createBalanceGroup(payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`/balanceGroups`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateBalanceGroup(id, payload){
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/balanceGroups/${id}`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        });
    },
    deleteBalanceGroup(id){
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`/balanceGroups/${id}`)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        });
    }
}