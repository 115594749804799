import Device from "@/models/telemetry/Device";

export default class DeviceTelemetry extends Device {
    constructor(params, showInList, showOnMap, fixOnMap) {
        super(params);

        this.showInList = showInList;
        this.showOnMap = showOnMap;
        this.fixOnMap = fixOnMap;
    }
}