var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',{staticClass:"loader-position"},[_c('base-loader')],1):_vm._e(),(this.$store.state.client.clients.length === 0)?_c('base-no-data',{attrs:{"title":"Абоненты не найдены"}}):_vm._e(),(_vm.storeStatus)?_c('div',{staticClass:"clients-filter-result",attrs:{"id":"filter-result"}},_vm._l((this.$store.state.client.clients),function(client_group){return _c('div',{key:client_group.id,staticClass:"base-card"},[_c('base-collapse',{scopedSlots:_vm._u([{key:"toggle",fn:function(ref){
var toggle = ref.toggle;
var isCollapsed = ref.isCollapsed;
return [_c('div',{staticClass:"client-group"},[_c('div',{staticClass:"client-group__address"},[_vm._v(" "+_vm._s(_vm.getFullAddress(client_group))+" ")]),_c('div',{staticClass:"client-group__actions"},[_c('div',{staticClass:"client-group__view-map",on:{"click":function($event){return _vm.$store.commit(
                  'baseMap/SET_CENTER_ZOOM',
                  {center: client_group.attributes.geometry.coordinates, zoom: 17}
                )}}},[_c('icon-map')],1),_c('div',{staticClass:"client-group__toggle",class:{'client-group__toggle--collapsed': isCollapsed},on:{"click":function($event){return toggle()}}},[_c('icon-arrow-down')],1)])])]}},{key:"body",fn:function(){return [(client_group.attributes.consumers.filter(
              function (client) { return client.attributes.client_type === 1; }).length !== 0)?_c('div',[_c('div',{staticClass:"collapse-block-title"},[_vm._v(" Физические лица: ")]),_c('ol',{attrs:{"type":"1"}},_vm._l((client_group.attributes.consumers.filter(
                  function (consumer) { return consumer.attributes.client_type === 1; })),function(consumer){return _c('li',{key:consumer.id,staticClass:"collapse-list-item",on:{"click":function($event){return _vm.getClientDetail(consumer.id)}}},[_vm._v(" №"+_vm._s(consumer.attributes.ls)+" от "+_vm._s(_vm.$moment(consumer.attributes.ls_date).format('DD-MM-YYYY'))+" "+_vm._s(_vm.describeLsStatus(consumer.attributes.status))+" "),_c('br'),_vm._v(" "+_vm._s(consumer.attributes.client_name)+" "),_c('br'),(consumer.attributes.saldo < 0)?_c('span',{staticClass:"text-danger"},[_vm._v(" Задолженность: "+_vm._s(Math.abs(consumer.attributes.saldo)))]):_vm._e()])}),0)]):_vm._e(),(client_group.attributes.consumers.filter(function (consumer) { return consumer.attributes.client_type === 2; }).length !== 0)?_c('div',[_c('div',{staticClass:"collapse-block-title"},[_vm._v(" Юридические лица: ")]),_c('ol',{attrs:{"type":"1"}},_vm._l((client_group.attributes.consumers.filter(
                  function (consumer) { return consumer.attributes.client_type === 2; })),function(consumer){return _c('li',{key:consumer.id,staticClass:"collapse-list-item",on:{"click":function($event){return _vm.getClientDetail(consumer.id)}}},[_vm._v(" №"+_vm._s(consumer.attributes.ls)+" от "+_vm._s(_vm.$moment(consumer.attributes.ls_date).format('DD-MM-YYYY'))+" "+_vm._s(_vm.describeLsStatus(consumer.attributes.status))+" "),_c('br'),_vm._v(" "+_vm._s(consumer.attributes.client_name)+" "),_c('br'),(consumer.attributes.saldo < 0)?_c('span',{staticClass:"text-danger"},[_vm._v(" Задолженность: "+_vm._s(Math.abs(consumer.attributes.saldo)))]):_vm._e()])}),0)]):_vm._e()]},proxy:true}],null,true)})],1)}),0):_vm._e(),(_vm.meta && _vm.meta.last_page > 1)?_c('base-paginate',{attrs:{"meta":_vm.meta,"action":"client/getClients","commit":"client/SET_PAGE"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }