<template>
  <div
    v-if="charts.length"
    class="base-chart"
    id="apexcharts-line"
  >
    <div class="base-chart__header">
      <span @click="toggleChartDropdown"
            class="base-chart__title">{{chartTitle}}</span>
      <icon-arrow-linear-down
        :class="{'toggle': showChartDropdown}"/>
      <div class="tags-select">
        <div v-for="chartType in charts"
             :key="chartType.id"
             @click="setCurrentChartType(chartType)"
             class="tags-select__item"
             :class="{
               'selected': currentChartType.id === chartType.id,
             }">
          {{ chartType.groupName }}
        </div>
      </div>
      <base-dropdown class="base-chart__dropdown"
                     v-if="showChartDropdown">
        <span v-for="chart in charts"
              :key="chart.id"
              @click="setCurrentChartType(chart.id)"
              class="base-dropdown__option">{{ chart.groupName }}</span>
      </base-dropdown>
    </div>
    <div v-for="chart in charts"
         :key="chart.id"
         class="chart-wrapper">
      <apexchart
        v-if="chart.id === currentChartType.id"
        type="donut"
        :options="chart.options"
        :series="chart.series"
        height="392px"
      />
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import VueApexCharts from "vue-apexcharts";
import IconArrowLinearDown from "@/components/Icons/IconArrowLinearDown.vue";
import BaseDropdown from "@/components/BaseDropdown.vue";
import {getCssVariableColor} from "@/utils/helpers";

export default {
  name: "IssuesCountByGroups",
  components: {BaseDropdown, IconArrowLinearDown, 'apexchart': VueApexCharts},
  props: {
    issuesStatistic: {
      type: Array,
      required: true
    },
    chartTitle: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      charts: [],
      currentChartType: null,
      showChartDropdown: false
    }
  },
  computed: {
    ...mapState({
      categoryGroups: state => state.serviceDeskCategories.categoryGroups,
      loading: state => state.issuesStatistic.issuesStatisticLoading
    })
  },
  watch: {
    issuesStatistic: function () {
      this.createChartData();
    }
  },
  mounted() {
    this.createChartData();
  },
  methods: {
    createChartData() {
      this.charts = [];

      this.categoryGroups.forEach(group => {
        const filteredStatistic = this.issuesStatistic.filter(statItem =>
            statItem.category_group_id === group.id);

        if (filteredStatistic.length) {
          this.charts.push({
            id: group.id,
            groupName: group.name,
            appealsCountByCategories: filteredStatistic,
            series: filteredStatistic.map(statItem => statItem.issue_count),
            options: {
              legend: {
                show: true,
                position: 'bottom',
                labels: {
                  colors: getCssVariableColor('--text-primary')
                }
              },
              labels: filteredStatistic.map(statItem => statItem.category_name),
              tooltip: {
                enabled: false
              },
              dataLabels: {
                formatter: (val, opts) => {
                  return opts.w.config.series[opts.seriesIndex]
                }
              },
              plotOptions: {
                pie: {
                  expandOnClick: false,
                  donut: {
                    labels: {
                      show: true,
                      value: {
                        color: getCssVariableColor('--text-primary')
                      },
                      total: {
                        show: true,
                        label: 'Всего',
                        color: getCssVariableColor('--text-primary')
                      }
                    }
                  }
                }
              }
            }
          })
          this.setCurrentChartType(this.charts[0]);
        }
      })
    },
    setCurrentChartType(type) {
      this.currentChartType = type;
      this.showChartDropdown = false;
    },
    toggleChartDropdown() {
      this.showChartDropdown = !this.showChartDropdown;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
