import * as API from './API';
import DetectedProblem from "@/models/serviceDesk/DetectedProblem";
import {getError} from "@/utils/helpers";

export default {
    fetchDetectedProblemsList(taskId) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/tasks/${taskId}/detected-problems`)
                .then(response => resolve(response.data.data
                    .map(item => new DetectedProblem({id: item.id, ...item.attributes}))))
                .catch(error => reject(getError(error)))
        })
    },
    createDetectedProblem(taskId, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`/service-desk/tasks/${taskId}/detected-problems`, payload)
                .then(response => resolve(response.data.data))
                .catch(error => reject(getError(error)))
        })
    },
    updateDetectedProblem(taskId, detectedProblemId, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/service-desk/tasks/${taskId}/detected-problems/${detectedProblemId}`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteDetectedProblem(taskId, detectedProblemId) {
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`/service-desk/tasks/${taskId}/detected-problems/${detectedProblemId}`)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    addDetectedProblemAttachments(taskId, detectedProblemId, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(
                `/service-desk/tasks/${taskId}/detected-problems/${detectedProblemId}/attachments`,
                payload,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    removeDetectedProblemAttachment(taskId, detectedProblemId, attachmentId) {
        return new Promise((resolve, reject) => {
            API.apiClient.delete(
                `/service-desk/tasks/${taskId}/detected-problems/${detectedProblemId}/attachments/${attachmentId}`
            )
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}