<template>
  <div class="map-base-height balance-map">
    <div v-if="grouping && periods.length"
         class="periods-selector">
      <div class="input-group">
        <button @click="changeIndex('-')">
          <icon-arrow-left/>
        </button>
        <div class="month-name">{{ processDate(sortPeriods()[currentMonthIndex]) }}</div>
        <button @click="changeIndex('+')">
          <icon-arrow-right/>
        </button>
      </div>
    </div>
    <base-map
      :center="center"
      :zoom="zoom"
      :base-layers="baseLayers"
      :polygons="createPolygonsContent()"
    />
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import BaseMap from "@/components/BaseMap";
import Polygon from "@/models/baseMap/Polygon";
import IconArrowLeft from "@/components/Icons/IconArrowLeft.vue";
import IconArrowRight from "@/components/Icons/IconArrowRight.vue";

export default {
  name: "BalanceMap",
  components: {IconArrowRight, IconArrowLeft, BaseMap},
  props: ['balance_zones_ids', 'balance_groups_ids', 'periods'],
  data() {
    return {
      polygons_content: [],
      selected_groups: [],
      currentMonthIndex: 0
    }
  },
  computed: {
    ...mapGetters({
      getBalanceGroup: 'balanceGroup/getBalanceGroup',
      getBalance: 'balance/balance_by_id',
      grouping: 'balance/grouping'
    }),
    ...mapState({
      center: state => state.baseMap.center,
      zoom: state => state.baseMap.zoom,
      baseLayers: state => state.baseLayers.baseLayers
    }),
    processDate: function () {
      return date => {
        return this.$moment(date).format('MMMM')
      }
    },
    getLossesInPercent: function () {
      return balance => {
        if (this.grouping === 1) {
          let period_balance = balance.periods
            .find(period => period.period === this.sortPeriods()[this.currentMonthIndex]);
          return (period_balance.calculateLosses() * 100) / period_balance.sumSupply();
        } else {
          return balance.calculatePeriodsLossesInPercent()
        }
      }
    },
    sortPeriods: function () {
      return () => {
        if (this.periods) {
          return this.periods
            .concat()
            .sort(function (a, b) {
              return new Date(a) - new Date(b)
            })
        }
      }
    }
  },
  watch: {
    balance_zones_ids: function (groups_ids) {
      this.selected_groups = groups_ids;
    },
    balance_groups_ids: function (groups_ids) {
      this.selected_groups = groups_ids;
    }
  },
  methods: {
    changeIndex(arg) {
      if (arg === '+' && this.currentMonthIndex < this.periods.length - 1) this.currentMonthIndex++;
      else if (arg === '-' && this.currentMonthIndex > 0) this.currentMonthIndex--;
    },
    createPolygonsContent() {
      let polygons = [];

      this.selected_groups.forEach(balance_group_id => {
        let balance_group = this.getBalanceGroup(balance_group_id);
        balance_group.attributes.zones.forEach(zone_id => {
          let zone = this.$store.getters['zone/getZone'](zone_id);

          let polygon = new Polygon({
            coords: zone.attributes.geometry.coordinates,
            color: this.setColor(balance_group_id),
            fill: this.setGradient(balance_group_id),
            popup_content: `Зона: ${zone.attributes.name}`
          })

          polygons.push(polygon)
        })
      });

      return polygons;
    },
    setColor(id) {
      let balance = this.getBalance(id)

      if (balance === undefined) {
        return '#8d8d8d'
      } else {
        let losses_percent = this.getLossesInPercent(balance)

        if (losses_percent > 0 && losses_percent < 100) {
          return `hsl(${100 - losses_percent}, 100%, 30%)`
        } else if (losses_percent >= 100) {
          return '#E02800'
        } else if (losses_percent < 0) {
          return '#4F94B7'
        } else {
          return '#8d8d8d'
        }
      }
    },
    setGradient(id) {
      let balance = this.getBalance(id);

      if (balance === undefined) {
        return '#d5d5d5'
      } else {
        let losses_percent = this.getLossesInPercent(balance)

        if (losses_percent > 0 && losses_percent < 100) {
          return `hsl(${100 - losses_percent}, 100%, 35%)`
        } else if (losses_percent >= 100) {
          return '#E02800'
        } else if (losses_percent < 0) {
          return '#6DAFD3'
        } else {
          return '#8d8d8d'
        }
      }
    }
  }
}
</script>

<style lang="scss">
.periods-selector {
  position: absolute;
  top: 20px;
  left: 84px;
  background-color: var(--panel);
  z-index: 500;
  border-radius: 4px;
  border: 1px solid var(--border-main);
  display: inline-block;
}

.periods-selector button {
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border: none;
}

.periods-selector button:focus {
  outline: none;
}

.month-name {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  line-height: 1;
}

.balance-map {
  & svg {
    & path {
      stroke: var(--ico-active);
    }
  }
}
</style>