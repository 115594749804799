<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="24"
       height="24"
       viewBox="0 0 24 24"
       fill="none">
    <path
      d="M21 21L19.2 19.2M11.55 20.1C12.6728 20.1 13.7846 19.8788 14.8219 19.4492C15.8593 19.0195 16.8018 18.3897
      17.5958 17.5958C18.3897 16.8018 19.0195 15.8593 19.4492 14.8219C19.8788 13.7846 20.1 12.6728 20.1 11.55C20.1
      10.4272 19.8788 9.31539 19.4492 8.27806C19.0195 7.24072 18.3897 6.29818 17.5958 5.50424C16.8018 4.7103 15.8593
      4.08051 14.8219 3.65083C13.7846 3.22115 12.6728 3 11.55 3C9.2824 3 7.10767 3.9008 5.50424 5.50424C3.9008 7.10767
      3 9.2824 3 11.55C3 13.8176 3.9008 15.9923 5.50424 17.5958C7.10767 19.1992 9.2824 20.1 11.55 20.1Z"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconSearch"
}
</script>

<style scoped>

</style>
