import Address from "@/models/Address";

export default class OperationalLeak {
    constructor(params) {
        this.id = params.id;
        this.actualStartDate = params.actual_start_date;
        this.actualEndDate = params.actual_end_date;
        this.issueId = params.issue?.id;
        this.issueCoordinates = params.issue.coordinates.coordinates.reverse();
        this.issueCreatedAt = params.issue.created_at;
        this.issueAddress = new Address(params.issue.address) || '';
        this.issueStateId = params.issue.state.id;
        this.issueStateName = params.issue.state.name;
        this.issueCategoryName = params.issue.category.name;
        this.issueCategoryGroupName = params.issue.category.group.name;
    }
}
