<script>
export default {
  name: "IconSnow"
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M5.5 20H16.5V11.5H5.5V20Z" fill="#DBEAEE"/>
    <path d="M16.61 19.9999C17.95 20.0099 19.24 19.5099 20.23 18.6099C23.5 15.7499 21.75 10.0099 17.44 9.46995C15.9 0.129949 2.42998 3.66995 5.61998 12.5599" fill="#DBEAEE"/>
    <path d="M16.61 19.9999C17.95 20.0099 19.24 19.5099 20.23 18.6099C23.5 15.7499 21.75 10.0099 17.44 9.46995C15.9 0.129949 2.42998 3.66995 5.61998 12.5599" stroke="#1AB9EB" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.28011 12.9698C6.75011 12.6998 6.16011 12.5598 5.57011 12.5698C0.910109 12.8998 0.920108 19.6798 5.57011 20.0098" fill="#DBEAEE"/>
    <path d="M7.28011 12.9698C6.75011 12.6998 6.16011 12.5598 5.57011 12.5698C0.910109 12.8998 0.920108 19.6798 5.57011 20.0098" stroke="#1AB9EB" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.8201 9.88998C16.3401 9.62998 16.9001 9.48998 17.4801 9.47998L15.8201 9.88998Z" fill="#DBEAEE"/>
    <path d="M15.8201 9.88998C16.3401 9.62998 16.9001 9.48998 17.4801 9.47998" stroke="#1AB9EB" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.9955 18.5H13.0045" stroke="#468FC5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.9955 18.5H9.0045" stroke="#468FC5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.9955 21.5H11.0045" stroke="#468FC5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<style scoped>

</style>