<template>
  <div class="network-object-form-map-container">
    <base-map
      :base-layers="baseLayers"
      :zoom="zoom"
      :center="center"
      :markers="setMarker()"
      @map-click="updateCoords"
    />
  </div>
</template>

<script>
import BaseMap from "@/components/BaseMap.vue";
import {mapState} from "vuex";
import Marker from "@/models/baseMap/Marker";
import Icon from "@/models/baseMap/Icon";

export default {
  name: "NetworkObjectFormMap",
  components: {BaseMap},
  props: {
    lat: {},
    lon: {}
  },
  data() {
    return {
      marker: []
    }
  },
  computed: {
    ...mapState({
      baseLayers: state => state.baseLayers.baseLayers,
      center: state => state.baseMap.center,
      zoom: state => state.baseMap.zoom
    })
  },
  methods: {
    updateCoords(latLon) {
      this.$emit('update-lat', latLon.coords.lat);
      this.$emit('update-lon', latLon.coords.lng);
    },
    setMarker() {
      return [new Marker({
        coords: [this.lat, this.lon],
        icon: new Icon({
          type: 'icon',
          url: require('@/assets/img/icons/map-pin.svg'),
          size: [36, 50],
          anchor: [18, 50]
        })
      })]
    }
  }
}
</script>

<style scoped>
.network-object-form-map-container {
  height: 360px;
}
</style>