<template>
  <div class="transports-map-wrapper reset-leaflet-popup-styles">
    <base-map
      :center="center"
      :zoom="zoom"
      :base-layers="baseLayers"
      :markers="createMarkers()"
      base_layers_control_position_top="140px"
    />

    <div
      @click="$store.commit('autos/TOGGLE_MARKERS_LABEL_VISIBLE')"
      class="transports-map-wrapper__labels-mode"
      :class="{'transports-map-wrapper__labels-mode--active' : isMarkersLabelVisible}">
      <icon-label/>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import BaseMap from "@/components/BaseMap";
import TransportMapIcon from "@/components/Transport/TransportMapIcon.vue";
import Vue from "vue";
import Marker from "@/models/baseMap/Marker";
import Icon from "@/models/baseMap/Icon";
import IconLabel from "@/components/Icons/IconLabel.vue";
import TransportMapPopup from "@/components/Transport/TransportMapPopup.vue";

export default {
  name: "TransportsMap",
  components: {
    IconLabel,
    BaseMap
  },
  computed: {
    ...mapState({
      center: state => state.baseMap.center,
      zoom: state => state.baseMap.zoom,
      baseLayers: state => state.baseLayers.baseLayers,
      isMarkersLabelVisible: state => state.autos.isMarkersLabelVisible
    }),
    ...mapGetters({
      autos: 'autos/getFilteredAndSortedAutos',
      lat: 'transportData/getLat',
      lon: 'transportData/getLon'
    })
  },
  methods: {
    createMarkers() {
      const TransportIconClass = Vue.extend(TransportMapIcon);
      const TransportPopupClass = Vue.extend(TransportMapPopup);

      return this.autos.map(auto => {
        const iconInstance = new TransportIconClass({ propsData: { vehicle: auto } });
        const icon = iconInstance.$mount();

        let popupEl = null;
        if (!this.isMarkersLabelVisible) {
          const popupInstance = new TransportPopupClass({ propsData: { vehicle: auto } });
          const popup = popupInstance.$mount();
          popupEl = popup.$el;

          popupInstance.$destroy();
        }

        const marker = new Marker({
          coords: [this.lat(auto.id), this.lon(auto.id)],
          icon: new Icon({
            type: 'div_icon',
            size: [36, 42],
            anchor: [18, 21],
            html: icon.$el
          }),
          accumulation: true,
          popup_content: popupEl
        });

        iconInstance.$destroy();

        return marker;
      });
    }
  }
}
</script>

<style lang="scss">
.transports-map-wrapper {
  height: calc(100vh - 200px);
  flex: 1;
  z-index: 1;
  position: relative;

  &__labels-mode {
    width: 48px;
    height: 48px;
    padding: 12px;

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    right: 24px;
    top: 24px;

    border-radius: 12px;
    border: 1px solid var(--border-main);

    background: var(--panel);
    box-shadow: 0px 8px 16px 0px var(--shadow);

    z-index: 400;

    cursor: pointer;

    &--active {
      & svg {
        fill: var(--ico-active);

        & path:first-child {
          stroke: var(--ico-active);
        }

        & path:not(:first-child) {
          stroke: var(--ico-panel);
        }
      }
    }
  }
}
</style>
