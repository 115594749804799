import * as API from './API';
import TechnicalServiceWork from "@/models/telemetry/TechnicalServiceWork";
import {getError} from "@/utils/helpers";

export default {
    fetchTechnicalServiceWorksList() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/technical-service/works`)
                .then(response => resolve(response.data.data
                    .map(item => new TechnicalServiceWork({id: item.id, ...item.attributes}))))
                .catch(error => reject(getError(error)))
        })
    },
    createTechnicalServiceWork(payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`/technical-service/works`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateTechnicalServiceWork(id, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/technical-service/works/${id}`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteTechnicalServiceWork(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`/technical-service/works/${id}`)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}
