<template>
  <div class="base-dropdown">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'BaseDropdown'
}
</script>

<style lang="scss" scoped>

</style>