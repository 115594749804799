<template>
  <transition name="slide-fade">
    <div class="task-modal-map"
         v-show="isMapVisible">
      <base-map
        id-key="issue-map"
        :center="task.coordinates"
        :zoom="17"
        :base-layers="baseLayers"
        :markers="createTaskMarker()"
        :need-resize="isMapVisible"
        :layers="layers"
      />
    </div>
  </transition>
</template>

<script>
import BaseMap from "@/components/BaseMap.vue";
import {mapState} from "vuex";
import Vue from "vue";
import Marker from "@/models/baseMap/Marker";
import Icon from "@/models/baseMap/Icon";
import PersonalListNetworkObjectMapMarker
  from "@/components/Telemetry/PersonalLists/PersonalListNetworkObjectMapMarker.vue";

export default {
  name: "TaskMap",
  components: {BaseMap},
  props: {
    task: {
      type: Object,
      required: true
    },
    isMapVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpenMapCard: false
    }
  },
  computed: {
    ...mapState({
      baseLayers: state => state.baseLayers.baseLayers,
      loading: state => state.baseLayers.loading,
      layers: state => state.baseMap.layers
    })
  },
  mounted() {
    this.$store.dispatch('baseMap/getLayers');
  },
  methods: {
    createTaskMarker() {
      const IconClass = Vue.extend(PersonalListNetworkObjectMapMarker);
      const iconInstance = new IconClass();
      const icon = iconInstance.$mount();

      const marker = [new Marker({
        coords: this.task.coordinates,
        icon: new Icon({
          type: 'div_icon',
          size: [36, 42],
          anchor: [18, 21],
          html: icon.$el.outerHTML
        })
      })]

      icon.$destroy();

      return marker;
    }
  }
}
</script>

<style lang="scss" scoped>
.task-modal-map {
  height: 360px;
  margin-top: 16px;
}

.slide-fade-enter-active {
  transition: all .5s linear;
}
.slide-fade-leave-active {
  transition: all .3s linear;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
</style>