<template>
  <div class="i-modal__content-wrapper">
    <div class="i-modal__content">
      <div v-if="Object.keys(networkObject).length && !loading"
           class="i-modal__inner">
        <div class="base-modal__left-side base-modal__left-side--hidden">
          <div class="base-modal-header base-modal-header--padding-s">
            <span class="base-modal-title">{{ networkObject.name }}</span>
          </div>

          <div class="base-modal-info base-modal-info--gap-24 base-modal-info--padding-s">
            <div class="base-modal-info__card">
              <base-collapse>
                <template #toggle="{toggle, isCollapsed}">
                  <div class="base-modal-info__card-title base-modal-info__card-title--loader">
                    <span class="base-modal-info__card-collapse"
                          :class="{'base-modal-info__card-collapse--closed': isCollapsed}"
                          @click="toggle()">
                      Детальная информация
                      <icon-arrow-down/>
                    </span>

                    <div
                      v-if="isEditAvailable"
                      @click="openEditModal()"
                      class="base-modal-info__card-title-subtext">
                      <icon-pencil/>
                      <span>Редактировать</span>
                    </div>
                  </div>
                </template>

                <template #body>
                  <div>
                    <div class="base-modal-info__item">
                      <div class="base-modal-info__name">
                        <span>Категория объекта</span>
                      </div>
                      <div class="base-modal-info__value">
                        <p class="base-modal-info__value-txt">{{ networkObject.getTypeLabel() }}</p>
                      </div>
                    </div>

                    <div class="base-modal-info__item">
                      <div class="base-modal-info__name">
                        <span>Тип объекта</span>
                      </div>
                      <div class="base-modal-info__value">
                        <p class="base-modal-info__value-txt">{{ networkObject._type.name }}</p>
                      </div>
                    </div>

                    <div class="base-modal-info__item">
                      <div class="base-modal-info__name">
                        <span>Телефон</span>
                      </div>
                      <div class="base-modal-info__value">
                        <p class="base-modal-info__value-txt">{{ networkObject.phone }}</p>
                      </div>
                    </div>

                    <div class="base-modal-info__item">
                      <div class="base-modal-info__name">
                        <span>Балансовая принадлежность</span>
                      </div>
                      <div class="base-modal-info__value">
                        <p class="base-modal-info__value-txt">{{ nullFilter(networkObject.balanceSheetName) }}</p>
                      </div>
                    </div>

                    <div class="base-modal-info__item">
                      <div class="base-modal-info__name">
                        <span>Структурные подразделения</span>
                      </div>
                      <div class="base-modal-info__value">
                        <p class="base-modal-info__value-txt">{{ networkObject.structuralUnitsNames }}</p>
                      </div>
                    </div>

                    <div class="base-modal-info__item">
                      <div class="base-modal-info__name">
                        <span>Адрес</span>
                      </div>
                      <div class="base-modal-info__value">
                        <p class="base-modal-info__value-txt">{{ networkObject.address }}</p>
                      </div>
                    </div>

                    <div class="i-modal-location"
                         :class="{'is-open': isMapVisible}">
                      <div class="i-modal-location__map">

                        <base-map
                          id-key="-network-object-modal"
                          :center="networkObject.coordinates"
                          :zoom="17"
                          :base-layers="baseLayers"
                          :markers="createMarker"
                          :need-resize="isMapVisible"
                        />

                      </div>
                      <button class="button button--mode-icon map-resize-btn"
                              @click="isMapVisible = !isMapVisible">
                        <icon-arrow-double-up/>
                      </button>
                    </div>
                  </div>
                </template>
              </base-collapse>
            </div>

            <div
              v-if="isEditAvailable"
              class="base-modal-info__item base-modal-info__item--options">
              <equipments-list
                :network-object-id="networkObject.id"
                :equipments-list="equipmentsList"
              />

              <devices-list
                :network-object-id="networkObject.id"
                :devices-list="devicesList"
              />
            </div>

            <div
              v-if="isReadingsVisible"
              class="base-modal-info__card">
              <base-collapse>
                <template #toggle="{toggle, isCollapsed}">
                  <div class="base-modal-info__card-title base-modal-info__card-title--loader">
                    <span class="base-modal-info__card-collapse"
                          :class="{'base-modal-info__card-collapse--closed': isCollapsed}"
                          @click="toggle()">
                      Показатели
                      <icon-arrow-down/>
                    </span>
                  </div>
                </template>

                <template #body>
                  <div class="network-object-readings">
                    <div class="network-object-readings__current">
                      <div class="network-object-readings__title">Текущие</div>
                      <div class="personal-list__equipments-list">
                        <div
                          v-for="equipment in equipmentsList"
                          :key="equipment.id"
                          class="personal-list__equipment-card">
                          <div class="personal-list__equipment-name">
                            {{ equipment.name }}
                          </div>
                          <div class="personal-list__equipment-parameters">
                            <personal-list-parameter
                              v-for="parameter in equipment.parameters"
                              :key="parameter.id"
                              :parameter="parameter"
                              :show-reading-at-date="true"
                              :parameter-profile="parameterProfile(parameter.id)"
                              :parameter-value="parameterValue(parameter.id)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <network-object-modal-archive-readings
                      :equipments-list="equipmentsList"
                    />

                  </div>

                </template>
              </base-collapse>
            </div>

          </div>
        </div>
      </div>
      <base-side-modal-skeleton v-if="loading"/>
    </div>
    <div v-if="Object.keys(networkObject).length"
         class="i-modal-btn-wp i-modal-btn-wp--back">
      <div class="task-modal__mobile-actions">
        <button @click="$store.dispatch('baseSideModal/closeModal')"
                class="button button--mode-outline task-modal__mobile-back">
          <icon-arrow-left/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrowLeft from "@/components/Icons/IconArrowLeft.vue";
import BaseSideModalSkeleton from "@/components/BaseSideModalSkeleton.vue";
import {mapGetters, mapState} from "vuex";
import DevicesList from "@/components/Telemetry/admin/NetworkObject/DevicesList.vue";
import EquipmentsList from "@/components/Telemetry/admin/NetworkObject/EquipmentsList.vue";
import IconPencil from "@/components/Icons/IconPencil.vue";
import {nullFilter} from "@/utils/filters";
import BaseCollapse from "@/components/BaseCollapse.vue";
import IconArrowDown from "@/components/Icons/IconArrowDown.vue";
import PersonalListParameter from "@/components/Telemetry/PersonalLists/PersonalListParameter.vue";
import NetworkObjectModalArchiveReadings
  from "@/components/Telemetry/admin/NetworkObject/NetworkObjectModalArchiveReadings.vue";
import BaseMap from "@/components/BaseMap.vue";
import IconArrowDoubleUp from "@/components/Icons/IconArrowDoubleUp.vue";
import Marker from "@/models/baseMap/Marker";
import Vue from "vue";
import PersonalListNetworkObjectMapMarker
  from "@/components/Telemetry/PersonalLists/PersonalListNetworkObjectMapMarker.vue";
import Icon from "@/models/baseMap/Icon";

export default {
  name: "NetworkObjectModalContent",
  components: {
    IconArrowDoubleUp, BaseMap,
    NetworkObjectModalArchiveReadings,
    PersonalListParameter,
    IconArrowDown,
    BaseCollapse,
    IconPencil,
    EquipmentsList,
    DevicesList,
    BaseSideModalSkeleton,
    IconArrowLeft
  },
  props: {
    isEditAvailable: {
      type: Boolean,
      default: false
    },
    isReadingsVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isMapVisible: false
    }
  },
  computed: {
    ...mapState({
      networkObject: state => state.networkObjectPage.networkObject,
      devicesList: state => state.networkObjectPage.devicesList,
      equipmentsList: state => state.networkObjectPage.equipmentsList,
      loading: state => state.networkObjectPage.loading,
      baseLayers: state => state.baseLayers.baseLayers
    }),
    ...mapGetters({
      parameterValue: 'telemetry/getParameterValue',
      parameterProfile: 'telemetry/getParameterProfile'
    }),
    createMarker() {
      const ObjectIconClass = Vue.extend(PersonalListNetworkObjectMapMarker);
      const iconInstance = new ObjectIconClass();
      iconInstance.typeGroup = this.networkObject._type.groupName;
      const icon = iconInstance.$mount();
      const iconHtml = icon.$el.outerHTML;

      const marker = [
        new Marker({
          coords: this.networkObject.coordinates,
          icon: new Icon({
            type: 'div_icon',
            size: [36, 42],
            anchor: [18, 21],
            html: iconHtml
          })
        })
      ]

      icon.$destroy();

      return marker;
    }
  },
  methods: {
    nullFilter,
    openEditModal() {
      this.$store.dispatch("baseSideModal/openModal", {
        modalContentType: 'networkObjectForm',
        propsData: this.networkObject
      });
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/helpers/mixins";

.network-object-readings {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include font-style(600, 16px, 24px, var(--text-primary));
  }

  &__current {
    display: flex;
    flex-direction: column;
    gap: 20px;

    padding-bottom: 24px;
    border-bottom: 1px solid var(--border-main);
  }

  &__archive {
    display: flex;
    flex-direction: column;
    gap: 20px;

    padding-top: 24px;

    &-form {
      display: flex;
      gap: 24px;

      min-width: 440px;
    }
  }
}
</style>