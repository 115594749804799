<template>
  <div class="base-card appeals-archive-form__wrapper">
    <div class="appeals-archive-form">
      <div class="appeals-archive-form__inner">
        <div class="form-item">
          <div class="form-item__name">Дата открытия</div>
          <div class="form-item__value">
            <date-picker
              v-model="openedPeriod"
              :default-value="defaultValue"
              class="base-date-picker"
              placeholder="Выберите период"
              type="date"
              format="DD.MM.YYYY"
              value-type="YYYY-MM-DD"
              range
              @change="dates => handleDateChange(dates, 'openedPeriod')"
            />
          </div>
        </div>

        <div class="form-item">
          <div class="form-item__name">Проблема</div>
          <div class="form-item__value">
            <base-cascade-select
              v-model="filter.category_id"
              :options-groups="getCategoriesByGroups"
            />
          </div>
        </div>

        <div class="form-item form-item--appeals-address">
          <div class="form-item__name">Адрес</div>
          <div class="form-item__value">
            <input
              v-model="$v.filter.address_string.$model"
              type="text"
              class="base-input base-input--bordered"
              :class="{'base-input--error': $v.filter.address_string.$error}"
              placeholder="Введите адрес"
            >
          </div>

          <div
            v-if="$v.filter.address_string.$error"
            class="form-item__errors-list">
            <div
              v-if="!$v.filter.address_string.minLength"
              class="form-item__error">
              Минимальная длина адреса - 3 символа
            </div>

            <div
              v-if="!$v.filter.address_string.noOnlyWhitespace"
              class="form-item__error">
              Адрес не может состоять только из пробелов
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="isFormError"
        class="form-item__error">
        Хотя бы одно поле должно быть заполнено
      </div>

      <div class="appeals-archive-form__actions">
        <button
          @click="getArchiveAppeals()"
          class="button button--mode-primary"
          type="button">
          Сформировать
        </button>

        <button
          @click="resetFilter()"
          type="button"
          class="button button--mode-outline">
          <icon-refresh/>
          <span>Сбросить</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import DatePicker from "vue2-datepicker";
import BaseCascadeSelect from "@/components/BaseCascadeSelect.vue";
import AppealState from "@/models/callCenter/AppealState";
import IconRefresh from "@/components/Icons/IconRefresh.vue";
import {handleDateChange} from "@/utils/helpers";
import { validationMixin } from 'vuelidate'
import { minLength } from 'vuelidate/lib/validators'
import {noOnlyWhitespace} from "@/utils/validators";

export default {
  name: "AppealsArchiveForm",
  components: {IconRefresh, BaseCascadeSelect, DatePicker},
  mixins: [validationMixin],
  data() {
    return {
      defaultValue: [new Date(), new Date()],
      openedPeriod: [],
      filter: {
        category_id: null,
        address_string: null
      },
      isFormError: false
    }
  },
  validations: {
    filter: {
      address_string: {
        minLength: minLength(3),
        noOnlyWhitespace
      }
    }
  },
  computed: {
    ...mapGetters({
      categoryGroups: 'serviceDeskCategories/getGroupsContainingCategories',
      categoriesByGroupId: 'serviceDeskCategories/getCategoriesByGroupId'
    }),
    getCategoriesByGroups() {
      return this.categoryGroups.map(group => {
        return {
          id: group.id,
          name: group.name,
          options: this.categoriesByGroupId(group.id)
        }
      })
    }
  },
  methods: {
    handleDateChange(dates, key) {
      handleDateChange(dates, key, this);
    },
    getArchiveAppeals() {
      const filter = {...this.filter};

      if (this.openedPeriod.length) {
        const [start, end] = this.openedPeriod;
        filter.open_date_start = start;
        filter.open_date_end = end;
      }

      this.$v.$touch()
      if (this.$v.$invalid) return false

      if (Object.values(filter).some(value => !!value)) {
        this.isFormError = false;
        filter.appeal_status = [AppealState.CANCELED, AppealState.CLOSED].join(',');
        this.$store.dispatch('appeals/getAllAppeals', filter);
      } else {
        this.isFormError = true;
        this.$store.commit('appeals/SET_ALL_APPEALS', []);
      }
    },
    resetFilter() {
      this.openedPeriod = [];
      this.filter.category_id = null;
      this.filter.address_string = null;
      this.isFormError = false;

      this.$store.commit('appeals/SET_ALL_APPEALS', []);
    }
  }
}
</script>

<style lang="scss" scoped>
.appeals-archive-form {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__wrapper {
    margin-bottom: 20px;
  }

  &__inner {
    display: flex;
    gap: 20px;

    & .form-item {
      flex: 1;

      &--appeals-address {
        flex: 2;
      }
    }
  }

  &__actions {
    display: flex;
    gap: 24px;

    & .button {
      min-width: 240px;
    }
  }
}
</style>