<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      <template v-if="!Object.keys(engineeringNetwork).length">Добавить сеть</template>
      <template v-else>Редактирование сети</template>
      <span @click="$modal.hide('engineering-network-form')"
            class="base-popup__close">
        <icon-close/>
      </span>
    </div>
    <div class="popup-form-group">
      <div class="form-item">
        <div class="form-item__name">Наименование</div>
        <div class="form-item__value">
          <input
            v-model="payload.name"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите наименование"
            required
          >
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Тип сети</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.network_type"
            :options="networkTypes"
            :reduce="type => type.id"
            class="vue-select-input"
            placeholder="Выберите из списка"
          />
        </div>
      </div>
    </div>
    <div class="base-popup__footer">
      <div @click="checkFormType()"
           class="button">
        Сохранить
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from "@/components/Icons/IconClose.vue";

export default {
  name: "EngineeringNetworkForm",
  components: {IconClose},
  props: {
    engineeringNetwork: {
      type: Object,
      default: () => {}
    },
    createEngineeringNetwork: {
      type: Function,
      default: () => {}
    },
    updateEngineeringNetwork: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      payload: {
        name: null,
        network_type: null
      },
      networkTypes: [
        {id: 1, label: 'Водоснабжение'},
        {id: 2, label: 'Водоотведение'},
        {id: 3, label: 'Теплосети'},
        {id: 4, label: 'Ливневая канализация'}
      ]
    }
  },
  mounted() {
    if (Object.keys(this.engineeringNetwork).length) {
      const {name, typeId, active} = this.engineeringNetwork
      this.payload.name = name;
      this.payload.network_type = typeId;
      this.active = active;
    }
  },
  methods: {
    checkFormType() {
      if (Object.keys(this.engineeringNetwork).length)
        this.updateEngineeringNetwork(this.engineeringNetwork.id, this.payload)
      else this.createEngineeringNetwork(this.payload)
    }
  }
}
</script>

<style scoped>

</style>