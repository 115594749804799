export default class BalancePeriod {
    constructor(period, supply, consumption) {
        this.period = period;
        this.supply = supply;
        this.consumption = consumption;
    }

    sumSupply() {
        return this.supply.reduce((s, i) => s = s + parseInt(i.value), 0);
    }

    sumConsumption() {
        return this.consumption.reduce((s, i) => s = s + parseInt(i.value), 0);
    }

    calculateLosses() {
        return (this.sumSupply() - this.sumConsumption()).toFixed(0);
    }

    calculateLossesInPercent() {
        let losses_in_percent = this.calculateLosses() * 100 / this.sumSupply()

        if (losses_in_percent !== (-Infinity) && !isNaN(losses_in_percent)) {
            return losses_in_percent.toFixed(1)
        } else {
            return '-'
        }
    }
}