<template>
  <div class="page-wrapper">
    <base-panel
      title="Балансовая принадлежность"
      :is-add-button-visible="true"
      @add-button-clicked="showPopup()"
    />

    <balance-sheets-list
      v-if="balanceSheetsList.length"
      :balance-sheets-list="balanceSheetsList"
      @edit-balance-sheet="showPopup"
      @delete-balance-sheet="deleteBalanceSheet"
    />

    <base-content-loaders v-if="loading && !balanceSheetsList.length"/>

    <base-no-data v-if="!loading && !balanceSheetsList.length" />
  </div>
</template>

<script>
import BalanceSheetsList from "@/components/Telemetry/admin/BalanceSheetsList";
import {mapState} from "vuex";
import BasePanel from "@/components/BasePanel.vue";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import {showAlert, showToast, showConfirmationModal} from "@/utils/notification";
import {modalAdaptiveWidth} from "@/utils/helpers";
import BalanceSheetsForm from "@/components/Telemetry/admin/BalanceSheetsForm.vue";
export default {
  name: "BalanceSheetsListPage",
  components: {BaseNoData, BaseContentLoaders, BasePanel, BalanceSheetsList},
  computed: {
    ...mapState({
      balanceSheetsList: state => state.balanceSheets.balanceSheetsList,
      loading: state => state.balanceSheets.loading
    })
  },
  mounted() {
    this.$store.dispatch('balanceSheets/getBalanceSheetsList');
  },
  methods: {
    showPopup(balanceSheet = {}) {
      this.$modal.show(
        BalanceSheetsForm,
        {
          'balanceSheet': balanceSheet,
          'createBalanceSheet': this.createBalanceSheet,
          'updateBalanceSheet': this.updateBalanceSheet
        },
        {
          name: 'balance-sheet-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        }
      )
    },
    createBalanceSheet(payload) {
      this.$store.dispatch('balanceSheets/createBalanceSheet', payload)
        .then(() => {
          this.$store.dispatch('balanceSheets/getBalanceSheetsList');
          this.$modal.hide('balance-sheet-form');
          showToast('Балансовая принадлежность добавлена', 'success');
        })
        .catch(error => showAlert('Ошибка', error));
    },
    updateBalanceSheet(balanceSheetId, payload) {
      this.$store.dispatch('balanceSheets/updateBalanceSheet', {
        id: balanceSheetId,
        payload
      })
        .then(() => {
          this.$store.dispatch('balanceSheets/getBalanceSheetsList');
          this.$modal.hide('balance-sheet-form');
          showToast('Балансовая принадлежность обновлена', 'success');
        })
        .catch(error => showAlert('Ошибка', error));
    },
    deleteBalanceSheet(id) {
      showConfirmationModal('Вы уверены, что хотите удалить балансовую принадлежность? Это действие невозвратно.')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('balanceSheets/deleteBalanceSheet', id)
              .then(() => {
                this.$store.dispatch('balanceSheets/getBalanceSheetsList');
                showToast('Балансовая принадлежность удалена', 'success');
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style scoped>

</style>