<template>
  <div
    class="map-marker"
    :class="{'danger': isDanger}">
    <map-icon-bg />
    <div class="map-marker__inner">
      <div class="map-marker__img">
        <img
          v-if="iconGroup === 'ВНС'"
          alt="boiler"
          src="@/assets/img/telemetry/pump.svg">
        <img
          v-if="iconGroup === 'РЧВ'"
          alt="boiler"
          src="@/assets/img/telemetry/tank.svg">
        <img
          v-if="iconGroup === 'МКД'"
          alt="boiler"
          src="@/assets/img/telemetry/mkd.svg">
        <img
          v-if="iconGroup === 'КП'"
          alt="boiler"
          src="@/assets/img/telemetry/counter.svg">
        <img
          v-if="iconGroup === 'Котельная'"
          alt="boiler"
          src="@/assets/img/telemetry/boiler.svg">
        <div v-else/>
      </div>
    </div>
  </div>
</template>

<script>
import MapIconBg from "@/components/Icons/MapIconBg";
export default {
  name: "ScadaMapIcon",
  components: {MapIconBg},
  props: {
    isDanger: {
      type: Boolean,
      default: false
    },
    iconGroup: {
      type: String,
      default: 'Другое'
    }
  }
}
</script>

<style scoped>

</style>