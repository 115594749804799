<template>
  <div class="base-table">
    <div class="base-table__container">
      <div class="base-table__header base-table__column--balance-group">
        <div class="base-table__header-cell">ID</div>
        <div class="base-table__header-cell">Наименование</div>
        <div class="base-table__header-cell">Входящие зоны</div>
        <div class="base-table__header-cell">Расходомеры</div>
        <div class="base-table__header-cell"/>
      </div>

      <div v-for="balanceGroup in balanceGroupsList"
           :key="balanceGroup.id"
           class="base-table__row base-table__column--balance-group">
        <div class="base-table__column">
          <div class="base-table__text">{{ balanceGroup.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ balanceGroup.name }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ getZoneNames(balanceGroup.zones) }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ getDevicesNames(balanceGroup.devices) }}</div>
        </div>
        <div class="base-table__column base-table__column--content-right">
          <div class="base-table__short-info">
            <div @click="$emit('edit-balance-group', balanceGroup)">
              <icon-pencil class="base-table__short-info--p-2" />
            </div>
            <div @click="$emit('delete-balance-group', balanceGroup.id)">
              <icon-trash />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import IconTrash from "@/components/Icons/IconTrash.vue";
import IconPencil from "@/components/Icons/IconPencil.vue";
export default {
  name: "BalanceGroupsList",
  components: {IconPencil, IconTrash},
  props: {
    balanceGroupsList: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      getZoneNames: 'zone/getNames',
      getDevicesNames: 'device/getNames'
    })
  }
}
</script>

<style scoped>
.base-table__column--balance-group {
  grid-template-columns: 80px 160px 1fr 2fr 80px;
}
</style>