<template>
  <div class="base-table">
    <div class="base-table__container">
      <div class="base-table__header base-table__column--technical-inspection-task">
        <div class="base-table__header-cell">#</div>
        <div class="base-table__header-cell">Обходчик</div>
        <div class="base-table__header-cell">Приоритет</div>
        <div class="base-table__header-cell">Объект</div>
        <div class="base-table__header-cell">Адрес</div>
        <div class="base-table__header-cell">Статус</div>
      </div>

      <div v-for="task in tasksList"
           :key="task.id"
           @click="openTaskModal(task.id)"
           class="base-table__row base-table__column--technical-inspection-task">
        <div class="base-table__column base-table__column--number">
          <div class="base-table__num">{{ task.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ task.resource.name }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ task.priority.name }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ task.object.name }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ task.object.address }}</div>
        </div>
        <div class="base-table__column">
          <div
            v-show="task.state.id === TechnicalInspectionTaskState.IN_WORK ||
              task.state.id === TechnicalInspectionTaskState.REVIEW"
            class="base-table__tag"
            :class="[
              {'base-table__tag--new': task.state.id === TechnicalInspectionTaskState.REVIEW},
              {'base-table__tag--work': task.state.id === TechnicalInspectionTaskState.IN_WORK}
            ]">
            {{ task.state.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {TechnicalInspectionTaskState} from "@/models/TechnicalInspection/TechnicalInspectionTaskState";

export default {
  name: "TasksList",
  props: {
    tasksList: {
      type: Array,
      required: true
    }
  },
  computed: {
    TechnicalInspectionTaskState() {
      return TechnicalInspectionTaskState
    }
  },
  methods: {
    openTaskModal(id) {
      this.$store.dispatch('baseSideModal/openModal', {
        modalContentType: 'technicalInspectionTask',
        entityId: id
      });
    }
  }
}
</script>

<style scoped>
.base-table__column--technical-inspection-task {
  grid-template-columns: 60px repeat(5, 1fr);
}
</style>