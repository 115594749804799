import * as API from '@/services/API'
import Balance from "@/models/billing/Balance";
import BalancePeriod from "@/models/billing/BalancePeriod";

export default {
    async getSupplyBalance(payload) {
        const {data: {data}} = await API.apiClient.get('/billing/supply-balance', {params: payload.params});
        return dataHandler(data);

    },
    async getDrainBalance(payload) {
        const {data: {data}} = await API.apiClient.get('/billing/drainage-balance', {params: payload.params});
        return dataHandler(data);
    }
}

function dataHandler(data) {
    return data.map(item => new Balance(
        item.id,
        item.name,
        item.periods
            .map(period => new BalancePeriod(
                    period.period,
                    period.supply,
                    period.consumption
                )
            )
            .sort(function (a, b) {
                return new Date(a.period) - new Date(b.period)
            })
    ))
}