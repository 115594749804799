import WorkShiftService from "@/services/WorkShiftService";

export const namespaced = true;

export const state = {
    workShifts: [],
    filterParams: null,
    loading: false,
    error: null
}

export const mutations = {
    SET_WORK_SHIFTS(state, workShifts) {
        state.workShifts = workShifts;
    },
    SET_FILTER_PARAMS(state, params) {
        state.filterParams = params;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    DELETE_WORK_SHIFT(state, id) {
        let index = state.workShifts.findIndex(workShift => workShift.id === id);
        state.workShifts.splice(index, 1);
    }
}

export const actions = {
    getWorkShifts({commit}, params) {
        commit("SET_LOADING", true);
        WorkShiftService.getWorkShift(params)
            .then(workShifts => commit("SET_WORK_SHIFTS", workShifts))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    createWorkShift({commit}, payload) {
        return new Promise((resolve, reject) => {
            WorkShiftService.createWorkShiftItem(payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    },
    deleteWorkShift({commit}, id) {
        WorkShiftService.deleteWorkShiftItem(id)
            .then(() => commit("DELETE_WORK_SHIFT", id))
            .catch(error => {
                commit("SET_ERROR", error);
            })
    }
}