export default class Role {
    constructor({id, attributes}) {
        this.id = id;
        this.name = attributes.name;
        this.permissions = attributes.permissions;
    }

    get permissionsList() {
        return this.permissions
            .map(item => item.attributes.custom_name ? item.attributes.custom_name : item.attributes.name).join(', ')
    }
}