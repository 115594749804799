<template>
  <div class="page-wrapper">
    <base-panel
      title="Эталонный объект"
      :is-add-button-visible="false"
    />

    <div class="billing-filter-container">
      <div class="tabs">
        <div class="tabs__header tabs__header--block">
          <div
            @click="tabIndex = tabFilter1"
            :class="{'active': tabIndex === tabFilter1}"
            class="tabs__header-item">
            Расчет по договорам
          </div>
          <div
            @click="tabIndex = tabFilter2"
            :class="{'active': tabIndex === tabFilter2}"
            class="tabs__header-item">
            Расчет по площади
          </div>
          <div
            @click="tabIndex = tabResult"
            :class="{'active': tabIndex === tabResult}"
            class="tabs__header-item">
            Результат
          </div>
        </div>

        <div
          v-show="tabIndex === tabFilter1"
          :class="{'active': tabIndex === tabFilter1}"
          class="tabs__item">
          <standard-building-filter />
        </div>

        <div
          v-show="tabIndex === tabFilter2"
          :class="{'active': tabIndex === tabFilter2}"
          class="tabs__item">
          <standard-building-filter2 />
        </div>

        <div
          v-show="tabIndex === tabResult"
          :class="{'active': tabIndex === tabResult}"
          class="tabs__item">
          <standard-building-filter-result />
        </div>
      </div>

      <div>
        <standard-building-map/>
      </div>
    </div>
  </div>
</template>

<script>
import StandardBuildingFilter from "@/components/Billing/StandardBuildingFilter";
import StandardBuildingFilterResult from "@/components/Billing/StandardBuildingFilterResult";
import StandardBuildingMap from "@/components/Billing/StandardBuildingMap";
import StandardBuildingFilter2 from "@/components/Billing/StandardBuildingFilter2";
import BasePanel from "@/components/BasePanel.vue";

export default {
  name: "StandardBuildingPage",
  components: {
    BasePanel,
    StandardBuildingFilter2,
    StandardBuildingMap,
    StandardBuildingFilterResult,
    StandardBuildingFilter
  },
  data() {
    return {
      tabFilter1: 0,
      tabFilter2: 1,
      tabResult: 2
    }
  },
  computed: {
    tabIndex: {
      get() {
        return this.$store.getters['standardBuilding/tabIndex']
      },
      set(index) {
        this.$store.commit('standardBuilding/SET_TAB_INDEX', index)
      }
    }
  }
}
</script>

<style scoped>
.scroll {
  overflow-y: scroll;
  height: calc(100vh - 120px);
}

.tab-content-custom {
  padding: 1.25rem;
  background-color: #F9FDFF;
}

.tab-content-custom::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #C7D3D9;
  border-radius: 0.2em;
}

.tab-content-custom::-webkit-scrollbar-thumb {
  background-color: #97ACB5;
  border-radius: 0.2em;
}
</style>