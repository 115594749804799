import NetworkSpreadService from "@/services/NetworkSpreadService";

export const namespaced = true;

export const state = {
    spread: {},
    loading: false,
    error: null
}

export const mutations = {
    SET_SPREAD(state, spread) {
        state.spread = spread;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getTaskNetworkSpread({commit}, taskId) {
        commit("SET_LOADING", true);
        NetworkSpreadService.fetchNetworkSpread(taskId)
            .then(networkSpread => commit("SET_SPREAD", networkSpread))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false))
    },
    updateNetworkSpread(context, {taskId, payload}) {
        return new Promise((resolve, reject) => {
            NetworkSpreadService.updateTaskNetworkSpread(taskId, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    }
}
