<script>
export default {
  name: "IconMonitor"
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
    <path d="M5.67497 2.16675H14.9666C16.7141 2.16675 18.1423 3.59172 18.15 5.35123V10.7667V10.9334C18.15 12.683 16.7314 14.1084 14.975 14.1084H11.8C11.0655 14.1084 10.4666 14.7073 10.4666 15.4417V16.2501C10.4666 16.9846 11.0655 17.5834 11.8 17.5834H13.8166C13.8468 17.5834 13.8781 17.5957 13.9037 17.6213C13.9294 17.647 13.9416 17.6783 13.9416 17.7084C13.9416 17.7386 13.9294 17.7698 13.9037 17.7955C13.8781 17.8211 13.8468 17.8334 13.8166 17.8334H6.8583C6.79727 17.8334 6.7333 17.7786 6.7333 17.7001C6.7333 17.6699 6.74559 17.6387 6.77123 17.613C6.79688 17.5874 6.82816 17.5751 6.8583 17.5751H8.87497C9.60945 17.5751 10.2083 16.9762 10.2083 16.2417V15.4334C10.2083 14.6989 9.60945 14.1001 8.87497 14.1001H5.67497C3.92537 14.1001 2.49997 12.6815 2.49997 10.9251V10.7584V5.34175C2.49997 3.59289 3.92611 2.16675 5.67497 2.16675Z" fill="#9192AD" stroke="#9192AD"/>
  </svg>
</template>

<style scoped>

</style>