<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="15"
       height="19"
       viewBox="0 0 15 19"
       fill="none">
    <path d="M7.39835 1.77148C4.76615 1.77148 2.627 3.91064 2.627 6.54283V8.84103C2.627
    9.32611 2.42024 10.0657 2.17372 10.4792L1.25922 11.9981C0.694608 12.9364 1.08427
    13.9782 2.11806 14.3281C5.54547 15.4732 9.24326 15.4732 12.6707 14.3281C13.6329
    14.01 14.0544 12.8728 13.5295 11.9981L12.615 10.4792C12.3764 10.0657 12.1697
    9.32611 12.1697 8.84103V6.54283C12.1697 3.91859 10.0226 1.77148 7.39835 1.77148Z"
          stroke="white"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"/>
    <path d="M8.86982 2.00198C7.90816 1.7281 6.88915 1.7281 5.92749 2.00198C6.15811
    1.41352 6.73067 1 7.39865 1C8.06664 1 8.6392 1.41352 8.86982 2.00198Z"
          stroke="white"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M10.2713 15.2C10.2713 16.5121 8.97279 17.5856 7.38567 17.5856C6.59692
    17.5856 5.86588 17.3152 5.34646 16.8858C4.80603 16.4384 4.50173 15.8323 4.5 15.2"
          stroke="white"
          stroke-width="1.5"
          stroke-miterlimit="10"/>
  </svg>
</template>
<script>
export default {
  name: "IconNotify"
}
</script>
