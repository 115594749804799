<template>
  <div>
    <base-no-data v-if="!$store.state.standardBuilding.buildings.length"/>

    <div class="standard-building-result">
      <div v-for="building in this.$store.getters['standardBuilding/buildings']"
           :key="building.id"
           class="base-card standard-building-result__item">
        <div class="building-block">
          <div class="building-address-block">
            <div class="building-address">{{ getFullAddress(building) }}</div>
            <div @click="$store.commit(
                   'baseMap/SET_CENTER_ZOOM',
                   { center: building.attributes.geometry.coordinates, zoom: 17})
                 "
                 class="building-map">
              <icon-map />
            </div>
          </div>
          <div class="coefficient-block">
            <div class="coefficient-icon">
              <img src="@/assets/img/icons/temperatures.png">

              <div class="base-tooltip">
                <div class="base-tooltip__inner">
                  Отклонение сезонного/не сезонного коэффициентов
                </div>
              </div>
            </div>

            <span class="coefficient"
                  :style="{'color': getCoefficientColor(building.attributes.season_coefficient)}">
              {{ getPercent(building.attributes.season_coefficient) }}
            </span>
            <span class="coefficient-divider">/</span>
            <span class="coefficient"
                  :style="{'color': getCoefficientColor(building.attributes.not_season_coefficient)}">
              {{ getPercent(building.attributes.not_season_coefficient) }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <base-paginate
      v-if="meta && meta.last_page > 1"
      :meta="meta"
      :action="getMethodAction()"
      commit="standardBuilding/SET_PAGE"
    />
  </div>
</template>

<script>
import BaseNoData from "@/components/BaseNoData.vue";
import BasePaginate from "@/components/BasePaginate.vue";
import {mapState} from "vuex";
import IconMap from "@/components/Icons/IconMap.vue";

export default {
  name: "StandardBuildingFilterResult",
  components: {IconMap, BasePaginate, BaseNoData},
  computed: {
    ...mapState({
      meta: state => state.standardBuilding.meta
    }),
    getFullAddress: function () {
      return building => {
        let full_address_arr = [building.attributes.city, building.attributes.settlement, building.attributes.street,
          building.attributes.house, building.attributes.block].filter(item => item !== null)
        return full_address_arr.join(', ')
      }
    }
  },
  methods: {
    getCoefficientColor(coefficient) {
      if (coefficient >= 0 && coefficient <= 1.2) return '#04bd00'
      else if (coefficient > 1.2 && coefficient <= 2) return '#ffbf1c'
      else if (coefficient > 2 && coefficient <= 4) return '#ff6b1c'
      else if (coefficient > 4) return '#E02800'
    },
    getMethodAction() {
      switch (this.$store.state.standardBuilding.method) {
        case 1:
          return 'standardBuilding/getBuildingsV1'
        case 2:
          return 'standardBuilding/getBuildingsV2'
      }
    },
    getPayload() {
      return {
        filter: this.$store.state.standardBuilding.filter_params,
        page: 1
      }
    },
    getPercent(coefficient) {
      let percent_coefficient = (coefficient * 100) - 100;
      if (percent_coefficient > 0) return '+' + percent_coefficient.toFixed(0) + '%'
      else return percent_coefficient.toFixed(0) + '%'
    }
  }
}
</script>

<style lang="scss" scoped>
.standard-building-result {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.building-address-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.building-address {
  display: flex;
  color: var(--text-primary);
  font-weight: 400;
  font-size: 16px;
}

.building-map {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.coefficient-block {
  display: flex;
  align-items: center;
}

.coefficient-icon {
  position: relative;

  & img {
    width: 20px;
    margin-right: 6px;
  }

  & .base-tooltip {
    bottom: -30px;
    min-width: max-content;
    transition-delay: .3s;

    &__inner {
      padding: 4px 8px;
    }
  }

  &:hover {
    & .base-tooltip {
      transform: scale(1);
      visibility: visible;
      opacity: 1;
      pointer-events: initial;
    }
  }
}

.coefficient {
  font-weight: 600;
}

.coefficient-divider {
  margin: 0 3px;
}
</style>
