<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      Работа
      <span @click="$modal.hide('work-modal')"
            class="base-popup__close">
        <icon-close />
      </span>
    </div>
    <div class="popup-form-group">
      <div class="form-item">
        <div class="form-item__name">Работа</div>
        <div class="form-item__value">{{ work.name }}</div>
      </div>
      <div class="form-item form-item--vertical form-item--border-top">
        <div class="form-item__name">Комментарий</div>
        <div class="form-item__value">
          {{ nullFilter(work.comment) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from "@/components/Icons/IconClose";
import {nullFilter} from "@/utils/filters";

export default {
  name: "WorkModal",
  components: {IconClose},
  props: {
    taskId: {
      type: Number,
      required: true
    },
    work: {
      type: Object,
      required: true
    }
  },
  methods: {nullFilter}
}
</script>

<style lang="scss" scoped>
</style>
