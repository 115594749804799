<template>
  <div class="additional-option-wrapper"
       :class="{'always-open': isCollapseVisible}">
    <div class="additional-option">
      <div class="additional-option__icon">
        <icon-task-square/>
      </div>
      <div class="additional-option__name"><span>Оборудование</span>
        <span class="additional-option__quantity">{{ equipmentsList.length }}</span>
      </div>
      <div class="additional-option__buttons">
        <button
          class="additional-option__add"
          type="button"
          @click="showEquipmentFormPopup()">
          <icon-plus/>
        </button>
        <button
          @click="isCollapseVisible = !isCollapseVisible"
          class="additional-option__toggle"
          :class="{'rotate': isCollapseVisible}">
          <icon-arrow-double-up/>
        </button>
      </div>
    </div>
    <div class="additional-options-list">
      <div class="additional-options-list__inner">
        <div v-for="equipment in equipmentsList"
             :key="equipment.id"
             @click="showEquipmentModal(equipment.id)"
             class="added-item added-item--flex added-item--cur-pointer">
          <div class="added-item__info">
            <div class="added-item__name">{{ equipment.typeName }} - {{ equipment.name }}</div>
            <div class="added-item__additional added-item__additional--span-margin-0">
              <span>Сер. номер {{ equipment.serial }}</span>
              <span v-if="equipment.inventoryNumber"> | </span>
              <span v-if="equipment.inventoryNumber">Инв. номер {{ equipment.inventoryNumber }}</span>
            </div>
            <div class="added-item__additional">
              <div class="equipment-parameters">
                <div
                  v-for="parameter in equipment.parameters"
                  :key="`${equipment.id}-${parameter.id}`"
                  class="equipment-parameters__item">
                  <div class="equipment-parameters__item-short-name">{{parameter.shortName}}</div>
                  <div class="equipment-parameters__item-name">{{parameter.name}}, {{parameter.measure}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="added-item__right">
            <div @click.stop="showEquipmentFormPopup(equipment)">
              <icon-pencil class="svg added-item__tool added-item__tool--size-20"/>
            </div>
            <div @click.stop="deleteEquipment(equipment.id)">
              <icon-trash class="svg added-item__tool"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {showAlert, showToast, showConfirmationModal} from "@/utils/notification";
import IconArrowDoubleUp from "@/components/Icons/IconArrowDoubleUp.vue";
import IconPlus from "@/components/Icons/IconPlus.vue";
import IconPencil from "@/components/Icons/IconPencil.vue";
import IconTrash from "@/components/Icons/IconTrash.vue";
import IconTaskSquare from "@/components/Icons/IconTaskSquare.vue";
import EquipmentsForm from "@/components/Telemetry/admin/NetworkObject/EquipmentsForm.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";

export default {
  name: "EquipmentsList",
  components: {
    IconTaskSquare,
    IconTrash,
    IconPencil,
    IconPlus,
    IconArrowDoubleUp
  },
  props: {
    networkObjectId: {
      type: Number,
      required: true
    },
    equipmentsList: {
      type: Array,
      required: this
    }
  },
  data() {
    return {
      isCollapseVisible: true
    }
  },
  methods: {
    showEquipmentModal(equipmentId) {
      this.$store.dispatch('networkObjectPage/getEquipment', equipmentId);
      this.$store.dispatch('baseSideModal/openModal', {
        modalContentType: 'networkObjectEquipment'
      })
    },
    showEquipmentFormPopup(equipment = {}) {
      this.$modal.show(
        EquipmentsForm,
        {
          'networkObjectId': this.networkObjectId,
          'equipment': equipment,
          'popupName': 'equipment-modal'
        },
        {
          name: 'equipment-modal',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460),
          scrollable: true
        })
    },
    deleteEquipment(id) {
      showConfirmationModal('Вы уверены, что хотите удалить оборудование? Это действие невозвратно.')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('networkEquipments/deleteNetworkEquipment', id)
              .then(() => {
                this.$store.dispatch('networkObjectPage/getNetworkObjectEquipments', this.networkObjectId);
                showToast('Сетевое оборудование удалено', 'success');
              })
              .catch(error => showAlert('Ошибка', error));
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.equipment-parameters {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;

  &__item {
    display: flex;
    gap: 4px;

    border-radius: 6px;
    padding: 4px 8px;
    background-color: var(--panel-bg);

    &-short-name {
      @include font-style(400, 14px, 16px, var(--text-primary));
    }

    &-name {
      @include font-style(400, 14px, 16px, var(--text-secondary));
    }
  }
}
</style>
