import StructuralUnitsService from "@/services/StructuralUnitsService";

export const namespaced = true;

export const state = {
    structuralUnits: [],
    structuralUnit: null,
    userStructuralUnitsList: [],
    error: null,
    loading: false,
    userStructuralUnitsLoading: false,
    query: ''
}

export const mutations = {
    SET_STRUCTURAL_UNITS(state, structuralUnits) {
        state.structuralUnits = structuralUnits;
    },
    SET_STRUCTURAL_UNIT(state, structuralUnit) {
        state.structuralUnit = structuralUnit;
    },
    SET_USER_STRUCTURAL_UNITS_LIST(state, structuralUnitsList) {
        state.userStructuralUnitsList = structuralUnitsList;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_USER_STRUCTURAL_UNIT_LOADING(state, loading) {
        state.userStructuralUnitsLoading = loading;
    },
    SET_QUERY(state, query) {
        state.query = query;
    }
}

export const actions = {
    getStructuralUnitsList({commit}) {
        commit("SET_LOADING", true);
        StructuralUnitsService.fetchStructuralUnitsList()
            .then(structuralUnits => commit("SET_STRUCTURAL_UNITS", structuralUnits))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false))
    },
    getStructuralUnit({commit}, id) {
        return new Promise((resolve, reject) =>  {
            StructuralUnitsService.fetchStructuralUnit(id)
                .then(structuralUnit => {
                    commit("SET_STRUCTURAL_UNIT", structuralUnit);
                    resolve(structuralUnit);
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    },
    getUserStructuralUnitsList({commit}) {
        commit("SET_USER_STRUCTURAL_UNIT_LOADING", true);
        StructuralUnitsService.fetchUserStructuralUnitsList()
            .then(structuralUnits => commit("SET_USER_STRUCTURAL_UNITS_LIST", structuralUnits))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_USER_STRUCTURAL_UNIT_LOADING", false))
    },
    createStructuralUnit(context, payload) {
        return new Promise((resolve, reject) => {
            StructuralUnitsService.createStructuralUnit(payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    updateStructuralUnit(context, {id, payload}) {
        return new Promise((resolve, reject) => {
            StructuralUnitsService.updateStructuralUnit(id, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    deleteStructuralUnit(context, id) {
        return new Promise((resolve, reject) => {
            StructuralUnitsService.deleteStructuralUnit(id)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    assignUserStructuralUnit(context, {structuralUnitId, userId}) {
        return new Promise((resolve, reject) => {
            StructuralUnitsService.assignUserStructuralUnit(structuralUnitId, userId)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    }
}

export const getters = {
    getStructuralUnitName: state => id => {
        const structuralUnit = state.structuralUnits.find(structuralUnit => structuralUnit.id === id);
        return structuralUnit ? structuralUnit.name : ''
    },
    getStructuralUnitByQuery: state => {
        return state.structuralUnits.filter(structuralUnit => {
            return structuralUnit.name.toLowerCase().includes(state.query.toLowerCase());
        })
    }
}