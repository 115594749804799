<template>
  <div class="issue-export">
    <button @click="isExportIssueDropdownVisible = !isExportIssueDropdownVisible"
            class="button button--mode-outline button--mode-outline-icon"
            :class="{'active': isExportIssueDropdownVisible}">
      <icon-export2/>
    </button>

    <div class="issue-export__dropdown"
         :class="{'visible': isExportIssueDropdownVisible}">
      <div @click="savePDF()"
           class="issue-export__dropdown-option">Выгрузить в PDF</div>
      <div @click="saveExcel()"
           class="issue-export__dropdown-option">Выгрузить в Excel</div>
      <div @click="saveWord()"
           class="issue-export__dropdown-option">Выгрузить в Word</div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {saveAs} from 'file-saver'
import {
  AlignmentType,
  convertMillimetersToTwip,
  Paragraph,
  TableRow,
  WidthType
} from 'docx/build'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import IconExport2 from "@/components/Icons/IconExport2.vue";
import TaskState from "@/models/serviceDesk/TaskState";
import {formatDateTime} from "@/utils/filters";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const docx = require('docx');

export default {
  name: "IssueExport",
  components: {IconExport2},
  props: {
    issue: {
      required: true
    }
  },
  data() {
    return {
      isExportIssueDropdownVisible: false
    }
  },
  computed: {
    ...mapState({
      leaks: state => state.issueModal.leaksList,
      issueHistory: state => state.issueModal.history
    })
  },
  mounted() {
    document.addEventListener('click', e => {
      if (!e.target.closest('.issue-export')) {
        this.isExportIssueDropdownVisible = false;
      }
    })
  },
  methods: {
    createExportData() {
      return [
        ['Статус', this.issue.state.label],
        ['Тип заявки', this.issue.type_name ? this.issue.type_name : '-'],
        ['Категория заявки', `${
            this.issue.category ? this.issue.category.group_name + ':' + this.issue.category.name : '-'
        }`],
        ['Адрес', this.issue.getFullAddress()],
        ['Создатель', this.issue.author],
        ['Основание заявки', this.issue.based_on],
        ['Комментарий', this.issue.description],
        ['Отложена до', formatDateTime(this.issue.activated_in)],
        ['Плановая дата начала', formatDateTime(this.issue.planned_start_at)],
        ['Плановая дата окончания', formatDateTime(this.issue.planned_end_at)],
        ['Утечки', this.getLeaksRow()],
        ['Задания', this.getTasksRow()]
      ]
    },
    savePDF() {
      const docDefinition = {
        content: [
          {
            text: `Заявка №${this.issue.id} от` +
                ` ${ this.$moment(this.issue.created_at).format(process.env.VUE_APP_BASE_DATE_TIME_FORMAT) }`,
            style: 'header'
          },
          {
            style: 'table',
            table: {
              widths: ['auto', '*'],
              body: this.createExportData()
            }
          },
          {
            text: 'История изменений заявки',
            style: 'subHeader'
          },
          {
            style: 'table',
            table: {
              widths: ['auto', 'auto', '*'],
              body: this.getIssueHistory()
            }
          }
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 10]
          },
          subHeader: {
            fontSize: 16,
            bold: true,
            margin: [0, 0, 0, 10]
          },
          table: {
            margin: [0, 5, 0, 15]
          }
        }
      }

      const pdfMake = require('pdfmake');
      pdfMake.createPdf(docDefinition).open();
    },
    saveExcel() {
      const ExcelJS = require('exceljs');
      const wb = new ExcelJS.Workbook();

      const ws = wb.addWorksheet('Заявка #' + this.issue.id);

      ws.columns = [
        { key: 'label', width: 26 },
        { key: 'value', width: 35 }
      ];

      ws.addRows(this.createExportData())
      ws.addRow(['']);
      ws.addRow(['История изменений заявки']);
      ws.addRows(this.getIssueHistory())

      const commentRow = ws.getRow(7);
      commentRow.eachCell({includeEmpty: true}, cell => {
        cell.alignment = {wrapText: true, vertical: "middle"}
      });

      const leaksRow = ws.getRow(11);
      leaksRow.eachCell({includeEmpty: true}, cell => {
        cell.alignment = {wrapText: true, vertical: "middle"}
      });

      const tasksRow = ws.getRow(12);
      tasksRow.eachCell({includeEmpty: true}, cell => {
        cell.alignment = {wrapText: true, vertical: "middle"}
      });

      wb.xlsx.writeBuffer().then(function (data) {
        let blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"})
        saveAs(blob, `Заявка .xlsx`)
      })
    },
    saveWord() {
      let tableRows = [];
      this.createExportData().forEach(row => {
        const [firstCol, secondCol] = row;

        if (firstCol === 'Утечки') {
          const leaks = this.leaks.map(leak => {

            let leakData;
            if (leak.type === 1) {
              leakData = `Тип: ${leak.getTypeLabel()}, ${leak.wormholes_count} шт.`
            } else if (leak.type === 2) {
              leakData = `Тип: ${leak.getTypeLabel()}, ${leak.width}x${leak.height} шт.\n`
            } else if (leak.type === 3 || leak.type === 4) {
              leakData = `Тип: ${leak.getTypeLabel()}, Ø${leak.diameter} \n`
            }

            return [
                new Paragraph({
                  text: leak.getCategoryLabel()
                }),
                new Paragraph({
                  text: leakData
                }),
                new Paragraph({
                  text: `Объём потерь: ${leak.volume.toFixed(2)}`
                }),
                new Paragraph({
                  text: `Факт. дата начала: ${
                      formatDateTime(leak.actual_start_date)
                  }`
                }),
                new Paragraph({
                  text: `Факт. дата окончания ${
                      formatDateTime(leak.actual_end_date)
                  }`
                }),
                new Paragraph({
                  children: []
                })
            ]
          })

          tableRows.push(new TableRow({
            children: [
              new docx.TableCell({
                width: {
                  size: convertMillimetersToTwip(50),
                  type: WidthType.DXA
                },
                verticalAlign: AlignmentType.CENTER,
                children: [new Paragraph({
                  text: firstCol,
                  alignment: AlignmentType.CENTER
                })]
              }),
              new docx.TableCell({
                width: {
                  size: convertMillimetersToTwip(130),
                  type: WidthType.DXA
                },
                verticalAlign: AlignmentType.CENTER,
                children: leaks.flat()
              })
            ]
          }))

        } else if (firstCol === 'Задания') {

          const tasks = this.issue.tasks.map(task => {
            return [
                new Paragraph({
                  text: `${task.type.name} #${task.id}, ${this.getTaskStateLabel(task.state)}`
                }),
                task.brigade ? new Paragraph({
                  text: `Бригада: ${task.brigade.name}`
                }) : [],
                task.actual_start_date ? new Paragraph({
                  text: `Дата начала: ${
                      formatDateTime(task.actual_start_date)
                  }`
                }) : [],
              task.actual_end_date ? new Paragraph({
                text: `Дата окончания: ${
                    formatDateTime(task.actual_end_date)
                }`
              }) : [],
                new Paragraph({
                  children: []
                })
            ]
          })

          tableRows.push(new TableRow({
            children: [
              new docx.TableCell({
                width: {
                  size: convertMillimetersToTwip(50),
                  type: WidthType.DXA
                },
                verticalAlign: AlignmentType.CENTER,
                children: [new Paragraph({
                  text: firstCol,
                  alignment: AlignmentType.CENTER
                })]
              }),
              new docx.TableCell({
                width: {
                  size: convertMillimetersToTwip(130),
                  type: WidthType.DXA
                },
                verticalAlign: AlignmentType.CENTER,
                children: tasks.flat()
              })
            ]
          }))
        } else {
          tableRows.push(new TableRow({
            children: [
              new docx.TableCell({
                width: {
                  size: convertMillimetersToTwip(50),
                  type: WidthType.DXA
                },
                verticalAlign: AlignmentType.CENTER,
                children: [new Paragraph({
                  text: firstCol,
                  alignment: AlignmentType.CENTER
                })]
              }),
              new docx.TableCell({
                width: {
                  size: convertMillimetersToTwip(130),
                  type: WidthType.DXA
                },
                verticalAlign: AlignmentType.CENTER,
                children: [new Paragraph({
                  text: secondCol
                })]
              })
            ]
          }))
        }
      })

      const historyTableRows = this.issueHistory.map(historyItem => {
        return new docx.TableRow({
          children: [
              new docx.TableCell({
                width: {
                  size: convertMillimetersToTwip(40),
                  type: WidthType.DXA
                },
                verticalAlign: AlignmentType.CENTER,
                children: [new Paragraph({
                  text: formatDateTime(historyItem.historyDate),
                  alignment: AlignmentType.CENTER
                })]
              }),
            new docx.TableCell({
              width: {
                size: convertMillimetersToTwip(40),
                type: WidthType.DXA
              },
              verticalAlign: AlignmentType.CENTER,
              children: [new Paragraph({
                text: historyItem.userName,
                alignment: AlignmentType.CENTER
              })]
            }),
            new docx.TableCell({
              width: {
                size: convertMillimetersToTwip(100),
                type: WidthType.DXA
              },
              verticalAlign: AlignmentType.CENTER,
              children: [new Paragraph({
                text: historyItem.message,
                alignment: AlignmentType.LEFT
              })]
            })
          ]
        })
      })


      const doc = new docx.Document({
        sections: [{
          properties: {
            page: {
              margin: {
                top: 720,
                right: 720,
                bottom: 720,
                left: 720
              }
            }
          },
          children: [
            new docx.Paragraph({
              children: [
                new docx.TextRun({
                  text: 'Заявка №' + this.issue.id,
                  size: 26
                })
              ],
              alignment: AlignmentType.CENTER
            }),
            new docx.Paragraph({}),
            new docx.Table({
              rows: tableRows
            }),
            new docx.Paragraph({}),
            new docx.Paragraph({
              children: [
                new docx.TextRun({
                  text: 'История изменений заявки',
                  size: 26
                })
              ],
              alignment: AlignmentType.CENTER
            }),
            new docx.Paragraph({}),
            new docx.Table({
              rows: historyTableRows
            })
          ]
        }]
      });

      docx.Packer.toBlob(doc).then(blob => {
        saveAs(blob, `Заявка №${this.issue.id}.docx`);
      });
    },
    getLeaksRow() {
      if (this.leaks.length) {
        const leaks = this.leaks.map(leak => {
          let row = `${leak.getCategoryLabel()} \n`

          if (leak.type === 1) {
            row += `Тип: ${leak.getTypeLabel()}, ${leak.wormholes_count} шт. \n`
          } else if (leak.type === 2) {
            row += `Тип: ${leak.getTypeLabel()}, ${leak.width}x${leak.height} шт.\n`
          } else if (leak.type === 3 || leak.type === 4) {
            row += `Тип: ${leak.getTypeLabel()}, Ø${leak.diameter} \n`
          }

          row += `Объём потерь: ${leak.volume.toFixed(2)} \n`
          row += `Факт. дата начала: ${
              formatDateTime(leak.actual_start_date)
          } \n`
          row += `Факт. дата окончания ${
              formatDateTime(leak.actual_end_date)
          } \n`
          return row
        })

        return leaks.join('\n\n')
      } else return '-'
    },
    getTasksRow() {
      if (this.issue.tasks.length) {
        const tasks = this.issue.tasks.map(task => {
          let row = `${task.type.name} #${task.id}, ${this.getTaskStateLabel(task.state)} \n`
          if (task.brigade) row += `Бригада: ${task.brigade.name} \n`
          if (task.actual_start_date) row += `Дата начала: ${
              formatDateTime(task.actual_start_date)
          } \n`
          if (task.actual_end_date) row += `Дата окончания: ${
              formatDateTime(task.actual_end_date)
          }`

          return row;
        })

        return tasks.join('\n\n');
      } else return '-'
    },
    getIssueHistory() {
      return this.issueHistory.map(item => {
        return [
          formatDateTime(item.historyDate),
          item.userName,
          item.message
        ]
      })
    },
    getTaskStateLabel(state) {
      return new TaskState(state).label;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.issue-export {
  position: relative;

  &__dropdown {
    visibility: hidden;
    opacity: 0;

    position: absolute;
    top: 60px;
    left: -70px;

    display: flex;
    flex-direction: column;
    gap: 4px;

    border-radius: 16px;
    border: 1px solid var(--border-active);
    background: var(--panel);
    box-shadow: 0px 6px 20px 0px rgba(6, 5, 50, 0.10);

    min-width: 195px;
    padding: 8px 0;
    z-index: 1;

    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }

  &__dropdown-option {
    padding: 4px 24px;

    cursor: pointer;
    @include font-style(400, 16px, 24px, var(--text-primary));

    &:hover {
      background-color: var(--panel-bg);
    }
  }
}
</style>
