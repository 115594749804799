<template>
  <base-popup
    :title="popupTitle"
    :name="popupName">
    <template #body>
      <div class="form-item">
        <div class="form-item__name">Наименование</div>
        <div class="form-item__value">
          <input
            v-model="payload.name"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите наименование"
            required
          >
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Тип</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.type"
            :options="defectTypesList"
            :reduce="type => type.id"
            :get-option-label="(option) => option.name"
            class="vue-select-input"
            placeholder="Выберите из списка"
          />
        </div>
      </div>
    </template>

    <template #footer>
      <div
        @click="checkFormType()"
        class="button">
        Сохранить
      </div>
    </template>
  </base-popup>
</template>

<script>
import BasePopup from "@/components/BasePopup.vue";

export default {
  name: "DefectsForm",
  components: {BasePopup},
  props: {
    defect: {
      type: Object,
      default: () => {
      }
    },
    createDefect: {
      type: Function,
      required: true
    },
    updateDefect: {
      type: Function,
      required: true
    },
    defectTypesList: {
      type: Array,
      required: true
    },
    popupName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      payload: {
        name: null,
        type: null
      }
    }
  },
  computed: {
    popupTitle() {
      return !Object.keys(this.defect).length ? 'Создать дефект' : 'Редактирование дефекта'
    }
  },
  mounted() {
    if (Object.keys(this.defect).length) {

      const {name, type} = this.defect;
      this.payload.name = name;
      this.payload.type = type.id;
    }
  },
  methods: {
    checkFormType() {
      if (!Object.keys(this.defect).length) this.createDefect(this.payload)
      else this.updateDefect(this.defect.id, this.payload)
    }
  }
}
</script>

<style scoped>

</style>