<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      Связать обращение
      <span @click="$modal.hide('link-appeal-modal')"
            class="base-popup__close">
        <icon-close/>
      </span>
    </div>
    <div class="popup-form-group">

      <div class="form-item">
        <div class="form-item__name">Заявка</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.issue_id"
            :options="issues"
            :reduce="issue => issue.id"
            :get-option-label="(option) => '#' + option.id + ' ' + option.getFullAddress()"
            placeholder="Выберите из списка"
            class="vue-select-input"
          />
        </div>
      </div>

    </div>
    <div class="base-popup__footer">
      <div @click="submitForm()"
           class="button">
        Связать
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from "@/components/Icons/IconClose";
import {mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";
export default {
  name: "LinkAppealForm",
  components: {IconClose},
  props: {
    appealId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      payload: {
        issue_id: null
      }
    }
  },
  computed: {
    ...mapState({
      issues: state => state.issues.issues,
      appealsPage: state => state.appeals.page
    })
  },
  methods: {
    submitForm() {
      this.$store.dispatch('appeals/linkAppealToIssue', {
        appeal_id: this.appealId,
        params: this.payload
      })
          .then(() => {
            this.$modal.hide('link-appeal-modal');
            this.$store.dispatch('appeals/getAppeals', this.appealsPage);
            showToast('Обращение связано', 'success');

            this.payload.issue_id = null;
          })
          .catch(error => showAlert('Ошибка', error))
    }
  }
}
</script>

<style scoped>

</style>
