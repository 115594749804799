<script>
export default {
  name: "IconSun"
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
    <path d="M16 10.0001C16 12.9456 13.6122 15.3334 10.6666 15.3334C7.72113 15.3334 5.33331 12.9456 5.33331 10.0001C5.33331 7.05456 7.72113 4.66675 10.6666 4.66675C13.6122 4.66675 16 7.05456 16 10.0001Z" fill="#9192AD" stroke="#9192AD"/>
    <path d="M10.6667 19.1333C10.2083 19.1333 9.83333 18.7916 9.83333 18.3333V18.2666C9.83333 17.8083 10.2083 17.4333 10.6667 17.4333C11.125 17.4333 11.5 17.8083 11.5 18.2666C11.5 18.7249 11.125 19.1333 10.6667 19.1333ZM16.6167 16.7833C16.4 16.7833 16.1917 16.6999 16.025 16.5416L15.9167 16.4333C15.5917 16.1083 15.5917 15.5833 15.9167 15.2583C16.2417 14.9333 16.7667 14.9333 17.0917 15.2583L17.2 15.3666C17.525 15.6916 17.525 16.2166 17.2 16.5416C17.0417 16.6999 16.8333 16.7833 16.6167 16.7833ZM4.71667 16.7833C4.5 16.7833 4.29167 16.6999 4.125 16.5416C3.8 16.2166 3.8 15.6916 4.125 15.3666L4.23333 15.2583C4.55833 14.9333 5.08333 14.9333 5.40833 15.2583C5.73333 15.5833 5.73333 16.1083 5.40833 16.4333L5.3 16.5416C5.14167 16.6999 4.925 16.7833 4.71667 16.7833ZM19 10.8333H18.9333C18.475 10.8333 18.1 10.4583 18.1 9.99992C18.1 9.54159 18.475 9.16659 18.9333 9.16659C19.3917 9.16659 19.8 9.54159 19.8 9.99992C19.8 10.4583 19.4583 10.8333 19 10.8333ZM2.4 10.8333H2.33333C1.875 10.8333 1.5 10.4583 1.5 9.99992C1.5 9.54159 1.875 9.16659 2.33333 9.16659C2.79167 9.16659 3.2 9.54159 3.2 9.99992C3.2 10.4583 2.85833 10.8333 2.4 10.8333ZM16.5083 4.99158C16.2917 4.99158 16.0833 4.90825 15.9167 4.74992C15.5917 4.42492 15.5917 3.89992 15.9167 3.57492L16.025 3.46659C16.35 3.14159 16.875 3.14159 17.2 3.46659C17.525 3.79159 17.525 4.31659 17.2 4.64159L17.0917 4.74992C16.9333 4.90825 16.725 4.99158 16.5083 4.99158ZM4.825 4.99158C4.60833 4.99158 4.4 4.90825 4.23333 4.74992L4.125 4.63325C3.8 4.30825 3.8 3.78325 4.125 3.45825C4.45 3.13325 4.975 3.13325 5.3 3.45825L5.40833 3.56659C5.73333 3.89159 5.73333 4.41659 5.40833 4.74159C5.25 4.90825 5.03333 4.99158 4.825 4.99158ZM10.6667 2.53325C10.2083 2.53325 9.83333 2.19159 9.83333 1.73325V1.66659C9.83333 1.20825 10.2083 0.833252 10.6667 0.833252C11.125 0.833252 11.5 1.20825 11.5 1.66659C11.5 2.12492 11.125 2.53325 10.6667 2.53325Z" fill="#9192AD"/>
  </svg>
</template>

<style scoped>

</style>