<template>
  <div class="base-table">
    <div class="base-table__container">
      <div class="base-table__header base-table__column--mechanical-equipment">
        <div class="base-table__header-cell">ID</div>
        <div class="base-table__header-cell">Наименование</div>
        <div class="base-table__header-cell">Тип топлива</div>
        <div class="base-table__header-cell">Расход топлива</div>
        <div class="base-table__header-cell">Тип масла</div>
        <div class="base-table__header-cell">Расход масла</div>
        <div class="base-table__header-cell"/>
      </div>

      <div v-for="equipment in mechanicalEquipmentsList"
           :key="equipment.id"
           class="base-table__row base-table__column--mechanical-equipment">
        <div class="base-table__column">
          <div class="base-table__text">{{ equipment.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ equipment.name }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ equipment.fuelType.name }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ equipment.fuelConsumption }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ equipment.oilType.name }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ equipment.oilConsumption }}</div>
        </div>
        <div class="base-table__column base-table__column--content-right">
          <div class="base-table__short-info">
            <div @click="$emit('edit-mechanical-equipment', equipment)">
              <icon-pencil class="base-table__short-info--p-2" />
            </div>
            <div @click="$emit('delete-mechanical-equipment', equipment.id)">
              <icon-trash />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import IconPencil from "@/components/Icons/IconPencil.vue";
import IconTrash from "@/components/Icons/IconTrash.vue";

export default {
  name: "MechanicalEquipmentsList",
  components: {IconTrash, IconPencil},
  props: {
    mechanicalEquipmentsList: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.base-table__column--mechanical-equipment {
  grid-template-columns: 4.07% 18.97% 18.97% 13.55% 13.55% 10.16% 1fr;
}
</style>
