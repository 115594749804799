<template>
  <div class="base-table">
    <div class="base-table__container">
      <div class="base-table__header base-table__column--consumables">
        <div class="base-table__header-cell">ID</div>
        <div class="base-table__header-cell">Наименование</div>
        <div class="base-table__header-cell"/>
      </div>

      <div v-for="consumable in consumablesList"
           :key="consumable.id"
           class="base-table__row base-table__column--consumables">
        <div class="base-table__column">
          <div class="base-table__text">{{ consumable.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ consumable.name }}</div>
        </div>
        <div class="base-table__column base-table__column--content-right">
          <div class="base-table__short-info">
            <div @click="$emit('delete-consumable', consumable.id)">
              <icon-trash />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconTrash from "@/components/Icons/IconTrash.vue";

export default {
  name: "ConsumablesList",
  components: {IconTrash},
  props: {
    consumablesList: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.base-table__column--consumables {
  grid-template-columns: 4.07% 40% 1fr;
}
</style>
