<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="20"
       height="20"
       viewBox="0 0 20 20"
       fill="none">
    <path
      d="M10.3324 7.19375H15.0574M4.94141 7.19375L5.61641 7.86875L7.64141 5.84375M10.3324 13.4937H15.0574M4.94141
      13.4937L5.61641 14.1687L7.64141 12.1437"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M7.3 19H12.7C17.2 19 19 17.2 19 12.7V7.3C19 2.8 17.2 1 12.7 1H7.3C2.8 1 1 2.8 1 7.3V12.7C1 17.2 2.8 19 7.3 19Z"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconWorksList"
}
</script>

<style scoped>

</style>
