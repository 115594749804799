<template>
  <div class="additional-option-wrapper always-open">
    <div class="additional-option">
      <div class="additional-option__icon">
        <icon-task-square/>
      </div>
      <div class="additional-option__name"><span>Параметры</span>
        <span class="additional-option__quantity">{{ parametersList.length }}</span>
      </div>
      <div class="additional-option__buttons">
        <button
          class="additional-option__add"
          type="button"
          @click="showParameterFormPopup()">
          <icon-plus/>
        </button>
      </div>
    </div>
    <div class="additional-options-list">
      <div class="additional-options-list__inner">
        <table class="base-modal-table">
          <thead>
            <tr>
              <th class="base-modal-table__header-cell">Полное наименование</th>
              <th class="base-modal-table__header-cell base-modal-table__header-cell--center">
                Краткое <br>наименование
              </th>
              <th class="base-modal-table__header-cell base-modal-table__header-cell--center">Ед. изм.</th>
              <th class="base-modal-table__header-cell base-modal-table__header-cell--center">Маска ввода</th>
              <th class="base-modal-table__header-cell base-modal-table__header-cell--center"/>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="parameter in parametersList"
              :key="parameter.id"
            >
              <td class="base-modal-table__body-cell">{{ parameter.name }}</td>
              <td class="base-modal-table__body-cell base-modal-table__body-cell--center">{{ parameter.shortName }}</td>
              <td class="base-modal-table__body-cell base-modal-table__body-cell--center">{{ parameter.measure }}</td>
              <td class="base-modal-table__body-cell base-modal-table__body-cell--center">{{ parameter.inputMask }}</td>
              <td class="base-modal-table__body-cell base-modal-table__body-cell--center">
                <div class="base-modal-table__flex-block base-modal-table__flex-block--centered">
                  <div @click="showParameterFormPopup(parameter)">
                    <icon-pencil class="svg added-item__tool added-item__tool--size-20"/>
                  </div>
                  <div @click.stop="showParameterProfileModal(parameter)">
                    <icon-settings4 class="svg added-item__tool"/>
                  </div>
                  <div @click="deleteParameter(parameter.id)">
                    <icon-trash class="svg added-item__tool"/>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {showAlert, showToast, showConfirmationModal} from "@/utils/notification";
import IconPlus from "@/components/Icons/IconPlus.vue";
import IconTaskSquare from "@/components/Icons/IconTaskSquare.vue";
import IconTrash from "@/components/Icons/IconTrash.vue";
import IconPencil from "@/components/Icons/IconPencil.vue";
import {mapState} from "vuex";
import {modalAdaptiveWidth} from "@/utils/helpers";
import EquipmentParametersForm from "@/components/Telemetry/admin/NetworkObject/EquipmentParametersForm.vue";
import IconSettings4 from "@/components/Icons/IconSettings4.vue";
import EquipmentParameterProfile from "@/components/Telemetry/admin/NetworkObject/EquipmentParameterProfile.vue";

export default {
  name: "EquipmentParametersList",
  components: {IconSettings4, IconPencil, IconTrash, IconTaskSquare, IconPlus},
  props: {
    parametersList: {
      type: Array,
      required: true
    },
    equipmentId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapState({
      networkObject: state => state.networkObjectPage.networkObject
    })
  },
  methods: {
    showParameterFormPopup(equipmentParameter = {}) {
      this.$modal.show(
        EquipmentParametersForm,
        {
          'popupName': 'equipment-parameter-form',
          'equipmentParameter': equipmentParameter,
          'equipmentId': this.equipmentId
        },
        {
          name: 'equipment-parameter-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460),
          scrollable: true
        }
      )
    },
    showParameterProfileModal(parameter) {
      const equipmentParameter = JSON.parse(JSON.stringify(parameter));

      this.$store.dispatch('networkObjectPage/getParameterProfile', {
        equipmentId: this.equipmentId,
        parameterId: parameter.id
      })
        .then(profile => {
          if (profile.length === 0) {
            equipmentParameter.profile = [];

            for (let i = 0; i < 24; i++) {
              equipmentParameter.profile.push({
                min: null,
                base: null,
                max: null,
                hour: i
              })
            }
          } else {
            equipmentParameter.profile = JSON.parse(JSON.stringify(profile));
          }

          this.$modal.show(
            EquipmentParameterProfile,
            {
              'popupName': 'equipment-parameter-profile',
              'parameter': equipmentParameter,
              'equipmentId': this.equipmentId
            },
            {
              name: 'equipment-parameter-profile',
              classes: 'base-popup',
              height: 'auto',
              width: modalAdaptiveWidth(706),
              scrollable: true
            }
          )
        })
        .catch(error => showAlert('Ошибка', error))
    },
    deleteParameter(parameterId) {
      showConfirmationModal('Вы уверены, что хотите удалить параметр? Это действие невозвратно.')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('networkEquipmentParameters/deleteNetworkEquipmentParameter', {
              equipmentId: this.equipmentId,
              parameterId: parameterId
            })
              .then(() => {
                this.$store.dispatch('networkObjectPage/getNetworkObjectEquipments', this.networkObject.id)
                  .then(() => this.$store.dispatch('networkObjectPage/getEquipment', this.equipmentId))
                showToast('Параметр удалён', 'success');
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style scoped>
.parameter {
  padding: 10px;
}

.parameter__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.parameter__name {
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  color: var(--text-primary);
  line-height: 1;
}

.parameter__actions {
  display: flex;
}

.parameter__text span {
  font-size: 14px;
  font-weight: 600;
}

.parameter__text span:first-child {
  color: var(--text-link);
  margin-right: 3px;
}

.parameter__text span:last-child {
  color: var(--text-primary);
}

.parameter__add_btn {
  width: 100%;
  text-align: center;
  cursor: pointer;
  color: var(--text-link);
  font-weight: 600;
}
</style>
