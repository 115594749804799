<template>
  <div
    v-if="currentWeather"
    class="base-weather"
  >
    <component :is="weatherIconMap[currentWeather.conditionId]"/>
    <div class="base-weather__temperature">
      <span v-if="isTemperatureAboveZero">+</span>{{currentWeather.temperature.toFixed(1)}} C°
    </div>
  </div>
</template>

<script>
import IconThunderstorm from "@/components/Icons/IconThunderstorm.vue";
import IconDrizzle from "@/components/Icons/IconDrizzle.vue";
import IconRain from "@/components/Icons/IconRain.vue";
import IconSnow from "@/components/Icons/IconSnow.vue";
import IconAtmosphere from "@/components/Icons/IconAtmosphere.vue";
import IconClear from "@/components/Icons/IconClear.vue";
import IconClouds from "@/components/Icons/IconClouds.vue";
import {mapState} from "vuex";
export default {
  name: "BaseCurrentWeather",
  data() {
    return {
      timer: null,
      weatherIconMap: {
        1: IconThunderstorm,
        2: IconDrizzle,
        3: IconRain,
        4: IconSnow,
        5: IconAtmosphere,
        6: IconClear,
        7: IconClouds
      }
    }
  },
  computed: {
    ...mapState({
      currentWeather: state => state.weather.currentWeather
    }),
    isTemperatureAboveZero() {
      return this.currentWeather?.temperature > 0
    }
  },
  mounted() {
    this.getCurrentWeather();

    this.timer = setInterval(() => {
      this.getCurrentWeather();
    }, 300000)
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    getCurrentWeather() {
      this.$store.dispatch('weather/getCurrentWeather');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.base-weather {
  display: flex;
  align-items: center;
  gap: 8px;

  &__temperature {
    font-family: DS-DIGI, sans-serif;
    @include font-style(400, 26px, 28px, var(--text-primary));
  }
}
</style>