export default class Geometry {
    constructor(type, coordinates) {
        this.type = type;
        this.coordinates = coordinates;
    }

    get isPoint() {
        return this.type === 'Point';
    }

    get isLine() {
        return this.type === 'LineString' || this.type === 'MultiLineString';
    }

    get isPolygon() {
        return this.type === 'Polygon' || this.type === 'MultiPolygon';
    }

    get oneCoordinate() {
        if (this.coordinates.some(item => Array.isArray(item))) {
            const [coords] = this.coordinates;
            return coords;
        } else return this.coordinates;
    }
}
