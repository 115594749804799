<template>
  <div
    class="personal-list__parameter"
    :class="{'personal-list__parameter--dark': isDarkTheme}">
    <div class="personal-list__parameter-wrapper">
      <div
        v-if="parameterTimeStatus(parameterValue.value) === 'warn'
          || parameterTimeStatus(parameterValue.value) === 'error'"
        class="personal-list__parameter-time-indicator"
        :class="parameterTimeStatus(parameterValue.value)"/>
      <div class="personal-list__parameter-short-name">{{ parameter.shortName }}</div>
      <div class="personal-list__parameter-value">
        <parameter-value
          :parameter-value="parameterValue"
          :precision="parameter.precision"
        />
      </div>
      <div
        v-if="isValueOutOfProfile(parameterValue.value, parameterProfile)"
        class="personal-list__parameter-arrow">
        <icon-arrow-up/>
      </div>

      <parameter-tooltip
        :parameter-profile="parameterProfile"
        :parameter-value="parameterValue"
        :parameter="parameter"
      />
    </div>

    <div
      v-if="showReadingAtDate && parameterTimeStatus(parameterValue.value) === 'error'"
      class="personal-list__parameter-value-date">
      {{formatDate(parameterValue.readingAt)}}
    </div>
  </div>
</template>

<script>
import IconArrowUp from "@/components/Icons/IconArrowUp.vue";
import {formatDate} from "@/utils/filters";
import ParameterValue from "@/components/Telemetry/ParameterValue.vue";
import {isValueOutOfProfile, parameterTimeStatus} from "@/utils/helpers";
import ParameterTooltip from "@/components/Telemetry/ParameterTooltip.vue";

export default {
  name: "PersonalListParameter",
  components: {ParameterTooltip, ParameterValue, IconArrowUp},
  props: {
    parameter: {
      type: Object,
      required: true
    },
    showReadingAtDate: {
      type: Boolean,
      default: false
    },
    isDarkTheme: {
      type: Boolean,
      default: false
    },
    parameterValue: {},
    parameterProfile: {}
  },
  methods: {
    isValueOutOfProfile,
    formatDate,
    parameterTimeStatus
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.personal-list {
  &__parameter {
    &-wrapper {
      display: flex;
      align-items: center;
      gap: 4px;

      position: relative;

      padding: 4px 6px;
      border-radius: 5px;
      background-color: var(--panel-bg);

      &:hover {
        & .scada-table__tooltip {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &-value-date {
      margin-top: 2px;
      @include font-style(400, 10px, 12px, var(--border-validation));
    }

    &-short-name {
      @include font-style(600, 12px, 14px, var(--text-link));
    }

    &-value {
      @include font-style(600, 12px, 14px, var(--text-primary));
    }

    &-time-indicator {
      width: 2px;
      height: 12px;

      background-color: var(--status-delay);

      &.error {
        background-color: var(--status-new);
      }
    }

    &-arrow {
      display: flex;
      align-items: center;
    }

    &--dark {
      & .personal-list__parameter {
        &-wrapper {
          background-color: var(--panel-dark);
        }

        &-short-name {
          color: var(--text-tertiary);
        }

        &-value {
          color: var(--text-panel);
        }
      }
    }
  }
}
</style>