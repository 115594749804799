export default {
    computed: {
        clean: function () {
            return filter => {
                let payload = JSON.parse(JSON.stringify(filter))
                for (let propName in payload) {
                    if (payload[propName] === null
                        || payload[propName] === 0
                        || payload[propName] === undefined
                        || payload[propName] === ""
                        || payload[propName].length === 0) {
                        delete payload[propName]
                    }
                    if (typeof payload[propName] === 'object') {
                        if (payload[propName][0] === '' && payload[propName][1] === '') {
                            delete payload[propName]
                        } else {
                            payload[propName][0] = (parseInt(payload[propName][0]) + 100) / 100
                            payload[propName][1] = (parseInt(payload[propName][1]) + 100) / 100
                        }
                    }
                }
                return payload
            }
        }
    }
}