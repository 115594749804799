<script>
export default {
  name: "IconWrench"
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none">
    <path
      d="M15.3429 1.47514L11.409 5.40906L11.9393 8.06071L14.591 8.59104L18.5249 4.65712L18.5253 4.65695C19.0122 5.80636
      19.1287 7.07897 18.8585 8.29766C18.5882 9.51635 17.9448 10.6205 17.0177 11.4563C16.0905 12.2922 14.9259 12.8182
      13.6858 12.9612C12.4457 13.1041 11.1919 12.8569 10.0989 12.2539L10.0991 12.2537L4.84099 18.341C4.41895 18.7626
      3.84678 18.9993 3.25027 18.9991C2.65375 18.999 2.08172 18.7619 1.65992 18.3401C1.23812 17.9183 1.00108 17.3463
      1.00091 16.7498C1.00074 16.1533 1.23745 15.5811 1.65901 15.1591L7.74633 9.90105L7.7462 9.90112C7.14319 8.80813
      6.89596 7.55434 7.0389 6.31427C7.18184 5.07419 7.70786 3.90953 8.54372 2.98241C9.37958 2.05528 10.4837 1.41182
      11.7024 1.1416C12.9211 0.871374 14.1937 0.987829 15.3431 1.47475L15.3429 1.47514Z"
      stroke="#E19421"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>

<style scoped>

</style>
