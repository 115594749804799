export default class ApplicantType {
    static PHYSICAL_PERSON = {id: 1, label: 'Физ. лицо'};
    static LEGAL_PERSON = {id: 2, label: 'Юр. лицо'};
    static EMPLOYEE = {id: 7, label: 'Сотрудник'};

    static get applicantTypesList() {
        return [
            this.PHYSICAL_PERSON,
            this.LEGAL_PERSON,
            this.EMPLOYEE
        ]
    }
}
