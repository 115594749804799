import axios from "axios";
import store from "@/store";

axios.defaults.headers['Content-Type'] = 'application/vnd.api+json';
axios.defaults.headers['Accept'] = 'application/vnd.api+json';

export const apiClient = axios.create({
    baseURL: '/api',
    withCredentials: true
});


apiClient.interceptors.response.use(
    response => {
        return response;
    },
    function (error) {
        if (
            error.response &&
            [401, 419].includes(error.response.status) &&
            store.getters["auth/authUser"]
        ) {
            store.dispatch("auth/logoutApp");
        }
        return Promise.reject(error);
    }
);