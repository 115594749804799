import TechnicalInspectionViolationsService from "@/services/TechnicalInspectionViolationsService";

export const namespaced = true;

export const state = {
    violationsList: [],
    loading: false,
    error: null,
    query: ''
}

export const mutations = {
    SET_VIOLATIONS_LIST(state, violations) {
        state.violationsList = violations;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_QUERY(state, query) {
        state.query = query;
    }
}

export const actions = {
    getViolationsList({commit}, objectTypeId) {
        commit("SET_LOADING", true);
        TechnicalInspectionViolationsService.fetchViolationsList(objectTypeId)
            .then(violationsList => commit('SET_VIOLATIONS_LIST', violationsList))
            .catch(error => commit('SET_ERROR', error))
            .finally(() => commit("SET_LOADING", false))
    },
    createViolation(context, payload) {
        return new Promise((resolve, reject) => {
            TechnicalInspectionViolationsService.createViolation(payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    updateViolation(context, {id, payload}) {
        return new Promise((resolve, reject) => {
            TechnicalInspectionViolationsService.updateViolation(id, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    deleteViolation(context, id) {
        return new Promise((resolve, reject) => {
            TechnicalInspectionViolationsService.deleteViolation(id)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    deleteViolationField(context, {id, fieldId}) {
        return new Promise((resolve, reject) => {
            TechnicalInspectionViolationsService.deleteViolationField(id, fieldId)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    }
}

export const getters = {
    getViolationsByQuery: state => {
        return state.query.length ? state.violationsList.filter(violation => violation.name.toLowerCase()
            .includes(state.query.toLowerCase())) : state.violationsList;
    }
}