import * as API from './API';
import {getError} from "@/utils/helpers";
import EnergyConsumptionObjectWithMeters from "@/models/energyConsumption/EnergyConsumptionObjectWithMeters";

export default {
    fetchEnergyConsumptionObjectsListWithMeters(page) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/energy-consumption/electricity-consumer-facilities?page=${page}`)
                .then(response => resolve({
                    energyConsumptionObjects: response.data.data
                        .map(item => new EnergyConsumptionObjectWithMeters({id: item.id, ...item.attributes})),
                    meta: response.data.meta
                }))
                .catch(error => reject(getError(error)))
        });
    },
    fetchAllEnergyConsumptionObjectsWithMeters() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/energy-consumption/electricity-consumer-facilities/all`)
                .then(response => resolve(response.data.data.map(item => new EnergyConsumptionObjectWithMeters({
                    id: item.id,
                    ...item.attributes
                }))))
                .catch(error => reject(getError(error)))
        })
    },
    fetchEnergyConsumptionObjectWithMeters(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/energy-consumption/electricity-consumer-facilities/${id}`)
                .then(response => resolve(new EnergyConsumptionObjectWithMeters({
                    id: response.data.data.id,
                    ...response.data.data.attributes
                })))
                .catch(error => reject(getError(error)));
        });
    },
    createEnergyConsumptionObject(payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post('/energy-consumption/electricity-consumer-facilities', payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateEnergyConsumptionObject(id, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/energy-consumption/electricity-consumer-facilities/${id}`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteEnergyConsumptionObject(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`/energy-consumption/electricity-consumer-facilities/${id}`)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}