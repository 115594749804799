import * as API from './API';
import {ProjectConfig} from "@/models/ProjectConfig";
import {getError} from "@/utils/helpers";
import ColorSchemaService from "@/services/ColorSchemaService";

export default {
    getProjectConfig() {
        return new Promise((resolve, reject) => {
            API.apiClient.get('/configuration')
                .then(response => {
                    const projectConfig = new ProjectConfig(response.data);

                    const localStorageThemeKey = 'selectedColorTheme';
                    const localStorageTheme = localStorage.getItem(localStorageThemeKey);
                    if (localStorageTheme === null) {
                        ColorSchemaService.switchColorScheme(projectConfig, 'light');
                    } else {
                        ColorSchemaService.switchColorScheme(projectConfig, localStorageTheme);
                    }

                    resolve({projectConfig, localStorageTheme});
                })
                .catch(error => reject(getError(error)));
        })
    }
}
