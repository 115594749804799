<template>
  <div id="archive-charts-wrapper">
    <apexchart
      :options="parameterOptions"
      :series="parameterSeries"
      height="400"
      type="line"
      width="100%"
    />

    <apexchart
      v-if="group === 'hour'"
      :options="temperatureOptions"
      :series="temperatureSeries"
      height="200"
      type="area"
      width="100%"
    />
  </div>
</template>

<script>
import {mapState} from "vuex";
import VueApexCharts from "vue-apexcharts";
let ru = require('apexcharts/dist/locales/ru.json')

export default {
  name: "ArchiveCharts",
  components: {'apexchart': VueApexCharts},
  props: {
    parameterSeries: {
      required: true
    },
    currentChartType: {
      required: true
    },
    group: {
      type: String,
      required: true
    },
    isDateTimeXaxis: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      chartOptions: {
        chart: {
          zoom: {
            enabled: false
          },
          fontFamily: 'Lato',
          foreColor: '#9192AD',
          local: [ru]
        },
        legend: {
          fontSize: '12px'
        },
        colors: ['#6375D4'],
        dataLabels: {
          enabled: false
        },
        grid: {
          borderColor: 'rgba(0, 0, 0, 0.20)',
          strokeDashArray: 2,
          position: 'back'
        },
        stroke: {
          curve: 'straight'
        },
        xaxis: {
          type: "datetime",
          labels: {
            show: true,
            datetimeUTC: false,
            rotateAlways: false
          }
        },
        tooltip: {
          x: {
            formatter: function (val) {
              const moment = require("moment");
              return moment(val).format('DD.MM HH:mm');
            }
          },
          y: {
            formatter: function (val) {
              return val
            }
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "vertical",
            shadeIntensity: 1,
            opacityFrom: 0.9,
            opacityTo: 0.2,
            stops: [0, 90, 100]
          }
        }
      }
    }
  },
  computed: {
    ...mapState({
      weatherArchive: state => state.weather.weatherArchive
    }),
    temperatureOptions: function () {
      return {
        ...this.chartOptions,
        colors: ['#FF3A81']
      }
    },
    parameterOptions: function () {
      const context = this;

      if (this.isDateTimeXaxis) return this.chartOptions
      else return {
        ...this.chartOptions,
        xaxis: {
          type: 'category',
          labels: {
            show: true,
            rotateAlways: false
          }
        },
        tooltip: {
          x: {
            formatter: function (val) {
              return context.parameterSeries[0].data[val - 1].x;
            }
          },
          y: {
            formatter: function (val) {
              return val
            }
          }
        }
      }
    },
    temperatureSeries: function () {
      return [
        {
          name: 'Температура',
          type: 'area',
          data: this.weatherArchive.map(item => {
            return {x: new Date(item.weatherDate), y: item.temperature}
          }),
          fill: {
            type: 'pattern',
            pattern: {
              style: 'verticalLines',
              width: 6,
              height: 6,
              strokeWidth: 2
            }
          }
        }
      ]
    }
  },
  watch: {
    parameterSeries: function () {
      if (this.isDateTimeXaxis) this.updateSync();
    }
  },
  methods: {
    syncCharts(minX, maxX) {
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          min: minX,
          max: maxX,
          type: "datetime"
        }
      };
    },
    updateSync() {
      const pressureTimes = this.parameterSeries[0].data.map(d => new Date(d.x).getTime());
      const temperatureTimes = this.temperatureSeries[0].data.map(d => new Date(d.x).getTime());

      const minPressureTime = Math.min(...pressureTimes);
      const maxPressureTime = Math.max(...pressureTimes);

      const minTemperatureTime = Math.min(...temperatureTimes);
      const maxTemperatureTime = Math.max(...temperatureTimes);


      const minX = Math.min(minPressureTime, minTemperatureTime);
      const maxX = Math.max(maxPressureTime, maxTemperatureTime);

      this.syncCharts(minX, maxX);
    }
  }
}
</script>

<style scoped>

</style>