<template>
  <div @click="toggleCollapse()"
       class="base-table__row-collapse">
    <div class="base-table__row-collapse-content base-table__row--resource">
      <div class="base-table__column">
        <div class="base-table__collapse">
          <icon-plus2 v-if="isCollapsed"/>
          <icon-minus v-else/>
        </div>
      </div>
      <div class="base-table__column">
        <div class="base-table__text">{{ resource.name }}</div>
      </div>
      <div class="base-table__column">
        <div class="base-table__text">
          <resource-load-indicator
            :resource-tasks-list="getResourceTasks" />
        </div>
      </div>
    </div>
    <div v-show="!isCollapsed"
         class="resource-collapse">
      <div v-for="task in getResourceTasks"
           :key="task.id"
           class="base-table__row-collapse-item base-table__row--resource-task">
        <div class="base-table__column">
          <div class="base-table__num">{{ task.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ task.type_label }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ task.plannedDatesRangeCaption }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ task.issue.getFullAddress() }}</div>
        </div>
        <div class="base-table__column base-table__column--state">
          <div class="base-table__tag"
               :class="[
                 {'base-table__tag--new': task.state.id === TaskState.NEW},
                 {'base-table__tag--work': task.state.id === TaskState.IN_WORK},
                 {'base-table__tag--done': task.state.id === TaskState.CLOSED},
                 {'base-table__tag--canceled': task.state.id === TaskState.CANCELED},
                 {'base-table__tag--suspended': task.state.id === TaskState.SUSPENDED},
                 {'base-table__tag--planned': task.state.id === TaskState.ON_THE_WAY}
               ]">
            {{ task.state.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconMinus from "@/components/Icons/IconMinus.vue";
import TaskState from "@/models/serviceDesk/TaskState";
import IconPlus2 from "@/components/Icons/IconPlus2.vue";
import ResourceLoadIndicator from "@/components/ServiceDesk/ResourcesLoad/ResourceLoadIndicator.vue";

export default {
  name: "ResourceItem",
  components: {ResourceLoadIndicator, IconPlus2, IconMinus},
  props: {
    resource: {
      type: Object,
      required: true
    },
    departmentId: {
      type: Number,
      required: true
    },
    tasksList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isCollapsed: true
    }
  },
  computed: {
    getResourceTasks() {
      return this.tasksList
        .filter(task => task.department.id === this.departmentId && task.brigade.id === this.resource.id)
    },
    TaskState: () => TaskState
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    }
  }
}
</script>

<style lang="scss" scoped>
.base-table__row--resource {
  grid-template-columns: 24px 40.65% 1fr;
}

.resource-collapse {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.base-table__row--resource-task {
  grid-template-columns: 4.13% 16.53% 19.28% 45.18% 1fr;
}
</style>
