import * as API from './API';
import StructuralUnit from "@/models/StructuralUnit";
import {getError} from "@/utils/helpers";

export default {
    fetchStructuralUnitsList() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/structural-units`)
                .then(response => resolve(response.data.data.map(item => new StructuralUnit({
                    id: item.id,
                    ...item.attributes
                }))))
                .catch(error => reject(getError(error)))
        })
    },
    fetchStructuralUnit(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/structural-units/${id}`)
                .then(response => resolve(new StructuralUnit({
                    id: response.data.data.id,
                    ...response.data.data.attributes
                })))
                .catch(error => reject(getError(error)))
        })
    },
    fetchUserStructuralUnitsList() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/users/structural-units`)
                .then(response => resolve(response.data.data
                    .map(item => new StructuralUnit({id: item.id, ...item.attributes}))))
                .catch(error => reject(getError(error)))
        })
    },
    createStructuralUnit(payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`/structural-units`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateStructuralUnit(id, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/structural-units/${id}`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteStructuralUnit(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`/structural-units/${id}`)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    assignUserStructuralUnit(structuralUnitId, userId) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`/structural-units/${structuralUnitId}/assign/${userId}`)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}
