import * as API from './API'
import EngineeringNetwork from "@/models/EngineeringNetworks/EngineeringNetwork";
import {getError} from "@/utils/helpers";

export default {
    fetchEngineeringNetworks(page) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`network-engineering/networks?page=${page}`)
                .then(response => {
                    resolve({
                        networks: response.data.data.map(item => new EngineeringNetwork(item.id, item.attributes)),
                        meta: response.data.meta
                    })
                })
                .catch(error => reject(getError(error)));
        })
    },
    fetchAllEngineeringNetworks() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`network-engineering/networks/all`)
                .then(response => {
                    resolve(response.data.data.map(item => new EngineeringNetwork(item.id, item.attributes)))
                })
                .catch(error => reject(getError(error)));
        })
    },
    makeEngineeringNetwork(payload) {
        return API.apiClient.post(`network-engineering/networks`, payload);
    },
    updateEngineeringNetwork({networkId, payload}) {
        return API.apiClient.patch(`network-engineering/networks/${networkId}`, payload);
    },
    activateEngineeringNetwork(networkId) {
        return API.apiClient.post(`network-engineering/networks/${networkId}/activate`);
    },
    deactivateEngineeringNetwork(networkId) {
        return API.apiClient.post(`network-engineering/networks/${networkId}/deactivate`);
    }
}