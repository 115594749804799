export default class File {
    constructor(id, attributes, extension = '') {
        this.id = id;
        this.name = attributes.name;
        this.url = attributes.path;
        this.extension = extension || null;
    }

    get fileExtension() {
        const splitUrl = this.url.split('.');
        return splitUrl.length > 1 ? this.url.split('.').pop() : this.extension
    }

    get isVideoFile() {
        const videoExtensionsList = ['mpeg', 'mp4', 'ogg', 'webm', 'avi'];
        return videoExtensionsList.some(item => item === this.fileExtension)
    }
}
