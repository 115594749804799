<script>
export default {
  name: "IconTruck"
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none">
    <path
      d="M22.5 6.25H16.5V2.5H20.4922C20.6421 2.5 20.7886 2.54491 20.9127 2.62895C21.0368 2.71298 21.1329 2.83228
       21.1886 2.97146L22.5 6.25Z"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M17.625 15.25C18.8676 15.25 19.875 14.2426 19.875 13C19.875 11.7574 18.8676 10.75 17.625 10.75C16.3824 10.75
       15.375 11.7574 15.375 13C15.375 14.2426 16.3824 15.25 17.625 15.25Z"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-miterlimit="10"/>
    <path
      d="M6.375 15.25C7.61764 15.25 8.625 14.2426 8.625 13C8.625 11.7574 7.61764 10.75 6.375 10.75C5.13236 10.75 4.125
       11.7574 4.125 13C4.125 14.2426 5.13236 15.25 6.375 15.25Z"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-miterlimit="10"/>
    <path
      d="M15.375 13H8.625"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M4.125 13H2.25C2.05109 13 1.86032 12.921 1.71967 12.7803C1.57902 12.6397 1.5 12.4489 1.5 12.25V1.75C1.5 1.55109
       1.57902 1.36032 1.71967 1.21967C1.86032 1.07902 2.05109 1 2.25 1H16.5V11.0514"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M16.5 11.0514V6.25M22.5 6.25V12.25C22.5 12.4489 22.421 12.6397 22.2803 12.7803C22.1397 12.921 21.9489 13 21.75
       13H19.875"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>

<style scoped>

</style>
