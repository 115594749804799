<template>
  <div>
    <base-no-data
      v-if="!$store.state.building.building"
      title="Объект не найден"
    />

    <div
      v-else
      class="text-list"
    >
      <div
        v-if="!mapClick"
        @click="$store.commit('baseMap/SET_CENTER_ZOOM', {center: buildingCoords(buildingId), zoom: 17})"
        class="building-show-on-map"
      >Показать на карте</div>

      <div class="text-list__item">
        <div class="text-list__item-name">
          Адрес:
        </div>
        <div class="text-list__item-value">
          {{ getFullAddress(this.$store.getters['building/getStateBuilding']) }}
        </div>
      </div>
      <div class="text-list__item">
        <div class="text-list__item-name">
          Зона водоснабжения:
        </div>
        <div class="text-list__item-value">
          {{ zoneName(buildingWSZoneId) }}
        </div>
      </div>
      <div class="text-list__item">
        <div class="text-list__item-name">
          Зона водоотведения:
        </div>
        <div class="text-list__item-value">
          {{ zoneName(buildingWDZoneId) }}
        </div>
      </div>
      <div class="text-list__item">
        <div class="text-list__item-name">
          Источник водоснабжения:
        </div>
        <div class="text-list__item-value">
          {{ buildingSource }}
        </div>
      </div>
      <div
        v-if="buildingTechnicalCondition.length !== 0"
        class="text-list__item"
      >
        <base-collapse
          :default-collapse-state="true"
          container-width="100%">
          <template #toggle="{toggle, isCollapsed}">
            <div
              @click="toggle()"
              class="text-list__item-name text-list__item-name--cur-pointer text-list__item-name--full-width"
            >
              <img
                id="readings_icon"
                alt="Раскрыть начисления"
                class="collapse-arrow"
                :class="{'rotateZ-90deg': !isCollapsed}"
                src="../../../assets/img/billing/arrow-right.svg"
              >
              Связанные Технические условия по ВС и ВО
            </div>
          </template>

          <template #body>
            <ol class="building-tc-list">
              <li
                v-for="techCondition in buildingTechnicalCondition"
                :key="techCondition.id"
                class="text-list text-list--small-gap"
              >
                <div class="text-list__item">
                  <div class="text-list__item-name">
                    Заявитель:
                  </div>
                  <div class="text-list__item-value">
                    {{ techCondition.attributes.declarer_name }}
                  </div>
                </div>
                <div class="text-list__item">
                  <div class="text-list__item-name">
                    Тип заявителя:
                  </div>
                  <div class="text-list__item-value">
                    {{ declarerTypeTitle(techCondition.attributes.declarer_type) }}
                  </div>
                </div>
                <div class="text-list__item">
                  <div class="text-list__item-name">
                    № ТУ:
                  </div>
                  <div class="text-list__item-value">
                    {{ techCondition.attributes.number }}
                  </div>
                </div>
                <div class="text-list__item">
                  <div class="text-list__item-name">
                    Тип ТУ:
                  </div>
                  <div class="text-list__item-value">
                    {{ typeTitle(techCondition.attributes.type) }}
                  </div>
                </div>
                <div class="text-list__item">
                  <div class="text-list__item-name">
                    Водоснабжение, м3/час:
                  </div>
                  <div class="text-list__item-value">
                    {{ techCondition.attributes.vs_hour }}
                  </div>
                </div>
                <div class="text-list__item">
                  <div class="text-list__item-name">
                    Водоснабжение, м3/день:
                  </div>
                  <div class="text-list__item-value">
                    {{ techCondition.attributes.vs_day }}
                  </div>
                </div>
                <div class="text-list__item">
                  <div class="text-list__item-name">
                    Водоотведение, м3/час:
                  </div>
                  <div class="text-list__item-value">
                    {{ techCondition.attributes.vo_hour }}
                  </div>
                </div>
                <div class="text-list__item">
                  <div class="text-list__item-name">
                    Водоотведение, м3/день:
                  </div>
                  <div class="text-list__item-value">
                    {{ techCondition.attributes.vo_day }}
                  </div>
                </div>
                <div class="text-list__item">
                  <div class="text-list__item-name">
                    Дата подключения:
                  </div>
                  <div class="text-list__item-value">
                    {{ nullFilter(techCondition.attributes.connection_date) }}
                  </div>
                </div>
                <div class="text-list__item">
                  <div class="text-list__item-name">
                    Дата технического подключения:
                  </div>
                  <div class="text-list__item-value">
                    {{ nullFilter(techCondition.attributes.accession_date) }}
                  </div>
                </div>
              </li>
            </ol>
          </template>
        </base-collapse>
      </div>
      <div class="text-list__item">
        <base-collapse
          :default-collapse-state="true"
          container-width="100%">
          <template #toggle="{toggle, isCollapsed}">
            <div
              @click="toggle()"
              class="text-list__item-name text-list__item-name--cur-pointer"
            >
              <img
                id="readings_icon"
                alt="Раскрыть начисления"
                class="collapse-arrow"
                :class="{'rotateZ-90deg': !isCollapsed}"
                src="../../../assets/img/billing/arrow-right.svg"
              >
              Абоненты:

              <div class="text-list__item-value text-list__item-value--inline">
                ФЛ - {{ buildingPrivateClientsCount }},
                ЮЛ - {{ buildingBusinessClientsCount }}
              </div>
            </div>
          </template>

          <template #body>
            <div v-if="buildingPrivateClientsCount !== 0"
                 class="building-clients-list">
              <div class="building-clients-list__title">
                Физические лица:
              </div>
              <ol type="1">
                <li
                  v-for="client in buildingClients.filter((item) => item.attributes.type == 1)"
                  @click="getClientDetail(client.id)"
                  :key="client.ls"
                  class="building-clients-list__item"
                >
                  №{{ client.attributes.contract_number }} от
                  {{ client.attributes.contract_open_date }} <br>
                  {{ client.attributes.address }} <br>
                  {{ client.attributes.contract_client_name }} <br>
                </li>
              </ol>
            </div>
            <div v-if="buildingBusinessClientsCount !== 0">
              <div class="building-clients-list__title">
                Юридические лица:
              </div>
              <ol type="1">
                <li
                  v-for="client in buildingClients.filter((item) => item.attributes.type == 2)"
                  @click="getClientDetail(client.id)"
                  :key="client.ls"
                  class="building-clients-list__item"
                >
                  №{{ client.attributes.contract_number }} от
                  {{ client.attributes.contract_open_date }} <br>
                  {{ client.attributes.contract_client_name }} <br>
                  {{ client.attributes.address }} <br>
                </li>
              </ol>
            </div>
          </template>
        </base-collapse>
      </div>
      <div class="text-list__item">
        <div class="text-list__item-name">
          Аварийность:
        </div>
        <div class="text-list__item-value">
          {{ buildingAccidentRate ? 'Да' : 'Нет' }}
        </div>
      </div>
      <div class="text-list__item">
        <div class="text-list__item-name">
          Наличие утечек:
        </div>
        <div class="text-list__item-value">
          {{ buildingLeaks ? 'Да' : 'Нет' }}
        </div>
      </div>
      <div class="text-list__item">
        <div class="text-list__item-name">
          Нелегальные подключения:
        </div>
        <div class="text-list__item-value">
          {{ buildingIllegalConnections ? 'Да' : 'Нет' }}
        </div>
      </div>
      <div class="text-list__item">
        <div class="text-list__item-name">
          Подключение без технических условий (ТУ):
        </div>
        <div class="text-list__item-value">
          {{ buildingIllegalTU ? 'Да' : 'Нет' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import BaseNoData from "@/components/BaseNoData.vue";
import BaseCollapse from "@/components/BaseCollapse.vue";
import {nullFilter} from "@/utils/filters";

export default {
  name: "BillingBuildingDetail",
  components: {BaseCollapse, BaseNoData},
  data() {
    return {
      mapClick: false
    }
  },
  computed: {
    ...mapGetters({
      buildingAddress: "building/getBuildingAddress",
      buildingWSZoneId: "building/getBuildingWSZoneId",
      buildingWDZoneId: "building/getBuildingWDZoneId",
      buildingSource: "building/getBuildingSource",
      buildingType: "building/getBuildingType",
      buildingArea: "building/getBuildingArea",
      buildingLeavingArea: "building/getBuildingLeavingArea",
      buildingTechnicalCondition: "building/getBuildingTechnicalCondition",
      buildingPrivateClientsCount: "building/getBuildingPrivateClientsCount",
      buildingBusinessClientsCount: "building/getBuildingBusinessClientsCount",
      buildingClients: "building/getBuildingClients",
      buildingConnectionPointsVS: "building/getBuildingConnectionPointsVS",
      buildingConnectionPointsVO: "building/getBuildingConnectionPointsVO",
      buildingBalance: "building/getBuildingBalance",
      buildingScheduleAm: "building/getBuildingScheduleAm",
      buildingSchedulePm: "building/getBuildingSchedulePm",
      buildingAccidentRate: "building/getBuildingAccidentRate",
      buildingLeaks: "building/getBuildingLeaks",
      buildingIllegalConnections: "building/getBuildingIllegalConnections",
      buildingIllegalTU: "building/getBuildingIllegalTU",
      buildingId: "building/getBuildingId",
      buildingCoords: "building/getBuildingCoords",
      zoneName: "zone/getNameBySys",
      clientLoading: "client/getClientLoading"
    }),
    getFullAddress: function () {
      return building => {
        let full_address_arr = [building.attributes.city, building.attributes.settlement, building.attributes.street,
          building.attributes.house, building.attributes.block].filter(item => item !== null)
        return full_address_arr.join(', ')
      }
    }
  },
  methods: {
    nullFilter,
    getClientDetail(id) {
      this.$store.dispatch('client/getClient', id)
          .then(() => {
            this.$store.commit('building/SET_TAB_INDEX', 3);
          })
    },
    declarerTypeTitle(value) {
      switch (value) {
        case 1:
          return 'Физическое лицо'
        case 2:
          return 'Юридическое лицо'
        case 3:
          return 'Неизвестно'
      }
    },
    typeTitle(value) {
      switch (value) {
        case 1:
          return 'ТУ на водоснабжение'
        case 2:
          return 'ТУ на водоотведение'
        case 3:
          return 'ТУ на водоснабжение и водоотведение'
        case 4:
          return 'Неизвестно'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.building-show-on-map {
  @include font-style(400, 16px, 24px, var(--text-primary));
}

.building-clients-list {
  margin-bottom: 12px;

  &__title {
    @include font-style(400, 16px, 24px, var(--text-primary));
  }

  &__item {
    cursor: pointer;
    @include font-style(400, 14px, 18px, var(--text-primary));
  }
}

.collapse-arrow {
  margin-right: 6px;
  transition: 0.2s;
}

.building-tc-list {
  & li {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}
</style>
