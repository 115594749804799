import Geometry from "@/models/Geometry";

export default class Address {
    constructor(params, geometry = null) {
        this.region = params.region || null;
        this.city = params.city;
        this.settlement = params.settlement;
        this.street = params.street;
        this.house = params.house;
        this.block = params.block;
        this.flat = params.flat;

        if (geometry) {
            const {type, coordinates} = geometry;
            this.geometry = new Geometry(type, coordinates.reverse()) || null;
        }
    }

    get fullAddress() {
        return [this.city, this.settlement, this.street, this.house, this.block, this.flat]
            .filter(item => item !== null)
            .join(', ')
    }
}
