<template>
  <div class="additional-option-wrapper"
       :class="{'always-open': isCollapseVisible}">
    <div class="additional-option">
      <div class="additional-option__icon">
        <icon-works-list/>
      </div>
      <div class="additional-option__name"><span>Работы</span><span
        class="additional-option__quantity">{{ worksList.length }}</span>
      </div>
      <div class="additional-option__buttons">
        <button
          v-show="isActionsVisible"
          class="additional-option__add"
          type="button"
          @click="showAddWorkModal()">
          <icon-plus/>
        </button>
        <button
          @click="isCollapseVisible = !isCollapseVisible"
          class="additional-option__toggle"
          :class="{'rotate': isCollapseVisible}">
          <icon-arrow-double-up/>
        </button>
      </div>

    </div>
    <div class="additional-options-list">
      <div class="additional-options-list__inner">
        <div v-for="work in worksList"
             :key="work.id"
             @click="showWorkModal(work)"
             class="added-item added-item--flex">
          <div class="added-item__info">
            <div class="added-item__name">{{ work.name }}</div>
            <div v-if="work.element"
                 class="added-item__additional">
              Аварийный элемент: {{ work.element.getCaption() }}
            </div>
          </div>
          <div class="added-item__right">
            <div
              v-if="isActionsVisible"
              @click.stop="deleteWork(work.id)">
              <icon-trash class="svg added-item__tool"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconWorksList from "@/components/Icons/IconWorksList";
import IconPlus from "@/components/Icons/IconPlus";
import IconArrowDoubleUp from "@/components/Icons/IconArrowDoubleUp";
import WorksForm from "@/components/ServiceDesk/Tasks/TaskComponents/Works/WorksForm";
import WorkModal from "@/components/ServiceDesk/Tasks/TaskComponents/Works/WorkModal";
import {modalAdaptiveWidth} from "@/utils/helpers";
import IconTrash from "@/components/Icons/IconTrash.vue";
import {showConfirmationModal, showToast, showAlert} from "@/utils/notification";

export default {
  name: "WorksList",
  components: {IconTrash, IconArrowDoubleUp, IconPlus, IconWorksList},
  props: {
    taskId: {
      type: Number,
      required: true
    },
    worksList: {
      type: Array,
      required: true
    },
    workTypeLists: {
      type: Array,
      required: true
    },
    isActionsVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isCollapseVisible: false
    }
  },
  methods: {
    showAddWorkModal() {
      this.$modal.show(
        WorksForm,
        {
          'work-types-list': this.workTypeLists,
          'task-id': this.taskId
        },
        {
          name: 'work-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(600)
        }
      )
    },
    showWorkModal(work) {
      this.$modal.show(
        WorkModal,
        {
          'task-id': this.taskId,
          'work': work
        },
        {
          name: 'work-modal',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        }
      )
    },
    deleteWork(workId) {
      showConfirmationModal('Вы уверены, что хотите удалить работу? Это действие невозвратно.')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('task/deleteWork', {taskId: this.taskId, workId})
              .then(() => {
                this.$store.dispatch('taskModal/getWorksList', this.taskId);
                showToast('Работа удалена', 'success');
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
