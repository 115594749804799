import * as API from './API';
import Zone from "@/models/billing/Zone";
import {getError} from "@/utils/helpers";

export default {
    getZone(zoneId) {
        return API.apiClient.get(`/zones/${zoneId}`);
    },
    getZones(page) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/zones?page=${page}`)
                .then(response => resolve({
                    zonesList: response.data.data.map(item => new Zone({id: item.id, ...item.attributes})),
                    meta: response.data.meta
                }))
                .catch(error => reject(getError(error)))
        })
    },
    getAllZones() {
        return API.apiClient.get('/zones/all');
    },
    createZone(payload) {
        return API.apiClient.post(`/zones`, payload)
    },
    updateZonesAuto() {
        return API.apiClient.post('/zones/auto')
    },
    updateZone(id, payload){
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/zones/${id}`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteZone(id){
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`/zones/${id}`)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        });
    }
}