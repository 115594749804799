<template>
  <div class="base-table">
    <div class="base-table__container">
      <base-table-header
        :table-headers="tableHeaders"
        header-class-name="base-table__column--resource"
        @update-sort-by="sortName => $emit('update-sort-by', sortName)"
        @update-sort-order="sortOrder => $emit('update-sort-order', sortOrder)"
      />

      <div v-for="resource in resourcesList"
           :key="resource.id"
           class="base-table__row base-table__column--resource">
        <div class="base-table__column">
          <div class="base-table__text">{{ resource.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ resource.name }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{resource.master.name}}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{resource.transport.name}}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{resource.department.name}}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{resource.structuralUnit.name}}</div>
        </div>
        <div class="base-table__column base-table__column--content-right">
          <div class="base-table__short-info">
            <div @click="$emit('update-resource', resource)">
              <icon-pencil class="base-table__short-info--p-2" />
            </div>
            <div @click="$emit('delete-resource', resource.id)">
              <icon-trash />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconTrash from "@/components/Icons/IconTrash.vue";
import IconPencil from "@/components/Icons/IconPencil.vue";
import BaseTableHeader from "@/components/BaseTableHeader.vue";

export default {
  name: "ResourcesList",
  components: {BaseTableHeader, IconPencil, IconTrash},
  props: {
    resourcesList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      tableHeaders: [
        {id: 1, name: 'ID', sortName: null, sortDir: null},
        {id: 2, name: 'Наименование', sortName: 'resource_name', sortDir: null},
        {id: 3, name: 'Мастер', sortName: 'master_name', sortDir: null},
        {id: 4, name: 'Транспорт', sortName: null, sortDir: null},
        {id: 5, name: 'Подразделение', sortName: 'department_name', sortDir: null},
        {id: 6, name: 'Структурное подразделение', sortName: 'unit_name', sortDir: null},
        {id: 7, name: '', sortName: null, sortDir: null}
      ]
    }
  }
}
</script>

<style>
.base-table__column--resource {
  grid-template-columns: 60px repeat(4, 1fr) 250px 100px;
}
</style>