<template>
  <div v-if="networkObjectType"
       class="page-wrapper">
    <div class="panel">
      <button
        @click="$router.push('/technical-inspection-network-object-types')"
        class="button button--mode-skinny"
      >
        <img
          src="@/assets/img/icons/arrowSquareLeft.svg"
          alt="стрелочка">
        Типы объектов
      </button>

      <base-panel
        :title="networkObjectType.name"
        :is-add-button-visible="false"/>
    </div>

    <div class="tabs">
      <div class="tabs__header tabs__header--block">
        <div
          @click="activeTab = 1"
          class="tabs__header-item"
          :class="{'active': activeTab === 1}"
        >Чек-лист</div>
        <div
          @click="activeTab = 3"
          class="tabs__header-item"
          :class="{'active': activeTab === 3}"
        >Нарушения</div>
      </div>

      <div
        v-if="activeTab === 1"
        class="tabs__item"
        :class="{'active': activeTab === 1}">
        <questions-list
          :questions-list="questionsList"
          :dictionaries-list="dictionariesList"
          :loading="questionsLoading"
        />
      </div>

      <div
        v-if="activeTab === 3"
        class="tabs__item"
        :class="{'active': activeTab === 3}">
        <violations-list
          :violations-list="violationsList"
          :loading="violationsLoading"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel.vue";
import {mapGetters, mapState} from "vuex";
import ViolationsList from "@/components/TechnicalInspection/NetworkObjectTypes/ViolationsList.vue";
import QuestionsList from "@/components/TechnicalInspection/NetworkObjectTypes/QuestionsList.vue";

export default {
  name: "NetworkObjectTypeConfigPage",
  components: {QuestionsList, ViolationsList, BasePanel},
  data() {
    return {
      activeTab: 1
    }
  },
  computed: {
    ...mapGetters({
      getNetworkObjectTypeById: 'networkObjectTypes/getNetworkObjectTypeById',
      isNetworkObjectTypesListEmpty: 'networkObjectTypes/isNetworkObjectTypesListEmpty',
      violationsList: 'violations/getViolationsByQuery',
      questionsList: 'questions/getQuestionsByQuery'
    }),
    ...mapState({
      violationsLoading: state => state.violations.loading,
      questionsLoading: state => state.questions.loading,
      dictionariesList: state => state.dictionaries.dictionariesList
    }),
    networkObjectType() {
      return this.getNetworkObjectTypeById(this.$route.params.id);
    }
  },
  mounted() {
    this.$store.dispatch('networkObjectTypes/getNetworkObjectTypesList');
    this.$store.dispatch('dictionaries/getDictionariesList');

    this.$store.dispatch('violations/getViolationsList', this.$route.params.id);
    this.$store.dispatch('questions/getQuestionsList', this.$route.params.id);
  }
}
</script>

<style scoped>

</style>