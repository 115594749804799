<script>
export default {
  name: "IconSpeedNormal"
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none">
    <path
      opacity="0.4"
      d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543
          44 24 44Z"

      fill="#B9BAD5"/>
    <path
      d="M24 39.5C15.46 39.5 8.5 32.54 8.5 24C8.5 21.28 9.22 18.62 10.56 16.28C10.98 15.56 11.9 15.32 12.6 15.72L24.74
       22.7C25.46 23.12 25.7 24.02 25.3 24.74C24.88 25.46 23.98 25.7 23.26 25.3L12.5 19.12C11.86 20.66 11.52 22.32 11.52
        24C11.52 30.9 17.12 36.5 24.02 36.5C30.92 36.5 36.52 30.9 36.52 24C36.52 17.1 30.92 11.5 24.02 11.5C21.3 11.5
         18.7 12.36 16.52 14C15.86 14.5 14.92 14.36 14.42 13.7C13.92 13.04 14.06 12.1 14.72 11.6C17.42 9.58001 20.64
          8.5 24.02 8.5C32.56 8.5 39.52 15.46 39.52 24C39.52 32.54 32.54 39.5 24 39.5Z"
      fill="#9192AD"/>
  </svg>
</template>

<style scoped>

</style>
