<template>
  <div class="base-table base-table--tasks">
    <div class="base-table__container">
      <div class="base-table__header base-table__header--resource">
        <div class="base-table__header-cell">Ресурс</div>
        <div class="base-table__header-cell">Загруженность</div>
      </div>

      <resource-item
        v-for="resource in resourcesList"
        :key="resource.id"
        :resource="resource"
        :tasks-list="tasksList"
        :department-id="departmentId"
      />
    </div>
  </div>
</template>

<script>
import ResourceItem from "@/components/ServiceDesk/ResourcesLoad/ResourceItem.vue";

export default {
  name: "ResourcesList",
  components: {ResourceItem},
  props: {
    resourcesList: {
      type: Array,
      required: true
    },
    tasksList: {
      type: Array,
      required: true
    },
    departmentId: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.base-table__header--resource {
  grid-template-columns: 40.65% 1fr;
}
</style>
