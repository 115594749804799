<template>
  <div class="i-modal__content-wrapper">
    <div class="i-modal__content">
      <div v-if="Object.keys(role).length"
           class="i-modal__inner">
        <div class="base-modal__left-side base-modal__left-side--hidden">
          <div class="base-modal-header base-modal-header--padding-s">
            <span class="base-modal-title">Роль - {{role.name}}</span>
          </div>

          <div class="base-modal-info base-modal-info--gap-24 base-modal-info--padding-s">
            <div class="base-modal-info__card">
              <div class="base-modal-info__card-title base-modal-info__card-title--loader">
                Детальная информация
              </div>

              <div class="base-modal-info__item">
                <div class="base-modal-info__name">
                  <span>Наименование</span>
                </div>
                <div class="base-modal-info__value">
                  <p class="base-modal-info__value-txt">{{ role.name }}</p>
                </div>
              </div>
            </div>

            <div class="base-modal-info__item base-modal-info__item--options">
              <roles-permissions-list
                :role-id="role.id"
                :role-permissions-list="role.permissions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="Object.keys(role).length"
         class="i-modal-btn-wp i-modal-btn-wp--back">
      <div class="task-modal__mobile-actions">
        <button @click="$store.dispatch('baseSideModal/closeModal')"
                class="button button--mode-outline task-modal__mobile-back">
          <icon-arrow-left/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrowLeft from "@/components/Icons/IconArrowLeft.vue";
import {mapState} from "vuex";
import RolesPermissionsList from "@/components/admin/RolesPermissionsList.vue";

export default {
  name: "RoleModalContent",
  components: {RolesPermissionsList, IconArrowLeft},
  computed: {
    ...mapState({
      role: state => state.role.role
    })
  }
}
</script>

<style scoped>
</style>