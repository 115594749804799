<template>
  <div class="additional-option-wrapper"
       :class="{'always-open': isCollapseVisible}">
    <div class="additional-option">
      <div class="additional-option__icon">
        <icon-settins/>
      </div>
      <div class="additional-option__name">
        <span>Средства малой механизации</span>
        <span class="additional-option__quantity">{{ mechanicalEquipmentsList.length }}</span>
      </div>
      <div class="additional-option__buttons">
        <button
          v-show="isActionsVisible"
          class="additional-option__add"
          type="button"
          @click="showModal()">
          <icon-plus/>
        </button>
        <button
          @click="isCollapseVisible = !isCollapseVisible"
          class="additional-option__toggle"
          :class="{'rotate': isCollapseVisible}">
          <icon-arrow-double-up/>
        </button>
      </div>
    </div>
    <div class="additional-options-list">
      <div v-for="equipment in mechanicalEquipmentsList"
           :key="equipment.id"
           class="additional-options-list__inner">
        <div class="added-item added-item--flex">
          <div class="added-item__info">
            <div class="added-item__name">{{ equipment.name }}</div>
            <div class="added-item__additional">Время работы {{ equipment.duration }} ч</div>
          </div>
          <div
            v-show="isActionsVisible"
            class="added-item__right"
            @click="deleteEquipment(equipment.id)">
            <icon-trash class="svg added-item__tool"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconSettins from "@/components/Icons/IconSettins";
import IconPlus from "@/components/Icons/IconPlus";
import IconArrowDoubleUp from "@/components/Icons/IconArrowDoubleUp";
import IconTrash from "@/components/Icons/IconTrash";
import MechanicalEquipmentForm
  from "@/components/ServiceDesk/Tasks/TaskComponents/MechanicalEquipments/MechanicalEquipmentForm";
import {showToast, showAlert} from "@/utils/notification";
import {modalAdaptiveWidth} from "@/utils/helpers";

export default {
  name: "MechanicalEquipmentsList",
  components: {IconTrash, IconArrowDoubleUp, IconPlus, IconSettins},
  props: {
    taskId: {
      type: Number,
      required: true
    },
    mechanicalEquipmentsList: {
      type: Array,
      required: true
    },
    mechanicalEquipmentsTypes: {
      type: Array,
      required: true
    },
    isActionsVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isCollapseVisible: false
    }
  },
  methods: {
    showModal() {
      this.$modal.show(
        MechanicalEquipmentForm,
        {'mechanical-equipments': this.mechanicalEquipmentsTypes, 'task-id': this.taskId},
        {
          name: 'mechanical-equipment-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(600)
        }
      )
    },
    deleteEquipment(equipmentId) {
      this.$store.dispatch('taskMechanicalEquipments/removeMechanicalEquipmentFromTask', {
        taskId: this.taskId,
        payload: {
          equipment_id: equipmentId
        }
      })
        .then(() => {
          showToast('Средство малой механизации удалено из задания', 'success');
          this.$store.commit('taskModal/DELETE_MECHANICAL_EQUIPMENT', equipmentId);
        })
        .catch(error => showAlert('Ошибка', error, 'error'))
    }
  }
}
</script>

<style scoped>

</style>
