<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="19"
    viewBox="0 0 24 19"
    fill="none">
    <g clip-path="url(#clip0_5044_2030)">
      <path
        d="M5.24025 0.429199H2.70835C2.37783 0.429199 2.1099 0.695716 2.1099 1.02448C2.1099 1.35325 2.37783 1.61976
         2.70835 1.61976L3.88815 2.84113L5.24025 1.61976C5.57078 1.61976 5.83871 1.35325 5.83871 1.02448C5.83871
          0.695716 5.57078 0.429199 5.24025 0.429199Z"
        fill="#A4D9F6"/>
      <path
        d="M10.384 0.429199H7.85215C7.52163 0.429199 7.2537 0.695716 7.2537 1.02448C7.2537 1.35325 7.52163 1.61976
         7.85215 1.61976L9.15523 2.8092L10.384 1.61976C10.7145 1.61976 10.9825 1.35325 10.9825 1.02448C10.9825
         0.695716 10.7145 0.429199 10.384 0.429199Z"
        fill="#A4D9F6"/>
      <path
        d="M3.44171 1.44508C3.5499 1.55269 3.6991 1.61941 3.86471 1.61941H2.70821C2.54307 1.61941 2.39335 1.55264
         2.28521 1.44508C2.17707 1.33751 2.1099 1.18858 2.1099 1.02432C2.1099 0.695274 2.37793 0.428711 2.70821
          0.428711H3.86475C3.53396 0.428711 3.26644 0.695274 3.26644 1.02432C3.26644 1.18854 3.33305 1.33746
          3.44171 1.44508Z"
        fill="#90C4E0"/>
      <path
        d="M8.58549 1.44508C8.69366 1.55269 8.84289 1.61941 9.00849 1.61941H7.85248C7.68687 1.61941 7.53715 1.55264
         7.42901 1.44508C7.32082 1.33746 7.2537 1.18858 7.2537 1.02432C7.2537 0.695274 7.52173 0.428711 7.85248
          0.428711H9.00857C8.6778 0.428711 8.41024 0.695274 8.41024 1.02432C8.41024 1.18854 8.47685 1.33746 8.58549
           1.44508Z"
        fill="#90C4E0"/>
      <path
        d="M2.39142 1.61963H10.701C12.0218 1.61963 13.0924 2.68463 13.0924 3.99837V9.44156C13.0924 10.7553 12.0218
         11.8203 10.701 11.8203H2.39142C1.07067 11.8203 0 10.7553 0 9.44156V3.99837C0 2.68463 1.07067 1.61963
         2.39142 1.61963Z"
        fill="#A4D9F6"/>
      <path
        d="M13.0923 5.54346V9.44088C13.0923 10.7546 12.0217 11.82 10.7009 11.82L7.20254 13.2262L2.39133 11.82C1.07058
         11.82 0 10.7546 0 9.44088V5.54346C0 6.8572 1.07058 7.92257 2.39133 7.92257H10.701C12.0217 7.92261 13.0923
         6.8572 13.0923 5.54346Z"
        fill="#90C4E0"/>
      <path
        d="M14.163 15.4607H1.95109C1.67364 15.4607 1.44873 15.237 1.44873 14.961V12.3198C1.44873 12.0438 1.67364
        11.8201 1.95109 11.8201H15.8201"
        fill="#262F3D"/>
      <path
        d="M23.4915 13.4456V9.62029L22.1288 3.85696C21.9874 3.26116 21.4536 2.83984 20.8383 2.83984H15.2121C14.6324
         2.83984 14.1631 3.30718 14.1631 3.88376V15.4607H19.9617"
        fill="#A4D9F6"/>
      <path
        d="M16.6791 4.93682L16.6803 8.23262C16.6803 8.55303 16.4193 8.81289 16.0971 8.81289H15.763C15.4409 8.81289
        15.1798 8.55317 15.1798 8.2328V4.93701C15.1798 4.61664 15.4409 4.35693 15.763 4.35693H16.0959C16.418 4.35699
         16.6791 4.61655 16.6791 4.93682Z"
        fill="#468FC5"/>
      <path
        d="M20.9975 8.81332H18.4654C18.1434 8.81332 17.8824 8.5538 17.8822 8.23352L17.8807 4.93773C17.8806 4.61727
        18.1417 4.35742 18.4639 4.35742H20.2182C20.4889 4.35742 20.7239 4.54262 20.7859 4.80466L21.5651 8.10045C21.6513
         8.46456 21.3735 8.81332 20.9975 8.81332Z"
        fill="#468FC5"/>
      <path
        d="M22.3277 12.2049H23.4915V10.6575H22.3277C21.8982 10.6575 21.5499 11.0038 21.5499 11.4311C21.5499 11.8584
         21.8982 12.2049 22.3277 12.2049Z"
        fill="#F9F6F9"/>
      <path
        d="M22.5542 11.9783C22.6951 12.1185 22.8897 12.205 23.1044 12.205H22.3279C22.1128 12.205 21.9186 12.1185
        21.7778 11.9783C21.6369 11.8382 21.5499 11.6446 21.5499 11.4311C21.5499 11.0037 21.8983 10.6572 22.3279
        10.6572H23.1044C22.6747 10.6572 22.3263 11.0037 22.3263 11.4311C22.3264 11.6445 22.4133 11.8382 22.5542
        11.9783Z"
        fill="#DDDAEC"/>
      <path
        d="M19.9617 13.4458H23.5431C23.7954 13.4458 24 13.6493 24 13.9003V15.0311C24 15.2821 23.7954 15.4855 23.5431
        15.4855H19.9617V13.4458Z"
        fill="#262F3D"/>
      <path
        d="M18.8267 18.2353C20.3534 18.2353 21.591 17.0042 21.591 15.4856C21.591 13.967 20.3534 12.7358 18.8267
        12.7358C17.3 12.7358 16.0623 13.967 16.0623 15.4856C16.0623 17.0042 17.3 18.2353 18.8267 18.2353Z"
        fill="#262F3D"/>
      <path
        d="M18.8266 16.7828C19.547 16.7828 20.1309 16.2019 20.1309 15.4855C20.1309 14.769 19.547 14.1882 18.8266
        14.1882C18.1064 14.1882 17.5225 14.769 17.5225 15.4855C17.5225 16.2019 18.1064 16.7828 18.8266 16.7828Z"
        fill="#F9F6F9"/>
      <path
        d="M5.62624 18.2353C7.15296 18.2353 8.39061 17.0042 8.39061 15.4856C8.39061 13.967 7.15296 12.7358 5.62624
        12.7358C4.09953 12.7358 2.86189 13.967 2.86189 15.4856C2.86189 17.0042 4.09953 18.2353 5.62624 18.2353Z"
        fill="#262F3D"/>
      <path
        d="M5.62623 16.7828C6.34652 16.7828 6.93043 16.2019 6.93043 15.4855C6.93043 14.769 6.34652 14.1882 5.62623
        14.1882C4.90593 14.1882 4.32202 14.769 4.32202 15.4855C4.32202 16.2019 4.90593 16.7828 5.62623 16.7828Z"
        fill="#F9F6F9"/>
      <path
        d="M16.4647 10.4517H15.4876C15.2876 10.4517 15.1255 10.2904 15.1255 10.0915C15.1255 9.89252 15.2876 9.7312
        15.4876 9.7312H16.4647C16.6647 9.7312 16.8269 9.89252 16.8269 10.0915C16.8269 10.2904 16.6647 10.4517
        16.4647 10.4517Z"
        fill="#F9F6F9"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconWaterCarrier"
}
</script>

<style scoped>

</style>
