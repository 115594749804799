var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-popup__wrapper"},[_c('div',{staticClass:"base-popup__header"},[_vm._v(" Обнаруженная проблема "+_vm._s(_vm.detectedProblem ? _vm.detectedProblem.id : '')+" "),_c('span',{staticClass:"base-popup__close",on:{"click":function($event){return _vm.$modal.hide('detected-problems-form')}}},[_c('icon-close')],1)]),_c('div',{staticClass:"popup-form-group"},[_c('problem-category-selector',{attrs:{"category_id":_vm.payload.category_id,"group":_vm.group_id},on:{"update:category_id":function (categoryId) { return _vm.payload.category_id = categoryId; },"update:group_id":function (groupId) { return _vm.group_id = groupId; }}}),_c('div',{staticClass:"form-item"},[_c('div',{staticClass:"form-item__name"},[_vm._v("Адрес")]),_c('div',{staticClass:"form-item__value"},[_c('address-selector',{attrs:{"address-string":_vm.addressString},on:{"update:address-string":function (value) { return this$1.addressString = value; },"set-address":_vm.setAddress}})],1)]),_c('defected-problem-map',{on:{"update:coords":function (ref) {
        var lat = ref.lat;
        var lng = ref.lng;

        _vm.payload.address_lat = lat;
        _vm.payload.address_lon = lng;
      }}}),_c('div',{staticClass:"form-item"},[_c('div',{staticClass:"form-item__name"},[_vm._v("Комментарий")]),_c('div',{staticClass:"form-item__value"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.payload.message),expression:"payload.message"}],staticClass:"base-textarea",attrs:{"type":"text","placeholder":"Введите комментарий"},domProps:{"value":(_vm.payload.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.payload, "message", $event.target.value)}}})])]),_c('div',{staticClass:"form-item--border-top"},[_c('detected-problem-attachments',{attrs:{"detected-problem-attachments":_vm.detectedProblemAttachments},on:{"delete:detected-problem-attachment":_vm.deleteDetectedProblemAttachment,"update:attachments-list":function (files) { return this$1.uploadFilesList = files; }}})],1)],1),_c('div',{staticClass:"base-popup__footer base-popup__footer--flex"},[_c('div',{staticClass:"button",on:{"click":function($event){return _vm.submitForm()}}},[_vm._v(" Сохранить ")]),_c('div',{staticClass:"button button--mode-outline",on:{"click":function($event){return _vm.$modal.hide('detected-problems-form')}}},[_vm._v(" Отменить ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }