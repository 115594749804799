import store from "@/store";
export default {
    methods: {
        searchAppealsByAddress(event) {
            const searchString = event.target.value;
            if (searchString.length > 2) {
                store.commit('appeals/SET_FILTER_PARAM', {
                    key: 'address_string',
                    value: searchString
                });
                store.dispatch('appeals/getAppeals', 1);
            } else if (searchString.length === 0){
                store.commit('appeals/SET_FILTER_PARAM', {
                    key: 'address_string',
                    value: null
                });
                store.dispatch('appeals/getAppeals', 1);
            }
        }
    }
}
