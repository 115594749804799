export default class AppealSourceType {
    static CALL = {id: 2, label: 'Звонок'}
    static SITE = {id: 1, label: 'Сайт'}
    static MESSENGER = {id: 3, label: 'Мессенджер'}
    static SYSTEM = {id: 4, label: 'Система'}

    static get sourceTypesList() {
        return [
            this.CALL,
            this.SITE,
            this.MESSENGER,
            this.SYSTEM
        ]
    }
}
