<template>
  <div class="base-table">
    <div class="base-table__container">
      <div class="base-table__header base-table__column--energy-consumption-object">
        <div class="base-table__header-cell">ID</div>
        <div class="base-table__header-cell">Наименование</div>
        <div class="base-table__header-cell">Адрес</div>
        <div class="base-table__header-cell"/>
      </div>

      <div v-for="energyConsumptionObject in energyConsumptionObjectsList"
           :key="energyConsumptionObject.id"
           @click="$emit('open-energy-consumption-object-modal', energyConsumptionObject.id)"
           class="base-table__row base-table__column--energy-consumption-object">
        <div class="base-table__column">
          <div class="base-table__text">{{ energyConsumptionObject.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ energyConsumptionObject.name }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ energyConsumptionObject.address }}</div>
        </div>
        <div class="base-table__column base-table__column--content-right">
          <div class="base-table__short-info">
            <div @click.stop="$emit('edit-energy-consumption-object', energyConsumptionObject)">
              <icon-pencil class="base-table__short-info--p-2" />
            </div>
            <div @click.stop="$emit('delete-energy-consumption-object', energyConsumptionObject.id)">
              <icon-trash />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconTrash from "@/components/Icons/IconTrash.vue";
import IconPencil from "@/components/Icons/IconPencil.vue";

export default {
  name: "EnergyConsumptionObjectsList",
  components: {IconPencil, IconTrash},
  props: {
    energyConsumptionObjectsList: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.base-table__column--energy-consumption-object {
  grid-template-columns: 1fr 5fr 5fr 1fr;
}
</style>