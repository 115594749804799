<template>
  <div class="task-modal__actions">
    <div v-if="taskStateId === TASK_STATE.NEW"
         @click="doTaskAction('task/cancelTask', 'Задание отменено', TASK_STATE.CANCELED)"
         class="button button--mode-outline task-modal__cancel-btn">
      <span>Отменить</span>
    </div>
    <div v-if="taskStateId === TASK_STATE.NEW"
         @click="doTaskAction('task/onTheWayTask', 'Задание переведено в путь', TASK_STATE.ON_THE_WAY)"
         class="button button--mode-primary task-modal__start-btn">
      <span>В путь</span>
    </div>
    <div v-if="taskStateId === TASK_STATE.ON_THE_WAY"
         @click="doTaskAction('task/startTask', 'Задание переведено в работу', TASK_STATE.IN_WORK)"
         class="button button--mode-primary task-modal__start-btn">
      <span>В работу</span>
    </div>
    <div v-if="taskStateId === TASK_STATE.SUSPENDED"
         @click="doTaskAction('task/resumeTask', 'Задание возобновлено', TASK_STATE.IN_WORK)"
         class="button button--mode-primary task-modal__resume-btn">
      <span>Возобновить</span>
    </div>
    <div v-if="taskStateId === TASK_STATE.IN_WORK"
         @click="doTaskAction('task/completeTask', 'Задание завершено', TASK_STATE.CLOSED)"
         class="button button--mode-primary task-modal__complete-btn">
      <span>Завершить</span>
    </div>
    <div v-if="taskStateId === TASK_STATE.IN_WORK"
         @click="doTaskAction('task/suspendTask', 'Задание приостановлено', TASK_STATE.SUSPENDED)"
         class="button button--mode-outline task-modal__suspend-btn">
      <span>В ожидание</span>
      <icon-timer />
    </div>
  </div>
</template>

<script>
import {showAlert, showToast} from "@/utils/notification";
import TaskState from "@/models/serviceDesk/TaskState";
import IconTimer from "@/components/Icons/IconTimer";

export default {
  name: "TaskStateActions",
  components: {IconTimer},
  props: {
    taskStateId: {
      type: Number,
      required: true
    },
    taskId: {
      type: Number,
      required: true
    }
  },
  computed: {
    TASK_STATE: () => TaskState
  },
  methods: {
    doTaskAction(action, successMessage, state) {
      this.$store.dispatch(action, this.taskId)
        .then(() => {
          this.$store.dispatch('taskModal/getTask', this.taskId);
          this.$store.dispatch('taskModal/getHistory', this.taskId);
          this.$store.commit('tasks/UPDATE_TASK_STATE', {
            taskId: this.taskId,
            taskState: state
          });

          showToast(successMessage, 'success');
        })
        .catch(error => showAlert('Ошибка', error, 'error'));
    }
  }
}
</script>

<style scoped>
</style>
