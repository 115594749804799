import ArchiveJournalService from "@/services/ArchiveJournalService";

export const namespaced = true;

export const state = {
    parametersValues: [],
    error: null,
    loading: false
}

export const mutations = {
    SET_PARAMETERS_VALUES(state, parametersValues) {
        state.parametersValues = parametersValues;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    }
}

export const actions = {
    getNetworkEquipmentArchive({commit}, {networkEquipmentId, params, routeGroup}) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ArchiveJournalService.getArchive(networkEquipmentId, params, routeGroup)
                .then(parametersValues => {
                    commit("SET_PARAMETERS_VALUES", parametersValues);
                    resolve(parametersValues);
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
                .finally(() => commit("SET_LOADING", false))
        })
    }
}