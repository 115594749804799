import * as API from './API'
import NetworkElement from "@/models/EngineeringNetworks/NetworkElement";
import {getError} from "@/utils/helpers";
import NetworkElementWithParams from "@/models/EngineeringNetworks/NetworkElementWithParams";

export default {
    fetchNetworkElementsByCoords(params) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/network-engineering/elements/search`, {params: params})
                .then(response => resolve(response.data.data
                    .map(item => new NetworkElement({
                        id: item.id,
                        ...item.attributes
                    }))))
                .catch(error => reject(getError(error)))
        })
    },
    fetchNetworkElementById(elementId, sourceId) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/network-engineering/elements/${elementId}?source_id=${sourceId}`)
                .then(response => {
                    const {id, attributes} = response.data.data;
                    resolve(new NetworkElementWithParams({
                        id,
                        ...attributes
                    }))
                })
                .catch(error => reject(getError(error)))
        })
    },
    changeNetworkElementMode(params) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/network-engineering/elements/${params.element_id}/mode`, {...params})
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}