<template>
  <div class="i-modal__content-wrapper">
    <div class="i-modal__content">
      <div v-if="task"
           class="i-modal__inner">
        <div :class="{'base-modal__left-side--hidden': isHistoryVisible}"
             class="base-modal__left-side">
          <div class="base-modal-header base-modal-header--padding-s">
            <span class="base-modal-title">Задание на обход #{{ task.id }}</span>
            <div class="base-modal-header__status">
              <div class="application-status-wrapper">
                <div class="application-status"
                     :class="[
                       {'base-table__tag--new': task.state.id === TechnicalInspectionTaskState.NEW},
                       {'base-table__tag--new': task.state.id === TechnicalInspectionTaskState.REVIEW},
                       {'base-table__tag--work': task.state.id === TechnicalInspectionTaskState.IN_WORK},
                       {'base-table__tag--done': task.state.id === TechnicalInspectionTaskState.FINISHED},
                       {'base-table__tag--canceled': task.state.id === TechnicalInspectionTaskState.CLOSED},
                       {'base-table__tag--canceled': task.state.id === TechnicalInspectionTaskState.CANCELED}
                     ]">
                  {{ task.state.name }}
                </div>
              </div>

              <div @click="$store.commit('baseSideModal/TOGGLE_MODAL_HORIZONTAL')"
                   class="base-modal-header__history-toggle"
                   :class="{'active': isHistoryVisible}">
                <icon-history-toggle/>
              </div>
            </div>
          </div>
          <div class="base-modal-info base-modal-info--gap-20 base-modal-info--padding-s">
            <div class="base-modal-info__card base-modal-info__card--padding-s">
              <base-collapse>
                <template #toggle="{toggle, isCollapsed}">
                  <div class="base-modal-info__card-title base-modal-info__card-title--loader">
                    <span class="base-modal-info__card-collapse"
                          :class="{'base-modal-info__card-collapse--closed': isCollapsed}"
                          @click="toggle()">
                      Детальная информация
                      <icon-arrow-down/>
                    </span>
                  </div>
                </template>

                <template #body>
                  <div>
                    <div class="base-modal-info__item">
                      <div class="base-modal-info__name">
                        <span>Объект</span>
                      </div>
                      <div class="base-modal-info__value">
                        <p class="base-modal-info__value-txt">
                          {{ task.object.name }}
                        </p>
                      </div>
                    </div>

                    <div class="base-modal-info__item">
                      <div class="base-modal-info__name">
                        <span>Адрес</span>
                      </div>
                      <div class="base-modal-info__value">
                        <p class="base-modal-info__value-text">
                          {{ task.object.address }}
                          <span
                            v-if="!isMapVisible"
                            @click="toggleMapVisible()"
                            class="base-modal-info__value--link">
                            На карте
                          </span>
                          <span
                            v-else
                            @click="toggleMapVisible()"
                            class="base-modal-info__value--link">
                            Скрыть карту
                          </span>
                        </p>

                        <task-map
                          :task="task"
                          :is-map-visible="isMapVisible"
                        />
                      </div>
                    </div>

                    <div class="base-modal-info__item">
                      <div class="base-modal-info__name">
                        <span>Обходчик</span>
                      </div>
                      <div class="base-modal-info__value">
                        <p class="base-modal-info__value-txt">
                          {{ task.resource.name }}
                        </p>
                      </div>
                    </div>

                    <div class="base-modal-info__item">
                      <div class="base-modal-info__name">
                        <span>Плановая дата выполнения</span>
                      </div>
                      <div class="base-modal-info__value">
                        <p class="base-modal-info__value-txt">
                          {{ formatDate(task.plannedDate) }}
                        </p>
                      </div>
                    </div>

                    <div class="base-modal-info__item">
                      <div class="base-modal-info__name">
                        <span>Приоритет</span>
                      </div>
                      <div class="base-modal-info__value">
                        <p class="base-modal-info__value-txt">
                          {{ task.priority.name }}
                        </p>
                      </div>
                    </div>

                  </div>
                </template>
              </base-collapse>
            </div>

            <div
              v-if="task.attachments.length"
              class="task-files-list">
              <div class="task-files-list__title">Фото и видео</div>
              <files-list
                :files-list="task.attachments"
                :max-length="10"
              />
            </div>

            <div class="tabs">
              <div class="tabs__header tabs__header--block">
                <div
                  @click="activeTab = 0"
                  :class="{'active': activeTab === 0}"
                  class="tabs__header-item">Чек-лист
                </div>

                <div
                  @click="activeTab = 1"
                  :class="{'active': activeTab === 1}"
                  class="tabs__header-item">Дефекты и нарушения
                </div>
              </div>

              <div :class="{'active': activeTab === 0}"
                   class="tabs__item">
                <questions-list :questions-list="questionsList"/>
              </div>

              <div :class="{'active': activeTab === 1}"
                   class="tabs__item">
                <violations-list :violations-list="violationsList"/>
              </div>
            </div>

            <div
              v-if="task.state.id === TechnicalInspectionTaskState.NEW ||
                task.state.id === TechnicalInspectionTaskState.REVIEW"
              class="base-modal-btn-wp">
              <task-state-actions
                :task-id="task.id"
                :task-state-id="task.state.id"
              />
            </div>
          </div>
        </div>

        <div v-if="isHistoryVisible"
             :class="{'base-modal__right-side--visible': isHistoryVisible}"
             class="base-modal__right-side">
          <history-list :history-list="history"/>
        </div>
      </div>
    </div>

    <div v-if="task"
         class="i-modal-btn-wp i-modal-btn-wp--back">
      <div v-if="isHistoryVisible"
           @click="$store.commit('baseSideModal/TOGGLE_MODAL_HORIZONTAL')"
           class="button button--mode-outline task-modal__history-hide">
        <icon-arrow-down/>
        Вернуться назад
      </div>

      <div v-else
           class="task-modal__mobile-actions">
        <button @click="$store.dispatch('baseSideModal/closeModal')"
                class="button button--mode-outline task-modal__mobile-back">
          <icon-arrow-left/>
        </button>
        <task-state-actions
          :task-id="task.id"
          :task-state-id="task.state.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import IconHistoryToggle from "@/components/Icons/IconHistoryToggle.vue";
import {mapState} from "vuex";
import {TechnicalInspectionTaskState} from "@/models/TechnicalInspection/TechnicalInspectionTaskState";
import BaseCollapse from "@/components/BaseCollapse.vue";
import IconArrowDown from "@/components/Icons/IconArrowDown.vue";
import TaskMap from "@/components/TechnicalInspection/Tasks/TaskMap.vue";
import {formatDate} from "@/utils/filters";
import HistoryList from "@/components/ServiceDesk/Tasks/TaskComponents/History/HistoryList.vue";
import IconArrowLeft from "@/components/Icons/IconArrowLeft.vue";
import TaskStateActions from "@/components/TechnicalInspection/Tasks/TaskStateActions.vue";
import QuestionsList from "@/components/TechnicalInspection/Tasks/QuestionsList.vue";
import ViolationsList from "@/components/TechnicalInspection/Tasks/ViolationsList.vue";
import FilesList from "@/components/ServiceDesk/CommonComponents/FilesList/FilesList.vue";

export default {
  name: "TaskModalContent",
  components: {
    FilesList,
    ViolationsList,
    QuestionsList,
    TaskStateActions,
    IconArrowLeft,
    HistoryList,
    TaskMap,
    IconArrowDown,
    BaseCollapse,
    IconHistoryToggle
  },
  data() {
    return {
      isMapVisible: false,
      activeTab: 0
    }
  },
  computed: {
    TechnicalInspectionTaskState() {
      return TechnicalInspectionTaskState
    },
    ...mapState({
      task: state => state.technicalInspectionTasks.task,
      history: state => state.technicalInspectionTasks.history,
      isHistoryVisible: state => state.baseSideModal.isModalHorizontal,
      questionsList: state => state.questions.questionsList,
      violationsList: state => state.technicalInspectionTasks.violationsList
    })
  },
  methods: {
    formatDate,
    toggleMapVisible() {
      this.isMapVisible = !this.isMapVisible;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";


.task-files-list {
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding: 24px 20px;
  border-radius: 16px;
  background: var(--panel-bg);

  &__title {
    @include font-style(500, 20px, 28px, var(--text-primary));
  }
}
</style>