<script>
export default {
  name: "IconFlashCircle"
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none">
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22
     12 22Z"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M8.67938 11.9137L10.3294 12.3237L9.37939 16.1638C9.15939 17.0638 9.59937 17.3637 10.3594 16.8337L15.5394
       13.2437C16.1694 12.8037 16.0794 12.2937 15.3294 12.1037L13.6794 11.6937L14.6294 7.85372C14.8494 6.95372
        14.4094 6.65374 13.6494 7.18374L8.46939 10.7738C7.83939 11.2138 7.92938 11.7237 8.67938 11.9137Z"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>

<style scoped>

</style>