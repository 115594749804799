<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="20"
       height="20"
       viewBox="0 0 20 20"
       fill="none">
    <path
      d="M10.1818 9.82104L8.91282 11.09C8.21082 11.792 8.21082 12.935 8.91282 13.637C9.61482 14.339 10.7578 14.339
       11.4598 13.637L13.4578 11.639C14.1321 10.9629 14.5107 10.0469 14.5107 9.09204C14.5107 8.13715 14.1321 7.22122
        13.4578 6.54504C12.7816 5.87081 11.8657 5.49219 10.9108 5.49219C9.95593 5.49219 9.04 5.87081 8.36382
        6.54504L6.18582 8.72304C5.89905 9.00956 5.67156 9.34979 5.51635 9.72428C5.36114 10.0988 5.28125 10.5002
         5.28125 10.9055C5.28125 11.3109 5.36114 11.7123 5.51635 12.0868C5.67156 12.4613 5.89905 12.8015 6.18582 13.088"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M10 19C14.9707 19 19 14.9707 19 10C19 5.0293 14.9707 1 10 1C5.0293 1 1 5.0293 1 10C1 14.9707 5.0293 19 10 19Z"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconClipCircle"
}
</script>

<style scoped>

</style>
