import * as API from './API';
import NetworkEquipment from "@/models/telemetry/NetworkEquipment";
import {getError} from "@/utils/helpers";

export default {
    fetchNetworkEquipments(filterParams) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/network-equipments`, {params: filterParams})
                .then(response => resolve(response.data.data.map(item => new NetworkEquipment({
                    id: item.id,
                    ...item.attributes
                }))))
                .catch(error => reject(getError(error)))
        })
    },
    fetchNetworkEquipment(equipmentId) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/network-equipments/${equipmentId}`)
                .then(response => {
                    const {id, attributes} = response.data.data;
                    resolve(new NetworkEquipment({id, ...attributes}));
                })
                .catch(error => reject(getError(error)))
        })
    },
    fetchScadaEquipments() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/network-equipments/scada`)
                .then(response => resolve(response.data.data
                    .map(item => new NetworkEquipment({
                        id: item.id,
                        ...item.attributes
                    }))))
                .catch(error => reject(getError(error)))
        })
    },
    createNetworkEquipment(payload) {
        return API.apiClient.post(`/network-equipments`, payload);
    },
    updateNetworkEquipment(equipmentId, payload) {
        return API.apiClient.patch(`/network-equipments/${equipmentId}`, payload);
    },
    deleteNetworkEquipment(equipmentId) {
        return API.apiClient.delete(`/network-equipments/${equipmentId}`);
    }
}