<template>
  <div class="scada-table__tooltip">
    <div class="scada-table__tooltip-header"
         :class="parameterTimeStatus(parameterValue)">
      Время проверки последний раз было обновлено
    </div>
    <div class="scada-table__tooltip-time">
      {{$moment(parameterValue.readingAt).fromNow()}}, <br>
      {{ formatDateTime(parameterValue.readingAt)}}
    </div>
    <div
      v-if="parameterProfile"
      class="scada-table__tooltip-body">
      <div class="scada-table__tooltip-body-header">Пороговые значения</div>
      <div class="scada-table__tooltip-values">
        <span>{{parameterProfile.min}}</span>
        <span> — </span>
        <span>{{parameterProfile.max}} </span>
        <span>{{parameter.measure}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {formatDateTime} from "@/utils/filters";
import {parameterTimeStatus} from '@/utils/helpers';

export default {
  name: "ParameterTooltip",
  props: {
    parameter: {
      required: true
    },
    parameterValue: {
      required: true
    },
    parameterProfile: {
      required: true
    }
  },
  methods: {
    formatDateTime,
    parameterTimeStatus
  }
}
</script>

<style scoped>

</style>