<template>
  <div class="base-table">
    <div class="base-table__container">
      <div class="base-table__header base-table__column--layers">
        <div class="base-table__header-cell">ID</div>
        <div class="base-table__header-cell">Наименование</div>
        <div class="base-table__header-cell">Ссылка</div>
        <div class="base-table__header-cell">Ресурс</div>
        <div class="base-table__header-cell"/>
      </div>

      <div v-for="layer in layersList"
           :key="layer.id"
           @click="$emit('show-layer-modal', layer.id)"
           class="base-table__row base-table__column--layers">
        <div class="base-table__column">
          <div class="base-table__text">{{ layer.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ layer.name }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ layer.url }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ layer.resource }}</div>
        </div>
        <div class="base-table__column base-table__column--content-right">
          <div class="base-table__short-info">
            <div @click.stop="$emit('edit-layer', layer)">
              <icon-pencil class="base-table__short-info--p-2" />
            </div>
            <div @click.stop="$emit('delete-layer', layer.id)">
              <icon-trash />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconPencil from "@/components/Icons/IconPencil.vue";
import IconTrash from "@/components/Icons/IconTrash.vue";

export default {
  name: "LayersList",
  components: {IconTrash, IconPencil},
  props: {
    layersList: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.base-table__column--layers {
  grid-template-columns: 1fr 5fr 5fr 5fr 2fr;
}
</style>