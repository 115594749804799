export default class User {
    constructor({id, attributes}) {
        this.id = id;
        this.name = attributes.name;
        this.email = attributes.email;
        this.isAdmin = attributes.isAdmin;
        this.permissions = attributes.permissions;
        this.roles = attributes.roles;
        this.phone = attributes.phone;
        this.job_title = attributes.job_title;
        this.structuralUnit = attributes.structural_unit;
        this.blockedAt = attributes.blocked_at;
        this.createdAt = attributes.created_at;
    }
}
