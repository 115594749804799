import * as API from './API';
import HistoryItem from "@/models/serviceDesk/HistoryItem";
import {getError} from "@/utils/helpers";

export default {
    getAppealHistory(appeal_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`call-center/appeals/${appeal_id}/history`)
                .then(response => resolve(
                    response.data.data.map(item => new HistoryItem(item.id, item.attributes))
                ))
                .catch(error => reject(getError(error)));
        })
    },
    getIssueHistory(issue_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/issues/${issue_id}/history`)
                .then(response => resolve(
                    response.data.data.map(item => new HistoryItem(item.id, item.attributes))
                ))
                .catch(error => reject(getError(error)));
        })
    },
    getTaskHistory(task_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/tasks/${task_id}/history`)
                .then(response => resolve(
                    response.data.data.map(item => new HistoryItem(item.id, item.attributes))
                ))
                .catch(error => reject(getError(error)));
        })
    }
}