<template>
  <div class="base-panel">
    <div class="panel__inner">
      <span class="panel__title">{{title}}</span>
      <base-loader v-if="loading" />
      <div class="panel__right">
        <div class="panel__general-indicators">
          <base-current-weather
            v-if="isWeatherVisible"
            :class="{'panel__general-indicators--with-time': isWeatherVisible && isTimeVisible}"
          />
          <base-current-time v-if="isTimeVisible" />
        </div>

        <div
          v-if="isAddButtonVisible"
          class="panel__buttons">
          <slot name="buttons">
            <button class="button button--panel"
                    data-modal-toggle="base-filter-modal"
                    @click="togledModalCard">
              <icon-plus/>
              <span>{{addButtonName}}</span>
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IconPlus from "@/components/Icons/IconPlus.vue";
import BaseLoader from "@/layout/BaseLoader";
import BaseCurrentTime from "@/components/BaseCurrentTime.vue";
import BaseCurrentWeather from "@/components/BaseCurrentWeather.vue";

export default {
  name: "BasePanel",
  components: {
    BaseCurrentWeather,
    BaseCurrentTime,
    BaseLoader,
    IconPlus
  },
  props: {
    title: {
      type: String
    },
    isAddButtonVisible: {
      type: Boolean,
      default: true
    },
    addButtonName: {
      type: String,
      default: 'Добавить'
    },
    loading: {
      type: Boolean,
      default: false
    },
    isTimeVisible:  {
      type: Boolean,
      default: false
    },
    isWeatherVisible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    togledModalCard() {
      this.$emit('add-button-clicked');
    }
  }
}
</script>
