<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="24"
       height="24"
       viewBox="0 0 24 24"
       fill="none">
    <path
      d="M8 2V5M16 2V5M3.5 9.09H20.5M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5
       8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
      stroke="#B9BAD5"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M15.694 13.7031H15.703M15.694 16.7031H15.703M11.994 13.7031H12.004M11.994 16.7031H12.004M8.29297
       13.7031H8.30297M8.29297 16.7031H8.30297"
      stroke="#9192AD"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconCalendar"
}
</script>

<style scoped>

</style>
