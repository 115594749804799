<template>
  <div class="page-wrapper">
    <base-panel
      title="Объекты сети"
      :isAddButtonVisible="false"
    />

    <base-functional-line @search-change="setSearchQuery">
      <div
        @click="showNetworkObjectCreateModal()"
        class="button button--mode-primary">
        <icon-plus />
        <span>Добавить объект сети</span>
      </div>
    </base-functional-line>

    <network-objects-list
      v-if="networkObjectsList.length"
      :network-objects-list="networkObjectsList"
      @open-network-object-modal="showNetworkObjectModal"
    />

    <base-content-loaders v-if="loading && !networkObjectsList.length"/>

    <base-no-data v-if="!loading && !networkObjectsList.length"/>
  </div>
</template>

<script>
import NetworkObjectsList from "@/components/Telemetry/admin/NetworkObject/NetworkObjectsList";
import {mapGetters, mapState} from "vuex";
import BasePanel from "@/components/BasePanel.vue";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import BaseFunctionalLine from "@/components/BaseFunctionalLine.vue";
import IconPlus from "@/components/Icons/IconPlus.vue";

export default {
  name: "NetworkObjectsListPage",
  components: {
    IconPlus,
    BaseFunctionalLine,
    BaseNoData,
    BaseContentLoaders,
    BasePanel,
    NetworkObjectsList
  },
  computed: {
    ...mapState({
      loading: state => state.networkObjects.loading
    }),
    ...mapGetters({
      networkObjectsList: "networkObjects/getNetworkObjectsByQuery"
    })
  },
  mounted() {
    this.$store.dispatch('networkObjects/getAllNetworkObjects');
    this.$store.dispatch('structuralUnits/getStructuralUnitsList');
  },
  beforeDestroy() {
    this.$store.commit('networkObjects/SET_QUERY', '');
  },
  methods: {
    setSearchQuery(event) {
      this.$store.commit('networkObjects/SET_QUERY', event.target.value);
    },
    showNetworkObjectModal(networkObjectId) {
      this.$store.dispatch('baseSideModal/openModal', {
        modalContentType: 'networkObject',
        entityId: networkObjectId,
        propsData: {
          isEditAvailable: true
        }
      })
    },
    showNetworkObjectCreateModal() {
      this.$store.dispatch('baseSideModal/openModal', {
        modalContentType: 'networkObjectForm'
      })
    }
  }
}
</script>

<style scoped>

</style>
