<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      <template v-if="!Object.keys(networkObjectType).length">Добавить тип объекта сети</template>
      <template v-else>Редактирование типа объекта сети</template>
      <span @click="$modal.hide('network-object-type-form')"
            class="base-popup__close">
        <icon-close/>
      </span>
    </div>
    <div class="popup-form-group">
      <div class="form-item">
        <div class="form-item__name">Наименование</div>
        <div class="form-item__value">
          <input
            v-model="payload.name"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите наименование"
            required
          >
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Группа</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.group_id"
            :options="networkObjectTypeGroups"
            :reduce="group => group.id"
            :get-option-label="(option) => option.name"
            class="vue-select-input"
            placeholder="Выберите из списка"
          />
        </div>
      </div>
    </div>
    <div class="base-popup__footer">
      <div @click="checkFormType()"
           class="button">
        Сохранить
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from "@/components/Icons/IconClose.vue";

export default {
  name: "NetworkObjectTypesForm",
  components: {IconClose},
  props: {
    networkObjectType: {
      type: Object,
      default: () => {}
    },
    networkObjectTypeGroups: {
      type: Array,
      required: true
    },
    createNetworkObjectType: {
      type: Function,
      default: () => {}
    },
    updateNetworkObjectType: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      payload: {
        name: null,
        group_id: null
      }
    }
  },
  mounted() {
    if (Object.keys(this.networkObjectType).length) {
      const {name, groupId} = this.networkObjectType;
      this.payload.name = name;
      this.payload.group_id = groupId;
    }
  },
  methods: {
    checkFormType() {
      if (Object.keys(this.networkObjectType).length)
        this.updateNetworkObjectType(this.networkObjectType.id, this.payload)
      else this.createNetworkObjectType(this.payload)
    }
  }
}
</script>

<style scoped>

</style>