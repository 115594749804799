<template>
  <div
    class="map-marker">
    <map-icon-bg/>
    <div class="map-marker__inner">
      <div
        class="map-marker__img"
        :class="[
          {'map-marker__img--bg-new': appeal.state === AppealState.NEW},
          {'map-marker__img--bg-work': appeal.state === AppealState.IN_WORK},
          {'map-marker__img--bg-done': appeal.state === AppealState.CLOSED},
          {'map-marker__img--bg-canceled': appeal.state === AppealState.CANCELED}
        ]">
        <icon-comment class="map-marker__img--white"/>
      </div>
    </div>
  </div>
</template>

<script>
import MapIconBg from "@/components/Icons/MapIconBg.vue";
import AppealState from "@/models/callCenter/AppealState";
import IconComment from "@/components/Icons/IconComment.vue";

export default {
  name: "AppealMapIcon",
  components: {IconComment, MapIconBg},
  props: {
    appeal: {
      type: Object
    }
  },
  computed: {
    AppealState: () => AppealState
  }
}
</script>

<style scoped>

</style>
