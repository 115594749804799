<script>
export default {
  name: "IconMoon"
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M17.5115 13.53L17.5114 13.5301L17.5186 13.5415C17.5425 13.5795 17.5793 13.6566 17.5717 13.7853C17.564 13.917 17.5072 14.1437 17.2708 14.4724C15.7155 16.584 13.2496 17.8251 10.6167 17.8251C10.5016 17.8251 10.3838 17.8179 10.2479 17.8094L10.2479 17.8092L10.2367 17.8088C9.45387 17.7775 8.69409 17.6288 7.97259 17.3778L7.97197 17.3776C4.94884 16.3305 2.67546 13.5525 2.44043 10.3232C2.2056 6.88025 4.18948 3.66255 7.40106 2.31142C7.77445 2.15661 8.00903 2.15455 8.14095 2.17848C8.27058 2.202 8.34254 2.25803 8.37978 2.29528C8.41127 2.32677 8.46341 2.39323 8.48454 2.51422C8.50589 2.63641 8.50303 2.85729 8.34994 3.20869L8.34945 3.20983C7.94633 4.14148 7.75 5.12598 7.74999 6.14172L7.75001 6.14417C7.75898 7.9753 8.47708 9.66592 9.63562 10.9584C10.946 12.4289 12.8286 13.3848 14.9107 13.4745C15.4774 13.5014 16.0396 13.4562 16.5873 13.359L16.5879 13.3589C16.9839 13.2882 17.2097 13.336 17.3282 13.3854C17.4441 13.4338 17.4928 13.4984 17.5115 13.53Z" fill="#9192AD" stroke="#9192AD"/>
  </svg>
</template>

<style scoped>

</style>