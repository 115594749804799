<template>
  <base-popup
    :title="popupTitle"
    :name="popupName"
  >
    <template #body>
      <div class="form-item">
        <div class="form-item__name">Наименование</div>
        <div class="form-item__value">
          <input
            v-model="$v.payload.name.$model"
            class="base-input base-input--bordered"
            :class="{'base-input--error': $v.payload.name.$error}"
            type="text"
            placeholder="Введите наименование">
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Мастер</div>
        <div class="form-item__value">
          <v-select
            class="vue-select-input"
            v-model="payload.master_id"
            :options="staffList"
            :reduce="employee => employee.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите мастера"/>
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Транспорт</div>
        <div class="form-item__value">
          <v-select
            class="vue-select-input"
            v-model="payload.transport_id"
            :options="vehiclesList"
            :reduce="vehicle => vehicle.id"
            :get-option-label="(option) => option.attributes.label"
            placeholder="Выберите транспорт"/>
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Подразделение</div>
        <div class="form-item__value">
          <v-select
            class="vue-select-input"
            :class="{'vue-select-input--error': $v.payload.department_id.$error}"
            v-model="$v.payload.department_id.$model"
            :options="departmentsList"
            :reduce="department => department.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите подразделение"/>
        </div>
      </div>

      <div
        v-if="!Object.keys(resource).length"
        class="form-item">
        <div class="form-item__name">Структурное подразделение</div>
        <div class="form-item__value">
          <v-select
            class="vue-select-input"
            :class="{'vue-select-input--error': $v.payload.structural_unit_id.$error}"
            v-model="$v.payload.structural_unit_id.$model"
            :options="structuralUnitsList"
            :reduce="structuralUnit => structuralUnit.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите структурное подразделение"/>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div
        @click="checkFormType()"
        class="button">
        Сохранить
      </div>
    </template>
  </base-popup>
</template>

<script>
import BasePopup from "@/components/BasePopup.vue";
import {validationMixin} from "vuelidate";
import {required} from 'vuelidate/lib/validators'
import {mapState} from "vuex";
export default {
  name: "ResourcesForm",
  components: {BasePopup},
  mixins: [validationMixin],
  props: {
    resource: {
      type: Object,
      required: true
    },
    createResource: {
      type: Function,
      default: () => {}
    },
    updateResource: {
      type: Function,
      default: () => {}
    },
    popupName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      payload: {
        name: null,
        master_id: null,
        transport_id: null,
        department_id: null,
        structural_unit_id: null
      }
    }
  },
  validations() {
    const validations = {
      payload: {
        name: {required},
        department_id: {required}
      }
    }

    if (Object.keys(this.resource).length) return validations

    validations.payload.structural_unit_id = {required};
    return validations;
  },
  computed: {
    popupTitle() {
      return !Object.keys(this.resource).length ? 'Создать ресурс' : 'Редактирование ресурса'
    },
    ...mapState({
      staffList: state => state.user.structuralUnitUsersList,
      vehiclesList: state => state.autos.autos,
      departmentsList: state => state.departments.departments,
      structuralUnitsList: state => state.structuralUnits.userStructuralUnitsList
    })
  },
  mounted() {
    if (Object.keys(this.resource).length) {
      const {name, master, transport, department} = this.resource;
      this.payload.name = name;
      this.payload.master_id = master?.id;
      this.payload.transport_id = transport?.id;
      this.payload.department_id = department.id;
    }
  },
  methods: {
    checkFormType() {
      this.$v.$touch()
      if (this.$v.$invalid) return false

      if (!Object.keys(this.resource).length) this.createResource(this.payload)
      else this.updateResource(this.resource.id, this.payload)
    }
  }
}
</script>

<style scoped>

</style>