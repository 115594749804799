<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="20"
       height="20"
       viewBox="0 0 20 20"
       fill="none">
    <path d="M10 6.4V10.9M10 19C14.95 19 19 14.95 19 10C19 5.05 14.95 1 10 1C5.05 1 1 5.05 1 10C1 14.95 5.05 19 10 19Z"
          stroke="#9192AD"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M9.99609 13.6016H10.0033"
          stroke="#9192AD"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconInfoCircle"
}
</script>

<style scoped>

</style>
