import LayerService from "@/services/LayerService";

export const namespaced = true

export const state = {
    layers: [],
    selectedLayerId: null,
    zoom: 13,
    center: [],
    loading: false,
    error: null
}

export const mutations = {
    SET_LAYERS(state, layers) {
        state.layers = layers;
    },
    SET_SELECTED_LAYER_ID(state, layerId) {
        state.selectedLayerId = layerId;
    },
    SET_CENTER_ZOOM(state, payload) {
        state.center = payload.center;
        state.zoom = payload.zoom;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getLayers({commit}) {
        commit('SET_LOADING', true);
        LayerService.getLayers()
            .then(layers => commit('SET_LAYERS', layers))
            .catch(error => commit('SET_ERROR', error))
            .finally(() => commit('SET_LOADING', false));
    },
    getLayersAuto({commit}, auto) {
        commit("SET_CENTER_ZOOM", auto)
    }
}

export const getters = {
    getCenter: state => {
        return state.center
    },
    getZoom: state => {
        return state.zoom
    }
}
