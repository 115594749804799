<template>
  <base-popup
    title="Добавить подписку"
    :name="popupName"
  >
    <template #body>
      <div class="form-item">
        <div class="form-item__name">Выберите подписку</div>
        <div class="form-item__value">
          <v-select
            v-model="type"
            :options="subscriptionTypes"
            :reduce="type => type.id"
            :get-option-label="(option) => option.name"
            class="vue-select-input"
            placeholder="Выберите из списка"
          />
        </div>
      </div>
    </template>

    <template #footer>
      <div @click="submitForm()"
           class="button">
        Добавить
      </div>
    </template>
  </base-popup>
</template>

<script>
import {mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";
import BasePopup from "@/components/BasePopup.vue";

export default {
  name: "UserSubscriptionsForm",
  components: {BasePopup},
  props: {
    popupName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      type: null
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      subscriptionTypes: state => state.userSubscriptions.subscriptionTypes
    })
  },
  methods: {
    submitForm() {
      this.$store.dispatch('userSubscriptions/createSubscription', {
        user_id: this.user.id,
        type: this.type
      })
          .then(() => {
            this.$store.dispatch('user/getUserSubscriptions', this.user.id);
            this.$modal.hide(this.popupName);
            showToast('Подписка активирована', 'success');
          })
          .catch(error => showAlert('Ошибка', error));
    }
  }
}
</script>

<style scoped>

</style>