import TransportOrdersService from "@/services/TransportOrdersService";
import {getError} from "@/utils/helpers";

export const namespaced = true;

export const state = {
    transportOrdersList: [],
    transportOrders: [],
    transportOrder: null,
    listFilterParams: {
        transport_type: null,
        state: null
    },
    filterParams: {
        task_id: null
    },
    searchQuery: '',
    error: null,
    loading: false
}

export const mutations = {
    SET_TRANSPORT_ORDERS_LIST(state, transportOrders) {
        state.transportOrdersList = transportOrders;
    },
    SET_TRANSPORT_ORDERS(state, transportOrders) {
        state.transportOrders = transportOrders;
    },
    SET_TRANSPORT_ORDER(state, transportOrder) {
        state.transportOrder = transportOrder;
    },
    SET_LIST_FILTER_PARAMS(state, {prop, value}) {
        state.listFilterParams[prop] = value;
    },
    SET_FILTER_PARAM(state, {prop, value}) {
        state.filterParams[prop] = value;
    },
    SET_SEARCH_QUERY(state, query) {
        state.searchQuery = query;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    }
}

export const actions = {
    getTransportOrdersList({commit, state}) {
        commit("SET_LOADING", true);
        TransportOrdersService.fetchTransportOrders(state.listFilterParams)
            .then(transportOrders => commit("SET_TRANSPORT_ORDERS_LIST", transportOrders))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getTransportOrders({commit, state}) {
        commit("SET_LOADING", true);
        TransportOrdersService.fetchTransportOrders(state.filterParams)
            .then(transportOrders => commit("SET_TRANSPORT_ORDERS", transportOrders))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getTransportOrder({commit}, transportOrderId) {
        return new Promise((resolve, reject) => {
            TransportOrdersService.fetchTransportOrder(transportOrderId)
                .then(transportOrder => {
                    commit("SET_TRANSPORT_ORDER", transportOrder);
                    resolve();
                })
                .catch(error => reject(error))
        })
    },
    createTransportOrder(context, payload) {
        return new Promise((resolve, reject) => {
            TransportOrdersService.createTransportOrder(payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    acceptTransportOrder(context, {transportOrderId, payload}) {
        return new Promise((resolve, reject) => {
            TransportOrdersService.acceptTransportOrder(transportOrderId, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    cancelTransportOrder(context, transportOrderId) {
        return new Promise((resolve, reject) => {
            TransportOrdersService.cancelTransportOrder(transportOrderId)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateTransportOrder(context, {transportOrderId, payload}) {
        return new Promise((resolve, reject) => {
            TransportOrdersService.updateTransportOrder(transportOrderId, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    }
}

export const getters = {
    searchTransportOrdersByQuery: state => transportOrders => {
        if (state.searchQuery.length) {
            return transportOrders
                .concat()
                .filter(transportOrder => transportOrder.transport?.gov_number.toLocaleLowerCase()
                    .includes(state.searchQuery.toLocaleLowerCase()))
        } else return transportOrders
    },
    sortTransportOrdersByPlannedStartDate: () => transportOrders => {
        return transportOrders
            .concat()
            .sort((a, b) => new Date(a.plannedStartDate) - new Date(b.plannedStartDate))
    },
    getFilteredAndSortedTransportOrders: (state, getters) => {
        return getters.sortTransportOrdersByPlannedStartDate(
            getters.searchTransportOrdersByQuery(
                state.transportOrdersList
            ))
    }
}
