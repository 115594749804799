<template>
  <div class="i-modal__content-wrapper">
    <div class="i-modal__content">
      <div v-if="Object.keys(appeal).length && !appealLoading"
           class="i-modal__inner">
        <div :class="{'base-modal__left-side--hidden': isHistoryVisible}"
             class="base-modal__left-side">
          <div class="base-modal-header">
            <span class="base-modal-title">Обращение #{{ appeal.id }}</span>
            <div class="base-modal-header__status">
              <div class="application-status-wrapper">
                <div class="application-status"
                     :class="[
                       {'base-table__tag--new': appeal.state === AppealState.NEW},
                       {'base-table__tag--work': appeal.state === AppealState.IN_WORK},
                       {'base-table__tag--done': appeal.state === AppealState.CLOSED},
                       {'base-table__tag--canceled': appeal.state === AppealState.CANCELED}
                     ]">
                  {{ appeal.getStateLabel() }}
                </div>
              </div>
              <div @click="$store.commit('baseSideModal/TOGGLE_MODAL_HORIZONTAL')"
                   class="base-modal-header__history-toggle"
                   :class="{'active': isHistoryVisible}">
                <icon-history-toggle/>
              </div>
            </div>
          </div>
          <div class="base-modal-info">
            <div
              v-if="appeal.stateIsNew"
              @click="updateAppeal()"
              class="appeal-edit-button">
              <icon-pencil/>
              <span>Редактировать</span>
            </div>
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-tag-cross/>
                <span>Структ. подразделение</span>
              </div>
              <div class="base-modal-info__value">
                <p
                  @click="showStructuralUnitModal()"
                  class="base-modal-info__value-txt"
                  :class="{'base-modal-info__value-text--pointer': appeal.stateIsNew}">
                  {{ appeal.structuralUnit.name }}
                </p>
              </div>
            </div>
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-message/>
                <span>От куда поступило</span>
              </div>
              <div class="base-modal-info__value">
                <p class="base-modal-info__value-txt">{{ appeal.getSourceLabel() }}</p>
              </div>
            </div>
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-profile/>
                <span>Кто обратился</span>
              </div>
              <div class="base-modal-info__value">
                <p class="base-modal-info__value-txt">{{ appeal.applicant.name }}</p>
                <p class="base-modal-info__value-txt">{{ appeal.applicant.phone }}</p>
                <p class="base-modal-info__value-txt">{{ appeal.getApplicantTypeLabel() }}</p>
              </div>
            </div>
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-link/>
                <span>Связанная заявка</span>
              </div>
              <div class="base-modal-info__value">
                <p v-if="appeal.issue_id"
                   @click="openIssueModal(appeal.issue_id)"
                   class="base-modal-info__value-txt base-modal-info__value--link">
                  #{{ appeal.issue_id }}</p>
                <p v-else>—</p>
              </div>
            </div>
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-info-circle/>
                <span>Проблема</span>
              </div>
              <div class="base-modal-info__value">
                <p
                  v-if="appeal.category"
                  class="base-modal-info__value-text">{{ appeal.category.group_name }}</p>
                <p
                  v-if="appeal.category"
                  class="base-modal-info__value-text">{{ appeal.category.name }}</p>
                <p class="base-modal-info__value-message">{{ appeal.message }}</p>
              </div>
            </div>
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <icon-pin/>
                <span>Адрес</span>
              </div>
              <div class="base-modal-info__value">
                <p class="base-modal-info__value-text">{{ appeal.getFullAddress() }}</p>
              </div>
            </div>
            <div class="base-modal-info__item">
              <appeal-modal-map :appeal-coordinates="appeal.coordinates"/>
            </div>

            <div
              v-if="appeal.attachmentsList.length"
              class="base-modal-info__item base-modal-info__item--border-bottom">
              <div class="base-modal-info__name">
                <span>Фото и видео</span>
              </div>
              <div class="base-modal-info__value">
                <files-list :files-list="appeal.attachmentsList" />
              </div>
            </div>

            <div
              class="base-modal-info__item"
              :class="{'base-modal-info__item--border': !appeal.attachmentsList.length}">
              <div class="base-modal-info__name">
                <icon-clock/>
                <span>Создал</span>
              </div>
              <div class="base-modal-info__value">
                <p v-if="firstHistoryItem"
                   class="base-modal-info__value-txt">
                  {{ firstHistoryItem.userName }}
                  <span class="base-modal-additional">
                    {{ formatDateTime(firstHistoryItem.historyDate) }}
                  </span>
                </p>
              </div>
            </div>

            <div v-if="appeal.state === AppealState.NEW"
                 class="base-modal-btn-wp">
              <appeal-actions
                :appeal-id="appeal.id"
                @create-issue="$emit('create-issue', appeal.id)"/>
            </div>

          </div>

        </div>
        <div v-if="isHistoryVisible"
             :class="{'base-modal__right-side--visible': isHistoryVisible}"
             class="base-modal__right-side">
          <history-list :history-list="appealHistory"/>
        </div>
      </div>
      <base-side-modal-skeleton v-if="appealLoading" />
    </div>
    <div v-if="Object.keys(appeal).length"
         class="i-modal-btn-wp i-modal-btn-wp--back">
      <div v-if="isHistoryVisible"
           @click="$store.commit('baseSideModal/TOGGLE_MODAL_HORIZONTAL')"
           class="button button--mode-outline task-modal__history-hide">
        <icon-arrow-down/>
        Вернуться назад
      </div>
      <div v-else
           class="task-modal__mobile-actions">
        <button @click="$store.dispatch('baseSideModal/closeModal')"
                class="button button--mode-outline task-modal__mobile-back">
          <icon-arrow-left/>
        </button>
        <appeal-actions
          :appeal-id="appeal.id"
          @create-issue="$emit('create-issue', appeal.id)"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import AppealState from "@/models/callCenter/AppealState";
import IconHistoryToggle from "@/components/Icons/IconHistoryToggle";
import IconProfile from "@/components/Icons/IconProfile";
import IconLink from "@/components/Icons/IconLink";
import IconPin from "@/components/Icons/IconPin";
import IconClock from "@/components/Icons/IconClock";
import AppealActions from "@/components/CallCenter/AppealActions";
import IconArrowLeft from "@/components/Icons/IconArrowLeft";
import IconArrowDown from "@/components/Icons/IconArrowDown";
import HistoryList from "@/components/ServiceDesk/Tasks/TaskComponents/History/HistoryList";
import IconInfoCircle from "@/components/Icons/IconInfoCircle";
import AppealModalMap from "@/components/CallCenter/AppealModalMap.vue";
import BaseSideModalSkeleton from "@/components/BaseSideModalSkeleton.vue";
import sideModalControls from "@/mixins/sideModalControls";
import IconMessage from "@/components/Icons/IconMessage.vue";
import FilesList from "@/components/ServiceDesk/CommonComponents/FilesList/FilesList.vue";
import IconTagCross from "@/components/Icons/IconTagCross.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import ChangeStructuralUnitForm
  from "@/components/ServiceDesk/CommonComponents/ChangeStructuralUnit/ChangeStructuralUnitForm.vue";
import {showAlert, showToast} from "@/utils/notification";
import IconPencil from "@/components/Icons/IconPencil.vue";
import {formatDateTime} from "@/utils/filters";

export default {
  name: "AppealModalContent",
  components: {
    IconPencil,
    IconTagCross,
    FilesList,
    IconMessage,
    BaseSideModalSkeleton,
    AppealModalMap,
    IconInfoCircle,
    HistoryList,
    IconArrowDown,
    IconArrowLeft,
    AppealActions,
    IconClock,
    IconPin,
    IconLink,
    IconProfile,
    IconHistoryToggle
  },
  mixins: [sideModalControls],
  computed: {
    ...mapState({
      appeal: state => state.appealModal.appeal,
      appealHistory: state => state.appealModal.appealHistory,
      appealLoading: state => state.appealModal.loading,
      isHistoryVisible: state => state.baseSideModal.isModalHorizontal,
      structuralUnitsList: state => state.structuralUnits.structuralUnits
    }),
    ...mapGetters({
      firstHistoryItem: 'appealModal/getHistoryFirstItem'
    }),
    AppealState: () => AppealState
  },
  mounted() {
    this.$store.dispatch('structuralUnits/getStructuralUnitsList')
  },
  methods: {
    formatDateTime,
    showStructuralUnitModal() {
      if (this.appeal.stateIsNew) {
        this.$modal.show(
          ChangeStructuralUnitForm,
          {
            'structuralUnitsList': this.structuralUnitsList,
            'changeStructuralUnit': this.changeAppealStructuralUnit
          },
          {
            name: 'change-structural-unit-modal',
            classes: 'base-popup',
            height: 'auto',
            width: modalAdaptiveWidth(460)
          }
        )
      }
    },
    changeAppealStructuralUnit(payload) {
      this.$store.dispatch('appeals/changeAppealStructuralUnit', {
        appealId: this.appeal.id,
        payload
      })
        .then(() => {
          this.$store.dispatch('appealModal/getAppeal', this.appeal.id);
          this.$modal.hide('change-structural-unit-modal');
          showToast('Структурное подразделение изменено', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    updateAppeal() {
      this.$store.dispatch('baseSideModal/openModal', {
        modalContentType: 'createAppeal',
        propsData: this.appeal
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.appeal-edit-button {
  position: absolute;
  right: 100px;
  top: 20px;

  display: flex;
  align-items: center;
  gap: 8px;

  cursor: pointer;

  & span {
    @include font-style(400, 14px, 16px, var(--text-secondary));
  }
}
</style>
