<template>
  <form class="issues-count-report-form">
    <div class="issues-count-report-form__left">
      <v-select
        v-model="reportParams.group"
        :options="groups"
        :reduce="report => report.key"
        :get-option-label="(option) => option.name"
        class="vue-select-input"
        placeholder="Выберите группировку"
        @input="getReportData"/>
      <date-picker
        class="base-date-picker"
        placeholder="Выберите дату"
        v-model="reportPeriod"
        :default-value="defaultValue"
        :format="reportGroup === 1 ? 'DD.MM.YYYY':'MMMM YYYY'"
        :type="reportGroup === 1 ? 'day':'month'"
        range
        value-type="YYYY-MM-DD"
        @input="getReportData"/>
    </div>
    <button @click.prevent="getExcel(tableRows)"
            :disabled="!reportDataLoaded"
            class="button button--mode-outline">
      <icon-export/>
      <span>Экспорт</span>
    </button>
  </form>
</template>

<script>
import DatePicker from "vue2-datepicker";
import {mapState} from "vuex";
import IconExport from "@/components/Icons/IconExport.vue";
import {getExcel} from "@/utils/table";

export default {
  name: "IssuesCountReportForm",
  components: {IconExport, DatePicker},
  props: {
    tableRows: {
      required: true
    }
  },
  data() {
    return {
      defaultValue: this.$moment().format('YYYY-MM-DD'),
      groups: [
        {key: 1, name: 'Дни'},
        {key: 2, name: 'Месяцы'}
      ]
    }
  },
  computed: {
    ...mapState({
      reportParams: state => state.reportsData.reportParams,
      reportData: state => state.reportsData.reportData,
      loading: state => state.reportsData.loading
    }),
    reportDataLoaded() {
      return !!this.reportData?.length && !this.loading;
    },
    reportGroup: {
      get() {
        return this.reportParams.group
      },
      set(value) {
        this.$store.commit('reportsData/SET_REPORT_PARAM', {
          key: 'group',
          value: value
        })
      }
    },
    reportPeriod: {
      get() {
        return [this.reportParams.period_start, this.reportParams.period_end]
      },
      set(value) {
        const [periodStart, periodEnd] = value;
        this.$store.commit('reportsData/SET_REPORT_PARAM', {
          key: 'period_start',
          value: periodStart
        });
        this.$store.commit('reportsData/SET_REPORT_PARAM', {
          key: 'period_end',
          value: periodEnd
        })
      }
    }
  },
  mounted() {
    const reportParams = {group: 1, period_start: this.defaultValue, period_end: this.defaultValue};
    this.$store.commit('reportsData/SET_REPORT_PARAMS', reportParams);
    this.getReportData();
  },
  methods: {
    getExcel,
    getReportData() {
      this.$store.dispatch('reportsData/getIssuesCountReportData', this.reportParams);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/viriables";
.issues-count-report-form {
  display: grid;
  grid-template-columns: 660px 144px;
  gap: 20px;
  width: 100%;
  margin-bottom: 32px;
  justify-content: space-between;

  &__left {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    @media (max-width: $tablet) {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: $tablet) {
    grid-template-columns: 1fr;
  }
}
</style>
