<template>
  <div
    class="files-list__item-video">
    <video :id="`videoElement_${index}`">
      <source :src="url">
    </video>
    <canvas :id="`canvasElement_${index}`"/>

    <div class="files-list__item-video-review">
      <div
        v-if="index !== maxIndex"
        class="files-list__item-video-play">
        <icon-video-play />
      </div>
      <img
        :id="`previewImage_${index}`"
        alt="preview"
        src="">
    </div>
  </div>
</template>

<script>
import IconVideoPlay from "@/components/Icons/IconVideoPlay.vue";

export default {
  name: "VideoPreview",
  components: {IconVideoPlay},
  props: {
    index: {
      type: Number,
      required: true
    },
    maxIndex: {
      type: Number,
      require: true
    },
    url: {
      type: String,
      required: true
    }
  },
  mounted() {
    const video = document.getElementById(`videoElement_${this.index}`);
    const canvas = document.getElementById(`canvasElement_${this.index}`);
    const context = canvas.getContext('2d');
    const previewImage = document.getElementById(`previewImage_${this.index}`);

    video.addEventListener('loadeddata', function() {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      video.currentTime = 1;
    })

    video.addEventListener('seeked', function() {
      context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

      previewImage.src = canvas.toDataURL('image/jpeg');
    });
  }
}
</script>

<style scoped>

</style>