import TechnicalServiceWorksService from "@/services/TechnicalServiceWorksService";

export const namespaced = true;

export const state = {
    technicalServiceWorksList: [],
    loading: false,
    error: null
}

export const mutations = {
    SET_TECHNICAL_SERVICE_WORKS_LIST(state, worksList) {
        state.technicalServiceWorksList = worksList;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getTechnicalServiceWorks({commit}) {
        commit("SET_LOADING", true);
        TechnicalServiceWorksService.fetchTechnicalServiceWorksList()
            .then(technicalServiceWorks => commit("SET_TECHNICAL_SERVICE_WORKS_LIST", technicalServiceWorks))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false))
    },
    createTechnicalServiceWork(context, payload) {
        return new Promise((resolve, reject) => {
            TechnicalServiceWorksService.createTechnicalServiceWork(payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    updateTechnicalServiceWork(context, {id, payload}) {
        return new Promise((resolve, reject) => {
            TechnicalServiceWorksService.updateTechnicalServiceWork(id, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    deleteTechnicalServiceWork(context, id) {
        return new Promise((resolve, reject) => {
            TechnicalServiceWorksService.deleteTechnicalServiceWork(id)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    }
}
