<template>
  <div class="i-modal__content-wrapper">
    <div class="i-modal__content">
      <div v-if="Object.keys(equipment).length"
           class="i-modal__inner">
        <div class="base-modal__left-side base-modal__left-side--hidden">
          <div class="base-modal-header">
            <span class="base-modal-title">
              {{equipment.name}}
            </span>
          </div>
          <div class="base-modal-info">
            <div class="i-modal-data__item">
              <div class="equipment-modal__data">
                <div class="equipment-modal__data-column">
                  <div class="equipment-modal__data-row">
                    <div class="equipment-modal__data-row-title">
                      Срок эксплуатации
                    </div>
                    <div class="equipment-modal__data-row-value">
                      {{equipment.durability}}
                    </div>
                  </div>
                  <div class="equipment-modal__data-row">
                    <div class="equipment-modal__data-row-title">
                      Дата изготовления
                    </div>
                    <div class="equipment-modal__data-row-value">
                      {{formatDate(equipment.buildDate)}}
                    </div>
                  </div>
                  <div class="equipment-modal__data-row">
                    <div class="equipment-modal__data-row-title">
                      Производитель
                    </div>
                    <div class="equipment-modal__data-row-value">
                      -
                    </div>
                  </div>
                </div>
                <div class="equipment-modal__data-column">
                  <div class="equipment-modal__data-row">
                    <div class="equipment-modal__data-row-title">
                      Серийный номер
                    </div>
                    <div class="equipment-modal__data-row-value">
                      {{equipment.serial}}
                    </div>
                  </div>
                  <div class="equipment-modal__data-row">
                    <div class="equipment-modal__data-row-title">
                      Дата установки
                    </div>
                    <div class="equipment-modal__data-row-value">
                      {{formatDate(equipment.installationDate)}}
                    </div>
                  </div>
                  <div class="equipment-modal__data-row">
                    <div class="equipment-modal__data-row-title">
                      Тип оборудования
                    </div>
                    <div class="equipment-modal__data-row-value">
                      {{equipment.typeName}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="equipment-modal__object">
                <div class="equipment-modal__object-title">Сетевой объект</div>
                <div class="equipment-modal__object-value">{{networkObject(equipment.networkObjectId).name}}</div>
              </div>
            </div>

            <div class="equipment-modal-parameters">
              <div class="equipment-modal-parameters__header">Показатели оборудования</div>
              <table v-if="!archiveLoading && archiveValues.length"
                     class="equipment-modal-table">
                <div class="equipment-modal-table__header">
                  <div class="equipment-modal-table__title">Дата, время</div>
                  <div v-for="parameterName in parameterNames"
                       :key="`${parameterName}_header`"
                       class="equipment-modal-table__title">
                    {{parameterName}}
                  </div>
                </div>
                <div class="equipment-modal-table__body">
                  <div v-for="dateLabel in archiveDateLabels"
                       :key="dateLabel"
                       class="equipment-modal-table__row">
                    <div class="equipment-modal-table__cell">{{formatDateTime(dateLabel)}}</div>
                    <div v-for="parameterName in parameterNames"
                         :key="`${parameterName}_body`"
                         class="equipment-modal-table__cell">
                      <div class="scada-table__cell-inner">
                        <div class="scada-table__cell-content"
                             :class="{
                               'danger': getParameterDangerStatus(getArchiveParameter(parameterName, dateLabel))
                             }">
                          <div class="scada-table__cell-text">
                            {{ nullFilter(getArchiveParameterValueByNameAndLabel(parameterName, dateLabel)) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </table>
              <div v-if="!archiveLoading && !archiveValues.length"
                   class="equipment-modal-parameters__no-result">
                Архивные значения не найдены
              </div>
              <div v-if="archiveLoading">
                <base-content-loaders-placeholder />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div v-if="Object.keys(equipment).length"
         class="i-modal-btn-wp i-modal-btn-wp--back">
      <div class="task-modal__mobile-actions">
        <button @click="$store.dispatch('baseSideModal/closeModal')"
                class="button button--mode-outline task-modal__mobile-back">
          <icon-arrow-left/>
        </button>
      </div>

    </div>
  </div>
</template>

<script>
import IconArrowLeft from "@/components/Icons/IconArrowLeft.vue";
import {mapGetters, mapState} from "vuex";
import BaseContentLoadersPlaceholder from "@/components/BaseContentLoadersPlaceholder.vue";
import {formatDate, nullFilter, formatDateTime} from "@/utils/filters";
import moment from "moment/moment";
import ParameterValue from "@/models/telemetry/ParameterValue";
import {isValueOutOfProfile, roundedNumber} from "@/utils/helpers";

export default {
  name: "ScadaNetworkEquipmentModalContent",
  components: {BaseContentLoadersPlaceholder, IconArrowLeft},
  data() {
    return {
      archiveDateLabels: [],
      archiveParameters: []
    }
  },
  computed: {
    ...mapState({
      equipment: state => state.baseSideModal.componentPropsData,
      archiveLoading: state => state.archivePage.loading,
      archiveValues: state => state.archivePage.parametersValues,
      parameterNames: state => state.scadaPage.parameterNames
    }),
    ...mapGetters({
      parameterProfile: 'telemetry/getParameterProfile',
      networkObject: 'telemetry/getNetworkObject'
    }),
    ParameterValue: () => ParameterValue
  },
  watch: {
    equipment: function (id, oldId) {
      if (id !== oldId) this.getArchive();
    }
  },
  mounted() {
    this.getArchive();
  },
  methods: {
    roundedNumber,
    formatDate,
    nullFilter,
    formatDateTime,
    getArchive() {
      const now = new Date();
      const params = {
        start_period: moment(now).subtract(23, 'hours').format('DD.MM.YYYY, HH:00'),
        end_period: moment(now).format('DD.MM.YYYY, HH:00')
      }

      this.$store.dispatch('archivePage/getNetworkEquipmentArchive', {
        networkEquipmentId: this.equipment.id,
        params: params,
        routeGroup: 'hour'
      })
        .then(archiveParameterValues => {
          this.archiveDateLabels = Array.from(new Set(archiveParameterValues.map(item => item.label)));
          this.archiveParameters = archiveParameterValues.map(parameterValue => {
            const parameter = this.equipment.parameters.find(parameter => parameter.id === parameterValue.parameterId);
            return {
              ...parameter,
              parameterValue: parameterValue
            }
          })
        })
    },
    getArchiveParameterValueByNameAndLabel(name, label) {
      const archiveParameter = this.getArchiveParameter(name, label);
      return archiveParameter ?
        parseFloat(roundedNumber(archiveParameter.parameterValue.value, archiveParameter.precision)) : null
    },
    getArchiveParameter(name, label) {
      return this.archiveParameters
        .find(parameter => parameter.shortName === name && parameter.parameterValue.label === label);
    },
    getParameterDangerStatus(parameter) {
      if (parameter?.parameterValue) {
        return isValueOutOfProfile(
          parameter?.parameterValue.value,
          this.parameterProfile(parameter.id)
        )
      }
    }
  }
}
</script>

<style scoped>
.equipment-modal-table__cell {
  color: var(--text-primary);
}
</style>