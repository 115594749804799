<template>
  <div class="damages-map">
    <base-map
      :center="issue.coordinates"
      :zoom="16"
      :base-layers="baseLayers"
      :layers="layers"
      :set_marker_by_click="true"
      :circles="setCircles"
      :polylines="setPolylines"
      :polygons="setPolygons"
      :need-resize="invalidateMapSize"
      @baseMap::change-layer="layerId => $store.commit('baseMap/SET_SELECTED_LAYER_ID', layerId)"
      id-key="emergency-elements"
    />
  </div>
</template>

<script>
import BaseMap from "@/components/BaseMap.vue";
import {mapState} from "vuex";
import Circle from "@/models/baseMap/Circle";
import Polyline from "@/models/baseMap/Polyline";
import Polygon from "@/models/baseMap/Polygon";
export default {
  name: "EmergencyElementsMap",
  components: {BaseMap},
  props: {
    invalidateMapSize: {
      type: Boolean,
      required: true
    },
    elements: {
      type: Array
    }
  },
  computed: {
    ...mapState({
      center: state => state.baseMap.center,
      zoom: state => state.baseMap.zoom,
      layers: state => state.baseMap.layers,
      baseLayers: state => state.baseLayers.baseLayers,
      issue: state => state.issueModal.issue
    }),
    setCircles: function () {
      return this.elements
        .filter(item => item.geometryType === 'Point')
        .map(item => {
          const color = item.isSelected ? '#000' : '#468FC5'
          return new Circle({
            coords: item.coordinates,
            radius: 3,
            color: color
          })
        })
    },
    setPolylines: function () {
      return this.elements
        .filter(item => item.geometryType === 'LineString' || item.geometryType === 'MultiLineString')
        .map(item => {
          const color = item.isSelected ? '#000' : '#468FC5'
          return new Polyline({
            coords: item.coordinates,
            color: color
          })
        })
    },
    setPolygons: function () {
      return this.elements
        .filter(item => item.geometryType === 'Polygon' || item.geometryType === 'MultiPolygon')
        .map(item => {
          const color = item.isSelected ? '#000' : '#468FC5'
          return new Polygon({
            coords: item.coordinates,
            color: color,
            fill: color
          })
        })
    }
  }
}
</script>

<style scoped>
.damages-map {
  height: 100%;
}
</style>
