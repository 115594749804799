<script>
export default {
  name: "IconQuestions"
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none">
    <path
      d="M17.5 10C17.5 14.14 14.14 17.5 10 17.5C5.86 17.5 2.5 14.14 2.5 10C2.5 5.86 5.86 2.5 10 2.5C14.14 2.5
      17.5 5.86 17.5 10Z"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M9.99977 11.1328V10.9578C9.99977 10.3912 10.3498 10.0912 10.6998 9.8495C11.0415 9.61616 11.3831 9.31617
       11.3831 8.76617C11.3831 7.99951 10.7664 7.38281 9.99977 7.38281C9.2331 7.38281 8.61646 7.99951 8.61646 8.76617"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M9.99617 13.125H10.0037"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>

<style scoped>

</style>