import * as API from './API';
import Device from "@/models/telemetry/Device";
import {getError} from "@/utils/helpers";
import DeviceTelemetry from "@/models/telemetry/DeviceTelemetry";

export default {
    getDevices(filterParams) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/object-monitoring/devices`, {params: filterParams})
                .then(response => resolve(response.data.data.map(item =>
                    new Device({id: item.id, ...item.attributes}))))
                .catch(error => reject(getError(error)))
        })
    },
    getDevice(deviceId){
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/object-monitoring/devices/${deviceId}`)
                .then(response => {
                    const {id, attributes} = response.data.data
                    resolve(new Device({id: id, ...attributes}))
                })
                .catch(error => reject(getError(error)));
        })
    },
    createDevice(payload){
        return API.apiClient.post(`/object-monitoring/devices`, payload);
    },
    updateDevice(deviceId, payload){
        return API.apiClient.patch(`/object-monitoring/devices/${deviceId}`, payload);
    },
    deleteDevice(deviceId){
        return API.apiClient.delete(`/object-monitoring/devices/${deviceId}`);
    },
    getDevicesWithSensors(filter) {
        return new Promise((resolve, reject) => {
            API.apiClient.get('/object-monitoring/devices', {params: filter})
                .then(response => resolve(response.data.data.map(item => new DeviceTelemetry(
                    {id: item.id, ...item.attributes},
                    !JSON.parse(localStorage.getItem('hiddenInList')).includes(item.id),
                    !JSON.parse(localStorage.getItem('hiddenOnMap')).includes(item.id),
                    JSON.parse(localStorage.getItem('fixedOnMap')).includes(item.id)
                ))))
                .catch(error => reject(getError(error)));
        })
    }
}