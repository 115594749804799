<script>
export default {
  name: "IconSource"
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="22"
       height="24"
       viewBox="0 0 22 24"
       fill="none">
    <path
      d="M18.0286 17.834C17.3652 17.8343 16.7082 17.7039 16.0952 17.4503C15.4822 17.1966 14.9251 16.8247 14.4559
      16.3557C13.9867 15.8867 13.6144 15.3299 13.3605 14.717C13.1065 14.1041 12.9758 13.4472 12.9758 12.7838C12.9758
      11.4706 14.0969 9.18169 15.2829 7.12891C13.116 3.34776 10.7621 0 10.7621 0C10.7621 0 8.40574 3.34776 6.22839
      7.12891C7.41438 9.18169 8.53549 11.4706 8.53549 12.7838C8.5355 14.1214 8.00485 15.4043 7.06 16.3511C6.11515
      17.2979 4.83328 17.8312 3.49569 17.834C3.29963 17.8337 3.10377 17.8216 2.90918 17.7976C3.32954 19.5639 4.33317
      21.1369 5.7579 22.2623C7.18263 23.3878 8.94522 24 10.7608 24C12.5765 24 14.3391 23.3878 15.7638 22.2623C17.1885
      21.1369 18.1922 19.5639 18.6125 17.7976C18.4188 17.8215 18.2238 17.8337 18.0286 17.834Z"
      fill="#8ED1EF"/>
    <path
      d="M18.0286 17.834C17.3652 17.8343 16.7082 17.7039 16.0952 17.4503C15.4822 17.1966 14.9251 16.8247 14.4559
      16.3557C13.9867 15.8867 13.6144 15.3299 13.3605 14.717C13.1065 14.1041 12.9758 13.4472 12.9758 12.7838C12.9758
      11.4706 14.0969 9.18169 15.2829 7.12891C13.116 3.34776 10.7621 0 10.7621 0C10.7621 0 8.40574 3.34776 6.22839
      7.12891C7.41438 9.18169 8.53549 11.4706 8.53549 12.7838C8.5355 14.1214 8.00485 15.4043 7.06 16.3511C6.11515
      17.2979 4.83328 17.8312 3.49569 17.834C3.29963 17.8337 3.10377 17.8216 2.90918 17.7976C3.32954 19.5639 4.33317
      21.1369 5.7579 22.2623C7.18263 23.3878 8.94522 24 10.7608 24C12.5765 24 14.3391 23.3878 15.7638 22.2623C17.1885
      21.1369 18.1922 19.5639 18.6125 17.7976C18.4188 17.8215 18.2238 17.8337 18.0286 17.834Z"
      fill="#A4D9F6"/>
    <path
      d="M10.7621 22.9431C9.14121 22.9426 7.55797 22.4541 6.21846 21.5414C4.87895 20.6286 3.8452 19.3338 3.25174
      17.8254C3.13755 17.8254 3.02337 17.8099 2.90918 17.7969C3.32954 19.5632 4.33317 21.1361 5.7579 22.2616C7.18263
      23.387 8.94522 23.9992 10.7608 23.9992C12.5765 23.9992 14.3391 23.387 15.7638 22.2616C17.1885 21.1361 18.1922
      19.5632 18.6125 17.7969C18.4983 17.7969 18.3841 17.8202 18.27 17.8254C17.6767 19.3334 16.6433 20.6279 15.3043
      21.5407C13.9653 22.4534 12.3826 22.942 10.7621 22.9431Z"
      fill="#262F3D"/>
    <path
      d="M3.49569 5.60742C1.64533 8.49324 0 11.6282 0 12.783C0.0248093 13.6933 0.40385 14.558 1.05647 15.1931C1.70909
      15.8282 2.58377 16.1835 3.49439 16.1835C4.40501 16.1835 5.27969 15.8282 5.93231 15.1931C6.58492 14.558 6.96397
      13.6933 6.98877 12.783C6.98877 11.6282 5.34344 8.49324 3.49569 5.60742Z"
      fill="#468FC5"/>
    <path d="M4.00326 14.6671C1.29842 15.6058 0.397347 13.8029 1.39459 12L4.00326 14.6671Z"
          fill="white"/>
    <path
      d="M18.0289 5.60742C16.1785 8.49324 14.5332 11.6282 14.5332 12.783C14.558 13.6933 14.9371 14.558 15.5897
      15.1931C16.2423 15.8282 17.117 16.1835 18.0276 16.1835C18.9382 16.1835 19.8129 15.8282 20.4655 15.1931C21.1181
      14.558 21.4972 13.6933 21.522 12.783C21.522 11.6282 19.8766 8.49324 18.0289 5.60742Z"
      fill="#468FC5"/>
  </svg>
</template>

<style scoped>

</style>