<template>
  <form class="water-flow-report-form">
    <div class="water-flow-report-form__left">
      <base-date-range-picker @updateDateRange="setDateRange($event)" />
      <date-picker
        class="base-date-picker"
        placeholder="Выберите период"
        v-model="reportPeriod"
        :default-value="defaultValue"
        format="DD.MM.YYYY"
        type="day"
        range
        value-type="YYYY-MM-DD"
        @input="getReportData"/>
    </div>
    <button @click.prevent="getExcel(tableRows)"
            :disabled="!reportDataLoaded"
            class="button button--mode-outline">
      <icon-export/>
      <span>Экспорт</span>
    </button>
  </form>
</template>

<script>
import DatePicker from "vue2-datepicker";
import {mapState} from "vuex";
import IconExport from "@/components/Icons/IconExport.vue";
import BaseDateRangePicker from "@/components/BaseDateRangePicker.vue";
import {getExcel} from "@/utils/table";

export default {
  name: "WaterFlowReportForm",
  components: {BaseDateRangePicker, IconExport, DatePicker},
  props: {
    tableRows: {
      required: true
    }
  },
  data() {
    return {
      defaultValue: this.$moment().format('YYYY-MM-DD')
    }
  },
  computed: {
    ...mapState({
      reportParams: state => state.reportsData.reportParams,
      reportData: state => state.reportsData.reportData,
      loading: state => state.reportsData.loading
    }),
    isReportData() {
      return !!(this.reportData?.loss_volume?.summary_volume || this.reportData?.own_costs_volume?.summary_volume ||
          this.reportData?.realization_volume?.summary_volume)
    },
    reportDataLoaded() {
      return this.isReportData  && !this.loading;
    },
    reportPeriod: {
      get() {
        const {start_date, end_date} = this.reportParams;
        return [start_date, end_date]
      },
      set(value) {
        const [startDate, endDate] = value;
        this.$store.commit('reportsData/SET_REPORT_PARAM', {
          key: 'start_date',
          value: startDate
        });
        this.$store.commit('reportsData/SET_REPORT_PARAM', {
          key: 'end_date',
          value: endDate
        });
      }
    }
  },
  mounted() {
    const reportParams = {start_date: this.defaultValue, end_date: this.defaultValue};
    this.$store.commit('reportsData/SET_REPORT_PARAMS', reportParams);
    this.getReportData();
  },
  methods: {
    getExcel,
    getReportData() {
      this.$store.dispatch('reportsData/getWaterFlowReportData', this.reportParams);
    },
    setDateRange(range) {
      const reportParams = {start_date: range.start, end_date: range.end};
      this.$store.commit('reportsData/SET_REPORT_PARAMS', reportParams);
      this.getReportData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/viriables";
.water-flow-report-form {
  display: grid;
  grid-template-columns: 771px 144px;
  gap: 20px;
  width: 100%;
  margin-bottom: 32px;
  justify-content: space-between;

  .base-date-range-picker {
    justify-self: flex-start;
  }

  &__left {
    display: grid;
    grid-template-columns: 1fr 320px;
    gap: 20px;
    justify-content: center;

    @media (max-width: $tablet) {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: $tablet) {
    grid-template-columns: 1fr;
  }
}
</style>
