<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="24"
       height="24"
       viewBox="0 0 24 24"
       fill="none">
    <path d="M8.72603 4.60464V16.4768M15.274 6.99734V19.2165M3 8.0567V16.9425C3 18.6777 4.23288 19.39 5.73059
     18.5316L7.87671 17.3078C8.34247 17.043 9.11872 17.0156 9.60274 17.2622L14.3973 19.664C14.8813 19.9014
     15.6575 19.8832 16.1233 19.6183L20.0776 17.3535C20.5799 17.0613 21 16.3489 21 15.7645V6.87862C21 5.14346
      19.7671 4.43113 18.2694 5.28958L16.1233 6.51332C15.6575 6.77816 14.8813 6.80556 14.3973 6.55898L9.60274
       4.16629C9.11872 3.92884 8.34247 3.94711 7.87671 4.21195L3.92237 6.47679C3.41096 6.76903 3 7.48136 3 8.0567Z"
          stroke="#9192AD"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconMap"
}
</script>

<style scoped>

</style>