<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="20"
       height="18"
       viewBox="0 0 20 18"
       fill="none">
    <path
      d="M2.1429 9.09926C1.40466 8.21569 1.00017 7.10093 1 5.94955C1 3.2318 3.2228 1 5.94955 1H10.4491C13.1669
      1 15.3987 3.2318 15.3987 5.94955C15.3987 8.6673 13.1759 10.8991 10.4491 10.8991H8.19934"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M17.8574 9.09671C18.5956 9.98028 19.0001 11.095 19.0002 12.2464C19.0002 14.9642 16.7774 17.196 14.0507
      17.196H9.55111C6.83336 17.196 4.60156 14.9642 4.60156 12.2464C4.60156 9.52867 6.82436 7.29688 9.55111
      7.29688H11.8009"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconLink"
}
</script>

<style scoped>

</style>
