<template>
  <div class="base-table">
    <div class="base-table__container">
      <div class="base-table__header base-table__categories">
        <div class="base-table__header-cell">ID</div>
        <div class="base-table__header-cell">Наименование</div>
        <div class="base-table__header-cell">Тип транспорта</div>
        <div class="base-table__header-cell">Гос. номер</div>
        <div class="base-table__header-cell">Внешний ID</div>
        <div class="base-table__header-cell">Структурное подразделение</div>
        <div class="base-table__header-cell">Статус</div>
      </div>

      <div
        v-for="vehicle in transportList"
        :key="vehicle.id"
        @click="openVehicleModal(vehicle.id)"
        class="base-table__row base-table__categories">
        <div class="base-table__column">
          <div class="base-table__text">{{ vehicle.id }}</div>
        </div>

        <div class="base-table__column">
          <div class="base-table__text">{{ vehicle.attributes.label }}</div>
        </div>

        <div class="base-table__column">
          <div class="base-table__text">{{ vehicle.getTypeLabel() }}</div>
        </div>

        <div class="base-table__column">
          <div class="base-table__text">{{ vehicle.attributes.gov_number }}</div>
        </div>

        <div class="base-table__column">
          <div class="base-table__text">{{ vehicle.attributes.external_id }}</div>
        </div>

        <div class="base-table__column">
          <div class="base-table__text">
            {{ vehicle.attributes.structural_unit ? vehicle.attributes.structural_unit.name : '' }}
          </div>
        </div>

        <div class="base-table__column">
          <div
            class="i-select i-select--city is-selected"
            :class="{'is-active': isActiveVehicleStatusSelect === vehicle.id}"
            @click.stop="handleActiveVehicleStatusSelect(vehicle.id)"
          >
            <input class="i-select__input"
                   hidden="hidden"
                   type="text"
                   name="executor">
            <div class="i-select__main"
                 tabindex="0"
                 role="combobox"
                 aria-expanded="false">
              <icon-available v-if="vehicle.isActive()"/>
              <icon-broken v-else/>
              <span class="i-select__selected"
                    data-placeholder="Выберите подразделение">
                {{ vehicle.attributes.state.name }}
              </span>
            </div>
            <div class="i-select__arrow">
              <icon-arrow/>
            </div>
            <div class="i-select__drop is-default-hidden">
              <div class="i-select__drop-inner">
                <ul class="i-select__list"
                    role="listbox">
                  <li
                    class="i-select__item"
                    :class="{'is-active': vehicle.isActive()}"
                    data-value="Доступно"
                    role="option"
                    @click="changeVehicleState(vehicle, 1)"
                  >
                    <icon-available/>
                    <div class='list-item'>
                      <span class='list-item__txt'>Доступно</span>
                    </div>
                  </li>
                  <li
                    class="i-select__item"
                    :class="{'is-active': !vehicle.isActive()}"
                    data-value="В ремонте"
                    role="option"
                    @click="changeVehicleState(vehicle, 2)"
                  >
                    <icon-broken/>
                    <div class='list-item'>
                      <span class='list-item__txt'>В ремонте</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconAvailable from "@/components/Icons/IconAvailable.vue";
import IconArrow from "@/components/Icons/IconArrow.vue";
import IconBroken from "@/components/Icons/IconBroken.vue";
import {showAlert, showToast} from "@/utils/notification";

export default {
  name: "TransportList",
  components: {IconBroken, IconArrow, IconAvailable},
  props: {
    transportList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isActiveVehicleStatusSelect: 0
    }
  },
  methods: {
    openVehicleModal(vehicleId) {
      this.$store.dispatch('baseSideModal/openModal', {
        modalContentType: 'transport',
        entityId: vehicleId,
        propsData: {
          isCatalogPage: true
        }
      })
    },
    changeVehicleState(vehicle, stateId) {
      const storePath = vehicle.isActive()
        ? 'autos/sendVehicleToRepair'
        : 'autos/makeVehicleAvailable'

      if (vehicle.attributes.state.id !== stateId) {
        this.$store.dispatch(`${storePath}`, vehicle.id)
          .then(() => {
            showToast('Статус изменён', 'success');
            this.isActiveVehicleStatusSelect = 0;
          })
          .catch(error => showAlert('Ошибка', error))
      }
    },
    handleActiveVehicleStatusSelect(vehicleId) {
      if (this.isActiveVehicleStatusSelect === vehicleId) this.isActiveVehicleStatusSelect = 0
      else this.isActiveVehicleStatusSelect = vehicleId
    }
  }
}
</script>

<style scoped>
.base-table__categories {
  grid-template-columns: 56fr 280fr 226fr 92fr 120fr 226fr 120fr;
}
</style>