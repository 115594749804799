<template>
  <svg width="24"
       height="24"
       viewBox="0 0 24 24"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <g id="Iconsax/Linear/trash">
      <path id="Vector"
            d="M19.4 7.184C16.736 6.92 14.056 6.784 11.384 6.784C9.8 6.784 8.216 6.864 6.632
      7.024L5 7.184M9.4 6.376L9.576 5.328C9.704 4.568 9.8 4 11.152 4H13.248C14.6 4 14.704 4.6 14.824 5.336L15
      6.376M17.68 9.712L17.16 17.768C17.072 19.024 17 20 14.768 20H9.632C7.4 20 7.328 19.024 7.24 17.768L6.72
      9.712M10.864 15.6H13.528M10.2 12.4H14.2"
            stroke=""
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconTrash"
}
</script>
