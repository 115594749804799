<template>
  <div class="issues-calendar-wrapper">
    <div
      @click="openCreateIssueModal()"
      class="button button--mode-primary issues-calendar-wrapper__create-btn">
      <icon-plus/>
      <span>Новая заявка</span>
    </div>
    <base-calendar
      initial-view="dayGridWeek"
      :events="issuesList.map(issue => issue.getCalendarEvent())"
      :show-event-tooltip="false"
      content-height="calc(100vh - 300px)"
      @update-calendar-range="period => $emit('update-calendar-period', period)">
      <template v-slot:eventItem="{eventData}">
        <div
          @click="openIssueModal(eventData.id)"
          class="issue-calendar-event"
          :class="eventData.extendedProps.eventContent.classByState">
          <div class="issue-calendar-event__caption">
            #{{ eventData.id }} {{ eventData.extendedProps.eventContent.categoryName }} |
            {{ eventData.extendedProps.eventContent.categoryGroupName }}
          </div>
          <div class="issue-calendar-event__address">
            {{ eventData.extendedProps.eventContent.address }}
          </div>
        </div>
      </template>
    </base-calendar>
  </div>
</template>

<script>
import BaseCalendar from "@/components/BaseCalendar.vue";
import sideModalControls from "@/mixins/sideModalControls";
import IconPlus from "@/components/Icons/IconPlus.vue";

export default {
  name: "IssuesCalendar",
  components: {IconPlus, BaseCalendar},
  mixins: [sideModalControls],
  props: {
    issuesList: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.issues-calendar-wrapper__create-btn {
  position: absolute;
  right: 315px;
}
</style>