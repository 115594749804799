<template>
  <div class="related-appeals-list">
    <div v-for="appeal in appealsList"
         :key="appeal.id"
         class="related-appeal__item">
      <span @click="openAppealModal(appeal.id)"
            class="related-appeal__caption">
        {{ appeal.getCaption() }}
      </span>
      <span @click="removeAppealFromIssue(appeal.id)"
            class="related-appeal__icon">
        <icon-close/>
      </span>
    </div>
  </div>
</template>

<script>
import IconClose from "@/components/Icons/IconClose";
import {showAlert, showToast} from "@/utils/notification";
import sideModalControls from "@/mixins/sideModalControls";

export default {
  name: "RelatedAppealsList",
  components: {IconClose},
  mixins: [sideModalControls],
  props: {
    appealsList: {
      type: Array,
      required: true
    },
    issueId: {
      type: Number,
      required: true
    }
  },
  methods: {
    removeAppealFromIssue(appeal_id) {
      this.$store.dispatch('appeals/unlinkAppealFromIssue', appeal_id)
        .then(() => {
          showToast('Обращение откреплено', 'success');
          this.$store.dispatch('issueModal/getIssue', this.issueId);
          this.$store.dispatch('appeals/getAppealsByStatus', 1);
        })
        .catch(error => {
          showAlert('Ошибка', error, 'error');
        });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.related-appeals-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.related-appeal {
  &__item {
    display: flex;
    gap: 4px;
  }

  &__caption {
    cursor: pointer;
    @include font-style(400, 16px, 24px, var(--text-link))
  }

  &__icon {
    cursor: pointer;
  }
}
</style>
