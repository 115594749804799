import DefectsService from "@/services/DefectsService";

export const namespaced = true;

export const state = {
    defectsList: [],
    defect: {},
    error: null,
    loading: false,
    defectTypesList: [],
    searchQuery: '',
    defectTypeFilter: null
}

export const mutations = {
    SET_DEFECTS_LIST(state, defectList) {
        state.defectsList = defectList;
    },
    SET_DEFECT(state, defect) {
        state.defect = defect;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_DEFECT_TYPES_LIST(state, defTypesList) {
        state.defectTypesList = defTypesList;
    },
    DELETE_DEFECT(state, defectId) {
        const index = state.defectsList.findIndex(defect => defect.id === defectId);
        state.defectsList.splice(index, 1);
    },
    SET_SEARCH_QUERY(state, searchQuery) {
        state.searchQuery = searchQuery;
    },
    SET_DEFECT_TYPE_FILTER(state, defectTypeId) {
        state.defectTypeFilter = defectTypeId;
    }
}

export const actions = {
    getDefectList({commit}) {
        commit("SET_LOADING", true);
        DefectsService.fetchDefectsList()
            .then(defectsList => commit("SET_DEFECTS_LIST", defectsList))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getDefect({commit}, defectId) {
        DefectsService.fetchDefect(defectId)
            .then(defect => commit("SET_DEFECT", defect))
            .catch(error => commit("SET_ERROR", error))
    },
    createDefect(context, payload) {
        return new Promise((resolve, reject) => {
            DefectsService.createDefect(payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    updateDefect(context, {defectId, payload}) {
        return new Promise((resolve, reject) => {
            DefectsService.updateDefect(defectId, payload)
              .then(() => resolve())
              .catch(error => reject(error))
        })
    },
    deleteDefect(context, defectId) {
        return new Promise((resolve, reject) => {
            DefectsService.deleteDefect(defectId)
              .then(() => resolve())
              .catch(error => reject(error))
        })
    },
    getDefectTypesList({commit}) {
        DefectsService.fetchDefectTypes()
            .then(defectTypesList => commit("SET_DEFECT_TYPES_LIST", defectTypesList))
            .catch(error => commit("SET_ERROR", error))
    }
}

export const getters = {
    getDefectType: state => defectId => {
        const defect = state.defectsList.find(defect => defect.id === defectId);
        return defect ? defect.type.id : 0;
    },
    getFilteredDefectsList: state => {
        let {defectsList} = state;

        if (state.searchQuery.length) defectsList = defectsList.filter(defect => defect.name.toLowerCase()
            .includes(state.searchQuery.toLowerCase()))
        if (state.defectTypeFilter !== null) defectsList = defectsList
            .filter(defect => defect.type.id === state.defectTypeFilter)

        return defectsList
    }
}