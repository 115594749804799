import axios from "axios";
import store from "@/store";

const dadataClient = axios.create({
    baseURL: process.env.VUE_APP_DADATA_BASE_URL,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Token " + process.env.VUE_APP_DADATA_API_KEY
    }
})

export default {
    getSuggestions(query, type, count) {
        return new Promise((resolve, reject) => {
            dadataClient.post(`/${type}`, {
                count,
                query,
                locations: store.getters["projectConfig/getProjectLocation"],
                restrict_value: false
            })
                .then(response => resolve(response.data.suggestions))
                .catch(error => reject(error))
        })
    }
}