<template>
  <div class="answer">{{dictionaryAnswer ? dictionaryAnswer.name : '—'}}</div>
</template>

<script>
export default {
  name: "AnswerDictionary",
  props: {
    answer: {
      type: Object || null,
      required: true
    },
    dictionaryItems: {
      type: Array,
      required: true
    }
  },
  computed: {
    dictionaryAnswer() {
      return this.dictionaryItems.find(item => item.id === this.answer.value);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.answer {
  @include font-style(700, 16px, 24px, var(--text-primary));
}
</style>