<script>
export default {
  name: "IconThunderstorm"
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M16.5 20H5L5.5 12L16.5 11.5V20Z" fill="#DBEAEE"/>
    <path d="M16.61 19.9999C17.95 20.0099 19.24 19.5099 20.23 18.6099C23.5 15.7499 21.75 10.0099 17.44 9.46995C15.9 0.129949 2.42998 3.66995 5.61998 12.5599" fill="#DBEAEE"/>
    <path d="M16.61 19.9999C17.95 20.0099 19.24 19.5099 20.23 18.6099C23.5 15.7499 21.75 10.0099 17.44 9.46995C15.9 0.129949 2.42998 3.66995 5.61998 12.5599" stroke="#1AB9EB" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.28011 12.9701C6.75011 12.7001 6.16011 12.5601 5.57011 12.5701C0.910109 12.9001 0.920108 19.6801 5.57011 20.0101" fill="#DBEAEE"/>
    <path d="M7.28011 12.9701C6.75011 12.7001 6.16011 12.5601 5.57011 12.5701C0.910109 12.9001 0.920108 19.6801 5.57011 20.0101" stroke="#1AB9EB" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.8201 9.88998C16.3401 9.62998 16.9001 9.48998 17.4801 9.47998L15.8201 9.88998Z" fill="#DBEAEE"/>
    <path d="M15.8201 9.88998C16.3401 9.62998 16.9001 9.48998 17.4801 9.47998" stroke="#1AB9EB" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.23008 19.1601H10.2301V21.4901C10.2301 21.8301 10.6601 22.0001 10.8801 21.7401L13.3301 18.9501C13.6301 18.6101 13.5001 18.3301 13.0501 18.3301H12.0501V16.0001C12.0501 15.6601 11.6201 15.4901 11.4001 15.7501L8.95008 18.5401C8.65008 18.8801 8.78008 19.1601 9.23008 19.1601Z" fill="#FFEDE0" stroke="#E19421" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<style scoped>

</style>