export default class Vehicle {
    static PASSENGER_VEHICLE = 1;
    static TRUCK_VEHICLE = 2;
    static WATER_CARRIER = 3;
    static SPECIAL_VEHICLE = 4;

    constructor(id, type, attributes) {
        this.id = id;
        this.type = type;
        this.attributes = attributes;
    }

    static getTransportTypes() {
        return [
            {id: Vehicle.PASSENGER_VEHICLE, name: 'Легковая'},
            {id: Vehicle.TRUCK_VEHICLE, name: 'Грузовая'},
            {id: Vehicle.WATER_CARRIER, name: 'Водовозка'},
            {id: Vehicle.SPECIAL_VEHICLE, name: 'Спец. техника'}
        ]
    }

    isActive() {
        return this.attributes.state.id === 1
    }

    getTypeLabel() {
        switch (this.attributes.type) {
            case Vehicle.PASSENGER_VEHICLE:
                return 'Легковая'
            case Vehicle.TRUCK_VEHICLE:
                return 'Грузовая'
            case Vehicle.WATER_CARRIER:
                return 'Водовозка'
            case Vehicle.SPECIAL_VEHICLE:
                return 'Спец. техника'
        }
    }

    isPassengerVehicle() {
        return this.attributes.type === Vehicle.PASSENGER_VEHICLE;
    }

    isTruck() {
        return this.attributes.type === Vehicle.TRUCK_VEHICLE;
    }

    isWaterCarrier() {
        return this.attributes.type === Vehicle.WATER_CARRIER;
    }

    isSpecialVehicle() {
        return this.attributes.type === Vehicle.SPECIAL_VEHICLE;
    }
}
