<template>
  <div class="additional-option-wrapper"
       :class="{'always-open': isCollapseVisible}">
    <div class="additional-option">
      <div class="additional-option__icon">
        <icon-clipboard-text />
      </div>
      <div class="additional-option__name">
        <span>Расход материалов</span>
        <span class="additional-option__quantity">{{ consumptionsList.length }}</span>
      </div>
      <div class="additional-option__buttons">
        <button
          v-show="isActionsVisible"
          class="additional-option__add"
          type="button"
          @click="showModal()">
          <icon-plus/>
        </button>
        <button
          @click="isCollapseVisible = !isCollapseVisible"
          class="additional-option__toggle"
          :class="{'rotate': isCollapseVisible}">
          <icon-arrow-double-up/>
        </button>
      </div>
    </div>
    <div class="additional-options-list">
      <div v-for="consumption in consumptionsList"
           :key="consumption.id"
           class="additional-options-list__inner">
        <div class="added-item added-item--flex">
          <div class="added-item__info">
            <div class="added-item__name">{{ consumption.consumable.name }}</div>
            <div class="added-item__additional">{{ consumption.amount }} шт.</div>
          </div>
          <div
            v-show="isActionsVisible"
            class="added-item__right"
            @click="deleteConsumption(consumption.id)">
            <icon-trash class="svg added-item__tool"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrowDoubleUp from "@/components/Icons/IconArrowDoubleUp.vue";
import IconTrash from "@/components/Icons/IconTrash.vue";
import IconPlus from "@/components/Icons/IconPlus.vue";
import {showToast, showAlert, showConfirmationModal} from "@/utils/notification";
import ConsumptionsForm from "@/components/ServiceDesk/Tasks/TaskComponents/Consumptions/ConsumptionsForm.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import {mapState} from "vuex";
import IconClipboardText from "@/components/Icons/IconClipboardText.vue";

export default {
  name: "ConsumptionsList",
  components: {IconClipboardText, IconPlus, IconTrash, IconArrowDoubleUp},
  props: {
    taskId: {
      type: Number,
      required: true
    },
    consumptionsList: {
      type: Array,
      required: true
    },
    isActionsVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isCollapseVisible: false
    }
  },
  computed: {
    ...mapState({
      consumablesList: state => state.consumables.consumables
    })
  },
  mounted() {
    this.$store.dispatch('consumables/getConsumablesList');
  },
  methods: {
    showModal() {
      this.$modal.show(
        ConsumptionsForm,
        {
          'consumables-list': this.consumablesList,
          'task-id': this.taskId
        },
        {
          name: 'consumptions-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(706)
        }
      )
    },
    deleteConsumption(consumptionId) {
      showConfirmationModal('Вы уверены, что хотите удалить расход материала?')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('taskConsumptions/deleteTaskConsumption', {
              taskId: this.taskId,
              consumptionId
            })
              .then(() => {
                this.$store.dispatch('taskModal/getConsumptionsList', this.taskId);
                showToast('Расход материала удалён', 'success');
              })
              .catch(error => showAlert('Ошибка', error, 'error'))
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
