<template>
  <base-popup
    :title="popupTitle"
    :name="popupName"
  >
    <template #body>
      <div
        class="damages-form">
        <div class="damages-form__wrapper">
          <div class="form-item">
            <div class="form-item__name">Дефект</div>
            <div class="form-item__value">
              <v-select
                v-model="payload.defect_id"
                :options="defectsList"
                :reduce="defect => defect.id"
                :get-option-label="option => option.name"
                class="vue-select-input"
                :class="{'vue-select-input--error': $v.payload.defect_id.$error}"
                placeholder="Выберите из списка"
              />
            </div>
          </div>

          <div v-if="defectWithFlaw || defectWithBreak"
               class="form-item">
            <div class="form-item__name">Ширина, мм</div>
            <div class="form-item__value">
              <input
                v-model="payload.defect_width"
                class="base-input base-input--bordered"
                type="text"
                placeholder="Введите ширину">
            </div>
          </div>

          <div v-if="defectWithFlaw || defectWithBreak"
               class="form-item">
            <div class="form-item__name">Длина, мм</div>
            <div class="form-item__value">
              <input
                v-model="payload.defect_height"
                class="base-input base-input--bordered"
                type="text"
                placeholder="Введите длину">
            </div>
          </div>

          <div v-if="defectWithHole"
               class="form-item">
            <div class="form-item__name">Диаметр, мм</div>
            <div class="form-item__value">
              <input
                v-model="payload.defect_diameter"
                class="base-input base-input--bordered"
                type="text"
                placeholder="Введите диаметр">
            </div>
          </div>

          <div v-if="defectWithWormhole"
               class="form-item">
            <div class="form-item__name">Кол-во свищей</div>
            <div class="form-item__value">
              <input
                v-model="payload.defect_wormhole_count"
                class="base-input base-input--bordered"
                type="text"
                placeholder="Введите количество свищей">
            </div>
          </div>

          <div
            v-if="defectWithBreak || defectWithFlaw || defectWithHole || defectWithWormhole"
            class="form-item">
            <div class="form-item__name">Давление, м</div>
            <div class="form-item__value">
              <input
                v-model="payload.defect_pressure"
                class="base-input base-input--bordered"
                type="text"
                placeholder="Давление">
            </div>
          </div>

          <div class="form-item">
            <div class="form-item__name">Дата и время обнаружения</div>
            <div class="form-item__value">
              <date-picker
                v-model="payload.start_date"
                :default-value="defaultValue"
                class="base-date-picker"
                :class="{'base-date-picker--error': $v.payload.start_date.$error}"
                placeholder="Выберите дату и время"
                type="datetime"
                format="DD.MM.YYYY HH:mm"
                value-type="YYYY-MM-DD HH:mm"
              />
            </div>
          </div>

          <div class="form-item">
            <div class="form-item__name">Дата и время устранения</div>
            <div class="form-item__value">
              <date-picker
                v-model="payload.end_date"
                :default-value="defaultValue"
                class="base-date-picker"
                placeholder="Выберите дату и время"
                type="datetime"
                format="DD.MM.YYYY HH:mm"
                value-type="YYYY-MM-DD HH:mm"
              />
            </div>
          </div>

          <div
            class="form-item">
            <div class="form-item__name form-item__name--with-loader form-item__name--with-indicator">
              <span>
                Элемент сети
                <div
                  v-if="elementSelectorOpenIterator === 0 && networkElements.length"
                  class="base-indicator base-indicator--attention"/>
              </span>

              <base-loader v-if="elementsLoading"/>
            </div>
            <div class="form-item__value">
              <v-select
                :options="networkElements"
                :reduce="element => element.id"
                :get-option-label="option => `#${option.id} ${option.type.name}`"
                class="vue-select-input"
                placeholder="Выберите из списка"
                @input="setElement"
                @open="elementSelectorOpenIterator++"
              >
                <template #no-options="">
                  Не найдено элементов сети, сделайте клик по карте.
                </template>
              </v-select>
            </div>

            <div
              v-if="damageNetworkElement"
              class="selected-emergency-element">
              <span>#{{ damageNetworkElement.id }} {{ damageNetworkElement.type.name }}</span>
              <div @click="deleteElement()">
                <icon-close/>
              </div>

            </div>
          </div>

          <div class="form-item">
            <div class="form-item__name">Описание повреждения</div>
            <div class="form-item__value">
              <textarea
                v-model="payload.description"
                class="base-textarea"
                :class="{'base-textarea--error': $v.payload.description.$error}"
                type="text"
                placeholder="Напишите текст"/>
            </div>
          </div>

          <div class="form-item">
            <label class="base-switcher">
              <input
                id="zone-active"
                v-model="payload.need_shutdown"
                class="base-switcher__checkbox"
                type="checkbox">
              <div class="base-switcher__switch"/>
              <span class="base-switcher__label">
                <div class="base-switcher__label--icon">
                  Требуется отключение
                </div>
              </span>
            </label>
          </div>
        </div>
        <div class="damages-form__map">
          <emergency-elements-map
            :invalidate-map-size="invalidateMapSize"
            :elements="networkElements"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <div
        @click="checkFormType()"
        class="button">
        Сохранить
      </div>
    </template>
  </base-popup>
</template>

<script>
import BasePopup from "@/components/BasePopup.vue";
import {mapGetters, mapState} from "vuex";
import EmergencyElementsMap
  from "@/components/ServiceDesk/Issues/IssueComponents/Damages/EmergencyElementsMap.vue";
import DatePicker from "vue2-datepicker";
import BaseLoader from "@/layout/BaseLoader.vue";
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import IconClose from "@/components/Icons/IconClose.vue";

export default {
  name: "DamagesForm",
  components: {IconClose, BaseLoader, EmergencyElementsMap, BasePopup, DatePicker},
  mixins: [validationMixin],
  props: {
    damage: {
      type: Object,
      default: () => {
      }
    },
    popupName: {
      type: String,
      required: true
    },
    createDamage: {
      type: Function,
      required: true
    },
    updateDamage: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      defaultValue: new Date(),
      invalidateMapSize: false,
      elementSelectorOpenIterator: 0,
      damageNetworkElement: null,
      payload: {
        defect_id: null,
        need_shutdown: false,
        start_date: null,
        end_date: null,
        description: null,
        element_external_id: null,
        element_source_id: null,
        defect_height: null,
        defect_width: null,
        defect_diameter: null,
        defect_wormhole_count: null,
        defect_pressure: null
      }
    }
  },
  validations: {
    payload: {
      defect_id: {required},
      start_date: {required},
      description: {required}
    }
  },
  computed: {
    popupTitle() {
      return !this.isUpdateForm ? 'Создать повреждение' : 'Редактирование повреждения'
    },
    ...mapState({
      defectsList: state => state.defects.defectsList,
      selectedLayerId: state => state.baseMap.selectedLayerId,
      elementsLoading: state => state.networkElements.loading,
      networkElements: state => state.networkElements.elements
    }),
    ...mapGetters({
      element: 'networkElements/getNetworkElement',
      defectType: 'defects/getDefectType'
    }),
    isUpdateForm() {
      return Object.keys(this.damage).length
    },
    defectWithWormhole() {
      return this.defectType(this.payload.defect_id) === 1
    },
    defectWithFlaw() {
      return this.defectType(this.payload.defect_id) === 2
    },
    defectWithHole() {
      return this.defectType(this.payload.defect_id) === 3
    },
    defectWithBreak() {
      return this.defectType(this.payload.defect_id) === 5
    }
  },
  watch: {
    'payload.element_external_id': function (newElementId, oldElementId) {
      this.$store.commit('networkElements/SELECT_ELEMENT', {newElementId, oldElementId});
    }
  },
  mounted() {
    this.$store.dispatch('defects/getDefectList');

    if (this.isUpdateForm) {
      const {defect, needShutdown, startDate, endDate, description, defectParams, emergencyElement} = this.damage;
      this.payload.defect_id = defect.id;
      this.payload.need_shutdown = needShutdown;
      this.payload.start_date = startDate;
      this.payload.end_date = endDate;
      this.payload.description = description;
      this.payload.element_external_id = emergencyElement ? emergencyElement?.external_id : null;
      this.payload.element_source_id = emergencyElement ? emergencyElement.semantic_source_id : null;
      this.payload.defect_height = defectParams.height;
      this.payload.defect_width = defectParams.width;
      this.payload.defect_diameter = defectParams.diameter;
      this.payload.defect_wormhole_count = defectParams.wormhole_count;
      this.payload.defect_pressure = defectParams.pressure;

      if (emergencyElement) {
        this.damageNetworkElement = {
          id: emergencyElement.external_id,
          type: emergencyElement.type
        };
      }
    } else {
      this.payload.defect_pressure = process.env.VUE_APP_DEFAULT_PRESSURE;
    }

    this.$root.$on('BaseMap::map-click', ({coords}) => {
      this.$store.commit('networkElements/SET_ELEMENTS', []);
      this.$store.commit('networkElements/SET_FILTER_PARAMS', {
        lat: coords.lat,
        lon: coords.lng,
        layer_id: this.selectedLayerId
      });
      this.$store.dispatch('networkElements/getNetworkElementsByCoords');
      this.elementSelectorOpenIterator = 0;
    });
  },
  methods: {
    checkFormType() {
      this.$v.$touch()
      if (this.$v.$invalid) return false

      if (!this.isUpdateForm) this.createDamage(this.payload)
      else this.updateDamage(this.damage.id, this.payload)
    },
    setElement(elementId) {
      const element = this.element(elementId);
      this.payload.element_source_id = element.semanticSource.id;
      this.payload.element_external_id = elementId;
      this.damageNetworkElement = element;
    },
    deleteElement() {
      this.payload.element_source_id = null;
      this.payload.element_external_id = null;
      this.damageNetworkElement = null;
    }
  }
}
</script>

<style lang="scss" scoped>
.damages-form {
  display: flex;
  gap: 12px;

  &__wrapper {
    flex: .4;

    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__map {
    flex: .6;
    height: 640px;
  }

  &--map-hidden {
    & .damages-form__wrapper {
      flex: 1;
    }

    & .damages-form__map {
      display: none;
    }
  }
}

.selected-emergency-element {
  display: flex;
  gap: 6px;

  color: var(--text-link);

  & div {
    cursor: pointer;
  }
}
</style>