import Author from "@/models/serviceDesk/Author";
import Address from "@/models/Address";
import File from "@/models/serviceDesk/File";

export default class DetectedProblem {
    constructor(params = {}) {
        this.id = params.id;
        this.taskId = params.task_id;
        this.category = params.category;
        this.state = params.state;
        this.author = new Author(params.author);
        this.message = params.message;
        this.createdAt = params.created_at;
        this.address = new Address(params.address, params.address.geometry);
        this.attachments = params.attachments.map(item => new File(item.id, item.attributes));
    }
}