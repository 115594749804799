import * as API from './API';
import WaterFlow from "@/models/serviceDesk/WaterFlow";
import {getError} from "@/utils/helpers";

export default {
    fetchTaskWaterFlowsList(taskId) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/tasks/${taskId}/water-flows`)
                .then(response => resolve(response.data.data.map(item => new WaterFlow({
                    id: item.id,
                    ...item.attributes
                }))))
                .catch(error => reject(getError(error)))
        })
    },
    addWaterFlow(taskId, payload) {
        return API.apiClient.post(`/service-desk/tasks/${taskId}/water-flows`, payload);
    },
    removeWaterFlow(taskId, waterFlowId) {
        return API.apiClient.delete(`/service-desk/tasks/${taskId}/water-flows/${waterFlowId}`)
    }
}