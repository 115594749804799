<template>
  <div class="page-wrapper">
    <base-panel
      title="Журнал энергопотребления"
      :is-add-button-visible="true"
    >
      <template #buttons>
        <date-picker
          v-model="currentYear"
          :default-value="currentYear"
          class="base-date-picker"
          placeholder="Выберите год"
          type="year"
          format="YYYY"
          @input="getElectricMetersReadings()"
        />
      </template>

    </base-panel>

    <energy-consumption-logbook
      :electric-meter-month-reading="electricMeterMonthReading"
      :energy-consumption-objects="energyConsumptionObjects"
      @save-electric-meter-reading="saveElectricMeterReading"
    />
  </div>
</template>

<script>
import EnergyConsumptionLogbook from "@/components/EnergyConsumption/EnergyConsumptionLogbook";
import BasePanel from "@/components/BasePanel.vue";
import DatePicker from "vue2-datepicker";
import {mapGetters, mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";
export default {
  name: "EnergyConsumptionLogbookPage",
  components: {BasePanel, EnergyConsumptionLogbook, DatePicker},
  data() {
    return {
      currentYear: new Date()
    }
  },
  computed: {
    ...mapState({
      energyConsumptionObjects: state => state.energyConsumptionObjects.energyConsumptionObjectsList
    }),
    ...mapGetters({
      electricMeterMonthReading: 'electricMetersReadings/getElectricMeterMonthReading'
    })
  },
  mounted() {
    this.$store.dispatch('energyConsumptionObjects/getAllEnergyConsumptionObjectsWithMeters');
    this.getElectricMetersReadings();
  },
  methods: {
    getElectricMetersReadings() {
      this.$store.commit('electricMetersReadings/SET_FILTER_PARAMS', {
        year: this.$moment(this.currentYear).format('YYYY')
      });
      this.$store.dispatch('electricMetersReadings/getElectricMetersMonthReading');
    },
    saveElectricMeterReading({value, electricMeterId, monthNumber}) {
      this.$store.dispatch('electricMetersReadings/createElectricMeterMonthReading', {
        payload: {
          value: parseFloat(value),
          reading_at: `${this.$moment(this.currentYear).format('YYYY')}-${monthNumber}`
        },
        electricMeterId
      })
        .then(() => {
          showToast('Значение сохранено', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
    }
  }
}
</script>

<style scoped>

</style>