import * as API from './API'
import Issue from "@/models/serviceDesk/Issue";
import Task from "@/models/serviceDesk/Task";
import Shutdown from "@/models/serviceDesk/Shutdown";
import TaskWork from "@/models/serviceDesk/TaskWork";
import {getError} from "@/utils/helpers";
import EmergencyElement from "@/models/serviceDesk/EmergencyElement";
import NetworkElementStatistic from "@/models/EngineeringNetworks/NetworkElementStatistic";

export default {
    getDailyReportData(params) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/reports/issues-daily`, {params: params})
                .then(response => {
                    resolve(
                        response.data.data.map(item => {
                            let issue = new Issue(item.id, item.attributes);

                            issue.tasks = item.attributes.tasks
                                .filter(task => task.attributes.actual_end_date)
                                .map(task => {
                                    const taskItem = new Task(task.id, task.attributes)
                                    taskItem.works = task.attributes.works
                                        .map(work => new TaskWork(work.id, work.attributes))
                                    return taskItem;
                                });

                            issue.shutdowns = item.attributes.shutdowns
                                .map(shutdown => new Shutdown(shutdown.id, shutdown.attributes));

                            issue.emergencyElements = item.attributes.emergency_elements
                                .map(element => new EmergencyElement(element.id, element.attributes))

                            return issue;
                        })
                    )
                })
                .catch(error => reject(getError(error)));
        })
    },
    getAccidentReportData(params) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/reports/summary-accident`, {params: params})
                .then(response => resolve(response.data.data))
                .catch(error => reject(getError(error)));
        })
    },
    getIssuesCountReportData(params) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/reports/issue-balance`, {params: params})
                .then(response => resolve(response.data.data))
                .catch(error => reject(getError(error)));
        })
    },
    getWaterFlowReport(params) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/reports/water-flows`, {params: params})
                .then(response => resolve(response.data.data))
                .catch(error => reject(getError(error)))
        })
    },
    fetchFuelAndLubricantsReportData(params) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/reports/pol-consumption`, {params: params})
                .then(response => resolve(response.data.data))
                .catch(error => reject(getError(error)))
        })
    },
    fetchResourceTasksReportData(params) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/reports/resource-task-report`, {params: params})
                .then(response => resolve(response.data.data.map(item => new Task(item.id, item.attributes))))
                .catch(error => reject(getError(error)))
        })
    },
    fetchResourceRatingsData(params) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/reports/resource-ratings`, {params: params})
                .then(response => resolve(response.data.data)).catch(error => reject(getError(error)))})},
    fetchEmergencyElementsReportData(params) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/reports/emergency-elements-statistics`, {params: params})
                .then(response => resolve(response.data.data
                    .map(item => new NetworkElementStatistic({id: item.id, ...item.attributes}))))
                .catch(error => reject(getError(error)))
        })
    },
    fetchTransportLoadReportData(params) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/reports/transport-workload`, {params: params})
                .then(response => resolve(response.data.data))
                .catch(error => reject(getError(error)))
        })
    }
}
