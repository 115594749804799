import * as API from './API'
import {getError} from "@/utils/helpers";
import DataServer from "@/models/DataServer";

export default {
    fetchAllTransportDataServers() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`transport-data-sources`)
                .then(response => resolve(
                    response.data.data.map(item => new DataServer(item.id, item.attributes))
                ))
                .catch(error => reject(getError(error)))
        })
    },
    createTransportDataServer(payload) {
        return API.apiClient.post(`transport-data-sources`, payload);
    },
    updateTransportDataServer({dataServerId, payload}) {
        return API.apiClient.patch(`transport-data-sources/${dataServerId}`, payload);
    },
    deleteTransportDataServer(dataServerId) {
        return API.apiClient.delete(`transport-data-sources/${dataServerId}`);
    },
    activateTransportDataServer(dataServerId) {
        return API.apiClient.post(`transport-data-sources/${dataServerId}/activate`);
    },
    deactivateTransportDataServer(dataServerId) {
        return API.apiClient.post(`transport-data-sources/${dataServerId}/deactivate`);
    }
}