<template>
  <div class="map-base-height map-cursor-crosshair">

    <div class="colorize-selector">
      <div class="colorize-selector-icon">
        <icon-map id="icon" />
      </div>
      <div class="colorize-selector-inner">
        <label class="custom-control custom-radio d-flex align-items-center">
          <input class="custom-control-input"
                 v-model="map_indication"
                 type="radio"
                 :value="1">
          <span class="custom-control-label">
            Водопотребление/водоотведение
          </span>
        </label>
        <label class="custom-control custom-radio d-flex align-items-center">
          <input class="custom-control-input"
                 v-model="map_indication"
                 type="radio"
                 :value="2">
          <span class="custom-control-label">
            Водопотребление/ТУ
          </span>
        </label>
        <label class="custom-control custom-radio d-flex align-items-center">
          <input class="custom-control-input"
                 v-model="map_indication"
                 type="radio"
                 :value="3">
          <span class="custom-control-label">
            Водоотведение/ТУ
          </span>
        </label>
      </div>
    </div>

    <base-map :center="center"
              :zoom="zoom"
              :base-layers="baseLayers"
              :layers="layers"
              :circles="createCircles()"
    />
  </div>
</template>

<script>
import BaseMap from "@/components/BaseMap.vue";
import Circle from "@/models/baseMap/Circle";
import {mapState} from "vuex";
import IconMap from "@/components/Icons/IconMap.vue";

export default {
  name: "BillingBuildingsMap",
  components: {IconMap, BaseMap},
  data() {
    return {
      consumption_indication: true,
      show_switcher: false,
      map_indication: 1
    }
  },
  computed: {
    ...mapState({
      center: state => state.baseMap.center,
      zoom: state => state.baseMap.zoom,
      layers: state => state.baseMap.layers,
      baseLayers: state => state.baseLayers.baseLayers
    })
  },
  mounted() {
    this.$store.dispatch('baseMap/getLayers');
  },
  methods: {
    createCircles() {
      return this.$store.getters['building/getBuildings'].map(building => {
        return new Circle({
          coords: building.attributes.geometry.coordinates,
          radius: 10,
          color: this.getGeometryColor(building)
        })
      })
    },
    getGeometryColor(building) {
      switch (this.map_indication) {
        case 1:
          return this.getColor(building.attributes.fact_ratio);
        case 2:
          return this.getColor(building.attributes.consumption_ratio);
        case 3:
          return this.getColor(building.attributes.drainage_ratio);
        default:
          return '#468FC5'
      }
    },
    getColor(ratio) {
      if (ratio != null) {
        if (ratio >= 0 && ratio <= 1.2) return '#04bd00'
        else if (ratio > 1.2 && ratio <= 2) return '#ffbf1c'
        else if (ratio > 2 && ratio <= 4) return '#ff6b1c'
        else if (ratio > 4) return '#E02800'
      } else {
        return '#468FC5'
      }
    }
  }
}
</script>

<style scoped>
.colorize-selector-icon {
  width: 44px;
  height: 44px;
  background-color: var(--panel);
  border-radius: 4px;
  border: 2px solid rgba(0,0,0,.2);
  background-clip: padding-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorize-selector {
  font-family: Nunito, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif;
  font-size: 12px;
  z-index: 1000;
  position: absolute;
  left: 65px;
  top: 10px;
}

.colorize-selector:hover .colorize-selector-icon {
  display: none;
}

.colorize-selector:hover .colorize-selector-inner {
  display: block;
}

.colorize-selector-inner {
  display: none;
  background-color: var(--panel);
  border-radius: 4px;
  border: 2px solid rgba(0,0,0,.2);
  background-clip: padding-box;
  padding: 6px 10px;
}
</style>