import {getDatesRangeCaption} from "@/utils/helpers";
import moment from "moment";

export default class TransportOrder {
    constructor(params) {
        this.id = params.id;
        this.state = params.state;
        this.transportType = params.transport_type;
        this.transport = params.transport;
        this.brigade = params.brigade;
        this.plannedStartDate = params.planned_start_date;
        this.plannedEndDate = params.planned_end_date;
        this.comment = params.comment;
        this.address = params.address;
        this.task = params.task;
    }

    static getStates() {
        return [
            {id: 1, name: 'Новый'},
            {id: 2, name: 'Подтверждён'},
            {id: 3, name: 'Отменён'}
        ]
    }

    getStateColor() {
        switch (this.state.id) {
            case 1:
                return '#468FC5'
            case 2:
                return '#04BD00'
            case 3:
                return '#4f4f4f'
        }
    }

    get fullAddress() {
        return [this.address.city, this.address.settlement, this.address.street,
            this.address.house, this.address.block, this.address.flat]
            .filter(item => item !== null)
            .join(', ')
    }

    get isNew() {
        return this.state.id === 1;
    }

    get isAccepted() {
        return this.state.id === 2;
    }

    get isCanceled() {
        return this.state.id === 3;
    }

    get plannedDatesCaption() {
        if(this.plannedStartDate && this.plannedEndDate) {
            const start = moment(this.plannedStartDate);
            const end = moment(this.plannedEndDate);
            return getDatesRangeCaption(start, end);
        } return moment(this.plannedStartDate).format(process.env.VUE_APP_BASE_DATE_TIME_FORMAT);
    }
}
