import HistoryService from "@/services/HistoryService";

export const namespaced = true;

export const state = {
    appeal_history: [],
    issue_history: [],
    task_history: [],
    error: null,
    loading: false
}

export const mutations = {
    SET_APPEAL_HISTORY(state, history) {
        state.appeal_history = history;
    },
    SET_ISSUE_HISTORY(state, history) {
        state.issue_history = history;
    },
    SET_TASK_HISTORY(state, history) {
        state.task_history = history;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    }
}

export const actions = {
    getAppealHistory({commit}, appeal_id) {
        commit("SET_LOADING", true);
        HistoryService.getAppealHistory(appeal_id)
            .then(history => commit("SET_APPEAL_HISTORY", history))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getIssueHistory({commit}, issue_id) {
        commit("SET_LOADING", true);
        HistoryService.getIssueHistory(issue_id)
            .then(history => commit("SET_ISSUE_HISTORY", history))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getTaskHistory({commit}, task_id) {
        commit("SET_LOADING", true);
        HistoryService.getTaskHistory(task_id)
            .then(history => commit("SET_TASK_HISTORY", history))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    }
}

export const getters = {
    getAppealHistory: state => {
        return state.appeal_history;
    },
    getIssueHistory: state => {
        return state.issue_history;
    },
    getTaskHistory: state => {
        return state.task_history;
    },
    getTaskHistoryFirstAction: state => {
        return state.task_history[0];
    },
    getIssueHistoryFirstAction: state => {
        return state.issue_history[0];
    },
    getAppealHistoryFirstAction: state => {
        return state.appeal_history[0];
    }
}
