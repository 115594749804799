<template>
  <div class="page-wrapper">
    <base-panel
      title="Расходные материалы"
      :is-add-button-visible="true"
      add-button-name="Добавить расходный материал"
      @add-button-clicked="showModal()"
    />

    <base-functional-line
      @search-change="event => $store.commit('consumables/SET_SEARCH_QUERY', event.target.value)"/>

    <consumables-list
      v-if="consumablesList.length"
      :consumables-list="consumablesList"
      @delete-consumable="deleteConsumable" />

    <base-content-loaders v-else-if="loading" />
  </div>
</template>

<script>
import ConsumablesList from "@/components/ServiceDesk/admin/ConsumablesList";
import {mapGetters, mapState} from "vuex";
import BasePanel from "@/components/BasePanel.vue";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import {showAlert, showToast, showConfirmationModal} from "@/utils/notification";
import {modalAdaptiveWidth} from "@/utils/helpers";
import ConsumablesForm from "@/components/ServiceDesk/admin/ConsumablesForm.vue";
import BaseFunctionalLine from "@/components/BaseFunctionalLine.vue";
export default {
  name: "ConsumablesListPage",
  components: {BaseFunctionalLine, BaseContentLoaders, BasePanel, ConsumablesList},
  computed: {
    ...mapState({
      loading: state => state.consumables.loading
    }),
    ...mapGetters({
      consumablesList: 'consumables/getConsumablesByQuery'
    })
  },
  mounted() {
    this.$store.dispatch('consumables/getConsumablesList');
  },
  methods: {
    showModal(consumable = {}) {
      this.$modal.show(
        ConsumablesForm,
        {
          'consumable': consumable
        },
        {
          name: 'consumable-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        })
    },
    deleteConsumable(consumableId) {
      showConfirmationModal('Вы уверены, что хотите удалить расходный материал?')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('consumables/deleteConsumable', consumableId)
              .then(() => {
                showToast('Расходный материал удалён', 'success');
                this.$store.commit("consumables/DELETE_CONSUMABLE", consumableId);
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
