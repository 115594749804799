import EnergyConsumptionObjectsService from "@/services/EnergyConsumptionObjectsService";

export const namespaced = true;

export const state = {
    energyConsumptionObjectsList: [],
    energyConsumptionObject: null,
    page: 1,
    meta: null,
    error: null,
    loading: false
}

export const mutations = {
    SET_ENERGY_CONSUMPTION_OBJECTS_LIST(state, objects) {
        state.energyConsumptionObjectsList = objects;
    },
    SET_ENERGY_CONSUMPTION_OBJECT(state, object) {
        state.energyConsumptionObject = object;
    },
    SET_PAGE(state, page) {
        state.page = page;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    DELETE_ENERGY_CONSUMPTION_OBJECT(state, objectId) {
        const index = state.energyConsumptionObjectsList.findIndex(object => object.id === objectId);
        state.energyConsumptionObjectsList.splice(index, 1);
    }
}

export const actions = {
    getEnergyConsumptionObjectsListWithMeters({commit, state}) {
        commit("SET_LOADING", true);
        EnergyConsumptionObjectsService.fetchEnergyConsumptionObjectsListWithMeters(state.page)
            .then(({energyConsumptionObjects, meta}) => {
                commit("SET_ENERGY_CONSUMPTION_OBJECTS_LIST", energyConsumptionObjects);
                commit("SET_META", meta);
            })
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getAllEnergyConsumptionObjectsWithMeters({commit}) {
        commit("SET_LOADING", true);
        EnergyConsumptionObjectsService.fetchAllEnergyConsumptionObjectsWithMeters()
            .then(energyConsumptionObjects => commit("SET_ENERGY_CONSUMPTION_OBJECTS_LIST", energyConsumptionObjects))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false))
    },
    getEnergyConsumptionObjectWithMeters({commit}, id) {
        commit("SET_LOADING", true);
        EnergyConsumptionObjectsService.fetchEnergyConsumptionObjectWithMeters(id)
            .then(energyConsumptionObject => commit("SET_ENERGY_CONSUMPTION_OBJECT", energyConsumptionObject))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false))
    },
    createEnergyConsumptionObject(context, payload) {
        return new Promise((resolve, reject) => {
            EnergyConsumptionObjectsService.createEnergyConsumptionObject(payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    updateEnergyConsumptionObject(context, {id, payload}) {
        return new Promise((resolve, reject) => {
            EnergyConsumptionObjectsService.updateEnergyConsumptionObject(id, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    deleteEnergyConsumptionObject(context, id) {
        return new Promise((resolve, reject) => {
            EnergyConsumptionObjectsService.deleteEnergyConsumptionObject(id)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    }
}