<script>
export default {
  name: "IconTagCross"
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none">
    <path
      d="M13.7203 20.25H7.00031C4.24031 20.25 2.00031 18.01 2.00031 15.25V8.75C2.00031 5.99 4.24031 3.75 7.00031
      3.75H13.7203C15.1303 3.75 16.4703 4.34 17.4203 5.39L20.9503 9.27C22.3603 10.82 22.3603 13.18 20.9503 14.73L17.4203
       18.61C16.4703 19.66 15.1303 20.25 13.7203 20.25Z"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M15 9H7"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M15 15H7"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <path
      d="M15 12H10"
      stroke="#9192AD"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>

<style scoped>

</style>