import {getError} from "@/utils/helpers";
import DeviceService from "@/services/DeviceService";

export const namespaced = true;

export const state  = {
    devices: [],
    device: null,
    meta: null,
    links: null,
    loading: false,
    error: null
};

export const mutations = {
    SET_DEVICES(state, devices) {
        state.devices = devices;
    },
    SET_DEVICE(state, device) {
        state.device = device;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    DELETE_DEVICE(state, device_id){
        let index = state.devices.findIndex(device => device.id === device_id);
        state.devices.splice(index, 1);
    }
};

export const actions = {
    getDevices({ commit }, filterParams = {}) {
        commit("SET_LOADING", true);
        DeviceService.getDevices(filterParams)
            .then(devices => commit("SET_DEVICES", devices))
            .catch(error => commit("SET_ERROR", getError(error)))
            .finally(() => commit("SET_LOADING", false));
    },
    getAllDevices({ commit }) {
        commit("SET_LOADING", true);
        DeviceService.getDevicesWithSensors()
            .then(devices => commit("SET_DEVICES", devices))
            .catch(error => commit("SET_ERROR", getError(error)))
            .finally(() => commit("SET_LOADING", false));
    },
    getDevice({commit}, deviceId) {
        return new Promise((resolve, reject) => {
            DeviceService.getDevice(deviceId)
                .then(device => {
                    commit("SET_DEVICE", device);
                    resolve(device);
                })
                .catch(error => reject(getError(error)))
        })
    },
    createDevice(context, payload) {
        return new Promise((resolve, reject) => {
            DeviceService.createDevice(payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateDevice(context, {deviceId, payload}) {
        return new Promise((resolve, reject) => {
            DeviceService.updateDevice(deviceId, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteDevice(context, deviceId) {
        return new Promise((resolve, reject) => {
            DeviceService.deleteDevice(deviceId)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
};

export const getters = {
    getDevice: state => id =>{
        return state.devices.find(device => device.id == id);
    },
    name: state => id => {
        let device = state.devices.find(device => device.id == id);
        return device ? device.name : 'Имя не найдено'
    },
    getNames: state => ids => {
        if (state.devices.length) {
            let devices = state.devices.filter(device => ids.includes(parseInt(device.id)))
            return devices.map(device => device.name).join(', ')
        } else {
            return ''
        }
    },
    getDeviceLat: state => id => {
        if (state.devices != null) {
            let device = state.devices.find(device => device.id == id)
            return device.attributes.lat
        } else {
            return []
        }
    },
    getDeviceLng: state => id => {
        if (state.devices != null) {
            let device = state.devices.find(device => device.id == id)
            return device.attributes.lng
        } else {
            return []
        }
    },
    getChildrenDevices: state => ids => {
        if (state.devices != null) {
            return state.devices.filter(device => ids.includes(device.parentId))
        } else {
            return []
        }
    },
    filDevices: state => c => {
        return state.devices.filter(device => device.attributes.construction_id == c);
    },
    getDevicesByConstructionId: state => id => {
        return state.devices.filter(device => device.attributes.construction_id === parseInt(id))
    },
    devices: state => {
        return state.devices;
    },
    meta: state => {
        return state.meta;
    },
    links: state => {
        return state.links;
    },
    loading: state => {
        return state.loading;
    },
    error: state => {
        return state.error;
    }
}
