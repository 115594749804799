import * as API from './API';
import ElectricMeterMonthReading from "@/models/energyConsumption/ElectricMeterMonthReading";
import {getError} from "@/utils/helpers";

export default {
    fetchElectricMeterMonthReadings(filterParams) {
        return new Promise((resolve, reject) => {
            API.apiClient.get('/energy-consumption/meters/readings', {params: filterParams})
                .then(response => resolve(response.data.data
                    .map(item => new ElectricMeterMonthReading({id: item.id, ...item.attributes}))))
                .catch(error => reject(getError(error)));
        });
    },
    createElectricMonthReading(electricMeterId, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`/energy-consumption/meters/${electricMeterId}/readings`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}